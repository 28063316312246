import { PayloadAction, Action } from '@reduxjs/toolkit'
import { SagaIterator } from 'redux-saga'
import { take, call, fork, put, select, race } from 'redux-saga/effects'
import * as Relay from '../../../services/relay'
import { RoomListCommandAction } from '../../../constants'
import { RoomListCommandPayload } from '../../interfaces'
import { roomsActions } from '../../features'
import { roomListCommand } from './roomListSlice'
import { getAuthToken } from '../auth/authSelectors'
import { getCantinaBackendAPIEndpoint } from '../settings/settingsSelectors'
import { Endpoint } from '../../../rest'
import { getRoomWebSocketURLByRoomId } from '../../../rest/getRoomWebSocketURL'

function* subscribeToInfoWorker(roomId: string): SagaIterator {
  try {
    const token = yield select(getAuthToken)
    const baseUrl = yield select(
      getCantinaBackendAPIEndpoint,
      Endpoint.RoomDomain
    )
    const { ws_url } = yield call(getRoomWebSocketURLByRoomId, {
      baseUrl,
      token,
      roomId,
    })
    console.debug('WS URL for:', roomId, ws_url)
    yield call(Relay.vertoSubscribeToConference, {
      vertoHost: ws_url,
      roomId,
    })
  } catch (error) {
    console.error('SubscribeToInfo', error)
    Relay.toastError('Error connecting to the room. Please retry')
  }
}

export function* worker({
  payload,
}: PayloadAction<RoomListCommandPayload>): SagaIterator {
  switch (payload.action) {
    case RoomListCommandAction.SubscribeToInfo: {
      // Cancel only if an incoming unsubscribe matches this room
      const { success, cancel } = yield race({
        success: call(subscribeToInfoWorker, payload.roomId),
        cancel: take(
          (action: any) =>
            action.type === RoomListCommandAction.UnsubscribeFromInfo &&
            action.roomId === payload.roomId
        ),
      })
      if (cancel) {
        console.debug('Cancelled before connecting to FS')
      }

      break
    }
    case RoomListCommandAction.UnsubscribeFromInfo: {
      try {
        yield call(Relay.vertoUnsubscribeFromConference, payload.roomId)
        yield put(roomsActions.destroyById(payload.roomId))
      } catch (error) {
        Relay.toastError('Something went wrong.')
        console.error('UnsubscribeFromInfo', error)
      }
      break
    }
    default:
      console.warn('Unknown roomListCommand action', payload)
  }
}

export function* watchRoomList(): SagaIterator {
  while (true) {
    const action = yield take(roomListCommand.type)
    yield fork(worker, action)
  }
}
