import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faArrowLeft,
  faArrowRight,
  faBackspace,
  faBars,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCog,
  faCompass,
  faCompressWide,
  faCopy,
  faDesktop,
  faDoNotEnter,
  faDownload,
  faEllipsisH,
  faEnvelope,
  faExclamationCircle,
  faExpand,
  faExpandAlt,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFilmAlt,
  faHandPaper,
  faHeadset,
  faHome,
  faImage,
  faInfoCircle,
  faKey,
  faLayerPlus,
  faLifeRing,
  faLock,
  faMegaphone,
  faMicrophone,
  faMicrophoneSlash,
  faMinus,
  faMinusSquare,
  faMoon,
  faPaintBrushAlt,
  faPalette,
  faPaperPlane,
  faPause,
  faPencilAlt,
  faPhone,
  faPlay,
  faPlayCircle,
  faPlus,
  faPlusSquare,
  faQuestion,
  faRecordVinyl,
  faRepeat,
  faSearch,
  faShareSquare,
  faSignal,
  faSignalStream,
  faSignOutAlt,
  faSlidersH,
  faSpinner,
  faSquare,
  faStepBackward,
  faStepForward,
  faStop,
  faSun,
  faTachometerFastest,
  faTachometerSlowest,
  faTh,
  faTimesCircle,
  faTimesOctagon,
  faTools,
  faTrashAlt,
  faUser,
  faUserFriends,
  faUserPlus,
  faUsers,
  faUserSlash,
  faUserTag,
  faVideo,
  faVideoSlash,
  faVolumeDown,
  faVolumeSlash,
  faVolumeUp,
  faWalkieTalkie,
  faWaveformPath,
  faWaveSquare,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons'

import { faInfoCircle as faInfoCircleRegular } from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { faCommentsAlt, faSignalAlt1 } from '@fortawesome/pro-duotone-svg-icons'

library.add(
  faArrowLeft,
  faArrowRight,
  faBackspace,
  faBars,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCog,
  faCommentsAlt,
  faCompass,
  faCompressWide,
  faCopy,
  faDesktop,
  faDoNotEnter,
  faDownload,
  faEllipsisH,
  faEnvelope,
  faExclamationCircle,
  faExpand,
  faExpandAlt,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFilmAlt,
  faHandPaper,
  faHeadset,
  faHome,
  faImage,
  faInfoCircle,
  faInfoCircleRegular,
  faKey,
  faLayerPlus,
  faLifeRing,
  faLock,
  faMegaphone,
  faMicrophone,
  faMicrophoneSlash,
  faMinus,
  faMinusSquare,
  faMoon,
  faPaintBrushAlt,
  faPalette,
  faPaperPlane,
  faPause,
  faPencilAlt,
  faPhone,
  faPlay,
  faPlayCircle,
  faPlus,
  faPlusSquare,
  faQuestion,
  faRecordVinyl,
  faRepeat,
  faSearch,
  faShareSquare,
  faSignal,
  faSignalAlt1,
  faSignalStream,
  faSignOutAlt,
  faSlidersH,
  faSpinner,
  faSquare,
  faStepBackward,
  faStepForward,
  faStop,
  faSun,
  faTachometerFastest,
  faTachometerSlowest,
  faTh,
  faTimes,
  faTimesCircle,
  faTimesOctagon,
  faTools,
  faTrashAlt,
  faUser,
  faUserFriends,
  faUserPlus,
  faUsers,
  faUserSlash,
  faUserTag,
  faVideo,
  faVideoSlash,
  faVolumeDown,
  faVolumeSlash,
  faVolumeUp,
  faWalkieTalkie,
  faWaveformPath,
  faWaveSquare,
  faWrench
)
