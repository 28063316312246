import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { getTheme } from 'src/common/redux/features/ui/uiSelectors'

export const Layout = ({ children }: PropsWithChildren<{}>) => {
  const theme = useSelector(getTheme)

  return (
    <div className={`app-root sw-theme-${theme}`}>
      <div className='app-main-content'>{children}</div>
    </div>
  )
}

export * from './SwLogo'
export * from './CenteredBox'
