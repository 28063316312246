import React, { LegacyRef, ChangeEvent, FC } from 'react'
import { LabelIconState } from 'src/common/constants'
import { IconState } from 'src/components/IconState'
import { TestId } from 'src/constants'

type InputGroupProps = {
  autoFocus?: boolean
  defaultValue?: string
  disableAutoComplete?: boolean
  disabled?: boolean
  error?: string
  hint?: string
  inputClassName?: string
  inputRef?: LegacyRef<HTMLInputElement>
  label?: string
  labelIconState?: LabelIconState
  max?: number
  min?: number
  maxLength?: number
  minLength?: number
  name: string
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  pattern?: string
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  showCounter?: boolean
  testId?: TestId // TODO: make required to enforce adding ID for testing
  title?: string
  type?: string
  value?: string
  wrapperClassName?: string
}

export const InputGroup: FC<InputGroupProps> = ({
  autoFocus = false,
  defaultValue,
  disableAutoComplete,
  disabled = false,
  error = null,
  hint = '',
  inputClassName = '',
  label,
  labelIconState,
  max,
  min,
  maxLength,
  minLength,
  name,
  onBlur,
  onChange,
  pattern = '',
  placeholder = '',
  readOnly = false,
  showCounter = false,
  inputRef,
  required = false,
  testId,
  title = '',
  type = 'text',
  value,
  wrapperClassName = 'mb-4',
}) => {
  const validationProps = pattern && title ? { pattern, title } : {}
  const autoCompleteValue = disableAutoComplete ? 'off' : name
  const errorClassName = error ? 'sw-input-error' : ''

  return (
    <div className={wrapperClassName}>
      <div className='flex justify-between'>
        {label && (
          <label htmlFor={name}>
            {`${label}${required ? '\u00A0*' : ''}`}
            {labelIconState && <IconState state={labelIconState} />}
          </label>
        )}
        {maxLength && showCounter && (
          <div className='text-contrast-m'>
            {maxLength - (value ? value.length : 0)}
          </div>
        )}
      </div>
      <input
        ref={inputRef}
        autoComplete={autoCompleteValue}
        autoFocus={autoFocus}
        className={`sw-input ${errorClassName} ${inputClassName}`.trim()}
        disabled={disabled}
        data-test={testId}
        id={name}
        min={min}
        max={max}
        minLength={minLength}
        maxLength={maxLength}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        type={type}
        defaultValue={defaultValue}
        value={value}
        {...validationProps}
      />
      {hint && !error && (
        <p className='text-xs text-contrast-m italic pt-1'>{hint}</p>
      )}
      <div
        className={`sw-error-hint ${error ? 'visible' : 'invisible'}`}
        data-test={TestId.MessageError}
      >
        {error}
      </div>
    </div>
  )
}
