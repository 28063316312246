import React, { FC, useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { compose } from 'redux'
import { RoutePath } from 'src/common/constants'
import { CantinaState } from 'src/common/redux/interfaces'
import { cantinaManagerGetRoomMembers } from 'src/common/services/relay'
import { withHideParticipantListFeatureFlag } from 'src/common/hoc'
import { getParticipantCountLabel } from 'src/common/services/helpers'
import { roomListActions } from 'src/common/redux/features'
import { useSmartRoomSelector } from 'src/common/hooks'
import {
  canSeeRoomParticipantSubtitle,
  getRoomMemberIds,
} from 'src/common/redux/features/roomList/roomListSelectors'
import { isNotSelfModerator } from 'src/common/redux/features/calls/callSelectors'
import { TestId, Size } from 'src/constants'
import { BasicTooltip } from '../Tooltip/Tooltip'
import { ParticipantData } from '../ParticipantList/ParticipantData'

interface RoomParticipantsProps {
  roomId: string
  tooltipPlacement?: string
}

const modifiers = [
  {
    name: 'offset',
    options: {
      offset: [0, 33],
    },
  },
]

const RoomParticipantsFunction = ({
  roomId,
  tooltipPlacement = 'left',
}: RoomParticipantsProps) => {
  const match = useRouteMatch({
    path: RoutePath.Call,
    strict: true,
    sensitive: true,
  })
  const tooltipClassName = match ? 'in-call-tooltip' : ''
  const participantCount = useSmartRoomSelector('getParticipantCount', roomId)

  return (
    <div className='room-participant-count'>
      <BasicTooltip
        tooltip={<TooltipContent roomId={roomId} />}
        placement={tooltipPlacement}
        trigger='click'
        tooltipClassName={tooltipClassName}
        modifiers={modifiers}
        hideArrow
      >
        <div
          className='text-xs cursor-pointer'
          data-test={TestId.RoomNavigatorButtonParticipants}
        >
          {getParticipantCountLabel(participantCount)}
        </div>
      </BasicTooltip>
    </div>
  )
}

const RoomParticipantsFallback = () => (
  <span className='text-sm text-contrast-m'>Live</span>
)

export const RoomParticipants = compose<FC<RoomParticipantsProps>>(
  withHideParticipantListFeatureFlag({
    show: false,
    FallbackComponent: RoomParticipantsFallback,
    customSelector: isNotSelfModerator,
  })
)(RoomParticipantsFunction)

const TooltipContent: FC<{ roomId: string }> = ({ roomId }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    cantinaManagerGetRoomMembers(roomId)

    return () => {
      // Cleanup the slice when the component unmounts
      dispatch(roomListActions.bootstrapMembers({ roomId, members: [] }))
    }
  }, [dispatch, roomId])

  const participantIds = useSelector(
    (state: CantinaState) => getRoomMemberIds(state, roomId),
    shallowEqual
  )
  const showSubtitle = useSelector((state: CantinaState) =>
    canSeeRoomParticipantSubtitle(state, roomId)
  )

  return (
    <div className='flex flex-col divide-y divide-gray-400 max-w-xs'>
      {participantIds.map((id) => (
        <ParticipantData
          key={`partRow${id}`}
          participantId={id}
          className='p-2'
          size={Size.Medium}
          showSubtitle={showSubtitle}
          showTalkingIndicator={false}
        />
      ))}
    </div>
  )
}
