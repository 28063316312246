import { useEffect } from 'react'
import { RoomCommandAction } from '../constants'
import { useDispatchRoomCommand } from './useDispatchRoomCommand'

export const useLiveArrayBootstrap = ({ roomId }: { roomId: string }) => {
  const dispatchRoomCommand = useDispatchRoomCommand()

  useEffect(() => {
    if (roomId) {
      dispatchRoomCommand({
        roomId,
        action: RoomCommandAction.LiveArrayBootstrap,
      })
    }
  }, [roomId])
}
