import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import {
  getDevices,
  getVideoDevices,
  getAudioInDevices,
  getAudioOutDevices,
  validateVideoDevice,
  validateAudioInDevice,
  validateAudioOutDevice,
  checkVideoPermissions,
  checkAudioPermissions,
  requestPermissions,
} from '@signalwire/js-cantina'
import { setAppMethods } from './common/services/relay'
import { callActions } from './common/redux/features'
import {
  clientBuilder,
  getUserClientDetails,
  VertoConference,
} from './services/RelayService'
import { playAudio, stopAudio } from './services/SoundCueService'
import { instanceName } from './services/Helpers'
import { reduxInit } from './services/ReduxService'
import { initI18n } from './services/I18nService'
import {
  toastInfo,
  toastError,
  toastWarn,
  toastSuccess,
  toastCustom,
  dismissToast,
} from './services/ToastService'
import * as serviceWorker from './serviceWorker'
import { App } from './App'

import './common/icons/library'
import './common/fonts/Inter/inter.css'
import './common/fonts/NeueEinstellung/neue-einstellung.css'

setAppMethods({
  getInstanceName: instanceName,
  afterLogoutCallback: () => {
    setTimeout(() => {
      window.location.reload()
    })
  },
  clientBuilder,
  getVertoConferenceClass: () => VertoConference,
  getUserClientDetails,
  playAudio,
  stopAudio,
  getDevices,
  getVideoDevices,
  getAudioInDevices,
  getAudioOutDevices,
  toastCustom,
  toastError,
  toastInfo,
  toastSuccess,
  toastWarn,
  dismissToast,
  validateVideoDevice,
  validateAudioInDevice,
  validateAudioOutDevice,
  checkVideoPermissions,
  checkAudioPermissions,
  requestPermissions,
})

initI18n()

const { store, persistor } = reduxInit()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// expose store when run in Cypress
// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.__swStore__ = store
}

function setWindowViewportHeightValue() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}
setWindowViewportHeightValue()

window.addEventListener('orientationchange', setWindowViewportHeightValue)
window.addEventListener('resize', setWindowViewportHeightValue)

// @ts-ignore
window.__setRTCPeerConfig = (config: RTCConfiguration = {}) => {
  store.dispatch(callActions.setRTCPeerConfig(config))
}
