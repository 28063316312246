import { createSelector } from 'reselect'
import { UserRole, UserType } from '../../../constants'
import { gravatarUrl, parseJWT } from '../../../services/helpers'
import { CantinaState } from '../../interfaces'

export const hasValidAuth = ({ auth, scopes }: CantinaState) => {
  const { scopeId } = auth
  return Boolean(
    scopeId && scopes.byId[scopeId] && scopes.byId[scopeId].length > 0
  )
}

export const getUserId = ({ auth }: CantinaState) => auth.userId
export const getUserType = ({ auth }: CantinaState) => auth.type
export const getUserRole = ({ auth }: CantinaState) => auth.role
export const getAuthToken = ({ auth }: CantinaState) => auth.token
export const isAuthTokenExpiring = ({ auth }: CantinaState) => {
  if (!auth.token) {
    return true
  }
  const payload = parseJWT(auth.token)
  if (payload && payload.exp && payload.iat) {
    /**
     * With a valid JWT payload, check if the token
     * has reached 2/3 of its expiration time.
     */
    const now = Math.abs(Date.now() / 1000)
    const stillValidFor = payload.exp - now
    return stillValidFor < (payload.exp - payload.iat) / 3
  }
  return true
}

export const getErrorMessage = ({ auth }: CantinaState) => {
  return auth.errorMessage
}

export const getAvatar = ({ auth }: CantinaState) => {
  return gravatarUrl(auth.email, auth.name)
}

export const getCompany = ({ auth }: CantinaState) => {
  return auth.company || ''
}

export const getName = ({ auth }: CantinaState) => {
  return auth.name || ''
}

export const getEmail = ({ auth }: CantinaState) => {
  return auth.email
}

export const getScopeId = ({ auth }: CantinaState) => {
  return auth.scopeId
}

export const getUsername = ({ auth }: CantinaState) => {
  return auth.username || ''
}

export const getPublicProfile = ({ auth }: CantinaState) => {
  return {
    name: auth.name || '',
    email: auth.email || '',
    phone: auth.phone || '',
    company: auth.company || '',
  }
}

export const hasMissingProfileInfo = ({ auth }: CantinaState) => {
  return Boolean(auth.username && (!auth.name || !auth.email))
}

export const isAuthenticated = createSelector(
  getUserType,
  (userType) => userType === UserType.Authenticated
)
export const hasRoleAdministrator = createSelector(
  getUserRole,
  (userRole) => userRole === UserRole.Administrator
)
export const hasRoleMember = createSelector(
  getUserRole,
  (userRole) => userRole === UserRole.Member
)
export const hasRoleGuest = createSelector(
  getUserRole,
  (userRole) => userRole === UserRole.Guest
)

export const hasTemporaryAuth = ({ auth }: CantinaState) =>
  Boolean(auth.temporary)
