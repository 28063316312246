import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDispatchRoomParticipantCommand } from 'src/common/hooks'
import { CantinaState } from 'src/common/redux/interfaces'
import {
  getGain as getGainSelector,
  isDeaf,
} from 'src/common/redux/features/participants/participantSelectors'
import { RoomCommandAction } from 'src/common/constants'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import RangeSlider from 'src/components/Inputs/RangeSlider'

interface MicVolumeControlProps {
  className?: string
  horizontalLabel?: boolean
  LowerIcon?: any
  participantId: string
  UpperIcon?: any
}

export const MicVolumeControl: FC<MicVolumeControlProps> = ({
  participantId,
  ...rest
}) => {
  const dispatchRoomParticipantCommand =
    useDispatchRoomParticipantCommand(participantId)
  const gainVal = useSelector((state: CantinaState) =>
    getGainSelector(state, participantId)
  )
  const dndEnabled = useSelector((state: CantinaState) =>
    isDeaf(state, participantId)
  )
  const onChange = useCallback(
    (value: number) => {
      dispatchRoomParticipantCommand({
        action: RoomCommandAction.MemberGain,
        participantId,
        value,
      })
    },
    [dispatchRoomParticipantCommand, participantId]
  )

  return (
    <RangeSlider
      currentValue={gainVal ?? undefined}
      disabled={dndEnabled}
      label={tr(Label.MIC_VOLUME)}
      onValueChange={onChange}
      testId={TestId.SoundRangeGain}
      {...rest}
    />
  )
}
