import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { getMyParticipantId } from '../redux/features/calls/callSelectors'

export const withCurrentParticipant = <T extends object>(
  WrappedComponent: ComponentType<T>
) => {
  return (props: T) => {
    const participantId = useSelector(getMyParticipantId)
    return <WrappedComponent participantId={participantId} {...props} />
  }
}
