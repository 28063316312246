import { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { floatersActions } from '../redux/features'
import { getFloater } from '../redux/features/floaters/floatersSelectors'
import { CantinaState } from '../redux/interfaces'
import { FloaterType } from '../constants'

export const useFloater = ({
  id,
  reservationId,
  type,
}: {
  id: string
  reservationId?: string
  type: FloaterType
}) => {
  const [left, setLeft] = useState(0)
  const [top, setTop] = useState(0)
  const dispatch = useDispatch()
  const floaterObject = useSelector((state: CantinaState) =>
    getFloater(state, id)
  )

  const ref = (node: HTMLButtonElement) => {
    if (node) {
      const rect = node.getBoundingClientRect()
      setLeft(rect.left + window.scrollX)
      setTop(rect.top + window.scrollY)
    }
  }

  const toggleFloaterFunction = () => {
    if (floaterObject) {
      return dispatch(floatersActions.removeFloater({ id, type }))
    } else {
      return dispatch(
        floatersActions.addFloater({
          coords: {
            left,
            top,
          },
          id,
          reservationId,
          type,
        })
      )
    }
  }

  return { toggleFloaterFunction, ref }
}
