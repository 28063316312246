import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RoutePath } from 'src/common/constants'
import { hasValidAuth } from 'src/common/redux/features/auth/authSelectors'

export const LoginRoute = ({ children, ...rest }: RouteProps) => {
  const hasAuth = useSelector(hasValidAuth)
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (hasAuth) {
          return (
            <Redirect
              to={{ pathname: RoutePath.Home, state: { from: location } }}
            />
          )
        }
        return children
      }}
    />
  )
}
