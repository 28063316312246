import { CantinaState } from '../../interfaces'

export const getClipeezes = ({ clipeeze }: CantinaState) => {
  return clipeeze.ids || []
}

export const getFilteredClipeezes = ({ clipeeze }: CantinaState) => {
  return clipeeze.filteredIds || []
}

export const getClipeeze = ({ clipeeze }: CantinaState, clipId: string) => {
  return clipeeze.byId[clipId] || null
}

export const getClipeezeFilter = ({ clipeeze }: CantinaState) =>
  clipeeze.clipeezeFilter || ''

export const getFullClipeezes = ({ clipeeze }: CantinaState) =>
  clipeeze.byId || {}

export const getClipeezeNextUrl = ({ clipeeze }: CantinaState) =>
  clipeeze.links?.next || ''

export const getFilteredClipeezeNextUrl = ({ clipeeze }: CantinaState) =>
  clipeeze.filteredLinks?.next || ''

export const hasMoreClipeeze = ({ clipeeze }: CantinaState) =>
  !!clipeeze.links?.next

export const hasMoreFilteredClipeeze = ({ clipeeze }: CantinaState) =>
  !!clipeeze.filteredLinks?.next
