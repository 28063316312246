import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ScopeState } from '../../interfaces'
import { roomJoinedAction, roomLeftAction } from '../../features'
import { MODERATOR_SCOPES, Scope } from '../../../constants'

export const initialScopeState: ScopeState = Object.freeze({
  byId: {},
})

const scopesSlice = createSlice({
  name: 'scopes',
  initialState: initialScopeState,
  reducers: {
    create: (
      state,
      { payload }: PayloadAction<{ scopeId: string; scopes: Scope[] }>
    ) => {
      state.byId[payload.scopeId] = payload.scopes
    },
    add: (
      state,
      { payload }: PayloadAction<{ scopeId: string; scopes: Scope[] }>
    ) => {
      const { scopeId, scopes = [] } = payload
      if (!scopeId || !state.byId[scopeId]) {
        return state
      }
      scopes.forEach((scope) => {
        if (!state.byId[scopeId].includes(scope)) {
          state.byId[scopeId].push(scope)
        }
      })
    },
    remove: (
      state,
      { payload }: PayloadAction<{ scopeId: string; scopes: Scope[] }>
    ) => {
      const { scopeId, scopes = [] } = payload
      if (!scopeId || !state.byId[scopeId]) {
        return state
      }
      state.byId[scopeId] = state.byId[scopeId].filter((scope) => {
        // filter the current array removing the input "scopes"
        return scopes.includes(scope) === false
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(roomJoinedAction, (state, { payload }) => {
        const { scopeId, role } = payload
        if (!state.byId?.[scopeId]) {
          return state
        }
        if (role === 'moderator') {
          MODERATOR_SCOPES.forEach((scope) => {
            if (!state.byId[scopeId].includes(scope)) {
              state.byId[scopeId].push(scope)
            }
          })
        } else {
          state.byId[scopeId] = state.byId[scopeId].filter((scope) => {
            return MODERATOR_SCOPES.includes(scope) === false
          })
        }
      })
      .addCase(roomLeftAction, (state, { payload }) => {
        const { scopeId } = payload
        if (!state.byId?.[scopeId]) {
          return state
        }
        state.byId[scopeId] = state.byId[scopeId].filter((scope) => {
          return MODERATOR_SCOPES.includes(scope) === false
        })
      })
  },
})

// prettier-ignore
export const {
  actions: scopesActions,
  reducer: scopesReducer
} = scopesSlice
