import { useParams } from 'react-router-dom'

/**
 * useParams from react-router-dom give us encoded values for
 * chars like: `/` or `?`
 * This hook just force all the string values to be `decodeURIComponent` first.
 */
export const useDecodedParams = <
  P extends Record<string, string> = {}
>(): P => {
  const params = useParams<P>()

  for (const key in params) {
    try {
      params[key] = decodeURIComponent(params[key]) as P[Extract<
        keyof P,
        string
      >]
    } catch (error) {
      console.debug(
        `The value for '${key}' won't be decoded because the string '${params[key]}' is malformed`,
        error
      )
    }
  }

  return params
}
