import React, { FC } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { CantinaState } from 'src/common/redux/interfaces'
import {
  getNameAndSubtitle,
  getAvatar,
  isScreenShareLeg,
} from 'src/common/redux/features/participants/participantSelectors'
import { Size, TestId } from 'src/constants'
import { UserProfile } from 'src/components/UserProfile'

type ParticipantDataProps = {
  className?: string
  participantId: string
  showSubtitle?: boolean
  showTalkingIndicator?: boolean
  size?: Size
  testId?: TestId
}

export const ParticipantData: FC<ParticipantDataProps> = ({
  className = '',
  participantId,
  showSubtitle = true,
  showTalkingIndicator = true,
  size = Size.Medium,
  testId,
}) => {
  const [name, subtitle] = useSelector(
    (state: CantinaState) => getNameAndSubtitle(state, participantId),
    shallowEqual
  )
  const avatar = useSelector((state: CantinaState) =>
    getAvatar(state, participantId)
  )
  const isScreenShare = useSelector((state: CantinaState) =>
    isScreenShareLeg(state, participantId)
  )

  return (
    <UserProfile
      avatar={avatar}
      isScreenShare={isScreenShare}
      name={name as string}
      nameClassName='font-bold'
      participantId={participantId}
      showTalkingIndicator={showTalkingIndicator}
      size={size}
      subtitle={showSubtitle ? (subtitle as string) : ''}
      subtitleClassName='text-xs'
      testId={testId}
      wrapperClassName={className}
    />
  )
}
