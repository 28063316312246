import { useState, useEffect, useCallback } from 'react'
import { RailsEndpoint, RequestState, Recording } from '../rest'
import { toastError, toastSuccess } from '../services/relay'
import { tr, Label } from '../i18n'
import { useInternalAPI } from './useInternalAPI'

type UseFetchRoomRecordingsData = {
  recordings: Recording[]
  requestState: RequestState
  error: string | null
}

const fetchOptions = {
  endpoint: RailsEndpoint.Recordings,
  method: 'GET',
}
const deleteOptions = {
  endpoint: RailsEndpoint.Recordings,
  method: 'DELETE',
}

export const useFetchRoomRecordings = (roomId: string) => {
  /**
   * `fetchHandler` is used to fetch both #index and #show
   * with a different `urlTransform` function
   */
  const { invokeAPI: fetchHandler } = useInternalAPI(fetchOptions)
  const { invokeAPI: deleteRoomRecording } = useInternalAPI(deleteOptions)
  const [data, setData] = useState<UseFetchRoomRecordingsData>({
    recordings: [],
    requestState: RequestState.Loading,
    error: null,
  })

  const refresh = useCallback(async () => {
    try {
      const { recordings } = await fetchHandler({
        urlTransform: (url) => `${url}?room_id=${roomId}`,
      })
      setData({
        recordings,
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      setData({
        recordings: [],
        requestState: RequestState.Error,
        error: 'Error loading recordings',
      })
    }
  }, [roomId])

  const item = tr(Label.RECORDING)
  const deleteHandler = useCallback(
    async (recordingId: string) => {
      try {
        await deleteRoomRecording({
          urlTransform: (url) => `${url}/${recordingId}`,
        })
        await refresh()
        toastSuccess(tr(Label.SUCCESSFUL_DELETE, { item }))
      } catch (error) {
        toastError(tr(Label.UNSUCCESSFUL_DELETE, { item: item.toLowerCase() }))
      }
    },
    [refresh]
  )

  const getDownloadURI = useCallback(async (recordingId: string) => {
    try {
      const { recording_uri } = await fetchHandler({
        urlTransform: (url) => `${url}/${recordingId}`,
      })
      return recording_uri
    } catch (error) {
      toastError('Error fetching the recording. Please retry.')
      return null
    }
  }, [])

  useEffect(() => {
    refresh()
  }, [])

  return { ...data, refresh, deleteHandler, getDownloadURI }
}
