import { useEffect, useRef, useState } from 'react'

// To use this hook, you must set the returned 'opacityClass' class and
// 'sw-transition-all' (or similar transition) on the element to be faded

// viewDuration (ms) is the length of time to show the content before fading
// transitionDuration (ms) should match the duration of the transition class used

export const useFade = ({ viewDuration = 2500, transitionDuration = 300 }) => {
  const [display, setDisplay] = useState({
    fadeClass: 'opacity-0',
    show: false,
  })
  const ref: any = useRef<any>(null)

  useEffect(() => {
    let viewTimer: ReturnType<typeof setTimeout>
    let transitionTimer: ReturnType<typeof setTimeout>

    const fade = () => {
      clearTimeout(transitionTimer)
      setDisplay({
        fadeClass: 'opacity-0',
        show: true,
      })

      // Hide content after transition finishes
      // This is done to remove buttons so they are no longer clickable
      transitionTimer = setTimeout(
        () => setDisplay({ fadeClass: 'opacity-0', show: false }),
        transitionDuration
      )
    }

    const show = () => {
      // Make visible on wrapper hover
      clearTimeout(viewTimer)
      clearTimeout(transitionTimer)

      setDisplay({
        fadeClass: 'opacity-100',
        show: true,
      })

      // Hide after mouse is idle
      viewTimer = setTimeout(fade, viewDuration)
    }

    if (ref?.current) {
      ref.current?.addEventListener('mousemove', show, false)

      // Hide without idle timer if mouse leaves wrapper
      ref.current?.addEventListener('mouseleave', fade, false)
    }

    return () => {
      clearTimeout(viewTimer)
      clearTimeout(transitionTimer)
      ref.current?.removeEventListener('mousemove', show, false)
      ref.current?.removeEventListener('mouseleave', fade, false)
    }
  }, [ref.current])

  return [display.fadeClass, display.show, ref]
}
