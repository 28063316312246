import React from 'react'
import { MicrophoneList } from './MicrophoneList'
import { SpeakerList } from './SpeakerList'
import { InCallParticipantDenoiseToggle } from 'src/components/CallSettings/DenoiseToggle'
import { PushToTalkToggle } from 'src/components/CallSettings/PushToTalkToggle'
import { EchoCancellationToggle } from 'src/components/CallSettings/EchoCancellationToggle'
import { NoiseSuppressionToggle } from 'src/components/CallSettings/NoiseSuppressionToggle'
import { AutoGainControlToggle } from 'src/components/CallSettings/AutoGainControlToggle'

export const AudioSettings = () => {
  return (
    <div className='sm:w-screen sm:max-w-md'>
      <MicrophoneList />
      <SpeakerList />
      <div className='block-highlight text-sm p-2 font-semibold'>
        Audio Settings
      </div>
      <InCallParticipantDenoiseToggle />
      <PushToTalkToggle />
      <EchoCancellationToggle />
      <NoiseSuppressionToggle />
      <AutoGainControlToggle />
    </div>
  )
}
