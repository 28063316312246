import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SpinnerIcon } from 'src/common/icons'
import { Endpoint, UserLookupResponse } from 'src/common/rest'
import { tr, Label } from 'src/common/i18n'
import { useCantinaBackendAPI } from 'src/common/hooks'
import { TestId } from 'src/constants'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { useInputChange } from 'src/effects/useInputChange'
import { PrimaryButton } from 'src/components/Button/Button'
import { userLookupResponseHandler } from 'src/services/Helpers'

const userLookupOptions = {
  endpoint: Endpoint.UsersLookup,
  method: 'GET',
}

type UserLookupProps = {
  cantinaId: string
}

export const UserLookup: FC<UserLookupProps> = ({ cantinaId }) => {
  const { invokeAPI: userLookupHandler } =
    useCantinaBackendAPI(userLookupOptions)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useInputChange({
    email: '',
  })
  const [error, setError] = useState('')
  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (values.email) {
      try {
        setLoading(true)
        setError('')
        const email = encodeURIComponent(values.email)
        const response: UserLookupResponse = await userLookupHandler({
          urlTransform: (url) => {
            return `${url}?cantinaId=${cantinaId}&email=${email}`
          },
        })

        if (!response.email) {
          response.email = values.email
        }
        userLookupResponseHandler({ response, history })
      } catch (error) {
        setLoading(false)
        setError('Unknown email')
      }
    }
    return false
  }

  return (
    <>
      <div className='text-center text-contrast-h text-4xl font-neue'>
        {tr(Label.WELCOME)}
      </div>
      <div className='text-sm text-contrast-m pb-4 text-center'>
        {tr(Label.ENTER_EMAIL)}
      </div>
      <form
        onSubmit={submitHandler}
        autoComplete='off'
        data-test={TestId.LoginForm}
      >
        <InputGroup
          autoFocus
          error={error}
          label={tr(Label.EMAIL)}
          name='email'
          onChange={setValues}
          required
          type='email'
          testId={TestId.LoginInputEmail}
        />
        <PrimaryButton
          className='w-full mb-2 px-16'
          disabled={loading}
          testId={TestId.LoginButtonContinue}
          type='submit'
        >
          {loading ? <SpinnerIcon /> : tr(Label.WELCOME)}
        </PrimaryButton>
      </form>
    </>
  )
}
