import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { getAppName } from '../redux/features/settings/settingsSelectors'

interface WithProductNameProps {
  productName: string
}

export const withProductName = <T extends object>(
  WrappedComponent: ComponentType<T & WithProductNameProps>
) => {
  return (props: T) => {
    const appName = useSelector(getAppName)
    return <WrappedComponent {...(props as T)} productName={appName} />
  }
}
