import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { CantinaState } from 'src/common/redux/interfaces'
import { getRoomCustomButtons } from 'src/common/redux/features/roomList/roomListSelectors'
import { useSmartRoomSelector } from 'src/common/hooks'
import { TestId } from 'src/constants'
import { JoinButton, JoinButtonProps } from './JoinButton'
import { RoomCustomButtons } from './RoomCustomButtons'
import { TransferListButton } from './RoomNavigator/TransferList'

interface RoomCardButtonsProps extends Omit<JoinButtonProps, 'testId'> {
  joinLabel?: string
  joinTestId: TestId | string
  roomId: string
  transferListToggle?: (arg0: boolean) => void
}

export const RoomCardButtons: FC<RoomCardButtonsProps> = ({
  roomId,
  joinLabel,
  joinTestId,
  transferListToggle,
  ...rest
}) => {
  const roomLocked = useSmartRoomSelector('isLocked', roomId)
  const customButtons = useSelector((state: CantinaState) =>
    getRoomCustomButtons(state, roomId)
  )

  return (
    <>
      {customButtons?.length ? (
        <RoomCustomButtons
          buttons={customButtons ?? []}
          roomLocked={roomLocked}
        />
      ) : (
        <JoinButton
          {...rest}
          disabled={roomLocked}
          label={joinLabel}
          testId={joinTestId}
        />
      )}

      {transferListToggle ? (
        <TransferListButton
          onOpen={() => transferListToggle(true)}
          onClose={() => transferListToggle(false)}
          roomId={roomId}
          testId={TestId.RoomNavigatorButtonTransfer}
        />
      ) : null}
    </>
  )
}
