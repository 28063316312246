import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SlidersIcon } from 'src/common/icons'
import { useDispatchRoomListCommand } from 'src/common/hooks'
import { RoomListCommandAction } from 'src/common/constants'
import { tr, Label } from 'src/common/i18n'
import { isMediaModalVisible } from 'src/common/redux/features/ui/uiSelectors'
import { ButtonWithTooltip } from '../Button/Button'
import { BasicTooltip } from '../Tooltip'
import { RoomSettingsLobby } from '../CallSettings/RoomSettingsLobby'
import { TOOLTIP_OFFSET_Y_ROOM_CARD } from 'src/hoc/withTextTooltip'

const modifiers = [
  {
    name: 'offset',
    options: {
      offset: [0, TOOLTIP_OFFSET_Y_ROOM_CARD],
    },
  },
]

type RoomControlsButtonProps = {
  roomId: string
  roomName: string
}

export const RoomControlsButton: FC<RoomControlsButtonProps> = ({
  roomId,
  roomName,
}) => {
  // When the MediaModal is visible, control the tooltip using `tooltipShown`
  // to keep the RoomSettingsLobby component from dismounting.
  // This prevents the race condition between TooltipContent unsubscribing
  // and MediaModal subscribing to the room information.
  const mediaModalVisible = useSelector(isMediaModalVisible)
  return (
    <BasicTooltip
      tooltipShown={mediaModalVisible ? true : undefined}
      contentAreaClassName='overflow-y-auto rounded-md'
      modifiers={modifiers}
      placement='right'
      tooltip={<TooltipContent roomId={roomId} roomName={roomName} />}
      tooltipClassName={`menu-tooltip max-h-none ${
        mediaModalVisible ? 'hidden' : ''
      }`}
      trigger='click'
    >
      <ButtonWithTooltip
        ariaLabel={tr(Label.ROOM_CONTROLS)}
        className='sw-btn-primary p-1'
        offsetY={TOOLTIP_OFFSET_Y_ROOM_CARD}
      >
        <SlidersIcon fixedWidth />
      </ButtonWithTooltip>
    </BasicTooltip>
  )
}

const TooltipContent: FC<RoomControlsButtonProps> = ({ roomId, roomName }) => {
  const dispatchRoomListCommand = useDispatchRoomListCommand()

  useEffect(() => {
    dispatchRoomListCommand({
      action: RoomListCommandAction.SubscribeToInfo,
      roomId,
    })

    return () => {
      dispatchRoomListCommand({
        action: RoomListCommandAction.UnsubscribeFromInfo,
        roomId,
      })
    }
  }, [dispatchRoomListCommand, roomId])

  return <RoomSettingsLobby roomId={roomId} roomName={roomName} />
}
