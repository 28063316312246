import { RestMethodParams, StringOrNull } from './interfaces'
import { cantinaFetch } from '.'

export type ProfileKeys = keyof Profile
export interface Profile {
  id: string
  cantina_id: string
  name: string
  email: string
  phone_number: StringOrNull
  company_name: StringOrNull
  created_at: string
  updated_at: string
}

export interface UpdateProfileMethodParams extends RestMethodParams {
  params: Partial<Profile>
}

export const updateProfile = ({
  baseUrl,
  token,
  params,
}: UpdateProfileMethodParams): Promise<Profile> => {
  return cantinaFetch(baseUrl, {
    body: JSON.stringify(params),
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}
