import { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LabelIconState } from '../constants'
import { updateSettingsRequest } from '../redux/features'
import { makeSettingsKeySelector } from '../redux/features/settings/settingsSelectors'
import { Settings } from '../rest'

/**
 * This hook is used to dispatch an
 * updateRoomTemplateRequest action for
 * input onBlur/onChange events.
 * It holds the local state to handle the UI feedback like
 * loading or success
 */
export const useSettingsField = (key: keyof Settings) => {
  const dispatch = useDispatch()
  const isFirstRun = useRef(true)
  const [iconState, setIconState] = useState<LabelIconState>(
    LabelIconState.None
  )
  const selector = makeSettingsKeySelector(key)
  const reduxValue = useSelector(selector)

  useEffect(() => {
    if (!isFirstRun.current && iconState === LabelIconState.Loading) {
      setIconState(LabelIconState.Success)
      setTimeout(() => setIconState(LabelIconState.None), 3000)
    }
    isFirstRun.current = false
  }, [key, reduxValue])

  const update = useCallback(
    async (newValue: any) => {
      if (newValue === reduxValue) {
        return console.debug('Prevent auto-save because not changed')
      }

      setIconState(LabelIconState.Loading)
      const params = {
        [key]: newValue,
      }
      dispatch(updateSettingsRequest(params))
    },
    [dispatch, key, reduxValue]
  )

  return { reduxValue, update, iconState }
}
