import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useDispatchRoomCommand } from 'src/common/hooks'
import { RoomCommandAction } from 'src/common/constants'
import { CantinaState } from 'src/common/redux/interfaces'
import { tr, Label } from 'src/common/i18n'
import { getReservationId } from 'src/common/redux/features/participants/participantSelectors'
import { LayoutRolesDropdown } from 'src/components/CallSettings/LayoutRolesDropdown'

interface ParticipantLayoutRoleDropdownProps {
  participantId: string
  roomId: string
  className?: string
}

/**
 * Here we used "useDispatchRoomCommand" instead of "useDispatchRoomParticipantCommand"
 * because the child component requires roomId so it's better to compose the action
 * with roomId and participantId.
 * */
export const ParticipantLayoutRoleDropdown: FC<ParticipantLayoutRoleDropdownProps> =
  ({ roomId, participantId, ...rest }) => {
    const dispatchRoomCommand = useDispatchRoomCommand()
    const reservationId = useSelector((state: CantinaState) =>
      getReservationId(state, participantId)
    )

    return (
      <LayoutRolesDropdown
        roomId={roomId}
        name='layoutRoles'
        label={tr(Label.LAYOUT_ROLE)}
        className='py-1'
        onChange={({ target }) => {
          dispatchRoomCommand({
            roomId,
            action: RoomCommandAction.SetLayoutRole,
            participantId,
            reservationId: target.value,
          })
        }}
        selectedId={reservationId || ''}
        {...rest}
      />
    )
  }
