import React, { FC } from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { callHangup } from 'src/common/redux/features'
import { getActiveCallRoomId } from 'src/common/redux/features/calls/callSelectors'
import { withActiveCall } from 'src/common/hoc'
import { Label } from 'src/common/i18n'
import { Scope } from 'src/common/constants'
import { TestId } from 'src/constants'
import { ScopedKickAllButton } from 'src/components/CallSettings/KickAllButton'
import { SecondaryButton } from 'src/components/Button/Button'
import { ButtonWithCaret } from 'src/components/Button/ButtonWithCaret'
import { pipHangupHandler } from '../CallSettings/PictureInPictureButton'

const LeaveRoomButton: FC = withActiveCall(({ callId }) => {
  const dispatch = useDispatch()
  const roomId = useSelector(getActiveCallRoomId)

  const clickHandler = () => {
    pipHangupHandler()
    dispatch(callHangup(callId))
  }

  return (
    <ButtonWithCaret
      buttonTestId={TestId.BottomBarButtonHangUp}
      ButtonType={SecondaryButton}
      caretScope={Scope.ConferenceKick}
      caretTestId={TestId.BottomBarButtonHangUpCaret}
      clickHandler={clickHandler}
      label={Label.LEAVE_ROOM}
      // @ts-ignore
      tooltipContent={<ScopedKickAllButton roomId={roomId} transparent />}
    />
  )
})

export const InCallLeaveRoomButton: FC =
  compose(withActiveCall)(LeaveRoomButton)
