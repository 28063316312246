import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { getActiveCallRoomId } from '../redux/features/calls/callSelectors'
import { hasChatDisabled } from '../redux/features/rooms/roomsSelectors'
import { CantinaState } from '../redux/interfaces'

export const withDisableChatGuard = <T extends object>(
  WrappedComponent: ComponentType<T>
) => {
  return (props: T) => {
    const roomId = useSelector(getActiveCallRoomId)
    const chatDisabled = useSelector((state: CantinaState) =>
      hasChatDisabled(state, roomId)
    )
    if (
      process.env.REACT_APP_HYBRID_MODE === '1' &&
      (!roomId || chatDisabled)
    ) {
      // check for roomId to wait until the user joined the room.
      // - it's the case where guests see an intro video before joining the room
      return null
    }
    return <WrappedComponent {...props} />
  }
}
