import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  getActiveCallId,
  isCallDirect,
} from 'src/common/redux/features/calls/callSelectors'
import { callHangup } from 'src/common/redux/features'
import { CantinaState } from 'src/common/redux/interfaces'
import { callLoaderEndAction } from 'src/common/redux/actions'
import { tr, Label } from 'src/common/i18n'
import { RoutePath } from 'src/common/constants'
import { useDecodedParams } from 'src/effects/useDecodedParams'
import { CenteredBox } from 'src/layout'
import SimpleHeader from 'src/components/Header/SimpleHeader'

type CallLoadingProps = {}
const CallLoading: FC<CallLoadingProps> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const callId = useSelector(getActiveCallId)
  const { room } = useDecodedParams<{ room: string }>()
  const isDirect = useSelector((state: CantinaState) =>
    isCallDirect(state, callId)
  )
  const title =
    isDirect || (room && room.charAt(0) === '@')
      ? tr(Label.DIALING)
      : tr(Label.CONFERENCE_ROOM)
  const hangupHandler = () => {
    dispatch(callHangup(callId))
    dispatch(callLoaderEndAction())
    history.push(RoutePath.Home)
  }

  return (
    <>
      <SimpleHeader />
      <CenteredBox>
        <div className='text-center my-10'>
          <div className='text-contrast-m mt-2 text-xl font-medium'>
            {title}
            <p className='text-3xl leading-9 text-contrast-h p-2 my-2 font-bold font-neue'>
              {room}
            </p>
          </div>
          <div className='text-contrast-m m-auto mb-10'>
            {tr(Label.CONNECTING)}...
          </div>

          <button
            className='sw-btn w-2/3 bg-sw-red border-sw-red text-white'
            onClick={hangupHandler}
          >
            {tr(Label.CANCEL)}
          </button>
        </div>
      </CenteredBox>
    </>
  )
}

export default CallLoading
