import sanitizeHtml from 'sanitize-html'
import { v4 as uuidv4 } from 'uuid'
import generateMd5 from 'md5'
import { decode as Base64Decode } from 'js-base64'
import { CURRENT_HOSTNAME, RoutePath } from '../constants'
import { Room } from '../redux/interfaces'

const development =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

export const isDev = () => development
export const isSimulateModeEnabled = () =>
  development && process.env.REACT_APP_SIMULATE_MODE === '1'

export const getJWTExpiresIn = () => {
  if (development) {
    return 72000
  }
}

export const capitalize = (str = '') =>
  str.charAt(0).toUpperCase() + str.slice(1)
export const stripSpaces = (str = '') => str.replace(/\s+/g, '')
export const stripFlags = (str = '') => str.replace(/;.*/, '').trim()
export const cleanFilter = (str = '') => stripFlags(str).toLowerCase()

const buttonParsePattern = /([^:]*):(.*)/
export function parseJoinButtons(inputString: string) {
  if (!inputString) return []
  const lines = inputString.split('\n')
  const buttons = lines.map((row) => {
    const btnMatch = row.match(buttonParsePattern)
    if (btnMatch && btnMatch[1] && btnMatch[2]) {
      return { text: btnMatch[1], url: btnMatch[2] }
    }
    return null
  })
  return buttons.filter(Boolean)
}

export const htmlClean = (htmlString: string) => {
  /**
   * @see https://github.com/apostrophecms/sanitize-html/issues/547
   */
  return sanitizeHtml(htmlString, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
      'img',
      'center',
      'iframe',
      'video',
      'source',
    ]),
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      img: ['src', 'style', 'width', 'height'],
      iframe: ['src', 'style', 'width', 'height'],
      video: [
        'src',
        'style',
        'width',
        'height',
        'controls',
        'muted',
        'loop',
        'playsinline',
        'autoplay',
        'poster',
      ],
      source: ['src', 'style', 'type'],
      '*': ['style'],
    },
  })
}

export const htmlStrip = (htmlString: string) => {
  return sanitizeHtml(htmlString, {
    allowedTags: [],
    allowedAttributes: {},
  })
}

/**
 * Match input like
 * {full-screen=true}https://example.com
 * or
 * https://example.com
 */
export const matchURLFlags = (input: string) => {
  const match = input.match(/^({[^}]+})?(.*)/)
  if (!match) {
    return {
      flags: '',
      value: '',
    }
  }
  return {
    flags: match[1] || '',
    value: match[2] || '',
  }
}

const HTTP_OR_HTTPS_PATTERN = /^(?:http|https):\/\//
export const checkValidHttpURL = (url: string) => {
  try {
    const check = new URL(url)
    return HTTP_OR_HTTPS_PATTERN.test(url)
  } catch {
    return false
  }
}

const HTTPS_PATTERN = /^(?:https):\/\//
export const checkValidHttpsURL = (url: string) => {
  try {
    const check = new URL(url)
    return HTTPS_PATTERN.test(url)
  } catch {
    return false
  }
}

const RTMP_PATTERN = /^(?:rtmp|rtmps|rtsp|srt|istream):\/\//
export const checkValidRtmpURL = (url: string) => {
  try {
    const check = new URL(url)
    return RTMP_PATTERN.test(url)
  } catch {
    return false
  }
}

const LOCAL_STREAM_PATTERN = /^(?:local_stream):\/\//
export const checkValidLocalStreamURL = (url: string) => {
  return LOCAL_STREAM_PATTERN.test(url)
}

export const checkValidPlaybackURL = (url: string) => {
  return (
    checkValidHttpURL(url) ||
    checkValidRtmpURL(url) ||
    checkValidLocalStreamURL(url)
  )
}

export const getTimezoneData = () => {
  const tzData: { timezone?: string; gmtOffset?: number; tzString?: string } =
    {}
  try {
    tzData.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const date = new Date()
    tzData.gmtOffset = date.getTimezoneOffset() / -60
    tzData.tzString = date.toString().substr(25)
  } catch (error) {
    console.warn('Error building TZ data', error)
  }
  return tzData
}

export const gravatarUrl = (email: string, name: string) => {
  const SIZE = 128
  const gravatarUrl = 'https://www.gravatar.com/avatar/'
  const initialsUrl = `https://eu.ui-avatars.com/api/${encodeURIComponent(
    name
  )}/${SIZE}`

  switch (true) {
    case Boolean(email && name):
      // Return gravatar with fallback to initials
      return `${gravatarUrl}${generateMd5(
        email
      )}?s=${SIZE}&d=${encodeURIComponent(initialsUrl)}`
    case Boolean(!email && name):
      // Return initials
      return initialsUrl
    case Boolean(email && !name):
      // Return gravatar with fallback to "mystery person" icon
      return `${gravatarUrl}${generateMd5(email)}?s=${SIZE}&d=mp`
    default:
      // Return "mystery person" icon
      return `${gravatarUrl}?s=${SIZE}&d=mp`
  }
}

export const getParticipantCountLabel = (count?: number) => {
  if (!count) {
    return ''
  }
  return count > 1 ? `${count} Participants` : '1 Participant'
}

export const requestAudioPermissions = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    stream.getTracks().forEach((track) => track.stop())
    return true
  } catch (error) {
    console.error('requestAudioPermissions', error)
    return false
  }
}

export const requestVideoPermissions = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true })
    stream.getTracks().forEach((track) => track.stop())
    return true
  } catch (error) {
    console.error('requestVideoPermissions', error)
    return false
  }
}

const LOCALE_DATE_STRING_OPTIONS = {
  weekday: 'short' as const,
  month: 'short' as const,
  day: 'numeric' as const,
}
const LOCALE_TIME_STRING_OPTIONS = {
  hour: 'numeric' as const,
  minute: 'numeric' as const,
}
export const timestampToFormatDate = (timestamp: number) => {
  try {
    const date = new Date(timestamp)
    const formattedDate = date.toLocaleDateString(
      undefined,
      LOCALE_DATE_STRING_OPTIONS
    )
    const formattedTime = date.toLocaleTimeString(
      undefined,
      LOCALE_TIME_STRING_OPTIONS
    )
    return `${formattedDate} - ${formattedTime}`
  } catch (error) {
    console.error('timestampToFormatDate', error)
    return ''
  }
}

/**
 * FS has a range of 1 - 4 with a 0.25 increment steps for motionQuality
 * while the UI sliders increment/decrement the value by 1.
 * This fn translates the 1-4 value into the slider scale.
 */
export const motionQualityToSliderValue = (quality: number) => {
  return quality / 0.25
}

/**
 * - Inverse of motionQualityToSliderValue -
 * FS has a range of 1 - 4 with a 0.25 increment steps for motionQuality
 * while the UI sliders increment/decrement the value by 1.
 * This fn translates the slider value into the 1-4 value for FS.
 * FS wants the value as a string.
 */
export const motionQualityToFSValue = (quality: number) => {
  return String(quality * 0.25)
}

export const roomInvitationURL = (room: string) => {
  // TODO: react-native has no "window" object. Move to a proxy
  const path = RoutePath.Room.replace(':room', fixedEncodeURIComponent(room))

  return `${window.location.origin}${path}`
}

/**
 * Additionally encode characters `!'()*` required for RFC 3986
 */
export const fixedEncodeURIComponent = (str: string) => {
  return encodeURIComponent(str).replace(/[!'()*.]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16)
  })
}

export const lazyLoadHandler = (lazyComponent: any, attemptsLeft = 3) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            return reject(error)
          }
          lazyLoadHandler(lazyComponent, attemptsLeft - 1).then(resolve, reject)
        }, 500)
      })
  })
}

const makeSortByPropFn = <T extends string>(prop: T) => {
  return (a: { [k in T]?: string }, b: { [k in T]?: string }) => {
    const aName = a?.[prop]?.toLowerCase()
    const bName = b?.[prop]?.toLowerCase()
    if (aName && bName) {
      if (aName < bName) {
        return -1
      } else if (aName > bName) {
        return 1
      }
    }
    return 0
  }
}

/**
 * Method to sort an array of objects with a "name" as prop
 * @param a Object with a property "name"
 * @param b Object with a property "name"
 */
export const sortByNameProperty = makeSortByPropFn('name')

/**
 * Method to sort an array of objects with a "description" as prop
 * @param a Object with a property "description"
 * @param b Object with a property "description"
 */
export const sortByDescriptionProperty = makeSortByPropFn('description')

/**
 * Method to sort rooms by "orderPriority" prop
 * @param a Object with a property "orderPriority"
 * @param b Object with a property "orderPriority"
 */
export const sortByOrderPriorityProperty = (
  a: { orderPriority?: number },
  b: { orderPriority?: number }
) => {
  const aOrder = a?.orderPriority
  const bOrder = b?.orderPriority
  if (typeof aOrder === 'number' && typeof bOrder === 'number') {
    // Compare if both are number
    if (aOrder > bOrder) {
      return 1
    } else if (aOrder < bOrder) {
      return -1
    }
  }

  if (typeof aOrder === 'number' && typeof bOrder !== 'number') {
    // If orderPriority for the second room is not a number,
    // always put the first on top
    return -1
  } else if (typeof aOrder !== 'number' && typeof bOrder === 'number') {
    // If orderPriority for the first room is not a number,
    // always put the second on top
    return 1
  }
  return 0
}

export const sortFilteredRooms = (room1: Room, room2: Room, filter: string) => {
  const cleanedFilter = cleanFilter(filter)
  const matchWordStart = new RegExp(`\\b${cleanedFilter}`)

  const name1 = room1.name?.toLowerCase() || ''
  const name2 = room2.name?.toLowerCase() || ''

  // First, group by names starting with the filter string
  if (name1.startsWith(cleanedFilter)) {
    if (name2.startsWith(cleanedFilter)) {
      // Alphabetize group
      return name1 < name2 ? -1 : 1
    }
    return -1
  }
  if (name2.startsWith(cleanedFilter)) {
    return 1
  }

  // Next, group by names containing a word that starts with the filter string
  if (matchWordStart.test(name1)) {
    if (matchWordStart.test(name2)) {
      // Alphabetize group
      return name1 < name2 ? -1 : 1
    }
    return -1
  }
  if (matchWordStart.test(name2)) {
    return 1
  }

  // Alphabetize remaining names
  return name1 < name2 ? -1 : 1
}

/**
 * Used to force memberID to be a string
 * since FS is using numeric ids.
 * TODO: remove this when backend returns uuid instead
 */
export const forceIdToString = (id: unknown) => {
  return `${id}`
}

/**
 * Generates a unique ID. If `prefix` is given, the ID is appended to it.
 * @param {string} [prefix=''] The value to prefix the ID with.
 * @returns {string} Returns the unique ID.
 */
export const uniqueId = (prefix = '') => {
  return `${prefix && `${prefix}-`}${uuidv4()}`
}

export const IMG_REGEX = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|svg)/
export const extractImageURL = (input: string) => {
  const result = IMG_REGEX.exec(input)
  return result && result[0]
}

export const staticRoomImageUrl = (name: string) => {
  return `/promo?fqdn=${CURRENT_HOSTNAME}&room=${fixedEncodeURIComponent(name)}`
  // return `/og/index.cgi?room=${fixedEncodeURIComponent(name)}`
}

export const truncate = (text: string, length: number, ellipsis = false) => {
  const strLen = text.length
  const addEllipsis = ellipsis && length > 3 && length < strLen
  const subStrLen = addEllipsis ? length - 3 : length

  if (subStrLen <= 0 || strLen <= subStrLen) {
    return text
  }
  return `${text.substring(0, subStrLen)}${addEllipsis ? '...' : ''}`
}

export const genMd5 = (str: string) => generateMd5(str)

export const parseJWT = (token: string) => {
  try {
    const base64Payload = token.split('.')[1]
    const payload = Base64Decode(base64Payload)
    return JSON.parse(payload.toString())
  } catch (error) {
    console.error('parseJWT', error)
    return null
  }
}

/**
 * CantinaMgr uses UUID as member ID
 * FS uses INT as member ID
 * We conver participantId (= string) to be a Number
 * and check for isNaN.
 * FIXME: we'll remove this hack as soon as we deal
 * with data only from cloud (dropping direct FS connections)
 */
export const isParticipantIdFromFS = (participantId: string) => {
  return !Number.isNaN(Number(participantId))
}
