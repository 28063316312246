import { LoadingState } from '../../interfaces'
import { PayloadAction } from '@reduxjs/toolkit'

export const initialLoadingState: LoadingState = Object.freeze({})

const PATTERN = /(.*)(Request|Success|Failure|Begin|End)$/
const truthyStates = ['Request', 'Begin']

// Automatically add the loading status to this slice if an action has one
// of the suffixes in PATTERN
//
// For example:
//   'clipeeze/loadClipeezeRequest' will add a loading state of true
//   'clipeeze/loadClipeezeSuccess' will change the loading state to false

export const loadingReducer = (
  state = initialLoadingState,
  action: PayloadAction
): LoadingState => {
  const matches = PATTERN.exec(action.type)
  if (!matches) {
    return state
  }

  const [, requestName, requestState] = matches
  return {
    ...state,
    [requestName]: truthyStates.includes(requestState),
  }
}
