import { useState, useEffect } from 'react'
import { RailsEndpoint, RequestState, User } from '../rest'
import { useInternalAPI } from './useInternalAPI'

type UseFetchUserData = {
  user: User | null
  requestState: RequestState
  error: string | null
}
const DEFAULT_DATA: UseFetchUserData = {
  user: null,
  requestState: RequestState.Loading,
  error: null,
}

const fetchOptions = {
  endpoint: RailsEndpoint.Users,
  method: 'GET',
}

export const useFetchUser = (userId: string) => {
  const { invokeAPI: fetchHandler } = useInternalAPI(fetchOptions)
  const [data, setData] = useState<UseFetchUserData>(DEFAULT_DATA)

  const refresh = async () => {
    try {
      setData(DEFAULT_DATA)
      const user = await fetchHandler({
        urlTransform: (url) => `${url}/${userId}`,
      })
      setData({
        user,
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      setData({
        user: null,
        requestState: RequestState.Error,
        error: 'Error loading user',
      })
    }
  }

  useEffect(() => {
    refresh()
  }, [])

  return { ...data, refresh }
}
