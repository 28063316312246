import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withActiveCall } from 'src/common/hoc'
import { promptViewActions } from 'src/common/redux/views'
import { tr, Label } from 'src/common/i18n'
import { SpinnerIcon } from 'src/common/icons'
import { callDTMF, callHangup } from 'src/common/redux/features'
import {
  hasError,
  isVisible,
} from 'src/common/redux/views/promptView/promptViewSelectors'
import { TestId } from 'src/constants'
import { Modal } from './Modal'
import { PINInputGroup } from '../components/InputGroup/PINInputGroup'

export const PINModal: FC = withActiveCall(({ callId }) => {
  const dispatch = useDispatch()
  const show = useSelector(isVisible)
  const cancelHandler = () => {
    dispatch(promptViewActions.hide())
    dispatch(callHangup(callId))
  }

  return (
    <Modal
      cancelHandler={cancelHandler}
      cancelLabel={tr(Label.LEAVE_ROOM)}
      containerClassName='small'
      show={show as boolean}
      showCloseButton={false}
      showConfirmButton={false}
      testId={TestId.PINModal}
      title={tr(Label.PIN_REQUIRED)}
    >
      <PINInput callId={callId} />
    </Modal>
  )
})

const PINInput: FC<{ callId: string }> = ({ callId }) => {
  const dispatch = useDispatch()
  const pinFailed = useSelector(hasError)
  const [isLoading, setIsLoading] = useState(false)
  const submitHandler = (pin: string) => {
    dispatch(callDTMF(callId, pin))
    setIsLoading(true)
  }

  useEffect(() => {
    if (pinFailed) {
      setIsLoading(false)
    }
  }, [pinFailed])

  return (
    <div className={`h-24 ${isLoading ? 'flex items-center' : ''}`}>
      {isLoading ? (
        <div>
          <SpinnerIcon className='mr-2' />
          {`${tr(Label.CONNECTING)}...`}
        </div>
      ) : (
        <PINInputGroup
          addFocus
          label={Label.ROOM_PIN}
          name='pin'
          pinError={pinFailed as boolean}
          testId={TestId.PINInputAccessParticipant}
          updateFunction={submitHandler}
        />
      )}
    </div>
  )
}
