import screenfull from 'screenfull'

export const enterFullScreen = (selector: string): any => {
  if (!screenfull.isEnabled) {
    return
  }
  try {
    // @ts-expect-error
    screenfull.request(document.querySelector(selector))
  } catch (error) {
    console.error('Error entering fullscreen', error)
  }
}

export const exitFullScreen = (): any => {
  if (!screenfull.isEnabled || !screenfull.isFullscreen) {
    return
  }
  try {
    screenfull.exit()
  } catch (error) {
    console.error('Error closing fullscreen', error)
  }
}
