import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { CloseIcon } from 'src/common/icons'
import { TestId } from 'src/constants'
import { tr, Label } from 'src/common/i18n'

type DrawerHeaderProps = {
  closeActionCreator?: any
  testId: TestId
  title: string
}

const DrawerHeader: FC<DrawerHeaderProps> = ({
  closeActionCreator,
  testId,
  title,
}) => {
  const dispatch = useDispatch()

  const onCloseDrawer = () => {
    dispatch(closeActionCreator())
  }

  return (
    <div className='drawer-header block-highlight'>
      <div className='flex items-center justify-between text-base'>
        <span className='text-base font-semibold p-2' data-test={testId}>
          {title}
        </span>
        <button
          aria-label={tr(Label.CLOSE)}
          className='sw-btn-close mr-2'
          data-test={TestId.DrawerButtonClose}
          onClick={onCloseDrawer}
        >
          <CloseIcon size='lg' />
        </button>
      </div>
    </div>
  )
}

export default DrawerHeader
