export enum RoomCommandAction {
  AudioMuteAll = 'AudioMuteAll',
  AudioMuteParticipant = 'AudioMuteParticipant',
  AudioUnMuteAll = 'AudioUnMuteAll',
  AudioUnMuteParticipant = 'AudioUnMuteParticipant',
  VideoMuteAll = 'VideoMuteAll',
  VideoMuteParticipant = 'VideoMuteParticipant',
  VideoUnMuteAll = 'VideoUnMuteAll',
  VideoUnMuteParticipant = 'VideoUnMuteParticipant',
  DeafParticipant = 'DeafParticipant',
  UndeafParticipant = 'UndeafParticipant',
  ShowBanners = 'ShowBanners',
  HideBanners = 'HideBanners',
  KickParticipant = 'KickParticipant',
  KickAll = 'KickAll',
  LiveArrayBootstrap = 'LiveArrayBootstrap',
  Lock = 'Lock',
  UnLock = 'UnLock',
  EnableClipeeze = 'EnableClipeeze',
  DisableClipeeze = 'DisableClipeeze',
  EnableSpeakerHighlight = 'EnableSpeakerHighlight',
  DisableSpeakerHighlight = 'DisableSpeakerHighlight',
  EnableIntercom = 'EnableIntercom',
  DisableIntercom = 'DisableIntercom',
  EnableSilentMode = 'EnableSilentMode',
  DisableSilentMode = 'DisableSilentMode',
  EnableBlindMode = 'EnableBlindMode',
  DisableBlindMode = 'DisableBlindMode',
  EnableMeetingMode = 'EnableMeetingMode',
  DisableMeetingMode = 'DisableMeetingMode',
  EnableHideVMutedMode = 'EnableHideVMutedMode',
  DisableHideVMutedMode = 'DisableHideVMutedMode',
  SetPin = 'SetPin',
  UnsetPin = 'UnsetPin',
  SetModeratorPin = 'SetModeratorPin',
  UnsetModeratorPin = 'UnsetModeratorPin',
  SetCanvasLayout = 'SetCanvasLayout',
  SetVideoQuality = 'SetVideoQuality',
  SetMotionQuality = 'SetMotionQuality',
  SetMotionQualityInbound = 'SetMotionQualityInbound',
  SetMotionQualityMember = 'SetMotionQualityMember',
  SetPlaybackRole = 'SetPlaybackRole',
  SetFilePlaybackRole = 'SetFilePlaybackRole',
  StartPlayback = 'StartPlayback',
  SeekPlayback = 'SeekPlayback',
  PausePlayback = 'PausePlayback',
  SetPlaybackVolume = 'SetPlaybackVolume',
  StopPlayback = 'StopPlayback',
  SendTTS = 'SendTTS',
  SendMemberTTS = 'SendMemberTTS',
  SetVolumeAudience = 'SetVolumeAudience',
  StartRemoteBroadcast = 'StartRemoteBroadcast',
  StartLocalBroadcast = 'StartLocalBroadcast',
  StartFileRecording = 'StartFileRecording',
  StopRecording = 'StopRecording',
  EnableNoiseBlocker = 'EnableNoiseBlocker',
  DisableNoiseBlocker = 'DisableNoiseBlocker',
  EnableLowBitrateMode = 'EnableLowBitrateMode',
  DisableLowBitrateMode = 'DisableLowBitrateMode',
  RaiseHand = 'RaiseHand',
  LowerHand = 'LowerHand',
  LowerAllHands = 'LowerAllHands',
  SetBannerText = 'SetBannerText',
  RestoreBannerText = 'RestoreBannerText',
  AddToCall = 'AddToCall',
  SetPerformerDelay = 'SetPerformerDelay',
  GrantModerator = 'GrantModerator',
  RevokeModerator = 'RevokeModerator',
  GrantScreenShare = 'GrantScreenShare',
  RevokeScreenShare = 'RevokeScreenShare',
  Transfer = 'Transfer',
  RoomNavigatorTransfer = 'RoomNavigatorTransfer',
  MemberEnergy = 'MemberEnergy',
  MemberVolume = 'MemberVolume',
  MemberGain = 'MemberGain',
  MemberHideBanner = 'MemberHideBanner',
  MemberShowBanner = 'MemberShowBanner',
  SetVideoFloor = 'SetVideoFloor',
  SetLayoutRole = 'SetLayoutRole',
  SetVidFloorResId = 'SetVidFloorResId',
  ShuffleVideo = 'ShuffleVideo',
  EnableEchoCancellation = 'EnableEchoCancellation',
  DisableEchoCancellation = 'DisableEchoCancellation',
  EnableNoiseSuppression = 'EnableNoiseSuppression',
  DisableNoiseSuppression = 'DisableNoiseSuppression',
  EnableAutoGainControl = 'EnableAutoGainControl',
  DisableAutoGainControl = 'DisableAutoGainControl',
  AllowHandraiseOnscreen = 'AllowHandraiseOnscreen',
  DisallowHandraiseOnscreen = 'DisallowHandraiseOnscreen',
  PlayClipeeze = 'PlayClipeeze',

  // Zone/Sidebars Actions
  ZoneAllowToJoin = 'ZoneAllowToJoin',
  ZoneCreate = 'ZoneCreate',
  ZoneDenyToJoin = 'ZoneDenyToJoin',
  ZoneDestroy = 'ZoneDestroy',
  ZoneInvite = 'ZoneInvite',
  ZoneInvitationDeclined = 'ZoneInvitationDeclined',
  ZoneJoin = 'ZoneJoin',
  ZoneKick = 'ZoneKick',
  ZonePart = 'ZonePart', // leave the zone
  ZoneRequestToJoin = 'ZoneRequestToJoin',
  ZoneSetExternalVolume = 'ZoneSetExternalVolume',
}
