import { EventChannel, SagaIterator } from 'redux-saga'
import { take, call, put, all, fork, select, delay } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import * as Relay from '../../../services/relay'
import * as Rest from '../../../rest'
import {
  checkCamera,
  checkMicrophone,
  checkSpeaker,
} from '../../../services/checkDevices'
import {
  MediaDevice,
  MixPanelEvent,
  CallState,
  Scope,
  RoomCommandAction,
} from '../../../constants'
import {
  purgeAction,
  mixPanelTrackAction,
  callLoaderEndAction,
} from '../../actions'
import { authActions, callActions, deviceActions } from '..'
import {
  getDevices,
  getCameraId,
  getMicrophoneId,
} from '../device/deviceSelectors'
import {
  getActiveCallRoomId,
  getCallStartedAt,
  getMyParticipantId,
} from '../calls/callSelectors'
import { roomsActions, roomCommand } from '../rooms/roomsSlice'
import { hasPodcastMode, hasChatOneWay } from '../rooms/roomsSelectors'
import { hasScope } from '../scopes/scopesSelectors'
import { isModerator } from '../participants/participantSelectors'
import { callViewActions } from '../../views'
import { messageActions } from '../messages/messageSlice'
import { hasRoleGuest } from '../auth/authSelectors'
import { checkChatDisabled } from '../chats/chatSaga'
import { getCantinaBackendAPIEndpoint } from '../settings/settingsSelectors'
import { Endpoint } from '../../../rest'

export function* setupRelay(appConfig: any): SagaIterator {
  try {
    const sagaChannel = yield call(Relay.createSagaChannel, appConfig)
    yield call(checkClientDevices)
    // prettier-ignore
    yield all([
      fork(watchRelayActions, sagaChannel),
      fork(watchLogout, sagaChannel),
      call(Relay.relayConnect),
    ])
  } catch (error) {
    yield put(purgeAction())
  }
}

// @ts-ignore
// TODO: fix <unknown>
export function* watchLogout(channel: EventChannel<unknown>): SagaIterator {
  const { type } = yield take([authActions.logout.type, purgeAction.type])
  channel.close()
  const url = yield select(getCantinaBackendAPIEndpoint, Endpoint.Logout)
  yield call(Rest.logout, url)
  if (type !== purgeAction.type) {
    yield put(purgeAction())
  }
  yield call(Relay.afterLogoutCallback)
}

export function* relayWorker(action: PayloadAction<any>): SagaIterator {
  switch (action.type) {
    case callActions.update.type: {
      yield put(action)
      if ([CallState.Recovering].includes(action.payload.state)) {
        const relayCall = yield call(Relay.getRelayCall, action.payload.id)
        const microphoneId = yield select(getMicrophoneId)
        if (microphoneId === MediaDevice.None) {
          relayCall.options.negotiateAudio = true
          relayCall.options.audio = false
          relayCall.options.micId = null
          relayCall.options.micLabel = null
        }
        const cameraId = yield select(getCameraId)
        if (cameraId === MediaDevice.None) {
          relayCall.options.negotiateVideo = relayCall.options.video !== false
          relayCall.options.video = false
          relayCall.options.camId = null
          relayCall.options.camLabel = null
        }
        relayCall.options.experimental = true
        relayCall.options.autoApplyMediaParams = false
        relayCall.answer()
      }
      if ([CallState.Active].includes(action.payload.state)) {
        yield delay(500)
        yield put(callLoaderEndAction())
      }
      if (action.payload.roomId) {
        const podcastMode = yield select(hasPodcastMode, action.payload.roomId)
        if (podcastMode) {
          yield call(Relay.listVideoLayouts, action.payload.id)
        }
      }
      break
    }
    case callActions.destroy.type: {
      const startedAt = yield select(getCallStartedAt, action.payload.id)
      const now = Date.now()

      yield put(
        mixPanelTrackAction({
          event: MixPanelEvent.CallFinished,
          params: {
            // Trying to fix a (possible) race condition
            // where a Call is destroyed before Redux recorded the "startedAt"
            // Of course this is an invalid/failed Call for some reason.
            // We keep track of it on mixPanel with callDuration: 0
            callDuration: now - (startedAt || now),
            roomName: action.payload.extension || '',
          },
        })
      )

      yield put(action)
      break
    }
    case roomsActions.createOrUpdate.type: {
      const roomId = action.payload.id
      const activeCallRoomId = yield select(getActiveCallRoomId)
      const isMyRoom = activeCallRoomId && activeCallRoomId === roomId

      if (isMyRoom) {
        const { publicClipeeze, messages = [] } = action.payload
        const hasClipeezeScope = yield select(
          hasScope,
          Scope.ConferenceEnableClipeeze
        )
        if (!hasClipeezeScope && publicClipeeze === false) {
          yield put(callViewActions.closeClipeeze())
        }
        messages.forEach((message: string) => Relay.toastError(message))
        // this hack is required because the user can start a remote broadcast + local recording.
        // if the remote broadcast fails for some reason, the App stops ALL the other recordings.
        if (messages.includes('Recording Error')) {
          yield put(
            roomCommand({ roomId, action: RoomCommandAction.StopRecording })
          )
        }
      }
      yield put(action)
      break
    }
    case messageActions.add.type: {
      const chatDisabled = yield call(checkChatDisabled)
      if (chatDisabled) {
        break
      }

      /**
       * OneWayChat means that guest doesn't get messages from other guests.
       * Guests can send to moderators
       * Moderators can send to all guests
       */
      let oneWayChat = false
      if (process.env.REACT_APP_HYBRID_MODE === '1') {
        const roomId = yield select(getActiveCallRoomId)
        oneWayChat = yield select(hasChatOneWay, roomId)
      }
      if (oneWayChat) {
        const myParticipantId = yield select(getMyParticipantId)
        const isAuthor = myParticipantId === action.payload.participantId
        if (!isAuthor) {
          const authorIsModerator = yield select(
            isModerator,
            action.payload.participantId
          )
          const iAmModerator = yield select(isModerator, myParticipantId)
          if (!iAmModerator && !authorIsModerator) {
            break
          }
        }
      }

      yield put(action)
      break
    }
    default:
      yield put(action)
      break
  }
}

export function* watchRelayActions(
  // @ts-ignore
  // TODO: fix <unknown>
  channel: EventChannel<unknown>
): SagaIterator {
  while (true) {
    try {
      while (true) {
        const action = yield take(channel)
        yield fork(relayWorker, action)
      }
    } catch (error) {
      console.error('watchRelayActions error:', error)
    } finally {
      console.warn('watchRelayActions finally')
    }
  }
}

export function* checkClientDevices(): SagaIterator {
  try {
    const defaultDevices = [MediaDevice.Default, MediaDevice.None]
    const {
      cameraId,
      cameraLabel,
      microphoneId,
      microphoneLabel,
      speakerId,
      speakerLabel,
    } = yield select(getDevices)
    if (!defaultDevices.includes(cameraId)) {
      const newDevice = yield call(checkCamera, cameraId, cameraLabel)
      if (newDevice && !defaultDevices.includes(newDevice.deviceId)) {
        yield put(deviceActions.cameraChanged(newDevice))
      }
    }
    if (!defaultDevices.includes(microphoneId)) {
      const newDevice = yield call(
        checkMicrophone,
        microphoneId,
        microphoneLabel
      )
      if (newDevice && !defaultDevices.includes(newDevice.deviceId)) {
        yield put(deviceActions.microphoneChanged(newDevice))
      }
    }
    if (!defaultDevices.includes(speakerId)) {
      const newDevice = yield call(checkSpeaker, speakerId, speakerLabel)
      if (newDevice && !defaultDevices.includes(newDevice.deviceId)) {
        yield put(deviceActions.speakerChanged(newDevice))
      }
    }
    const newDevices = yield select(getDevices)
    yield call(Relay.setClientDefaultMediaConstraints, newDevices)
  } catch (error) {
    console.error('checkClientDevices error:', error)
  }
}
