import React, { FC } from 'react'
import { tr, Label } from 'src/common/i18n'
import { UseFetchRoomConsumerProps } from 'src/common/hooks'
import { RoomKeys } from 'src/common/rest'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { useRoomAutoSave } from '../../../effects/useRoomAutoSave'

export const RoomInputGroup: FC<
  UseFetchRoomConsumerProps & {
    defaultValue: string | null
    hint?: Label
    label: Label
    maxLength?: number
    name: RoomKeys
    required?: boolean
    showCounter?: boolean
    type?: string
  }
> = ({
  defaultValue,
  getErrorByName,
  hint,
  label,
  maxLength,
  name,
  required,
  type,
  update,
}) => {
  const { blurHandler, changeHandler, currentValue, iconState } =
    useRoomAutoSave({
      defaultValue,
      required,
      updateRoomHandler: update,
    })

  return (
    <InputGroup
      error={getErrorByName(name)}
      hint={hint && tr(hint)}
      label={tr(label)}
      labelIconState={iconState}
      maxLength={maxLength}
      name={name}
      onBlur={blurHandler}
      onChange={changeHandler}
      required={required}
      showCounter
      type={type}
      value={currentValue as string}
    />
  )
}
