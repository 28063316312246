import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  RoomParticipantCommandPayload,
  CantinaState,
} from '../redux/interfaces'
import { roomCommand } from '../redux/features'
import { getParticipantRoomId } from '../redux/features/participants/participantSelectors'

/**
 * This custom hooks encapsulates the logic to select the roomId using the participantId
 * It takes "participantId" and the "RoomCommandAction" to dispatch and select the roomId
 * from the store to create the correct RoomCommandPayload action.
 */
export const useDispatchRoomParticipantCommand = (participantId: string) => {
  const dispatch = useDispatch()
  const roomId = useSelector((state: CantinaState) =>
    getParticipantRoomId(state, participantId)
  )

  const dispatchRoomParticipantCommand: (
    params: RoomParticipantCommandPayload
  ) => void = useCallback(
    (params: RoomParticipantCommandPayload) => {
      if (!roomId) {
        return console.warn('Unknown room by participantId', params)
      }
      dispatch(roomCommand({ ...params, roomId }))
    },
    [dispatch, roomId]
  )

  return dispatchRoomParticipantCommand
}
