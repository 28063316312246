import React, { useEffect, useRef } from 'react'
import { Route, RouteProps, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AuthProvider } from 'src/common/constants'
import { useCantinaId, useCantinaBackendAPI } from 'src/common/hooks'
import { Endpoint } from 'src/common/rest'
import { purgeAction } from 'src/common/redux/actions'
import { getJWTExpiresIn } from 'src/common/services/helpers'
import {
  uiActions,
  refreshJWTRequest,
  authActions,
} from 'src/common/redux/features'
import {
  getAuthToken,
  getEmail,
  getUserId,
} from 'src/common/redux/features/auth/authSelectors'
import {
  getSearchParams,
  userLookupResponseHandler,
} from 'src/services/Helpers'
import { Loading } from 'src/App'

const userLookupOptions = {
  endpoint: Endpoint.UsersLookup,
  method: 'GET',
}

const loginOptions = {
  endpoint: Endpoint.Login,
  method: 'POST',
}

export const AutoLoginRoute = ({ children, ...rest }: RouteProps) => {
  const waitForToken = useRef(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const cantinaId = useCantinaId()
  const userId = useSelector(getUserId)
  const email = useSelector(getEmail)
  const token = useSelector(getAuthToken)
  const {
    email: emailParam,
    room,
    username,
    companyName,
    // loginType, // TODO: should we use it to know how to force the login type?
  } = getSearchParams()
  const { invokeAPI: userLookupHandler } =
    useCantinaBackendAPI(userLookupOptions)
  const { invokeAPI: loginHandler } = useCantinaBackendAPI(loginOptions)

  useEffect(() => {
    if (cantinaId && userId) {
      if (email === emailParam) {
        console.debug('Same email: refresh token and check again')
        dispatch(refreshJWTRequest())
        waitForToken.current = true
      } else {
        console.debug('Different email: clean refresh the page')
        dispatch(purgeAction())
        setTimeout(() => window.location.reload())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Run only after the first render

  useEffect(() => {
    if (waitForToken.current && token) {
      console.log('Second run with token')
      if (email === emailParam) {
        console.log('Same email again: go to room page')
        dispatch(uiActions.setAutoDialExtension(room))
      } else {
        console.debug('Different email (2time): clean refresh the page')
        dispatch(purgeAction())
        setTimeout(() => window.location.reload())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    if (cantinaId && !userId) {
      console.log('Do user lookup by email', cantinaId, emailParam)
      userLookupHandler({
        urlTransform: (url) => {
          return `${url}?cantinaId=${cantinaId}&email=${encodeURIComponent(
            emailParam
          )}`
        },
      }).then(async (response) => {
        console.log('User Lookup Response', response)
        const guestHandler = () => {
          // Save the room to auto-join later (after login)
          dispatch(uiActions.setAutoDialExtension(room))

          // Auto-Login Guest
          loginHandler({
            credentials: 'include',
            body: JSON.stringify({
              cantinaId,
              email: emailParam,
              name: username,
              provider: AuthProvider.Guest,
              expiresIn: getJWTExpiresIn(),
            }),
          })
            .then((response) => {
              dispatch(
                authActions.loginSuccess({
                  ...response,
                  name: username,
                  company: companyName,
                })
              )
            })
            .catch((error) => {
              console.error('Guest Auto-Login Error', error)
            })
        }
        const samlHandler = () => {
          // Save the room to auto-join later (after login)
          dispatch(uiActions.setAutoDialExtension(room))
        }
        if (!response.email) {
          response.email = emailParam
        }
        userLookupResponseHandler({
          response,
          history,
          guestHandler,
          samlHandler,
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cantinaId, userId])

  return (
    <Route
      {...rest}
      render={() => {
        return <Loading />
      }}
    />
  )
}
