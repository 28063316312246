import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'
import { PromptViewState } from '../../interfaces'
import {
  roomJoinedAction,
  roomLeftAction,
} from '../../features/rooms/roomsSlice'
import { callActions } from '../../features'

export const initialPromptViewState: PromptViewState = Object.freeze({
  isVisible: false,
  promptType: null,
  title: null,
  regex: null,
  error: false,
})

const clearHandler = (state: PromptViewState) => {
  state.isVisible = false
  state.promptType = null
  state.title = null
  state.regex = null
  state.error = false
}

const promptViewSlice = createSlice({
  name: 'promptView',
  initialState: initialPromptViewState,
  reducers: {
    hide: clearHandler,
    show: (state, { payload }: PayloadAction<Partial<PromptViewState>>) => {
      // If the modal is already open and we get the notification from Relay again,
      // it's because the previous attempt to initiate the call failed (invalid PIN)
      if (state.isVisible) {
        state.error = true
      } else {
        state.isVisible = true
      }
      state.promptType = payload?.promptType ?? null
      state.title = payload?.title ?? null
      state.regex = payload?.regex ?? null
    },
  },
  extraReducers: (builder) => {
    const shouldClear = isAnyOf(
      roomJoinedAction,
      roomLeftAction,
      callActions.destroy
    )
    builder.addMatcher(shouldClear, clearHandler)
  },
})

// prettier-ignore
export const {
  actions: promptViewActions,
  reducer: promptViewReducer,
} = promptViewSlice
