import React, { FC } from 'react'
import { useAutoLogin } from 'src/effects/useAutoLogin'
import { Loading } from 'src/App'

const AutoLogin: FC = () => {
  useAutoLogin()

  return <Loading />
}

export default AutoLogin
