import React, { FC, useRef, HTMLProps, KeyboardEvent, ChangeEvent } from 'react'
import { CloseIcon, SearchIcon } from 'src/common/icons'
import { tr, Label } from 'src/common/i18n'
import { TITLE_MAX_CHARS } from 'src/common/constants'
import { TestId } from 'src/constants'

interface SearchInputProps extends HTMLProps<HTMLInputElement> {
  autoFocus?: boolean
  clearInputFunction?: () => void
  currentValue: string
  enterHandler?(event: KeyboardEvent<HTMLInputElement>): void
  inputChanged(event: ChangeEvent<HTMLInputElement>): void
  inputClass?: string
  label?: string
  testId?: TestId // TODO: make required
}

export const SearchInput: FC<SearchInputProps> = ({
  className = '',
  clearInputFunction,
  currentValue,
  enterHandler,
  inputChanged,
  inputClass = 'sw-input-search',
  label,
  testId,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { name = '' } = rest
  const clear = () => {
    clearInputFunction?.()
    inputRef?.current?.focus()
  }
  const keyUpHandler = enterHandler
    ? (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          enterHandler(event)
        }
      }
    : undefined

  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className='flex'>
        <div className='relative grow focus-within:z-10'>
          <input
            autoComplete='off'
            className={inputClass}
            data-test={testId}
            maxLength={TITLE_MAX_CHARS}
            onChange={inputChanged}
            onKeyUp={keyUpHandler}
            ref={inputRef}
            type='text'
            value={currentValue}
            {...rest}
          />
          <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none'>
            <SearchIcon className='self-center mx-2 cursor-pointer' />
          </div>
          {currentValue && (
            <button
              aria-label={tr(Label.CLEAR_FILTER)}
              className='absolute inset-y-0 right-0 px-2 cursor-pointer'
              data-test={testId && `${testId}_clear`}
              onClick={clear}
            >
              <CloseIcon className='text-m' />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
