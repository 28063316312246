import {
  isMobileOnly,
  isTablet,
  isAndroid,
  isWinPhone,
  isIOS,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isIE,
  isEdge,
  isYandex,
  isChromium,
  osVersion,
  osName,
  fullBrowserVersion,
  getUA,
} from 'react-device-detect'
import { v4 as uuidv4 } from 'uuid'
import { Verto, VertoConference } from '@signalwire/js-cantina'

export { VertoConference }
type LogLevel = 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'silent'

const UUID_PATTERN =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
export const getSessionId = () => {
  if (window.name && UUID_PATTERN.test(window.name)) {
    return window.name
  }
  return uuidv4()
}

export const clientBuilder = ({
  vertoHost,
  vertoLogin,
  vertoPasswd,
  loginParams = {},
  iceServers,
}: any) => {
  let client: Verto
  if (vertoHost && vertoLogin && vertoPasswd) {
    client = new Verto({
      host: vertoHost,
      login: vertoLogin,
      passwd: vertoPasswd,
      loginParams,
    })
    // Always incognito setting sessionId
    client.incognito = true
    client.sessionid = getSessionId()
    // Workaround to the FS close event before the "auth-expires" value
    // @ts-expect-error
    client._expiredDiffSeconds = 5
    // @ts-expect-error
    client._refreshTokenNotificationDiff = 180
    /**
     * Set _experimental flag on the old SDK
     * @ts-expect-error */
    client._experimental = true
  } else {
    throw new Error('Invalid params to build the client')
  }
  client.autoRecoverCalls = false
  // client.remoteElement = 'remoteVideo'
  // client.localElement = 'localVideo'
  client.iceServers = iceServers || [{ urls: ['stun:stun.l.google.com:19302'] }]
  client.__logger.setLevel(client.__logger.levels.INFO)
  // @ts-expect-error
  window['_relayClient'] = client /* eslint-disable-line */

  // prettier-ignore
  // @ts-expect-error
  window['_setVertoLogLevel'] = (l: LogLevel) => { /* eslint-disable-line */
    const ll = l.toUpperCase() as Uppercase<LogLevel>
    const level = client.__logger.levels[ll] || client.__logger.levels.INFO
    console.debug('Set Verto logLevel to', ll, level)
    client.__logger.setLevel(level)
  }

  return client
}

export const getUserClientDetails = () => {
  return {
    isMobile: isMobileOnly,
    isTablet,
    isAndroid,
    isWinPhone,
    isIOS,
    isChrome,
    isFirefox,
    isSafari,
    isOpera,
    isIE,
    isEdge,
    isYandex,
    isChromium,
    osVersion,
    osName,
    fullBrowserVersion,
    userAgent: getUA,
  }
}
