import React, { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Scope } from 'src/common/constants'
import { CantinaState } from 'src/common/redux/interfaces'
import { getNameAndSubtitle } from 'src/common/redux/features/participants/participantSelectors'
import { hasScope } from 'src/common/redux/features/scopes/scopesSelectors'
import { withTextTooltip } from 'src/hoc/withTextTooltip'
import { Size, TestId } from 'src/constants'
import { ParticipantData } from './ParticipantData'
import { ParticipantSettingsButton } from '../CallButtons/ParticipantSettingsButton'

type ParticipantListRowDetailsProps = {
  className?: string
  participantId: string
  size: Size
  testId: TestId
}
export const ParticipantListRowDetails: FC<ParticipantListRowDetailsProps> = (
  props
) => {
  const { participantId, className } = props
  const [name, subtitle] = useSelector(
    (state: CantinaState) => getNameAndSubtitle(state, participantId),
    shallowEqual
  )
  const hasControlsScope = useSelector((state: CantinaState) =>
    hasScope(state, Scope.ConferenceLayerControls)
  )
  const TooltippedParticipantData = withTextTooltip({
    title: name as string,
    subtitle: subtitle as string,
  })(ParticipantData)

  if (hasControlsScope) {
    return (
      <ParticipantSettingsButton participantId={participantId} size='1x'>
        <TooltippedParticipantData
          {...props}
          className={`${className} cursor-pointer`}
        />
      </ParticipantSettingsButton>
    )
  }
  return <TooltippedParticipantData {...props} />
}
