import React, { PropsWithChildren } from 'react'
import { Copyright } from '../components/Copyright/Copyright'

interface CenteredBoxProps {
  className?: string
}
export const CenteredBox = ({
  className = '',
  children,
}: PropsWithChildren<CenteredBoxProps>) => {
  return (
    <div className='flex-1 flex items-center justify-center mt-2'>
      <div className='w-11/12 sm:w-full max-w-lg'>
        <div
          className={`block-primary center-box shadow-md p-6 mb-4 rounded-lg ${className}`}
        >
          {children}
        </div>
        <Copyright />
      </div>
    </div>
  )
}
