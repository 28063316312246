import React, { FC } from 'react'
import { RecordingIcon } from 'src/common/icons'
import { useSmartRoomSelector } from 'src/common/hooks'
import { tr, Label } from 'src/common/i18n'
import { withTextTooltip } from 'src/hoc/withTextTooltip'

type RoomRecordingStateProps = {
  roomId: string
  showLabel?: boolean
}

const RoomRecordingState: FC<RoomRecordingStateProps> = withTextTooltip()(
  ({ roomId, showLabel = true }) => {
    const active = useSmartRoomSelector('isRecording', roomId)
    if (!active) {
      return null
    }
    return (
      <div
        className='text-sw-red cursor-default'
        aria-label={tr(Label.ROOM_RECORDING)}
      >
        <RecordingIcon className='text-sw-red animate-pulse' />
        {showLabel && ` ${tr(Label.RECORDING)}`}
      </div>
    )
  }
)

export { RoomRecordingState }
