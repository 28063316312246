import React, { FC, useState } from 'react'
import { Size } from 'src/constants'
import { DesktopIcon } from 'src/common/icons'

type AvatarProps = {
  alt?: string
  className?: string
  isScreenShare?: boolean
  size?: Size
  src: string
  useDefault?: boolean
}

const iconHash: Record<string, any> = {
  [Size.Small]: {
    iconClasses: 'mt-2',
    iconSize: '1x',
  },
  [Size.Large]: {
    iconClasses: 'mt-3 ml-2',
    iconSize: '2x',
  },
}

export const Avatar: FC<AvatarProps> = ({
  alt = '',
  className = '',
  isScreenShare = false,
  size = Size.Small,
  src,
  useDefault = false,
}) => {
  const [useDefaultImage, setUseDefaultImage] = useState(useDefault ?? !src)
  const { iconSize = '', iconClasses = '' } = iconHash?.[size] || {}

  if (isScreenShare) {
    return (
      <div className='flex justify-center items-center bg-contrast-l rounded-full w-full h-full'>
        <DesktopIcon size={iconSize} className={iconClasses} />
      </div>
    )
  }

  // If no source or source fails, use default image
  return useDefaultImage ? (
    <img
      src='https://www.gravatar.com/avatar/0?d=mp&f=y'
      className={className}
      alt={alt}
    />
  ) : (
    <img
      src={src}
      className={className}
      alt={alt}
      onError={() => setUseDefaultImage(true)}
    />
  )
}
