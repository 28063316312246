import React, { FC } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { CantinaState } from 'src/common/redux/interfaces'
import { getRoomZoneIdsByRoomId } from 'src/common/redux/features/roomZones/roomZonesSelectors'
import ZoneGroup from './ZoneGroup'

interface ZoneListProps {
  roomId: string
}

export const ZoneList: FC<ZoneListProps> = ({ roomId }) => {
  const currentRoomZoneIds = useSelector(
    (state: CantinaState) => getRoomZoneIdsByRoomId(state, roomId),
    shallowEqual
  )

  return (
    <>
      {currentRoomZoneIds?.map((zoneId, index) => (
        <ZoneGroup
          className='mb-2'
          key={zoneId}
          roomId={roomId}
          zoneId={zoneId}
          zoneIndex={index}
        />
      ))}
    </>
  )
}
