import React from 'react'
import { SolidIcon, RegularIcon, DuotoneIcon, LightIcon } from '../Icon'
import { FontAwesomeIconProps } from '../FontAwesomeIcon'

/**
 * General Purposes Icons
 */
export const ChevronRightIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='chevron-right' {...props} />
}

export const HomeIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='home' {...props} />
}

export const ImageIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='image' {...props} />
}

export const PhoneIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='phone' flip='horizontal' {...props} />
}

export const SignoutIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='sign-out-alt' {...props} />
}

export const SignalIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon fixedWidth={true} icon='signal' {...props} />
}

export const SignalAlt1Icon = (props: Partial<FontAwesomeIconProps>) => {
  return <DuotoneIcon fixedWidth={true} icon='signal-alt-1' {...props} />
}

export const UserIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='user' {...props} />
}

export const UserSlashIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='user-slash' {...props} />
}

export const MeetingIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='users' {...props} />
}

export const SpinnerIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='spinner' spin={true} {...props} />
}

export const EnvelopeIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='envelope' {...props} />
}

export const ExclamationCircleIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='exclamation-circle' {...props} />
}

export const CheckCircleIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='check-circle' {...props} />
}

export const SearchIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='search' {...props} />
}

export const BarsIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='bars' {...props} />
}

export const UsersIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='user-friends' {...props} />
}

export const FilmIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='film-alt' {...props} />
}

export const CommentsIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <DuotoneIcon icon='comments-alt' {...props} />
}

export const EllipsisIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='ellipsis-h' {...props} />
}

export const EllipsisSolidIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='ellipsis-h' {...props} />
}

export const UserPlusIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='user-plus' {...props} />
}

export const DesktopIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='desktop' {...props} />
}

export const CloseIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <LightIcon icon='times' {...props} />
}

export const CopyIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='copy' {...props} />
}

export const QuestionIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='question' {...props} />
}

export const HeadsetIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='headset' {...props} />
}

export const InfoCircleIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <RegularIcon icon='info-circle' {...props} />
}

export const InfoCircleIconSolid = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='info-circle' {...props} />
}

export const CaretUpIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='caret-up' {...props} />
}

export const ArrowDownIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='chevron-down' {...props} />
}

export const ArrowUpIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='chevron-up' {...props} />
}

export const ArrowLeftIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='arrow-left' {...props} />
}

export const ArrowRightIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='arrow-right' {...props} />
}

export const RepeatIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='repeat' {...props} />
}

export const EyeSlashIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='eye-slash' {...props} />
}

export const EyeIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='eye' {...props} />
}

export const CompassIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='compass' {...props} />
}

export const PaperPlaneIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='paper-plane' {...props} />
}

export const TrashIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='trash-alt' {...props} />
}

export const EditIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='pencil-alt' {...props} />
}

export const PlusSquareIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='plus-square' {...props} />
}

export const PlusIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='plus' {...props} />
}

export const MinusIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='minus' {...props} />
}

export const MinusSquareIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='minus-square' {...props} />
}

export const MinusSquareSolidIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='minus-square' {...props} />
}

export const StopIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='stop' {...props} />
}

export const PlayIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='play' {...props} />
}

export const PlayCircleIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='play-circle' {...props} />
}

export const PauseIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='pause' {...props} />
}

export const ForwardIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='step-forward' {...props} />
}

export const RewindIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='step-backward' {...props} />
}

export const RecordingIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='record-vinyl' {...props} />
}

export const ExpandIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='expand' {...props} />
}

export const CompressIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='compress-wide' {...props} />
}

export const BackspaceIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='backspace' {...props} />
}

export const ExternalLinkIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='external-link' {...props} />
}

export const ThIcon = (props: Partial<FontAwesomeIconProps>) => {
  // 3x3 grid
  return <SolidIcon icon='th' {...props} />
}

export const MoonIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='moon' {...props} />
}

export const SunIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='sun' {...props} />
}

export const SignalStreamIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='signal-stream' {...props} />
}

export const UserTagIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='user-tag' {...props} />
}

export const WaveformIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='waveform-path' {...props} />
}

export const WaveSquareIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='wave-square' {...props} />
}

export const LayerPlusIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='layer-plus' {...props} />
}

export const LifeRingIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='life-ring' {...props} />
}

/**
 * Call Icons
 */
export const CameraOnIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='video' {...props} />
}

export const CameraOffIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='video-slash' {...props} />
}

export const MicrophoneOnIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='microphone' {...props} />
}

export const MicrophoneOffIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='microphone-slash' {...props} />
}

export const RaiseHandIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='hand-paper' {...props} />
}

export const DnDIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='do-not-enter' {...props} />
}

export const LeaveIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='times-octagon' {...props} />
}

export const VolumeUpIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='volume-up' {...props} />
}

export const VolumeDownIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='volume-down' {...props} />
}

export const TachometerSlowIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='tachometer-slowest' {...props} />
}

export const TachometerFastIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='tachometer-fastest' {...props} />
}

export const KeyIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='key' {...props} />
}

export const WrenchIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='wrench' {...props} />
}

export const ToolsIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='tools' {...props} />
}

export const PaletteIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='palette' {...props} />
}

export const VideoFullScreenIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='expand-alt' {...props} />
}

export const SlidersIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='sliders-h' {...props} />
}

export const VolumeSlashIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='volume-slash' {...props} />
}

export const KnockIcon = ({ className = '' }) => {
  return (
    <svg
      width='28.5'
      height='28.5'
      viewBox='0 0 28.5 28.5'
      className={className}
    >
      <path d='M19.54 13.27a1.37 1.37 0 00-1.37 1.37v-.69a1.52 1.52 0 00-3.05 0v-1.7a1.52 1.52 0 10-3.04 0v1.18a1.37 1.37 0 10-2.74 0v5.65l-.01-.01a.29.29 0 01-.32.22.3.3 0 01-.25-.3v-1.1a.3.3 0 00-.3-.3h-.05a1.33 1.33 0 00-.94.4l-.26.25a2.27 2.27 0 000 3.22l3.02 3.02a3.77 3.77 0 00.44.44l.15.15.02-.02a3.74 3.74 0 002.26.76h3.6a4.2 4.2 0 004.2-4.21v-6.96a1.37 1.37 0 00-1.36-1.37z'></path>
      <path
        d='M19.22 8.4L22.8 4.8M9.28 8.4L5.7 4.8M14.15 7.35V2.28'
        strokeLinecap='round'
        strokeWidth='2px'
      ></path>
    </svg>
  )
}

export const SidebarIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      fill='#eaedf0'
      viewBox='0 0 512 512'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='213.33' height='234.67' rx='42.67' />
      <rect y='277.33' width='213.33' height='234.67' rx='42.67' />
      <path
        fillRule='evenodd'
        d='M256,42.67A42.66,42.66,0,0,1,298.67,0H469.33A42.66,42.66,0,0,1,512,42.67V469.33A42.66,42.66,0,0,1,469.33,512H298.67A42.66,42.66,0,0,1,256,469.33Zm64,65.06a42.67,42.67,0,0,1,42.67-42.67h42.66A42.67,42.67,0,0,1,448,107.73v88a42.66,42.66,0,0,1-42.67,42.67H362.67A42.66,42.66,0,0,1,320,195.73Zm42.67,174A42.67,42.67,0,0,0,320,324.39v88a42.66,42.66,0,0,0,42.67,42.67h42.66A42.66,42.66,0,0,0,448,412.39v-88a42.67,42.67,0,0,0-42.67-42.67Z'
      />
    </svg>
  )
}

export const SWLogoIcon = ({ className = '' }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M15.0089 10.1983C15.0089 10.6528 14.8856 10.9529 14.639 11.1956C13.1681 12.6651 11.6971 14.1346 10.2262 15.5997C9.7153 16.1072 8.96662 16.1337 8.46015 15.6703C7.92727 15.1805 7.90525 14.3729 8.42492 13.839C9.08553 13.1638 9.75934 12.493 10.4332 11.8266C11.2391 11.0235 12.045 10.2203 12.8598 9.42159C13.2517 9.03325 13.7758 8.93175 14.2603 9.1524C14.7535 9.37746 14.9913 9.77022 15.0089 10.1983ZM0.99092 5.56468C0.999728 5.3352 1.11864 5.04395 1.36086 4.80565C2.8274 3.34055 4.29394 1.87986 5.75608 0.414766C6.05996 0.110273 6.42109 -0.0485936 6.84828 0.0131876C7.35915 0.0837948 7.71147 0.370637 7.87882 0.860474C8.04617 1.35031 7.93167 1.79161 7.57054 2.15788C6.8703 2.86837 6.16125 3.57444 5.45661 4.28051C4.6859 5.04836 3.9152 5.8118 3.14009 6.57524C2.74813 6.96358 2.22405 7.05625 1.73961 6.84443C1.26837 6.64144 0.982112 6.19131 0.99092 5.56468Z' />
      <path d='M1.99502 7.22828C2.16237 7.21946 2.2989 7.22387 2.43542 7.20622C2.78775 7.15768 3.09162 7.00764 3.34265 6.75168C3.62451 6.46484 3.90637 6.178 4.19263 5.88233C4.24548 5.93088 4.28071 5.95735 4.31154 5.98824L7.56172 9.245C7.91845 9.60245 8.04176 10.0349 7.90083 10.5203C7.7643 10.9881 7.44281 11.2838 6.96717 11.3941C6.5576 11.4868 6.16564 11.4073 5.86176 11.1073C4.57578 9.83192 3.30302 8.54334 1.99502 7.22828ZM14.0136 8.79047C13.353 8.72427 12.8818 8.98022 12.4854 9.41711C12.2696 9.6554 12.0362 9.87605 11.8028 10.1099C11.7544 10.0658 11.7191 10.0393 11.6883 10.0084C10.6049 8.92286 9.51711 7.83727 8.43372 6.74727C8.07699 6.38982 7.95368 5.95735 8.09901 5.47193C8.23994 5.00416 8.56143 4.70849 9.03707 4.60258C9.45986 4.50991 9.85182 4.60258 10.1557 4.90707C11.4152 6.16035 12.6704 7.42245 13.9255 8.68014C13.952 8.70221 13.9652 8.72427 14.0136 8.79047Z' />
    </svg>
  )
}

export const PictureInPictureIcon = ({ className = '' }) => {
  return (
    <svg
      width='31'
      height='24'
      viewBox='0 0 31 24'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='mask-pip' fill='white'>
        <rect width='30.5455' height='24' rx='2' />
      </mask>
      <rect
        width='30.5455'
        height='24'
        rx='2'
        strokeWidth='6'
        fill='none'
        mask='url(#mask-pip)'
      />
      <rect x='14.1818' y='9.81836' width='10.9091' height='8.72727' />
    </svg>
  )
}

export const PictureInPictureIconActive = ({ className = '' }) => {
  return (
    <svg
      width='31'
      height='24'
      viewBox='0 0 31 24'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='mask-pip-active' fill='white'>
        <rect width='30.5455' height='24' rx='2' />
      </mask>
      <rect
        width='30.5455'
        height='24'
        rx='2'
        strokeWidth='6'
        mask='url(#mask-pip-active)'
        fill='none'
      />
    </svg>
  )
}

export const DownloadIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='download' {...props} />
}

export const SquareIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='square' {...props} />
}

export const CheckSquareIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='check-square' {...props} />
}

export const CogIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='cog' {...props} />
}

export const MegaphoneIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='megaphone' {...props} />
}

export const WalkieTalkieIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='walkie-talkie' {...props} />
}

export const CheckIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='check' {...props} />
}

export const LockIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='lock' {...props} />
}

export const TimesCircleIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='times-circle' {...props} />
}

export const TimesOctagonIcon = (props: Partial<FontAwesomeIconProps>) => {
  return <SolidIcon icon='times-octagon' {...props} />
}

export const DecibelIcon = ({ className = '' }) => {
  return (
    <svg
      className={`${className} stroke-current fill-current`}
      fill='none'
      height='16'
      viewBox='0 0 16 10'
      width='20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M3.15483 9.41779C4.21165 9.41779 4.76136 8.80842 5.01278 8.26296H5.08949V9.31126H6.87926V0.583984H5.06818V3.86523H5.01278C4.76989 3.33256 4.24574 2.68058 3.15057 2.68058C1.71449 2.68058 0.5 3.79705 0.5 6.04705C0.5 8.23739 1.66335 9.41779 3.15483 9.41779ZM3.73011 7.97319C2.83949 7.97319 2.35369 7.18058 2.35369 6.03853C2.35369 4.90501 2.83097 4.12518 3.73011 4.12518C4.61222 4.12518 5.10653 4.87092 5.10653 6.03853C5.10653 7.20614 4.60369 7.97319 3.73011 7.97319Z' />
      <path d='M8.40589 9.31126H12.1346C14.0948 9.31126 15.1389 8.28853 15.1389 6.89933C15.1389 5.60813 14.2141 4.8283 13.2212 4.78143V4.6962C14.1289 4.49166 14.7979 3.84819 14.7979 2.8212C14.7979 1.51296 13.8264 0.583984 11.9002 0.583984H8.40589V9.31126ZM10.2511 7.80273V5.48455H11.7979C12.6843 5.48455 13.234 5.99592 13.234 6.71609C13.234 7.37234 12.7866 7.80273 11.7553 7.80273H10.2511ZM10.2511 4.23597V2.07546H11.6531C12.4712 2.07546 12.9357 2.49734 12.9357 3.12802C12.9357 3.81836 12.3732 4.23597 11.619 4.23597H10.2511Z' />
    </svg>
  )
}

export const ErrorRobotIcon = () => {
  return (
    <svg
      width='160'
      height='271'
      viewBox='0 0 160 271'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='mx-auto'
    >
      <path
        d='M59.8132 140.461H114.874L107.173 161.249L59.8132 149.45V140.461Z'
        fill='#044EF4'
      />
      <rect
        x='126.464'
        y='180.327'
        width='14.6079'
        height='8.98948'
        transform='rotate(105 126.464 180.327)'
        fill='#044EF4'
      />
      <path
        d='M82.8489 216.31L82.8489 233.165L103.637 241.592L91.2764 247.189L71.0503 237.098L69.9266 213.5L82.8489 216.31Z'
        fill='#044EF4'
      />
      <rect
        x='51.9475'
        y='205.635'
        width='33.7106'
        height='13.4842'
        transform='rotate(90 51.9475 205.635)'
        fill='#044EF4'
      />
      <path
        d='M35.092 164.058L31.721 179.79L22.7315 179.79L22.7315 165.182L35.092 164.058Z'
        fill='#044EF4'
      />
      <path
        d='M95.7712 56.1843L92.4002 65.1738L92.4002 75.287L83.4107 75.287L83.4107 65.1738L86.7818 56.1843L95.7712 56.1843Z'
        fill='#044EF4'
      />
      <circle cx='92.4002' cy='50.5659' r='7.8658' fill='#044EF4' />
      <path
        d='M37.9648 142.532L122.626 165.217L106.63 224.913L21.9691 202.229L37.9648 142.532Z'
        fill='#B8B8B8'
      />
      <path
        d='M43.0815 86.2869C43.0815 80.2117 48.0064 75.2869 54.0815 75.2869H119.729C125.804 75.2869 130.729 80.2117 130.729 86.2869V140.461H43.0815V86.2869Z'
        fill='#B8B8B8'
      />
      <path
        d='M27.3501 239.345H58.8133V257.324H27.3501V239.345Z'
        fill='#B8B8B8'
      />
      <path
        d='M75.6685 255.099L102.903 239.345L111.906 254.908L84.6709 270.662L75.6685 255.099Z'
        fill='#B8B8B8'
      />
      <path
        d='M7 162.821C7 157.298 11.4772 152.821 17 152.821H22.7316V214.737H7V162.821Z'
        fill='#B8B8B8'
      />
      <path
        d='M30 226C30 222.022 28.4196 218.206 25.6066 215.393C22.7936 212.58 18.9782 211 15 211C11.0218 211 7.20645 212.58 4.3934 215.393C1.58035 218.206 6.00699e-07 222.022 0 226L9.08897 226C9.08897 224.432 9.71174 222.929 10.8203 221.82C11.9288 220.712 13.4323 220.089 15 220.089C16.5677 220.089 18.0712 220.712 19.1797 221.82C20.2883 222.929 20.911 224.432 20.911 226H30Z'
        fill='#044EF4'
      />
      <path
        d='M140.436 182.377C141.95 177.012 138.785 171.445 133.4 170.002L127.992 168.553L111.5 227L126.696 231.072L140.436 182.377Z'
        fill='#B8B8B8'
      />
      <circle cx='65.5552' cy='108.997' r='16.8553' fill='#EEEEEE' />
      <circle cx='108.255' cy='108.997' r='16.8553' fill='#EEEEEE' />
      <path
        d='M62 105L69.8651 113M62.1349 113L70 105'
        stroke='#F72A72'
        strokeWidth='4'
      />
      <path
        d='M104 105L111.865 113M104.135 113L112 105'
        stroke='#F72A72'
        strokeWidth='4'
      />
      <path
        d='M130.489 242.882C131.518 239.04 130.979 234.945 128.99 231.5C127.001 228.055 123.725 225.541 119.882 224.511C116.04 223.481 111.945 224.02 108.5 226.01C105.055 227.999 102.541 231.275 101.511 235.118L110.29 237.47C110.696 235.956 111.687 234.665 113.044 233.881C114.402 233.097 116.016 232.885 117.53 233.29C119.044 233.696 120.335 234.687 121.119 236.044C121.903 237.402 122.115 239.016 121.71 240.53L130.489 242.882Z'
        fill='#044EF4'
      />
      <path
        d='M105.17 10.1132L105.049 24.2947M98.1392 17.3849L112.321 17.2643'
        stroke='#F72A72'
        strokeWidth='2'
      />
      <path
        d='M139.221 32.2812L126.111 45.3915L139.618 38.2404L147.564 46.1861L159.88 24.3355L145.578 38.6377L139.221 32.2812Z'
        fill='#F72A72'
      />
      <path
        d='M63.8495 23.5704L68.5795 41.4977L69.1988 26.2265L80.0638 23.3598L67.3815 1.71997L72.5415 21.277L63.8495 23.5704Z'
        fill='#F72A72'
      />
      <path
        d='M109.78 51.5439L107.324 61.2092L111.602 54.1896L117.46 55.6784L117.146 42.191L114.466 52.7349L109.78 51.5439Z'
        fill='#F72A72'
      />
      <circle
        cx='50.2618'
        cy='57.8698'
        r='5.18027'
        stroke='#F72A72'
        strokeWidth='2'
      />
    </svg>
  )
}

export const HideVideoMutedIcon = ({ className = '' }) => {
  return (
    <svg
      className={`svg-inline--fa ${className}`}
      fill='none'
      height='14'
      viewBox='0 0 16 14'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.75 0H0.75C0.335781 0 0 0.335781 0 0.75V5.75C0 6.16422 0.335781 6.5 0.75 6.5H6.75C7.16422 6.5 7.5 6.16422 7.5 5.75V0.75C7.5 0.335781 7.16422 0 6.75 0ZM0 8.25V13.25C0 13.6642 0.335781 14 0.75 14H6.75C7.16422 14 7.5 13.6642 7.5 13.25V8.25C7.5 7.83578 7.16422 7.5 6.75 7.5H0.75C0.335781 7.5 0 7.83578 0 8.25ZM9.25 14H15.25C15.6642 14 16 13.6642 16 13.25V8.25C16 7.83578 15.6642 7.5 15.25 7.5H9.25C8.83578 7.5 8.5 7.83578 8.5 8.25V13.25C8.5 13.6642 8.83578 14 9.25 14Z'
        fill='currentColor'
      />
    </svg>
  )
}

export const SpeakerHighlightIcon = ({ className = '' }) => {
  return (
    <svg
      className={`svg-inline--fa ${className}`}
      fill='none'
      height='18'
      viewBox='0 0 19 18'
      width='19'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.3252 9.75C11.1885 9.75 12.7002 8.23828 12.7002 6.375C12.7002 4.51172 11.1885 3 9.3252 3C7.46191 3 5.9502 4.51172 5.9502 6.375C5.9502 8.23828 7.46191 9.75 9.3252 9.75ZM12.3252 10.5H11.0338C10.5135 10.7391 9.93457 10.875 9.3252 10.875C8.71582 10.875 8.13926 10.7391 7.6166 10.5H6.3252C4.66816 10.5 3.3252 11.843 3.3252 13.5V13.875C3.3252 14.4961 3.8291 15 4.4502 15H14.2002C14.8213 15 15.3252 14.4961 15.3252 13.875V13.5C15.3252 11.843 13.9822 10.5 12.3252 10.5Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.3252 1.3H2.3252C1.9386 1.3 1.6252 1.6134 1.6252 2V16C1.6252 16.3866 1.9386 16.7 2.3252 16.7H16.3252C16.7118 16.7 17.0252 16.3866 17.0252 16V2C17.0252 1.6134 16.7118 1.3 16.3252 1.3ZM2.3252 0C1.22063 0 0.325195 0.89543 0.325195 2V16C0.325195 17.1046 1.22063 18 2.3252 18H16.3252C17.4298 18 18.3252 17.1046 18.3252 16V2C18.3252 0.895431 17.4298 0 16.3252 0H2.3252Z'
        fill='currentColor'
      />
    </svg>
  )
}
