import { SagaIterator } from 'redux-saga'
import { take, fork, select, call } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'

import { sendChatMessage } from '../../../services/relay'
import { Message } from '../../interfaces'
import { getActiveCallId, getActiveCallRoomId } from '../calls/callSelectors'
import { hasChatDisabled } from '../rooms/roomsSelectors'
import { sendMessage } from '../messages/messageSlice'

export const ACTIONS = [sendMessage.type]

export function* worker({ type, payload }: PayloadAction<any>): SagaIterator {
  switch (type) {
    case sendMessage.type: {
      const disableChat = yield call(checkChatDisabled)
      if (disableChat) {
        break
      }
      const callId = yield select(getActiveCallId)
      if (callId) {
        const { content, participantId, participantName } = payload as Message
        const type = {
          type: 'chat',
          authorId: participantId,
          authorName: participantName,
        }
        sendChatMessage(callId, content.trim(), JSON.stringify(type))
      }
      break
    }
    default:
      console.warn('Unhandled chatSaga action', type, payload)
  }
}

export function* checkChatDisabled(): SagaIterator {
  if (process.env.REACT_APP_HYBRID_MODE === '1') {
    // Hybrid-Mode check chatDisabled at the room level
    const roomId = yield select(getActiveCallRoomId)
    return yield select(hasChatDisabled, roomId)
  }
  return false
}

export function* watchChats(): SagaIterator {
  while (true) {
    const action = yield take(ACTIONS)
    yield fork(worker, action)
  }
}
