import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  ComponentType,
} from 'react'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from '../hooks'
import { CantinaState } from '../redux/interfaces'

type WithFilterAction = ActionCreatorWithPayload<{ filter: string }>
type WithFilterSelector = (state: CantinaState) => string

export const withFilter = <T extends {}>(
  WrappedComponent: ComponentType<T>,
  actionCreator: WithFilterAction,
  filterSelector: WithFilterSelector
) => {
  return (props: Omit<T, 'onChange' | 'filter'>) => {
    const dispatch = useDispatch()
    const reduxFilter = useSelector(filterSelector)
    const [localFilter, setLocalFilter] = useState(reduxFilter)
    const delayedFilter = useDebounce(localFilter, 300)

    useEffect(() => {
      if (reduxFilter === '') {
        setLocalFilter(reduxFilter)
      }
    }, [reduxFilter])

    useEffect(() => {
      dispatch(actionCreator({ filter: delayedFilter }))
    }, [dispatch, actionCreator, delayedFilter])

    const clearHandler = useCallback(() => {
      dispatch(actionCreator({ filter: '' }))
    }, [dispatch, actionCreator])

    useEffect(() => {
      return clearHandler
    }, [clearHandler])

    const onChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setLocalFilter(event.target.value)
      },
      [setLocalFilter]
    )

    const newProps = {
      onChange,
      clearHandler,
      filter: localFilter,
      ...props,
    } as unknown as T
    return <WrappedComponent {...newProps} />
  }
}
