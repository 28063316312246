import React, { Suspense, lazy } from 'react'
import {
  fullBrowserVersion,
  isIOS,
  isMacOs,
  isSafari,
  osVersion,
} from 'react-device-detect'

// https://caniuse.com/?search=flex%20gap
const IOS_VERSION_SUPPORTING_FLEX_GAP = 14.5
const SAFARI_VERSION_SUPPORTING_FLEX_GAP = 14.1

// @ts-ignore
const LazyFlexGapImport = lazy(() => import('./FlexGapImport'))

export const Polyfill = () => {
  let useFlexGapPolyfill = false

  if (osVersion) {
    useFlexGapPolyfill =
      isIOS && parseFloat(osVersion) < IOS_VERSION_SUPPORTING_FLEX_GAP
  }

  if (!useFlexGapPolyfill && fullBrowserVersion) {
    useFlexGapPolyfill =
      isMacOs &&
      isSafari &&
      parseFloat(fullBrowserVersion) < SAFARI_VERSION_SUPPORTING_FLEX_GAP
  }

  return useFlexGapPolyfill ? (
    <Suspense fallback={<></>}>
      <LazyFlexGapImport />
    </Suspense>
  ) : null
}
