import React, { MouseEvent } from 'react'
import { useDispatch } from 'react-redux'
import { StopIcon } from 'src/common/icons'
import { tr, Label } from 'src/common/i18n'
import { callDTMF } from 'src/common/redux/features'
import { ButtonWithTooltip } from '../Button/Button'

// "Music On Hold"
// FS uses this term because it's the same logic for when you are when
// you are waiting for a response from a call center
// In our App it is more "Video On Hold"

export const StopMohLayer = ({ callId }: { callId: string }) => {
  const dispatch = useDispatch()
  const clickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    dispatch(callDTMF(callId, '*9'))
  }

  return (
    <div className='absolute user-menu w-full h-full inset-0'>
      <div className='user-menu-items flex items-center'>
        <ButtonWithTooltip onClick={clickHandler} ariaLabel={tr(Label.STOP)}>
          <StopIcon size='lg' fixedWidth />
        </ButtonWithTooltip>
      </div>
    </div>
  )
}
