import React, { FC, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { withActiveCall } from 'src/common/hoc'
import { getLocalStream } from 'src/common/services/relay'
import { CantinaState } from 'src/common/redux/interfaces'
import { getMyParticipantId } from 'src/common/redux/features/calls/callSelectors'
import { isMuted } from 'src/common/redux/features/participants/participantSelectors'
import { toastInfo, dismissToast } from 'src/services/ToastService'
import { useAudioAnalyser } from 'src/effects/useAudioAnalyser'

const TOAST_MSG = 'You are muted. Unmute if you want to be heard.'
const DELAY_TIME = 700
const CHECK_TIME = 500

const Detector = ({ stream, onNoiseDetected }: any) => {
  const [micEnergy] = useAudioAnalyser({ stream })
  const firstNoiseRef = useRef(0)
  const lastNoiseRef = useRef(0)

  useEffect(() => {
    if (micEnergy >= 5) {
      const now = Date.now()
      if (now - lastNoiseRef.current > CHECK_TIME) {
        // you have to keep making noise within time limit
        firstNoiseRef.current = now
      } else if (lastNoiseRef.current - firstNoiseRef.current > DELAY_TIME) {
        onNoiseDetected()
      }
      lastNoiseRef.current = now
    }
  }, [micEnergy, onNoiseDetected])

  return null
}

export const TalkingWhileMutedToast: FC = withActiveCall(({ callId }) => {
  const toastRef = useRef<any>()
  const participantId = useSelector(getMyParticipantId)
  const muted = useSelector((state: CantinaState) =>
    isMuted(state, participantId)
  )
  const localStream: MediaStream = getLocalStream(callId)

  const onNoiseDetected = () => {
    if (!toastRef.current) {
      toastRef.current = toastInfo(TOAST_MSG, { autoClose: false })
    }
  }

  useEffect(() => {
    return () => {
      if (toastRef.current) {
        dismissToast(toastRef.current)
        toastRef.current = null
      }
    }
  }, [muted])

  if (muted) {
    return <Detector stream={localStream} onNoiseDetected={onNoiseDetected} />
  }

  return null
})
