import { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LabelIconState } from '../constants'
import { updateRoomTemplateRequest } from '../redux/features'
import { makeDefaultRoomTemplateKeySelector } from '../redux/features/roomTemplates/roomTemplatesSelectors'
import { getDefaultRoomTemplateId } from '../redux/features/settings/settingsSelectors'
import { RoomTemplate } from '../rest'

/**
 * This hook is used to dispatch an
 * updateRoomTemplateRequest action for
 * input onBlur/onChange events.
 * It holds the local state to handle the UI feedback like
 * loading or success
 */
export const useRoomTemplateField = (key: keyof RoomTemplate) => {
  const dispatch = useDispatch()
  const isFirstRun = useRef(true)
  const [iconState, setIconState] = useState<LabelIconState>(
    LabelIconState.None
  )
  const selector = makeDefaultRoomTemplateKeySelector(key)
  const reduxValue = useSelector(selector)
  const defaultRoomTemplateId = useSelector(getDefaultRoomTemplateId)

  useEffect(() => {
    if (!isFirstRun.current && iconState === LabelIconState.Loading) {
      setIconState(LabelIconState.Success)
      setTimeout(() => setIconState(LabelIconState.None), 3000)
    }
    isFirstRun.current = false
  }, [key, reduxValue])

  const update = useCallback(
    async (newValue: any) => {
      if (newValue === reduxValue) {
        return console.debug('Prevent auto-save because not changed')
      }

      setIconState(LabelIconState.Loading)
      const params = {
        roomTemplateId: defaultRoomTemplateId as string,
        [key]: newValue,
      }
      dispatch(updateRoomTemplateRequest(params))
    },
    [dispatch, key, defaultRoomTemplateId, reduxValue]
  )

  return { reduxValue, update, iconState }
}
