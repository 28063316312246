import React, { FC } from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { isSidebarRequestToJoinModalVisible } from 'src/common/redux/features/ui/uiSelectors'
import { uiActions } from 'src/common/redux/features'
import {
  getActiveCallRoomId,
  getMyParticipantId,
} from 'src/common/redux/features/calls/callSelectors'
import {
  getRoomZone,
  getZoneRequestToJoinList,
} from 'src/common/redux/features/roomZones/roomZonesSelectors'
import { getParticipantRoomZoneId } from 'src/common/redux/features/participants/participantSelectors'
import { Displayable, RoomCommandAction } from 'src/common/constants'
import { useDispatchRoomCommand } from 'src/common/hooks'
import { CantinaState, RequestToJoin } from 'src/common/redux/interfaces'
import { tr, Label } from 'src/common/i18n'
import {
  withConferenceZoneManageScope,
  withSidebarConversationsFeatureFlag,
} from 'src/common/hoc'
import { TestId } from 'src/constants'
import { Modal } from '../Modal'
import ActionListFragment from '../ActionListFragment'

const RequestToJoinSidebarModal: FC = () => {
  const dispatch = useDispatch()
  const dispatchRoomCommand = useDispatchRoomCommand()
  const show = useSelector(isSidebarRequestToJoinModalVisible)
  const zoneRequestToJoinList: RequestToJoin[] = useSelector(
    getZoneRequestToJoinList
  )
  const roomId = useSelector(getActiveCallRoomId)
  const participantId = useSelector(getMyParticipantId)
  const roomZoneId = useSelector((state: CantinaState) =>
    getParticipantRoomZoneId(state, participantId)
  ) as string
  const zone = useSelector((state: CantinaState) =>
    getRoomZone(state, roomZoneId)
  )

  if (!show) {
    return null
  }

  const close = () => {
    if (zoneRequestToJoinList?.length <= 1) {
      dispatch(uiActions.hide(Displayable.SidebarRequestToJoinModal))
    }
  }

  // Deny a user's request to join this zone
  const cancelHandler = ({
    requestingParticipantId,
    requestingParticipantName,
  }: {
    requestingParticipantId: string
    requestingParticipantName: string
  }) => {
    dispatchRoomCommand({
      action: RoomCommandAction.ZoneDenyToJoin,
      requestingParticipantId,
      requestingParticipantName,
      roomId,
      roomZoneId,
    })
    close()
  }

  // Allow a user to join this zone
  const confirmHandler = ({
    requestingParticipantId,
    requestingParticipantName,
  }: {
    requestingParticipantId: string
    requestingParticipantName: string
  }) => {
    dispatchRoomCommand({
      action: RoomCommandAction.ZoneAllowToJoin,
      requestingParticipantId,
      requestingParticipantName,
      roomId,
      roomZoneId,
      zoneName: zone.name,
    })
    close()
  }

  return (
    <Modal
      byline={tr(Label.SIDEBAR_REQUEST_TO_JOIN_BYLINE)}
      cancelHandler={cancelHandler}
      cancelLabel={tr(Label.DECLINE)}
      closeHandler={close}
      confirmHandler={confirmHandler}
      confirmLabel={tr(Label.ALLOW)}
      show={show}
      showCancelButton={false}
      showConfirmButton={false}
      testId={TestId.SidebarModalRequestToJoin}
      title={tr(Label.SIDEBAR_REQUEST_TO_JOIN)}
    >
      {zoneRequestToJoinList?.length
        ? zoneRequestToJoinList?.map((req, i) => {
            const {
              requestingParticipantAvatar,
              requestingParticipantId,
              requestingParticipantName,
            } = req

            return (
              <ActionListFragment
                key={requestingParticipantName}
                confirmHandler={() =>
                  confirmHandler({
                    requestingParticipantId,
                    requestingParticipantName,
                  })
                }
                confirmLabel={tr(Label.ALLOW)}
                confirmTestId={`${TestId.SidebarModalRequestToJoinConfirmButton}_${i}`}
                declineHandler={() =>
                  cancelHandler({
                    requestingParticipantId,
                    requestingParticipantName,
                  })
                }
                declineLabel={tr(Label.DECLINE)}
                declineTestId={`${TestId.SidebarModalRequestToJoinDeclineButton}_${i}`}
              >
                <div className='pb-2 font-bold'>
                  <img
                    alt={requestingParticipantName}
                    className='inline-block w-8 h-8 mr-2 rounded-full'
                    src={requestingParticipantAvatar}
                  />
                  {requestingParticipantName}
                </div>
              </ActionListFragment>
            )
          })
        : tr(Label.NO_REQUESTS_TO_JOIN)}
    </Modal>
  )
}

export default compose<any>(
  withSidebarConversationsFeatureFlag(),
  withConferenceZoneManageScope
)(RequestToJoinSidebarModal)
