export enum Label {
  ABOUT = 'ABOUT',
  ACCESS_GRANTS_NO_RESULTS = 'ACCESS_GRANTS_NO_RESULTS',
  ACCEPT = 'ACCEPT',
  ACCOUNT_FOUND_INSTRUX = 'ACCOUNT_FOUND_INSTRUX',
  ADD = 'ADD',
  ADD_ANOTHER_IMAGE = 'ADD_ANOTHER_IMAGE',
  ADD_BUTTON = 'ADD_BUTTON',
  ADD_CUSTOM_IMAGE_URL = 'ADD_CUSTOM_IMAGE_URL',
  ADD_NEW = 'ADD_NEW',
  ADD_NEW_USER = 'ADD_NEW_USER',
  ADD_PERMISSION = 'ADD_PERMISSION',
  ADD_USER = 'ADD_USER',
  ADD_USER_TO_GROUP = 'ADD_USER_TO_GROUP',
  ADD_USER_TO_GROUP_ERROR = 'ADD_USER_TO_GROUP_ERROR',
  ADDING_SECOND_SOURCE = 'ADDING_SECOND_SOURCE',
  ADDITIONAL_DEVICES = 'ADDITIONAL_DEVICES',
  ADHOC_ROOMS_PUBLIC = 'ADHOC_ROOMS_PUBLIC',
  ADMINISTRATION = 'ADMINISTRATION',
  ADMINISTRATOR = 'ADMINISTRATOR',
  ADMINISTRATOR_plural = 'ADMINISTRATOR_plural',
  ADVANCED_CONTROLS = 'ADVANCED_CONTROLS',
  ADVANCED_OPTIONS = 'ADVANCED_OPTIONS',
  AGREE = 'AGREE',
  ALL_VIDEO_OFF = 'ALL_VIDEO_OFF',
  ALL_VIDEO_ON = 'ALL_VIDEO_ON',
  ALLOW = 'ALLOW',
  ALLOW_CLIPEEZE = 'ALLOW_CLIPEEZE',
  ALLOW_INTERRUPTIONS = 'ALLOW_INTERRUPTIONS',
  AVATAR = 'AVATAR',
  AVOID_INTERRUPTIONS = 'AVOID_INTERRUPTIONS',
  APPEARANCE = 'APPEARANCE',
  APPEARANCE_TOOLTIP_BANNER_LOGO = 'APPEARANCE_TOOLTIP_BANNER_LOGO',
  APPEARANCE_TOOLTIP_BANNER_TEXT_COLOR = 'APPEARANCE_TOOLTIP_BANNER_TEXT_COLOR',
  APPEARANCE_TOOLTIP_BORDER_COLOR = 'APPEARANCE_TOOLTIP_BORDER_COLOR',
  APPEARANCE_TOOLTIP_BORDER_SPACING = 'APPEARANCE_TOOLTIP_BORDER_SPACING',
  APPEARANCE_TOOLTIP_BORDER_WIDTH = 'APPEARANCE_TOOLTIP_BORDER_WIDTH',
  APPEARANCE_TOOLTIP_CANVAS = 'APPEARANCE_TOOLTIP_CANVAS',
  APPEARANCE_TOOLTIP_EMPTY = 'APPEARANCE_TOOLTIP_EMPTY',
  APPEARANCE_TOOLTIP_HEADER_LOGO = 'APPEARANCE_TOOLTIP_HEADER_LOGO',
  APPEARANCE_TOOLTIP_MUTE = 'APPEARANCE_TOOLTIP_MUTE',
  APPEARANCE_TOOLTIP_PREVIEW = 'APPEARANCE_TOOLTIP_PREVIEW',
  APPEARANCE_TOOLTIP_SINGLE_USER = 'APPEARANCE_TOOLTIP_SINGLE_USER',
  APPEARANCE_TOOLTIP_SPLASH = 'APPEARANCE_TOOLTIP_SPLASH',
  APPLY = 'APPLY',
  ASSIGN_ROLE_INSTRUX = 'ASSIGN_ROLE_INSTRUX',
  AUDIENCE = 'AUDIENCE',
  AUDIO = 'AUDIO',
  AUDIO_MUTE = 'AUDIO_MUTE',
  AUDIO_UNMUTE = 'AUDIO_UNMUTE',
  AUTO_END = 'AUTO_END',
  AUTO_GAIN_CONTROL = 'AUTO_GAIN_CONTROL',
  AUTO_OPEN_ROOM_NAV = 'AUTO_OPEN_ROOM_NAV',
  AUTOPLAY_URL = 'AUTOPLAY_URL',
  AVAILABLE_FORMATS = 'AVAILABLE_FORMATS',
  BACK_TO_HOME = 'BACK_TO_HOME',
  BANNER_DISPLAY_OPTION = 'BANNER_DISPLAY_OPTION',
  BANNER_LOGO = 'BANNER_LOGO',
  BANNER_PLACEHOLDER = 'BANNER_PLACEHOLDER',
  BANNER_TEXT_COLOR = 'BANNER_TEXT_COLOR',
  BASIC_INFO = 'BASIC_INFO',
  BORDER_COLOR = 'BORDER_COLOR',
  BORDER_SPACING = 'BORDER_SPACING',
  BORDER_WIDTH = 'BORDER_WIDTH',
  BROADCAST_ERROR = 'BROADCAST_ERROR',
  BROADCAST_TO_LOCAL = 'BROADCAST_TO_LOCAL',
  BROADCAST_TO_REMOTE = 'BROADCAST_TO_REMOTE',
  BROWSER_DOES_NOT_SUPPORT_VIDEO = 'BROWSER_DOES_NOT_SUPPORT_VIDEO',
  CALL_USER = 'CALL_USER',
  CAMERA = 'CAMERA',
  CAMERA_DISABLED = 'CAMERA_DISABLED',
  CAMERA_OPTIONS = 'CAMERA_OPTIONS',
  CANCEL = 'CANCEL',
  CANVAS = 'CANVAS',
  CANVAS_LAYOUT = 'CANVAS_LAYOUT',
  CHANGE_DEVICES = 'CHANGE_DEVICES',
  CHAT = 'CHAT',
  CHECK_BROWSER_PERMISSIONS = 'CHECK_BROWSER_PERMISSIONS',
  CHECK_YOUR_DEVICE = 'CHECK_YOUR_DEVICE',
  CHECK_YOUR_EMAIL = 'CHECK_YOUR_EMAIL',
  CHOOSE_GROUP_ROLE = 'CHOOSE_GROUP_ROLE',
  CHOOSE_NEW_PASSWORD = 'CHOOSE_NEW_PASSWORD',
  CHOOSE_RECORDING_OR_BROADCAST = 'CHOOSE_RECORDING_OR_BROADCAST',
  CHOOSE_ROOM_ROLE = 'CHOOSE_ROOM_ROLE',
  CHOOSE_USER_ROLE = 'CHOOSE_USER_ROLE',
  CLEAR_BANNER = 'CLEAR_BANNER',
  CLEAR_FILTER = 'CLEAR_FILTER',
  CLEAR_INPUT = 'CLEAR_INPUT',
  CLICK_AGAIN_TO_CONFIRM = 'CLICK_AGAIN_TO_CONFIRM',
  CLICK_TO_CHOOSE_A_COLOR = 'CLICK_TO_CHOOSE_A_COLOR',
  CLICK_TO_PLAY = 'CLICK_TO_PLAY',
  CLIPEEZE = 'CLIPEEZE',
  CLIPEEZE_ADDED = 'CLIPEEZE_ADDED',
  CLIPEEZE_DELETE_ERROR = 'CLIPEEZE_DELETE_ERROR',
  CLIPEEZE_DELETE_SUCCESS = 'CLIPEEZE_DELETE_SUCCESS',
  CLIPEEZE_FILE_TYPE_ERROR = 'CLIPEEZE_FILE_TYPE_ERROR',
  CLIPEEZE_FORM_ERROR = 'CLIPEEZE_FORM_ERROR',
  CLIPEEZE_LIST_ERROR = 'CLIPEEZE_LIST_ERROR',
  CLIPEEZE_NO_RESULTS = 'CLIPEEZE_NO_RESULTS',
  CLIPEEZE_ROLE = 'CLIPEEZE_ROLE',
  CLIPEEZE_STATUS_FAILED = 'CLIPEEZE_STATUS_FAILED',
  CLIPEEZE_STATUS_PROCESSING = 'CLIPEEZE_STATUS_PROCESSING',
  CLIPEEZE_UPLOAD_ERROR = 'CLIPEEZE_UPLOAD_ERROR',
  CLOSE = 'CLOSE',
  CLOSE_SIDEBAR_FOR_ALL = 'CLOSE_SIDEBAR_FOR_ALL',
  COMPANY = 'COMPANY',
  COMPLETE_TRANSFER = 'COMPLETE_TRANSFER',
  COMPLETE_YOUR_PROFILE = 'COMPLETE_YOUR_PROFILE',
  CONFERENCE_ROOM = 'CONFERENCE_ROOM',
  CONFIRM = 'CONFIRM',
  CONNECTING = 'CONNECTING',
  CONNECTION_ISSUE_DEVICES = 'CONNECTION_ISSUE_DEVICES',
  CONTINUE = 'CONTINUE',
  COPIED = 'COPIED',
  COPIED_TO_CLIPBOARD = 'COPIED_TO_CLIPBOARD',
  COPY = 'COPY',
  COPY_INVITE_LINK = 'COPY_INVITE_LINK',
  COPY_INVITE_LINK_SUCCESS = 'COPY_INVITE_LINK_SUCCESS',
  COPY_URL_TO_CLIPBOARD = 'COPY_URL_TO_CLIPBOARD',
  CREATE_GROUP = 'CREATE_GROUP',
  CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS',
  CREATE_GROUP_ERROR = 'CREATE_GROUP_ERROR',
  CREATE_NEW_GROUP = 'CREATE_NEW_GROUP',
  CREATE_NEW_ROOM = 'CREATE_NEW_ROOM',
  CREATE_NEW_USER = 'CREATE_NEW_USER',
  CREATE_ROOM = 'CREATE_ROOM',
  CREATE_ROOM_ERROR = 'CREATE_ROOM_ERROR',
  CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS',
  CREATE_SIDEBAR = 'CREATE_SIDEBAR',
  CREATE_THIS_ROOM = 'CREATE_THIS_ROOM',
  CREATE_USER = 'CREATE_USER',
  CREATE_USER_ERROR = 'CREATE_USER_ERROR',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  CURRENT_PERFORMER = 'CURRENT_PERFORMER',
  CURRENT_PIN = 'CURRENT_PIN',
  CUSTOM_APP_LABEL = 'CUSTOM_APP_LABEL',
  CUSTOM_BUTTON_LINK = 'CUSTOM_BUTTON_LINK',
  CUSTOM_BUTTON_NAME = 'CUSTOM_BUTTON_NAME',
  CUSTOM_BUTTONS = 'CUSTOM_BUTTONS',
  CUSTOM_COMPANY_LABEL = 'CUSTOM_COMPANY_LABEL',
  CUSTOM_NAME_LABEL = 'CUSTOM_NAME_LABEL',
  DARK_MODE = 'DARK_MODE',
  DATE = 'DATE',
  DECLINE = 'DECLINE',
  DEFAULT_FLAGS = 'DEFAULT_FLAGS',
  DEFAULT_SMS_NUMBER = 'DEFAULT_SMS_NUMBER',
  DELETED_FROM_SYSTEM = 'DELETED_FROM_SYSTEM',
  DEVICE_CONNECTION_ERROR = 'DEVICE_CONNECTION_ERROR',
  DEVICE_OPTIONS = 'DEVICE_OPTIONS',
  DEVICES = 'DEVICES',
  DEVICES_ERROR_DEFAULT = 'DEVICES_ERROR_DEFAULT',
  DEVICES_ERROR_NOT_ALLOWED = 'DEVICES_ERROR_NOT_ALLOWED',
  DIALING = 'DIALING',
  DISABLE_AUTO_ROLE = 'DISABLE_AUTO_ROLE',
  DISABLE_CHAT = 'DISABLE_CHAT',
  DISPLAY_BANNER = 'DISPLAY_BANNER',
  DISPLAY_BANNERS = 'DISPLAY_BANNERS',
  DND_OFF = 'DND_OFF',
  DND_ON = 'DND_ON',
  DND_UNAVAILABLE = 'DND_UNAVAILABLE',
  DO_NOT_DISTURB_OFF = 'DO_NOT_DISTURB_OFF',
  DO_NOT_DISTURB_ON = 'DO_NOT_DISTURB_ON',
  DOMAIN_NOT_CONFIGURED = 'DOMAIN_NOT_CONFIGURED',
  DOMAIN_NOT_CONFIGURED_BYLINE = 'DOMAIN_NOT_CONFIGURED_BYLINE',
  DOWNLOAD = 'DOWNLOAD',
  DUPLICATE_ROOM = 'DUPLICATE_ROOM',
  DURATION = 'DURATION',
  ECHO_CANCELLATION = 'ECHO_CANCELLATION',
  EDIT = 'EDIT',
  EDIT_SIDEBAR = 'EDIT_SIDEBAR',
  EDIT_USER = 'EDIT_USER',
  EMAIL = 'EMAIL',
  EMPTY = 'EMPTY',
  EMPTY_ROOM = 'EMPTY_ROOM',
  ENABLE_CAMERA = 'ENABLE_CAMERA',
  ENABLE_CLIPEEZE = 'ENABLE_CLIPEEZE',
  ENABLE_MICROPHONE = 'ENABLE_MICROPHONE',
  ENTER_EMAIL = 'ENTER_EMAIL',
  ENTER_SIDEBAR_NAME = 'ENTER_SIDEBAR_NAME',
  ENTER_STREAMING_URL = 'ENTER_STREAMING_URL',
  EXIT = 'EXIT',
  FEATURE_IS_UNAVAILABLE_IN_SIDEBAR = 'FEATURE_IS_UNAVAILABLE_IN_SIDEBAR',
  FILE_URL_TO_PLAY = 'FILE_URL_TO_PLAY',
  FIND_A_ROOM = 'FIND_A_ROOM',
  FIND_OR_CREATE_A_ROOM = 'FIND_OR_CREATE_A_ROOM',
  FIND_CLIPEEZE = 'FIND_CLIPEEZE',
  FINISH_EDITING = 'FINISH_EDITING',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORMAT = 'FORMAT',
  FULL_SCREEN = 'FULL_SCREEN',
  GLOBAL = 'GLOBAL',
  MY_PROFILE = 'MY_PROFILE',
  GRANT_MODERATOR = 'GRANT_MODERATOR',
  GRANT_SCREEN_SHARE = 'GRANT_SCREEN_SHARE',
  GRANT_VISIBILITY = 'GRANT_VISIBILITY',
  GROUP = 'GROUP',
  GROUP_BY = 'GROUP_BY',
  GROUP_NAME = 'GROUP_NAME',
  GROUP_NO_MEMBERSHIPS = 'GROUP_NO_MEMBERSHIPS',
  GROUP_ROLE_MAINTAINER_DESC = 'GROUP_ROLE_MAINTAINER_DESC',
  GROUP_ROLE_MAINTAINER_LABEL = 'GROUP_ROLE_MAINTAINER_LABEL',
  GROUP_ROLE_MEMBER_DESC = 'GROUP_ROLE_MEMBER_DESC',
  GROUP_ROLE_MEMBER_LABEL = 'GROUP_ROLE_MEMBER_LABEL',
  GROUPS = 'GROUPS',
  HAND_LOWERED = 'HAND_LOWERED',
  HAND_RAISED = 'HAND_RAISED',
  HANDS_RAISED = 'HANDS_RAISED',
  HEADER_LOGO = 'HEADER_LOGO',
  HELLO = 'HELLO',
  HELP = 'HELP',
  HIDE_ADVANCED_OPTIONS = 'HIDE_ADVANCED_OPTIONS',
  HIDE_BANNERS = 'HIDE_BANNERS',
  HIDE_VIDEO_MUTED = 'HIDE_VIDEO_MUTED',
  HIDE_VIDEO = 'HIDE_VIDEO',
  HIGHLIGHT_SPEAKER = 'HIGHLIGHT_SPEAKER',
  HOME = 'HOME',
  HOME_SCREEN = 'HOME_SCREEN',
  IGNORE = 'IGNORE',
  IMAGE = 'IMAGE',
  INBOUND_CALL = 'INBOUND_CALL',
  INHERITED_GLOBAL_PERMISSIONS = 'INHERITED_GLOBAL_PERMISSIONS',
  INPUT_ERROR_TELEPHONE = 'INPUT_ERROR_TELEPHONE',
  INVALID_HTTPS_URL = 'INVALID_HTTPS_URL',
  INVALID_URL = 'INVALID_URL',
  INVALID_VIDEO_URL = 'INVALID_VIDEO_URL',
  INVITE = 'INVITE',
  INVITE_BY_CALL_ERROR = 'INVITE_BY_CALL_ERROR',
  INVITE_BY_SMS_ERROR = 'INVITE_BY_SMS_ERROR',
  INVITE_BY_CALL = 'INVITE_BY_CALL',
  INVITE_BY_CALL_DESCRIPTION = 'INVITE_BY_CALL_DESCRIPTION',
  INVITE_BY_LINK = 'INVITE_BY_LINK',
  INVITE_BY_LINK_DESCRIPTION = 'INVITE_BY_LINK_DESCRIPTION',
  INVITE_BY_SMS = 'INVITE_BY_SMS',
  INVITE_BY_SMS_DESCRIPTION = 'INVITE_BY_SMS_DESCRIPTION',
  IS_CALLING_YOU = 'IS_CALLING_YOU',
  JOIN = 'JOIN',
  JOIN_AS_GUEST = 'JOIN_AS_GUEST',
  JOIN_ROOM = 'JOIN_ROOM',
  JOIN_THIS_ROOM = 'JOIN_THIS_ROOM',
  KEYBOARD_SHORTCUT = 'KEYBOARD_SHORTCUT',
  KNOCK_MODAL_BYLINE = 'KNOCK_MODAL_BYLINE',
  KNOCK_MODAL_CONFIRM = 'KNOCK_MODAL_CONFIRM',
  KNOCK_MODAL_TITLE = 'KNOCK_MODAL_TITLE',
  LAYOUT_ROLE = 'LAYOUT_ROLE',
  LEARN_MORE = 'LEARN_MORE',
  LEAVE = 'LEAVE',
  LEAVE_BROADCAST = 'LEAVE_BROADCAST',
  LEAVE_ROOM = 'LEAVE_ROOM',
  LEAVE_SIDEBAR = 'LEAVE_SIDEBAR',
  LOADING = 'LOADING',
  LOADING_FAILURE_MSG = 'LOADING_FAILURE_MSG',
  LOBBY_FOOTER = 'LOBBY_FOOTER',
  LOBBY_HEADER = 'LOBBY_HEADER',
  LOCK_ROOM = 'LOCK_ROOM',
  LOG_IN = 'LOG_IN',
  LOG_OUT = 'LOG_OUT',
  LOOP_CLIP = 'LOOP_CLIP',
  LOW_BITRATE_MODE = 'LOW_BITRATE_MODE',
  LOWER_ALL_HANDS = 'LOWER_ALL_HANDS',
  LOWER_HAND = 'LOWER_HAND',
  MAINTAINER = 'MAINTAINER',
  MAINTAINER_plural = 'MAINTAINER_plural',
  MEDIA = 'MEDIA',
  MEETING_IN_PROGRESS = 'MEETING_IN_PROGRESS',
  MEETING_MODE = 'MEETING_MODE',
  MENU = 'MENU',
  MEMBER = 'MEMBER',
  MEMBER_plural = 'MEMBER_plural',
  MIC_VOLUME = 'MIC_VOLUME',
  MICROPHONE = 'MICROPHONE',
  MICROPHONE_HAS_BEEN_MUTED = 'MICROPHONE_HAS_BEEN_MUTED',
  MICROPHONE_MUTED = 'MICROPHONE_MUTED',
  MICROPHONE_ON = 'MICROPHONE_ON',
  MICROPHONE_OPTIONS = 'MICROPHONE_OPTIONS',
  MIRROR_VIDEO = 'MIRROR_VIDEO',
  MODERATOR = 'MODERATOR',
  MODERATOR_PIN = 'MODERATOR_PIN',
  MORE = 'MORE',
  MOTION_QUALITY = 'MOTION_QUALITY',
  MOTION_QUALITY_INBOUND = 'MOTION_QUALITY_INBOUND',
  MUST_BE_BETWEEN = 'MUST_BE_BETWEEN',
  MUST_BE_GREATER_ZERO = 'MUST_BE_GREATER_ZERO',
  MUTE = 'MUTE',
  MUTE_ALL = 'MUTE_ALL',
  MY_ACCOUNT = 'MY_ACCOUNT',
  NAME = 'NAME',
  NEW_PASSWORD = 'NEW_PASSWORD',
  NO_ACTIVE_DEVICES = 'NO_ACTIVE_DEVICES',
  NO_CLIPEEZE_FOUND = 'NO_CLIPEEZE_FOUND',
  NO_PARTICIPANTS_FOUND = 'NO_PARTICIPANTS_FOUND',
  NO_PARTICIPANTS_GROUP = 'NO_PARTICIPANTS_GROUP',
  NO_REQUESTS_TO_JOIN = 'NO_REQUESTS_TO_JOIN',
  NO_RESULTS = 'NO_RESULTS',
  NO_ROOMS_AVAILABLE = 'NO_ROOMS_AVAILABLE',
  NO_ROOMS_IN_USE = 'NO_ROOMS_IN_USE',
  NO_ROOMS_INSTRUX = 'NO_ROOMS_INSTRUX',
  NO_USERS_AVAILABLE = 'NO_USERS_AVAILABLE',
  NOISE_GATE = 'NOISE_GATE',
  NOISE_SUPPRESSION = 'NOISE_SUPPRESSION',
  NONE = 'NONE',
  NOT_YOU = 'NOT_YOU',
  NOTICE = 'NOTICE',
  NOTICES = 'NOTICES',
  NUMBERS_ONLY = 'NUMBERS_ONLY',
  OCCUPIED_ROOM_PREVIEW = 'OCCUPIED_ROOM_PREVIEW',
  OF = 'OF',
  OK = 'OK',
  ONE_WAY_CHAT = 'ONE_WAY_CHAT',
  OPEN_IN_A_NEW_TAB = 'OPEN_IN_A_NEW_TAB',
  OPTIONAL = 'OPTIONAL',
  OPTIONS = 'OPTIONS',
  ORDER_PRIORITY = 'ORDER_PRIORITY',
  PARTICIPANT_LIST = 'PARTICIPANT_LIST',
  PARTICIPANT_OPTIONS = 'PARTICIPANT_OPTIONS',
  PASSWORD_CREATION_FAILED = 'PASSWORD_CREATION_FAILED',
  PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR',
  PASSWORD_RESET_LINK_SENT = 'PASSWORD_RESET_LINK_SENT',
  PASSWORD_RESET_INSTRUX = 'PASSWORD_RESET_INSTRUX',
  PASSWORD_RESET_TOKEN_EXPIRED = 'PASSWORD_RESET_TOKEN_EXPIRED',
  PAUSE = 'PAUSE',
  PENDING_INVITE = 'PENDING_INVITE',
  PERMISSIONS = 'PERMISSIONS',
  PERMISSIONS_TOOLTIP_GLOBAL_PERMISSIONS = 'PERMISSIONS_TOOLTIP_GLOBAL_PERMISSIONS',
  PHONE = 'PHONE',
  PICTURE_IN_PICTURE = 'PICTURE_IN_PICTURE',
  PIN = 'PIN',
  PIN_ERROR_INVALID = 'PIN_ERROR_INVALID',
  PIN_ERROR_LENGTH = 'PIN_ERROR_LENGTH',
  PIN_REQUIRED = 'PIN_REQUIRED',
  PLAY = 'PLAY',
  PLAY_AUDIO_TEST = 'PLAY_AUDIO_TEST',
  PLAY_CLIP_FULLSCREEN = 'PLAY_CLIP_FULLSCREEN',
  PLAY_MEDIA = 'PLAY_MEDIA',
  PLAY_WITH_AUDIO = 'PLAY_WITH_AUDIO',
  PLAYBACK_SETTINGS = 'PLAYBACK_SETTINGS',
  PLEASE_ENTER_A_VALID_EMAIL = 'PLEASE_ENTER_A_VALID_EMAIL',
  POP_OUT = 'POP_OUT',
  PREJOIN_INSTRUX_1 = 'PREJOIN_INSTRUX_1',
  PREJOIN_INSTRUX_2 = 'PREJOIN_INSTRUX_2',
  PREJOIN_INSTRUX_3 = 'PREJOIN_INSTRUX_3',
  PREJOIN_INSTRUX_FIREFOX = 'PREJOIN_INSTRUX_FIREFOX',
  PREVENT_AUDIO_UNMUTING = 'PREVENT_AUDIO_UNMUTING',
  PRIORITIZE_HAND_RAISING = 'PRIORITIZE_HAND_RAISING',
  PREVENT_VIDEO_UNMUTING = 'PREVENT_VIDEO_UNMUTING',
  PREVIEW = 'PREVIEW',
  PROFILE_IMAGE = 'PROFILE_IMAGE',
  PUSH_TO_TALK = 'PUSH_TO_TALK',
  QUICK_ACTIONS = 'QUICK_ACTIONS',
  RAISE_HAND = 'RAISE_HAND',
  RECORD_TO_FILE = 'RECORD_TO_FILE',
  RECORD_TO_FILE_BACKUP = 'RECORD_TO_FILE_BACKUP',
  RECORDING = 'RECORDING',
  RECORDINGS = 'RECORDINGS',
  RECORDINGS_CHOOSE_ROOM = 'RECORDINGS_CHOOSE_ROOM',
  RECORDINGS_NO_RESULTS = 'RECORDINGS_NO_RESULTS',
  REFRESH = 'REFRESH',
  REFRESH_DEVICES = 'REFRESH_DEVICES',
  REMOVE = 'REMOVE',
  REMOVE_EVERYONE = 'REMOVE_EVERYONE',
  REMOVE_BACKGROUND_NOISE = 'REMOVE_BACKGROUND_NOISE',
  REMOVE_FROM_THE_ROOM = 'REMOVE_FROM_THE_ROOM',
  REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT',
  REMOVE_USER = 'REMOVE_USER',
  REMOVE_USER_FROM_GROUP_FAILURE = 'REMOVE_USER_FROM_GROUP_FAILURE',
  REMOVE_USER_FROM_GROUP_SUCCESS = 'REMOVE_USER_FROM_GROUP_SUCCESS',
  REQUEST_TO_JOIN_SIDEBAR_DENIED = 'REQUEST_TO_JOIN_SIDEBAR_DENIED',
  REQUIRE_MODERATOR = 'REQUIRE_MODERATOR',
  RESERVED_FOR = 'RESERVED_FOR',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RETRY = 'RETRY',
  ROLE = 'ROLE',
  ROLES_ASSIGNED = 'ROLES_ASSIGNED',
  ROOM = 'ROOM',
  ROOM_ACTIONS = 'ROOM_ACTIONS',
  ROOM_CONTROLS = 'ROOM_CONTROLS',
  ROOM_CUSTOMIZE_THEME = 'ROOM_CUSTOMIZE_THEME',
  ROOM_DESCR = 'ROOM_DESCR',
  ROOM_LOCKED = 'ROOM_LOCKED',
  ROOM_NAME = 'ROOM_NAME',
  ROOM_NAVIGATOR = 'ROOM_NAVIGATOR',
  ROOM_OPTIONS = 'ROOM_OPTIONS',
  ROOM_PIN = 'ROOM_PIN',
  ROOM_PERMISSIONS = 'ROOM_PERMISSIONS',
  ROOM_PREVIEW = 'ROOM_PREVIEW',
  ROOM_PREVIEW_IMAGE = 'ROOM_PREVIEW_IMAGE',
  ROOM_RECORDING = 'ROOM_RECORDING',
  ROOM_ROLE_ATTENDEE_DESC = 'ROOM_ROLE_ATTENDEE_DESC',
  ROOM_ROLE_ATTENDEE_LABEL = 'ROOM_ROLE_ATTENDEE_LABEL',
  ROOM_ROLE_INVITEABLE_DESC = 'ROOM_ROLE_INVITEABLE_DESC',
  ROOM_ROLE_INVITEABLE_LABEL = 'ROOM_ROLE_INVITEABLE_LABEL',
  ROOM_ROLE_MANAGER_DESC = 'ROOM_ROLE_MANAGER_DESC',
  ROOM_ROLE_MANAGER_LABEL = 'ROOM_ROLE_MANAGER_LABEL',
  ROOM_ROLE_MODERATOR_DESC = 'ROOM_ROLE_MODERATOR_DESC',
  ROOM_ROLE_MODERATOR_LABEL = 'ROOM_ROLE_MODERATOR_LABEL',
  ROOM_ROLE_VISITOR_DESC = 'ROOM_ROLE_VISITOR_DESC',
  ROOM_ROLE_VISITOR_LABEL = 'ROOM_ROLE_VISITOR_LABEL',
  ROOM_SEARCH = 'ROOM_SEARCH',
  ROOM_SECURITY = 'ROOM_SECURITY',
  ROOM_SETTINGS = 'ROOM_SETTINGS',
  ROOM_VISIBILITY = 'ROOM_VISIBILITY',
  ROOM_VOLUME = 'ROOM_VOLUME',
  ROOMS = 'ROOMS',
  ROOMS_SECURITY = 'ROOMS_SECURITY',
  SAVE = 'SAVE',
  SCREEN_SHARING = 'SCREEN_SHARING',
  SCREEN_SHARING_PERMISSION = 'SCREEN_SHARING_PERMISSION',
  SEARCH = 'SEARCH',
  SEARCH_USERS = 'SEARCH_USERS',
  SEARCH_USERS_OR_GROUPS = 'SEARCH_USERS_OR_GROUPS',
  SEEK_FORWARD = 'SEEK_FORWARD',
  SEEK_BACK = 'SEEK_BACK',
  SEEK_SPEED = 'SEEK_SPEED',
  SELECT_DEVICES = 'SELECT_DEVICES',
  SELECT_ITEMS = 'SELECT_ITEMS',
  SELECT_PARTICIPANTS = 'SELECT_PARTICIPANTS',
  SELECT_PEOPLE_YOU_WANT_TO = 'SELECT_PEOPLE_YOU_WANT_TO',
  SELECT_USERS = 'SELECT_USERS',
  SELECT_USERS_OR_GROUPS = 'SELECT_USERS_OR_GROUPS',
  SELECTED = 'SELECTED',
  SEND = 'SEND',
  SEND_INVITATION_EMAIL = 'SEND_INVITATION_EMAIL',
  SEND_RESET_LINK = 'SEND_RESET_LINK',
  SET_AUTO_ROLE = 'SET_AUTO_ROLE',
  SET_CUSTOM_BANNER = 'SET_CUSTOM_BANNER',
  SHARE_YOUR_SCREEN = 'SHARE_YOUR_SCREEN',
  SHOW_ADVANCED_OPTIONS = 'SHOW_ADVANCED_OPTIONS',
  SHOW_ROOM_RECORDINGS = 'SHOW_ROOM_RECORDINGS',
  SHOW_VIDEO = 'SHOW_VIDEO',
  SHUFFLE_NOW = 'SHUFFLE_NOW',
  SHUFFLE_VIDEO = 'SHUFFLE_VIDEO',
  SHUFFLE_VIDEO_LEARN_MORE = 'SHUFFLE_VIDEO_LEARN_MORE',
  SIDEBAR = 'SIDEBAR',
  SIDEBAR_HAS_ENDED = 'SIDEBAR_HAS_ENDED',
  SIDEBAR_INVITATION = 'SIDEBAR_INVITATION',
  SIDEBAR_INVITATION_BYLINE = 'SIDEBAR_INVITATION_BYLINE',
  SIDEBAR_INVITATION_DECLINED = 'SIDEBAR_INVITATION_DECLINED',
  SIDEBAR_INVITATION_INSTRUCTIONS = 'SIDEBAR_INVITATION_INSTRUCTIONS',
  SIDEBAR_KICKED = 'SIDEBAR_KICKED',
  SIDEBAR_NAME_ERROR = 'SIDEBAR_NAME_ERROR',
  SIDEBAR_REQUEST_TO_JOIN = 'SIDEBAR_REQUEST_TO_JOIN',
  SIDEBAR_REQUEST_TO_JOIN_BYLINE = 'SIDEBAR_REQUEST_TO_JOIN_BYLINE',
  SIDEBAR_REQUEST_TO_JOIN_SENT = 'SIDEBAR_REQUEST_TO_JOIN_SENT',
  SIGN_IN_NOTIFICATION = 'SIGN_IN_NOTIFICATION',
  SIGNALWIRE_INTEGRATION = 'SIGNALWIRE_INTEGRATION',
  SINGLE_USER = 'SINGLE_USER',
  SKIP_THIS_PAGE = 'SKIP_THIS_PAGE',
  SMS_USER = 'SMS_USER',
  SOMEONE_DENIED_FROM_SIDEBAR = 'SOMEONE_DENIED_FROM_SIDEBAR',
  SOMEONE_JOINED_THE_SIDEBAR = 'SOMEONE_JOINED_THE_SIDEBAR',
  SPEAKER = 'SPEAKER',
  SPEAKER_CONTROLS = 'SPEAKER_CONTROLS',
  SPEAKER_VOLUME = 'SPEAKER_VOLUME',
  SPECIAL_VISIBILITY_ERROR = 'SPECIAL_VISIBILITY_ERROR',
  SPLASH = 'SPLASH',
  START_A_SIDEBAR = 'START_A_SIDEBAR',
  START_A_SIDEBAR_BYLINE = 'START_A_SIDEBAR_BYLINE',
  START_BROADCAST = 'START_BROADCAST',
  START_RECORDING = 'START_RECORDING',
  START_SIDEBAR = 'START_SIDEBAR',
  STEREO_AUDIO = 'STEREO_AUDIO',
  STOP = 'STOP',
  STOP_BROADCAST = 'STOP_BROADCAST',
  STOP_RECORDING = 'STOP_RECORDING',
  STOP_SHARING = 'STOP_SHARING',
  STREAM_ERROR = 'STREAM_ERROR',
  SUCCESSFUL_DELETE = 'SUCCESSFUL_DELETE',
  TALKING = 'TALKING',
  TEXT_TO_SPEECH_LABEL = 'TEXT_TO_SPEECH_LABEL',
  TEXT_USER = 'TEXT_USER',
  THUMBNAIL = 'THUMBNAIL',
  TOGGLE = 'TOGGLE',
  TRANSFER_TO = 'TRANSFER_TO',
  UNKNOWN_EMAIL = 'UNKNOWN_EMAIL',
  UNMUTE_ALL = 'UNMUTE_ALL',
  UNSUCCESSFUL_DELETE = 'UNSUCCESSFUL_DELETE',
  UPDATE = 'UPDATE',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  UPLOAD = 'UPLOAD',
  UPLOAD_A_NEW_CLIPEEZE = 'UPLOAD_A_NEW_CLIPEEZE',
  UPLOAD_IN_PROGRESS_MSG = 'UPLOAD_IN_PROGRESS_MSG',
  USE_THESE_DEVICES = 'USE_THESE_DEVICES',
  USER = 'USER',
  USER_BANNERS = 'USER_BANNERS',
  USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS',
  USER_ROLE = 'USER_ROLE',
  USER_ROLE_ADMINISTRATOR_DESC = 'USER_ROLE_ADMINISTRATOR_DESC',
  USER_ROLE_ADMINISTRATOR_LABEL = 'USER_ROLE_ADMINISTRATOR_LABEL',
  USER_ROLE_MEMBER_DESC = 'USER_ROLE_MEMBER_DESC',
  USER_ROLE_MEMBER_LABEL = 'USER_ROLE_MEMBER_LABEL',
  USERS = 'USERS',
  USERS_NO_RESULTS = 'USERS_NO_RESULTS',
  VERSION = 'VERSION',
  VIDEO = 'VIDEO',
  VIDEO_FULL_SCREEN = 'VIDEO_FULL_SCREEN',
  VIDEO_MUTE = 'VIDEO_MUTE',
  VIDEO_OFF = 'VIDEO_OFF',
  VIDEO_ON = 'VIDEO_ON',
  VIDEO_PLAYBACK_ROLE = 'VIDEO_PLAYBACK_ROLE',
  VIDEO_QUALITY = 'VIDEO_QUALITY',
  VIDEO_RECORDING_QUALITY_DROPDOWN_ERROR = 'VIDEO_RECORDING_QUALITY_DROPDOWN_ERROR',
  VIDEO_SETTINGS = 'VIDEO_SETTINGS',
  VIDEO_UNMUTE = 'VIDEO_UNMUTE',
  VISIBILITY = 'VISIBILITY',
  VOLUME = 'VOLUME',
  WELCOME = 'WELCOME',
  WARN_BEFORE_JOIN = 'WARN_BEFORE_JOIN',
  WAS_NOT_FOUND = 'WAS_NOT_FOUND',
  WEB_PLAYER = 'WEB_PLAYER',
  WIDTH_IN_PIXELS = 'WIDTH_IN_PIXELS',
  YOU_ARE_JOINING = 'YOU_ARE_JOINING',
}
