import { cantinaFetch } from '.'

type DefaultLayoutsParams = {
  url: string
  token: string
}

interface ResIDS {
  name: string
  label: string
}
interface Layout {
  type: 'layout'
  name: string
  displayName: string
  resIds: ResIDS[]
}

interface GroupLayout {
  type: 'group'
  name: string
  displayName: string
  groupLayouts: string[]
}

export type DefaultLayoutsList = (Layout | GroupLayout)[]

export const defaultLayouts = ({
  url,
  token,
}: DefaultLayoutsParams): Promise<{ layouts: DefaultLayoutsList }> => {
  return cantinaFetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}
