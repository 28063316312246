import { useState } from 'react'
import { RailsEndpoint, RequestState } from '../rest'
import { toastError, toastSuccess } from '../services/relay'
import { RoutePath, CURRENT_HOSTNAME } from '../constants'
import { useInternalAPI } from './useInternalAPI'

type UseSendInvitationEmailData = {
  error: string | null
  requestState: RequestState
}

const DEFAULT_DATA: UseSendInvitationEmailData = {
  error: null,
  requestState: RequestState.Loading,
}

const fetchOptions = {
  endpoint: RailsEndpoint.Users,
  method: 'POST',
}

export const useSendInvitationEmail = (userId: string) => {
  const { invokeAPI: sendHandler } = useInternalAPI(fetchOptions)
  const [data, setData] = useState<UseSendInvitationEmailData>(DEFAULT_DATA)

  const sendInvitation = async () => {
    try {
      setData(DEFAULT_DATA)
      await sendHandler({
        urlTransform: (url) => `${url}/${userId}/send_invitation_email`,
        body: JSON.stringify({
          domain: CURRENT_HOSTNAME,
          invitation_url: `https://${CURRENT_HOSTNAME}${RoutePath.UserSetup}`,
        }),
      })
      toastSuccess('Invite successfully sent!')
      setData({
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      toastError('An error occurred while sending the email.')
      setData({
        requestState: RequestState.Error,
        error: 'Error sending the invitation email.',
      })
    }
  }

  return { ...data, sendInvitation }
}
