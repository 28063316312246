import React, { FC } from 'react'
import { ButtonGroup } from 'src/components/Button/ButtonGroup'
import { PrimaryButton, TertiaryButton } from 'src/components/Button/Button'

type ActionListFragmentProps = {
  children?: any
  confirmHandler: () => void
  confirmLabel: string
  confirmTestId: string
  declineHandler: () => void
  declineLabel: string
  declineTestId: string
}

const ActionListFragment: FC<ActionListFragmentProps> = ({
  children,
  confirmHandler,
  confirmLabel,
  confirmTestId,
  declineHandler,
  declineLabel,
  declineTestId,
}) => {
  return (
    <div className='border rounded border-gray-500 mb-4 p-2'>
      {children}
      <ButtonGroup>
        <TertiaryButton onClick={declineHandler} testId={declineTestId}>
          {declineLabel}
        </TertiaryButton>
        <PrimaryButton onClick={confirmHandler} testId={confirmTestId}>
          {confirmLabel}
        </PrimaryButton>
      </ButtonGroup>
    </div>
  )
}

export default ActionListFragment
