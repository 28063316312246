import React, { FC, ChangeEvent } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useDispatchRoomCommand } from 'src/common/hooks'
import { RoomCommandAction } from 'src/common/constants'
import { CantinaState } from 'src/common/redux/interfaces'
import { getPlayingSettings } from 'src/common/redux/features/rooms/roomsSelectors'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import { LayoutRolesDropdown } from 'src/components/CallSettings/LayoutRolesDropdown'

interface PlaybackLayoutRoleDropdownProps {
  className?: string
  roomId: string
}

export const PlaybackLayoutRoleDropdown: FC<PlaybackLayoutRoleDropdownProps> =
  ({ className = '', roomId }) => {
    const dispatchRoomCommand = useDispatchRoomCommand()
    const playingSettings = useSelector(
      (state: CantinaState) => getPlayingSettings(state, roomId),
      shallowEqual
    )
    const currentPlayingRole = playingSettings && playingSettings.role

    return (
      <LayoutRolesDropdown
        className={className}
        roomId={roomId}
        name='playbackRoles'
        label={tr(Label.VIDEO_PLAYBACK_ROLE)}
        onChange={({ target }: ChangeEvent<HTMLSelectElement>) => {
          // Change the role while playing
          dispatchRoomCommand({
            roomId,
            action: RoomCommandAction.SetPlaybackRole,
            reservationId: target.value,
          })
          // Set the role for other dropdowns
          dispatchRoomCommand({
            roomId,
            action: RoomCommandAction.SetFilePlaybackRole,
            reservationId: target.value,
          })
        }}
        selectedId={currentPlayingRole || ''}
        testId={TestId.MediaSelectPlaybackRole}
      />
    )
  }
