import React, { FC, ComponentType } from 'react'
import { CrispButton } from '../CrispButton'
import { AppLogo } from './AppLogo'
import { HamburgerButton } from './HamburgerButton'

type HeaderProps = {
  left?: ComponentType
  middle?: ComponentType | null
  right?: ComponentType
  className?: string
}

const HeaderAppLogo = () => <AppLogo />

export const Header: FC<HeaderProps> = ({
  left: LeftComponent = null,
  middle: MiddleComponent = HeaderAppLogo,
  right: RightComponent = null,
  className = '',
}) => (
  <header
    className={`sw-header flex items-center justify-between h-14 py-2 ${className}`}
  >
    <div className='hidden md:flex items-center justify-start flex-1 pl-2'>
      {LeftComponent && <LeftComponent />}
    </div>
    <div className='flex items-center justify-start md:justify-center flex-1 pl-2 md:pl-0'>
      {MiddleComponent && <MiddleComponent />}
    </div>
    <div className='flex items-center justify-end flex-1 pr-2 text-sm'>
      {RightComponent && <RightComponent />}
      <DefaultRight />
    </div>
  </header>
)

const DefaultRight = () => {
  return (
    <>
      <CrispButton />
      <HamburgerButton />
    </>
  )
}
