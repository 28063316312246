import React, { FC, useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { CantinaState, CanvasLayout } from 'src/common/redux/interfaces'
import { SignalIcon } from 'src/common/icons'
import { useFade, useRoomSelector } from 'src/common/hooks'
import {
  getCallCanvasParticipantLayers,
  getMyBase64Logo,
  isSendingVideo,
} from 'src/common/redux/features/calls/callSelectors'
import {
  hasAutoOverlay,
  hasBanner,
  hasLowBitrateMode,
  isTalking,
  hasNoLocalVideo,
  makeParticipantLayerSelector,
} from 'src/common/redux/features/participants/participantSelectors'
import { getRoomZone } from 'src/common/redux/features/roomZones/roomZonesSelectors'
import {
  getLastLayout,
  hasSpeakerHighlight,
} from 'src/common/redux/features/rooms/roomsSelectors'
import { withTextTooltip } from 'src/hoc/withTextTooltip'
import {
  ParticipantAudioMuteButton,
  ParticipantVideoMuteButton,
  ParticipantHandButton,
} from 'src/components/ParticipantCommandButtons'
import { ScopedParticipantSettingsButton } from 'src/components/CallButtons/ParticipantSettingsButton'
import { ScopedParticipantKnockButton } from 'src/components/CallButtons/ParticipantKnockButton'
import { LocalVideo, NoLocalVideo } from 'src/pages/Call/LocalVideo'
import { FlipLocalVideoButton } from 'src/components/CallButtons/FlipLocalVideoButton'
import Speaking from 'src/components/Speaking'
import { JoinZoneButton } from 'src/components/CallButtons/JoinZoneButton'

export const ParticipantLayers = ({
  callId,
  roomId,
}: {
  callId: string
  roomId: string
}) => {
  const speakerHighlight = useRoomSelector(hasSpeakerHighlight, roomId)
  const roomLayout = useRoomSelector(getLastLayout, roomId) as string
  const layouts = useSelector(
    (state: CantinaState) => getCallCanvasParticipantLayers(state, callId),
    shallowEqual
  )

  return (
    <>
      {layouts.map((layout) => (
        <UserLayer
          callId={callId}
          key={`part-${layout.participantId}`}
          layout={layout}
          roomLayout={roomLayout}
          speakerHighlight={speakerHighlight}
        />
      ))}
    </>
  )
}

interface TooltipProps {
  title: string
  subtitle?: string
}
const ParticipantInfoTooltip = ({ title, subtitle = '' }: TooltipProps) => {
  // Reveal the tooltip on group hover after a slight delay
  return withTextTooltip({
    className: 'opacity-0 group-hover:animate-delay-opacity z-40',
    title,
    subtitle,
    controlled: true,
    controlledShow: true,
  })(() => <div className='h-full w-full'>&nbsp;</div>)({})
}

type UserLayerProps = {
  callId: string
  layout: CanvasLayout
  roomLayout: string
  speakerHighlight?: boolean
}

const UserLayer: FC<UserLayerProps> = ({
  callId,
  layout,
  roomLayout,
  speakerHighlight = false,
}) => {
  const [optionsClass, showOptions, wrapper] = useFade({})
  const layerSelector = useMemo(makeParticipantLayerSelector, [])
  const info = useSelector(
    (state: CantinaState) =>
      layerSelector(state, layout.participantId, speakerHighlight),
    shallowEqual
  )
  const zone = useSelector(
    (state: CantinaState) => getRoomZone(state, info?.zoneId as string),
    shallowEqual
  )

  // If the screenshare/user is full-screen, hide the overlay because the
  // controls will conflict with those of the speaker video in the upper right
  if (!info || (info.isScreenReservation && roomLayout === 'screen-share')) {
    return null
  }

  const {
    isDeaf,
    isInTheSameZone,
    isBlurred,
    classNames,
    zoneId,
    name,
    subtitle,
  } = info
  const hasLocalVideoOverlay = layout.myLayout && !layout.treatedVideo

  // User is in Do Not Disturb mode
  const isDnd = !layout.myLayout && (isInTheSameZone || !zoneId) && isDeaf

  // User is in a sidebar, other than one the current user might be in
  const isInSidebar = !isInTheSameZone && zone

  // Note: Layout/style changes to the controls might also need to be made in
  //       pages/Call/LocalVideo.tsx
  return (
    <div className='absolute inset-0' style={layout.styles}>
      {hasLocalVideoOverlay && (
        <LocalVideoOverlay
          participantId={layout.participantId}
          callId={callId}
        />
      )}
      <div
        className={`relative user-menu group text-contrast-h w-full h-full ${classNames}`}
        ref={wrapper}
        style={{ color: zone?.color, borderColor: zone?.color }}
      >
        {!isBlurred && (
          <div className='absolute top-0 left-0 z-20'>
            <SpeakingIcon participantId={layout.participantId} />
          </div>
        )}
        {showOptions && (
          <ParticipantInfoTooltip
            title={name as string}
            subtitle={subtitle as string}
          />
        )}
        {showOptions && (
          <>
            {/* overflow-hidden to keep the button tooltips inside the user's video */}
            <div className='absolute inset-0 overflow-hidden'>
              <div
                className={`user-menu-items absolute inset-x-0 top-0 h-8 flex justify-end items-start text-right z-10 ${optionsClass}`}
              >
                <div className='inline-flex flex-wrap h-8 gap-y-2 justify-end items-center overflow-hidden'>
                  {/* Empty space the same width as a button/indicator, needed to wrap the next icon offscreen */}
                  <div className='w-12'>&nbsp;</div>
                  <LowBitrateModeIcon participantId={layout.participantId} />
                  {/* The controls will be hidden together */}
                  <div className='inline-flex'>
                    <ParticipantHandButton
                      className='raise-hand'
                      participantId={layout.participantId}
                      lowerOnly
                    />
                    {hasLocalVideoOverlay && <FlipLocalVideoButton />}
                    <ParticipantAudioMuteButton
                      participantId={layout.participantId}
                    />
                    <ParticipantVideoMuteButton
                      participantId={layout.participantId}
                    />
                  </div>
                </div>
                <div>
                  <ScopedParticipantSettingsButton
                    participantId={layout.participantId}
                    reservationId={layout.resID}
                    showTooltip
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {isInSidebar && (
          <div className='absolute inset-0 flex flex-col items-center justify-center text-white'>
            <span className='truncate w-full px-2 text-center'>
              {zone.name}
            </span>
            <JoinZoneButton
              zone={zone}
              className='!text-white mt-2 hidden md:block'
            />
          </div>
        )}
        {isDnd && (
          <div className='absolute inset-0'>
            <ScopedParticipantKnockButton
              participantId={layout.participantId}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export const SpeakingIcon = ({ participantId }: { participantId: string }) => {
  const talking = useSelector((state: CantinaState) =>
    isTalking(state, participantId)
  )
  return talking ? (
    <Speaking className='user-menu-indicator talking p-1' />
  ) : null
}

export const LowBitrateModeIcon = ({
  participantId,
}: {
  participantId: string
}) => {
  const lowBrMode = useSelector((state: CantinaState) =>
    hasLowBitrateMode(state, participantId)
  )
  if (!lowBrMode) {
    return null
  }
  return (
    <div className='user-menu-indicator text-sw-red'>
      <SignalIcon size='sm' fixedWidth />
    </div>
  )
}

const LocalVideoOverlay = ({
  callId,
  participantId,
}: {
  callId: string
  participantId: string
}) => {
  const sendingVideo = useSelector((state: CantinaState) =>
    isSendingVideo(state, callId)
  )
  const autoOverlay = useSelector((state: CantinaState) =>
    hasAutoOverlay(state, participantId)
  )
  const noLocalVideo = useSelector((state: CantinaState) =>
    hasNoLocalVideo(state, participantId)
  )
  if (sendingVideo && autoOverlay) {
    if (noLocalVideo) {
      return <NoLocalVideo callId={callId} />
    }
    return (
      <>
        <LocalVideo participantId={participantId} callId={callId} />
        <MyLogo participantId={participantId} />
      </>
    )
  }
  return null
}

const MyLogo = ({ participantId }: { participantId: string }) => {
  const logo = useSelector(getMyBase64Logo)
  const banner = useSelector((state: CantinaState) =>
    hasBanner(state, participantId)
  )
  if (banner && logo) {
    return <img className='absolute bottom-0 left-0 w-full' src={logo} alt='' />
  }
  return null
}
