import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getAuthToken } from '../redux/features/auth/authSelectors'
import { getCantinaBackendAPIEndpoint } from '../redux/features/settings/settingsSelectors'
import { CantinaState } from '../redux/interfaces'
import { cantinaFetch, Endpoint, FETCH_OPTIONS } from '../rest'

const identity = (input: string) => input

type UseCantinaBackendAPIParams = {
  endpoint: Endpoint
  method: RequestInit['method']
  withAuth?: boolean
}

interface InvokeAPIOptions extends RequestInit {
  urlTransform?: typeof identity
}

/**
 * useCantinaBackendAPI is a lower level hook to consume
 * the cantina-backend APIs
 * https://cantinabackend.swire.io/documentation/static/index.html
 * It automatically builds the baseUrl using the cantina-backend host from
 * Redux and returns the invokeAPI() method to fire the request.
 * @param params: specify a Endpoint and an HTTP method
 */
export const useCantinaBackendAPI = ({
  endpoint,
  method,
  withAuth = false,
}: UseCantinaBackendAPIParams) => {
  const token = useSelector(getAuthToken)
  const baseUrl = useSelector((state: CantinaState) =>
    getCantinaBackendAPIEndpoint(state, endpoint)
  )

  /**
   * Using invokeAPI the child hooks/components can send the request
   * and `await` the result.
   * The `options` argument is an object to build the HTTP request
   * and, in addition, with "urlTransform" it allows us to transform
   * the final endpoint such as adding query params on the URL or appending
   * specific arguments.
   */
  const invokeAPI = useCallback((options: InvokeAPIOptions = {}) => {
    const { urlTransform = identity, ...params } = options
    const url = urlTransform(baseUrl)
    const headers: HeadersInit = {}
    if (withAuth) {
      headers.Authorization = `Bearer ${token}`
    }
    return cantinaFetch(url, {
      ...FETCH_OPTIONS,
      method,
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      ...params,
    })
  }, [baseUrl])

  return { invokeAPI }
}
