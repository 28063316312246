import { useState, useEffect } from 'react'
import { RailsEndpoint, RequestState, Room } from '../rest'
import { sortByNameProperty } from '../services/helpers'
import { useInternalAPI } from './useInternalAPI'

type UseFetchRoomsData = {
  rooms: Room[]
  requestState: RequestState
  error: string | null
}

const fetchOptions = {
  endpoint: RailsEndpoint.Rooms,
  method: 'GET',
}

export const useFetchRooms = () => {
  const { invokeAPI: fetchHandler } = useInternalAPI(fetchOptions)
  const [data, setData] = useState<UseFetchRoomsData>({
    rooms: [],
    requestState: RequestState.Loading,
    error: null,
  })

  const refresh = async () => {
    try {
      if (data.requestState !== RequestState.Loading) {
        setData({
          ...data,
          requestState: RequestState.Loading,
        })
      }
      const { rooms } = await fetchHandler()
      setData({
        rooms: rooms.sort(sortByNameProperty),
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      setData({
        rooms: [],
        requestState: RequestState.Error,
        error: 'Error loading rooms',
      })
    }
  }

  useEffect(() => {
    refresh()
  }, [])

  return { ...data, refresh }
}
