import React, { FC } from 'react'
import { TestId } from 'src/constants'
import { PrimaryButton } from 'src/components/Button/Button'
import { SpinnerIcon } from 'src/common/icons'

type InputElements = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement

interface SubmittableInputProps extends React.HTMLProps<HTMLInputElement> {
  autoFocus?: boolean
  buttonTitle?: string
  canHaveError?: boolean
  currentValue: string
  disabled?: boolean
  error?: string
  inputChanged?: (event: React.ChangeEvent<InputElements>) => void
  label?: string
  submitButtonLabel: string
  submitHandler(event: React.FormEvent<HTMLFormElement>): void
  submitting?: boolean
  testId?: TestId // TODO: make required
}

const SubmittableInput: FC<SubmittableInputProps> = ({
  buttonTitle,
  canHaveError = true,
  className = '',
  currentValue,
  disabled = false,
  error,
  inputChanged,
  label,
  submitButtonLabel,
  submitHandler,
  submitting = false,
  testId,
  ...rest
}) => {
  const { name = '' } = rest

  return (
    <div className={`${className}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <form autoComplete='off' className='w-100' onSubmit={submitHandler}>
        <div className='flex'>
          <div className='relative grow focus-within:z-10'>
            <input
              className='relative focus-within:z-10'
              required={!submitting}
              disabled={submitting}
              onChange={inputChanged}
              value={currentValue}
              data-test={testId}
              {...rest}
            />
          </div>
          <PrimaryButton
            type='submit'
            className='ml-1 relative inline-flex items-center py-1 px-3'
            disabled={submitting || disabled}
            title={buttonTitle}
            testId={testId && `${testId}_submit`}
          >
            {submitting ? <SpinnerIcon /> : submitButtonLabel}
          </PrimaryButton>
        </div>
        {canHaveError && (
          <div
            className={`sw-error-hint ${error ? 'visible' : 'invisible'}`}
            data-test={TestId.MessageError}
          >
            {error}
          </div>
        )}
      </form>
    </div>
  )
}

export default SubmittableInput
