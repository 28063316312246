import {
  createSlice,
  createAction,
  PayloadAction,
  isAnyOf,
} from '@reduxjs/toolkit'
import { callViewActions, lobbyViewActions } from '../../views'
import { callRequest } from '../../features'
import {
  AUTO_DIAL_VALID_FOR_MS,
  Displayable,
  SocketStatus,
  Theme,
} from '../../../constants'
import { UiState } from '../../interfaces'
import { roomZoneActions } from '../roomZones/roomZonesSlice'

export const initialUiState: UiState = Object.freeze({
  autoDialExpiresAt: 0,
  autoDialExtension: null,
  flipLocalVideo: false,
  isVisible: {},
  myDevicesWarningDone: false,
  prejoinPageViewed: false,
  skipPrejoinPage: true,
  socketStatus: '',
  theme: Theme.Light,
  version: null,
})

const shouldCloseAppMenu = isAnyOf(
  callViewActions.setRoomNavigatorFilter,
  callViewActions.toggleClipeeze,
  callViewActions.toggleParticipantList,
  lobbyViewActions.setRoomFilter
)

const closeAppMenuActions = [
  Displayable.ProfileModal,
  Displayable.DevicesModal,
  Displayable.AboutModal,
]

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialUiState,
  reducers: {
    show: (state: UiState, action: PayloadAction<Displayable>) => {
      if (closeAppMenuActions.includes(action.payload)) {
        state.isVisible[Displayable.AppMenu] = false
      }
      state.isVisible[action.payload] = true
    },
    hide: (state, action: PayloadAction<Displayable>) => {
      state.isVisible[action.payload] = false
    },
    toggle: (state, action: PayloadAction<Displayable>) => {
      state.isVisible[action.payload] = !state.isVisible[action.payload]
    },
    setTheme: (state, { payload: theme }: PayloadAction<Theme>) => {
      state.theme = theme
    },
    setSocketStatus: (state, { payload }: PayloadAction<string>) => {
      if (
        state.socketStatus === SocketStatus.Error &&
        payload === SocketStatus.Closed
      ) {
        return state
      }
      state.socketStatus = payload
    },
    setMyDevicesWarningDone: (state, { payload }: PayloadAction<boolean>) => {
      state.myDevicesWarningDone = payload
    },
    setSkipPrejoinPage: (state, { payload }: PayloadAction<boolean>) => {
      state.skipPrejoinPage = payload
      state.prejoinPageViewed = true
    },
    setPrejoinPageViewed: (state) => {
      state.prejoinPageViewed = true
    },
    toggleFlipLocalVideo: (state) => {
      state.flipLocalVideo = !state.flipLocalVideo
    },
    setAutoDialExtension: (state, { payload }: PayloadAction<string>) => {
      if (payload) {
        state.autoDialExtension = decodeURIComponent(payload)
        // AutoDial valid for 10minutes to allow the user to login.
        // After 10minutes will be ignored.
        state.autoDialExpiresAt = Date.now() + AUTO_DIAL_VALID_FOR_MS
      } else {
        state.autoDialExtension = null
        state.autoDialExpiresAt = 0
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(roomZoneActions.setZoneInvitationList, (state) => {
        state.isVisible[Displayable.SidebarInvitationModal] = true
      })
      .addCase(roomZoneActions.setZoneRequestToJoinList, (state) => {
        state.isVisible[Displayable.SidebarRequestToJoinModal] = true
      })
      .addCase(callRequest.type, (state) => {
        state.autoDialExtension = null
        state.autoDialExpiresAt = 0
        state.isVisible[Displayable.Knocking] = false
      })
      .addMatcher(shouldCloseAppMenu, (state) => {
        state.isVisible[Displayable.AppMenu] = false
      })
  },
})

export const openSettingsRequest = createAction('ui/openSettingsRequest')
export const closeSettingsRequest = createAction('ui/closeSettingsRequest')
export const openThemesRequest = createAction('ui/openThemesRequest')
export const closeThemesRequest = createAction('ui/closeThemesRequest')

// prettier-ignore
export const {
  actions: uiActions,
  reducer: uiReducer
} = uiSlice
