import { createSelector } from 'reselect'
import { CantinaState } from '../../interfaces'

const roomZoneId = (_: CantinaState, roomZoneId: string) => roomZoneId

export const getAllRoomZonesById = ({ roomZones }: CantinaState) => {
  return roomZones.byId || {}
}

export const getAllRoomZoneNames = createSelector(
  getAllRoomZonesById,
  (roomZonesById) =>
    (roomZonesById &&
      Object.keys(roomZonesById).map((id) => roomZonesById[id]?.name)) ??
    []
)

export const getRoomZone = createSelector(
  getAllRoomZonesById,
  roomZoneId,
  (roomZonesById, roomZoneId) => roomZonesById[roomZoneId] || null
)

export const getRoomZoneName = createSelector(
  getRoomZone,
  (zone) => zone.name || null
)

export const getVolumeSidebarExternal = createSelector(
  getRoomZone,
  (roomZone) => roomZone?.extVol ?? null
)

export const getRoomZoneIdsByRoomId = (
  { roomZones }: CantinaState,
  roomId: string
) => {
  return roomZones.idsByRoomId[roomId] || []
}

export const getZoneInvitationList = ({ roomZones }: CantinaState) => {
  return roomZones.zoneInvitationList || []
}

export const getZoneRequestToJoinList = ({ roomZones }: CantinaState) => {
  return roomZones.zoneRequestToJoinList || []
}

export const getZoneEditInvitationList = ({ roomZones }: CantinaState) => {
  return roomZones.zoneEditInvitationList || []
}
