import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { tr, Label } from 'src/common/i18n'
import { RoomCommandAction, Scope } from 'src/common/constants'
import { MicrophoneOnIcon, MicrophoneOffIcon } from 'src/common/icons'
import { CantinaState } from 'src/common/redux/interfaces'
import {
  isDeaf,
  isMuted,
} from 'src/common/redux/features/participants/participantSelectors'
import { TOOLTIP_OFFSET_Y_BOTTOM_BAR } from 'src/hoc/withTextTooltip'
import { ParticipantCommandButton, ParticipantButtonProps } from './index'

const ParticipantAudioMuteButton: FC<ParticipantButtonProps> = ({
  offsetY,
  participantId,
  scope,
  showDNDLabel = true,
  titleSecondary,
  ...rest
}) => {
  let buttonActivated = false
  let buttonScope = scope ?? Scope.ConferenceAudioMute
  let ButtonIcon = MicrophoneOnIcon
  let buttonLabel = tr(Label.AUDIO_MUTE)
  const buttonAction = {
    participantId,
    action: RoomCommandAction.AudioMuteParticipant,
  }

  const muted = useSelector((state: CantinaState) =>
    isMuted(state, participantId)
  )

  const dndEnabled = useSelector((state: CantinaState) =>
    isDeaf(state, participantId)
  )

  if (muted) {
    buttonActivated = true
    buttonScope = scope ?? Scope.ConferenceAudioUnmute
    ButtonIcon = MicrophoneOffIcon
    buttonLabel = tr(Label.AUDIO_UNMUTE)
    buttonAction.action = RoomCommandAction.AudioUnMuteParticipant
  }

  if (dndEnabled && showDNDLabel) {
    buttonLabel = tr(Label.DND_UNAVAILABLE)
  }

  return (
    <ParticipantCommandButton
      action={buttonAction}
      activated={buttonActivated}
      disabled={dndEnabled}
      Icon={ButtonIcon}
      label={buttonLabel}
      offsetY={offsetY}
      participantId={participantId}
      scope={buttonScope}
      titleSecondary={titleSecondary}
      {...rest}
    />
  )
}

// The InCall version is used in the bottom bar and includes tooltip overrides
const InCallParticipantAudioMuteButton: FC<ParticipantButtonProps> = (
  props
) => {
  return (
    <ParticipantAudioMuteButton
      showDNDLabel={false}
      scope={Scope.ConferenceAudioMuteSelf}
      offsetY={TOOLTIP_OFFSET_Y_BOTTOM_BAR}
      titleSecondary={props.disabled ? '' : 'M'}
      {...props}
    />
  )
}

export { ParticipantAudioMuteButton, InCallParticipantAudioMuteButton }
