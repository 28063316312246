import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { withUiDialerScope } from 'src/common/hoc'
import { tr, Label } from 'src/common/i18n'
import { stripFlags } from 'src/common/services/helpers'
import { hasExactRoomNameMatch } from 'src/common/redux/views/lobbyView/lobbyViewSelectors'
import { MakeRoomButton } from './MakeRoomButton'

type NoSearchResultsProps = {
  filter: string
  makeRoomHandler?: () => void
}

export const NoSearchResults: FC<NoSearchResultsProps> = ({
  filter,
  makeRoomHandler,
}) => {
  const hasExactMatch = useSelector(hasExactRoomNameMatch)
  const strippedFilter = stripFlags(filter)

  return (
    <>
      {strippedFilter && !hasExactMatch ? (
        <div className='flex flex-col sm:flex-row gap-2 items-center justify-center text-contrast-h'>
          <div>
            <span className='font-bold'>&quot;{strippedFilter}&quot; </span>
            {tr(Label.WAS_NOT_FOUND)}
          </div>
          {makeRoomHandler && (
            <ScopedCreateRoomQuestion
              filter={filter?.trim()}
              makeRoomHandler={makeRoomHandler}
            />
          )}
        </div>
      ) : null}
    </>
  )
}

// Moved to a component to use the HOC
const ScopedCreateRoomQuestion: FC<Required<NoSearchResultsProps>> =
  withUiDialerScope(({ makeRoomHandler }) => {
    return (
      <div className='flex flex-col gap-2 items-center md:block'>
        <span className='md:mr-2'> {tr(Label.CREATE_THIS_ROOM)}</span>
        <span>
          <MakeRoomButton clickHandler={makeRoomHandler} />
        </span>
      </div>
    )
  })
