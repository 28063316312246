import type { useHistory } from 'react-router-dom'
import { formatDistanceToNow, intervalToDuration } from 'date-fns'
import { AuthProvider, RoutePath } from 'src/common/constants'
import type { UserLookupResponse } from 'src/common/rest'
import { SAML_AUTH_PATH } from 'src/constants'

export const getSearchParams = () => {
  const paramsMap = new URLSearchParams(window.location.search)
  const paramsObj: { [key: string]: string } = {}

  paramsMap.forEach(function (value, key) {
    paramsObj[key] = value
  })

  return paramsObj
}

export const instanceName = () => {
  const name = window.location.hostname.split('.')[0]
  return name[0].toUpperCase() + name.slice(1)
}

export const isWebRTCSupported = () => {
  return (
    typeof navigator === 'object' &&
    typeof navigator.mediaDevices === 'object' &&
    typeof navigator.mediaDevices.getUserMedia === 'function' &&
    typeof RTCPeerConnection === 'function'
  )
}

export const dateFormatDistance = (date: Date) => {
  return formatDistanceToNow(date, { addSuffix: true })
}

export const durationBetweenDates = (start: Date, end: Date) => {
  const time = intervalToDuration({ end, start })
  const hours = `${time.hours}`.padStart(2, '0')
  const minutes = `${time.minutes}`.padStart(2, '0')
  const seconds = `${time.seconds}`.padStart(2, '0')

  return `${hours}:${minutes}:${seconds}`
}

export const getParticipantTestIdSuffix = ({
  groupParticipantTestId,
  rowIndex,
  zoneIndex,
}: {
  groupParticipantTestId?: string
  rowIndex: number
  zoneIndex?: number
}) => {
  let testIdSuffix = ''

  switch (true) {
    // @ts-expect-error
    case !!`${zoneIndex}` && zoneIndex > -1:
      testIdSuffix = `_p${rowIndex}_z${zoneIndex}`
      break
    case !!groupParticipantTestId?.length:
      testIdSuffix = `_p${rowIndex}_g_${groupParticipantTestId}`
      break
    case !!`${rowIndex}`:
      testIdSuffix = `_p${rowIndex}`
      break
  }

  return testIdSuffix
}

export const isElementTruncated = (e: HTMLElement) => {
  return e.offsetWidth < e.scrollWidth
}

type UserLookupResponseHandlerParams = {
  response: UserLookupResponse
  history: ReturnType<typeof useHistory>
  guestHandler?: () => void
  samlHandler?: () => void
}
export const userLookupResponseHandler = ({
  response,
  history,
  guestHandler,
  samlHandler,
}: UserLookupResponseHandlerParams) => {
  const { room } = getSearchParams()
  const pushState = { ...response, room }

  const goToSAML = () => {
    if (samlHandler && typeof samlHandler === 'function') {
      samlHandler()
    }
    return window.location.replace(SAML_AUTH_PATH + window.location.search)
  }

  // This is a guest
  if (!response.user_exists) {
    if (!guestHandler) {
      guestHandler = () => {
        history.push(RoutePath.LoginGuest, pushState)
      }
    }
    const authSettings = response?.authorization_settings
    const authSettingsLength =
      (authSettings && Object.keys(authSettings)?.length) ?? 0

    // If authorization_settings is empty it means this instance
    // does not support any other authorization (guest or saml)
    if (!authSettings || !authSettingsLength) {
      throw new Error('Unknown email')
    }

    // If SAML is the only available auth option, send them directly to the saml login route
    if (authSettingsLength === 1 && authSettings?.[AuthProvider.Saml]) {
      return goToSAML()
    }

    // By default send all other guest to the GuestProvider which will display the available methods
    // OR invoke the custom implementation if guestHandler has been passed as args
    return guestHandler()
  }

  // Check available auth methods for an existing user
  switch (response?.auth_provider) {
    case AuthProvider.Local:
      return history.push(RoutePath.LoginLocal, pushState)
    case AuthProvider.Saml:
      return goToSAML()
    default:
      console.warn('Unknown auth provider', response)
      throw new Error(`Unknown Auth Provider: ${response?.auth_provider}`)
  }
}
