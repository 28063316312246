import React, { FC } from 'react'

type CopyrightProps = {
  className?: string
  text?: string
}

export const Copyright: FC<CopyrightProps> = ({
  className = 'text-center text-gray-500 text-xs whitespace-nowrap',
  text = `2020-${new Date().getFullYear()} SignalWire, Inc.`,
}) => {
  return <p className={className}>&copy;&nbsp;{text}</p>
}
