import { PasswordInputLength } from 'src/common/constants'
import React, { FC, ChangeEvent } from 'react'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { TestId } from 'src/constants'

type PasswordConfirmationProps = {
  onPasswordChange: (event: ChangeEvent<HTMLInputElement>) => void
  onConfirmPasswordChange: (event: ChangeEvent<HTMLInputElement>) => void
  error?: string
}

export const PasswordConfirmation: FC<PasswordConfirmationProps> = ({
  onPasswordChange,
  onConfirmPasswordChange,
  error,
}) => {
  return (
    <>
      <InputGroup
        minLength={PasswordInputLength.Min}
        maxLength={PasswordInputLength.Max}
        name='password'
        type='password'
        label='New Password'
        onChange={onPasswordChange}
        required
        testId={TestId.SetPasswordInputPassword}
      />
      <InputGroup
        minLength={PasswordInputLength.Min}
        maxLength={PasswordInputLength.Max}
        name='confirmPassword'
        type='password'
        label='Confirm Password'
        onChange={onConfirmPasswordChange}
        required
        error={error}
        hint='Use 8 or more characters.'
        testId={TestId.SetPasswordInputConfirm}
      />
    </>
  )
}
