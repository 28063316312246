import { createSelector } from 'reselect'
import { CantinaState } from '../../interfaces'

const layoutId = (_: CantinaState, layoutId: string) => layoutId

export const getLayouts = ({ layouts }: CantinaState) => {
  return layouts.byId
}

const getDefaultIds = ({ layouts }: CantinaState) => {
  return layouts.defaultIds
}

const getIdsByCallId = ({ layouts }: CantinaState, callId: string) => {
  return layouts.idsByCallId[callId] || []
}

export const getLayout = createSelector(
  getLayouts,
  layoutId,
  (layouts, layoutId) => layouts[layoutId] || null
)
export const getDefaultDropdownOptions = createSelector(
  getLayouts,
  getDefaultIds,
  (layouts, defaultIds) => {
    return defaultIds
      .filter((id) => layouts[id] && !layouts[id].belongsToAGroup)
      .map((id) => ({ id, label: layouts[id].label }))
  }
)

export const getCallLayoutDropdownOptions = createSelector(
  getLayouts,
  getIdsByCallId,
  (layouts, layoutIds) => {
    return layoutIds
      .filter((id) => layouts[id] && !layouts[id].belongsToAGroup)
      .map((id) => ({ id, label: layouts[id].label }))
  }
)

export const getLayoutReservationsDropdownOptions = createSelector(
  getLayout,
  (layout) => {
    if (!layout) {
      return []
    }
    const options = layout.reservationIds.map(({ name, label }) => ({
      id: name,
      label,
    }))

    // If there are options at all, add this to the top
    if (options?.length) {
      options.unshift({ id: 'clear', label: 'None' })
    }

    return options
  }
)
