import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getCallRoomId } from 'src/common/redux/features/calls/callSelectors'
import { getParticipantCountByRoomId } from 'src/common/redux/features/participants/participantSelectors'
import {
  getRoomCanvasImages,
  getRoomAloneImages,
} from 'src/common/redux/features/rooms/roomsSelectors'
import { CantinaState } from 'src/common/redux/interfaces'

export const MCUOverlayImages = ({ callId }: { callId: string }) => {
  const roomId = useSelector((state: CantinaState) =>
    getCallRoomId(state, callId)
  )
  const canvasImages = useSelector(
    (state: CantinaState) => getRoomCanvasImages(state, roomId),
    shallowEqual
  )
  const aloneImages = useSelector(
    (state: CantinaState) => getRoomAloneImages(state, roomId),
    shallowEqual
  )
  const participantCount = useSelector((state: CantinaState) =>
    getParticipantCountByRoomId(state, roomId)
  )
  const images = participantCount === 1 ? aloneImages : canvasImages

  return (
    <>
      {images.map(({ id, src }) => (
        <div
          key={id}
          className='overlay-mask'
          style={{ backgroundImage: `url(${src})` }}
        ></div>
      ))}
    </>
  )
}
