import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { UserPlusIcon } from 'src/common/icons'
import { withHideInviteButtonFeatureFlag } from 'src/common/hoc'
import { inviteViewActions } from 'src/common/redux/views'
import { getActiveCallExtension } from 'src/common/redux/features/calls/callSelectors'
import { tr, Label } from 'src/common/i18n'
import { Button } from '../Button/Button'
import { TestId } from 'src/constants'

type InviteButtonProps = {
  label?: string
  className?: string
}

const InviteButtonFunction: FC<InviteButtonProps> = ({
  className = 'text-xs',
}) => {
  const dispatch = useDispatch()
  const extension = useSelector(getActiveCallExtension)

  const clickHandler = () => {
    dispatch(inviteViewActions.show({ room: extension as string }))
  }

  return (
    <Button
      className={className}
      onClick={clickHandler}
      testId={TestId.TopBarButtonInvite}
    >
      <UserPlusIcon size='lg' fixedWidth className='mr-2' />
      {tr(Label.INVITE)}
    </Button>
  )
}

// prettier-ignore
export const InviteButton: FC<InviteButtonProps> = compose(
  withHideInviteButtonFeatureFlag({ show: false })
)(InviteButtonFunction)
