import React, { FC } from 'react'
import { tr, Label } from 'src/common/i18n'
import { RoomTemplateCheckbox } from './RoomTemplateCheckbox'
import { PINInputGlobal } from 'src/components/Inputs/PINInputGlobal'
import { TestId } from 'src/constants'

export const RoomsSecurity: FC = () => {
  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.ROOMS_SECURITY)}
      </div>
      <PINInputGlobal
        label={Label.PIN}
        name='pin'
        testId={TestId.PINInputParticipant}
      />
      <PINInputGlobal
        className='mb-2'
        label={Label.MODERATOR_PIN}
        name='moderator_pin'
        testId={TestId.PINInputModerator}
      />
      <RoomTemplateCheckbox
        name='require_moderator'
        label={Label.REQUIRE_MODERATOR}
      />
    </>
  )
}
