import { FC, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CantinaState } from 'src/common/redux/interfaces'
import { getMyParticipantId } from 'src/common/redux/features/calls/callSelectors'
import { hasLowBitrateMode } from 'src/common/redux/features/participants/participantSelectors'
import { toastInfo, dismissToast } from 'src/services/ToastService'
import { mixPanelTrackAction } from 'src/common/redux/actions'
import { MixPanelEvent } from 'src/common/constants'

const TOAST_MSG =
  "We're prioritizing audio and reducing video quality until conditions improve."
const TOAST_HEADER = 'Temporary Internet Issue Detected'
const TOAST_LINK =
  'https://cantina.crisp.help/en/article/potential-networking-issue-prioritizing-audio-over-video-gm4d55/'

export const LowBitrateToast: FC = () => {
  const toastId = useRef<any>()
  const dispatch = useDispatch()
  const participantId = useSelector(getMyParticipantId)
  const lowBitrateMode = useSelector((state: CantinaState) =>
    hasLowBitrateMode(state, participantId)
  )

  useEffect(() => {
    return () => {
      if (toastId.current) {
        dismissToast(toastId.current)
      }
    }
  }, [])

  if (lowBitrateMode) {
    if (toastId.current === null) {
      toastId.current = toastInfo(TOAST_MSG, {
        header: TOAST_HEADER,
        link: TOAST_LINK,
        autoClose: false,
      })
      dispatch(mixPanelTrackAction({ event: MixPanelEvent.NetworkDegradation }))
    }
  } else {
    if (toastId.current) {
      dismissToast(toastId.current)
      dispatch(mixPanelTrackAction({ event: MixPanelEvent.NetworkRestored }))
    }
    toastId.current = null
  }

  return null
}
