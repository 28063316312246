import React, {
  FC,
  useCallback,
  useState,
  MouseEvent,
  ComponentType,
} from 'react'
import { enterFullScreen, exitFullScreen } from 'src/services/FullScreen'
import { withFullScreenSupport } from 'src/hoc/withFullScreenSupport'
import { useWindowEvent } from 'src/effects/useWindowEvent'
import { TestId } from 'src/constants'
import { tr, Label } from 'src/common/i18n'
import {
  ButtonWithTooltip,
  PrimaryButton,
  TransparentButton,
} from '../Button/Button'

type FullScreenButtonProps = {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  selector?: string
  testId: TestId
  label?: string
  ActiveIcon?: ComponentType<any>
  InactiveIcon?: ComponentType<any>
}

const DEFAULT_SELECTOR = 'body'

export const FullScreenButton: FC<FullScreenButtonProps> =
  withFullScreenSupport(
    ({
      onClick,
      selector = DEFAULT_SELECTOR,
      testId,
      label = tr(Label.FULL_SCREEN),
      ActiveIcon,
      InactiveIcon,
    }) => {
      const [active, setActive] = useState(
        document.fullscreenElement === document.querySelector(selector)
      )

      const fullScreenChangeHandler = useCallback(() => {
        setActive(
          document.fullscreenElement === document.querySelector(selector)
        )
      }, [selector])

      useWindowEvent('fullscreenchange', fullScreenChangeHandler)

      const clickHandler = (event: MouseEvent<HTMLButtonElement>) => {
        if (active) {
          exitFullScreen()
        } else {
          enterFullScreen(selector)
        }

        if (onClick) {
          onClick(event)
        }
      }

      const Icon = active ? ActiveIcon : InactiveIcon
      const buttonLabel = active ? `${tr(Label.EXIT)} ${label}` : label

      if (Icon) {
        return (
          <ButtonWithTooltip
            ariaLabel={buttonLabel}
            className='px-2'
            data-test={testId}
            onClick={clickHandler}
          >
            <Icon size='lg' />
          </ButtonWithTooltip>
        )
      }

      const ButtonComponent = active ? PrimaryButton : TransparentButton
      return (
        <ButtonComponent onClick={clickHandler} testId={testId}>
          {label}
        </ButtonComponent>
      )
    }
  )
