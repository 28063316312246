import React, { FC, useEffect, useState, ChangeEvent } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'

import {
  withActiveCall,
  withConferenceZoneManageScope,
  withSidebarConversationsFeatureFlag,
} from 'src/common/hoc'
import {
  Displayable,
  RoomCommandAction,
  TITLE_MAX_CHARS,
} from 'src/common/constants'
import { uiActions } from 'src/common/redux/features'
import { CantinaState } from 'src/common/redux/interfaces'
import { tr, Label } from 'src/common/i18n'
import { useMyZone, useDispatchRoomCommand } from 'src/common/hooks'
import { SidebarIcon } from 'src/common/icons'
import { getRoomParticipantModalIds } from 'src/common/redux/views/callView/callViewSelectors'
import { isSidebarStartModalVisible } from 'src/common/redux/features/ui/uiSelectors'
import { getAllRoomZoneNames } from 'src/common/redux/features/roomZones/roomZonesSelectors'
import {
  getCallRoomId,
  getMyParticipantId,
} from 'src/common/redux/features/calls/callSelectors'
import { getName } from 'src/common/redux/features/participants/participantSelectors'
import { stripSpaces } from 'src/common/services/helpers'

import { TestId } from 'src/constants'
import { SearchParticipantModalWithFilter } from 'src/components/ParticipantList/SearchParticipantList'
import { ParticipantData } from 'src/components/ParticipantList/ParticipantData'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { Checkbox } from 'src/components/Inputs/Checkbox'

import { Modal } from '../Modal'

const StartSideBarModal: FC = withActiveCall(({ callId }) => {
  const dispatch = useDispatch()
  const dispatchRoomCommand = useDispatchRoomCommand()
  const show: boolean = useSelector(isSidebarStartModalVisible)

  // If you are starting a sidebar and you're not already in another zone,
  // automatically check yourself and add yourself to the list of invitees
  const myParticipantId = useSelector(getMyParticipantId)
  const { isInZone } = useMyZone()
  const roomId = useSelector((state: CantinaState) =>
    getCallRoomId(state, callId)
  )
  const participantIds: string[] = useSelector(
    (state: CantinaState) => getRoomParticipantModalIds(state, roomId),
    shallowEqual
  )
  const [selectedSidebarParticipantIds, setSelectedSidebarParticipantIds] =
    useState<string[]>([])

  // Set default zone name
  const userName = useSelector((state: CantinaState) =>
    getName(state, myParticipantId)
  ) as string
  const currentZoneNames: string[] = useSelector((state: CantinaState) =>
    getAllRoomZoneNames(state)
  )
  const count = currentZoneNames?.reduce((a, b) => {
    return b.startsWith(stripSpaces(userName)) ? a + 1 : a
  }, 0)
  const defaultZoneName = currentZoneNames.includes(stripSpaces(userName))
    ? `${userName}-(${count})`
    : userName
  const [zoneName, setZoneName] = useState(defaultZoneName)
  const [error, setError] = useState('')

  useEffect(() => {
    !isInZone &&
      setSelectedSidebarParticipantIds([
        ...selectedSidebarParticipantIds,
        myParticipantId,
      ])

    return function cleanup() {
      setSelectedSidebarParticipantIds([])
    }
    // TODO - SIDEBARS: Maybe there is another way to un/set this?
    // Since we are setting state here, only run on mount and unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myParticipantId, isInZone])

  if (!show) {
    return null
  }

  const closeHandler = () =>
    dispatch(uiActions.toggle(Displayable.SidebarStartModal))
  const resetSidebar = () => {
    setSelectedSidebarParticipantIds([])
    dispatch(uiActions.toggle(Displayable.SidebarStartModal))
  }

  const startSidebar = () => {
    dispatchRoomCommand({
      action: RoomCommandAction.ZoneCreate,
      participantIds: selectedSidebarParticipantIds,
      roomId,
      zoneName,
    })
    resetSidebar()
  }

  const updateName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value
    setZoneName(name)

    if (currentZoneNames?.includes(stripSpaces(name))) {
      setError(tr(Label.SIDEBAR_NAME_ERROR))
    } else {
      setError('')
    }
  }

  const updateSelectedSidebarParticipantIds = (id: string) => {
    if (selectedSidebarParticipantIds.includes(id)) {
      setSelectedSidebarParticipantIds(
        selectedSidebarParticipantIds.filter((x) => x !== id)
      )
    } else {
      setSelectedSidebarParticipantIds([...selectedSidebarParticipantIds, id])
    }
  }

  return (
    <Modal
      byline={tr(Label.START_A_SIDEBAR_BYLINE)}
      cancelTestId={TestId.SidebarModalCreateCancel}
      closeHandler={closeHandler}
      confirmHandler={startSidebar}
      confirmLabel={tr(Label.START_SIDEBAR)}
      confirmTestId={TestId.SidebarModalCreateStart}
      disabled={
        !!(
          !selectedSidebarParticipantIds?.length ||
          !zoneName?.length ||
          error?.length
        )
      }
      show={show}
      testId={TestId.SidebarModalCreate}
      title={tr(Label.START_A_SIDEBAR)}
      TitleIcon={SidebarIcon}
    >
      <div className='flex flex-col'>
        <div className='relative grow focus-within:z-10'>
          <InputGroup
            autoFocus
            disableAutoComplete
            error={error}
            inputClassName='text-base p-2 focus-within:z-10 focus:rounded-l-md'
            label={tr(Label.ENTER_SIDEBAR_NAME)}
            maxLength={TITLE_MAX_CHARS}
            name='group_name'
            onChange={updateName}
            placeholder={tr(Label.ENTER_SIDEBAR_NAME)}
            required
            testId={TestId.SidebarModalCreateTextName}
            title={tr(Label.ENTER_SIDEBAR_NAME)}
            value={zoneName}
          />
        </div>
        <div className='flex justify-between text-sm text-contrast-m my-1'>
          <div>{tr(Label.SELECT_PARTICIPANTS)}</div>
          <div data-test={TestId.SidebarModalCreateDivParticipantCounter}>
            {selectedSidebarParticipantIds.length} {tr(Label.OF)}{' '}
            {participantIds.length}
          </div>
        </div>
        <div
          className='block-secondary p-4 flex flex-col h-full'
          style={{ height: '40vh' }}
        >
          <SearchParticipantModalWithFilter className='mb-4' />
          <div className='overflow-y-auto'>
            {participantIds.map((id, index) => (
              <div className='flex items-center mb-2' key={`partRow${id}`}>
                <Checkbox
                  checked={selectedSidebarParticipantIds.includes(id)}
                  className=''
                  label=''
                  name={id}
                  onChange={() => updateSelectedSidebarParticipantIds(id)}
                  testId={`${TestId.SidebarModalCreateCheckbox}_${index}`}
                  wrapperClassName=''
                />
                <ParticipantData
                  participantId={id}
                  className='truncate'
                  showTalkingIndicator={false}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
})

export default compose<any>(
  withSidebarConversationsFeatureFlag(),
  withConferenceZoneManageScope
)(StartSideBarModal)
