import React, { FC } from 'react'
import { TestId } from 'src/constants'

type CheckboxProps = {
  checked?: boolean
  className?: string
  defaultChecked?: boolean
  label: string
  labelClassName?: string
  name: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  required?: boolean
  testId?: TestId | string // TODO: make required
  value?: string | number
  wrapperClassName?: string
}

export const Checkbox: FC<CheckboxProps> = ({
  checked,
  className = '',
  defaultChecked,
  label,
  labelClassName = '',
  name,
  onChange,
  required = false,
  testId,
  value = 1,
  wrapperClassName = 'mb-2',
}) => {
  return (
    <div className={wrapperClassName}>
      <label
        className={`relative h-5 text-sm inline-flex items-center cursor-pointer ${className}`}
      >
        <input
          checked={checked}
          className='peer hidden'
          data-test={testId}
          defaultChecked={defaultChecked}
          id={name}
          name={name}
          onChange={onChange}
          required={required}
          type='checkbox'
          value={value}
        />
        <span className='absolute border border-contrast-h h-4 w-4' />
        <span className='absolute invisible peer-checked:visible top-[3px] left-[5px] w-[6px] h-[11px] border-contrast-h border-r-2 border-b-2 rotate-45' />
        <span className={`h-5 pl-6 truncate leading-snug ${labelClassName}`}>
          {label}
        </span>
      </label>
    </div>
  )
}
