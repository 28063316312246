import {
  createSlice,
  createAction,
  PayloadAction,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit'
import { Participant, ParticipantState } from '../../interfaces'
// import { CantinaManagerRoom } from '../../../rest'
import { roomListActions } from '../roomList/roomListSlice'
import { flushAction } from '../../actions'
import { forceIdToString } from '../../../services/helpers'

type RoomBootstrapPayload = PayloadAction<{
  roomId: string
  participants: Participant[]
}>

export const initialParticipantState: ParticipantState = Object.freeze({
  byId: {},
  idsByRoomId: {},
})

const participantSlice = createSlice({
  name: 'participants',
  initialState: initialParticipantState,
  reducers: {
    bulkInsert: (
      state,
      { payload }: PayloadAction<Partial<ParticipantState>>
    ) => {
      // @ts-expect-error
      Object.keys(payload.byId).forEach((participantId) => {
        const newParticipant = payload.byId?.[participantId]
        if (!newParticipant) {
          return
        }
        const { roomId } = newParticipant
        if (roomId) {
          state.idsByRoomId[roomId] = state.idsByRoomId[roomId] || []
          if (!state.idsByRoomId[roomId].includes(participantId)) {
            state.idsByRoomId[roomId].push(participantId)
          }
        }

        state.byId[participantId] = state.byId[participantId] || {
          id: participantId,
        }
        Object.keys(newParticipant).forEach((key) => {
          // @ts-expect-error
          state.byId[participantId][key] = newParticipant[key]
        })
      })
    },
    create: (state, { payload }: PayloadAction<Participant>) => {
      const { roomId, myself } = payload
      const method = myself === true ? 'unshift' : 'push'
      if (roomId) {
        state.idsByRoomId[roomId] = state.idsByRoomId[roomId] || []
        if (!state.idsByRoomId[roomId].includes(payload.id)) {
          state.idsByRoomId[roomId][method](payload.id)
        }
      }
      state.byId[payload.id] = payload
    },
    update: (state, { payload }: PayloadAction<Participant>) => {
      if (payload.id in state.byId) {
        Object.keys(payload).forEach((key) => {
          // @ts-expect-error
          state.byId[payload.id][key] = payload[key]

          // This timestamp allows us to modify the getParticipantsGroupedByTalking
          // selector so that we only remove them after a few seconds have passed.
          // This is a way to "debounce" the selector's results rather than
          // debouncing the selector itself.
          if (key === 'talking' && state.byId[payload.id].talking) {
            state.byId[payload.id].lastTalkingTime = Date.now()
          }
        })
      }
    },
    destroy: (state, { payload }: PayloadAction<Participant>) => {
      const { roomId } = payload
      if (roomId && state.idsByRoomId[roomId]) {
        state.idsByRoomId[roomId] = state.idsByRoomId[roomId].filter(
          (id) => id !== payload.id
        )
      }
      delete state.byId[payload.id]
    },
    roomBootstrap: (state, { payload }: RoomBootstrapPayload) => {
      const { roomId, participants = [] } = payload
      if (!roomId) {
        return state
      }
      const ids: string[] = []
      participants?.forEach((participant) => {
        const id = forceIdToString(participant.id)
        ids.push(id)
        state.byId[id] = {
          ...(state.byId[id] || {}),
          ...participant,
          id,
          roomId,
        }
      })

      if (state.idsByRoomId?.[roomId]?.length) {
        state.idsByRoomId[roomId].forEach((id) => {
          if (!ids.includes(id)) {
            delete state.byId[id]
          }
        })
      }

      state.idsByRoomId[roomId] = ids
    },
  },
  extraReducers: {
    // [roomListActions.createOrUpdate.type]: (
    //   state,
    //   { payload: room }: PayloadAction<CantinaManagerRoom>
    // ) => {
    //   const running = Boolean(room.memberCount)
    //   if (!running) {
    //     cleanupOnRoomDestroy(state, room.id)
    //   }
    // },
    [roomListActions.destroyById.type]: (
      state,
      { payload: roomId }: PayloadAction<string>
    ) => {
      cleanupOnRoomDestroy(state, roomId)
    },
    [flushAction.type]: () => initialParticipantState,
    [roomListActions.bootstrapMembers.type]: (
      state,
      { payload }: PayloadAction<{ roomId: string; members: Participant[] }>
    ) => {
      const { members = [] } = payload
      members.forEach((member) => {
        state.byId[member.id] = state.byId[member.id] || {
          id: member.id,
        }
        Object.keys(member).forEach((key) => {
          // @ts-expect-error
          state.byId[member.id][key] = member[key]
        })
      })
    },
  },
})

const cleanupOnRoomDestroy = (state: ParticipantState, roomId: string) => {
  const allIds = Object.keys(state.byId)
  allIds.forEach((id) => {
    if (state.byId[id] && state.byId[id].roomId === roomId) {
      delete state.byId[id]
    }
  })
  delete state.idsByRoomId[roomId]

  return state
}

export const participantKnock: ActionCreatorWithPayload<string> =
  createAction('participants/knock')

// prettier-ignore
export const {
  actions: participantActions,
  reducer: participantReducer
} = participantSlice
