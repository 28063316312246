import React, { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getErrorMessage } from 'src/common/redux/features/auth/authSelectors'
import {
  getCustomCompanyLabel,
  getCustomNameLabel,
} from 'src/common/redux/features/settings/settingsSelectors'
import { SpinnerIcon, LockIcon } from 'src/common/icons'
import { Endpoint, UserLookupResponse } from 'src/common/rest'
import { RoutePath, AuthProvider } from 'src/common/constants'
import { useCantinaBackendAPI } from 'src/common/hooks'
import { getJWTExpiresIn } from 'src/common/services/helpers'
import { authActions } from 'src/common/redux/features'
import { tr, Label } from 'src/common/i18n'
import { SAML_AUTH_PATH, TestId } from 'src/constants'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { useInputChange } from 'src/effects/useInputChange'
import { PrimaryButton } from 'src/components/Button/Button'
import { PrivacyPolicyLink } from 'src/components/PrivacyPolicyLink'
import { SigninNotification } from 'src/components/SigninNotification'
import { LoginHeader } from './LoginHeader'

const loginOptions = {
  endpoint: Endpoint.Login,
  method: 'POST',
}

type AuthProviderComponentProps = { cantinaId: string } & UserLookupResponse

export const GuestProvider: FC<AuthProviderComponentProps> = ({
  cantinaId,
  email,
  authorization_settings = {},
}) => {
  const { invokeAPI: loginHandler } = useCantinaBackendAPI(loginOptions)
  const { guest, saml } = authorization_settings
  const dispatch = useDispatch()
  const errorMessage = useSelector(getErrorMessage)
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useInputChange({
    name: '',
    company: '',
  })
  const [error, setError] = useState('')
  const nameFieldLabel = useSelector(getCustomNameLabel)
  const companyFieldLabel = useSelector(getCustomCompanyLabel)

  useEffect(() => {
    if (errorMessage) {
      setLoading(false)
      setError(errorMessage)
    }

    return () => {
      if (errorMessage) {
        dispatch(authActions.clearErrorMessage())
      }
    }
  }, [dispatch, errorMessage])

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { name, company = '' } = values
    if (email && name) {
      setLoading(true)
      setError('')
      try {
        const response = await loginHandler({
          credentials: 'include',
          body: JSON.stringify({
            cantinaId,
            email,
            name,
            provider: AuthProvider.Guest,
            expiresIn: getJWTExpiresIn(),
          }),
        })
        dispatch(
          authActions.loginSuccess({
            ...response,
            name,
            company,
          })
        )
      } catch (error) {
        setLoading(false)
        setError('Invalid Login')
      }
    }
    return false
  }

  return (
    <>
      <LoginHeader email={email} />
      {guest && (
        <form
          onSubmit={submitHandler}
          autoComplete='off'
          data-test={TestId.LoginGuestForm}
        >
          <InputGroup
            autoFocus
            label={nameFieldLabel}
            name='name'
            onChange={setValues}
            placeholder='John Doe'
            required
            testId={TestId.LoginGuestInputUsername}
          />
          <InputGroup
            error={error}
            label={companyFieldLabel}
            name='company'
            onChange={setValues}
            placeholder='SignalWire'
            testId={TestId.LoginGuestInputCompany}
          />
          <PrimaryButton
            className='w-full mb-2'
            disabled={loading}
            testId={TestId.LoginGuestButtonLogin}
            type='submit'
          >
            {loading ? <SpinnerIcon /> : tr(Label.JOIN_AS_GUEST)}
          </PrimaryButton>
        </form>
      )}
      {saml && <SamlButton {...saml} />}
      <PrivacyPolicyLink />
      <SigninNotification refPage={RoutePath.Login} />
    </>
  )
}

// @ts-expect-error
const SamlButton: FC<UserLookupResponse['authorization_settings']['saml']> = ({
  button,
}) => {
  const { label, bg_color, text_color } = button

  return (
    <a
      href={SAML_AUTH_PATH}
      className='sw-btn w-full inline-flex items-center justify-center'
      style={{ backgroundColor: bg_color, color: text_color }}
      data-test={TestId.LoginButtonSaml}
    >
      <LockIcon fixedWidth />
      <span className='flex-1 text-center'>{label}</span>
    </a>
  )
}
