import { combineReducers, Action } from 'redux'
import { purgeAction } from './actions'
import {
  authReducer,
  callReducer,
  chatReducer,
  clipeezeReducer,
  deviceReducer,
  floatersReducer,
  groupsReducer,
  layoutsReducer,
  loadingReducer,
  messageReducer,
  participantReducer,
  roomsReducer,
  roomListReducer,
  roomTemplatesReducer,
  roomZoneReducer,
  scopesReducer,
  settingsReducer,
  uiReducer,
  usersReducer,
} from './features'

import {
  lobbyViewReducer,
  callViewReducer,
  inviteViewReducer,
  promptViewReducer,
} from './views'

const appReducer = combineReducers({
  auth: authReducer,
  calls: callReducer,
  chats: chatReducer,
  clipeeze: clipeezeReducer,
  device: deviceReducer,
  floaters: floatersReducer,
  groups: groupsReducer,
  layouts: layoutsReducer,
  loading: loadingReducer,
  messages: messageReducer,
  participants: participantReducer,
  rooms: roomsReducer,
  roomList: roomListReducer,
  roomTemplates: roomTemplatesReducer,
  roomZones: roomZoneReducer,
  scopes: scopesReducer,
  settings: settingsReducer,
  ui: uiReducer,
  users: usersReducer,
  // views reducers
  callView: callViewReducer,
  inviteView: inviteViewReducer,
  lobbyView: lobbyViewReducer,
  promptView: promptViewReducer,
})

/**
 * Ignore purgeAction for these reducers
 */
const IGNORE_PURGE_ACTION_REDUCERS = ['settings']
const rootReducer = (state: any, action: Action<any>) => {
  if (action.type === purgeAction.type) {
    const newState: any = {}
    IGNORE_PURGE_ACTION_REDUCERS.forEach((key) => {
      newState[key] = state[key]
    })
    state = newState
  }
  return appReducer(state, action)
}

export default rootReducer
