// @ts-expect-error
import mixpanel from 'mixpanel-browser'
import { instanceName } from './Helpers'

export const init = () => {
  if (process.env.REACT_APP_MIXPANEL_TOKEN) {
    // console.debug('MixPanel init', process.env.REACT_APP_MIXPANEL_TOKEN)
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)
  } else {
    console.debug('Missing MIXPANEL_TOKEN')
  }
}

export const identify = (userId: string, params: any) => {
  if (process.env.REACT_APP_MIXPANEL_TOKEN) {
    params.instanceName = instanceName()
    mixpanel.people.set(params)
    const id = `${params.instanceName}-${userId}`.toLowerCase()
    console.debug('MixPanel identify', id, params)
    mixpanel.identify(id)
  }
}

export const track = (event: string, params: any = {}) => {
  if (process.env.REACT_APP_MIXPANEL_TOKEN) {
    params.instanceName = instanceName()
    // console.debug('mixPanelEvent', event, params)
    mixpanel.track(event, params)
  }
}
