import React, { FC } from 'react'
import { tr, Label } from 'src/common/i18n'
import { SettingsTinyMCE } from './SettingsTinyMCE'
import { RoomTemplateCheckbox } from './RoomTemplateCheckbox'

export const HomeScreen: FC = () => {
  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.HOME_SCREEN)}
      </div>
      <SettingsTinyMCE name='lobby_header' label={Label.LOBBY_HEADER} />
      <SettingsTinyMCE name='lobby_footer' label={Label.LOBBY_FOOTER} />

      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.ROOM_NAVIGATOR)}
      </div>
      <RoomTemplateCheckbox
        name='auto_open_nav'
        label={Label.AUTO_OPEN_ROOM_NAV}
      />
    </>
  )
}
