import { CantinaState } from '../../interfaces'

// Create a selector for the loading state which corresponds to your action
// by passing the action with the loading suffix removed
//
// For example, if your action is 'clipeeze/loadClipeezeRequest', you
// would call createLoadingSelector('clipeeze/loadClipeeze')

export const createLoadingSelector = (target: string | string[]) => {
  return ({ loading }: CantinaState) => {
    if (typeof target === 'string') {
      return loading[target] || false
    } else {
      return target.some((action) => loading[action])
    }
  }
}
