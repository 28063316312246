import React, { FC, useMemo } from 'react'
import { makeRoomCardSelector } from 'src/common/redux/features/roomList/roomListSelectors'
import { RoomType } from 'src/common/constants'
import { useRoomSelector } from 'src/common/hooks'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import { TitleWithTruncationTooltip } from 'src/hoc/withTruncationTooltip'
import { RoomControlsButton } from './RoomControlsButton'
import { RoomPreview } from './RoomPreview'
import { RoomParticipants } from '../RoomNavigator/RoomParticipants'
import { RoomCardButtons } from '../RoomCardButtons'
import { LobbyRoomCopyInviteLinkButton } from './RoomCopyInviteLinkButton'
import { RoomRecordingState } from './RoomRecordingState'
import { RoomStatusIcon } from './RoomStatusIcon'

type RoomCardProps = {
  roomId: string
  joinButtonTestId: TestId | string
}

export const RoomCard: FC<RoomCardProps> = ({ roomId, joinButtonTestId }) => {
  const selector = useMemo(makeRoomCardSelector, [])
  const { roomName, roomDescription, running, canManage } = useRoomSelector(
    selector,
    roomId
  )
  const type = running ? RoomType.Live : RoomType.Static

  return (
    <div
      className='block-primary rounded-md overflow-hidden flex flex-col border border-contrast-l shadow-md'
      data-test={TestId.HomeHeaderRoom}
    >
      <div className='flex gap-3 items-center justify-between p-3'>
        <div className='flex-1 flex items-center min-w-[30%] overflow-hidden'>
          <RoomStatusIcon roomId={roomId} className='mr-2' fixedWidth />
          <div className='overflow-hidden'>
            <TitleWithTruncationTooltip
              title={roomName}
              className='font-bold leading-6 text-xl text-contrast-h truncate'
            />
          </div>
        </div>
        <div className='flex justify-end max-w-[70%] gap-1 overflow-hidden'>
          {canManage && type === RoomType.Live && (
            <RoomControlsButton roomId={roomId} roomName={roomName} />
          )}
          <RoomCardButtons
            extension={roomName}
            joinTestId={joinButtonTestId}
            roomId={roomId}
          />
        </div>
      </div>
      <RoomPreview type={type} roomId={roomId} roomName={roomName} />
      <div className='flex flex-col flex-1 p-3'>
        <div className='flex items-center justify-between'>
          {type === RoomType.Live ? (
            <RoomParticipants roomId={roomId} tooltipPlacement='top' />
          ) : (
            <div className='text-sm text-contrast-m'>
              {tr(Label.EMPTY_ROOM)}
            </div>
          )}
          <div className='flex items-center text-sm'>
            <RoomRecordingState roomId={roomId} />
            {canManage && (
              <div className='ml-2'>
                <LobbyRoomCopyInviteLinkButton roomId={roomId} />
              </div>
            )}
          </div>
        </div>
        <p
          className='leading-snug mt-2'
          dangerouslySetInnerHTML={{ __html: roomDescription }}
        ></p>
      </div>
    </div>
  )
}
