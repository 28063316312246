import { createSelector } from 'reselect'
import { CantinaState, Call, CanvasLayout } from '../../interfaces'
import { CallState } from '../../../constants'
import { getRooms } from '../rooms/roomsSelectors'
// import { getParticipants } from '../participants/participantSelectors'

/**
 * HOFs to not duplicate the logic and
 * have type checking on Call keys
 */
const makeCallIdSelector = <T extends keyof Call>(key: T) => {
  return createSelector(getCallById, (call) => call?.[key] ?? '')
}
const makeActiveCallSelector = <T extends keyof Call, DefaultValType>(
  key: T,
  defaultVal: DefaultValType
) => {
  return createSelector(getActiveCall, (call) => call?.[key] ?? defaultVal)
}

/**
 * HOF as above but force the value to be a boolean
 */
const makeBooleanCallIdSelector = (key: keyof Call) => {
  return createSelector(getCallById, (call) => Boolean(call?.[key]))
}

export const hasDeviceError = ({ calls }: CantinaState) => {
  // Return true if there are any true values in the error object
  return Boolean(
    calls.deviceErrors && Object.values(calls.deviceErrors).some((e) => e)
  )
}

export const getDeviceErrors = ({ calls }: CantinaState) => {
  return calls.deviceErrors
}

export const hasErrors = ({ calls }: CantinaState) => {
  return Object.keys(calls.errors).length > 0
}

export const getLastTriedCall = ({ calls }: CantinaState) => {
  return calls.lastTriedCall
}

export const getCalls = ({ calls }: CantinaState) => {
  return calls.byId || {}
}

export const getRTCPeerConfig = ({ calls }: CantinaState) => {
  return calls?.rtcPeerConfig || {}
}

export const getActiveCall = ({ calls }: CantinaState) => {
  return (calls.active && calls.byId[calls.active]) || null
}

export const getCallById = ({ calls }: CantinaState, callId: string) => {
  return calls.byId[callId] || null
}

export const getCanvasInfoById = ({ calls }: CantinaState, callId: string) => {
  return calls.canvasInfoById[callId] || null
}

const addStylesToLayout = (layout: CanvasLayout) => {
  return {
    ...layout,
    styles: {
      width: `${layout.screenWidthPct}%`,
      height: `${layout.screenHeightPct}%`,
      left: `${layout.xPOSPct}%`,
      top: `${layout.yPOSPct}%`,
    },
  }
}

export const getCallCanvasParticipantLayers = createSelector(
  getCanvasInfoById,
  (canvasInfo) => {
    if (!canvasInfo) {
      return []
    }
    return canvasInfo.layouts
      .filter(({ layerOccupied, participantId }) => {
        return layerOccupied && participantId !== '0'
      })
      .map(addStylesToLayout)
  }
)

export const getCallCanvasEmptyLayers = createSelector(
  getCanvasInfoById,
  (canvasInfo) => {
    if (!canvasInfo) {
      return []
    }
    const fullScreenOccupied = canvasInfo.layouts.some(
      ({ layerOccupied, playingFile, resID }) => {
        return resID && resID === 'fullscreen' && (layerOccupied || playingFile)
      }
    )
    if (fullScreenOccupied) {
      return []
    }
    return canvasInfo.layouts
      .filter(
        ({ layerOccupied, playingFile, resID, resIDExclusive = false }) => {
          return (
            resIDExclusive &&
            !layerOccupied &&
            !playingFile &&
            resID &&
            resID !== 'fullscreen'
          )
        }
      )
      .map(addStylesToLayout)
  }
)

export const getCallCanvasMediaLayers = createSelector(
  getCanvasInfoById,
  (canvasInfo) => {
    if (!canvasInfo) {
      return []
    }
    return canvasInfo.layouts
      .filter(({ playingFile }) => playingFile === true)
      .map(addStylesToLayout)
  }
)

export const getRingingCallId = createSelector(getCalls, (calls) => {
  return Object.keys(calls).find(
    (callId) => calls[callId].state === CallState.Ringing
  )
})

export const getCallSecondSourceCallIds = createSelector(
  getCallById,
  (call) => call?.secondSourceCallIds || []
)

export const getCallPlaceholderImage = createSelector(getCallById, (call) => {
  if (call) {
    const param = call.isDirect ? 'oneonone' : 'audio'
    const name = encodeURIComponent((call?.extension ?? '').replace(/^@/, ''))
    return `${process.env.REACT_APP_LEGACY_BACKEND}/og/index.cgi?${param}=${name}`
  } else {
    return `${process.env.REACT_APP_LEGACY_BACKEND}/og/index.cgi?audio=cantina`
  }
})
export const getCallInboundVideoTrackIds = createSelector(
  getCallById,
  (call) => call && (call.inboundVideoTrackIds || [])
)

export const getCallRemoteCallerName = makeCallIdSelector('remoteCallerName')
export const getCallRoomId = makeCallIdSelector('roomId')
export const getCallStartedAt = makeCallIdSelector('startedAt')
export const getCallParticipantId = makeCallIdSelector('participantId')

// Active call properties
export const getActiveCallId = makeActiveCallSelector('id', '')
export const getActiveCallExtension = makeActiveCallSelector('extension', '')
export const getActiveCallStartedAt = makeActiveCallSelector('startedAt', 0)
export const getActiveCallRoomId = makeActiveCallSelector('roomId', '')
export const getMyParticipantId = makeActiveCallSelector('participantId', '')
export const getMyBase64Logo = makeActiveCallSelector('participantLogo', '')
export const getRoomRouteData = createSelector(
  getActiveCallId,
  getActiveCallRoomId,
  getMyParticipantId,
  getActiveCallExtension,
  (callId, roomId, participantId, roomName) => {
    return { callId, roomId, participantId, roomName }
  }
)

// Boolean properties
export const isSendingVideo = makeBooleanCallIdSelector('isSendingVideo')
export const isSendingAudio = makeBooleanCallIdSelector('isSendingAudio')
export const isReceivingVideo = makeBooleanCallIdSelector('isReceivingVideo')
export const isReceivingAudio = makeBooleanCallIdSelector('isReceivingAudio')
export const isCallDirect = makeBooleanCallIdSelector('isDirect')
export const isCallOnHold = createSelector(getCallById, (call) =>
  call ? call.state === CallState.Held : false
)

// Return undefined until meetingMode value is set, then return a boolean
export const meetingModeModalSelector = createSelector(
  getActiveCallRoomId,
  getMyParticipantId,
  getRooms,
  // getParticipants,
  ({ participants }: CantinaState) => {
    return participants.byId || {}
  },
  (activeCallRoomId, participantId, roomsById, participantsById) => {
    if (!activeCallRoomId || !participantId) {
      return
    }

    const room = roomsById[activeCallRoomId]
    const myParticipant = participantsById[participantId]
    if (!room || !myParticipant) {
      return
    }

    return room.meetingMode && myParticipant.muted
  }
)

export const isNotSelfModerator = createSelector(
  getMyParticipantId,
  ({ participants }: CantinaState) => {
    return participants.byId || {}
  },
  (participantId, participantsById) => {
    if (!participantId) {
      return false
    }
    const myParticipant = participantsById[participantId]
    return !Boolean(myParticipant?.moderator)
  }
)
