import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'
import { RoomState, Room, RoomCommandPayload } from '../../interfaces'
import { flushAction } from '../../actions'

export const initialRoomState: RoomState = Object.freeze({
  ids: [],
  byId: {},
})

const roomsSlice = createSlice({
  name: 'rooms',
  initialState: initialRoomState,
  reducers: {
    createOrUpdate: (state, { payload: room }: PayloadAction<Room>) => {
      if (room.recording) {
        room.recordingError = ''
      }
      if (room.filesPlaying || room.asyncFilesPlaying) {
        room.playingError = false
      }
      if (room.id in state.byId) {
        state.byId[room.id] = {
          ...state.byId[room.id],
          ...room,
        }
      } else {
        state.byId[room.id] = room
        state.ids = state.ids.filter((id) => id !== room.id).concat(room.id)
      }
    },
    destroyById: (state, { payload: roomId }: PayloadAction<string>) => {
      delete state.byId[roomId]
      state.ids = state.ids.filter((id) => id !== roomId)
    },
  },
  extraReducers: {
    [flushAction.type]: () => initialRoomState,
  },
})

export const roomCommand = createAction<RoomCommandPayload>('rooms/command')

type RoomJoinedPayload = {
  roomId: string
  callId: string
  scopeId: string
  role: 'participant' | 'moderator'
}
export const roomJoinedAction = createAction<RoomJoinedPayload>('rooms/joined')

type RoomLeftPayload = {
  roomId: string
  callId: string
  scopeId: string
}
export const roomLeftAction = createAction<RoomLeftPayload>('rooms/left')

// prettier-ignore
export const {
  actions: roomsActions,
  reducer: roomsReducer
} = roomsSlice
