import { PayloadAction } from '@reduxjs/toolkit'
import { SagaIterator } from 'redux-saga'
import { call, take, fork, put, select } from 'redux-saga/effects'
import { getVersion } from '../settings/settingsSelectors'
import { callActions } from '../calls/callSlice'
import { authActions } from '../auth/authSlice'
import { settingsActions, checkVersion } from '../settings/settingsSlice'
import { cantinaFetch } from '../../../rest'

interface AppStats {
  version: string
  date: number
}

export const ACTIONS: string[] = [
  authActions.loginSuccess.type,
  callActions.destroy.type,
  checkVersion.type,
]

export function* checkVersionWorker(): SagaIterator {
  const clientVersion = yield select(getVersion)
  const url = `/${process.env.REACT_APP_STATS_FILE_NAME}`
  const { version: serverVersion }: AppStats = yield call(cantinaFetch, url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  // If the client doesn't have the latest version, refresh the page
  if (serverVersion && serverVersion !== clientVersion) {
    yield put(settingsActions.updateVersion(serverVersion))
    // A user who has never used the site doesn't need the reload
    // because they are already getting the latest version from bootstrapSuccess
    if (clientVersion) {
      location.reload()
    }
  }
}

export function* uiWorker({ type, payload }: PayloadAction<any>): SagaIterator {
  switch (type) {
    case authActions.loginSuccess.type:
    case callActions.destroy.type:
    case checkVersion.type: {
      yield call(checkVersionWorker)
      return
    }
    default:
      console.warn('Unhandled UI action', type, payload)
  }
}

export function* watchUI(): SagaIterator {
  while (true) {
    const action = yield take(ACTIONS)
    yield fork(uiWorker, action)
  }
}
