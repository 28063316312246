import React, { FC } from 'react'
import { compose } from 'redux'
import { useDispatch } from 'react-redux'
import { callHangup } from 'src/common/redux/features'
import { withActiveCall } from 'src/common/hoc'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'

// callId will override the one from withActiveCall
type HangupButtonProps = {
  callId?: string
  label?: string
}

export const HangupButton: FC<HangupButtonProps> = withActiveCall(
  ({ callId }) => {
    const dispatch = useDispatch()

    const clickHandler = () => {
      dispatch(callHangup(callId))
    }

    return (
      <button
        className='py-1 px-2 bg-sw-red border-sw-red text-white text-xs sm:text-sm rounded leading-4 sm:leading-normal'
        onClick={clickHandler}
        data-test={TestId.BottomBarButtonHangUp}
      >
        &larr; {tr(Label.LEAVE)}{' '}
        <span className='hidden sm:inline'>{tr(Label.ROOM)}</span>
      </button>
    )
  }
)

export const InCallHangupButton =
  compose<FC<HangupButtonProps>>(withActiveCall)(HangupButton)
