"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerOnStatsListener = exports.disconnect = exports.connect = exports.mapStream = exports.addEvent = exports.enableDataCollection = exports.disableDataCollection = exports.addKeys = exports.setUserRating = exports.setConfig = exports.initSDK = void 0;
var watchrtcsocket_1 = require("./watchrtcsocket");
var watchrtchttp_1 = require("./watchrtchttp");
var utils_1 = require("./utils");
var version_1 = require("./version");
var standardGetstats = true;
var isFirefox = !!window.mozRTCPeerConnection;
var isSafari = !isFirefox && window.RTCPeerConnection && !window.navigator.webkitGetUserMedia;
// Data structure for RTCPeerConnection related stuff we need
var openChannels = {};
var watchrtcIdentifiers = {
    rtcRoomId: undefined,
    rtcPeerId: undefined,
    projectId: undefined,
};
var watchrtcConfig = null;
var http = null;
var socket = null;
var trace;
var lastConnectionOpen = 0; // so we know when was the last active connection seen
var getStatsInterval;
var tryingToConnectSocket = false;
var hardwareInfo;
var currentAudioOutputId;
var authFailed = false;
var reconnectTimerId;
var isManualConnect = false;
var isManualDisconnect = false;
var getStatsCallback;
var debugLog = utils_1.debugLog.bind(null, function () { return watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.debug; });
var maybeOpenWebsocketConnection = function (_a) {
    var _b;
    var _c = _a.forceRecreate, forceRecreate = _c === void 0 ? false : _c, _d = _a.reconnecting, reconnecting = _d === void 0 ? false : _d, _e = _a.pcId, pcId = _e === void 0 ? "PC_unknown" : _e;
    debugLog("info", "maybeOpenWebsocketConnection called: [".concat(pcId, "]"), {
        forceRecreate: forceRecreate,
        reconnecting: reconnecting,
        isManualDisconnect: isManualDisconnect,
        tryingToConnectSocket: tryingToConnectSocket,
        watchrtcConfig: watchrtcConfig,
        openChannels: JSON.stringify(openChannels),
    });
    isManualDisconnect = false;
    var opened = ((_b = socket === null || socket === void 0 ? void 0 : socket.connection) === null || _b === void 0 ? void 0 : _b.readyState) === WebSocket.OPEN;
    if (opened) {
        var roomIdOrPeerIdChanged = (0, utils_1.isRoomIdOrPeerIdChanged)(watchrtcIdentifiers, watchrtcConfig);
        if (roomIdOrPeerIdChanged && forceRecreate) {
            debugLog("info", "maybeOpenWebsocketConnection. Closing WS connection. [".concat(pcId, "]"));
            socket === null || socket === void 0 ? void 0 : socket.close();
        }
        else {
            debugLog("info", "maybeOpenWebsocketConnection. WS connection already opened [".concat(pcId, "]"));
            return;
        }
    }
    var connectionCount = (0, utils_1.countOfValidConnections)(openChannels);
    if (!isManualConnect && connectionCount < 1 && !tryingToConnectSocket) {
        debugLog("info", "maybeOpenWebsocketConnection. WS connection not opened - previous connect call not finised or missing peer connection [".concat(pcId, "]"), {
            openChannels: JSON.stringify(openChannels),
            connectionCount: connectionCount,
            tryingToConnectSocket: tryingToConnectSocket,
            isManualConnect: isManualConnect,
        });
        return;
    }
    var canConnect = (0, utils_1.validateConfig)(watchrtcConfig);
    var id = Object.keys(openChannels)[connectionCount - 1]; // not very critical, but for consistency with trace
    if (!canConnect) {
        tryingToConnectSocket = false;
        debugLog("info", "maybeOpenWebsocketConnection. WS connection not opened - invalid config [".concat(pcId, "]"), {
            watchrtcConfig: watchrtcConfig,
        });
        return;
    }
    if (watchrtcConfig.keys) {
        Object.keys(watchrtcConfig.keys || {}).forEach(function (k) {
            if (typeof watchrtcConfig.keys[k] === "string") {
                watchrtcConfig.keys[k] = [watchrtcConfig.keys[k]];
            }
        });
    }
    var useToken = !!watchrtcConfig.rtcToken;
    var wsConnectionData = (0, utils_1.getConnectionData)("ws", useToken ? watchrtcConfig.rtcToken : watchrtcConfig.rtcApiKey, watchrtcConfig.proxyUrl);
    if (!socket) {
        debugLog("error", "maybeOpenWebsocketConnection. WS socket wasn't initialized [".concat(pcId, "]"));
    }
    tryingToConnectSocket = true;
    lastConnectionOpen = Date.now();
    debugLog("info", "maybeOpenWebsocketConnection. Opening websocket connection [".concat(pcId, "]"));
    var wsOpeningTimestamp = Date.now();
    socket === null || socket === void 0 ? void 0 : socket.connect("".concat(wsConnectionData.url, "?").concat(useToken ? "token" : "apiKey", "=").concat(wsConnectionData.key, "&timestamp=").concat(Date.now()), function (data) {
        var _a;
        watchrtcIdentifiers.projectId = data.projectId;
        tryingToConnectSocket = false;
        watchrtcConfig.allowBrowserLogCollection = Boolean(data.collectConsoleLogEnabled);
        if (!watchrtcConfig.allowBrowserLogCollection) {
            (0, utils_1.restoreOriginalConsoleMethods)();
            // clean up collected logs from socket buffer
            if (socket === null || socket === void 0 ? void 0 : socket.buffer) {
                socket.buffer = socket.buffer.filter(function (data) { return data[0] !== "log"; });
            }
        }
        else {
            if (!((_a = watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.console) === null || _a === void 0 ? void 0 : _a.override) && data.collectConsoleLogLevel) {
                (0, utils_1.setConsoleLevel)(data.collectConsoleLogLevel, trace);
            }
        }
        console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("info"), false), ["Connection established. watchRTCConnectionId: ".concat(data.connectionId, " sdkVersion:").concat(version_1.default, " [").concat(pcId, "]")], false));
        trace({ data: ["watchrtc", id, __assign(__assign(__assign({}, watchrtcConfig), data), { sdkVersion: version_1.default })] });
        if (reconnecting) {
            trace({ data: ["reconnect", null, null] });
        }
        if (hardwareInfo) {
            trace({ data: ["hardware", null, hardwareInfo] });
        }
        window.clearInterval(getStatsInterval);
        getStatsInterval = window.setInterval(function () {
            return __awaiter(this, void 0, void 0, function () {
                var stats, _i, _a, pcInfo, _b, peer, streams;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            if (!(!isManualConnect && (0, utils_1.countOfValidConnections)(openChannels) === 0)) return [3 /*break*/, 1];
                            debugLog("info", "getStatsInterval. No valid connections at this time");
                            // if we don't have any connection for 20 sec we can close the socket
                            if (lastConnectionOpen && lastConnectionOpen + 20000 < Date.now()) {
                                window.clearInterval(getStatsInterval);
                                socket === null || socket === void 0 ? void 0 : socket.close();
                                console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("info"), false), ["Last connection closed. watchRTCConnectionId: ".concat(data.connectionId, " sdkVersion: ").concat(version_1.default)], false));
                            }
                            return [3 /*break*/, 6];
                        case 1:
                            lastConnectionOpen = Date.now();
                            stats = { connections: {}, streams: {} };
                            _i = 0, _a = Object.values(openChannels);
                            _c.label = 2;
                        case 2:
                            if (!(_i < _a.length)) return [3 /*break*/, 5];
                            pcInfo = _a[_i];
                            debugLog("info", "getStatsInterval. ".concat(pcInfo.id, " signalingState: ").concat(pcInfo.pc.signalingState));
                            if (!(pcInfo.pc.signalingState !== "closed")) return [3 /*break*/, 4];
                            return [4 /*yield*/, getStats(pcInfo)];
                        case 3:
                            _b = _c.sent(), peer = _b.peer, streams = _b.streams;
                            stats.connections = __assign(__assign({}, stats.connections), peer);
                            stats.streams = __assign(__assign({}, stats.streams), streams);
                            _c.label = 4;
                        case 4:
                            _i++;
                            return [3 /*break*/, 2];
                        case 5:
                            if (getStatsCallback) {
                                getStatsCallback(stats);
                            }
                            _c.label = 6;
                        case 6: return [2 /*return*/];
                    }
                });
            });
        }, data.interval);
    }, function (_, type) {
        if (type === "auth") {
            authFailed = true;
        }
        tryingToConnectSocket = false;
        lastConnectionOpen = 0;
    }, function () {
        authFailed = false;
        if (reconnectTimerId) {
            clearInterval(reconnectTimerId);
            reconnectTimerId = undefined;
        }
        var delta = Date.now() - wsOpeningTimestamp;
        debugLog("info", "maybeOpenWebsocketConnection. Connection opened. Opening time - ".concat(delta, " ms [").concat(pcId, "]"));
    }, function (closeEvent) {
        var code = closeEvent.code, reason = closeEvent.reason, wasClean = closeEvent.wasClean;
        debugLog("info", "close event", { authFailed: authFailed, code: code, reason: reason, wasClean: wasClean });
        if (authFailed) {
            if (reconnectTimerId) {
                clearInterval(reconnectTimerId);
            }
        }
        else if (!reconnectTimerId && !isManualDisconnect) {
            maybeOpenWebsocketConnection({ reconnecting: true, pcId: pcId });
            reconnectTimerId = setInterval(function () {
                maybeOpenWebsocketConnection({ reconnecting: true, pcId: pcId });
            }, 30 * 1000);
        }
    });
};
var getStats = function (pcInfo) {
    return new Promise(function (resolve, __reject) {
        if (pcInfo) {
            var id_1 = pcInfo.id, pc_1 = pcInfo.pc, prev_1 = pcInfo.prev;
            if (standardGetstats || isFirefox || isSafari) {
                pc_1.getStats(null).then(function (res) {
                    debugLog("info", "getStats res", { res: res });
                    var now = {};
                    if (isFirefox) {
                        res.forEach(function (report) {
                            now["".concat(report.type, "_").concat(report.id)] = report;
                        });
                    }
                    else {
                        now = (0, utils_1.map2obj)(res);
                    }
                    var base = JSON.parse(JSON.stringify(now)); // our new prev
                    now = (0, utils_1.applyPatchForRTT)(prev_1, now);
                    var data = (0, utils_1.deltaCompression)(prev_1, now, watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.debug);
                    debugLog("info", "getStats(null) [".concat(id_1, "]"), { data: data });
                    if ((data === null || data === void 0 ? void 0 : data.timestamp) !== null && (data === null || data === void 0 ? void 0 : data.timestamp) !== -Infinity) {
                        trace({ data: ["getstats", id_1, data] });
                    }
                    pcInfo.prev = base;
                    resolve((0, utils_1.exposeApplicationStatsForPC)(id_1, prev_1, now));
                });
            }
            else {
                pc_1.getStats(function (res) {
                    var now = (0, utils_1.mangleChromeStats)(pc_1, res);
                    var base = JSON.parse(JSON.stringify(now)); // our new prev
                    var data = (0, utils_1.deltaCompression)(prev_1, now, watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.debug);
                    debugLog("info", "getStats() [".concat(id_1, "]"), { data: data });
                    if ((data === null || data === void 0 ? void 0 : data.timestamp) !== null && (data === null || data === void 0 ? void 0 : data.timestamp) !== -Infinity) {
                        trace({ data: ["getstats", id_1, data] });
                    }
                    pcInfo.prev = base;
                    resolve((0, utils_1.exposeApplicationStatsForPC)(id_1, prev_1, now));
                });
            }
        }
    });
};
var getHardware = function () {
    setTimeout(function () {
        // if get hardware take more than 50 ms then do not save it
        var getHardwareStartTime = Date.now();
        (0, utils_1.getHardwareInfo)()
            .then(function (hwInfo) {
            var getHardwareTime = Date.now() - getHardwareStartTime;
            if (getHardwareTime <= 50000) {
                hardwareInfo = hwInfo;
                debugLog("info", "getHardware", { hardwareInfo: hardwareInfo });
            }
            else {
                debugLog("info", "getHardware failure: getHardwareTime: ".concat(getHardwareTime), { hardwareInfo: hardwareInfo });
            }
        })
            .catch(function (err) {
            console.error("Error. Get hardware info: ".concat(err.message));
        });
    }, 0);
};
/**
 * Initialize SDK.
 * @param watchrtc
 * @param prefixesToWrap
 * @param services
 */
var initSDK = function (watchrtc, prefixesToWrap, services) {
    var _a;
    var initialized = window.watchRTCInitialized;
    if (initialized) {
        return;
    }
    else {
        var isRTCPeerConnectionNative = RTCPeerConnection.toString().indexOf("[native code]") !== -1;
        if (!isRTCPeerConnectionNative) {
            console.warn.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("info"), false), ["init. RTCPeerConnection object has been already overriden"], false));
        }
        window.watchRTCInitialized = true;
        getHardware();
    }
    var urlParams = new URLSearchParams(location.search);
    if (urlParams.has("watchrtc") && urlParams.get("watchrtc") === "debug") {
        watchrtc.debug = true;
    }
    var peerconnectioncounter = 0;
    socket = (services === null || services === void 0 ? void 0 : services.socketService) || new watchrtcsocket_1.default({ debug: watchrtc === null || watchrtc === void 0 ? void 0 : watchrtc.debug });
    http = (services === null || services === void 0 ? void 0 : services.httpService) || new watchrtchttp_1.default({ debug: watchrtc === null || watchrtc === void 0 ? void 0 : watchrtc.debug });
    watchrtcConfig = watchrtc;
    watchrtcIdentifiers.rtcRoomId = watchrtcConfig.rtcRoomId;
    watchrtcIdentifiers.rtcPeerId = watchrtcConfig.rtcPeerId;
    trace = socket.trace;
    if (watchrtcConfig.wsUrl) {
        console.warn.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("info"), false), ["\"wsUrl\" config property is deprecated. Use \"proxyUrl\" instead of it"], false));
    }
    if (watchrtcConfig.proxyUrl) {
        console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("info"), false), ["\"proxyUrl\" is used"], false));
    }
    prefixesToWrap.forEach(function (prefix) {
        if (!window[prefix + "RTCPeerConnection"]) {
            return;
        }
        var origPeerConnection = window[prefix + "RTCPeerConnection"];
        var peerconnection = function (config, constraints) {
            if (config === null || config === void 0 ? void 0 : config.watchrtc) {
                watchrtcConfig = __assign(__assign({}, watchrtcConfig), config.watchrtc);
            }
            debugLog("info", "new RTCPeerConnection called.", {
                config: config,
                constraints: constraints,
            });
            var pc = new origPeerConnection(config, constraints);
            var id = "PC_" + peerconnectioncounter++;
            pc.__rtcStatsId = id;
            openChannels[id] = {
                id: id,
                pc: pc,
                validConnection: false,
            };
            if (!config) {
                config = { nullConfig: true };
            }
            config = JSON.parse(JSON.stringify(config)); // deepcopy
            // don't log credentials
            ((config && config.iceServers) || []).forEach(function (server) {
                delete server.credential;
            });
            if (config === null || config === void 0 ? void 0 : config.watchrtc) {
                delete config.watchrtc;
            }
            if (isFirefox) {
                config.browserType = "moz";
            }
            else {
                config.browserType = "webkit";
            }
            trace({ data: ["create", id, config] });
            // TODO: do we want to log constraints here? They are chrome-proprietary.
            // http://stackoverflow.com/questions/31003928/what-do-each-of-these-experimental-goog-rtcpeerconnectionconstraints-do
            if (constraints) {
                trace({ data: ["constraints", id, constraints] });
            }
            pc.addEventListener("icecandidate", function (e) {
                trace({ data: ["onicecandidate", id, e.candidate] });
            });
            pc.addEventListener("icecandidateerror", function (e) {
                trace({ data: ["onicecandidateerror", id, e] });
            });
            pc.addEventListener("addstream", function (e) {
                trace({
                    data: [
                        "onaddstream",
                        id,
                        e.stream.id +
                            " " +
                            e.stream.getTracks().map(function (t) {
                                return t.kind + ":" + t.id;
                            }),
                    ],
                });
            });
            pc.addEventListener("track", function (e) {
                trace({
                    data: [
                        "ontrack",
                        id,
                        e.track.kind +
                            ":" +
                            e.track.id +
                            " " +
                            e.streams.map(function (stream) {
                                return "stream:" + stream.id;
                            }),
                    ],
                });
            });
            pc.addEventListener("removestream", function (e) {
                trace({
                    data: [
                        "onremovestream",
                        id,
                        e.stream.id +
                            " " +
                            e.stream.getTracks().map(function (t) {
                                return t.kind + ":" + t.id;
                            }),
                    ],
                });
            });
            pc.addEventListener("signalingstatechange", function () {
                if (openChannels[id] && !openChannels[id].validConnection) {
                    openChannels[id].validConnection = true;
                    setTimeout(function () {
                        maybeOpenWebsocketConnection({ forceRecreate: true, pcId: id });
                    }, 5000);
                }
                else {
                    debugLog("info", "signalingstatechage. WS connection opening not triggered - peer connection not in channels or was already opened [".concat(id, "]"), { openChannels: JSON.stringify(openChannels) });
                }
                trace({ data: ["onsignalingstatechange", id, pc.signalingState] });
            });
            pc.addEventListener("iceconnectionstatechange", function () {
                trace({ data: ["oniceconnectionstatechange", id, pc.iceConnectionState] });
            });
            pc.addEventListener("icegatheringstatechange", function () {
                trace({ data: ["onicegatheringstatechange", id, pc.iceGatheringState] });
            });
            pc.addEventListener("connectionstatechange", function () {
                trace({ data: ["onconnectionstatechange", id, pc.connectionState] });
            });
            pc.addEventListener("negotiationneeded", function () {
                trace({ data: ["onnegotiationneeded", id, undefined] });
            });
            pc.addEventListener("datachannel", function (event) {
                trace({ data: ["ondatachannel", id, [event.channel.id, event.channel.label]] });
            });
            // https://redmine.testrtc.com/issues/6529
            // pc.addEventListener("iceconnectionstatechange", () => {
            //   if (pc.iceConnectionState === "connected") {
            //     getStats(openChannels[id]);
            //   }
            // });
            return pc;
        };
        if ("HTMLMediaElement" in window && "setSinkId" in HTMLMediaElement.prototype) {
            var nativeMethod_1 = HTMLMediaElement.prototype.setSinkId;
            HTMLMediaElement.prototype.setSinkId = function () {
                var selectedDeviceId = arguments[0];
                navigator.mediaDevices
                    .enumerateDevices()
                    .then(function (devices) {
                    var currentDevice = devices.find(function (_a) {
                        var deviceId = _a.deviceId;
                        return deviceId === selectedDeviceId;
                    });
                    if (currentDevice && currentDevice.deviceId !== currentAudioOutputId) {
                        trace({ data: ["audioOutputChange", null, currentDevice.label] });
                    }
                    currentAudioOutputId = selectedDeviceId;
                })
                    .catch(function (error) {
                    debugLog("error", error.message, { error: error });
                });
                return nativeMethod_1.apply(this, arguments);
            };
        }
        // wrap RTCRtpSender.setParameters
        if ("RTCRtpSender" in window && "setParameters" in window.RTCRtpSender.prototype) {
            var origRTCRtpSender = window.RTCRtpSender;
            var nativeMethod_2 = origRTCRtpSender.prototype.setParameters;
            origRTCRtpSender.prototype.setParameters = function () {
                trace({ data: ["setParameters", this.__pcId, arguments] });
                return nativeMethod_2.apply(this, arguments);
            };
        }
        // adding pc id to sender for above logic
        ["addTransceiver"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var streams = "";
                    if (arguments[1] && arguments[1].streams) {
                        streams = arguments[1].streams.map(function (s) { return "stream:" + s.id; }).join(";");
                    }
                    var trackOrKind = typeof arguments[0] === "string" ? arguments[0] : arguments[0].kind + ":" + arguments[0].id;
                    var init = arguments[1] ? __assign(__assign({}, arguments[1]), { streams: streams }) : null;
                    trace({ data: [method, this.__rtcStatsId, [trackOrKind, init]] });
                    var transceiver = nativeMethod.apply(this, arguments);
                    transceiver.sender.__pcId = this.__rtcStatsId;
                    return transceiver;
                };
            }
        });
        ["createDataChannel"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    trace({ data: [method, this.__rtcStatsId, arguments] });
                    return nativeMethod.apply(this, arguments);
                };
            }
        });
        ["close"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    trace({ data: [method, this.__rtcStatsId, arguments] });
                    delete openChannels[this.__rtcStatsId];
                    isManualDisconnect = true;
                    debugLog("info", "on RTCPeerConnection(".concat(this.__rtcStatsId, ") close"));
                    return nativeMethod.apply(this, arguments);
                };
            }
        });
        ["addStream", "removeStream"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var stream = arguments[0];
                    var streamInfo = stream
                        .getTracks()
                        .map(function (t) {
                        return t.kind + ":" + t.id;
                    })
                        .join(",");
                    trace({ data: [method, this.__rtcStatsId, stream.id + " " + streamInfo] });
                    return nativeMethod.apply(this, arguments);
                };
            }
        });
        ["addTrack"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var track = arguments[0];
                    var streams = [].slice.call(arguments, 1);
                    trace({
                        data: [
                            method,
                            this.__rtcStatsId,
                            track.kind +
                                ":" +
                                track.id +
                                " " +
                                (streams
                                    .map(function (s) {
                                    return "stream:" + s.id;
                                })
                                    .join(";") || "-"),
                        ],
                    });
                    return nativeMethod.apply(this, arguments);
                };
            }
        });
        ["removeTrack"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var track = arguments[0].track;
                    trace({ data: [method, this.__rtcStatsId, track ? track.kind + ":" + track.id : "null"] });
                    return nativeMethod.apply(this, arguments);
                };
            }
        });
        ["createOffer", "createAnswer"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var rtcStatsId = this.__rtcStatsId;
                    var args = arguments;
                    var opts;
                    if (arguments.length === 1 && typeof arguments[0] === "object") {
                        opts = arguments[0];
                    }
                    else if (arguments.length === 3 && typeof arguments[2] === "object") {
                        opts = arguments[2];
                    }
                    trace({ data: [method, this.__rtcStatsId, opts] });
                    return nativeMethod.apply(this, opts ? [opts] : undefined).then(function (description) {
                        trace({ data: [method + "OnSuccess", rtcStatsId, description] });
                        if (args.length > 0 && typeof args[0] === "function") {
                            args[0].apply(null, [description]);
                            return undefined;
                        }
                        return description;
                    }, function (err) {
                        trace({ data: [method + "OnFailure", rtcStatsId, err.toString()] });
                        if (args.length > 1 && typeof args[1] === "function") {
                            args[1].apply(null, [err]);
                            return;
                        }
                        throw err;
                    });
                };
            }
        });
        ["setLocalDescription", "setRemoteDescription", "addIceCandidate"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var rtcStatsId = this.__rtcStatsId;
                    var args = arguments;
                    var _this = this;
                    // because of setLocalDescription(null) sometimes we don't have SDP value
                    // fippo suggested: Access pc.localDescription.sdp in the successcallback
                    var needToHandleSDPInSuccessCallback = method === "setLocalDescription" && (!args[0] || (args[0] && !args[0].sdp));
                    trace({
                        data: [method, this.__rtcStatsId, needToHandleSDPInSuccessCallback ? { parameterless: true } : args[0]],
                    });
                    return nativeMethod.apply(this, [args[0]]).then(function () {
                        trace({
                            data: [
                                method + "OnSuccess",
                                rtcStatsId,
                                needToHandleSDPInSuccessCallback ? _this === null || _this === void 0 ? void 0 : _this.localDescription : undefined,
                            ],
                        });
                        if (args.length >= 2 && typeof args[1] === "function") {
                            args[1].apply(null, []);
                            return undefined;
                        }
                        return undefined;
                    }, function (err) {
                        trace({ data: [method + "OnFailure", rtcStatsId, err.toString()] });
                        if (args.length >= 3 && typeof args[2] === "function") {
                            args[2].apply(null, [err]);
                            return undefined;
                        }
                        throw err;
                    });
                };
            }
        });
        // wrap static methods. Currently just generateCertificate.
        if (origPeerConnection.generateCertificate) {
            Object.defineProperty(peerconnection, "generateCertificate", {
                get: function () {
                    return arguments.length
                        ? origPeerConnection.generateCertificate.apply(null, arguments)
                        : origPeerConnection.generateCertificate;
                },
            });
        }
        window[prefix + "RTCPeerConnection"] = peerconnection;
        window[prefix + "RTCPeerConnection"].prototype = origPeerConnection.prototype;
    });
    // getUserMedia wrappers
    prefixesToWrap.forEach(function (prefix) {
        var name = prefix + (prefix.length ? "GetUserMedia" : "getUserMedia");
        if (!navigator[name]) {
            return;
        }
        var origGetUserMedia = navigator[name].bind(navigator);
        var gum = function () {
            trace({ data: ["getUserMedia", null, arguments[0]] });
            var cb = arguments[1];
            var eb = arguments[2];
            origGetUserMedia(arguments[0], function (stream) {
                // we log the stream id, track ids and tracks readystate since that is ended GUM fails
                // to acquire the cam (in chrome)
                trace({ data: ["getUserMediaOnSuccess", null, (0, utils_1.dumpStream)(stream)] });
                if (cb) {
                    cb(stream);
                }
            }, function (err) {
                var errorData = ["getUserMediaOnFailure", null, err.name];
                trace({ data: errorData });
                httpReportError(errorData);
                if (eb) {
                    eb(err);
                }
            });
        };
        navigator[name] = gum.bind(navigator);
    });
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        var origGetUserMedia_1 = navigator.mediaDevices.getUserMedia.bind(navigator.mediaDevices);
        var gum = function () {
            trace({ data: ["navigator.mediaDevices.getUserMedia", null, arguments[0]] });
            return origGetUserMedia_1.apply(navigator.mediaDevices, arguments).then(function (stream) {
                trace({ data: ["navigator.mediaDevices.getUserMediaOnSuccess", null, (0, utils_1.dumpStream)(stream)] });
                return stream;
            }, function (err) {
                var errorData = ["navigator.mediaDevices.getUserMediaOnFailure", null, err.name];
                trace({ data: errorData });
                httpReportError(errorData);
                return Promise.reject(err);
            });
        };
        navigator.mediaDevices.getUserMedia = gum.bind(navigator.mediaDevices);
    }
    // getDisplayMedia
    if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
        var origGetDisplayMedia_1 = navigator.mediaDevices.getDisplayMedia.bind(navigator.mediaDevices);
        var gdm = function () {
            trace({ data: ["navigator.mediaDevices.getDisplayMedia", null, arguments[0]] });
            return origGetDisplayMedia_1.apply(navigator.mediaDevices, arguments).then(function (stream) {
                trace({ data: ["navigator.mediaDevices.getDisplayMediaOnSuccess", null, (0, utils_1.dumpStream)(stream)] });
                return stream;
            }, function (err) {
                var errorData = ["navigator.mediaDevices.getDisplayMediaOnFailure", null, err.name];
                trace({ data: errorData });
                httpReportError(errorData);
                return Promise.reject(err);
            });
        };
        navigator.mediaDevices.getDisplayMedia = gdm.bind(navigator.mediaDevices);
    }
    if ((_a = watchrtc.console) === null || _a === void 0 ? void 0 : _a.level) {
        (0, utils_1.setConsoleLevel)(watchrtc.console.level, trace);
    }
};
exports.initSDK = initSDK;
var setConfig = function (newWatchrtcConfig) {
    var _a;
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("error"), false), ["SDK is not initialized. Use 'init' function fisrt."], false));
        return;
    }
    var collectConsoleDisabled = (watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.allowBrowserLogCollection) === false;
    if (!collectConsoleDisabled && ((_a = newWatchrtcConfig === null || newWatchrtcConfig === void 0 ? void 0 : newWatchrtcConfig.console) === null || _a === void 0 ? void 0 : _a.override) === true && newWatchrtcConfig.console.level) {
        (0, utils_1.setConsoleLevel)(newWatchrtcConfig.console.level, trace);
    }
    watchrtcConfig = __assign(__assign({}, watchrtcConfig), newWatchrtcConfig);
    watchrtcIdentifiers.rtcRoomId = watchrtcConfig.rtcRoomId;
    watchrtcIdentifiers.rtcPeerId = watchrtcConfig.rtcPeerId;
    debugLog("info", "setConfig", { newWatchrtcConfig: newWatchrtcConfig, watchrtcConfig: watchrtcConfig });
    maybeOpenWebsocketConnection({});
};
exports.setConfig = setConfig;
/**
 * Set user rating and/or comment for peer session
 * @param rating number from 1 to 5
 * @param comment string
 */
var setUserRating = function (
/** 1 | 2 | 3 | 4 | 5 */
rating, ratingComment) {
    var _a;
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("error"), false), ["SDK is not initialized. Use 'init' function fisrt."], false));
        return Promise.resolve({ error: "SDK is not initialized. Use 'init' function fisrt." });
    }
    var isValidRating = (0, utils_1.validateRating)(rating);
    if (!isValidRating) {
        return Promise.resolve({ error: "Rating is invalid" });
    }
    var opened = ((_a = socket === null || socket === void 0 ? void 0 : socket.connection) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN;
    var data = ["userRating", null, { rating: rating, ratingComment: ratingComment }];
    return new Promise(function (resolve, reject) {
        var options = { promiseFuncs: { resolve: resolve, reject: reject } };
        if (opened) {
            trace({ data: data, options: options });
        }
        else {
            httpTrace.apply(void 0, data).then(function () { return resolve({}); })
                .catch(function (error) { return resolve({ error: error }); });
        }
    });
};
exports.setUserRating = setUserRating;
/**
 * Add keys for peer session
 * @param keys
 */
var addKeys = function (
/** { "key1": "value1", "key2": ["value2_1", "value2_2"]} */
keys) {
    var _a;
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("error"), false), ["SDK is not initialized. Use 'init' function fisrt."], false));
        return;
    }
    Object.keys(keys || {}).forEach(function (k) {
        if (typeof keys[k] === "string") {
            keys[k] = [keys[k]];
        }
    });
    var data = ["keys", null, keys];
    var opened = ((_a = socket === null || socket === void 0 ? void 0 : socket.connection) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN;
    var wasConnected = socket === null || socket === void 0 ? void 0 : socket.wasConnected;
    return new Promise(function (resolve, reject) {
        var options = { promiseFuncs: { resolve: resolve, reject: reject } };
        if (opened) {
            trace({ data: data, options: options });
        }
        else if (!wasConnected) {
            trace({ data: data, options: options });
        }
        else {
            httpTrace.apply(void 0, data).then(function () { return resolve({}); })
                .catch(function (error) { return resolve({ error: error }); });
        }
    });
};
exports.addKeys = addKeys;
var disableDataCollection = function () {
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("error"), false), ["SDK is not initialized. Use 'init' function fisrt."], false));
        return;
    }
    socket === null || socket === void 0 ? void 0 : socket.disableDataCollection();
};
exports.disableDataCollection = disableDataCollection;
var enableDataCollection = function () {
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("error"), false), ["SDK is not initialized. Use 'init' function fisrt."], false));
        return;
    }
    socket === null || socket === void 0 ? void 0 : socket.enableDataCollection();
};
exports.enableDataCollection = enableDataCollection;
var addEvent = function (event) {
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("error"), false), ["addEvent error. SDK is not initialized. Use 'init' function fisrt."], false));
        return;
    }
    // const opened = socket?.connection?.readyState === WebSocket.OPEN;
    // if (!opened && socket?.wasConnected) {
    //   console.info(...logPrefix("error"), `addEvent error. Connection has been closed.`);
    //   return;
    // }
    var isEventValid = (0, utils_1.validateEvent)(event);
    if (!isEventValid) {
        return;
    }
    var data = ["event", null, event];
    return new Promise(function (resolve, reject) {
        var options = { promiseFuncs: { resolve: resolve, reject: reject } };
        trace({ data: data, options: options });
    });
};
exports.addEvent = addEvent;
var mapStream = function (id, name) {
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("error"), false), ["mapStream error. SDK is not initialized. Use 'init' function fisrt."], false));
        return;
    }
    var isDataValid = !!id && !!name;
    if (!isDataValid) {
        return;
    }
    var data = ["mapStream", null, { id: id, name: name }];
    trace({ data: data });
};
exports.mapStream = mapStream;
var connect = function () {
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("error"), false), ["connect error. SDK is not initialized. Use 'init' function fisrt."], false));
        return;
    }
    isManualConnect = true;
    debugLog("info", "manual connect");
    maybeOpenWebsocketConnection({});
};
exports.connect = connect;
var disconnect = function () {
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("error"), false), ["disconnect error. SDK is not initialized. Use 'init' function fisrt."], false));
        return;
    }
    isManualConnect = false;
    isManualDisconnect = true;
    socket === null || socket === void 0 ? void 0 : socket.close();
    debugLog("info", "manual disconnect");
};
exports.disconnect = disconnect;
var registerOnStatsListener = function (listener) {
    getStatsCallback = listener;
};
exports.registerOnStatsListener = registerOnStatsListener;
var httpTrace = function () {
    var _a;
    var data = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        data[_i] = arguments[_i];
    }
    if (!watchrtcIdentifiers.rtcRoomId || !watchrtcIdentifiers.rtcPeerId) {
        var message = "Cannot do http without room and peer ids";
        console.log.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("info"), false), [message], false));
        return Promise.reject(message);
    }
    if (!watchrtcIdentifiers.projectId && !watchrtcConfig.rtcApiKey) {
        var message = "Missing apiKey to enable trace before connection establishment";
        console.log.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("info"), false), [message], false));
        return Promise.reject(message);
    }
    var httpConnectionData = (0, utils_1.getConnectionData)("http", watchrtcConfig.rtcApiKey, watchrtcConfig.proxyUrl);
    return http
        ? http.trace.apply(http, __spreadArray(["".concat(httpConnectionData.url, "/trace"), (_a = watchrtcIdentifiers.projectId) !== null && _a !== void 0 ? _a : httpConnectionData.key, watchrtcIdentifiers.rtcRoomId,
            watchrtcIdentifiers.rtcPeerId], data, false)) : Promise.reject("Invalid configuration of http service");
};
var httpReportError = function (data) {
    var apiKey = watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.rtcApiKey;
    var rtcRoomId = watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.rtcRoomId;
    var rtcPeerId = watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.rtcPeerId;
    if (!apiKey || !rtcRoomId || !rtcPeerId) {
        console.log.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("error"), false), ["Cannot report an error. Please provide apiKey, rtcRoomId and rtcPeerId "], false));
        return;
    }
    var httpConnectionData = (0, utils_1.getConnectionData)("http", watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.rtcApiKey, watchrtcConfig.proxyUrl);
    data = data || (socket === null || socket === void 0 ? void 0 : socket.buffer) || [];
    http === null || http === void 0 ? void 0 : http.trace("".concat(httpConnectionData.url, "/error"), httpConnectionData.key, rtcRoomId, rtcPeerId, data);
};
// (window as any).setUserRating = setUserRating;
// (window as any).addTags = addTags;
// (window as any).addKeys = addKeys;
// (window as any).init = initSDK;
// (window as any).addEvent = addEvent;
// (window as any).wrtcConnect = connect;
// (window as any).wrtcDisconnect = disconnect;
