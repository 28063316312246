import { useState, useEffect, useRef } from 'react'
import { RailsEndpoint, RequestState, Room, RoomKeys } from '../rest'
import { useInternalAPI } from './useInternalAPI'

export type UseFetchRoomConsumerProps = {
  update: (params: Partial<Room>) => Promise<void>
  getErrorByName: (key: RoomKeys) => any
}

type UseFetchRoomData = {
  room: Room | null
  requestState: RequestState
  error: string | null
  errorByKey: {
    [key in RoomKeys]?: any
  }
}
const DEFAULT_DATA: UseFetchRoomData = {
  room: null,
  requestState: RequestState.Loading,
  error: null,
  errorByKey: {},
}

const fetchOptions = {
  endpoint: RailsEndpoint.Rooms,
  method: 'GET',
}
const updateOptions = {
  endpoint: RailsEndpoint.Rooms,
  method: 'PATCH',
}

export const useFetchRoom = (roomId: string) => {
  const baseUrlTransform = (url: string) => `${url}/${roomId}`

  const { invokeAPI: fetchHandler } = useInternalAPI(fetchOptions)
  const { invokeAPI: updateHandler } = useInternalAPI(updateOptions)
  const globalChangedRef = useRef<boolean>(false)
  const visibilityChangedRef = useRef<string>()
  const [data, setData] = useState<UseFetchRoomData>(DEFAULT_DATA)

  const refresh = async () => {
    try {
      setData(DEFAULT_DATA)
      const room = await fetchHandler({
        urlTransform: baseUrlTransform,
      })
      setData({
        room,
        requestState: RequestState.Success,
        error: null,
        errorByKey: {},
      })
    } catch (error) {
      setData({
        room: null,
        requestState: RequestState.Error,
        error: 'Error loading room',
        errorByKey: {},
      })
    }
  }

  const update = async (params: Partial<Room>) => {
    try {
      const response = await updateHandler({
        urlTransform: baseUrlTransform,
        body: JSON.stringify(params),
      })
      Object.keys(params).forEach((key) => {
        if (!globalChangedRef.current && key === 'visibility') {
          visibilityChangedRef.current = params.visibility
        } else {
          globalChangedRef.current = true
        }
        delete data.errorByKey?.[key as RoomKeys]
      })
      setData({
        ...data,
        room: response,
      })
    } catch (error) {
      // @ts-expect-error
      const { errors = {} } = await error.response.json().catch(() => {
        console.error(error, roomId, params)
        return {}
      })
      setData({
        ...data,
        errorByKey: {
          ...data.errorByKey,
          ...errors,
        },
      })
    }
  }

  const getErrorByName = (key: RoomKeys) => {
    return data.errorByKey?.[key]?.join()
  }

  useEffect(() => {
    refresh()
  }, [])

  return { ...data, refresh, update, getErrorByName }
}
