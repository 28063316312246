import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CantinaState } from 'src/common/redux/interfaces'
import {
  getActiveCallId,
  getMyParticipantId,
} from 'src/common/redux/features/calls/callSelectors'
import HorizontalFeatureToggle from 'src/components/Inputs/HorizontalFeatureToggle'
import { callDTMF } from 'src/common/redux/features'
import { hasEchoCancellation } from 'src/common/redux/features/participants/participantSelectors'
import { TestId } from 'src/constants'

export const EchoCancellationToggle = () => {
  const dispatch = useDispatch()
  const callId = useSelector(getActiveCallId)
  const participantId = useSelector(getMyParticipantId)
  const active = useSelector((state: CantinaState) =>
    hasEchoCancellation(state, participantId)
  )

  /**
   * #72 Echo Canc On
   * #73 Echo Canc Off
   */
  const toggleOn = () => dispatch(callDTMF(callId, '#72'))
  const toggleOff = () => dispatch(callDTMF(callId, '#73'))

  return (
    <HorizontalFeatureToggle
      onToggleOn={toggleOn}
      onToggleOff={toggleOff}
      label='Echo Cancellation'
      checked={active}
      className='text-sm p-2 pl-6'
      testId={TestId.AudioToggleEchoCancellation}
    />
  )
}
