import { SoundFile } from 'src/common/constants'

export const audioFiles: { [key in SoundFile]: HTMLAudioElement } = {
  ringer: new Audio('/assets/sounds/phone.mp3'),
  knock: new Audio('/assets/sounds/knock.mp3'),
}

export const playAudio = (name: SoundFile) => {
  if (name in audioFiles) {
    audioFiles[name]?.play()
    return
  }
  console.warn(`Audio file '${name}' not found.`)
}

export const stopAudio = (name: SoundFile) => {
  if (name in audioFiles) {
    audioFiles[name]?.pause()
    // Reset to the beginning of the audio track
    audioFiles[name]?.load()
    return
  }
  console.warn(`Audio file '${name}' not found.`)
}
