import React, { FC } from 'react'
import { compose } from 'redux'
import { useDispatch } from 'react-redux'
import { Checkbox } from '../Inputs/Checkbox'
import { callViewActions } from 'src/common/redux/views'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import { withHideRaiseHandFeatureFlag } from 'src/common/hoc'

// This is its own component just so we can wrap it with a scope

interface HandsRaisedCheckboxProps {
  checked: boolean
}

const HandsRaisedCheckboxFunction: FC<HandsRaisedCheckboxProps> = ({
  checked,
}) => {
  const dispatch = useDispatch()

  return (
    <Checkbox
      checked={checked}
      className='w-full'
      label={tr(Label.HANDS_RAISED)}
      name={tr(Label.HANDS_RAISED)}
      onChange={() => dispatch(callViewActions.updateGroupsList('handRaised'))}
      testId={TestId.ParticipantsGroupToggleHandsRaised}
      wrapperClassName='mb-2 overflow-hidden'
    />
  )
}

export const HandsRaisedCheckbox = compose<any>(
  withHideRaiseHandFeatureFlag({ show: false })
)(HandsRaisedCheckboxFunction)
