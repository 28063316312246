import { useState, useEffect } from 'react'
import { RailsEndpoint, RequestState, Group, GroupMembership } from '../rest'
import { GroupRole } from '../constants'
import { useInternalAPI } from './useInternalAPI'

type UseFetchGroupData = {
  group: Group | null
  membershipById: {
    [membershipId: string]: GroupMembership
  }
  requestState: RequestState
  error: string | null
}
const DEFAULT_DATA: UseFetchGroupData = {
  group: null,
  membershipById: {},
  requestState: RequestState.Loading,
  error: null,
}

const fetchOptions = {
  endpoint: RailsEndpoint.Groups,
  method: 'GET',
}
const updateMembershipGroupRoleOptions = {
  endpoint: RailsEndpoint.GroupMemberships,
  method: 'PATCH',
}
const removeMembershipFromGroupOptions = {
  endpoint: RailsEndpoint.GroupMemberships,
  method: 'DELETE',
}
const addUserToGroupOptions = {
  endpoint: RailsEndpoint.GroupMemberships,
  method: 'POST',
}

/**
 * Use this custom hook to handle a Group.
 * The local state "data" has the structure above with "membershipById"
 * to easily update the memberships when we want to update a single user
 * into this group (see the updateUserGroupRole method)
 */
export const useFetchGroup = (groupId: string) => {
  const { invokeAPI: fetchHandler } = useInternalAPI(fetchOptions)
  const { invokeAPI: updateMembershipGroupRole } = useInternalAPI(
    updateMembershipGroupRoleOptions
  )
  const { invokeAPI: removeMembershipFromGroup } = useInternalAPI(
    removeMembershipFromGroupOptions
  )
  const { invokeAPI: addUserToGroup } = useInternalAPI(addUserToGroupOptions)
  const [data, setData] = useState<UseFetchGroupData>(DEFAULT_DATA)

  const refresh = async () => {
    try {
      setData({
        ...data,
        error: null,
      })
      const response: Group = await fetchHandler({
        urlTransform: (url) => `${url}/${groupId}`,
      })
      const { memberships = [], ...group } = response
      const membershipById: UseFetchGroupData['membershipById'] = {}
      memberships.forEach((row) => {
        membershipById[row.id] = row
      })
      setData({
        group,
        membershipById,
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      setData({
        group: null,
        membershipById: {},
        requestState: RequestState.Error,
        error: 'Error loading group',
      })
    }
  }

  /**
   * Update the group_role for a group membership.
   * It updates the local state with the server response.
   */
  const updateUserGroupRole = async ({
    membershipId,
    groupRole,
  }: {
    membershipId: string
    groupRole: GroupRole
  }) => {
    try {
      const response = await updateMembershipGroupRole({
        urlTransform: (url) => {
          return `${url}/${membershipId}`.replace(':groupId', groupId)
        },
        body: JSON.stringify({ group_role: groupRole }),
      })
      setData({
        ...data,
        membershipById: {
          ...data.membershipById,
          [response.id]: response,
        },
      })
    } catch (error) {
      console.error('Error updating membership', error)
    }
  }

  /**
   * Loop through the userIds list and creates the membership relation
   * (Add multiple userId into the groupId)
   */
  const addUsersToGroup = async (userIds: string[]) => {
    try {
      if (userIds?.length) {
        const promises = userIds.map((userId) => {
          return addUserToGroup({
            urlTransform: (url) => {
              return url.replace(':groupId', groupId)
            },
            body: JSON.stringify({
              user_id: userId,
              group_role: GroupRole.Member,
            }),
          })
        })
        await Promise.all(promises)
      }
    } catch (error) {
      console.error('Error adding user to group', error)
    }
  }

  /**
   * Remove the membership from the groupId
   */
  const removeUserFromGroup = async (membershipId: string) => {
    try {
      await removeMembershipFromGroup({
        urlTransform: (url) => {
          return `${url}/${membershipId}`.replace(':groupId', groupId)
        },
      })
    } catch (error) {
      console.error('Error removing user from group', error)
    }
  }

  const getMembershipUserId = (membershipId: string) => {
    return data.membershipById[membershipId]?.cantina_user_id
  }

  /**
   * Helper methods to expose the internal state to the components
   */
  const getMembershipIds = () => Object.keys(data.membershipById)
  const getGroupUserIds = () => {
    return getMembershipIds().map(getMembershipUserId)
  }

  /**
   * Returns the list of memberships (users) applying the proper UI filters
   * filterByText: filter on the name only for now
   * filterByGroupRole: filter on the membership.group_role (member | maintainer)
   */
  const getMemberships = (params: {
    filterByText?: string
    filterByGroupRole?: string
  }) => {
    const { filterByText, filterByGroupRole } = params
    const memberships: GroupMembership[] = getMembershipIds()
      .filter((id) => {
        const { name, group_role } = data.membershipById[id]
        if (filterByText && !name.toLowerCase().includes(filterByText)) {
          return false
        }
        if (filterByGroupRole !== 'all' && group_role !== filterByGroupRole) {
          return false
        }
        return true
      })
      .map((id) => data.membershipById[id])

    return memberships
  }

  useEffect(() => {
    refresh()
  }, [])

  return {
    ...data,
    refresh,
    getMemberships,
    getGroupUserIds,
    updateUserGroupRole,
    addUsersToGroup,
    removeUserFromGroup,
  }
}
