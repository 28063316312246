import React, { FC } from 'react'
import { tr, Label } from 'src/common/i18n'
import { TITLE_MAX_CHARS } from 'src/common/constants'
import { RoomComponentProps } from './RoomDetail'
import { RoomInputGroup } from './RoomInputGroup'
import { RoomTextAreaGroup } from './RoomTextAreaGroup'

export const RoomBasicInfo: FC<RoomComponentProps> = ({
  room,
  update,
  getErrorByName,
}) => {
  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.BASIC_INFO)}
      </div>
      <RoomInputGroup
        defaultValue={room?.name}
        getErrorByName={getErrorByName}
        label={Label.ROOM_NAME}
        maxLength={TITLE_MAX_CHARS}
        name='name'
        required
        showCounter
        update={update}
      />
      <RoomTextAreaGroup
        defaultValue={room?.room_description}
        getErrorByName={getErrorByName}
        label={Label.ROOM_DESCR}
        name='room_description'
        update={update}
      />
      <RoomInputGroup
        defaultValue={room?.transfer_to}
        getErrorByName={getErrorByName}
        label={Label.TRANSFER_TO}
        name='transfer_to'
        update={update}
      />
    </>
  )
}
