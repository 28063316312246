import React from 'react'
import { Editor } from '@tinymce/tinymce-react'

import tinymce from 'tinymce/tinymce'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/print'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/code'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/wordcount'

tinymce.init({
  selector: '#tiny',
  plugins: [
    'advlist',
    'autolink',
    'lists',
    'link',
    'image',
    'charmap',
    'print',
    'preview',
    'anchor',
    'searchreplace',
    'visualblocks',
    'code',
    'fullscreen',
    'insertdatetime',
    'media',
    'table',
    'paste',
  ],
})

type TinyMCEProps = {
  height?: number
  initialValue?: string
  label: string
  menubar?: boolean
  name: string
  onChange: (a: string) => void
  outputFormat?: 'html' | 'text'
}

const TinyMCE = ({
  height = 300,
  initialValue = '',
  label,
  menubar = false,
  name,
  onChange,
  outputFormat = 'html',
}: TinyMCEProps) => {
  return (
    <div className='wysiwyg mb-4'>
      <label htmlFor={name}>{label}</label>
      <Editor
        initialValue={initialValue}
        init={{
          skin: false,
          // content_css: false,
          height,
          menubar,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat ',
        }}
        onEditorChange={onChange}
        outputFormat={outputFormat}
        textareaName={name}
      />
    </div>
  )
}

export default TinyMCE
