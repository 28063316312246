import React, { PropsWithChildren } from 'react'

interface CenteredMessageProps {
  className?: string
}
export const CenteredMessage = ({
  children,
}: PropsWithChildren<CenteredMessageProps>) => (
  <div className='flex-1 flex items-center justify-center my-12'>
    <div className='block-primary flex flex-col items-center w-11/12 max-w-2xl py-10 sm:px-10 text-center shadow-md rounded-lg border'>
      {children}
    </div>
  </div>
)
