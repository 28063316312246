import { SagaIterator } from 'redux-saga'
import { call, select, put, take, actionChannel } from 'redux-saga/effects'
import {
  fetchRoomTemplate,
  updateRoomTemplate,
  fetchAvailableRoomTemplates,
} from '../../../rest/roomTemplates'
import { getAuthToken } from '../auth/authSelectors'
import { setScopesRequest } from '../auth/authSlice'
import {
  getDefaultRoomTemplateId,
  getInternalAPIEndpoint,
} from '../settings/settingsSelectors'
import {
  roomTemplatesActions,
  updateRoomTemplateRequest,
} from './roomTemplatesSlice'
import { RailsEndpoint } from '../../../rest'

export function* fetchDefaultRoomTemplateWorker(): SagaIterator {
  try {
    const baseUrl = yield select(
      getInternalAPIEndpoint,
      RailsEndpoint.RoomTemplates
    )
    const params = {
      baseUrl,
      token: yield select(getAuthToken),
      roomTemplateId: yield select(getDefaultRoomTemplateId),
    }
    const roomTemplate = yield call(fetchRoomTemplate, params)
    yield put(roomTemplatesActions.fetchTemplateSuccess(roomTemplate))

    /**
     * Scope.UiDialer depends on the default room template `my_roles` field
     * so we need to set the scopes in here too to make sure the State has
     * the room template data.
     * We may want to review the logic in this ticket:
     * https://github.com/signalwire/cloud-product/issues/4052
     */
    yield put(setScopesRequest())
  } catch (error) {
    console.error('Error fetching the default RoomTemplate', error)
  }
}

/**
 * This Saga uses actionChannel to buffer multiple actions
 * https://redux-saga.js.org/docs/advanced/Channels.html
 */
export function* watchUpdateRoomTemplateRequests(): SagaIterator {
  const channel = yield actionChannel(updateRoomTemplateRequest.type)
  while (true) {
    const { payload } = yield take(channel)
    const { roomTemplateId, ...params } = payload
    const baseUrl = yield select(
      getInternalAPIEndpoint,
      RailsEndpoint.RoomTemplates
    )
    const updateData = {
      baseUrl,
      token: yield select(getAuthToken),
      roomTemplateId,
      params,
    }
    const roomTemplate = yield call(updateRoomTemplate, updateData)
    yield put(roomTemplatesActions.fetchTemplateSuccess(roomTemplate))
  }
}

export function* fetchAvailableRoomTemplatesWorker(): SagaIterator {
  const baseUrl = yield select(
    getInternalAPIEndpoint,
    RailsEndpoint.RoomTemplatesAvailable
  )
  const token = yield select(getAuthToken)
  const params = {
    baseUrl,
    token,
  }
  const roomTemplates = yield call(fetchAvailableRoomTemplates, params)
  yield put(roomTemplatesActions.setAvailableTemplates(roomTemplates))
}
