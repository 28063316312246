import { createSelector } from 'reselect'
import { Scope } from '../../../constants'
import { CantinaState } from '../../interfaces'
import { getScopeId } from '../auth/authSelectors'

export const getScopes = ({ scopes }: CantinaState) => {
  return scopes.byId
}

const scope = (_: CantinaState, scope: Scope) => scope

export const getAuthScopeList = createSelector(
  getScopes,
  getScopeId,
  (scopes, scopeId) => {
    if (scopeId) {
      return scopes[scopeId] || []
    }
    return []
  }
)

export const hasScope = createSelector(
  getAuthScopeList,
  scope,
  (scopeList, scope) => {
    return scopeList.includes(scope)
  }
)

export const makeAuthScopeSelector = () => {
  return createSelector(getAuthScopeList, scope, (scopeList, scope) => {
    // console.debug('Checking scope:', scope)
    return scopeList.includes(scope)
  })
}
