import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'
import { RoomTemplateState } from '../../interfaces'
import { RoomTemplate } from '../../../rest'
import { flushAction } from '../../actions'

type SetAvailableTemplatesPayload = PayloadAction<
  Pick<RoomTemplate, 'id' | 'name' | 'cantina_id'>[]
>
export const initialRoomTemplateState: RoomTemplateState = Object.freeze({
  byId: {},
})

const roomTemplatesSlice = createSlice({
  name: 'roomTemplates',
  initialState: initialRoomTemplateState,
  reducers: {
    fetchTemplateSuccess: (
      state,
      { payload: roomTemplate }: PayloadAction<RoomTemplate>
    ) => {
      if (roomTemplate.id in state.byId) {
        Object.keys(roomTemplate).forEach((key) => {
          // @ts-expect-error
          state.byId[roomTemplate.id][key] = roomTemplate[key]
        })
      } else {
        state.byId[roomTemplate.id] = roomTemplate
      }
    },
    setAvailableTemplates: (
      state,
      { payload = [] }: SetAvailableTemplatesPayload
    ) => {
      payload.forEach(({ id, name, cantina_id }) => {
        if (id in state.byId) {
          // no-op: in case the id is already there, just update the name
          state.byId[id].name = name
        } else {
          state.byId[id] = {
            id,
            name,
            cantina_id,
          }
        }
      })
    },
  },
  extraReducers: {
    [flushAction.type]: () => initialRoomTemplateState,
  },
})

export const updateRoomTemplateRequest = createAction<
  Partial<RoomTemplate> & { roomTemplateId: string }
>('roomTemplates/updateRoomTemplateRequest')

// prettier-ignore
export const {
  actions: roomTemplatesActions,
  reducer: roomTemplatesReducer
} = roomTemplatesSlice
