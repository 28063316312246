import React, { ComponentType } from 'react'

export const withHybridFlag = <T extends object>(
  WrappedComponent: ComponentType<T>
) => {
  return (props: T) => {
    if (process.env.REACT_APP_HYBRID_MODE === '1') {
      return <WrappedComponent {...props} />
    }
    return null
  }
}
