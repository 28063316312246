import React, { ChangeEvent, ComponentType, FC } from 'react'
import { TestId } from 'src/constants'
import { FontAwesomeIconProps } from 'src/common/icons/FontAwesomeIcon'
import { ArrowDownIcon } from 'src/common/icons'
import { LabelIconState } from 'src/common/constants'
import { IconState } from 'src/components/IconState'

type SelectGroupOptionProps = {
  id: string
  label: string | null
}

export type SelectGroupProps = {
  canHaveError?: boolean
  className?: string
  defaultSelectedId?: string
  disabled?: boolean
  error?: string
  horizontalLabel?: boolean
  IconLabel?: ComponentType<Partial<FontAwesomeIconProps>>
  id?: string
  label?: string
  labelIconState?: LabelIconState
  name: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  options?: SelectGroupOptionProps[]
  placeholder?: string
  required?: boolean
  selectClassName?: string
  selectedId?: string
  testId?: TestId
}

export const SelectGroup: FC<SelectGroupProps> = ({
  canHaveError = true,
  className = '',
  defaultSelectedId,
  disabled = false,
  error,
  horizontalLabel = false,
  IconLabel,
  id,
  label,
  labelIconState,
  name,
  onChange,
  options = [],
  placeholder = '',
  required = false,
  selectClassName = '',
  selectedId,
  testId,
}) => {
  const classNames = `${error ? 'sw-input-error' : ''} ${selectClassName}`
  const hasIconLabel = !!(IconLabel && label)

  return (
    <div className={className}>
      {/* Standard label above input */}
      {!horizontalLabel && !IconLabel && label && (
        <label htmlFor={name}>
          {`${label}${required ? '\u00A0*' : ''}`}
          {labelIconState && <IconState state={labelIconState} />}
        </label>
      )}
      {/* Horizontal text label */}
      <div className='flex items-center'>
        {horizontalLabel && label && (
          <label htmlFor={name} className='leading-none mr-2'>
            {label}
          </label>
        )}
        {/* Horizontal icon label */}
        {hasIconLabel && (
          <label
            aria-label={label}
            htmlFor={name}
            className='leading-none pr-4'
          >
            <IconLabel fixedWidth />
          </label>
        )}
        <div className={`relative ${horizontalLabel ? '' : 'flex-1'}`}>
          <select
            className={`sw-select ${classNames}`}
            data-test={testId}
            defaultValue={defaultSelectedId}
            disabled={disabled}
            id={id || name}
            name={name}
            onChange={onChange}
            required={required}
            value={selectedId}
          >
            {placeholder && <option value=''>{placeholder}</option>}
            {options.map(({ id, label }) => (
              <option key={id} value={id}>
                {label}
              </option>
            ))}
          </select>
          <div className='sw-select-arrow'>
            <ArrowDownIcon fixedWidth />
          </div>
        </div>
      </div>
      {canHaveError && (
        <div
          className={`sw-error-hint ${error ? 'sm:visible' : 'sm:invisible'} ${
            hasIconLabel ? 'ml-10' : ''
          }`}
          data-test={TestId.MessageError}
        >
          {error}
        </div>
      )}
    </div>
  )
}
