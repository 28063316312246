import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDispatchRoomParticipantCommand } from 'src/common/hooks'
import { CantinaState } from 'src/common/redux/interfaces'
import {
  getVolume as getVolumeSelector,
  isDeaf,
} from 'src/common/redux/features/participants/participantSelectors'
import { RoomCommandAction } from 'src/common/constants'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import RangeSlider from 'src/components/Inputs/RangeSlider'

interface ParticipantVolumeControlProps {
  participantId: string
  className?: string
  horizontalLabel?: boolean
  LowerIcon?: any
  UpperIcon?: any
}

export const ParticipantVolumeControl: FC<ParticipantVolumeControlProps> = ({
  participantId,
  ...rest
}) => {
  const dispatchRoomParticipantCommand =
    useDispatchRoomParticipantCommand(participantId)
  const volumeVal = useSelector((state: CantinaState) =>
    getVolumeSelector(state, participantId)
  )
  const dndEnabled = useSelector((state: CantinaState) =>
    isDeaf(state, participantId)
  )
  const onChange = useCallback(
    (val: number) => {
      dispatchRoomParticipantCommand({
        action: RoomCommandAction.MemberVolume,
        participantId,
        value: val,
      })
    },
    [dispatchRoomParticipantCommand, participantId]
  )

  return (
    <RangeSlider
      className='mb-3'
      currentValue={volumeVal ?? undefined}
      disabled={dndEnabled}
      label={tr(Label.SPEAKER_VOLUME)}
      onValueChange={onChange}
      testId={TestId.SoundRangeVolume}
      {...rest}
    />
  )
}
