import React, { FC } from 'react'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import { useHistoryPushToRoom } from 'src/effects/useHistoryPushToRoom'
import { PrimaryButton, TransparentPrimaryButton } from './Button/Button'

export type JoinButtonProps = {
  extension: string
  testId: TestId | string
  disabled?: boolean
  label?: string
  forceToJoin?: boolean
  transparent?: boolean
  wrap?: boolean
}

export const JoinButton: FC<JoinButtonProps> = ({
  disabled = false,
  extension,
  label = tr(Label.JOIN_ROOM),
  testId,
  transparent = false,
  wrap = false,
  forceToJoin = false,
}) => {
  const { pushHandler } = useHistoryPushToRoom()

  const ButtonComponent = transparent ? TransparentPrimaryButton : PrimaryButton
  const defaultClasses = wrap ? '' : 'whitespace-nowrap'

  const clickHandler = () => {
    pushHandler({ roomName: extension, force: forceToJoin })
  }

  return (
    <ButtonComponent
      className={`${defaultClasses} sw-btn-xs`}
      disabled={disabled}
      onClick={clickHandler}
      testId={testId}
    >
      {label}
    </ButtonComponent>
  )
}
