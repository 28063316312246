import React, { FC } from 'react'
import { TestId } from 'src/constants'
import { useSelector } from 'react-redux'
import { CantinaState } from 'src/common/redux/interfaces'
import { getMyParticipantId } from 'src/common/redux/features/calls/callSelectors'
import { isDeaf } from 'src/common/redux/features/participants/participantSelectors'

import { InCallLeaveRoomButton } from 'src/components/CallButtons/LeaveRoomButton'
import { InCallParticipantAudioButton } from 'src/components/ParticipantCommandButtons/ParticipantAudioButton'
import { InCallParticipantVideoButton } from 'src/components/ParticipantCommandButtons/ParticipantVideoButton'
import { InCallMoreButton } from 'src/components/CallButtons/MoreButton'
import { InCallSidebarButton } from 'src/components/CallButtons/SidebarButton'
import { InCallParticipantHandButton } from 'src/components/ParticipantCommandButtons/ParticipantHandButton'
import { InCallScreenShareButton } from 'src/components/CallButtons/ScreenShareButton'
import { InCallParticipantDnDButton } from 'src/components/ParticipantCommandButtons/ParticipantDnDButton'
import { InCallAdvancedControlsButton } from 'src/components/CallButtons/AdvancedControlsButton'
import { InCallChatButton } from 'src/components/CallButtons/ChatButton'

export const BottomBar: FC = () => {
  // If we have a participant ID, we should be allowed to use the controls
  const participantId = useSelector(getMyParticipantId)
  const callIsReady = !!participantId
  const dndEnabled = useSelector((state: CantinaState) =>
    isDeaf(state, participantId)
  )

  return (
    <div className='in-call-bottom-bar' data-test={TestId.BottomBarContainer}>
      <InCallLeaveRoomButton />
      <div className='flex text-white pl-2'>
        <div>
          <InCallParticipantAudioButton disabled={!callIsReady || dndEnabled} />
        </div>
        <div>
          <InCallParticipantVideoButton disabled={!callIsReady} />
        </div>
        <div className='element-mobile'>
          <InCallMoreButton disabled={!callIsReady} />
        </div>
        <span className='element-desktop'>
          <InCallSidebarButton disabled={!callIsReady} />
        </span>
        <div className='element-desktop'>
          <InCallParticipantHandButton disabled={!callIsReady || dndEnabled} />
        </div>
        <div className='element-desktop'>
          <InCallScreenShareButton disabled={!callIsReady} />
        </div>
        <div className='element-desktop'>
          <InCallParticipantDnDButton disabled={!callIsReady} />
        </div>
        <div className='element-desktop'>
          <InCallAdvancedControlsButton disabled={!callIsReady} />
        </div>
      </div>
      <div className='element-desktop'>
        <InCallChatButton />
      </div>
    </div>
  )
}
