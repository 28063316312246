import { RefreshResponse, FETCH_OPTIONS, cantinaFetch } from '.'

type RefreshJWTParams = {
  baseUrl: string
  body: {
    cantinaId: string
    refreshToken?: string
    expiresIn?: number
  }
}

export const refreshJWT = ({
  baseUrl,
  body,
}: RefreshJWTParams): Promise<RefreshResponse> => {
  const options: RequestInit = {
    ...FETCH_OPTIONS,
    credentials: 'include',
    method: 'PUT',
    body: JSON.stringify(body),
  }
  return cantinaFetch(baseUrl, options)
}
