import React from 'react'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeIconProps } from './FontAwesomeIcon'

export const FaIcon = ({
  icon,
  prefix,
  size,
  fixedWidth,
  transform,
  symbol,
  className,
  spin,
  flip,
  rotation,
  style,
}: FontAwesomeIconProps & { prefix: IconPrefix }) => {
  return (
    <FontAwesomeIcon
      className={className}
      icon={[prefix, icon as IconName]}
      size={size}
      fixedWidth={fixedWidth}
      transform={transform}
      symbol={symbol}
      spin={spin}
      flip={flip}
      rotation={rotation}
      style={style}
    />
  )
}

export const SolidIcon = (props: FontAwesomeIconProps) => {
  return <FaIcon prefix='fas' {...props} />
}

export const DuotoneIcon = (props: FontAwesomeIconProps) => {
  return <FaIcon prefix='fad' {...props} />
}

export const RegularIcon = (props: FontAwesomeIconProps) => {
  return <FaIcon prefix='far' {...props} />
}

export const LightIcon = (props: FontAwesomeIconProps) => {
  return <FaIcon prefix='fal' {...props} />
}
