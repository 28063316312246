import { useState } from 'react'
import { useSelector } from 'react-redux'
import { tr, Label } from '../i18n'
import { getDefaultRoomTemplateId } from '../redux/features/settings/settingsSelectors'
import { RailsEndpoint, RequestState, Room, FetchError } from '../rest'
import { toastError, toastSuccess } from '../services/relay'
import { useInternalAPI } from './useInternalAPI'

type UseDuplicateRoomData = {
  errors: {
    [key: string]: string[]
  }
  requestState: RequestState
  room: Room | null
}

interface useDuplicateRoomParams {
  room: Room
  rooms: Room[]
}

const regex = /(-\(\d*\))$/
const createOptions = {
  endpoint: RailsEndpoint.Rooms,
  method: 'POST',
}

export const useDuplicateRoom = () => {
  const { invokeAPI: createHandler } = useInternalAPI(createOptions)
  const defaultRoomTemplateId = useSelector(getDefaultRoomTemplateId)
  const [data, setData] = useState<UseDuplicateRoomData>({
    room: null,
    requestState: RequestState.Success,
    errors: {},
  })

  const duplicate = async ({ room, rooms }: useDuplicateRoomParams) => {
    // Create a new name for the room
    const { name, ...rest } = room
    // Account for myRoom-(1) so you can duplicate these based
    // on the original name
    const originalName = name.split(regex)[0]

    // If there are multiple rooms with that name,
    // find the highest number inside the parens
    const roomNums = rooms
      .filter((room) => room.name.split(regex)[0] === originalName)
      .map((rm) => parseInt(rm.name.replace(/\D/g, '')) || 0)
    const count = Math.max(...roomNums) + 1

    // Assemble the new name
    const duplicateRoomName = `${originalName}-(${count})`

    try {
      if (data.requestState !== RequestState.Loading) {
        setData({
          ...data,
          requestState: RequestState.Loading,
          errors: {},
        })
      }
      const room = await createHandler({
        body: JSON.stringify({
          cantina_room_template_id: defaultRoomTemplateId as string,
          name: duplicateRoomName,
          ...rest,
        }),
      })
      setData({
        room,
        requestState: RequestState.Success,
        errors: {},
      })
      toastSuccess(tr(Label.CREATE_ROOM_SUCCESS))
    } catch (error) {
      const handlerError = <FetchError>error
      setData({
        ...data,
        requestState: RequestState.Error,
        errors: handlerError?.body?.errors || {},
      })
      toastError(tr(Label.CREATE_ROOM_ERROR))
    }
  }

  return { ...data, duplicate }
}
