import React, { FC } from 'react'
import { RoomCommandAction } from 'src/common/constants'
import { useDispatchRoomCommand } from 'src/common/hooks'
import { withConferenceKickScope } from 'src/common/hoc'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import { ButtonWithConfirmation, SecondaryButton } from '../Button/Button'

type KickAllButtonProps = {
  className?: string
  roomId: string
  withConfirmation?: boolean
}

const KickAllButton: FC<KickAllButtonProps> = ({
  className,
  roomId,
  withConfirmation,
  ...rest
}) => {
  const dispatchRoomCommand = useDispatchRoomCommand()
  const clickHandler = () =>
    dispatchRoomCommand({ roomId, action: RoomCommandAction.KickAll })
  const Component = withConfirmation ? ButtonWithConfirmation : SecondaryButton

  return (
    <Component
      className={`sw-btn block ${className}`}
      onClick={clickHandler}
      testId={TestId.AudienceButtonRemoveAll}
      {...rest}
    >
      {tr(Label.REMOVE_EVERYONE)}
    </Component>
  )
}

export const ScopedKickAllButton: FC<KickAllButtonProps> =
  withConferenceKickScope(KickAllButton)
