import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'
import { ClipeezeState } from '../../interfaces'
import { callActions } from '../../features'

export const initialClipeezeState: ClipeezeState = Object.freeze({
  byId: {},
  ids: [],
  links: {
    first: null,
    next: null,
    prev: null,
    self: null,
  },
  clipeezeFilter: '',
  filteredIds: [],
  filteredLinks: {
    first: null,
    next: null,
    prev: null,
    self: null,
  },
})

const clearHandler = () => initialClipeezeState

const clipeezeSlice = createSlice({
  name: 'clipeeze',
  initialState: initialClipeezeState,
  reducers: {
    addList: (
      state,
      { payload }: PayloadAction<Pick<ClipeezeState, 'byId' | 'ids' | 'links'>>
    ) => {
      // Add clips to the list
      state.byId = { ...state.byId, ...payload.byId }

      // Add clips and keep them in order by adding new IDs to the end
      // If there's a duplicate, it's kept in its original location
      state.ids = Array.from(new Set([...state.ids, ...payload.ids]))
      state.links = payload.links || initialClipeezeState.links
    },
    addListFiltered: (
      state,
      { payload }: PayloadAction<Pick<ClipeezeState, 'byId' | 'ids' | 'links'>>
    ) => {
      // Add clips to the list
      state.byId = { ...state.byId, ...payload.byId }

      // Add filtered clips to their own list and keep them in order
      state.filteredIds = Array.from(
        new Set([...state.filteredIds, ...payload.ids])
      )
      state.filteredLinks = payload.links || initialClipeezeState.filteredLinks
    },
    setClipeezeFilter: (
      state,
      { payload }: PayloadAction<{ filter: string }>
    ) => {
      state.clipeezeFilter = payload.filter.toLowerCase().trim()
    },
    clearClipeezeFilter: (state) => {
      state.clipeezeFilter = ''
      state.filteredIds = []
      state.filteredLinks = initialClipeezeState.filteredLinks
    },
    clearClipeezeFiltered: (state) => {
      state.filteredIds = []
      state.filteredLinks = initialClipeezeState.filteredLinks
    },
  },
  extraReducers: (builder) => {
    builder.addCase(callActions.destroy, clearHandler)
  },
})

// These actions automatically update the loading slice
export const loadClipeezeRequest = createAction('clipeeze/loadClipeezeRequest')
export const loadClipeezeSuccess = createAction('clipeeze/loadClipeezeSuccess')
export const loadClipeezeError = createAction('clipeeze/loadClipeezeError')
export const loadClipeezeFilteredRequest = createAction(
  'clipeeze/loadClipeezeFilteredRequest'
)
export const loadClipeezeFilteredSuccess = createAction(
  'clipeeze/loadClipeezeFilteredSuccess'
)
export const loadClipeezeFilteredError = createAction(
  'clipeeze/loadClipeezeFilteredError'
)

export const {
  actions: clipeezeActions,
  reducer: clipeezeReducer,
} = clipeezeSlice
