import React, { FC } from 'react'
import { Size } from 'src/constants'
import { Avatar } from 'src/components/Images/Avatar'
import { ParticipantTalkingShadow } from 'src/components/ParticipantList/ParticipantTalkingShadow'

type UserProfileProps = {
  avatar: string
  badges?: React.ReactNode
  clickHandler?: any
  isScreenShare?: boolean
  name: string
  nameClassName?: string
  participantId?: string
  showSubtitle?: boolean
  showTalkingIndicator?: boolean
  size?: Size
  subtitle?: string
  subtitleClassName?: string
  testId?: string
  wrapperClassName?: string
}

const userProfileHash: Record<string, any> = {
  // Participant List
  [Size.Small]: {
    nameSize: 'text-sm',
    avatarClasses: 'w-8 h-8',
  },
  // Participant Options
  [Size.Large]: {
    nameSize: 'text-xl',
    avatarClasses: 'w-12 h-12',
  },
  // Mini Profile
  [Size.ExtraLarge]: {
    nameSize: '',
    avatarClasses: 'w-14 h-14',
  },
}

export const UserProfile: FC<UserProfileProps> = ({
  avatar,
  badges,
  clickHandler,
  isScreenShare,
  name,
  nameClassName = '',
  participantId,
  showTalkingIndicator = true,
  subtitleClassName = 'text-sm',
  size = Size.Medium,
  subtitle,
  testId,
  wrapperClassName = '',
}) => {
  const { nameSize = 'text-base', avatarClasses = 'w-10 h-10' } =
    userProfileHash?.[size] || {}

  return (
    <div
      className={`flex items-center ${wrapperClassName}`}
      data-test={testId}
      onClick={clickHandler}
    >
      <div className={`relative shrink-0 mr-2 ${avatarClasses}`}>
        <Avatar
          className='absolute w-full h-full rounded-full'
          isScreenShare={isScreenShare}
          size={size}
          src={avatar}
          alt={name}
        />
        {participantId && showTalkingIndicator && (
          <ParticipantTalkingShadow participantId={participantId} />
        )}
      </div>
      <div className='text-left overflow-hidden'>
        <div
          className={`flex items-center overflow-hidden text-contrast-h ${nameSize} ${nameClassName}`}
        >
          <div className='truncate'>{name}</div>
          {badges}
        </div>
        {subtitle && (
          <div className={`truncate text-contrast-m ${subtitleClassName}`}>
            {subtitle}
          </div>
        )}
      </div>
    </div>
  )
}
