import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import Clipboard from 'react-clipboard.js'
import { useDispatchRoomCommand, useRoomSelector } from 'src/common/hooks'
import { SpinnerIcon } from 'src/common/icons'
import { tr, Label } from 'src/common/i18n'
import { Displayable, RoomCommandAction } from 'src/common/constants'
import { roomInvitationURL } from 'src/common/services/helpers'
import { lobbyViewActions } from 'src/common/redux/views'
import { uiActions } from 'src/common/redux/features'
import { toastSuccess } from 'src/services/ToastService'
import {
  hasLogosVisible,
  hasMeetingMode,
  isRecording,
  roomExists,
} from 'src/common/redux/features/rooms/roomsSelectors'
import { TestId } from 'src/constants'
import { SendTTSInput } from './SendTTSInput'
import { ButtonWithConfirmation } from '../Button/Button'

type RoomSettingsProps = {
  roomId: string
  roomName?: string
}

export const RoomSettingsLobby: FC<RoomSettingsProps> = ({
  roomId,
  roomName = '',
}) => {
  const exists = useRoomSelector(roomExists, roomId)

  if (!exists) {
    return (
      <div className='flex flex-col items-center p-2 gap-3'>
        <div className='text-lg my-1'>{tr(Label.LOADING)}</div>
        <SpinnerIcon />
      </div>
    )
  }

  return (
    <div className='flex flex-col'>
      <RecordingItem roomId={roomId} />
      <PlayMediaItem roomId={roomId} />
      <BannersItem roomId={roomId} />
      <AvoidInterruptionsItem roomId={roomId} />
      <InviteLinkItem roomName={roomName} />
      <RemoveEveryoneItem roomId={roomId} />
      <SendTTSInput roomId={roomId} className='p-4 mt-2 border-t' />
    </div>
  )
}

type PlayMediaItemProps = {
  roomId: string
}

const PlayMediaItem: FC<PlayMediaItemProps> = ({ roomId }) => {
  const dispatch = useDispatch()
  const clickHandler = () => {
    dispatch(lobbyViewActions.setCurrentRoomId(roomId))
    dispatch(uiActions.show(Displayable.MediaModal))
  }

  return (
    <button
      className='app-menu-item justify-start'
      onClick={clickHandler}
      data-test={TestId.RoomSettingsLobbyButtonMedia}
    >
      <div className='app-menu-item-label whitespace-nowrap'>
        {tr(Label.PLAY_MEDIA)}
      </div>
    </button>
  )
}

const RecordingItem: FC<RoomSettingsProps> = ({ roomId }) => {
  const dispatchRoomCommand = useDispatchRoomCommand()
  const recording = useRoomSelector(isRecording, roomId)
  const label = recording ? Label.STOP_RECORDING : Label.START_RECORDING
  const clickHandler = () => {
    const action = recording
      ? RoomCommandAction.StopRecording
      : RoomCommandAction.StartFileRecording

    return dispatchRoomCommand({
      action,
      roomId,
    })
  }

  return (
    <button
      className='app-menu-item justify-start'
      onClick={clickHandler}
      data-test={TestId.RoomSettingsLobbyButtonRecording}
    >
      <div className='app-menu-item-label whitespace-nowrap'>{tr(label)}</div>
    </button>
  )
}

const BannersItem: FC<RoomSettingsProps> = ({ roomId }) => {
  const dispatchRoomCommand = useDispatchRoomCommand()
  const logosVisible = useRoomSelector(hasLogosVisible, roomId)
  const label = logosVisible ? Label.HIDE_BANNERS : Label.DISPLAY_BANNERS
  const clickHandler = () => {
    const action = logosVisible
      ? RoomCommandAction.HideBanners
      : RoomCommandAction.ShowBanners

    return dispatchRoomCommand({
      action,
      roomId,
    })
  }

  return (
    <button
      className='app-menu-item justify-start'
      onClick={clickHandler}
      data-test={TestId.RoomSettingsLobbyButtonBanner}
    >
      <div className='app-menu-item-label whitespace-nowrap'>{tr(label)}</div>
    </button>
  )
}

const AvoidInterruptionsItem: FC<RoomSettingsProps> = ({ roomId }) => {
  const dispatchRoomCommand = useDispatchRoomCommand()
  const enabled = useRoomSelector(hasMeetingMode, roomId)
  const label = enabled ? Label.ALLOW_INTERRUPTIONS : Label.AVOID_INTERRUPTIONS
  const clickHandler = () => {
    const action = enabled
      ? RoomCommandAction.DisableMeetingMode
      : RoomCommandAction.EnableMeetingMode

    return dispatchRoomCommand({
      action,
      roomId,
    })
  }

  return (
    <button
      className='app-menu-item justify-start'
      onClick={clickHandler}
      data-test={TestId.RoomSettingsLobbyButtonAvoidInterruptions}
    >
      <div className='app-menu-item-label whitespace-nowrap'>{tr(label)}</div>
    </button>
  )
}

type InviteLinkProps = {
  roomName: string
}

const InviteLinkItem: FC<InviteLinkProps> = ({ roomName = '' }) => {
  const clipboardHandler = (e: MouseEvent) => {
    e.preventDefault()
    toastSuccess(tr(Label.COPY_INVITE_LINK_SUCCESS))
  }

  return (
    <Clipboard
      data-clipboard-text={roomInvitationURL(roomName)}
      onClick={clipboardHandler}
    >
      <div
        className='app-menu-item justify-start w-full'
        data-test={TestId.RoomSettingsLobbyButtonInvite}
      >
        <div className='app-menu-item-label whitespace-nowrap'>
          {tr(Label.COPY_INVITE_LINK)}
        </div>
      </div>
    </Clipboard>
  )
}

const RemoveEveryoneItem: FC<RoomSettingsProps> = ({ roomId }) => {
  const dispatchRoomCommand = useDispatchRoomCommand()
  const clickHandler = () =>
    dispatchRoomCommand({ roomId, action: RoomCommandAction.KickAll })

  return (
    <ButtonWithConfirmation onClick={clickHandler} className='w-full'>
      <div
        className='app-menu-item justify-start'
        data-test={TestId.RoomSettingsLobbyButtonRemoveEveryone}
      >
        <div className='app-menu-item-label whitespace-nowrap text-sw-red'>
          {tr(Label.REMOVE_EVERYONE)}
        </div>
      </div>
    </ButtonWithConfirmation>
  )
}
