import { CantinaState } from '../../interfaces'
import { FloaterType } from '../../../constants'

export const getFloater = ({ floaters }: CantinaState, id: string) => {
  return floaters.byId[id]
}

export const getOpenFloatersCount = ({ floaters }: CantinaState) => {
  return Object.keys(floaters.byId).length
}

export const getParticipantFloaterIds = ({ floaters }: CantinaState) => {
  return floaters.idsByType[FloaterType.ParticipantOptions] || []
}

// These are currently undifferentiated in the list
// Especially if we have more participant-related floaters in the
// future, we may want to create a more unique id like we do in
// /components/Rooms/RoomsList
export const getOrderedFloaters = ({ floaters }: CantinaState) => {
  return floaters.idsByOrder || []
}
