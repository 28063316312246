export enum Scope {
  None = '-none-',
  ConferenceList = 'conference.list',
  ConferenceJoin = 'conference.join',
  ConferenceDeaf = 'conference.deaf',
  ConferenceDeafSelf = 'conference.deaf.self',
  ConferenceUndeaf = 'conference.undeaf',
  ConferenceUndeafSelf = 'conference.undeaf.self',
  ConferenceAudioMute = 'conference.mute',
  ConferenceAudioMuteSelf = 'conference.mute.self',
  ConferenceAudioUnmute = 'conference.unmute',
  ConferenceAudioUnmuteSelf = 'conference.unmute.self',
  ConferenceVideoMute = 'conference.vmute',
  ConferenceVideoUnmute = 'conference.unvmute',
  ConferenceToggleVideoMute = 'conference.toggleVmute',
  ConferenceVideoMuteSelf = 'conference.vmute.self',
  ConferenceVideoUnmuteSelf = 'conference.unvmute.self',
  ConferenceVideoShuffle = 'conference.video.shuffle',
  ConferenceAudioFlags = 'conference.audio_flags',
  ConferenceAudioFlagsSelf = 'conference.audio_flags.self',
  ConferenceKick = 'conference.kick',
  ConferenceListParticipants = 'conference.list_participants',
  ConferenceLock = 'conference.lock',
  ConferenceUnLock = 'conference.unlock',
  ConferenceShareScreen = 'conference.share_screen',
  ConferenceRaiseHand = 'conference.raisehand',
  ConferenceRaiseHandSelf = 'conference.raisehand.self',
  ConferenceLowerHand = 'conference.lowerhand',
  ConferenceLowerHandSelf = 'conference.lowerhand.self',
  ConferenceEnableClipeeze = 'conference.enable_clipeeze',
  ConferenceDisableClipeeze = 'conference.disable_clipeeze',
  ConferenceEnableMeetingMode = 'conference.enable_meeting_mode',
  ConferenceDisableMeetingMode = 'conference.disable_meeting_mode',
  ConferenceEnableSilentMode = 'conference.enable_silent_mode',
  ConferenceDisableSilentMode = 'conference.disable_silent_mode',
  ConferenceEnableBlindMode = 'conference.enable_blind_mode',
  ConferenceDisableBlindMode = 'conference.disable_blind_mode',
  ConferenceEnableSpeakerHighlight = 'conference.enable_speaker_highlight',
  ConferenceDisableSpeakerHighlight = 'conference.disable_speaker_highlight',
  ConferenceEnableHideVMutedMode = 'conference.enable_hide_vmuted',
  ConferenceDisableHideVMutedMode = 'conference.disable_hide_vmuted',
  ConferenceToggleBanners = 'conference.toggle_banners',
  ConferenceSetPin = 'conference.set_pin',
  ConferenceUnsetPin = 'conference.unset_pin',
  ConferenceAudienceControls = 'conference.audience_controls',
  ConferencePlaybackControls = 'conference.playback_controls', // play | pause | resume | seek | stop
  ConferenceCanvasControls = 'conference.canvas_controls', // layout | mode | quality
  ConferenceRecordingControls = 'conference.recording_controls', // start | stop
  ConferenceRaiseHandsControls = 'conference.raise_hands_controls', // allow | disallow | lowerAll
  ConferenceHandraiseOnscreenControls = 'conference.handraise_onscreen', // on | off
  ConferenceInviteByCall = 'conference.invite_by_call',
  ConferenceInviteBySms = 'conference.invite_by_sms',
  ConferenceKnock = 'conference.knock',
  ConferenceLayerControls = 'conference.layer_controls',
  ConferenceAudienceVolume = 'conference.audience_volume',
  ConferenceBottomDrawer = 'conference.bottom_drawer',
  ConferenceTransfer = 'conference.transfer',
  ConferenceZoneManage = 'conference.zone.manage', // Create / Edit / Accept Request to Join
  ConferenceZoneDestroy = 'conference.zone.destroy', // close sidebar fo all
  ConferenceZoneExtVol = 'conference.zone.extVol',
  ConferenceGroupsViewRolesAssigned = 'conference.view_roles_assigned',

  UiSettings = 'ui.settings',
  UiSettingsGlobal = 'ui.settings.global',
  UiSettingsUsers = 'ui.settings.users',
  UiSettingsUsersCreate = 'ui.settings.users.create',
  UiSettingsUsersUpdate = 'ui.settings.users.update',
  UiSettingsUsersDelete = 'ui.settings.users.delete',
  UiSettingsRooms = 'ui.settings.rooms',
  UiSettingsGroups = 'ui.settings.groups',
  UiSettingsGroupsCreate = 'ui.settings.groups.create',
  UiSettingsRecordings = 'ui.settings.recordings',
  UiSettingsClipeeze = 'ui.settings.clipeeze',
  UiDialer = 'ui.dialer',
}

export const ADMIN_SCOPES = Object.values(Scope)
export const LEGACY_SCOPE_ID = 'legacyScopeId'
export const HYBRID_SCOPE_ID = 'hybridScopeId'

export const MEMBER_SCOPES = [
  Scope.ConferenceList,
  Scope.ConferenceJoin,
  Scope.ConferenceDeafSelf,
  Scope.ConferenceUndeafSelf,
  Scope.ConferenceAudioMuteSelf,
  Scope.ConferenceAudioUnmuteSelf,
  Scope.ConferenceAudioFlagsSelf,
  Scope.ConferenceVideoMuteSelf,
  Scope.ConferenceVideoUnmuteSelf,
  Scope.ConferenceListParticipants,
  Scope.ConferenceShareScreen,
  Scope.ConferenceRaiseHandSelf,
  Scope.ConferenceLowerHandSelf,
  Scope.ConferenceKnock,
  Scope.ConferenceZoneManage,
  Scope.ConferenceZoneDestroy,
  Scope.ConferenceZoneExtVol,
  Scope.UiSettings,
  Scope.UiSettingsUsers,
  Scope.UiSettingsRooms,
  Scope.UiSettingsGroups,
  Scope.UiSettingsRecordings,
]

// Moderator scopes must contain only the scopes that can be added and removed from redux
export const MODERATOR_SCOPES = [
  Scope.ConferenceDeaf,
  Scope.ConferenceUndeaf,
  Scope.ConferenceAudioMute,
  Scope.ConferenceAudioUnmute,
  Scope.ConferenceVideoMute,
  Scope.ConferenceVideoUnmute,
  Scope.ConferenceToggleVideoMute,
  Scope.ConferenceAudioFlags,
  Scope.ConferenceKick,
  Scope.ConferenceLock,
  Scope.ConferenceUnLock,
  Scope.ConferenceRaiseHand,
  Scope.ConferenceLowerHand,
  Scope.ConferenceHandraiseOnscreenControls,
  Scope.ConferenceEnableClipeeze,
  Scope.ConferenceDisableClipeeze,
  Scope.ConferenceEnableMeetingMode,
  Scope.ConferenceDisableMeetingMode,
  Scope.ConferenceEnableSilentMode,
  Scope.ConferenceDisableSilentMode,
  Scope.ConferenceEnableBlindMode,
  Scope.ConferenceDisableBlindMode,
  Scope.ConferenceEnableSpeakerHighlight,
  Scope.ConferenceDisableSpeakerHighlight,
  Scope.ConferenceEnableHideVMutedMode,
  Scope.ConferenceDisableHideVMutedMode,
  Scope.ConferenceToggleBanners,
  Scope.ConferenceSetPin,
  Scope.ConferenceUnsetPin,
  Scope.ConferenceAudienceControls,
  Scope.ConferencePlaybackControls,
  Scope.ConferenceCanvasControls,
  Scope.ConferenceRecordingControls,
  Scope.ConferenceRaiseHandsControls,
  Scope.ConferenceVideoShuffle,
  Scope.ConferenceInviteByCall,
  Scope.ConferenceInviteBySms,
  Scope.ConferenceLayerControls,
  Scope.ConferenceAudienceVolume,
  Scope.ConferenceBottomDrawer,
  Scope.ConferenceTransfer,
  Scope.ConferenceZoneManage,
  Scope.ConferenceZoneDestroy,
  Scope.ConferenceZoneExtVol,
  Scope.ConferenceGroupsViewRolesAssigned,
]

export const GUEST_SCOPES = [
  Scope.ConferenceList,
  Scope.ConferenceJoin,
  Scope.ConferenceDeafSelf,
  Scope.ConferenceUndeafSelf,
  Scope.ConferenceAudioMuteSelf,
  Scope.ConferenceAudioUnmuteSelf,
  Scope.ConferenceAudioFlagsSelf,
  Scope.ConferenceVideoMuteSelf,
  Scope.ConferenceVideoUnmuteSelf,
  Scope.ConferenceListParticipants,
  Scope.ConferenceShareScreen,
  Scope.ConferenceRaiseHandSelf,
  Scope.ConferenceLowerHandSelf,
  Scope.ConferenceKnock,
]
