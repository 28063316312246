import { useState, useEffect, useRef } from 'react'

export function useThrottle<T>(value: T, delay = 300) {
  const [throttledValue, setThrottledValue] = useState<T>(value)
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const nextValue = useRef(null) as any
  const hasNextValue = useRef(0) as any

  useEffect(() => {
    if (!timeout.current) {
      setThrottledValue(value)
      const timeoutCallback = () => {
        if (hasNextValue.current) {
          hasNextValue.current = false
          setThrottledValue(nextValue.current)
          timeout.current = setTimeout(timeoutCallback, delay)
        } else {
          timeout.current = undefined
        }
      }
      timeout.current = setTimeout(timeoutCallback, delay)
    } else {
      nextValue.current = value
      hasNextValue.current = true
    }
  }, [value, delay])

  useEffect(() => {
    return () => timeout.current && clearTimeout(timeout.current)
  }, [])

  return throttledValue
}
