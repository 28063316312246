import React, { FC } from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { tr, Label } from 'src/common/i18n'
import { SlidersIcon } from 'src/common/icons'
import { withConferenceBottomDrawerScope } from 'src/common/hoc'
import { callViewActions } from 'src/common/redux/views'
import { isCallSettingsShown } from 'src/common/redux/views/callView/callViewSelectors'
import { TestId } from 'src/constants'
import { TOOLTIP_OFFSET_Y_BOTTOM_BAR } from 'src/hoc/withTextTooltip'
import { Button, ButtonWithTooltip } from '../Button/Button'

type AdvancedControlsButtonProps = {
  className?: string
  clickHandler?: () => void
  disabled?: boolean
  showLabel?: boolean
}

const AdvancedControlsButton: FC<AdvancedControlsButtonProps> = ({
  className = '',
  clickHandler,
  disabled,
  showLabel,
}) => {
  const dispatch = useDispatch()
  const isShown = useSelector(isCallSettingsShown)
  const buttonClasses = isShown ? 'active' : ''
  const buttonClickHandler = () => {
    if (clickHandler) {
      clickHandler()
    }
    dispatch(callViewActions.toggleCallSettings())
  }

  return showLabel ? (
    <Button
      className={`sw-btn-icon-toggle flex gap-2 ${buttonClasses} ${className}`}
      disabled={disabled}
      onClick={buttonClickHandler}
      testId={TestId.BottomBarButtonMore}
    >
      <SlidersIcon size='lg' fixedWidth />
      <span className='more-menu-item-label'>
        {tr(Label.ADVANCED_CONTROLS)}
      </span>
    </Button>
  ) : (
    <ButtonWithTooltip
      ariaLabel={tr(Label.ADVANCED_CONTROLS)}
      className={`sw-btn-icon-toggle ${buttonClasses} ${className}`}
      disabled={disabled}
      offsetY={TOOLTIP_OFFSET_Y_BOTTOM_BAR}
      onClick={buttonClickHandler}
      testId={TestId.BottomBarButtonMore}
      titleSecondary={disabled ? '' : 'A'}
    >
      <SlidersIcon size='lg' fixedWidth />
    </ButtonWithTooltip>
  )
}

export const InCallAdvancedControlsButton: FC<AdvancedControlsButtonProps> =
  compose(withConferenceBottomDrawerScope)(AdvancedControlsButton)
