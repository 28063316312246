import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { RoomCommandPayload } from '../redux/interfaces'
import { roomCommand } from '../redux/features'

export const useDispatchRoomCommand = () => {
  const dispatch = useDispatch()
  const dispatchRoomCommand: (params: RoomCommandPayload) => void = useCallback(
    (params: RoomCommandPayload) => {
      dispatch(roomCommand(params))
    },
    [dispatch]
  )

  return dispatchRoomCommand
}
