import React, { FC, useEffect, useRef } from 'react'
import { isIOS } from 'react-device-detect'
import { useHistory } from 'react-router'
// @ts-ignore shaka player does not have types
import shaka from 'shaka-player'
import { tr, Label } from 'src/common/i18n'
import { RoutePath } from 'src/common/constants'
import { withAuth } from 'src/common/hoc'
import { useDecodedParams } from 'src/effects/useDecodedParams'
import SimpleHeader from 'src/components/Header/SimpleHeader'
import { SecondaryButton } from 'src/components/Button/Button'
import { toastError } from 'src/services/ToastService'

const LeaveButton: FC = withAuth(() => {
  const history = useHistory()
  return (
    <SecondaryButton onClick={() => history.push(RoutePath.Home)}>
      {tr(Label.LEAVE_BROADCAST)}
    </SecondaryButton>
  )
})

type ParamsProps = {
  broadcastId: string
  slugName: string
}

export const StreamingPage: FC = () => {
  const { broadcastId, slugName } = useDecodedParams<ParamsProps>()
  const ref = useRef<HTMLVideoElement>(null)
  const hostname = window.location.hostname
  const slug = slugName ?? hostname.split('.')[0]
  const poster = `https://pimg.sw.work/promo?fqdn=${hostname}&room=${slug}`
  const manifestMedia = isIOS
    ? `https://${hostname}:5443/hls/${broadcastId}/index.m3u8`
    : `https://${hostname}:5443/dash/${broadcastId}/index.mpd`

  useEffect(() => {
    const video = ref.current
    const player = new shaka.Player(video)

    if (video) {
      player.addEventListener('error', (error: any) =>
        console.log('Error:', error)
      )
      player
        .load(manifestMedia)
        .then(function () {
          console.log('The video is loaded!')
        })
        .catch((error: any) => {
          toastError(tr(Label.BROADCAST_ERROR), { autoClose: false })
          console.error('Video failed to play. Error code:', error.code)
          console.error('Full Error:', error)
        })
    }

    return () => {
      player.destroy()
    }
  }, [manifestMedia])

  return (
    <>
      <SimpleHeader />
      <div className='call-mcu-area relative flex items-center overflow-hidden'>
        <div className='aspect-ratio-16-9-wrapper '>
          <div className='aspect-ratio-16-9-content'>
            <video
              autoPlay
              className='absolute w-full h-full'
              controls
              playsInline
              poster={poster}
              ref={ref}
            />
          </div>
        </div>
      </div>
      <div className='in-call-bottom-bar'>
        <LeaveButton />
      </div>
    </>
  )
}
