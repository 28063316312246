import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { getActiveCallId } from '../redux/features/calls/callSelectors'

interface WithActiveCallProps {
  callId: string
}

export const withActiveCall = <T extends {}>(
  WrappedComponent: ComponentType<T & WithActiveCallProps>,
  FallbackComponent: ComponentType<T> | null = null
) => {
  return (props: Omit<T, 'callId'>) => {
    const callId = useSelector(getActiveCallId)

    if (callId) {
      return <WrappedComponent callId={callId} {...props as T} />
    }
    return FallbackComponent && <FallbackComponent {...props as T} />
  }
}
