import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CantinaState } from 'src/common/redux/interfaces'
import {
  getActiveCallId,
  getMyParticipantId,
} from 'src/common/redux/features/calls/callSelectors'
import HorizontalFeatureToggle from 'src/components/Inputs/HorizontalFeatureToggle'
import { callDTMF } from 'src/common/redux/features'
import { hasAutoGainControl } from 'src/common/redux/features/participants/participantSelectors'
import { TestId } from 'src/constants'

export const AutoGainControlToggle = () => {
  const dispatch = useDispatch()
  const callId = useSelector(getActiveCallId)
  const participantId = useSelector(getMyParticipantId)
  const active = useSelector((state: CantinaState) =>
    hasAutoGainControl(state, participantId)
  )

  /**
   * #74 AGC On
   * #75 AGC Off
   */
  const toggleOn = () => dispatch(callDTMF(callId, '#74'))
  const toggleOff = () => dispatch(callDTMF(callId, '#75'))

  return (
    <HorizontalFeatureToggle
      onToggleOn={toggleOn}
      onToggleOff={toggleOff}
      label='Auto Gain Control'
      checked={active}
      className='text-sm p-2 pl-6'
      testId={TestId.AudioToggleAutoGain}
    />
  )
}
