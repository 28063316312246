import React, { FC } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { RoomType } from 'src/common/constants'
import {
  getFilteredRoomNavigatorRooms,
  getRoomNavigatorRooms,
  getRoomNavigatorFilter,
} from 'src/common/redux/views/callView/callViewSelectors'
import { TestId } from 'src/constants'
import { CurrentRoomCard } from './CurrentRoomCard'
import { LiveRoomCard } from './LiveRoomCard'
import { RoomFilterResults } from './RoomFilterResults'

export const RoomNavigatorList: FC = () => {
  const unfilteredRooms: string[] = useSelector(
    getRoomNavigatorRooms,
    shallowEqual
  )
  const filteredRooms: string[] = useSelector(
    getFilteredRoomNavigatorRooms,
    shallowEqual
  )
  const isFiltered = Boolean(useSelector(getRoomNavigatorFilter))
  const roomList = isFiltered ? filteredRooms : unfilteredRooms

  return (
    <div className='divide-y divide-gray-500'>
      <RoomFilterResults />
      {roomList.map((roomIdAndType, index) => {
        const [roomId, type] = roomIdAndType.split('|')
        switch (type) {
          case RoomType.Current:
            return (
              <CurrentRoomCard
                key={roomId}
                roomId={roomId}
                testId={`${TestId.RoomNavigatorRoom}_${index}`}
              />
            )
          case RoomType.Live:
            return (
              <LiveRoomCard
                key={roomId}
                roomId={roomId}
                testId={`${TestId.RoomNavigatorRoom}_${index}`}
              />
            )
          default:
            return null
        }
      })}
    </div>
  )
}
