import React, { FC, ChangeEvent, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDebounce, useFetchGroup } from 'src/common/hooks'
import {
  TimesCircleIcon,
  SpinnerIcon,
  ChevronRightIcon,
} from 'src/common/icons'
import { GroupRole, RoutePath } from 'src/common/constants'
import { tr, Label } from 'src/common/i18n'
import { sortByNameProperty } from 'src/common/services/helpers'
import { SEARCH_DEBOUNCE_DELAY } from 'src/constants'
import { ButtonWithConfirmation } from 'src/components/Button/Button'
import { SearchInput } from 'src/components/Inputs/SearchInput'
import { SelectGroup } from 'src/components/SelectGroup/SelectGroup'
import { toastError, toastSuccess } from 'src/services/ToastService'
import { useDecodedParams } from 'src/effects/useDecodedParams'
import { RequestStateSwitcher } from './RequestStateSwitcher'
import { AddUserToGroupButton } from './AddUserToGroupButton'
import { RolesDropdown } from './RolesDropdown'
import { UserData } from './UserData'

const SHOW_ALL = 'all'
const showOptions = [
  { id: SHOW_ALL, label: 'All' },
  { id: GroupRole.Member, label: 'Members' },
  { id: GroupRole.Maintainer, label: 'Maintainers' },
]

export const Group: FC = () => {
  const { groupId } = useDecodedParams<{ groupId: string }>()
  const {
    group,
    requestState,
    refresh,
    error,
    getMemberships,
    updateUserGroupRole,
    removeUserFromGroup,
  } = useFetchGroup(groupId)
  // console.log(group, requestState, refresh, error)
  const [filter, setFilter] = useState('')
  const [filterByGroup, setFilterByGroup] = useState(SHOW_ALL)
  const delayedFilter = useDebounce(
    filter,
    SEARCH_DEBOUNCE_DELAY
  )?.toLowerCase()
  const memberships = getMemberships({
    filterByText: delayedFilter,
    filterByGroupRole: filterByGroup,
  })

  memberships.sort(sortByNameProperty)

  const isMaintainer = group?.my_role === GroupRole.Maintainer
  const memberCount = memberships.filter(
    (member) => member.group_role === GroupRole.Member
  ).length
  const maintainerCount = memberships.filter(
    (member) => member.group_role === GroupRole.Maintainer
  ).length

  return (
    <>
      <div className='administration-section-title'>
        <div className='text-contrast-h text-xl w-3/4 truncate'>
          <Link
            to={RoutePath.SettingsGroupList}
            title={tr(Label.GROUPS)}
            className='pr-2'
          >
            {tr(Label.GROUPS)}
          </Link>
          <ChevronRightIcon size='sm' />{' '}
          <span className='font-bold'>{group?.name}</span>
        </div>
        {isMaintainer && <AddUserToGroupButton refresh={refresh} />}
      </div>
      <div className='block-primary administration-section-content'>
        <RequestStateSwitcher
          requestState={requestState}
          refresh={refresh}
          error={error}
        >
          <div className='flex items-center mb-4'>
            <SearchInput
              className='text-sm mr-6'
              clearInputFunction={() => setFilter('')}
              currentValue={filter}
              inputChanged={(event: ChangeEvent<HTMLInputElement>) => {
                setFilter(event.target.value)
              }}
              name='groupMembersFilter'
              placeholder='Search'
            />
            <SelectGroup
              canHaveError={false}
              className='flex-none mr-4'
              horizontalLabel
              label='Show'
              name='auto-shuffle'
              onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                setFilterByGroup(event.target.value)
              }
              options={showOptions}
              selectedId={filterByGroup}
            />
            <p className='flex-1 text-sm text-right'>{`${memberCount}\u00A0${tr(
              Label.MEMBER,
              { count: memberCount }
            )}\u00A0- ${maintainerCount}\u00A0${tr(Label.MAINTAINER, {
              count: maintainerCount,
            })}`}</p>
          </div>
          {!memberships?.length && (
            <p className='text-base py-2'>{tr(Label.GROUP_NO_MEMBERSHIPS)}</p>
          )}
          {memberships.map((membership) => {
            return (
              <UserItem
                key={membership.cantina_user_id}
                userId={membership.cantina_user_id}
              >
                {isMaintainer ? (
                  <Membership
                    membershipId={membership.id}
                    group_role={membership.group_role}
                    updateUserGroupRole={updateUserGroupRole}
                    removeUserFromGroup={removeUserFromGroup}
                    refresh={refresh}
                  />
                ) : (
                  <p className='w-32 text-center capitalize'>
                    {membership.group_role}
                  </p>
                )}
              </UserItem>
            )
          })}
        </RequestStateSwitcher>
      </div>
    </>
  )
}

type UserItemProps = {
  userId: string
}

const UserItem: FC<UserItemProps> = ({ userId, children }) => {
  return (
    <div tabIndex={0} className='administration-section-item-title group'>
      <UserData userId={userId} />
      <div className='flex items-center'>{children}</div>
    </div>
  )
}

type MembershipProps = {
  membershipId: string
  group_role: GroupRole
  updateUserGroupRole: (params: {
    membershipId: string
    groupRole: GroupRole
  }) => Promise<void>
  removeUserFromGroup: (membershipId: string) => Promise<void>
  refresh: () => Promise<void>
}
const Membership: FC<MembershipProps> = ({
  membershipId,
  group_role,
  updateUserGroupRole,
  removeUserFromGroup,
  refresh,
}) => {
  const [loading, setLoading] = useState(false)

  const removeHandler = async () => {
    try {
      await removeUserFromGroup(membershipId)
      await refresh()
      toastSuccess(tr(Label.REMOVE_USER_FROM_GROUP_SUCCESS))
    } catch (error) {
      toastError(tr(Label.REMOVE_USER_FROM_GROUP_FAILURE))
    }
  }

  useEffect(() => {
    setLoading(false)
  }, [group_role])

  return (
    <div className='flex items-center justify-between'>
      {loading ? (
        <SpinnerIcon
          size='2x'
          fixedWidth
          className='block mx-auto text-contrast-h'
        />
      ) : (
        <>
          <div className='item-actions justify-center gap-3 mr-4'>
            <RemoveUserButton clickHandler={removeHandler} />
          </div>
          <RolesDropdown
            backendValue={group_role}
            changeHandler={(event: ChangeEvent<HTMLInputElement>) => {
              setLoading(true)
              updateUserGroupRole({
                membershipId,
                groupRole: event.target.value as GroupRole,
              })
            }}
            roleType='group_role'
          />
        </>
      )}
    </div>
  )
}

type UserTooltipButtonProps = {
  clickHandler?: () => void
}

const RemoveUserButton: FC<UserTooltipButtonProps> = ({ clickHandler }) => {
  return (
    <ButtonWithConfirmation
      ariaLabel={tr(Label.REMOVE)}
      className='text-sw-red'
      onClick={clickHandler}
      singleUse
    >
      <TimesCircleIcon />
    </ButtonWithConfirmation>
  )
}
