import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { withAuth } from 'src/common/hoc'
import { getAutoDialExtension } from 'src/common/redux/features/ui/uiSelectors'
import { useHistoryPushToRoom } from 'src/effects/useHistoryPushToRoom'

/**
 * This component is used to auto-join a room for users with a valid login.
 *
 * For logged in users the useEffect will check to see if the autoDialExtension
 * has been set and redirect to that room.
 * Otherwise the Room route will set the autoDialExtension flag and
 * redirect the user to login. After logging in, this component will work same way
 * for logged in users.
 */
export const AutoDialExtensionGuard: FC = withAuth(() => {
  const { pushHandler } = useHistoryPushToRoom()
  const extension = useSelector(getAutoDialExtension)

  useEffect(() => {
    if (extension) {
      pushHandler({ roomName: extension })
    }
  }, [pushHandler, extension])

  return null
})
