import { useEffect, useState, useRef } from 'react'
import { LabelIconState } from '../constants'

export function useLabelIconState(defaultValue: any) {
  const isFirstRun = useRef(true)
  const [labelIconState, setLabelIconState] = useState(LabelIconState.None)

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (!isFirstRun.current) {
      setLabelIconState(LabelIconState.Loading)
      timer = setTimeout(() => setLabelIconState(LabelIconState.Success), 1500)
      timer = setTimeout(() => setLabelIconState(LabelIconState.None), 4000)
    }

    isFirstRun.current = false
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  return labelIconState
}
