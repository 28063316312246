import React from 'react'
import { StopPlaybackButton } from '../CallSettings/StopPlaybackButton'

export const StopFullScreenPlaybackLayer = ({ roomId }: { roomId: string }) => {
  return (
    <div className='absolute user-menu w-full h-full inset-0'>
      <div className='user-menu-items flex items-center'>
        <StopPlaybackButton roomId={roomId} />
      </div>
    </div>
  )
}
