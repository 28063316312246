/* eslint-disable no-constant-condition */
import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import { useSelector } from 'react-redux'
import { MediaDevice } from 'src/common/constants'
import { CameraOffIcon, SpinnerIcon } from 'src/common/icons'
import { useCameraPreview, useMountedRef } from 'src/common/hooks'
import { tr, Label } from 'src/common/i18n'
import { getCameraId } from 'src/common/redux/features/device/deviceSelectors'

const videoAspectClasses = 'aspect-video'

export const CurrentVideoPreview = () => {
  const [previewCameraId] = useCameraPreview()
  const currentCameraId = useSelector(getCameraId)
  const id = previewCameraId || currentCameraId

  return <VideoPreview cameraId={id} />
}

export const VideoPreview = ({ cameraId }: { cameraId: string }) => {
  if (cameraId === MediaDevice.None) {
    return (
      <div className='bg-contrast-l' aria-label={tr(Label.CAMERA_DISABLED)}>
        <div
          className={`flex items-center justify-center ${videoAspectClasses}`}
        >
          <CameraOffIcon size='5x' fixedWidth className='text-sw-red' />
        </div>
      </div>
    )
  }

  return <CameraFeed cameraId={cameraId} />
}

type props = {
  cameraId: string
  className?: string
  setDeviceError?: Dispatch<SetStateAction<boolean>>
}

export const CameraFeed = ({
  cameraId,
  className = '',
  setDeviceError,
}: props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const streamRef = useRef<MediaStream>()
  const mountedRef = useMountedRef()
  const videoRef = useRef<HTMLVideoElement>(null)

  const cleanup = useCallback(() => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((t: MediaStreamTrack) => t.stop())
    }
  }, [])

  useEffect(() => {
    setLoading(true)

    const constraints: MediaStreamConstraints = {
      video: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        aspectRatio: 16 / 9,
        deviceId: cameraId,
      },
    }
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        streamRef.current = stream
        if (videoRef.current) {
          videoRef.current.srcObject = stream
        }
      })
      .catch((error) => {
        console.error('VideoPreview', error)
        setError(true)
        setLoading(false)
        setDeviceError && setDeviceError(true)
      })
      .finally(() => {
        if (!mountedRef.current) {
          cleanup()
        }
      })

    // FF never reaches "canPlay" but it also never throws an error
    // so if it doesn't load in 5 seconds, just show an error
    const timer = setTimeout(() => {
      if (videoRef?.current?.readyState === 0) {
        setLoading(false)
        setError(true)
      }
    }, 5000)

    return () => {
      clearTimeout(timer)
      setLoading(false)
      setError(false)
      cleanup()
    }
  }, [cameraId, cleanup, mountedRef, setDeviceError])

  const onCanPlay = () => {
    setLoading(false)
  }

  const videoClassName = loading || error ? 'hidden' : ''

  return (
    <div
      className={`w-full overflow-hidden bg-black ${videoAspectClasses} ${className}`}
    >
      {loading && (
        <div className='h-full flex items-center justify-center'>
          <SpinnerIcon size='3x' fixedWidth className='text-gray-600' />
        </div>
      )}
      {error ? (
        <div className='h-full flex flex-col text-center items-center justify-center text-white leading-tight'>
          <div>{tr(Label.STREAM_ERROR)}</div>
          <div>{tr(Label.CHECK_YOUR_DEVICE)}</div>
        </div>
      ) : (
        <video
          ref={videoRef}
          onCanPlay={onCanPlay}
          muted
          autoPlay
          playsInline
          className={`max-w-full max-h-full ${videoClassName}`}
        ></video>
      )}
    </div>
  )
}
