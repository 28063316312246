import {
  persistReducer,
  createTransform,
  persistStore,
  createMigrate,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { configureStore } from 'src/common/redux'
import {
  AuthState,
  CallViewState,
  CantinaState,
  UiState,
} from 'src/common/redux/interfaces'
import { settingsActions, refreshJWTRequest } from 'src/common/redux/features'
import { setAppMixPanel } from 'src/common/redux/mixPanel'
import * as MixPanelService from './MixPanelService'
import { isDev } from 'src/common/services/helpers'
import { CURRENT_HOSTNAME } from 'src/common/constants'

const uiTransform = createTransform(
  // Transform it before save
  (state: UiState): UiState => ({ ...state, isVisible: {}, socketStatus: '' }),
  // Don't transform it before rehydration
  null,
  // Transform only the UiState
  { whitelist: ['ui'] }
)

const authTransform = createTransform(
  // Transform it before save
  (state: AuthState): AuthState => ({ ...state, token: '' }),
  // Don't transform it before rehydration
  null,
  // Transform only the AuthState
  { whitelist: ['auth'] }
)

const callViewTransform = createTransform(
  // Transform it before save
  (state: CallViewState): CallViewState => ({
    ...state,
    pictureInPicture: false,
  }),
  // Don't transform it before rehydration
  null,
  // Transform only the CallViewState
  { whitelist: ['callView'] }
)

const migrations = {
  /* eslint-disable-next-line */
  0: (state: CantinaState): CantinaState => {
    return {
      ...state,
      callView: {
        ...state.callView,
        participantListGroups: {},
      },
    }
  },
  /* eslint-disable-next-line */
  1: (state: CantinaState): CantinaState => {
    /**
     * The @ts-ignore lines are required because
     * 'skipPrejoinPage' and 'flipLocalVideo' were
     * moved from settingsSlice to UiSlice
     */
    return {
      ...state,
      ui: {
        ...state.ui,
        // @ts-ignore
        skipPrejoinPage: Boolean(state?.settings?.skipPrejoinPage),
        // @ts-ignore
        flipLocalVideo: Boolean(state?.settings?.flipLocalVideo),
      },
    }
  },
  /* eslint-disable-next-line */
  2: (state: CantinaState): CantinaState => {
    /**
     * Adding prejoinPageViewed: true to uiSlice.
     * We used true since these client already uses the App
     * so we don't want to force the preJoin page for them.
     */
    return {
      ...state,
      ui: {
        ...state.ui,
        prejoinPageViewed: true,
      },
    }
  },
}

const REDUX_PERSIST_VERSION = Math.max(...Object.keys(migrations).map(Number))

export const persistConfig = {
  persistReducer,
  config: {
    key: 'root',
    storage,
    whitelist: ['auth', 'ui', 'device', 'settings', 'lobbyView', 'callView'],
    transforms: [uiTransform, authTransform, callViewTransform],
    version: REDUX_PERSIST_VERSION,
    // @ts-ignore
    migrate: createMigrate(migrations, { debug: isDev() }),
  },
}

export const reduxInit = () => {
  // Set mixPanel to identify user and track events
  setAppMixPanel(MixPanelService)
  const store = configureStore({}, { persistConfig })
  const persistor = persistStore(store, {}, () => {
    window.addEventListener('offline', () => {
      console.debug('App offline')
    })
    window.addEventListener('online', () => {
      store.dispatch(refreshJWTRequest())
    })
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        store.dispatch(refreshJWTRequest())
      } else {
        // Handle when is not visible (?)
      }
    })
  })

  store.dispatch(
    settingsActions.bootstrapRequest({ hostname: CURRENT_HOSTNAME })
  )

  return { store, persistor }
}
