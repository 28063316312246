import React, { Component, ComponentType } from 'react'
import { PrimaryButton } from './components/Button/Button'
import { tr, Label } from './common/i18n'

export const LoadingFailure = () => {
  return (
    <div className='text-center my-4'>
      <p className='mb-2'>{tr(Label.LOADING_FAILURE_MSG)}</p>
      <PrimaryButton onClick={() => window.location.reload()}>
        {tr(Label.REFRESH)}
      </PrimaryButton>
    </div>
  )
}

export const LoadingMsg = () => {
  return <div className='text-center my-4'>{tr(Label.LOADING)}...</div>
}

type ErrorBoundaryProps = {
  fallback: ComponentType<any>
}

type ErrorBoundaryState = {
  hasError: boolean
  error: any
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = { hasError: false, error: null }

  static getDerivedStateFromError(error: any) {
    return {
      hasError: true,
      error,
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback
    }
    return this.props.children
  }
}
