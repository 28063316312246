import React, { FC, ComponentType } from 'react'
import { tr, Label } from 'src/common/i18n'
import { getParticipantTestIdSuffix } from 'src/services/Helpers'
import { TestId } from 'src/constants'
import { ParticipantRow } from './ParticipantList'

interface ParticipantGroupProps {
  className?: string
  heading: string
  Icon: ComponentType<{ className?: string }>
  memberIds?: string[]
  groupParticipantTestId?: string
  testId: TestId
}

export const ParticipantGroup: FC<ParticipantGroupProps> = ({
  className,
  heading,
  Icon,
  memberIds,
  groupParticipantTestId,
  testId,
}) => {
  return (
    <div
      className={`${className} border rounded-md block-primary overflow-auto`}
      data-test={testId}
    >
      <div className='block-highlight p-2 sticky top-0 shadow-level-1 z-20 truncate'>
        {Icon && <Icon className='mr-2' />}
        <span data-test={TestId.ParticipantsGroupName}>{heading}</span>
      </div>
      <div className='mb-2 h-30'>
        {memberIds?.length ? (
          memberIds.map((id, rowIndex) => (
            <ParticipantRow
              className='px-2'
              key={id}
              participantId={id}
              testIdSuffix={getParticipantTestIdSuffix({
                groupParticipantTestId,
                rowIndex,
              })}
            />
          ))
        ) : (
          <div className='text-contrast-m text-sm p-2'>
            {tr(Label.NO_PARTICIPANTS_GROUP)}
          </div>
        )}
      </div>
    </div>
  )
}
