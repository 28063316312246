import { useState, useCallback } from 'react'
import { Clipeeze } from '../redux/interfaces'
import { RailsEndpoint, RequestState } from '../rest'
import { useInternalAPI } from './useInternalAPI'

interface useFetchClipeezeData {
  clipeeze: Clipeeze | null
  requestState: RequestState
  error: string | null
}

const DEFAULT_DATA: useFetchClipeezeData = {
  clipeeze: null,
  requestState: RequestState.Loading,
  error: null,
}

const fetchOptions = {
  endpoint: RailsEndpoint.Clipeeze,
  method: 'GET',
}

export const useFetchClipeeze = (id: string) => {
  const { invokeAPI: fetchHandler } = useInternalAPI(fetchOptions)
  const [data, setData] = useState<useFetchClipeezeData>(DEFAULT_DATA)

  const refresh = useCallback(async () => {
    try {
      setData(DEFAULT_DATA)
      const data = await fetchHandler({
        urlTransform: (url) => `${url}/${id}`,
      })
      setData({
        clipeeze: data,
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      setData({
        ...DEFAULT_DATA,
        requestState: RequestState.Error,
        error: 'Error fetching ClipEEze',
      })
    }
  }, [])

  return { ...data, refresh }
}
