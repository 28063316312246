import React, { ChangeEvent, FC } from 'react'
import { tr, Label } from 'src/common/i18n'

type RadioButtonsOptionsProps = {
  id: string
  label: Label
  description?: Label
}

type RadioButtonsProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  options?: RadioButtonsOptionsProps[]
  name: string
  selectedId?: string
  gridView?: boolean
}

export const RadioButtons: FC<RadioButtonsProps> = ({
  name,
  onChange,
  selectedId,
  options = [],
  gridView = false,
}) => {
  const className = gridView ? 'grid grid-cols-2' : ''

  return (
    <div className={className} onChange={onChange}>
      {options.map(({ id, label, description }) => {
        const checked = selectedId === id
        const htmlId = `${name}-${id}`
        return (
          <div key={id} className='relative pb-2 flex flex-col'>
            <div className='flex items-center'>
              <input
                id={htmlId}
                name={name}
                type='radio'
                defaultChecked={checked}
                value={id}
              />
              <label htmlFor={htmlId} className='ml-3 cursor-pointer'>
                {tr(label)}
              </label>
            </div>
            {description && (
              <div className='text-sm text-contrast-m ml-7'>
                {tr(description)}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
