import React, { FC } from 'react'

// ButtonGroup is an HOC wrapper for displaying buttons on one line, wrapping if necessary
// At mobile screen widths, buttons will display full-width and stack in reverse order

type ButtonGroupProps = {
  className?: string
}

export const ButtonGroup: FC<ButtonGroupProps> = ({
  children,
  className = '',
}) => {
  if (!children) {
    return null
  }

  const buttons: React.ReactNode[] = []
  let keyIndex = 0

  React.Children.forEach(children, (child: React.ReactNode) => {
    // Account for showElement && <Element /> situations that might return a boolean
    if (child) {
      // Convert child to ReactElement to access props
      const childNode = child as React.ReactElement
      const childClassName = childNode?.props?.className || ''

      // Gap not working on iOS, so use bottom margin on buttons at mobile widths
      const childProps = {
        key: `buttonList_${keyIndex++}`,
        ...childNode?.props,
        className: `w-full sm:flex-1 sm:w-max sm:max-w-xs sm:whitespace-nowrap ${childClassName}`,
      }

      buttons.push(React.cloneElement(childNode, childProps))
    }
  })

  return (
    <>
      {buttons.length ? (
        <div
          className={`flex flex-col-reverse sm:flex-row sm:flex-wrap justify-center items-start gap-x-4 gap-y-2 overflow-hidden ${className}`}
        >
          {buttons}
        </div>
      ) : null}
    </>
  )
}
