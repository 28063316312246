import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'
import { InviteViewState } from '../../interfaces'
import {
  roomJoinedAction,
  roomLeftAction,
} from '../../features/rooms/roomsSlice'
import { callActions } from '../../features'

export const initialInviteViewState: InviteViewState = Object.freeze({
  room: '',
  isVisible: false,
})

const clearHandler = (state: InviteViewState) => {
  state.isVisible = false
  state.room = ''
}

const inviteViewSlice = createSlice({
  name: 'inviteView',
  initialState: initialInviteViewState,
  reducers: {
    hide: clearHandler,
    show: (state, { payload }: PayloadAction<{ room: string }>) => {
      state.isVisible = true
      state.room = payload.room
    },
  },
  extraReducers: (builder) => {
    const shouldClear = isAnyOf(
      roomJoinedAction,
      roomLeftAction,
      callActions.destroy
    )
    builder.addMatcher(shouldClear, clearHandler)
  },
})

// prettier-ignore
export const {
  actions: inviteViewActions,
  reducer: inviteViewReducer,
} = inviteViewSlice
