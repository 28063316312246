import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { CantinaState } from 'src/common/redux/interfaces'
import { useDispatchRoomParticipantCommand } from 'src/common/hooks'
import {
  motionQualityToSliderValue,
  motionQualityToFSValue,
} from 'src/common/services/helpers'
import {
  getParticipantMotionQuality,
  isDeaf,
} from 'src/common/redux/features/participants/participantSelectors'
import { RoomCommandAction } from 'src/common/constants'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import RangeSlider from 'src/components/Inputs/RangeSlider'

interface VideoQualityControlProps {
  className?: string
  horizontalLabel?: boolean
  LowerIcon?: any
  participantId: string
  UpperIcon?: any
}

export const VideoQualityControl: FC<VideoQualityControlProps> = ({
  participantId,
  ...rest
}) => {
  const dispatchRoomParticipantCommand =
    useDispatchRoomParticipantCommand(participantId)
  const quality = useSelector((state: CantinaState) =>
    getParticipantMotionQuality(state, participantId)
  )
  const dndEnabled = useSelector((state: CantinaState) =>
    isDeaf(state, participantId)
  )
  const onChange = useCallback(
    (value: number) => {
      dispatchRoomParticipantCommand({
        action: RoomCommandAction.SetMotionQualityMember,
        participantId,
        value: motionQualityToFSValue(value),
      })
    },
    [dispatchRoomParticipantCommand, participantId]
  )

  return (
    <RangeSlider
      currentValue={motionQualityToSliderValue(quality || 0)}
      disabled={dndEnabled}
      label={tr(Label.VIDEO_QUALITY)}
      maxValue={16}
      minValue={0}
      onValueChange={onChange}
      testId={TestId.SoundRangeMotionQuality}
      {...rest}
    />
  )
}
