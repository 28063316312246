import { useRef, useEffect } from 'react'

export const usePrevious = (value: any, defaultValue: any = {}) => {
  const previousRef = useRef(defaultValue)

  useEffect(() => {
    previousRef.current = value
  }, [value])

  return previousRef.current
}
