import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Group as ApiGroup } from '../../../rest'
import { GroupState } from '../../interfaces'
import { GroupRole } from '../../../constants'

export const initialGroupState: GroupState = Object.freeze({
  byId: {},
  membershipById: {},
})

const groupsSlice = createSlice({
  name: 'groups',
  initialState: initialGroupState,
  reducers: {
    fetchGroupsSuccess: (
      state,
      { payload: groupList }: PayloadAction<ApiGroup[]>
    ) => {
      groupList.forEach((row) => {
        state.byId[row.id] = {
          id: row.id,
          name: row.name,
          parentGroupId: row.cantina_group_id,
          system: Boolean(row.system),
          subgroupsCount: row.subgroups_count,
          membershipsCount: row.memberships_count,
          myRole: row.my_role || GroupRole.Member,
        }
      })
    },
    clear: () => initialGroupState,
  },
})

// prettier-ignore
export const {
  actions: groupsActions,
  reducer: groupsReducer
} = groupsSlice
