import { getActiveChat } from '../chats/chatSelectors'
import { CantinaState } from '../../interfaces'

export const getMessage = ({ messages }: CantinaState, messageId: string) => {
  return messages.byId[messageId] || null
}

export const getMessageIdsByChatId = (
  { messages }: CantinaState,
  chatId: string
) => {
  return messages.idsByChatId[chatId] || []
}

export const getNewMessageCount = (state: CantinaState) => {
  const chat = getActiveChat(state)
  if (!chat) {
    return 0
  }
  const messages = getMessageIdsByChatId(state, chat.id)
  return messages.length > 0
    ? messages.length - messages.indexOf(chat.lastReadId) - 1
    : 0
}

export const getLatestUnreadMessage = (state: CantinaState) => {
  const chat = getActiveChat(state)
  if (!chat) {
    return null
  }

  const messageIds = getMessageIdsByChatId(state, chat.id)
  const latestMessageIndex = messageIds.length - 1

  if (latestMessageIndex > messageIds.indexOf(chat.lastReadId)) {
    return getMessage(state, messageIds[latestMessageIndex])
  }

  return null
}
