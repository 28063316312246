import { createSelector } from 'reselect'
import { CantinaState } from '../../interfaces'
import { RoomVisibility } from '../../../constants'
import { getRoomIds, getRooms } from '../../features/roomList/roomListSelectors'
import {
  sortByNameProperty,
  sortByOrderPriorityProperty,
  sortFilteredRooms,
  cleanFilter,
} from '../../../services/helpers'

export const getRoomFilter = ({ lobbyView }: CantinaState) =>
  lobbyView.roomFilter?.trim() || ''

export const getCurrentRoomId = ({ lobbyView }: CantinaState) =>
  lobbyView.currentRoomId

export const hasExactRoomNameMatch = createSelector(
  getRooms,
  getRoomFilter,
  (rooms, filter) => {
    const cleanedFilter = cleanFilter(filter)
    return (
      rooms &&
      Object.keys(rooms)
        .map((id) => rooms?.[id]?.name?.toLowerCase())
        .includes(cleanedFilter)
    )
  }
)

export const getRoomIdsFiltered = createSelector(
  getRooms,
  getRoomFilter,
  (rooms, filter) => {
    const roomIds = Object.keys(rooms)
    if (!filter) {
      return roomIds
    }

    const cleanedFilter = cleanFilter(filter)
    return roomIds.filter((id) =>
      rooms?.[id]?.name?.toLowerCase()?.includes(cleanedFilter)
    )
  }
)

export const hasVisibleRooms = createSelector(getRoomIds, (roomIds) => {
  return Boolean(roomIds.length)
})

const makeRoomIdsSelector = (allowedVisibilityList: RoomVisibility[]) => {
  return createSelector(
    getRooms,
    getRoomIdsFiltered,
    (rooms, roomIdsFiltered) => {
      return roomIdsFiltered
        .filter((roomId) => {
          const { visibility } = rooms[roomId] ?? {}
          return visibility && allowedVisibilityList.includes(visibility)
        })
        .sort((id1, id2) => {
          // Sort by running before and then orderPriority
          if (rooms[id1].running && rooms[id2].running) {
            return sortByNameProperty(rooms[id1], rooms[id2])
          }
          if (!rooms[id1].running && rooms[id2].running) {
            return 1
          } else if (rooms[id1].running && !rooms[id2].running) {
            return -1
          }
          const byOrder = sortByOrderPriorityProperty(rooms[id1], rooms[id2])
          if (byOrder !== 0) {
            return byOrder
          }

          // Sort by name as last
          return sortByNameProperty(rooms[id1], rooms[id2])
        })
    }
  )
}

export const getPinnedRoomIds = makeRoomIdsSelector([RoomVisibility.Pinned])

export const getUnpinnedRoomIds = makeRoomIdsSelector([RoomVisibility.Normal])

export const getFilteredSortedRoomIds = createSelector(
  getRooms,
  getRoomIdsFiltered,
  getRoomFilter,
  (rooms, roomIdsFiltered, roomFilter) => {
    // The room list is already reduced to rooms having names which include the filter string
    // Sort the remaining rooms by display name (CM filtered the visibility for us)
    return roomIdsFiltered.sort((id1, id2) => {
      return sortFilteredRooms(rooms[id1], rooms[id2], roomFilter)
    })
  }
)
