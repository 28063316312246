import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'
import { Loader } from '../constants'
import { MixPanelIdentifyPayload } from './interfaces'

export const purgeAction = createAction('cantina/purge')
export const flushAction = createAction('cantina/flush')
export const globalLoaderBeginAction = createAction(`${Loader.Global}Begin`)
export const globalLoaderEndAction = createAction(`${Loader.Global}End`)
export const mixPanelIdentifyAction =
  createAction<MixPanelIdentifyPayload>('mixpanel/identify')
export const mixPanelTrackAction: ActionCreatorWithPayload<{
  event: string
  params?: any
}> = createAction('mixpanel/track')
export const callLoaderBeginAction = createAction(`${Loader.Call}Begin`)
export const callLoaderEndAction = createAction(`${Loader.Call}End`)
