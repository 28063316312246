import React, { FC } from 'react'
import { Color } from 'src/common/constants'
import SimpleHeader from 'src/components/Header/SimpleHeader'
import SubmittableInput from 'src/components/Inputs/SubmittableInput'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { SelectGroup } from 'src/components/SelectGroup/SelectGroup'
import { TextAreaGroup } from 'src/components/TextAreaGroup/TextAreaGroup'
import HorizontalFeatureToggle from 'src/components/Inputs/HorizontalFeatureToggle'
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  TransparentButton,
} from 'src/components/Button/Button'
import ToggleButton from 'src/components/Inputs/ToggleButton'
import { useInputChange } from 'src/effects/useInputChange'
import { TestId, Size } from 'src/constants'
import { ToastCard } from 'src/services/ToastService'
import {
  InfoCircleIconSolid,
  TimesCircleIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from 'src/common/icons'

const OPTIONS = [
  { id: '1', label: 'Option 1' },
  { id: '2', label: 'Option 2' },
]
const DesignSystemPage: FC = () => {
  const [values, setValues, bulkUpdate] = useInputChange({
    primaryPlay: 'Test',
    primaryUrl: 'example.com',
    primarySel: '1',
    primaryText: '',
    primaryToggle: false,
    primaryToggleButton: false,
    secondaryPlay: '',
    secondaryUrl: '',
    secondarySel: '1',
    secondaryText: '',
    secondaryToggle: false,
    secondaryToggleButton: false,
    tertiaryPlay: '',
    tertiaryUrl: '',
    tertiarySel: '1',
    tertiaryText: '',
    tertiaryToggle: false,
    tertiaryToggleButton: false,
  })

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    console.log('Submit', values)
  }

  const themes = ['light', 'dark']
  const blocks = ['primary', 'secondary', 'tertiary']

  return (
    <>
      <SimpleHeader />
      <div className='w-full h-full flex flex-col'>
        <div className='w-full flex items-center justify-center bg-white'>
          {themes.map((theme) => (
            <div
              key={theme}
              className={`sw-theme-${theme} relative w-full h-full flex flex-col items-center justify-start overflow-auto`}
            >
              {blocks.map((block) => (
                <div
                  key={`${theme}-${block}`}
                  className={`block-${block} grow w-full p-12`}
                >
                  <SubmittableInput
                    name={`${block}Play`}
                    label='File URL to Play'
                    currentValue={values[`${block}Play`]}
                    inputChanged={setValues}
                    submitHandler={submitHandler}
                    type='text'
                    placeholder='https://example.com/video.mp4'
                    submitButtonLabel='Play'
                    className='flex-1 mb-4 text-sm'
                  />
                  <InputGroup
                    name={`${block}Url`}
                    label='Enter the streaming URL'
                    onChange={setValues}
                    value={values[`${block}Url`]}
                  />
                  <SelectGroup
                    name={`${block}Sel`}
                    label='camera'
                    onChange={setValues}
                    selectedId={values[`${block}Sel`]}
                    options={OPTIONS}
                  />
                  <TextAreaGroup
                    name={`${block}Text`}
                    label='Some Text'
                    onChange={setValues}
                    value={values[`${block}Text`]}
                  />
                  <HorizontalFeatureToggle
                    onToggleOn={() =>
                      bulkUpdate({ ...values, [`${block}Toggle`]: true })
                    }
                    onToggleOff={() =>
                      bulkUpdate({ ...values, [`${block}Toggle`]: false })
                    }
                    label='Toggle'
                    checked={values[`${block}Toggle`]}
                    className='text-sm mb-4'
                  />
                  <ToggleButton
                    onChange={() =>
                      bulkUpdate({
                        ...values,
                        [`${block}ToggleButton`]:
                          !values[`${block}ToggleButton`],
                      })
                    }
                    checked={values[`${block}ToggleButton`]}
                    className='px-4'
                    size={Size.Medium}
                    testId={TestId.AppMenuToggleTheme}
                  />
                  <ToggleButton
                    onChange={() =>
                      bulkUpdate({
                        ...values,
                        [`${block}ToggleButton`]:
                          !values[`${block}ToggleButton`],
                      })
                    }
                    checked={values[`${block}ToggleButton`]}
                    className='px-4'
                    size={Size.Large}
                    testId={TestId.AppMenuToggleTheme}
                  />
                  <div className='flex items-center justify-between mb-4'>
                    <PrimaryButton>Primary</PrimaryButton>
                    <SecondaryButton>Secondary</SecondaryButton>
                    <TertiaryButton>Tertiary</TertiaryButton>
                  </div>
                  <div className='flex items-center justify-between mb-4'>
                    <PrimaryButton disabled>Primary Disabled</PrimaryButton>
                    <SecondaryButton disabled>
                      Secondary Disabled
                    </SecondaryButton>
                    <TertiaryButton disabled>Tertiary Disabled</TertiaryButton>
                  </div>
                  <div className='flex items-center justify-between mb-4'>
                    <TransparentButton>Transparent</TransparentButton>
                    <PrimaryButton transparent>Primary</PrimaryButton>
                    <SecondaryButton transparent>Secondary</SecondaryButton>
                  </div>
                  <div className='flex items-center justify-between'>
                    <TransparentButton disabled>
                      Trns Disabled
                    </TransparentButton>
                    <PrimaryButton transparent disabled>
                      Primary Disabled
                    </PrimaryButton>
                    <SecondaryButton transparent disabled>
                      Secondary Disabled
                    </SecondaryButton>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className='w-full flex items-center justify-center bg-white'>
          {themes.map((theme) => (
            <div
              key={theme}
              className={`sw-theme-${theme} relative w-full h-full flex flex-col items-center justify-start overflow-auto`}
            >
              <div
                key={`${theme}-primary`}
                className='block-primary grow w-full p-12'
              >
                <div className='Toastify__toast--default my-4'>
                  <ToastCard
                    header='Poor Network Connection'
                    message='You are experiencing degraded audio/video due to poor network connection'
                    link='/test'
                    Icon={<ExclamationCircleIcon size='2x' fixedWidth />}
                    color={Color.Warning}
                  />
                </div>
                <div className='Toastify__toast--default my-4'>
                  <ToastCard
                    header='Poor Network Connection'
                    message='Short Message.'
                    link='/test'
                    Icon={<InfoCircleIconSolid size='2x' fixedWidth />}
                    color={Color.Info}
                  />
                </div>
                <div className='Toastify__toast--default my-4'>
                  <ToastCard
                    header='Poor Network Connection'
                    message='Longer message to see wrapped text. You are experiencing degraded audio/video due to poor network connection'
                    link='/test'
                    Icon={<CheckCircleIcon size='2x' fixedWidth />}
                    color={Color.Success}
                  />
                </div>
                <div className='Toastify__toast--default my-4'>
                  <ToastCard
                    header='Poor Network Connection'
                    message='You are experiencing degraded audio/video due to poor network connection'
                    link='/test'
                    Icon={<TimesCircleIcon size='2x' fixedWidth />}
                    color={Color.Danger}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default DesignSystemPage
