import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { groupsActions } from '../redux/features'
import { getGroupsById } from '../redux/features/groups/groupsSelectors'
import { RailsEndpoint, RequestState } from '../rest'
import { useInternalAPI } from './useInternalAPI'
import { sortByNameProperty } from '../services/helpers'

type UseFetchGroupsData = {
  requestState: RequestState
  error: string | null
}

type UseFetchGroupsParams = {
  refreshOnMount?: boolean
}

const fetchOptions = {
  endpoint: RailsEndpoint.Groups,
  method: 'GET',
}

export const useFetchGroups = ({
  refreshOnMount = true,
}: UseFetchGroupsParams = {}) => {
  const dispatch = useDispatch()
  const { invokeAPI: fetchHandler } = useInternalAPI(fetchOptions)
  const groups = useSelector(getGroupsById)
  const [data, setData] = useState<UseFetchGroupsData>({
    requestState: RequestState.Loading,
    error: null,
  })

  const refresh = async () => {
    try {
      setData({
        ...data,
        error: null,
      })
      const { groups } = await fetchHandler()
      dispatch(groupsActions.clear())
      dispatch(groupsActions.fetchGroupsSuccess(groups))
      setData({
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      setData({
        requestState: RequestState.Error,
        error: 'Error loading groups',
      })
    }
  }

  /**
   * Returns the list of groups applying the proper UI filters
   * filterByText: filter on the name only for now
   */
  const getGroups = (params: { filterByText?: string }) => {
    const { filterByText } = params
    return Object.keys(groups)
      .filter((id) => {
        const { name, system } = groups[id]
        // Filter out the system groups
        if (system) {
          return false
        }
        if (filterByText && !name.toLowerCase().includes(filterByText)) {
          return false
        }
        return true
      })
      .map((id) => groups[id])
      .sort(sortByNameProperty)
  }

  useEffect(() => {
    if (refreshOnMount) {
      refresh()
    } else {
      setData({
        requestState: RequestState.Success,
        error: null,
      })
    }
  }, [refreshOnMount])

  return { ...data, refresh, getGroups }
}
