export interface FetchError extends Error {
  response?: Response
  body?: { 
    errors?: Record<string, string[]>
  }
}

export const FETCH_OPTIONS: RequestInit = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-store, max-age=0',
  },
}

const FETCH_TIMEOUT = 30 * 1000

export const cantinaFetch = async (url: RequestInfo, options: RequestInit) => {
  try {
    const controller = new AbortController()
    const abortTimer = setTimeout(() => {
      controller.abort()
    }, FETCH_TIMEOUT)

    const response: Response = await fetch(url, {
      ...options,
      headers: {
        ...(options.headers || {}),
        'Cache-Control': 'no-store, max-age=0',
      },
      cache: 'no-store',
      signal: controller.signal,
    })
    clearTimeout(abortTimer)

    switch (response.status) {
      case 204:
        return response
      case 200:
        return await response.json()
      default: {
        const errorMessage = `HTTP Request failed with status ${response.status}`
        const error: FetchError = new Error(errorMessage)

        error.response = response
        error.body = await response.json().catch(() => ({}))
        return Promise.reject(error)
      }
    }
  } catch (error) {
    return Promise.reject(error)
  }
}
