import { createSelector } from 'reselect'
import { GrantOwnerType } from '../../../constants'
import { CantinaState } from '../../interfaces'
import { hasRoleAdministrator } from '../auth/authSelectors'
import { hasHideParticipantEmailFeatureFlag } from '../settings/settingsSelectors'

const userId = (_: CantinaState, userId: string) => userId
const excludeUserIds = (_: CantinaState, excludeUserIds: string[]) =>
  excludeUserIds

export const getUsersById = ({ users }: CantinaState) => {
  return users.byId
}

export const getUser = createSelector(
  getUsersById,
  userId,
  (users, userId) => users[userId] || null
)
export const getUserName = createSelector(getUser, (user) => user?.name)

/**
 * HideParticipantEmail is a feature flag to hide emails
 * if the logged user is not ad Admin.
 */
export const getUserEmail = createSelector(
  getUser,
  hasRoleAdministrator,
  hasHideParticipantEmailFeatureFlag,
  (user, myRoleAdmin, hasFlag) => {
    if (!hasFlag || (hasFlag && myRoleAdmin)) {
      return user?.email
    }
    return ''
  }
)

export const getUserAutoCompleteItems = createSelector(
  getUsersById,
  excludeUserIds,
  (users, excludeUserIds = []) => {
    let userIds = Object.keys(users)
    if (excludeUserIds?.length) {
      userIds = userIds.filter((userId) => !excludeUserIds.includes(userId))
    }
    return userIds.map((userId) => ({
      id: userId,
      type: GrantOwnerType.User,
      name: users[userId].name,
      searchableLabel: users[userId].name.toLowerCase(),
    }))
  }
)
