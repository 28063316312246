import React, { FC, useEffect, FormEvent } from 'react'
import { useSelector } from 'react-redux'
import { tr, Label } from 'src/common/i18n'
import { User } from 'src/common/redux/interfaces'
import { UserActionType, UserRole } from 'src/common/constants'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { useInputChange } from 'src/effects/useInputChange'
import { RolesPicker } from 'src/pages/SystemSettings/RolesDropdown'
import { Modal } from 'src/modals/Modal'
import { TestId } from 'src/constants'
import {
  getCustomCompanyLabel,
  getCustomNameLabel,
} from 'src/common/redux/features/settings/settingsSelectors'

type UserActionsModalProps = {
  actionType: UserActionType
  closeHandler: () => void
  formHandler: Function
  getError?: Function
  show: boolean
  user?: User
}

const LABELS = {
  [UserActionType.Add]: {
    title: Label.ADD_NEW_USER,
    confirmButtonLabel: Label.ADD_USER,
  },
  [UserActionType.Edit]: {
    title: Label.EDIT_USER,
    confirmButtonLabel: Label.SAVE,
  },
}

export const UserActionsModal: FC<UserActionsModalProps> = ({
  actionType,
  closeHandler,
  formHandler,
  getError,
  show,
  user,
}) => {
  const [values, setValues, bulkUpdate] = useInputChange(
    user ?? { role: UserRole.Member }
  )
  const nameFieldLabel = useSelector(getCustomNameLabel)
  const companyFieldLabel = useSelector(getCustomCompanyLabel)

  // After the update from the back end, apply the user changes
  useEffect(() => {
    if (user) {
      bulkUpdate(user)
    }
  }, [bulkUpdate, user])

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    formHandler({ user: values })
  }
  const emailReadOnly = actionType === UserActionType.Edit
  const emailError = getError && getError('email')

  return (
    <Modal
      autoComplete='off'
      closeHandler={closeHandler}
      confirmHandler={submitHandler}
      confirmLabel={tr(LABELS[actionType].confirmButtonLabel)}
      disabled={!values.name?.length || !values.email?.length}
      isForm
      show={show}
      testId={TestId.SystemSettingsProfileForm}
      title={tr(LABELS[actionType].title)}
    >
      <InputGroup
        autoFocus
        disableAutoComplete
        label={nameFieldLabel}
        name='name'
        onChange={setValues}
        required
        value={values.name ?? ''}
      />
      <InputGroup
        disableAutoComplete
        error={emailError && `${tr(Label.EMAIL)} ${emailError}`}
        label={tr(Label.EMAIL)}
        name='email'
        onChange={setValues}
        required
        disabled={emailReadOnly}
        hint={emailReadOnly ? 'Email cannot be changed' : ''}
        type='email'
        value={values.email ?? ''}
      />
      <RolesPicker
        roleType='user_role'
        name='role'
        backendValue={values.role}
        changeHandler={setValues}
        gridView
        className='mb-4'
      />
      <InputGroup
        label={`${companyFieldLabel} (${tr(Label.OPTIONAL)})`}
        name='companyName'
        onChange={setValues}
        value={values.companyName ?? ''}
      />
      <InputGroup
        disableAutoComplete
        hint={tr(Label.INPUT_ERROR_TELEPHONE)}
        label={`${tr(Label.PHONE)} (${tr(Label.OPTIONAL)})`}
        name='phoneNumber'
        onChange={setValues}
        pattern='^\+?[0-9\- ]{0,20}$'
        type='tel'
        title={tr(Label.INPUT_ERROR_TELEPHONE)}
        value={values.phoneNumber ?? ''}
      />
    </Modal>
  )
}
