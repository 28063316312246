import React, { ComponentType } from 'react'
import { isWebRTCSupported } from 'src/services/Helpers'
import { WebRTCNotSupported } from 'src/components/WebRTCNotSupported'

interface withWebRTCSupportProps {}

export const withWebRTCSupport = <T extends object>(
  WrappedComponent: ComponentType<T>
) => {
  return (props: T & withWebRTCSupportProps) => {
    const isSupported = isWebRTCSupported()
    if (!isSupported) {
      return <WebRTCNotSupported />
    }
    return <WrappedComponent {...props} />
  }
}
