import React, { FC, useState } from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { tr, Label } from 'src/common/i18n'
import {
  withConferenceZoneManageScope,
  withSidebarConversationsFeatureFlag,
} from 'src/common/hoc'
import { EditIcon } from 'src/common/icons'
import { uiActions } from 'src/common/redux/features'
import { Displayable } from 'src/common/constants'
import { getMyParticipantId } from 'src/common/redux/features/calls/callSelectors'
import { TestId } from 'src/constants'
import EditSideBarModal from 'src/modals/SidebarModals/EditSidebarModal'

type ZoneGroupEditSidebarProps = {
  roomId: string
  zoneId: string
  zoneParticipants: string[]
}

const ZoneGroupEditSidebar: FC<ZoneGroupEditSidebarProps> = ({
  roomId,
  zoneId,
  zoneParticipants,
}) => {
  const dispatch = useDispatch()
  const [editingZoneId, setEditingZoneId] = useState('')
  const myParticipantId = useSelector(getMyParticipantId)
  const isInZone = zoneParticipants.includes(myParticipantId)
  const openEditModal = () => {
    dispatch(uiActions.toggle(Displayable.SidebarEditModal))
    setEditingZoneId(zoneId)
  }

  return (
    <>
      {isInZone && (
        <button
          aria-label={tr(Label.EDIT)}
          data-test={TestId.ParticipantsGroupEdit}
          onClick={openEditModal}
        >
          <EditIcon className='cursor-pointer' fixedWidth />
        </button>
      )}
      {editingZoneId === zoneId && (
        <EditSideBarModal roomId={roomId} zoneId={zoneId} />
      )}
    </>
  )
}

export default compose<any>(
  withSidebarConversationsFeatureFlag(),
  withConferenceZoneManageScope
)(ZoneGroupEditSidebar)
