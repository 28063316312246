import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { callViewActions } from 'src/common/redux/views'
import { CantinaState } from 'src/common/redux/interfaces'
import { hasAutoOpenNav } from 'src/common/redux/features/rooms/roomsSelectors'
import { getActiveCallRoomId } from 'src/common/redux/features/calls/callSelectors'

export const AutoOpenRoomNavGuard: FC = () => {
  const dispatch = useDispatch()
  const roomId = useSelector(getActiveCallRoomId)
  const autoOpen = useSelector((state: CantinaState) =>
    hasAutoOpenNav(state, roomId)
  )

  useEffect(() => {
    if (autoOpen && !isMobile) {
      dispatch(callViewActions.openRoomNavigator())
    }
  }, [dispatch, autoOpen])

  return null
}
