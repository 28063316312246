import { cantinaFetch } from '.'

type GetRoomWebSocketURLParams = {
  url: string
  token: string
  roomName: string
}

export const getRoomWebSocketURL = ({
  url,
  token,
  roomName,
}: GetRoomWebSocketURLParams): Promise<void> => {
  return cantinaFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      room_name: roomName,
    }),
  })
}

type GetRoomWebSocketURLByRoomIdParams = {
  baseUrl: string
  token: string
  roomId: string
}
export const getRoomWebSocketURLByRoomId = ({
  baseUrl,
  token,
  roomId,
}: GetRoomWebSocketURLByRoomIdParams): Promise<void> => {
  const url = baseUrl.replace(':roomId', encodeURIComponent(roomId))

  return cantinaFetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}
