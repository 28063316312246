import React, { FC, ChangeEvent, useState } from 'react'
import { tr, Label } from 'src/common/i18n'
import { useAccessGrants } from 'src/common/hooks'
import { RoomRole, GrantResourceType } from 'src/common/constants'
import { Size } from 'src/constants'
import { SelectGroup } from 'src/components/SelectGroup/SelectGroup'
import { InfoTooltip } from 'src/components/Tooltip'
import { RequestStateSwitcher } from '../RequestStateSwitcher'
import { UserData } from '../UserData'
import { GroupData } from '../GroupData'
import { RoomComponentProps } from './RoomDetail'
import { CreateNewAccessGrantButton } from '../CreateNewAccessGrantButton'
import { AccessGrantItem } from '../AccessGrantItem'

const SHOW_ALL = 'all'
const showOptions = [
  { id: SHOW_ALL, label: 'All' },
  { id: RoomRole.Inviteable, label: 'Inviteable' },
  { id: RoomRole.Visitor, label: 'Visitors' },
  { id: RoomRole.Moderator, label: 'Moderators' },
  { id: RoomRole.Manager, label: 'Managers' },
]

export const RoomPermissions: FC<RoomComponentProps> = ({ room }) => {
  const {
    getAccessGrants,
    requestState,
    refresh,
    error,
    updateGrantType,
    deleteAccessGrant,
  } = useAccessGrants(GrantResourceType.Room, room.id)
  const [filterByType, setFilterByType] = useState(SHOW_ALL)
  const [groups, users] = getAccessGrants({ filterByType })
  const groupsGlobal = groups?.filter(
    (accessGrant) => accessGrant.grant_resource_type === 'cantina_room_template'
  )
  const groupsRoom = groups?.filter(
    (accessGrant) => accessGrant.grant_resource_type !== 'cantina_room_template'
  )
  const usersGlobal = users?.filter(
    (accessGrant) => accessGrant.grant_resource_type === 'cantina_room_template'
  )
  const usersRoom = users?.filter(
    (accessGrant) => accessGrant.grant_resource_type !== 'cantina_room_template'
  )

  // Do not exclude current user and group ids
  const userIds: string[] = [] // users.map(({ grant_owner_id }) => grant_owner_id)
  const groupIds: string[] = [] // groups.map(({ grant_owner_id }) => grant_owner_id)

  const ItemRow = ({ accessGrant, readOnly, children }: any) => (
    <AccessGrantItem
      accessGrant={accessGrant}
      readOnly={readOnly}
      updateGrantType={updateGrantType}
      deleteAccessGrant={deleteAccessGrant}
      roleType='room_role'
    >
      {children}
    </AccessGrantItem>
  )

  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.PERMISSIONS)}
      </div>
      <RequestStateSwitcher
        requestState={requestState}
        refresh={refresh}
        error={error}
      >
        <div className='flex items-center justify-between mb-4'>
          <SelectGroup
            canHaveError={false}
            className='flex-1 mr-4'
            horizontalLabel
            label='Show'
            name='auto-shuffle'
            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
              setFilterByType(event.target.value)
            }
            options={showOptions}
            selectedId={filterByType}
          />
          <CreateNewAccessGrantButton
            resourceType={GrantResourceType.Room}
            resourceId={room.id}
            refresh={refresh}
            userIds={userIds}
            groupIds={groupIds}
          />
        </div>
        {!users?.length && !groups?.length && (
          <p className='text-base py-2'>{tr(Label.ACCESS_GRANTS_NO_RESULTS)}</p>
        )}
        <div className='divide-y divide-contrast-m'>
          {(usersGlobal?.length > 0 || groupsGlobal?.length > 0) && (
            <div className='pt-4 pb-3'>
              <div className='font-bold mb-2'>
                {tr(Label.INHERITED_GLOBAL_PERMISSIONS)}
                <InfoTooltip
                  title={tr(Label.PERMISSIONS_TOOLTIP_GLOBAL_PERMISSIONS)}
                />
              </div>
              {usersGlobal?.map((accessGrant) => (
                <ItemRow
                  key={accessGrant.id}
                  accessGrant={accessGrant}
                  readOnly
                >
                  <UserData
                    userId={accessGrant.grant_owner_id}
                    size={Size.Small}
                  />
                </ItemRow>
              ))}
              {groupsGlobal?.map((accessGrant) => (
                <ItemRow
                  key={accessGrant.id}
                  accessGrant={accessGrant}
                  readOnly
                >
                  <GroupData
                    groupId={accessGrant.grant_owner_id}
                    className='ml-2'
                  />
                </ItemRow>
              ))}
            </div>
          )}
          {(usersRoom?.length > 0 || groupsRoom?.length > 0) && (
            <div className='py-4'>
              <div className='font-bold mb-2'>{tr(Label.ROOM_PERMISSIONS)}</div>
              {usersRoom?.map((accessGrant) => (
                <ItemRow
                  key={accessGrant.id}
                  accessGrant={accessGrant}
                  readOnly={false}
                >
                  <UserData
                    userId={accessGrant.grant_owner_id}
                    size={Size.Small}
                  />
                </ItemRow>
              ))}
              {groupsRoom?.map((accessGrant) => (
                <ItemRow
                  key={accessGrant.id}
                  accessGrant={accessGrant}
                  readOnly={false}
                >
                  <GroupData
                    groupId={accessGrant.grant_owner_id}
                    className='ml-2'
                  />
                </ItemRow>
              ))}
            </div>
          )}
        </div>
      </RequestStateSwitcher>
    </>
  )
}
