import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RoutePath } from 'src/common/constants'
import { hasValidAuth } from 'src/common/redux/features/auth/authSelectors'

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const hasAuth = useSelector(hasValidAuth)
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (hasAuth) {
          return children
        }
        return (
          <Redirect
            to={{ pathname: RoutePath.GuestLogin, state: { from: location } }}
          />
        )
      }}
    />
  )
}
