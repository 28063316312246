import { SagaIterator } from 'redux-saga'
import { take, call, put, fork, select, all, retry } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'

import * as Relay from '../../../services/relay'
import {
  ADMIN_SCOPES,
  GUEST_SCOPES,
  MEMBER_SCOPES,
  HYBRID_SCOPE_ID,
  Scope,
  SocketStatus,
} from '../../../constants'
import {
  RailsEndpoint,
  refreshJWT,
  Profile,
  updateProfile,
  Endpoint,
} from '../../../rest'
import { flushAction, purgeAction } from '../../actions'
import {
  authActions,
  refreshJWTRequest,
  setScopesRequest,
  updateProfileRequest,
} from '../'
import {
  getCantinaId,
  getVertoHost,
  getIceServers,
  hasSidebarConversationsGuestCreateFeatureFlag,
  getInternalAPIEndpoint,
  getCantinaBackendAPIEndpoint,
  getCantinaManagerHost,
  hasHideAdminMenuFeatureFlag,
} from '../settings/settingsSelectors'
import {
  getUserId,
  getAuthToken,
  getUserType,
  isAuthenticated,
  hasRoleAdministrator,
  hasRoleMember,
  hasTemporaryAuth,
  isAuthTokenExpiring,
} from '../auth/authSelectors'
import { setupRelay } from '../relay/relaySaga'
import { scopesActions } from '../scopes/scopesSlice'
import { canCreateAdHocRooms } from '../roomTemplates/roomTemplatesSelectors'
import {
  fetchAvailableRoomTemplatesWorker,
  fetchDefaultRoomTemplateWorker,
} from '../roomTemplates/roomTemplatesSaga'
import { fetchSettingsWorker } from '../settings/settingsSaga'
import { getSocketStatus } from '../ui/uiSelectors'

const REFRESH_MAX_TRIES = 2
const REFRESH_DELAY_MS = 500

const ACTIONS: string[] = [
  authActions.loginSuccess.type,
  refreshJWTRequest.type,
  setScopesRequest.type,
  updateProfileRequest.type,
]

const READY_OR_CONNECTING = [SocketStatus.Connecting, SocketStatus.Ready]

/**
 * In hybrid mode we use Verto instead of Relay
 * so we need different params to init the client.
 * As soon as we jump on Relay, we will have another
 * method to "setupRelay" with different params.
 */
function* hybridVertoConnect(): SagaIterator {
  const params = {
    cantinaManagerHost: yield select(getCantinaManagerHost),
    vertoHost: yield select(getVertoHost),
    vertoLogin: yield select(getUserId),
    vertoPasswd: yield select(getAuthToken),
    iceServers: yield select(getIceServers),
    loginParams: {
      cantinaId: yield select(getCantinaId),
      userType: yield select(getUserType),
    },
  }
  /**
   * Before connecting to Relay/Verto:
   * - fetch the cantina settings
   * - fetch the default room template
   * - fetch the list of room templates available to me
   */
  // prettier-ignore
  yield all([
    put(flushAction()),
    fork(fetchSettingsWorker),
    fork(fetchDefaultRoomTemplateWorker),
    fork(fetchAvailableRoomTemplatesWorker),
  ])
  yield call(setupRelay, params)
}

export function* worker({ type, payload }: PayloadAction<any>): SagaIterator {
  switch (type) {
    case authActions.loginSuccess.type: {
      try {
        yield call(hybridVertoConnect)
      } catch (error) {
        console.error('Auth request failed', error)
        yield put(authActions.loginFailure({ message: 'Invalid Login' }))
      }
      break
    }
    case refreshJWTRequest.type: {
      try {
        const temporaryAuth = yield select(hasTemporaryAuth)
        if (temporaryAuth) {
          return
        }
        const cantinaId = yield select(getCantinaId)
        const userId = yield select(getUserId)
        if (!cantinaId || !userId) {
          console.debug('Missing cantinaId or userId')
          yield put(purgeAction())
          return
        }

        const isTokenExpiring = yield select(isAuthTokenExpiring)
        const socketStatus = yield select(getSocketStatus)
        if (READY_OR_CONNECTING.includes(socketStatus) && !isTokenExpiring) {
          return console.debug('Token still valid')
        }

        const baseUrl = yield select(
          getCantinaBackendAPIEndpoint,
          Endpoint.Refresh
        )
        const params = { baseUrl, body: { cantinaId } }
        const response = yield retry(
          REFRESH_MAX_TRIES,
          REFRESH_DELAY_MS,
          refreshJWT,
          params
        )
        const { company_name, email, name, phone_number, token, user_role } =
          response

        yield put(
          authActions.updateProfileSuccess({
            company: company_name,
            email,
            name,
            phone: phone_number,
            token,
            role: user_role,
          })
        )
        if (Relay.isClientInitialized()) {
          yield call(Relay.relayRefreshToken, token)
        } else {
          yield call(hybridVertoConnect)
        }
      } catch (error) {
        // @ts-expect-error
        console.debug('JWT Refresh error', error, error?.response)
        // Purge the state only with 400/401 response codes
        // Otherwise could be a network error (ie: lid closed)
        // @ts-expect-error
        if ([400, 401].includes(error?.response?.status)) {
          yield put(purgeAction())
        }
      }
      break
    }
    case setScopesRequest.type: {
      const scopeId = HYBRID_SCOPE_ID
      const authenticated = yield select(isAuthenticated)
      let scopes = GUEST_SCOPES

      if (authenticated) {
        const isAdministrator = yield select(hasRoleAdministrator)
        const isMember = yield select(hasRoleMember)
        if (isAdministrator) {
          scopes = ADMIN_SCOPES
        } else if (isMember) {
          scopes = MEMBER_SCOPES

          // For members check the FF to enable or disable the admin menu
          const hideAdminMenu = yield select(hasHideAdminMenuFeatureFlag)
          if (hideAdminMenu) {
            scopes = scopes.filter((s) => s !== Scope.UiSettings)
          }
        }
      } else {
        // Inject guest sidebar creation/mgmt scope if the flag is set
        const guestsCanCreateSidebars = yield select(
          hasSidebarConversationsGuestCreateFeatureFlag
        )
        if (guestsCanCreateSidebars) {
          scopes = [
            ...scopes,
            Scope.ConferenceZoneManage,
            Scope.ConferenceZoneDestroy,
            Scope.ConferenceZoneExtVol,
          ]
        }
      }
      // Inject (or remove) UiDialer if the user can/cannot create AdHoc rooms
      const canCreateAdHoc = yield select(canCreateAdHocRooms)
      scopes = scopes.filter((s) => s !== Scope.UiDialer)
      if (canCreateAdHoc) {
        scopes = [...scopes, Scope.UiDialer]
      }
      yield put(scopesActions.create({ scopeId, scopes }))
      yield put(authActions.updateScopeId(scopeId))
      break
    }
    case updateProfileRequest.type: {
      const baseUrl = yield select(
        getInternalAPIEndpoint,
        RailsEndpoint.Profile
      )
      const token = yield select(getAuthToken)
      const params: Partial<Profile> = {
        name: payload.name,
        company_name: payload.company,
        phone_number: payload.phone,
      }
      try {
        const { name, email, company_name, phone_number } = yield call(
          updateProfile,
          { baseUrl, token, params }
        )
        const action = authActions.updateProfileSuccess({
          name,
          email,
          phone: phone_number,
          company: company_name,
        })
        yield put(action)
        Relay.toastSuccess('Your profile was updated.')
      } catch (error) {
        console.error('Profile Error', error)
        Relay.toastError('An error occurred while saving.')
      }
      break
    }
    default:
      console.warn('Unhandled authSaga action', type, payload)
  }
}

export function* watchAuths(actions: string[] = ACTIONS): SagaIterator {
  while (true) {
    const action = yield take(actions)
    yield fork(worker, action)
  }
}
