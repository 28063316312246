import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { hasValidAuth } from '../redux/features/auth/authSelectors'

export const withAuth = <T extends object>(
  WrappedComponent: ComponentType<T>
) => {
  return (props: T) => {
    const hasAuth = useSelector(hasValidAuth)
    if (hasAuth) {
      return <WrappedComponent {...props} />
    }
    return null
  }
}
