import { SagaIterator } from 'redux-saga'
import { take, fork, select } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import * as Relay from '../../../services/relay'
import { participantKnock } from '../'
import { getParticipantCallId } from './participantSelectors'

import { getMyParticipantId } from '../calls/callSelectors'

const ACTIONS = [participantKnock.type]

export function* worker({
  type,
  payload: participantId,
}: PayloadAction<any>): SagaIterator {
  const callId = yield select(getParticipantCallId, participantId)
  if (!callId) {
    console.warn(
      `Invalid ${type} action on Participant ${participantId}. Call not found.`
    )
    return
  }
  switch (type) {
    case participantKnock.type: {
      const sendingParticipantId = yield select(getMyParticipantId)
      return Relay.knock(callId, sendingParticipantId, participantId)
    }
    default:
      console.warn('Unhandled participantSaga action', type, participantId)
  }
}

export function* watchParticipants(actions: string[] = ACTIONS): SagaIterator {
  while (true) {
    const action = yield take(actions)
    yield fork(worker, action)
  }
}
