import React, { FC, useState, useEffect } from 'react'
import { tr, Label } from 'src/common/i18n'
import { useSettingsField, useDebounce } from 'src/common/hooks'
import { SettingsKeys } from 'src/common/rest'
import TinyMCE from 'src/components/TinyMCE'

export const SettingsTinyMCE: FC<{ name: SettingsKeys; label: Label }> = ({
  name,
  label,
}) => {
  const { reduxValue, update } = useSettingsField(name)
  const [text, setText] = useState(reduxValue as string)
  const debouncedText = useDebounce(text, 500)

  // Only set the initial text on the first render because it otherwise
  // "controls" the MCE field and changes to it will move the cursor to
  // the beginning of the content
  const [initialText] = useState(reduxValue as string)

  useEffect(() => {
    update(debouncedText)
  }, [update, debouncedText])

  return (
    <TinyMCE
      initialValue={initialText}
      label={tr(label)}
      name={name}
      onChange={setText}
    />
  )
}
