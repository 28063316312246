import React, { FC, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { compose } from 'redux'

import { CantinaState } from 'src/common/redux/interfaces'
import { getRoomZone } from 'src/common/redux/features/roomZones/roomZonesSelectors'
import { MinusSquareIcon, PlusSquareIcon } from 'src/common/icons'
import { tr, Label } from 'src/common/i18n'
import { withSidebarConversationsFeatureFlag } from 'src/common/hoc'
import { getParticipantTestIdSuffix } from 'src/services/Helpers'
import { getRoomParticipantIdsByRoomZoneId } from 'src/common/redux/features/participants/participantSelectors'

import { TestId } from 'src/constants'
import { ParticipantRow } from './ParticipantList'
import { JoinZoneButton } from '../CallButtons/JoinZoneButton'
import ZoneGroupEditSidebar from './ZoneGroupEditSidebar'

type ZoneGroupProps = {
  className?: string
  roomId: string
  zoneId: string
  zoneIndex?: number
}

const ZoneGroup: FC<ZoneGroupProps> = ({
  className,
  roomId,
  zoneId,
  zoneIndex,
}) => {
  const [showList, setShowList] = useState(true)
  const zoneParticipants = useSelector(
    (state: CantinaState) =>
      getRoomParticipantIdsByRoomZoneId(state, roomId, zoneId),
    shallowEqual
  )
  const zone = useSelector((state: CantinaState) => getRoomZone(state, zoneId))
  const ToggleComponent = showList ? MinusSquareIcon : PlusSquareIcon
  const toggleClasses = showList && 'h-48 resize-y'

  return (
    <>
      <div
        className={`${className} ${toggleClasses} border rounded block-primary overflow-auto`}
        style={{ borderColor: `${zone?.color}` }}
        data-test={`${TestId.ParticipantsGroupZone}-${zoneIndex}`}
      >
        <div className='block-highlight p-2 sticky top-0 shadow-level-1 z-20 flex'>
          <span
            className='flex-3 truncate'
            data-test={TestId.ParticipantsGroupName}
          >
            {zone.name}
          </span>
          <span
            aria-label={tr(Label.TOGGLE)}
            className='flex-1 ml-2'
            data-test={TestId.ParticipantsGroupToggleShow}
            onClick={() => setShowList(!showList)}
            role='button'
          >
            <ToggleComponent className='cursor-pointer' fixedWidth />
          </span>
          <div>
            <ZoneGroupEditSidebar
              roomId={roomId}
              zoneId={zoneId}
              zoneParticipants={zoneParticipants}
            />
            <JoinZoneButton className='text-xs ml-2' zone={zone} />
          </div>
        </div>
        {/* TODO - SIDEBARS: Maybe this could be a shared fragment with ParticipantGroup? */}
        {showList && (
          <div className='mb-2 h-30'>
            {zoneParticipants?.length ? (
              zoneParticipants.map((id, rowIndex) => (
                <ParticipantRow
                  className='px-2'
                  key={id}
                  participantId={id}
                  testIdSuffix={getParticipantTestIdSuffix({
                    rowIndex,
                    zoneIndex,
                  })}
                />
              ))
            ) : (
              <div className='text-contrast-m text-sm p-2'>
                {tr(Label.NO_PARTICIPANTS_GROUP)}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default compose<any>(withSidebarConversationsFeatureFlag())(ZoneGroup)
