import React, { ComponentType } from 'react'

export const withScreenShareSupport = <T extends object>(
  WrappedComponent: ComponentType<T>
) => {
  return (props: T) => {
    // @ts-ignore
    const hasSupport = Boolean(
      navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia
    )
    if (hasSupport) {
      return <WrappedComponent {...props} />
    }
    return null
  }
}
