import { v4 as uuidv4 } from 'uuid'

type Timer =
  | 'cantina:bootstrap'
  | 'cantina:roomReserve'
  | 'cantina:connectToFs'
  | 'cantina:roomJoin'

export const cantinaTimer = (label?: Timer) => {
  const tmp: string = label ?? uuidv4()

  return {
    start: () => console.time(tmp),
    stop: () => console.timeEnd(tmp),
  }
}
