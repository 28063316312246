import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'
import { LayoutState, Layout, Call } from '../../interfaces'
import { callActions } from '../calls/callSlice'

export const initialLayoutState: LayoutState = Object.freeze({
  byId: {},
  defaultIds: [],
  idsByCallId: {},
})

const layoutsSlice = createSlice({
  name: 'layouts',
  initialState: initialLayoutState,
  reducers: {
    bulkInsert: (state, { payload }: PayloadAction<LayoutState>) => {
      state.byId = payload.byId
      state.defaultIds = payload.defaultIds
    },
    addCallLayouts: (
      state,
      { payload }: PayloadAction<{ callId: string; layouts: Layout[] }>
    ) => {
      const { callId, layouts = [] } = payload
      state.idsByCallId[callId] = []
      layouts.forEach((layout) => {
        state.byId[layout.id] = layout
        state.idsByCallId[callId].push(layout.id)
      })
    },
  },
  extraReducers: {
    [callActions.destroy.type]: (
      state,
      { payload: call }: PayloadAction<Call>
    ) => {
      const callId = call.id
      const ids = state.idsByCallId[callId] || []
      ids.forEach((layoutId) => {
        delete state.byId[layoutId]
      })
      delete state.idsByCallId[callId]
    },
  },
})

export const loadDefaultLayouts = createAction('layouts/loadDefaultLayouts')

// prettier-ignore
export const {
  actions: layoutsActions,
  reducer: layoutsReducer
} = layoutsSlice
