import React, { FC, useState, useMemo } from 'react'
import { RoomType } from 'src/common/constants'
import { tr, Label } from 'src/common/i18n'
import { useRoomSelector } from 'src/common/hooks'
import { makeRoomCardSelector } from 'src/common/redux/features/roomList/roomListSelectors'
import { TestId } from 'src/constants'
import { RoomParticipants } from './RoomParticipants'
import { RoomCardButtons } from '../RoomCardButtons'
import { RoomPreview } from '../Rooms/RoomPreview'
import { TransferActions, TransferList } from './TransferList'
import { RoomData } from './RoomData'

type RoomProps = {
  roomId: string
  testId: TestId | string
}

export const LiveRoomCard: FC<RoomProps> = ({ roomId, testId }) => {
  const selector = useMemo(makeRoomCardSelector, [])
  const { roomName, roomDescription, running } = useRoomSelector(
    selector,
    roomId
  )
  const [transferListOpened, setTransferListOpened] = useState(false)

  const type = running ? RoomType.Live : RoomType.Static

  if (transferListOpened) {
    return (
      <div className='room-nav-box' data-test={testId}>
        <RoomData
          roomId={roomId}
          name={roomName}
          description={roomDescription}
        />
        <TransferList />
        <TransferActions
          extension={roomName}
          onCancel={() => setTransferListOpened(false)}
        />
      </div>
    )
  }

  return (
    <div className='room-nav-box' data-test={testId}>
      <RoomData roomId={roomId} name={roomName} description={roomDescription} />
      <RoomPreview type={type} roomId={roomId} roomName={roomName} />
      <div className='flex justify-between items-center gap-2 p-1'>
        {type === RoomType.Live ? (
          <RoomParticipants roomId={roomId} />
        ) : (
          <div className='text-xs font-medium'>{tr(Label.EMPTY_ROOM)}</div>
        )}
        <div className='flex justify-end items-center gap-1 overflow-hidden'>
          <RoomCardButtons
            extension={roomName}
            joinLabel={tr(Label.JOIN)}
            joinTestId={TestId.RoomNavigatorButtonJoin}
            roomId={roomId}
            transferListToggle={setTransferListOpened}
            transparent
            forceToJoin
          />
        </div>
      </div>
    </div>
  )
}
