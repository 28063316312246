import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useInternalAPI } from './useInternalAPI'
import { usersActions } from '../redux/features'
import { tr, Label } from '../i18n'
import { toastSuccess } from '../services/relay'
import { RailsEndpoint, RequestState, User } from '../rest'

type UpdateParams = Partial<
  Pick<User, 'name' | 'email' | 'phone_number' | 'company_name' | 'user_role'>
>
type UseCreateUserData = {
  requestState: RequestState
  errors: {
    [key: string]: string[]
  }
}

const updateOptions = {
  endpoint: RailsEndpoint.Users,
  method: 'PATCH',
}

// Used directly inside the modal
export const useUpdateUser = (userId: string) => {
  const { invokeAPI: updateHandler } = useInternalAPI(updateOptions)
  const dispatch = useDispatch()
  const [data, setData] = useState<UseCreateUserData>({
    requestState: RequestState.Loading,
    errors: {},
  })

  const update = async (params: UpdateParams) => {
    try {
      if (data.requestState !== RequestState.Loading) {
        setData({
          requestState: RequestState.Loading,
          errors: {},
        })
      }
      const response = await updateHandler({
        urlTransform: (url) => `${url}/${userId}`,
        body: JSON.stringify(params),
      })
      dispatch(usersActions.fetchUsersSuccess([response]))

      setData({
        requestState: RequestState.Success,
        errors: {},
      })
      toastSuccess(tr(Label.USER_PROFILE_UPDATE_SUCCESS))
    } catch (error) {
      // @ts-expect-error
      const { errors } = await error?.response?.json()
      setData({
        requestState: RequestState.Error,
        errors,
      })
    }
  }

  const getError = (key: string) => data.errors[key]?.join()
  const isInvalid = () => Object.keys(data.errors).length > 0

  return { ...data, update, getError, isInvalid }
}
