import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'
import {
  RequestToJoin,
  RoomZone,
  RoomZoneInvitation,
  RoomZoneState,
} from '../../interfaces'
import { flushAction } from '../../actions'
import {
  roomsActions,
  roomCommand,
  roomJoinedAction,
  roomLeftAction,
} from '../rooms/roomsSlice'
import { participantActions } from '../participants/participantSlice'
import { callActions } from '../calls/callSlice'
import { RoomCommandAction } from '../../../constants/index'

type CreateFromConferenceInfoParams = {
  roomId: string
  zones: Omit<RoomZone, 'roomId'>[]
}

export const initialRoomZoneState: RoomZoneState = Object.freeze({
  /**
   * Example: {
   *   'z1': { id: 'z1', roomId: 'r1', name: 'first', extVol: -13 },
   *   'z2': { id: 'z2', roomId: 'r1', name: 'second', extVol: -13 },
   *   'z3': { id: 'z3', roomId: 'r2', name: 'third', extVol: -13 }
   * }
   */
  byId: {},
  // convenience to prevent extra filtering
  // Example: {'r1': ['z1', 'z2'], 'r2': ['z3'] }
  idsByRoomId: {},
  zoneInvitationList: [],
  zoneEditInvitationList: [],
  zoneRequestToJoinList: [],
})

const roomZoneSlice = createSlice({
  name: 'roomZones',
  initialState: initialRoomZoneState,
  reducers: {
    bulkInsert: (
      state,
      { payload }: PayloadAction<Omit<RoomZoneState, 'idsByRoomId'>>
    ) => {
      /**
       * Bulk insert RoomZones in the state filling both "idsByRoomId" and "byId"
       */
      Object.keys(payload.byId).forEach((zoneId) => {
        const { roomId } = payload.byId[zoneId]

        state.idsByRoomId[roomId] = state.idsByRoomId[roomId] || []

        if (!state.idsByRoomId[roomId].includes(zoneId)) {
          state.idsByRoomId[roomId].push(zoneId)
        }

        state.byId[zoneId] = {
          ...(state.byId[zoneId] || {}),
          ...payload.byId[zoneId],
        }
      })
    },
    createFromConferenceInfo: (
      state,
      { payload }: PayloadAction<CreateFromConferenceInfoParams>
    ) => {
      /**
       * From every conferenceInfo event we have the list of zones currently active so we have to
       * clear the previous data and fill "idsByRoomId" and "byId" with new data.
       */
      const { roomId, zones = [] } = payload
      const currentZoneIds = state.idsByRoomId[roomId] || []

      currentZoneIds.forEach((zoneId) => {
        delete state.byId[zoneId]
      })
      state.idsByRoomId[roomId] = []

      if (zones?.length) {
        zones.forEach((zone) => {
          const zoneId = `${zone.id}` // Because FS returns an integer

          state.idsByRoomId[roomId].push(zoneId)
          state.byId[zoneId] = {
            roomId,
            ...zone,
            id: zoneId,
          }
        })
      }
    },
    setZoneInvitationList: (
      state,
      { payload }: PayloadAction<RoomZoneInvitation>
    ) => {
      // Don't add duplicate invitations to the same room
      if (
        !state.zoneInvitationList?.some(
          (inv) => inv.zoneName === payload.zoneName
        )
      ) {
        state.zoneInvitationList?.push(payload)
      }
    },
    setZoneEditInvitationList: (state, { payload }: PayloadAction<string>) => {
      state.zoneEditInvitationList?.push(payload)
    },
    deleteInvitationFromZoneEditInvitationList: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.zoneEditInvitationList = state.zoneEditInvitationList?.filter(
        (id) => id !== payload
      )
    },
    setZoneRequestToJoinList: (
      state,
      { payload }: PayloadAction<RequestToJoin>
    ) => {
      // Only send the request if it's not already on the zoneRequestToJoinList
      if (
        state.zoneRequestToJoinList?.findIndex(
          (x) => x.requestingParticipantId === payload.requestingParticipantId
        ) === -1
      ) {
        state.zoneRequestToJoinList.push(payload)
      }
    },
    deleteRequestFromRequestToJoinList: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.zoneRequestToJoinList = state.zoneRequestToJoinList?.filter(
        (req) => req.requestingParticipantId !== payload
      )
    },
    deleteInvitationFromZoneInvitationList: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      // Used when a zone is ended while someone still has a pending invitation
      state.zoneInvitationList = state.zoneInvitationList?.filter(
        (inv) => inv.zoneName !== payload
      )
    },
    clear: (state) => {
      state.zoneInvitationList = []
      state.zoneRequestToJoinList = []
      state.zoneEditInvitationList = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(flushAction, () => initialRoomZoneState)
      .addCase(
        roomsActions.destroyById,
        (state, { payload: roomId }: PayloadAction<string>) => {
          if (roomId in state.idsByRoomId) {
            state.idsByRoomId[roomId].forEach((roomZoneId) => {
              delete state.byId[roomZoneId]
            })
            delete state.idsByRoomId[roomId]
          }
        }
      )
      .addCase(callActions.destroy, (state) => {
        state.zoneInvitationList = []
        state.zoneRequestToJoinList = []
        state.zoneEditInvitationList = []
      })
      .addCase(participantActions.update, (state, { payload }) => {
        // Payload is a Participant
        const { id, roomZoneId } = payload

        // If someone on your list is now in a sidebar, remove them from
        // everyone's list of new invites from the Edit modal
        if (roomZoneId) {
          state.zoneEditInvitationList = state.zoneEditInvitationList?.filter(
            (x) => x !== id
          )
        }
      })
      .addCase(roomCommand, (state, { payload: { action, zoneName } }) => {
        switch (action) {
          case RoomCommandAction.ZoneInvitationDeclined:
          case RoomCommandAction.ZoneJoin: {
            state.zoneInvitationList = state.zoneInvitationList?.filter(
              (inv) => inv.zoneName !== zoneName
            )
            break
          }
          case RoomCommandAction.ZonePart: {
            state.zoneInvitationList = []
            state.zoneRequestToJoinList = []
            state.zoneEditInvitationList = []
            break
          }
        }
      })
      /**
       * Clear the state on roomJoined/roomLeft
       */
      .addMatcher(
        isAnyOf(roomJoinedAction, roomLeftAction),
        () => initialRoomZoneState
      )
  },
})

export const {
  actions: roomZoneActions,
  reducer: roomZoneReducer,
} = roomZoneSlice
