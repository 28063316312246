import { createSelector } from 'reselect'
import { GrantOwnerType } from '../../../constants'
import { CantinaState } from '../../interfaces'

const groupId = (_: CantinaState, groupId: string) => groupId
const excludeGroupIds = (_: CantinaState, excludeGroupIds: string[]) =>
  excludeGroupIds

export const getGroupsById = ({ groups }: CantinaState) => {
  return groups.byId
}

export const getGroup = createSelector(
  getGroupsById,
  groupId,
  (groups, groupId) => groups[groupId] || null
)
export const getGroupName = createSelector(getGroup, (group) => group?.name)

// prettier-ignore
export const getGroupAutoCompleteItems = createSelector(
  getGroupsById,
  excludeGroupIds,
  (groups, excludeGroupIds = []) => {
    let groupIds = Object.keys(groups)
    if (excludeGroupIds?.length) {
      groupIds = groupIds.filter(groupId => !excludeGroupIds.includes(groupId))
    }
    return groupIds.map(groupId => ({
      id: groupId,
      type: GrantOwnerType.Group,
      name: groups[groupId].name,
      searchableLabel: groups[groupId].name.toLowerCase(),
    }))
  }
)
