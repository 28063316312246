import React, { ChangeEvent, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { callViewActions } from 'src/common/redux/views'
import { CantinaState } from 'src/common/redux/interfaces'
import { getClipeezePlayOptions } from 'src/common/redux/views/callView/callViewSelectors'
import {
  getFilePlaybackRole,
  isVideoPlaying,
} from 'src/common/redux/features/rooms/roomsSelectors'
import { getCallRoomId } from 'src/common/redux/features/calls/callSelectors'
import { useDispatchRoomCommand, useRoomSelector } from 'src/common/hooks'
import { withActiveCall } from 'src/common/hoc'
import { tr, Label } from 'src/common/i18n'
import { RepeatIcon } from 'src/common/icons'
import { RoomCommandAction } from 'src/common/constants'
import { TestId } from 'src/constants'
import { ClipeezeList } from './ClipeezeList'
import { ClipeezeSearchWithFilter } from './ClipeezeSearch'
import { ButtonFeatureToggle } from '../Inputs/ButtonFeatureToggle'
import { LayoutRolesDropdown } from '../CallSettings/LayoutRolesDropdown'

const ClipeezePlayOptions = ({ callId }: { callId: string }) => {
  const dispatch = useDispatch()
  const dispatchRoomCommand = useDispatchRoomCommand()
  const roomId = useSelector((state: CantinaState) =>
    getCallRoomId(state, callId)
  )
  const isPlaying = useRoomSelector(isVideoPlaying, roomId)
  const filePlaybackRole = useRoomSelector(getFilePlaybackRole, roomId)
  const { clipeezeLoop } = useSelector(getClipeezePlayOptions, shallowEqual)

  const loopToggleOn = useCallback(() => {
    dispatch(callViewActions.setClipeezeLoop(true))
  }, [dispatch])
  const loopToggleOff = useCallback(() => {
    dispatch(callViewActions.setClipeezeLoop(false))
  }, [dispatch])

  return (
    <div className='flex items-end gap-1'>
      <LayoutRolesDropdown
        roomId={roomId}
        name='layoutReservation'
        label={tr(Label.CLIPEEZE_ROLE)}
        className='flex-1 pb-7 sm:pb-0'
        onChange={({ target }: ChangeEvent<HTMLSelectElement>) => {
          if (isPlaying) {
            dispatchRoomCommand({
              roomId,
              action: RoomCommandAction.SetPlaybackRole,
              reservationId: target.value,
            })
          }
          dispatchRoomCommand({
            roomId,
            action: RoomCommandAction.SetFilePlaybackRole,
            reservationId: target.value,
          })
        }}
        selectClassName='h-10'
        selectedId={filePlaybackRole || ''}
      />
      <ButtonFeatureToggle
        checked={clipeezeLoop}
        buttonClassName='mt-1'
        Icon={RepeatIcon}
        iconSize='lg'
        label={Label.LOOP_CLIP}
        onToggleOn={loopToggleOn}
        onToggleOff={loopToggleOff}
        testId={TestId.ClipeezeToggleLoop}
      />
    </div>
  )
}

export const Clipeeze = withActiveCall(({ callId }) => {
  return (
    <div className='block-primary flex flex-col h-full overflow-hidden'>
      <div className='shrink-0 mt-3 mb-4 px-2'>
        <ClipeezeSearchWithFilter className='mb-4' autoFocus />
        <ClipeezePlayOptions callId={callId} />
      </div>
      <div className='w-full flex flex-1 flex-col overflow-y-auto select-none'>
        <ClipeezeList />
      </div>
    </div>
  )
})
