import { CantinaState } from '../../interfaces'

export const getActiveChat = ({ chats }: CantinaState) => {
  // @ts-expect-error
  return chats.byId[chats.active] || null
}

export const getActiveChatId = ({ chats }: CantinaState) => {
  return chats.active || null
}

export const getLastReadId = (state: CantinaState) => {
  const chat = getActiveChat(state)
  if (!chat) {
    return null
  }

  return chat.lastReadId || ''
}
