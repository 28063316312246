import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { RoutePath, Scope, Loader } from 'src/common/constants'
import { withLoading } from 'src/common/hoc'
import { refreshJWTRequest } from 'src/common/redux/features'
import Home from 'src/pages/Home/Home'
import { CallPage } from 'src/pages/Call/Call'
import DirectCallPage from 'src/pages/DirectCall/DirectCall'
import { Login } from 'src/pages/Logins/Login'
import { PasswordForgot } from 'src/pages/PasswordForgot/PasswordForgot'
import PasswordSet from 'src/pages/PasswordSet/PasswordSet'
import SystemSettingsPage from 'src/pages/SystemSettings/SystemSettings'
import AutoLoginPage from 'src/pages/AutoLogin/AutoLogin'
import ImpersonatePage from 'src/pages/Impersonate/Impersonate'
import { PrejoinPage } from 'src/pages/Prejoin/Prejoin'
import { PrivateRoute } from './PrivateRoute'
import { LoginRoute } from './LoginRoute'
import { NotFoundRoute } from './NotFoundRoute'
import { RoomRoute } from './RoomRoute'
import { AutoLoginRoute } from './AutoLoginRoute'
import ScopedRoute from './ScopedRoute'
import DesignSystemPage from 'src/pages/DesignSystem/DesignSystem'
import { StreamingPage } from 'src/pages/Streaming/StreamingPage'
import { LoadingBox } from 'src/layout/LoadingBox'

/**
 * Render CUSTOM_ROUTES only for LEGACY or HYBRID builds.
 * It's using JSX.Element[] because the <Switch> below
 * does not support Fragments.
 * https://github.com/ReactTraining/react-router/issues/5785
 */
let CUSTOM_ROUTES: JSX.Element[] = []

if (process.env.REACT_APP_HYBRID_MODE === '1') {
  CUSTOM_ROUTES = [
    <ScopedRoute
      key={RoutePath.Settings}
      path={RoutePath.Settings}
      redirectTo={RoutePath.Home}
      scope={Scope.UiSettings}
    >
      <SystemSettingsPage />
    </ScopedRoute>,
    <LoginRoute key={RoutePath.Login} path={RoutePath.Login}>
      <Login />
    </LoginRoute>,
    <Route
      exact
      key={RoutePath.UserSetup}
      path={[RoutePath.UserSetup, RoutePath.UserPasswordReset]}
    >
      <PasswordSet />
    </Route>,
  ]
}

/**
 * A kind of HOC to apply the useEffect and dispatch
 * a refreshJWTRequest action in Hybrid Mode
 */
const AutoRefreshTokenRoutes = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (process.env.REACT_APP_HYBRID_MODE === '1') {
      dispatch(refreshJWTRequest())
    }
  }, [dispatch])

  return <RoutesWithGlobalLoader />
}

/**
 * Wraps all the UI-Routes with the Global loader
 * so they will show up when the App is ready.
 */
const RoutesWithGlobalLoader = withLoading(
  Loader.Global,
  LoadingBox
)(() => {
  return (
    <Switch>
      <PrivateRoute exact path={RoutePath.Home}>
        <Home />
      </PrivateRoute>
      <PrivateRoute exact path={RoutePath.Call}>
        <CallPage />
      </PrivateRoute>
      <PrivateRoute exact path={RoutePath.DirectCall}>
        <DirectCallPage />
      </PrivateRoute>
      <PrivateRoute exact path={RoutePath.Prejoin}>
        <PrejoinPage />
      </PrivateRoute>
      <RoomRoute exact path={[RoutePath.Room, RoutePath.Join]}></RoomRoute>
      {CUSTOM_ROUTES}
      <Route exact path={RoutePath.PasswordForgot}>
        <PasswordForgot />
      </Route>
      <Route exact path={RoutePath.DesignSystem}>
        <DesignSystemPage />
      </Route>
      <Route path={RoutePath.Broadcast}>
        <StreamingPage />
      </Route>
      <NotFoundRoute />
    </Switch>
  )
})

/**
 * CantinaRoutes is the App entrypoint where some Route
 * requires valid refresh_token cookie and others not.
 * Impersonate and AutoLogin won't dispatch a refreshJWTRequest
 * redux action but they verify the token with the backend.
 *
 * All the other Route instead will dispatch a refreshJWTRequest
 * and waits the Global loaded to be hidden.
 */
const CantinaRoutes = () => {
  return (
    <Switch>
      <LoginRoute exact path={RoutePath.Impersonate}>
        <ImpersonatePage />
      </LoginRoute>
      <LoginRoute exact path={RoutePath.AutoLogin}>
        <AutoLoginPage />
      </LoginRoute>
      <AutoLoginRoute exact path={RoutePath.AutoLoginNew} />

      <Route>
        <AutoRefreshTokenRoutes />
      </Route>
    </Switch>
  )
}

export default CantinaRoutes
