import React, { FC, ChangeEvent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CantinaState } from 'src/common/redux/interfaces'
import {
  getDefaultDropdownOptions,
  getCallLayoutDropdownOptions,
} from 'src/common/redux/features/layouts/layoutsSelectors'
import { loadDefaultLayouts } from 'src/common/redux/features/'
import {
  SelectGroup,
  SelectGroupProps,
} from 'src/components/SelectGroup/SelectGroup'
import { withActiveCall } from 'src/common/hoc'
import { TestId } from 'src/constants'

interface DefaultLayoutsDropdownProps extends SelectGroupProps {
  id?: string
  name: string
  label: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  selectedId: string
  placeholder?: string
  error?: string
  required?: boolean
  disabled?: boolean
}
export const DefaultLayoutsDropdown: FC<DefaultLayoutsDropdownProps> = (
  props
) => {
  const dispatch = useDispatch()
  const options = useSelector(getDefaultDropdownOptions)

  useEffect(() => {
    if (!Boolean(options?.length)) {
      dispatch(loadDefaultLayouts())
    }
  }, [dispatch, options])

  return (
    <SelectGroup
      {...props}
      options={options}
      testId={TestId.VideoLayoutSelectDefaultCanvas}
    />
  )
}

interface CallLayoutsDropdownProps extends SelectGroupProps {
  callId?: string
}
export const CallLayoutsDropdown: FC<CallLayoutsDropdownProps> = withActiveCall(
  ({ callId, ...props }) => {
    const options = useSelector((state: CantinaState) =>
      getCallLayoutDropdownOptions(state, callId)
    )
    return (
      <SelectGroup
        {...(props as SelectGroupProps)}
        options={options}
        testId={TestId.VideoLayoutSelectCallCanvas}
      />
    )
  }
)
