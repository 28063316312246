import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  getLobbyHeader,
  getLobbyFooter,
} from 'src/common/redux/features/settings/settingsSelectors'
import { TestId } from 'src/constants'
import { LobbyHeader } from 'src/components/Header/LobbyHeader'
import { RoomList } from 'src/components/Rooms/RoomList'
import { RenderHTML } from 'src/components/RenderHTML'
import { RoomSearch } from 'src/pages/Home/RoomSearch'
import { DevicesModal } from 'src/modals/DevicesModal'
import { MediaModal } from 'src/modals/MediaModal'

const HomePage: FC = () => {
  return (
    <>
      <LobbyHeader />
      <div className='sw-page-content' id='room-lazy-container'>
        <div className='h-full flex flex-col justify-start'>
          <CustomLobbyHeader />
          <RoomSearch />
          <div className='px-4'>
            <RoomList />
          </div>
          <CustomLobbyFooter />
        </div>
      </div>
      <DevicesModal testId={TestId.DevicesModalLobby} />
      <MediaModal testId={TestId.MediaModalLobby} />
    </>
  )
}

const CustomLobbyHeader = () => {
  const lobbyHeader = useSelector(getLobbyHeader)
  return <RenderHTML text={lobbyHeader} />
}

const CustomLobbyFooter = () => {
  const lobbyFooter = useSelector(getLobbyFooter)
  return <RenderHTML text={lobbyFooter} />
}

export default HomePage
