import React, { FC, useState, useEffect, useRef } from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { CommentsIcon, ImageIcon } from 'src/common/icons'
import { CantinaState } from 'src/common/redux/interfaces'
import { callViewActions } from 'src/common/redux/views'
import { withDisableChatGuard } from 'src/common/hoc'
import { extractImageURL } from 'src/common/services/helpers'
import { useThrottle } from 'src/common/hooks'
import { tr, Label } from 'src/common/i18n'
import { isChatShown } from 'src/common/redux/views/callView/callViewSelectors'
import { getMyParticipantId } from 'src/common/redux/features/calls/callSelectors'
import { getLatestUnreadMessage } from 'src/common/redux/features/messages/messageSelectors'
import { TestId } from 'src/constants'
import {
  withTextTooltip,
  TOOLTIP_OFFSET_Y_BOTTOM_BAR,
} from 'src/hoc/withTextTooltip'
import { NewMessageCountBadge } from '../Badges'
import { Button, ButtonWithTooltip } from '../Button/Button'

const CHAT_TOOLTIP_DURATION = 2500 // milliseconds
const CHAT_DEBOUNCE_DURATION = 2000 // milliseconds

const style = {
  '--fa-primary-color': 'var(--sw-red)',
  '--fa-secondary-color': 'var(--sw-blue)',
  '--fa-primary-opacity': 1,
  '--fa-secondary-opacity': 1,
}

type ChatButtonProps = {
  className?: string
  clickHandler?: () => void
  showLabel?: boolean
  showTooltip?: boolean
}

const ChatButton: FC<ChatButtonProps> = ({
  className = '',
  clickHandler,
  showLabel,
  showTooltip,
}) => {
  const dispatch = useDispatch()
  const isShown = useSelector(isChatShown)
  const buttonClasses = isShown ? 'active' : ''
  const buttonClickHandler = () => {
    if (clickHandler) {
      clickHandler()
    }
    dispatch(callViewActions.toggleChat())
  }

  // Button with label and no tooltip
  if (showLabel) {
    return (
      <Button
        className={`relative sw-btn-icon-toggle ${buttonClasses} ${className}`}
        onClick={buttonClickHandler}
        testId={TestId.BottomBarButtonChat}
      >
        {/* @ts-ignore */}
        <CommentsIcon size='lg' fixedWidth style={style} />
        {!isShown && <NewMessageCountBadge />}
        <span className='more-menu-item-label'>{tr(Label.CHAT)}</span>
      </Button>
    )
  }

  // Button without tooltip is used when the chat preview tooltip is being shown
  // to avoid showing both tooltips at once
  return showTooltip ? (
    <ButtonWithTooltip
      ariaLabel={tr(Label.CHAT)}
      className={`relative sw-btn-icon-toggle ${buttonClasses} ${className}`}
      offsetY={TOOLTIP_OFFSET_Y_BOTTOM_BAR}
      onClick={buttonClickHandler}
      testId={TestId.BottomBarButtonChat}
      titleSecondary='O'
    >
      {/* @ts-ignore */}
      <CommentsIcon size='lg' fixedWidth style={style} />
      {!isShown && <NewMessageCountBadge />}
    </ButtonWithTooltip>
  ) : (
    <Button
      ariaLabel={tr(Label.CHAT)}
      className={`relative sw-btn-icon-toggle ${buttonClasses} ${className}`}
      onClick={buttonClickHandler}
      testId={TestId.BottomBarButtonChat}
    >
      {/* @ts-ignore */}
      <CommentsIcon size='lg' fixedWidth style={style} />
      {!isShown && <NewMessageCountBadge />}
    </Button>
  )
}

const PreviewChatButton = (props: ChatButtonProps) => {
  const [showMessage, setShowMessage] = useState(false)
  const drawerIsShown = useSelector(isChatShown)
  const participantId = useSelector(getMyParticipantId)
  const message = useSelector((state: CantinaState) =>
    getLatestUnreadMessage(state)
  )
  const debouncedMessage = useThrottle(message, CHAT_DEBOUNCE_DURATION)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (
      debouncedMessage?.content &&
      debouncedMessage?.participantId !== participantId
    ) {
      setShowMessage(true)
      timer.current && clearTimeout(timer.current)

      timer.current = setTimeout(() => {
        setShowMessage(false)
        timer.current = undefined
      }, CHAT_TOOLTIP_DURATION)
    } else {
      setShowMessage(false)
    }

    return () => {
      timer.current && clearTimeout(timer.current)
    }
  }, [debouncedMessage, participantId])

  const imageUrl = extractImageURL(debouncedMessage?.content || '')
  const contentTitle =
    debouncedMessage?.participantName && `${debouncedMessage.participantName}:`
  const showMessageTooltip =
    !drawerIsShown && showMessage && !!debouncedMessage?.content

  const contentOverride = imageUrl && (
    <div className='tooltip-title'>
      {contentTitle}
      <span className='font-medium pl-3'>
        <ImageIcon size='lg' fixedWidth className='pr-1' />
        {tr(Label.IMAGE).toLowerCase()}
      </span>
    </div>
  )

  // This adds the chat preview tooltip
  return withTextTooltip({
    content: contentOverride,
    title: contentTitle,
    titleSecondary: debouncedMessage?.content,
    className: 'tooltip-theme tooltip-chat',
    placement: 'top-end',
    controlled: true,
    controlledShow: showMessageTooltip,
    offsetY: TOOLTIP_OFFSET_Y_BOTTOM_BAR,
  })(ChatButton)({ showTooltip: !showMessageTooltip, ...props })
}

// Standard chat button with preview tooltip
const InCallChatButton: FC<ChatButtonProps> =
  compose(withDisableChatGuard)(PreviewChatButton)

// Chat button without preview tooltip
const InCallChatButtonSimple: FC<ChatButtonProps> =
  compose(withDisableChatGuard)(ChatButton)

export { InCallChatButton, InCallChatButtonSimple }
