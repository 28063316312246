import React from 'react'
import TooltipTrigger from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'
import { useSelector } from 'react-redux'
import { getTheme } from 'src/common/redux/features/ui/uiSelectors'
import { InfoCircleIcon } from 'src/common/icons'

/**
 * For now the trigger is always hidden but visible from md:
 * We can build different Trigger/Tooltip components to target specific cases
 */
const Trigger =
  (children: React.ReactNode, tooltipTriggerClassName = '') =>
  ({ triggerRef, getTriggerProps }: any) =>
    (
      <span
        {...getTriggerProps({
          className: `tooltip-trigger ${tooltipTriggerClassName}`,
          ref: triggerRef,
        })}
      >
        {children}
      </span>
    )

const Tooltip =
  ({
    contentAreaClassName = 'block-primary overflow-y-auto rounded-md',
    headingText,
    hideArrow = false,
    theme = '',
    tooltip,
    tooltipClassName = '',
  }: any) =>
  ({ arrowRef, getArrowProps, getTooltipProps, placement, tooltipRef }: any) =>
    (
      <div
        {...getTooltipProps({
          className: `tooltip-container sw-theme-${theme} ${tooltipClassName}`,
          ref: tooltipRef,
        })}
      >
        {!hideArrow && (
          <div
            {...getArrowProps({
              className: 'tooltip-arrow',
              'data-placement': placement,
              ref: arrowRef,
            })}
          />
        )}
        <div className={`${contentAreaClassName}`}>
          {headingText && (
            <div className='text-base text-contrast-h'>
              {headingText}
              <InfoCircleIcon className='ml-2' />
            </div>
          )}
          {tooltip}
        </div>
      </div>
    )

export const BasicTooltip = ({
  children,
  contentAreaClassName = 'block-primary overflow-y-auto rounded-md',
  headingText = '',
  hideArrow = false,
  tooltip,
  tooltipClassName = '',
  tooltipTriggerClassName = '',
  ...props
}: any) => {
  const theme = useSelector(getTheme)
  return (
    <TooltipTrigger
      {...props}
      tooltip={Tooltip({
        headingText,
        hideArrow,
        contentAreaClassName,
        theme,
        tooltip,
        tooltipClassName,
      })}
    >
      {Trigger(children, tooltipTriggerClassName)}
    </TooltipTrigger>
  )
}
