import { RestMethodParams, Settings } from './interfaces'
import { cantinaFetch } from '.'

export interface UpdateSettingsMethodParams extends RestMethodParams {
  params: Partial<Settings>
}

export const fetchSettings = ({
  baseUrl,
  token,
}: RestMethodParams): Promise<Settings> => {
  return cantinaFetch(baseUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const updateSettings = ({
  baseUrl,
  token,
  params,
}: UpdateSettingsMethodParams): Promise<Settings> => {
  return cantinaFetch(baseUrl, {
    body: JSON.stringify(params),
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}
