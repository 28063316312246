import React, { FC, useEffect } from 'react'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { UserLookupResponse } from 'src/common/rest'
import { RoutePath } from 'src/common/constants'
import { useCantinaId } from 'src/common/hooks'
import { uiActions } from 'src/common/redux/features'

import { CenteredBox } from 'src/layout'
import { GuestProvider } from './GuestProvider'
import { LocalProvider } from './LocalProvider'
import { UserLookup } from './UserLookup'
import { Header } from 'src/components/Header/Header'

export const Login: FC = () => {
  return (
    <>
      <Header />
      <CenteredBox>
        <AuthContainer />
      </CenteredBox>
    </>
  )
}

const AuthContainer = () => {
  const cantinaId = useCantinaId()
  const dispatch = useDispatch()
  const { state } = useLocation<{ room?: string } & UserLookupResponse>()

  useEffect(() => {
    if (state && state.room) {
      console.log('AutoDial From Login', state.room)
      dispatch(uiActions.setAutoDialExtension(state.room))
    }
  }, [dispatch, state])

  return (
    <Switch>
      <Route exact path={RoutePath.Login}>
        <UserLookup cantinaId={cantinaId} />
      </Route>
      <Route exact path={RoutePath.LoginLocal}>
        {state ? (
          <LocalProvider cantinaId={cantinaId} {...state} />
        ) : (
          <Redirect to={RoutePath.Login} />
        )}
      </Route>
      <Route exact path={RoutePath.LoginGuest}>
        {state ? (
          <GuestProvider cantinaId={cantinaId} {...state} />
        ) : (
          <Redirect to={RoutePath.Login} />
        )}
      </Route>
      <Redirect to={RoutePath.Login} />
    </Switch>
  )
}
