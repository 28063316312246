import { MediaDevice } from '../../../constants'
import { CantinaState } from '../../interfaces'

export const getDevices = ({ device }: CantinaState) => {
  const {
    cameraId,
    cameraLabel,
    microphoneId,
    microphoneLabel,
    speakerId,
    speakerLabel,
  } = device
  return {
    cameraId,
    cameraLabel,
    microphoneId,
    microphoneLabel,
    speakerId,
    speakerLabel,
  }
}

export const getCameraId = ({ device }: CantinaState) => {
  return device.cameraId || ''
}

export const getCameraLabel = ({ device }: CantinaState) => {
  return device.cameraLabel || ''
}

export const getMicrophoneId = ({ device }: CantinaState) => {
  return device.microphoneId || ''
}

export const getMicrophoneLabel = ({ device }: CantinaState) => {
  return device.microphoneLabel || ''
}

export const getSpeakerId = ({ device }: CantinaState) => {
  return device.speakerId || ''
}

export const getSpeakerLabel = ({ device }: CantinaState) => {
  return device.speakerLabel || ''
}

export const getCurrentCameraId = ({ device }: CantinaState) => {
  return device.previewCameraId || device.cameraId
}

export const getCurrentCameraLabel = ({ device }: CantinaState) => {
  if (device.previewCameraId === MediaDevice.None) {
    return ''
  }
  return device.previewCameraLabel || device.cameraLabel
}

export const getCurrentMicrophoneId = ({ device }: CantinaState) => {
  return device.previewMicrophoneId || device.microphoneId
}

export const getCurrentMicrophoneLabel = ({ device }: CantinaState) => {
  if (device.previewMicrophoneId === MediaDevice.None) {
    return ''
  }
  return device.previewMicrophoneLabel || device.microphoneLabel
}

export const getCurrentSpeakerId = ({ device }: CantinaState) => {
  return device.previewSpeakerId || device.speakerId
}

export const getCurrentSpeakerLabel = ({ device }: CantinaState) => {
  if (device.previewSpeakerId === MediaDevice.None) {
    return ''
  }
  return device.previewSpeakerLabel || device.speakerLabel
}

export const hasVideoEnabled = ({ device }: CantinaState) => {
  return device.cameraId !== MediaDevice.None
}

export const hasAudioEnabled = ({ device }: CantinaState) => {
  return device.microphoneId !== MediaDevice.None
}
