import React, { memo, FC, useEffect, ComponentType } from 'react'
import { Switch, Route, useRouteMatch, Redirect, Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { tr, Label } from 'src/common/i18n'
import { RoutePath } from 'src/common/constants'
import {
  ChevronRightIcon,
  CogIcon,
  EditIcon,
  EyeIcon,
  KeyIcon,
  LockIcon,
  MeetingIcon,
  PaletteIcon,
  SWLogoIcon,
} from 'src/common/icons'
import { useFetchRoom, UseFetchRoomConsumerProps } from 'src/common/hooks'
import { Room } from 'src/common/rest'
import { dateFormatDistance } from 'src/services/Helpers'
import { useDecodedParams } from 'src/effects/useDecodedParams'
import { RequestStateSwitcher } from '../RequestStateSwitcher'
import { RoomBasicInfo } from './RoomBasicInfo'
import { RoomSecurity } from './RoomSecurity'
import { RoomVisibility } from './RoomVisibility'
import { Options } from './RoomOptions'
import { RoomTheme } from './RoomTheme'
import { RoomAppearance } from '../Appearance'
import { RoomSignalWire } from './RoomSignalWire'
import { RoomPermissions } from './RoomPermissions'
import { canManageRoom } from '../utils'

export type RoomComponentProps = UseFetchRoomConsumerProps & {
  room: Room
}

type TabProps = {
  path: RoutePath
  label: string
  Component: ComponentType<RoomComponentProps>
  IconComponent: ComponentType<any>
  iconClass?: string // useful for custom svgs
}

const TABS: TabProps[] = [
  {
    path: RoutePath.SettingsRoomBasicInfo,
    label: Label.BASIC_INFO,
    Component: RoomBasicInfo,
    IconComponent: EditIcon,
  },
  {
    path: RoutePath.SettingsRoomVisibility,
    label: Label.ROOM_VISIBILITY,
    Component: RoomVisibility,
    IconComponent: EyeIcon,
  },
  {
    path: RoutePath.SettingsRoomSecurity,
    label: Label.ROOM_SECURITY,
    Component: RoomSecurity,
    IconComponent: LockIcon,
  },
  {
    path: RoutePath.SettingsRoomSignalWire,
    label: Label.SIGNALWIRE_INTEGRATION,
    Component: RoomSignalWire,
    IconComponent: SWLogoIcon,
    iconClass: 'fa-w-14 fa-fw fill-current',
  },
  {
    path: RoutePath.SettingsRoomOptions,
    label: Label.ROOM_OPTIONS,
    Component: Options,
    IconComponent: MeetingIcon,
  },
  {
    path: RoutePath.SettingsRoomThemes,
    label: Label.ROOM_CUSTOMIZE_THEME,
    Component: RoomTheme,
    IconComponent: CogIcon,
  },
  {
    path: RoutePath.SettingsRoomAppearance,
    label: Label.APPEARANCE,
    Component: RoomAppearance,
    IconComponent: PaletteIcon,
  },
  {
    path: RoutePath.SettingsRoomPermissions,
    label: Label.PERMISSIONS,
    Component: RoomPermissions,
    IconComponent: KeyIcon,
  },
]

export const RoomDetail: FC = () => {
  const history = useHistory()
  const { roomId } = useDecodedParams<{ roomId: string }>()
  const { room, requestState, error, getErrorByName, refresh, update } =
    useFetchRoom(roomId)

  useEffect(() => {
    if (room) {
      const hasPermission = canManageRoom(room?.my_roles)
      if (!hasPermission) {
        history.push(RoutePath.SettingsRoomList)
      }
    }
  }, [history, room])

  return (
    <>
      <div className='administration-section-title'>
        <div className='text-contrast-h text-xl'>
          <Link
            to={RoutePath.SettingsRoomList}
            title={tr(Label.ROOMS)}
            className='pr-2'
          >
            {tr(Label.ROOMS)}
          </Link>
          <ChevronRightIcon size='sm' />{' '}
          <span className='font-bold'>{room?.name}</span>
        </div>
      </div>
      <div className='block-primary flex flex-row administration-section-content'>
        <RequestStateSwitcher
          requestState={requestState}
          refresh={refresh}
          error={error}
        >
          <div className='w-1/3'>
            <div className='administration-section-tabs-wrapper divide-y divide-contrast-l'>
              {TABS.map(({ path, label, IconComponent, iconClass }) => {
                const navigateTo = path.replace(':roomId', roomId)
                return (
                  <Tab
                    key={path}
                    path={path}
                    navigateTo={navigateTo}
                    label={label}
                    IconComponent={IconComponent}
                    iconClass={iconClass}
                  />
                )
              })}
            </div>
          </div>
          <div className='relative w-3/4 pl-8'>
            <RoomLastEdited updateAt={room?.updated_at} />
            <Switch>
              {TABS.map(({ path, Component }) => {
                return (
                  <Route key={path} exact path={path}>
                    <Component
                      // @ts-expect-error
                      room={room}
                      update={update}
                      getErrorByName={getErrorByName}
                    />
                  </Route>
                )
              })}
              <Redirect to={RoutePath.SettingsRoomBasicInfo} />
            </Switch>
          </div>
        </RequestStateSwitcher>
      </div>
    </>
  )
}

const Tab: FC<Omit<TabProps, 'Component'> & { navigateTo: string }> = memo(
  ({ path, label, navigateTo, IconComponent, iconClass }) => {
    const match = useRouteMatch({ path, strict: true, sensitive: true })
    const className = match ? 'text-sw-selected border-sw-selected' : ''

    return (
      <Link to={navigateTo} className='block-primary-tint block w-full'>
        <label className={`administration-section-tab-label ${className}`}>
          <IconComponent
            fixedWidth
            className={`mr-2 ${match ? '' : 'opacity-50'} ${iconClass}`}
          />
          {tr(label)}
        </label>
      </Link>
    )
  }
)

const RoomLastEdited = ({ updateAt }: { updateAt?: string }) => {
  if (!updateAt) {
    return null
  }
  const updatedAt = new Date(updateAt)
  return (
    <span className='absolute top-0 right-0 pr-8 text-sm text-contrast-m'>
      Last edited {dateFormatDistance(updatedAt)}
    </span>
  )
}
