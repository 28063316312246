import React, { FC, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { withActiveCall, withLoading } from 'src/common/hoc'
import { CantinaState } from 'src/common/redux/interfaces'
import {
  isReceivingVideo,
  isSendingVideo,
} from 'src/common/redux/features/calls/callSelectors'
import DirectCallHeader from 'src/components/Header/DirectCallHeader'
import { DirectCallButtons } from 'src/components/CallButtons/DirectCallButtons'
import { MCU } from 'src/components/Mcu/MCU'
import { DraggableLocalVideo } from '../Call/LocalVideo'
// import DialpadModal from 'src/modals/DialpadModal'
import { Loader, RoutePath } from 'src/common/constants'
import CallLoading from 'src/components/CallLoading'
import { Redirect } from 'react-router'

type VideoWrapperProps = {
  callId: string
}

const VideoWrapper: FC<VideoWrapperProps> = ({ callId }) => {
  const receivingVideo = useSelector((state: CantinaState) =>
    isReceivingVideo(state, callId)
  )
  const sendingVideo = useSelector((state: CantinaState) =>
    isSendingVideo(state, callId)
  )

  return (
    <div className='call-mcu-area relative flex items-center overflow-hidden'>
      <div className='aspect-ratio-16-9-wrapper'>
        <div className='aspect-ratio-16-9-content'>
          {receivingVideo && <MCU callId={callId} />}
          {!receivingVideo && (
            <video
              className='absolute w-full h-full'
              muted
              autoPlay
              loop
              poster='https://picsum.photos/1280/720'
            ></video>
          )}
        </div>
      </div>
      {sendingVideo && <DraggableLocalVideo callId={callId} />}
    </div>
  )
}

const CallContent: FC<{ callId: string }> = ({ callId }) => {
  return (
    <div className='relative flex flex-1 w-full overflow-hidden'>
      <div className='flex flex-col flex-1 max-w-full'>
        <VideoWrapper callId={callId} />
        <DirectCallButtons />
      </div>
    </div>
  )
}

const RedirectToHome = () => <Redirect to={RoutePath.Home} />

const DirectCall: FC<{ callId: string }> = ({ callId }) => {
  console.log('Render DirectCall')

  return (
    <Fragment>
      <DirectCallHeader />
      <CallContent callId={callId} />
      {/* <DialpadModal /> */}
    </Fragment>
  )
}

const DirectCallWithLoading = withLoading(Loader.Call, CallLoading)(DirectCall)

export default withActiveCall(DirectCallWithLoading, RedirectToHome)
