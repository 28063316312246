import { createSelector } from 'reselect'
import { RoomRole } from '../../../constants'
import { RoomTemplate } from '../../../rest'
import { CantinaState } from '../../interfaces'
import {
  getDefaultRoomTemplateId,
  getUpdatedAt,
} from '../settings/settingsSelectors'

const roomTemplateId = (_: CantinaState, roomTemplateId: string) =>
  roomTemplateId

export const getRoomTemplateIds = ({ roomTemplates }: CantinaState) => {
  return Object.keys(roomTemplates.byId)
}

const getRoomTemplateById = ({ roomTemplates }: CantinaState) => {
  return roomTemplates.byId
}

// prettier-ignore
const getRoomTemplate = createSelector(
  getRoomTemplateById,
  roomTemplateId,
  (roomTemplates, roomTemplateId) => {
  return roomTemplates[roomTemplateId] || null
  }
)

const getDefaultRoomTemplate = createSelector(
  getRoomTemplateById,
  getDefaultRoomTemplateId,
  (roomTemplates, roomTemplateId) => {
    if (roomTemplateId) {
      return roomTemplates[roomTemplateId] || null
    }
    return null
  }
)

/**
 * Returns a selector based on the "key" param
 */
export const makeDefaultRoomTemplateKeySelector = (key: keyof RoomTemplate) => {
  return createSelector(
    getDefaultRoomTemplate,
    (roomTemplate) => roomTemplate?.[key]
  )
}

export const hasRoomTemplates = createSelector(
  getRoomTemplateIds,
  (roomTemplateIds) => {
    return roomTemplateIds?.length > 0
  }
)

export const getGlobalLastUpdatedAt = createSelector(
  getUpdatedAt,
  makeDefaultRoomTemplateKeySelector('updated_at'),
  (settingsUpdatedAt, defaultRoomTemplateUpdatedAt) => {
    const settingsDate = new Date(settingsUpdatedAt as string)
    const defaultRoomTemplateDate = new Date(
      defaultRoomTemplateUpdatedAt as string
    )
    return settingsDate > defaultRoomTemplateDate
      ? settingsDate
      : defaultRoomTemplateDate
  }
)

export const getRoomTemplateMyRoles = createSelector(
  getRoomTemplate,
  (roomTemplate) => roomTemplate?.my_roles || []
)

export const canCreateAdHocRooms = createSelector(
  makeDefaultRoomTemplateKeySelector('my_roles'),
  (defaultRoomTemplateMyRoles) => {
    if (Array.isArray(defaultRoomTemplateMyRoles)) {
      return (
        defaultRoomTemplateMyRoles.includes(RoomRole.Manager) ||
        defaultRoomTemplateMyRoles.includes(RoomRole.Moderator) ||
        defaultRoomTemplateMyRoles.includes(RoomRole.Attendee)
      )
    }

    return false
  }
)
