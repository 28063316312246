import { MediaDevice } from '../constants'
import {
  checkVideoPermissions,
  checkAudioPermissions,
  validateCamera,
  getCameras,
  validateMicrophone,
  getMicrophones,
  validateSpeaker,
  getSpeakers,
} from './relay'

const getDefaultMediaDevice = (deviceLength: number) => {
  return deviceLength > 0
    ? { deviceId: MediaDevice.Default, label: 'OS Default' }
    : { deviceId: MediaDevice.None, label: undefined }
}

export const checkCamera = async (cameraId: string, cameraLabel: string) => {
  try {
    const hasPerms = await checkVideoPermissions()
    if (!hasPerms) {
      return { deviceId: MediaDevice.None, label: undefined }
    }
    const newDeviceId = await validateCamera(cameraId, cameraLabel)
    if (!newDeviceId) {
      const cameras = await getCameras()
      return getDefaultMediaDevice(cameras.length)
    } else if (newDeviceId && newDeviceId !== cameraId) {
      return { deviceId: newDeviceId, label: cameraLabel }
    }
    return { deviceId: cameraId, label: cameraLabel }
  } catch (error) {
    console.error('checkCamera error', error)
  }
}

export const checkMicrophone = async (
  microphoneId: string,
  microphoneLabel: string
) => {
  try {
    const hasPerms = await checkAudioPermissions()
    if (!hasPerms) {
      return { deviceId: MediaDevice.None, label: undefined }
    }
    const newDeviceId = await validateMicrophone(microphoneId, microphoneLabel)
    if (!newDeviceId) {
      const microphones = await getMicrophones()
      return getDefaultMediaDevice(microphones.length)
    } else if (newDeviceId && newDeviceId !== microphoneId) {
      return { deviceId: newDeviceId, label: microphoneLabel }
    }
    return { deviceId: microphoneId, label: microphoneLabel }
  } catch (error) {
    console.error('checkMicrophone error', error)
  }
}

export const checkSpeaker = async (speakerId: string, speakerLabel: string) => {
  try {
    const hasPerms = await checkAudioPermissions()
    if (!hasPerms) {
      return { deviceId: MediaDevice.None, label: undefined }
    }
    const newDeviceId = await validateSpeaker(speakerId, speakerLabel)
    if (!newDeviceId) {
      const speakers = await getSpeakers()
      return getDefaultMediaDevice(speakers.length)
    } else if (newDeviceId && newDeviceId !== speakerId) {
      return { deviceId: newDeviceId, label: speakerLabel }
    }
    return { deviceId: speakerId, label: speakerLabel }
  } catch (error) {
    console.error('checkSpeaker error', error)
  }
}
