import { useState } from 'react'
import { RailsEndpoint, RequestState } from '../rest'
import { useInternalAPI } from './useInternalAPI'

type UseDeleteClipeezeData = {
  error: string | null
  requestState: RequestState
}

const DEFAULT_DATA: UseDeleteClipeezeData = {
  error: null,
  requestState: RequestState.Loading,
}

const deleteOptions = {
  endpoint: RailsEndpoint.Clipeeze,
  method: 'DELETE',
}

export const useDeleteClipeeze = (clipId: string) => {
  const { invokeAPI: deleteHandler } = useInternalAPI(deleteOptions)
  const [data, setData] = useState<UseDeleteClipeezeData>(DEFAULT_DATA)

  // delete is a private keyword in strict mode
  const performDelete = async () => {
    try {
      setData(DEFAULT_DATA)
      await deleteHandler({
        urlTransform: (url) => {
          return `${url}/${clipId}`
        },
      })
      setData({
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      setData({
        requestState: RequestState.Error,
        error: 'Error deleting ClipEEze',
      })
    }
  }

  return { ...data, deleteClipeeze: performDelete }
}
