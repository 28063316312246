import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { RoomListCommandPayload } from '../redux/interfaces'
import { roomListCommand } from '../redux/features'

export const useDispatchRoomListCommand = () => {
  const dispatch = useDispatch()
  const dispatchRoomListCommand: (params: RoomListCommandPayload) => void =
    useCallback(
      (params: RoomListCommandPayload) => {
        dispatch(roomListCommand(params))
      },
      [dispatch]
    )

  return dispatchRoomListCommand
}
