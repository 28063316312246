// import { createSelector } from 'reselect'
import { CantinaState } from '../../../redux/interfaces'

export const isVisible = ({ promptView }: CantinaState) => {
  return promptView.isVisible
}

export const hasError = ({ promptView }: CantinaState) => {
  return promptView.error
}

export const getPromptOptions = ({ promptView }: CantinaState) => {
  return {
    type: promptView.promptType,
    regex: promptView.regex,
    title: promptView.title,
  }
}
