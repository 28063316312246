import React, { FC } from 'react'
import { CenteredBox } from 'src/layout'
import { SimpleHeaderWithoutLink } from 'src/components/Header/SimpleHeader'

const ServerError = () => (
  <svg
    className='mx-auto server-error-svg'
    xmlns='http://www.w3.org/2000/svg'
    width='181.461'
    height='141.967'
    viewBox='0 0 181.461 141.967'
  >
    <g transform='translate(-254.5 -409.533)'>
      <rect
        className='a'
        width='129.401'
        height='27.558'
        rx='2'
        transform='translate(285 432)'
      />
      <line className='a' x2='27' transform='translate(295.7 442.5)' />
      <line className='a' x2='12' transform='translate(328.7 442.5)' />
      <line className='a' x2='15.5' transform='translate(295.7 449.5)' />
      <line className='a' x2='17' transform='translate(316.7 449.5)' />
      <circle
        className='a'
        cx='6.875'
        cy='6.875'
        r='6.875'
        transform='translate(389.95 438.904)'
      />
      <circle
        className='a'
        cx='3.336'
        cy='3.336'
        r='3.336'
        transform='translate(393.489 442.443)'
      />
      <rect
        className='a'
        width='129.401'
        height='27.558'
        rx='2'
        transform='translate(285 467)'
      />
      <line className='a' x2='21.18' transform='translate(295.7 477.5)' />
      <line className='a' x2='15.913' transform='translate(321.587 477.5)' />
      <line className='a' x2='25.8' transform='translate(295.7 484.5)' />
      <line className='a' x2='9.2' transform='translate(327.5 484.5)' />
      <circle
        className='a'
        cx='6.875'
        cy='6.875'
        r='6.875'
        transform='translate(389.95 473.904)'
      />
      <circle
        className='a'
        cx='3.336'
        cy='3.336'
        r='3.336'
        transform='translate(393.489 477.443)'
      />
      <rect
        className='a'
        width='129.401'
        height='27.558'
        rx='2'
        transform='translate(285 502)'
      />
      <line className='a' x2='27' transform='translate(295.7 512.5)' />
      <line className='a' x2='12' transform='translate(328.7 512.5)' />
      <line className='a' x2='15.5' transform='translate(295.7 519.5)' />
      <line className='a' x2='17' transform='translate(316.7 519.5)' />
      <circle
        className='a'
        cx='6.875'
        cy='6.875'
        r='6.875'
        transform='translate(389.95 508.904)'
      />
      <circle
        className='a'
        cx='3.336'
        cy='3.336'
        r='3.336'
        transform='translate(393.489 512.443)'
      />
      <circle
        className='a'
        cx='2.5'
        cy='2.5'
        r='2.5'
        transform='translate(376.5 478.5)'
      />
      <circle
        className='a'
        cx='2.5'
        cy='2.5'
        r='2.5'
        transform='translate(365 478.5)'
      />
      <circle
        className='a'
        cx='2.5'
        cy='2.5'
        r='2.5'
        transform='translate(353.5 478.5)'
      />
      <circle
        className='a'
        cx='2.5'
        cy='2.5'
        r='2.5'
        transform='translate(376.5 513.279)'
      />
      <circle
        className='a'
        cx='2.5'
        cy='2.5'
        r='2.5'
        transform='translate(365 513.279)'
      />
      <circle
        className='a'
        cx='2.5'
        cy='2.5'
        r='2.5'
        transform='translate(376.5 443.5)'
      />
      <circle
        className='a'
        cx='2.5'
        cy='2.5'
        r='2.5'
        transform='translate(365 443.5)'
      />
      <path className='a' d='M414.4,523h15.1v19.5h-40' />
      <path
        className='a'
        d='M.393,0H2.5A7.5,7.5,0,0,1,10,7.5v0A7.5,7.5,0,0,1,2.5,15H.393A.393.393,0,0,1,0,14.607V.393A.393.393,0,0,1,.393,0Z'
        transform='translate(379.5 535.5)'
      />
      <path
        className='a'
        d='M1.5,0H7A0,0,0,0,1,7,0V3A0,0,0,0,1,7,3H1.5A1.5,1.5,0,0,1,0,1.5v0A1.5,1.5,0,0,1,1.5,0Z'
        transform='translate(372.5 538.5)'
      />
      <path
        className='a'
        d='M1.5,0H7A0,0,0,0,1,7,0V3A0,0,0,0,1,7,3H1.5A1.5,1.5,0,0,1,0,1.5v0A1.5,1.5,0,0,1,1.5,0Z'
        transform='translate(372.5 544.5)'
      />
      <line className='a' x2='16' transform='translate(269.5 515.5)' />
      <line className='a' x2='8.5' transform='translate(254.5 515.5)' />
      <line className='a' x2='7.5' transform='translate(414 515.5)' />
      <line className='a' x2='10.5' transform='translate(425 515.5)' />
      <path
        className='a'
        d='M425.2,410.533h5.775a.215.215,0,0,1,.205.281l-2.4,7.5H434.9a.047.047,0,0,1,.037.077l-10.753,13.143a.136.136,0,0,1-.237-.119l2.342-9.368h-5.106a.129.129,0,0,1-.122-.172l3.933-11.2A.216.216,0,0,1,425.2,410.533Z'
      />
      <path
        className='a'
        d='M273.8,410.533h-5.775a.215.215,0,0,0-.2.281l2.4,7.5H264.1a.047.047,0,0,0-.037.077l10.753,13.143a.136.136,0,0,0,.237-.119l-2.342-9.368h5.106a.129.129,0,0,0,.122-.172l-3.933-11.2A.216.216,0,0,0,273.8,410.533Z'
      />
    </g>
  </svg>
)

type ConnectionErrorBoxProps = {
  text: string
  message: string
}
const ConnectionErrorBox: FC<ConnectionErrorBoxProps> = ({ text, message }) => (
  <div className='app-root sw-theme-default'>
    <div className='app-main-content'>
      <SimpleHeaderWithoutLink />
      <CenteredBox>
        <div className='text-center mb-6'>
          <ServerError />
          <div className='text-center text-contrast-h text-4xl font-neue mt-12'>
            {text}
          </div>
          <div className='text-contrast-m m-auto'>{message}</div>
        </div>
      </CenteredBox>
    </div>
  </div>
)

export const ConnectionClosed = () => (
  <ConnectionErrorBox
    text='Connection Closed'
    message='The connection with the server has been closed.'
  />
)

export const ConnectionError = () => (
  <ConnectionErrorBox
    text='Reconnecting...'
    message='Please wait! We are trying to reconnect.'
  />
)
