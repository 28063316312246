import { createSelector } from 'reselect'
import { CantinaState } from '../../interfaces'
import { Displayable, Theme } from '../../../constants'

const getIsVisibleMap = ({ ui }: CantinaState) => {
  return ui.isVisible || {}
}

const makeIsVisibleSelector = (key: Displayable) => {
  return createSelector(getIsVisibleMap, (isVisible) => Boolean(isVisible[key]))
}

const hasInvitations = ({ roomZones }: CantinaState) => {
  return roomZones.zoneInvitationList?.length
}

export const isSettingsModalVisible = makeIsVisibleSelector(
  Displayable.SettingsModal
)
export const isThemesModalVisible = makeIsVisibleSelector(
  Displayable.ThemesModal
)
export const isKnockingVisible = makeIsVisibleSelector(Displayable.Knocking)
export const isAppMenuVisible = makeIsVisibleSelector(Displayable.AppMenu)
export const isAboutModalVisible = makeIsVisibleSelector(Displayable.AboutModal)
export const isDialpadModalVisible = makeIsVisibleSelector(
  Displayable.DialpadModal
)
export const isSecondSourceModalVisible = makeIsVisibleSelector(
  Displayable.SecondSourceModal
)
export const isDevicesModalVisible = makeIsVisibleSelector(
  Displayable.DevicesModal
)
export const isMediaModalVisible = makeIsVisibleSelector(Displayable.MediaModal)
export const isProfileModalVisible = makeIsVisibleSelector(
  Displayable.ProfileModal
)
export const isSidebarStartModalVisible = makeIsVisibleSelector(
  Displayable.SidebarStartModal
)
export const isSidebarEditModalVisible = makeIsVisibleSelector(
  Displayable.SidebarEditModal
)
export const isSidebarRequestToJoinModalVisible = makeIsVisibleSelector(
  Displayable.SidebarRequestToJoinModal
)
// TODO - SIDEBARS: Depending on UX decisions, we might want to change this
export const isSidebarInvitationModalVisible = createSelector(
  getIsVisibleMap,
  hasInvitations,
  (isVisible, hasInvitations) =>
    hasInvitations && Boolean(isVisible[Displayable.SidebarInvitationModal])
)

export const getSocketStatus = ({ ui }: CantinaState) => {
  return ui.socketStatus
}

export const getTheme = ({ ui }: CantinaState) => {
  return ui.theme || Theme.Default
}

export const getMyDevicesWarningDone = ({ ui }: CantinaState) => {
  return ui.myDevicesWarningDone || false
}

export const hasSkipPrejoinPage = ({ ui }: CantinaState) => {
  // Force the preJoin page the first time.
  if (ui?.prejoinPageViewed === false) {
    return false
  }
  // Otherwise follow user's choice
  return ui.skipPrejoinPage || false
}

export const hasFlipLocalVideo = ({ ui }: CantinaState) => {
  return ui.flipLocalVideo || false
}

export const getAutoDialExtension = ({ ui }: CantinaState) => {
  const { autoDialExtension = null, autoDialExpiresAt = 0 } = ui
  if (autoDialExtension && autoDialExpiresAt >= Date.now()) {
    return autoDialExtension
  }
  return null
}
