import React, { FC } from 'react'
import { CenteredMessage } from 'src/layout/CenteredMessage'
import { tr, Label } from 'src/common/i18n'

export const NoRooms: FC = () => {
  return (
    <CenteredMessage>
      <div className='text-contrast-h font-bold text-2xl'>
        {tr(Label.NO_ROOMS_AVAILABLE)}
      </div>
      <div className='text-contrast-m p-8'>{tr(Label.NO_ROOMS_INSTRUX)}</div>
    </CenteredMessage>
  )
}
