import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { CantinaState, Clipeeze, PaginationLinks } from '../redux/interfaces'
import { getAuthToken } from '../redux/features/auth/authSelectors'
import { ClipeezeStatus } from '../constants'
import {
  fetchClipeezeList,
  RailsEndpoint,
  RequestState,
  SortColumn,
  SortOrder,
} from '../rest'
import {
  getInternalAPIEndpoint,
  getInternalAPIHost,
} from '../redux/features/settings/settingsSelectors'

interface useFetchClipeezeData {
  clipeezeList: Clipeeze[] | []
  clipeezeLinks: PaginationLinks
  requestState: RequestState | null
  error: string | null
  endOfList: boolean
}

const DEFAULT_DATA: useFetchClipeezeData = {
  clipeezeList: [],
  clipeezeLinks: { self: null, first: null, next: null },
  requestState: null,
  error: null,
  endOfList: false,
}

type refreshParams = {
  clear?: boolean
  description?: string
  fetchData?: boolean
  status?: ClipeezeStatus
}

type useFetchClipeezeParams = {
  pageSize?: number
  initialStatus?: ClipeezeStatus
  makeInitialRequest?: boolean
  sortColumn?: SortColumn
  sortOrder?: SortOrder
}

export const useFetchClipeezeList = ({
  pageSize,
  initialStatus,
  makeInitialRequest = true,
  sortColumn,
  sortOrder,
}: useFetchClipeezeParams) => {
  const authToken = useSelector(getAuthToken)
  const apiHost = useSelector(getInternalAPIHost)
  const initialUrl = useSelector((state: CantinaState) =>
    getInternalAPIEndpoint(state, RailsEndpoint.Clipeeze)
  )
  const [data, setData] = useState<useFetchClipeezeData>(DEFAULT_DATA)
  const urlRef = useRef(initialUrl)
  const clipeezeRef = useRef([] as Clipeeze[])

  // The initial refresh will use the default API link and subsequent calls
  // to refresh will use the 'next' link returned from the API to deliver the
  // next page of results
  const refresh = async ({
    clear = false,
    description,
    fetchData = true,
    status,
  }: refreshParams = {}) => {
    const baseUrl = clear ? initialUrl : urlRef.current
    let clipeezeList = clear ? [] : clipeezeRef.current

    if (fetchData) {
      try {
        // We will concatenate ClipEEze to the existing list unless asked
        // to 'clear' the data
        setData({
          ...data,
          requestState: RequestState.Loading,
        })
        const { clipeeze, links } = await fetchClipeezeList({
          baseUrl,
          description,
          pageSize,
          status,
          sortColumn,
          sortOrder,
          token: authToken as string,
        })

        // Build the list of ClipEEze
        clipeezeList = [...clipeezeList, ...clipeeze]

        // We have hit the end of the list if we're not paging or if we're paging
        // and have no 'next' link
        setData({
          clipeezeList,
          clipeezeLinks: links,
          requestState: RequestState.Success,
          error: null,
          endOfList: !pageSize || !links?.next,
        })

        // If we are paging, we need to store the list of ClipEEze and the 'next' link
        // for a subsequent refresh call
        if (pageSize) {
          urlRef.current = links?.next ? `${apiHost}${links.next}` : ''
          clipeezeRef.current = clipeezeList
        }
      } catch (error) {
        setData({
          ...data,
          requestState: RequestState.Error,
          error: 'Error fetching ClipEEze list',
        })
      }
    }
  }

  useEffect(() => {
    refresh({ status: initialStatus, fetchData: makeInitialRequest })
  }, [])

  return { ...data, refresh }
}
