import React, { FC, ChangeEvent, useState } from 'react'
import { ArrowDownIcon } from 'src/common/icons'
import { tr, Label } from 'src/common/i18n'
import { RoomRole } from 'src/common/constants'
import { BasicTooltip } from 'src/components/Tooltip'
import { RadioButtons } from 'src/components/Inputs/RadioButtons'
import { TransparentButton } from 'src/components/Button/Button'

const roomTemplateRoles = [
  {
    id: RoomRole.Inviteable,
    label: Label.ROOM_ROLE_INVITEABLE_LABEL,
    description: Label.ROOM_ROLE_INVITEABLE_DESC,
  },
  {
    id: RoomRole.Visitor,
    label: Label.ROOM_ROLE_VISITOR_LABEL,
    description: Label.ROOM_ROLE_VISITOR_DESC,
  },
  {
    id: RoomRole.Attendee,
    label: Label.ROOM_ROLE_ATTENDEE_LABEL,
    description: Label.ROOM_ROLE_ATTENDEE_DESC,
  },
  {
    id: RoomRole.Moderator,
    label: Label.ROOM_ROLE_MODERATOR_LABEL,
    description: Label.ROOM_ROLE_MODERATOR_DESC,
  },
  {
    id: RoomRole.Manager,
    label: Label.ROOM_ROLE_MANAGER_LABEL,
    description: Label.ROOM_ROLE_MANAGER_DESC,
  },
]
// Use the roleType prop to determine which set of role options to use (user, group, etc.)
const OPTIONS = {
  user_role: [
    {
      id: 'member',
      label: Label.USER_ROLE_MEMBER_LABEL,
      description: Label.USER_ROLE_MEMBER_DESC,
    },
    {
      id: 'administrator',
      label: Label.USER_ROLE_ADMINISTRATOR_LABEL,
      description: Label.USER_ROLE_ADMINISTRATOR_DESC,
    },
  ],
  group_role: [
    {
      id: 'member',
      label: Label.GROUP_ROLE_MEMBER_LABEL,
      description: Label.GROUP_ROLE_MEMBER_DESC,
    },
    {
      id: 'maintainer',
      label: Label.GROUP_ROLE_MAINTAINER_LABEL,
      description: Label.GROUP_ROLE_MAINTAINER_DESC,
    },
  ],
  // Remove Attendee role for Rooms (but not for RoomTemplates)
  room_role: roomTemplateRoles.filter(({ id }) => id !== RoomRole.Attendee),
  room_template_role: roomTemplateRoles,
}

const TOOLTIP_TITLE = {
  user_role: Label.CHOOSE_USER_ROLE,
  group_role: Label.CHOOSE_GROUP_ROLE,
  room_role: Label.CHOOSE_ROOM_ROLE,
  room_template_role: Label.CHOOSE_ROOM_ROLE,
}

interface RolesDropdownProps {
  backendValue: string
  changeHandler: (event: ChangeEvent<HTMLInputElement>) => void
  roleType: 'user_role' | 'group_role' | 'room_role' | 'room_template_role'
  name?: string
}

export const RolesDropdown: FC<RolesDropdownProps> = ({
  backendValue,
  changeHandler,
  roleType,
  name,
}) => {
  const [tooltipShown, setTooltipShown] = useState(false)

  return (
    <BasicTooltip
      hideArrow
      onVisibilityChange={setTooltipShown}
      tooltipClassName='tooltip-min-w'
      placement='bottom-end'
      tooltip={
        <RolesPicker
          backendValue={backendValue}
          changeHandler={changeHandler}
          roleType={roleType}
          name={name}
          className='block-pop-out p-3 px-6'
        />
      }
      tooltipShown={tooltipShown}
      trigger='click'
    >
      <TransparentButton
        testId={`button-select_user_role_${backendValue}`}
        className='administration-user-role flex items-center capitalize border-contrast-m rounded-lg px-3'
        ariaLabel={tr(Label.CHOOSE_USER_ROLE)}
      >
        <div className='text-contrast-m'>{`${tr(Label.ROLE)}:\u00A0`}</div>
        <div className='pr-2'>{backendValue}</div>
        <ArrowDownIcon fixedWidth className='ml-auto' />
      </TransparentButton>
    </BasicTooltip>
  )
}

export const RolesPicker: FC<
  RolesDropdownProps & { className?: string; gridView?: boolean }
> = ({
  className = '',
  gridView = false,
  backendValue,
  changeHandler,
  roleType,
  name,
}) => {
  const options = OPTIONS[roleType] || []

  return (
    <div className={className}>
      <div className='py-2 font-bold text-md'>
        {tr(TOOLTIP_TITLE[roleType])}
      </div>
      <RadioButtons
        gridView={gridView}
        name={name || roleType}
        onChange={changeHandler}
        selectedId={backendValue}
        options={options}
      />
    </div>
  )
}
