import React, { FC, ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { hasValidSettings } from '../redux/features/settings/settingsSelectors'

interface withCantinaSettingsProps {}

export const withCantinaSettings = (FallbackComponent?: FC) => <
  T extends object
>(
  WrappedComponent: ComponentType<T>
) => {
  return (props: T & withCantinaSettingsProps) => {
    const hasSettings = useSelector(hasValidSettings)
    if (hasSettings) {
      return <WrappedComponent {...props} />
    }
    return FallbackComponent ? <FallbackComponent /> : null
  }
}
