import { createSelector } from 'reselect'
import { CantinaState, Room } from '../../interfaces'
import { Scope } from '../../../constants'
import { fixedEncodeURIComponent } from '../../../services/helpers'
import { getLayouts } from '../layouts/layoutsSelectors'
import { getAuthScopeList } from '../scopes/scopesSelectors'
import { hasAllowStereoAudioFeatureFlag } from '../settings/settingsSelectors'

const roomId = (_: CantinaState, roomId: string) => roomId
const roomName = (_: CantinaState, roomName: string) => roomName

/**
 * HOF to not duplicate the logic and
 * have type checking on Room keys
 */
const makeRoomKeySelector = <T extends keyof Room>(key: T) => {
  return createSelector(getRoom, (room) => room?.[key])
}

/**
 * HOF as above but force the value to be a boolean
 */
const makeBooleanRoomKeySelector = (key: keyof Room) => {
  return createSelector(getRoom, (room) => Boolean(room?.[key]))
}

export const getRoomIds = ({ rooms }: CantinaState) => {
  return rooms.ids
}

export const getRooms = ({ rooms }: CantinaState) => {
  return rooms.byId
}

export const roomExists = ({ rooms }: CantinaState, roomId: string) => {
  return roomId in rooms.byId
}

export const getRoom = createSelector(getRooms, roomId, (rooms, roomId) =>
  roomId ? rooms[roomId] || null : null
)

export const getRoomName = makeRoomKeySelector('name')
export const getParticipantCount = makeRoomKeySelector('numMembers')
export const getRoomUuid = makeRoomKeySelector('uuid')
export const getRoomPreview = makeRoomKeySelector('preview')
export const getRoomDefaultPlayVolume = createSelector(
  getRoom,
  (room) => room?.defaultPlayVolume ?? 0
)
export const getRoomBannerDisplayOption = createSelector(getRoom, (room) => {
  return room?.bannerDisplayOption
})
export const getLocalBroadcastUrls = createSelector(getRoom, (room) => {
  if (!room) {
    return null
  }
  const { uuid, name = '' } = room

  return {
    web_player: `${
      window.location.origin
    }/stream/${uuid}/${fixedEncodeURIComponent(name)}`,
    dash: `${window.location.origin}:5443/dash/${uuid}/index.mpd`,
    flv: `rtmp://${window.location.hostname}:1935/flv/${uuid}`,
    hls: `${window.location.origin}:5443/hls/${uuid}/index.m3u8`,
  }
})
export const getVolumeAudience = makeRoomKeySelector('volAudience')
export const getPerformerDelay = makeRoomKeySelector('performerDelay')
export const getRecordingError = makeRoomKeySelector('recordingError')
export const getVideoQuality = makeRoomKeySelector('confQuality')
export const getAccessPin = makeRoomKeySelector('accessPin')
export const getModeratorPin = makeRoomKeySelector('moderatorPin')
export const getLastLayout = makeRoomKeySelector('lastLayout')
export const getFilePlaybackRole = makeRoomKeySelector('filePlaybackRole')
export const getVidFloorRole = makeRoomKeySelector('vidFloorRole')
export const getMotionQuality = makeRoomKeySelector('motionQuality')
export const getMotionQualityInbound = makeRoomKeySelector(
  'motionQualityInbound'
)
export const getVideoShuffle = makeRoomKeySelector('videoShuffle')

/**
 * Boolean Properties
 */
// TODO: isRunning, isRecording, isLocked are duplicated in roomListSelectors.ts. Consolidate?
export const isRunning = makeBooleanRoomKeySelector('running')
export const isRecording = createSelector(
  isRunning,
  makeBooleanRoomKeySelector('recording'),
  (running, recording) => {
    return running && recording
  }
)
export const isLocked = createSelector(
  isRunning,
  makeBooleanRoomKeySelector('locked'),
  (running, locked) => {
    return running && locked
  }
)
export const hasMohPlaying = makeBooleanRoomKeySelector('mohPlaying')
export const hasMeetingMode = makeBooleanRoomKeySelector('meetingMode')
export const hasSilentMode = makeBooleanRoomKeySelector('silentMode')
export const hasBlindMode = makeBooleanRoomKeySelector('blindMode')
export const hasLiveMusicMode = makeBooleanRoomKeySelector('liveMusic')
export const hasHideVMutedMode = makeBooleanRoomKeySelector('vidMuteHide')
export const hasSpeakerHighlight =
  makeBooleanRoomKeySelector('speakerHighlight')
export const hasDisableIntercom = makeBooleanRoomKeySelector('disableIntercom')
export const hasFilesFullScreen = makeBooleanRoomKeySelector('filesFullScreen')
export const hasPodcastMode = makeBooleanRoomKeySelector('podcastMode')
export const hasHandraiseOnscreen =
  makeBooleanRoomKeySelector('handraiseOnscreen')
export const hasLogosVisible = makeBooleanRoomKeySelector('logosVisible')
export const hasClipeezePublic = makeBooleanRoomKeySelector('publicClipeeze')
export const hasChatDisabled = makeBooleanRoomKeySelector('publicDisableChat')
export const hasChatOneWay = makeBooleanRoomKeySelector('chatOneWay')
export const hasAutoOpenNav = makeBooleanRoomKeySelector('autoOpenNav')

export const getRoomLayout = createSelector(
  getRoom,
  getLayouts,
  (room, layouts) => {
    if (!room) {
      return null
    }
    const belongsToAGroup =
      room.lastLayout &&
      layouts[room.lastLayout] &&
      layouts[room.lastLayout].belongsToAGroup
    return belongsToAGroup ? `group:${room.lastLayoutGroup}` : room.lastLayout
  }
)
export const hasLayoutWithReservationIds = createSelector(
  getRoomLayout,
  getLayouts,
  (currentLayout, layouts) => {
    if (!currentLayout) {
      return false
    }
    return (
      layouts[currentLayout] && layouts[currentLayout].reservationIds.length > 0
    )
  }
)

export const getPlayingError = makeBooleanRoomKeySelector('playingError')
export const getPlayingSettings = createSelector(getRoom, (room) => {
  if (room && room.asyncFilesPlaying) {
    return {
      fileName: room.asyncFilesPlayingName,
      volume: room.asyncFilesPlayingVolume || 0,
      paused: room.asyncFilesPlayingPaused,
      seekable: room.asyncFilesSeekable,
      role: room.asyncFilesRole,
      async: true,
    }
  }
  if (room && room.filesPlaying) {
    return {
      fileName: room.filesPlayingName,
      volume: room.filesPlayingVolume || 0,
      paused: room.filesPlayingPaused,
      seekable: room.filesSeekable,
      role: room.filesRole,
      async: false,
    }
  }
  return null
})

export const isVideoPlaying = createSelector(getPlayingSettings, (settings) =>
  Boolean(settings)
)

export const canPlayClipeeze = createSelector(
  getAuthScopeList,
  hasClipeezePublic,
  (scopeList, clipeezePublic) => {
    return scopeList.includes(Scope.ConferenceEnableClipeeze) || clipeezePublic
  }
)

const getRoomByName = createSelector(getRooms, roomName, (rooms, roomName) => {
  const roomId = Object.keys(rooms).find((id) => {
    return rooms?.[id]?.name === roomName
  })
  return roomId && rooms[roomId] ? rooms[roomId] : null
})

export const hasStereoAudioByName = createSelector(
  getRoomByName,
  hasAllowStereoAudioFeatureFlag,
  (room, allowStereoAudio) => {
    return Boolean(allowStereoAudio && room?.stereo_audio)
  }
)

export const isPollingRequired = ({ rooms }: CantinaState) => {
  return Boolean(rooms.pollingRequired)
}

export const getPollingIds = ({ rooms }: CantinaState) => {
  const { pollingIds = [], deniedIds = [] } = rooms
  if (!pollingIds?.length) {
    return []
  }
  if (deniedIds?.length) {
    return pollingIds.filter((roomId) => !deniedIds.includes(roomId))
  }
  return pollingIds
}

const makeCustomImageSelector = (
  key: 'customAlone' | 'customCanvas' | 'emptyImages' | 'previewImages'
) => {
  return createSelector(getRoom, (room) => {
    const images = room?.[key] ?? []
    if (!Array.isArray(images)) {
      return []
    }
    return images.map((url, index) => ({ id: `${key}-${index}`, src: url }))
  })
}
export const getRoomAloneImages = makeCustomImageSelector('customAlone')
export const getRoomCanvasImages = makeCustomImageSelector('customCanvas')
