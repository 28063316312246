import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import { meetingModeModalSelector } from 'src/common/redux/features/calls/callSelectors'
import { Modal } from './Modal'

export const MutedModal: FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [enableModal, setEnableModal] = useState(true)
  const meetingMode = useSelector(meetingModeModalSelector)

  // Once we have an actual boolean value for meetingMode, we can use it
  // to decide whether to show the modal or not
  useEffect(() => {
    if (meetingMode === undefined) {
      // Reset the modal logic when changing rooms
      setEnableModal(true)
    } else if (enableModal) {
      // Get the initial boolean value and show the modal if true
      setShowModal(meetingMode)
      // Ignore subsequent meeting mode toggles
      setEnableModal(false)
    }
  }, [meetingMode, enableModal])

  const closeHandler = () => {
    setShowModal(false)
  }

  return (
    <Modal
      byline={tr(Label.MICROPHONE_HAS_BEEN_MUTED)}
      confirmLabel={tr(Label.OK)}
      closeHandler={closeHandler}
      confirmHandler={closeHandler}
      show={showModal}
      showCancelButton={false}
      testId={TestId.MutedModal}
      title={tr(Label.MEETING_IN_PROGRESS)}
    />
  )
}
