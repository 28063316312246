import { useEffect, useState, ChangeEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { WebRTC } from '@signalwire/js'
import { ActionCreator } from '@reduxjs/toolkit'
import { Devices, MediaDevice } from '../constants'
import { deviceActions } from '../redux/features'
import {
  getCurrentCameraId,
  getCurrentCameraLabel,
  getCurrentMicrophoneId,
  getCurrentMicrophoneLabel,
  getCurrentSpeakerId,
  getCurrentSpeakerLabel,
} from '../redux/features/device/deviceSelectors'
import {
  checkCamera,
  checkMicrophone,
  checkSpeaker,
} from '../services/checkDevices'

const {
  createCameraDeviceWatcher,
  createMicrophoneDeviceWatcher,
  createSpeakerDeviceWatcher,
  getCameraDevices,
  getMicrophoneDevices,
  getSpeakerDevices,
} = WebRTC

type DeviceOption = { id: string; label: string | null }
type UseDeviceHookParams = {
  injectDefaultDevice?: boolean
  injectDisabledDevice?: boolean
}

const deviceFn = {
  camera: getCameraDevices,
  microphone: getMicrophoneDevices,
  speaker: getSpeakerDevices,
}
const watcherFn = {
  camera: createCameraDeviceWatcher,
  microphone: createMicrophoneDeviceWatcher,
  speaker: createSpeakerDeviceWatcher,
}

// We don't need the fake OS Default or Disable devices everywhere
// because in some cases, the user must pick a specific one.
// SecondSourceModal.tsx: It doesn't make sense to pick "OS Default" but
// they can pick the Disabled one in case they want only the Microphone or the Camera.
// CameraList.tsx: When listing the available devices (audio or video) doesn't
//  make sense to list OS Default and Disabled too

export const makeDeviceHook = ({ type }: { type: Devices }) => {
  return ({
    injectDefaultDevice,
    injectDisabledDevice,
  }: UseDeviceHookParams = {}) => {
    const [devices, setDevices] = useState<DeviceOption[]>([])
    const [error, setError] = useState(null)

    useEffect(() => {
      let mounted = true

      const getDevices = () =>
        deviceFn[type]()
          .then((result: MediaDeviceInfo[]) => {
            if (!mounted) {
              return
            }
            const options = result.map(({ deviceId, label }) => ({
              id: deviceId,
              label,
            }))
            if (options.length && injectDefaultDevice) {
              options.unshift({ id: MediaDevice.Default, label: 'OS Default' })
            }
            if (injectDisabledDevice) {
              options.push({ id: MediaDevice.None, label: 'Disabled' })
            }
            setDevices(options)
          })
          .catch((error: any) => {
            console.error('Device error', error)
            setError(error)
          })

      getDevices()

      const update = async () => {
        try {
          const watchedDevices = await watcherFn[type]()
          watchedDevices.on('changed', () => {
            getDevices()
          })
        } catch (error) {
          console.debug('Device not supported:', type)
        }
      }

      update()

      return () => {
        mounted = false
      }
    }, [type, injectDefaultDevice, injectDisabledDevice])

    return { devices, error }
  }
}

export const useCameras = makeDeviceHook({ type: Devices.Camera })
export const useMicrophones = makeDeviceHook({ type: Devices.Microphone })
export const useSpeakers = makeDeviceHook({ type: Devices.Speaker })

export const useChangeDevice = (
  devices: DeviceOption[],
  actionCreator: ActionCreator<any>
) => {
  const dispatch = useDispatch()

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const device = devices.find(({ id }) => id === event.target.value)
    if (device) {
      dispatch(actionCreator({ deviceId: device.id, label: device.label }))
    }
  }

  return onChange
}

export const useCameraPreview = () => {
  const dispatch = useDispatch()
  const cameraId = useSelector(getCurrentCameraId)
  const cameraLabel = useSelector(getCurrentCameraLabel)

  useEffect(() => {
    // console.log('Check for camera', cameraId, cameraLabel)
    if (cameraId === MediaDevice.None || !cameraId || !cameraLabel) {
      return
    }

    checkCamera(cameraId, cameraLabel).then((newDevice) => {
      if (newDevice) {
        dispatch(deviceActions.previewCameraChanged(newDevice))
      }
    })
  }, [cameraId, cameraLabel, dispatch])

  return [cameraId, cameraLabel]
}

export const useMicrophonePreview = () => {
  const dispatch = useDispatch()
  const micId = useSelector(getCurrentMicrophoneId)
  const micLabel = useSelector(getCurrentMicrophoneLabel)

  useEffect(() => {
    // console.log('Check for microphone', currentMicrophoneId, currentMicrophoneLabel)
    if (micId === MediaDevice.None || !micId || !micLabel) {
      return
    }
    checkMicrophone(micId, micLabel).then((newDevice) => {
      if (newDevice) {
        dispatch(deviceActions.previewMicrophoneChanged(newDevice))
      }
    })
  }, [micId, micLabel, dispatch])

  return [micId, micLabel]
}

export const useSpeakerPreview = () => {
  const dispatch = useDispatch()
  const speakerId = useSelector(getCurrentSpeakerId)
  const speakerLabel = useSelector(getCurrentSpeakerLabel)

  useEffect(() => {
    // console.log('Check for speaker', currentSpeakerId, currentSpeakerLabel)
    if (speakerId === MediaDevice.None || !speakerId || !speakerLabel) {
      return
    }
    checkSpeaker(speakerId, speakerLabel).then((newDevice) => {
      if (newDevice) {
        dispatch(deviceActions.previewSpeakerChanged(newDevice))
      }
    })
  }, [speakerId, speakerLabel, dispatch])

  return [speakerId, speakerLabel]
}
