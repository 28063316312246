import React, { FC, useEffect } from 'react'
import { RequestState } from 'src/common/rest'
import { User } from 'src/common/redux/interfaces'
import { useUpdateUser } from 'src/common/hooks'
import { UserActionType } from 'src/common/constants'
import { UserActionsModal } from './UserActionsModal'

type UpdateUserModalProps = {
  user: User
  closeHandler: () => void
  show: boolean
}

export const UpdateUserModal: FC<UpdateUserModalProps> = ({
  user,
  closeHandler,
  show,
}) => {
  const { requestState, update, getError } = useUpdateUser(user.id)

  useEffect(() => {
    if (requestState === RequestState.Success) {
      closeHandler()
    }
  }, [requestState, closeHandler])

  const formHandler = ({ user }: any) => {
    update({
      company_name: user.companyName,
      email: user.email,
      name: user.name,
      phone_number: user.phoneNumber,
      user_role: user.role,
    })
  }

  return (
    <UserActionsModal
      actionType={UserActionType.Edit}
      closeHandler={closeHandler}
      formHandler={formHandler}
      getError={getError}
      show={show}
      user={user}
    />
  )
}
