import React, { FC, useState, useCallback } from 'react'
import { tr, Label } from 'src/common/i18n'
import { EllipsisSolidIcon } from 'src/common/icons'
import { TestId } from 'src/constants'
import { Button, ButtonWithTooltip } from '../Button/Button'
import { BasicTooltip } from 'src/components/Tooltip'
import { BottomBarMore } from 'src/pages/Call/BottomBarMore'
import { TOOLTIP_OFFSET_Y_BOTTOM_BAR } from 'src/hoc/withTextTooltip'

const modifiers = [
  {
    name: 'offset',
    options: {
      offset: [0, TOOLTIP_OFFSET_Y_BOTTOM_BAR],
    },
  },
]

type MoreButtonProps = {
  className?: string
  disabled?: boolean
}

export const InCallMoreButton: FC<MoreButtonProps> = ({
  className = '',
  disabled,
}) => {
  const [shown, setShown] = useState(false)
  const closeTooltip = useCallback(() => {
    setShown(false)
  }, [])

  // Don't include the hover tooltip if the options tooltip is being shown
  const WrappedButton = (props: any) => {
    if (shown) {
      return <Button {...props} />
    }
    return (
      <ButtonWithTooltip
        {...{ ...props, offsetY: TOOLTIP_OFFSET_Y_BOTTOM_BAR }}
      />
    )
  }

  // Adding margin when using <Button>, which does not include the wrapper
  // we're using to target CSS
  return (
    <div className={shown ? 'mr-3' : ''}>
      <BasicTooltip
        modifiers={modifiers}
        onVisibilityChange={setShown}
        placement='top-end'
        tooltip={<BottomBarMore closeHandler={closeTooltip} />}
        tooltipShown={shown}
        tooltipClassName='in-call-tooltip menu-tooltip'
        trigger='click'
      >
        <WrappedButton
          ariaLabel={tr(Label.MORE)}
          className={`sw-btn-icon-toggle ${className}`}
          disabled={disabled}
          testId={TestId.BottomBarButtonMoreOptions}
        >
          <EllipsisSolidIcon size='lg' fixedWidth />
        </WrappedButton>
      </BasicTooltip>
    </div>
  )
}
