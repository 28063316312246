import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserRole } from '../constants'
import { usersActions } from '../redux/features'
import { getUsersById } from '../redux/features/users/usersSelectors'
import { RailsEndpoint, RequestState } from '../rest'
import { sortByNameProperty } from '../services/helpers'
import { useInternalAPI } from './useInternalAPI'

type UseFetchUsersData = {
  requestState: RequestState
  error: string | null
}

type UseFetchUsersParams = {
  refreshOnMount?: boolean
}

const fetchOptions = {
  endpoint: RailsEndpoint.Users,
  method: 'GET',
}
const updateOptions = {
  endpoint: RailsEndpoint.Users,
  method: 'PATCH',
}

export const useFetchUsers = ({
  refreshOnMount = true,
}: UseFetchUsersParams = {}) => {
  const { invokeAPI: fetchHandler } = useInternalAPI(fetchOptions)
  const { invokeAPI: updateHandler } = useInternalAPI(updateOptions)
  const dispatch = useDispatch()
  const users = useSelector(getUsersById)
  const [data, setData] = useState<UseFetchUsersData>({
    requestState: RequestState.Loading,
    error: null,
  })

  const refresh = async () => {
    try {
      setData({
        ...data,
        error: null,
      })
      const { users } = await fetchHandler()
      dispatch(usersActions.clear())
      dispatch(usersActions.fetchUsersSuccess(users))
      setData({
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      setData({
        requestState: RequestState.Error,
        error: 'Error loading users',
      })
    }
  }

  /**
   * Update specific fields in the user profile
   */
  const updateUserRole = async ({
    userId,
    role,
  }: {
    userId: string
    role: UserRole
  }) => {
    try {
      const response = await updateHandler({
        urlTransform: (url) => `${url}/${userId}`,
        body: JSON.stringify({
          user_role: role,
        }),
      })
      // Dispatch fetchUsersSuccess with [response] to reuse the same action
      dispatch(usersActions.fetchUsersSuccess([response]))
    } catch (error) {
      console.error('Error updating membership', error)
    }
  }

  /**
   * Returns the list of users applying the proper UI filters
   * filterByText: filter on the name only for now
   * filterByUserRole: filter on the user_role (member | administrator)
   */
  const getUsers = (params: {
    filterByText?: string
    filterByUserRole?: string
  }) => {
    const { filterByText, filterByUserRole } = params
    return Object.keys(users)
      .filter((id) => {
        const { name, role } = users[id]
        if (filterByText && !name.toLowerCase().includes(filterByText)) {
          return false
        }
        if (
          filterByUserRole &&
          filterByUserRole !== 'all' &&
          role !== filterByUserRole
        ) {
          return false
        }
        return true
      })
      .map((id) => users[id])
      .sort(sortByNameProperty)
  }

  useEffect(() => {
    if (refreshOnMount) {
      refresh()
    } else {
      setData({
        requestState: RequestState.Success,
        error: null,
      })
    }
  }, [refreshOnMount])

  return { ...data, refresh, getUsers, updateUserRole }
}
