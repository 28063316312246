import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { CantinaState } from '../redux/interfaces'
import { makeAuthScopeSelector } from '../redux/features/scopes/scopesSelectors'
import { Scope } from '../constants'

export const withScope = <T extends object>(
  WrappedComponent: ComponentType<T>,
  scope: Scope
) => {
  const scopeSelector = makeAuthScopeSelector()

  return (props: T) => {
    const hasScope = useSelector((state: CantinaState) =>
      scopeSelector(state, scope)
    )
    return hasScope ? <WrappedComponent {...(props as T)} /> : null
  }
}

const makeWithScopeHOC = (scope: Scope) => {
  return <T extends object>(WrappedComponent: ComponentType<T>) => {
    return withScope(WrappedComponent, scope)
  }
}

export const withConferenceListScope = makeWithScopeHOC(Scope.ConferenceList)
export const withConferenceJoinScope = makeWithScopeHOC(Scope.ConferenceJoin)
export const withConferenceDeafScope = makeWithScopeHOC(Scope.ConferenceDeaf)
export const withConferenceDeafSelfScope = makeWithScopeHOC(
  Scope.ConferenceDeafSelf
)
export const withConferenceUndeafScope = makeWithScopeHOC(
  Scope.ConferenceUndeaf
)
export const withConferenceUndeafSelfScope = makeWithScopeHOC(
  Scope.ConferenceUndeafSelf
)
export const withConferenceAudioMuteScope = makeWithScopeHOC(
  Scope.ConferenceAudioMute
)
export const withConferenceAudioMuteSelfScope = makeWithScopeHOC(
  Scope.ConferenceAudioMuteSelf
)
export const withConferenceAudioUnmuteScope = makeWithScopeHOC(
  Scope.ConferenceAudioUnmute
)
export const withConferenceAudioUnmuteSelfScope = makeWithScopeHOC(
  Scope.ConferenceAudioUnmuteSelf
)
export const withConferenceVideoMuteScope = makeWithScopeHOC(
  Scope.ConferenceVideoMute
)
export const withConferenceVideoUnmuteScope = makeWithScopeHOC(
  Scope.ConferenceVideoUnmute
)
export const withConferenceToggleVideoMuteScope = makeWithScopeHOC(
  Scope.ConferenceToggleVideoMute
)
export const withConferenceVideoMuteSelfScope = makeWithScopeHOC(
  Scope.ConferenceVideoMuteSelf
)
export const withConferenceVideoUnmuteSelfScope = makeWithScopeHOC(
  Scope.ConferenceVideoUnmuteSelf
)
export const withConferenceVideoShuffleScope = makeWithScopeHOC(
  Scope.ConferenceVideoShuffle
)
export const withConferenceAudioFlagsScope = makeWithScopeHOC(
  Scope.ConferenceAudioFlags
)
export const withConferenceAudioFlagsSelfScope = makeWithScopeHOC(
  Scope.ConferenceAudioFlagsSelf
)
export const withConferenceKickScope = makeWithScopeHOC(Scope.ConferenceKick)
export const withConferenceListParticipantsScope = makeWithScopeHOC(
  Scope.ConferenceListParticipants
)
export const withConferenceLockScope = makeWithScopeHOC(Scope.ConferenceLock)
export const withConferenceUnLockScope = makeWithScopeHOC(
  Scope.ConferenceUnLock
)
export const withConferenceShareScreenScope = makeWithScopeHOC(
  Scope.ConferenceShareScreen
)
export const withConferenceRaiseHandScope = makeWithScopeHOC(
  Scope.ConferenceRaiseHand
)
export const withConferenceRaiseHandSelfScope = makeWithScopeHOC(
  Scope.ConferenceRaiseHandSelf
)
export const withConferenceRaiseHandsControlsScope = makeWithScopeHOC(
  Scope.ConferenceRaiseHandsControls
)
export const withConferenceLowerHandScope = makeWithScopeHOC(
  Scope.ConferenceLowerHand
)
export const withConferenceLowerHandSelfScope = makeWithScopeHOC(
  Scope.ConferenceLowerHandSelf
)
export const withConferenceHandraiseOnscreenControls = makeWithScopeHOC(
  Scope.ConferenceHandraiseOnscreenControls
)
export const withConferenceEnableClipeezeScope = makeWithScopeHOC(
  Scope.ConferenceEnableClipeeze
)
export const withConferenceDisableClipeezeScope = makeWithScopeHOC(
  Scope.ConferenceDisableClipeeze
)
export const withConferenceEnableMeetingModeScope = makeWithScopeHOC(
  Scope.ConferenceEnableMeetingMode
)
export const withConferenceDisableMeetingModeScope = makeWithScopeHOC(
  Scope.ConferenceDisableMeetingMode
)
export const withConferenceEnableSilentModeScope = makeWithScopeHOC(
  Scope.ConferenceEnableSilentMode
)
export const withConferenceDisableSilentModeScope = makeWithScopeHOC(
  Scope.ConferenceDisableSilentMode
)
export const withConferenceEnableBlindModeScope = makeWithScopeHOC(
  Scope.ConferenceEnableBlindMode
)
export const withConferenceDisableBlindModeScope = makeWithScopeHOC(
  Scope.ConferenceDisableBlindMode
)
export const withConferenceEnableSpeakerHighlightScope = makeWithScopeHOC(
  Scope.ConferenceEnableSpeakerHighlight
)
export const withConferenceDisableSpeakerHighlightScope = makeWithScopeHOC(
  Scope.ConferenceDisableSpeakerHighlight
)
export const withConferenceEnableHideVMutedModeScope = makeWithScopeHOC(
  Scope.ConferenceEnableHideVMutedMode
)
export const withConferenceDisableHideVMutedModeScope = makeWithScopeHOC(
  Scope.ConferenceDisableHideVMutedMode
)
export const withConferenceToggleBannersScope = makeWithScopeHOC(
  Scope.ConferenceToggleBanners
)
export const withConferenceSetPinScope = makeWithScopeHOC(
  Scope.ConferenceSetPin
)
export const withConferenceUnsetPinScope = makeWithScopeHOC(
  Scope.ConferenceUnsetPin
)
export const withConferencePlaybackControlsScope = makeWithScopeHOC(
  Scope.ConferencePlaybackControls
)
export const withConferenceCanvasControlsScope = makeWithScopeHOC(
  Scope.ConferenceCanvasControls
)
export const withConferenceRecordingControlsScope = makeWithScopeHOC(
  Scope.ConferenceRecordingControls
)
export const withConferenceAudienceControlsScope = makeWithScopeHOC(
  Scope.ConferenceAudienceControls
)
export const withConferenceInviteByCallScope = makeWithScopeHOC(
  Scope.ConferenceInviteByCall
)
export const withConferenceInviteBySmsScope = makeWithScopeHOC(
  Scope.ConferenceInviteBySms
)
export const withConferenceKnockScope = makeWithScopeHOC(Scope.ConferenceKnock)
export const withConferenceLayerControlsScope = makeWithScopeHOC(
  Scope.ConferenceLayerControls
)
export const withConferenceAudienceVolumeScope = makeWithScopeHOC(
  Scope.ConferenceAudienceVolume
)
export const withConferenceBottomDrawerScope = makeWithScopeHOC(
  Scope.ConferenceBottomDrawer
)
export const withConferenceTransferScope = makeWithScopeHOC(
  Scope.ConferenceTransfer
)
export const withConferenceZoneManageScope = makeWithScopeHOC(
  Scope.ConferenceZoneManage
)
export const withConferenceZoneDestroyScope = makeWithScopeHOC(
  Scope.ConferenceZoneDestroy
)
export const withConferenceZoneExtVolScope = makeWithScopeHOC(
  Scope.ConferenceZoneExtVol
)
export const withConferenceGroupsViewRolesAssigned = makeWithScopeHOC(
  Scope.ConferenceGroupsViewRolesAssigned
)

// UI Scopes
export const withUiDialerScope = makeWithScopeHOC(Scope.UiDialer)
export const withUiSettingsScope = makeWithScopeHOC(Scope.UiSettings)
export const withUiSettingsUsersCreateScope = makeWithScopeHOC(
  Scope.UiSettingsUsersCreate
)
export const withUiSettingsUsersUpdateScope = makeWithScopeHOC(
  Scope.UiSettingsUsersUpdate
)
export const withUiSettingsUsersDeleteScope = makeWithScopeHOC(
  Scope.UiSettingsUsersDelete
)
export const withUiSettingsGroupsCreateScope = makeWithScopeHOC(
  Scope.UiSettingsGroupsCreate
)
