import { RestMethodParams, RoomTemplate } from './interfaces'
import { cantinaFetch } from '.'

interface TemplateMethodParams extends RestMethodParams {
  roomTemplateId: string
}
export interface UpdateRoomTemplateMethodParams extends RestMethodParams {
  roomTemplateId: string
  params: Partial<RoomTemplate>
}

export const fetchRoomTemplate = ({
  baseUrl,
  token,
  roomTemplateId,
}: TemplateMethodParams): Promise<RoomTemplate> => {
  const url = `${baseUrl}/${roomTemplateId}`
  return cantinaFetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const updateRoomTemplate = ({
  baseUrl,
  token,
  roomTemplateId,
  params,
}: UpdateRoomTemplateMethodParams): Promise<RoomTemplate> => {
  const url = `${baseUrl}/${roomTemplateId}`
  return cantinaFetch(url, {
    body: JSON.stringify(params),
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const fetchAvailableRoomTemplates = ({
  baseUrl,
  token,
}: RestMethodParams): Promise<Partial<RoomTemplate>[]> => {
  return cantinaFetch(baseUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}
