import React, { FC, ComponentType } from 'react'
import { TestId } from 'src/constants'

type HorizontalFeatureToggleProps = {
  className?: string
  Icon?: ComponentType<{ className: string }>
  label?: string
  onToggleOn: any
  onToggleOff: any
  checked?: boolean
  testId?: TestId // TODO: make this required
}

const HorizontalFeatureToggle: FC<HorizontalFeatureToggleProps> = ({
  className = '',
  onToggleOn,
  onToggleOff,
  checked = false,
  label,
  Icon,
  testId,
}) => {
  return (
    <div className={className}>
      <label
        className='flex justify-between items-center cursor-pointer'
        data-test={`${testId}_label`}
      >
        <span>
          {Icon && <Icon className='mr-2' />}
          {label}
        </span>
        <div className='flex items-center' data-test={`${testId}_wrapper`}>
          <div className='relative inline-block flex-start sw-transition-all'>
            <input
              type='checkbox'
              className='hidden'
              onChange={(event) => {
                event.currentTarget.checked ? onToggleOn() : onToggleOff()
              }}
              checked={checked}
              data-test={`${testId}_checkbox`}
            />
            <div className='toggle-line' />
            <div className='toggle-dot' />
          </div>
          <span className='w-8 text-center text-xs pl-2'>
            {checked ? 'ON' : 'OFF'}
          </span>
        </div>
      </label>
    </div>
  )
}

export default HorizontalFeatureToggle
