import { useSelector } from 'react-redux'
import { getAuthToken } from '../redux/features/auth/authSelectors'
import { getInternalAPIEndpoint } from '../redux/features/settings/settingsSelectors'
import { CantinaState } from '../redux/interfaces'
import { cantinaFetch, RailsEndpoint } from '../rest'

const identity = (input: string) => input

type UseInternalAPIParams = {
  endpoint: RailsEndpoint
  method: RequestInit['method']
}

interface InvokeAPIOptions extends RequestInit {
  urlTransform?: typeof identity
}

/**
 * useInternalAPI is a lower level hook to consume
 * the cantina internal APIs
 * https://github.com/signalwire/relay-apis/tree/master/cantina_backend#internal-apis
 * It automatically builds the baseUrl using the host and cantinaId from
 * Redux and returns the invokeAPI() method to fire the request.
 * @param params: specify a RailsEndpoint and an HTTP method
 */
export const useInternalAPI = ({ endpoint, method }: UseInternalAPIParams) => {
  const token = useSelector(getAuthToken)
  const baseUrl = useSelector((state: CantinaState) =>
    getInternalAPIEndpoint(state, endpoint)
  )

  /**
   * Using invokeAPI the child hooks/components can send the request
   * and `await` the result.
   * The `options` argument is an object to build the HTTP request
   * and, in addition, with "urlTransform" it allows us to transform
   * the final endpoint such as adding query params on the URL or appending
   * specific arguments.
   */
  const invokeAPI = (options: InvokeAPIOptions = {}) => {
    const { urlTransform = identity, ...params } = options
    const url = urlTransform(baseUrl)
    return cantinaFetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...params,
    })
  }

  return { invokeAPI }
}
