import { ChangeEvent, useState, Dispatch, SetStateAction } from 'react'

type StringMap = { [key: string]: any }
type InputElements = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
type ChangeHandler = (event: ChangeEvent<InputElements>) => void

export const useInputChange = (
  defaultState = {}
): [StringMap, ChangeHandler, Dispatch<SetStateAction<{}>>] => {
  const [values, bulkUpdate] = useState(defaultState)
  const handleInputChange = ({ target }: ChangeEvent<InputElements>) => {
    return bulkUpdate({
      ...values,
      [target.name]:
        target.type === 'checkbox'
          ? (target as HTMLInputElement).checked
          : target.value,
    })
  }

  return [values, handleInputChange, bulkUpdate]
}
