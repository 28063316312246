import React, { FC, useEffect, useRef, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { ParticipantGroup } from './ParticipantGroup'
import { CantinaState } from 'src/common/redux/interfaces'
import {
  PARTICIPANT_TALKING_TIMEOUT,
  getParticipantsGroupedByTalking,
} from 'src/common/redux/views/callView/callViewSelectors'
import { tr, Label } from 'src/common/i18n'
import { MicrophoneOnIcon } from 'src/common/icons'
import { TestId } from 'src/constants'

interface ParticipantGroupTalkingProps {
  className?: string
  roomId: string
}

// Reduce list of IDs to Participants talking now or within the last 5 seconds
const filterTalkers = (talkingParticipants: any[]) => {
  return talkingParticipants
    ?.filter((participant) => {
      return (
        participant.talking ||
        (participant.lastTalkingTime &&
          participant.lastTalkingTime >
            Date.now() - PARTICIPANT_TALKING_TIMEOUT)
      )
    })
    .map((participant) => participant.id)
}

export const ParticipantGroupTalking: FC<ParticipantGroupTalkingProps> = ({
  className,
  roomId,
}) => {
  const talkingParticipants = useSelector(
    (state: CantinaState) => getParticipantsGroupedByTalking(state, roomId),
    shallowEqual
  )
  const [talkingIds, setTalkingIds] = useState(
    talkingParticipants.map((participant) => participant.id)
  )
  const talkingTimer = useRef<ReturnType<typeof setTimeout>>()

  // The getParticipantsGroupedByTalking selector is not refreshing to clear
  // speakers who are now quiet, so we're clearing our local list of those speakers
  // after the same time period
  useEffect(() => {
    // We need to set our local list whenever the selector updates
    if (talkingParticipants.length) {
      setTalkingIds(filterTalkers(talkingParticipants))
    }

    // Set a timer to re-run the filter
    talkingTimer.current && clearTimeout(talkingTimer.current)
    talkingTimer.current = setTimeout(
      () => setTalkingIds(filterTalkers(talkingParticipants)),
      PARTICIPANT_TALKING_TIMEOUT + 100
    )
    return () => {
      talkingTimer.current && clearTimeout(talkingTimer.current)
    }
  }, [talkingParticipants])

  return (
    <ParticipantGroup
      className={className}
      heading={tr(Label.TALKING)}
      Icon={MicrophoneOnIcon}
      groupParticipantTestId='talking'
      memberIds={talkingIds}
      testId={TestId.ParticipantsGroupTalking}
    />
  )
}
