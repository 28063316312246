import React, { FC } from 'react'
import { TitleWithTruncationTooltip } from 'src/hoc/withTruncationTooltip'
import { RoomRecordingState } from '../Rooms/RoomRecordingState'
import { RoomStatusIcon } from '../Rooms/RoomStatusIcon'

type RoomDataProps = {
  roomId: string
  name: string
  description: string
}

export const RoomData: FC<RoomDataProps> = ({ roomId, name, description }) => {
  return (
    <div className='flex-1 p-1 overflow-hidden'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center flex-1 pr-2 overflow-hidden'>
          <RoomStatusIcon roomId={roomId} className='mr-2' size='1x' />
          <div className='overflow-hidden'>
            <TitleWithTruncationTooltip
              title={name}
              className='font-medium text-base truncate'
            />
          </div>
        </div>
        <RoomRecordingState roomId={roomId} showLabel={false} />
      </div>
      {description && (
        <p
          className='text-xs text-contrast-m py-1'
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      )}
    </div>
  )
}
