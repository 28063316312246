import { PayloadAction } from '@reduxjs/toolkit'
import { SagaIterator } from 'redux-saga'
import { call, take, fork, put, select } from 'redux-saga/effects'
import { getCantinaBackendAPIEndpoint } from '../settings/settingsSelectors'
import { layoutsActions, loadDefaultLayouts } from './layoutsSlice'
import { Endpoint, defaultLayouts, DefaultLayoutsList } from '../../../rest'
import { getAuthToken } from '../auth/authSelectors'
import { Layout as LayoutSliceItem } from '../../interfaces'

// prettier-ignore
export const ACTIONS: string[] = [
  loadDefaultLayouts.type
]

export function* worker({ type, payload }: PayloadAction<any>): SagaIterator {
  switch (type) {
    case loadDefaultLayouts.type: {
      try {
        const token = yield select(getAuthToken)
        const url = yield select(
          getCantinaBackendAPIEndpoint,
          Endpoint.DefaultLayouts
        )
        const { layouts }: { layouts: DefaultLayoutsList } = yield call(
          defaultLayouts,
          { url, token }
        )
        console.warn('Layouts:', layouts)
        const defaultIds: string[] = []
        const byId: Record<string, LayoutSliceItem> = {}
        layouts.forEach((layout) => {
          defaultIds.push(layout.name)
          byId[layout.name] = {
            id: layout.name,
            label: layout.displayName ?? layout.name,
            type: layout.type,
            reservationIds: 'resIds' in layout ? layout.resIds : [],
            belongsToAGroup: false,
          }
        })
        yield put(
          layoutsActions.bulkInsert({ defaultIds, byId, idsByCallId: {} })
        )
      } catch (error) {
        console.error('Loading default layouts', error)
      }
      break
    }
    default:
      console.warn('Unhandled Layout action', type, payload)
  }
}

export function* watchLayouts(actions = ACTIONS): SagaIterator {
  while (true) {
    const action = yield take(actions)
    yield fork(worker, action)
  }
}
