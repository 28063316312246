import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Displayable, MediaDevice } from 'src/common/constants'
import { tr, Label } from 'src/common/i18n'
import { isDevicesModalVisible } from 'src/common/redux/features/ui/uiSelectors'
import {
  deviceActions,
  uiActions,
  updateMultipleDevices,
} from 'src/common/redux/features'
import {
  getMicrophoneId,
  getCameraId,
  getSpeakerId,
} from 'src/common/redux/features/device/deviceSelectors'
import { TestId } from 'src/constants'
import { DevicesPreview } from 'src/components/Devices/DevicesPreview'
import { Modal } from './Modal'
import { useInputChange } from 'src/effects/useInputChange'

type DevicesModalInCallProps = {
  callId: string
}

export const DevicesModalInCall: FC<DevicesModalInCallProps> = ({ callId }) => {
  const dispatch = useDispatch()
  const currentCamId = useSelector(getCameraId)
  const currentMicId = useSelector(getMicrophoneId)
  const currentSpeakerId = useSelector(getSpeakerId)
  const showModal = useSelector(isDevicesModalVisible)
  const [values, setValues] = useInputChange({
    camera: currentCamId || MediaDevice.Default,
    microphone: currentMicId || MediaDevice.Default,
    speaker: currentSpeakerId || MediaDevice.Default,
  })

  const confirmHandler = () => {
    const params = {
      callId,
      cameraId: values.camera,
      microphoneId: values.microphone,
      speakerId: values.speaker,
    }
    dispatch(deviceActions.promoteDevices())
    dispatch(updateMultipleDevices(params))
    cancelHandler()
  }

  const cancelHandler = () => {
    dispatch(uiActions.hide(Displayable.DevicesModal))
  }

  return (
    <Modal
      cancelHandler={cancelHandler}
      closeHandler={cancelHandler}
      confirmHandler={confirmHandler}
      confirmLabel={tr(Label.USE_THESE_DEVICES)}
      containerClassName='wide'
      show={showModal}
      testId={TestId.DevicesModal}
      title={tr(Label.SELECT_DEVICES)}
    >
      <DevicesPreview updateFn={setValues} />
    </Modal>
  )
}
