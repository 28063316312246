import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tr, Label } from 'src/common/i18n'
import { BarsIcon } from 'src/common/icons'
import { withAuth } from 'src/common/hoc'
import { isAppMenuVisible } from 'src/common/redux/features/ui/uiSelectors'
import { uiActions } from 'src/common/redux/features'
import { Displayable } from 'src/common/constants'
import { TestId } from 'src/constants'
import { BasicTooltip } from 'src/components/Tooltip'
import { AppMenu } from 'src/layout/AppMenu'
import { dismissAllToast } from 'src/services/ToastService'
import { TOOLTIP_OFFSET_Y_TOP_BAR } from 'src/hoc/withTextTooltip'
import { Button, ButtonWithTooltip } from '../Button/Button'

// This controls the offset of the menu itself.
const modifiers = [
  {
    name: 'offset',
    options: {
      offset: [0, TOOLTIP_OFFSET_Y_TOP_BAR],
    },
  },
  {
    name: 'preventOverflow',
    options: {
      padding: { right: 12 },
    },
  },
]

export const HamburgerButton: FC = withAuth(() => {
  const dispatch = useDispatch()
  const isVisible = useSelector(isAppMenuVisible)

  // Don't include the hover tooltip if the app menu tooltip is being shown
  const WrappedButton = (props: any) => {
    if (isVisible) {
      return <Button {...props} />
    }
    return (
      // Include the offsetY prop in the object to make sure it gets overridden.
      <ButtonWithTooltip {...{ ...props, offsetY: TOOLTIP_OFFSET_Y_TOP_BAR }} />
    )
  }

  return (
    <div>
      <BasicTooltip
        contentAreaClassName='overflow-y-auto'
        modifiers={modifiers}
        onVisibilityChange={() =>
          dispatch(uiActions.toggle(Displayable.AppMenu))
        }
        placement='bottom'
        tooltip={<AppMenu />}
        tooltipShown={isVisible}
        tooltipClassName='in-call-tooltip menu-tooltip z-60'
        trigger='click'
      >
        <WrappedButton
          ariaLabel={tr(Label.MENU)}
          className='p-2'
          onClick={dismissAllToast}
          testId={TestId.TopBarButtonRightSideMenu}
        >
          <BarsIcon size='lg' fixedWidth />
        </WrappedButton>
      </BasicTooltip>
    </div>
  )
})
