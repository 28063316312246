import { useHotkeys } from 'react-hotkeys-hook'
import { useDispatch, useSelector } from 'react-redux'
import { useDispatchRoomCommand, useMyZone } from 'src/common/hooks'
import { RoomCommandAction, Displayable } from 'src/common/constants'
import {
  callDTMF,
  uiActions,
  callScreenShareStart,
  callScreenShareStop,
} from 'src/common/redux/features'
import { canShare } from 'src/common/redux/features/participants/participantSelectors'
import { isScreenShareActive } from 'src/common/redux/views/callView/callViewSelectors'
import {
  getCallRoomId,
  getMyParticipantId,
} from 'src/common/redux/features/calls/callSelectors'
import { hasHideRaiseHandFeatureFlag } from 'src/common/redux/features/settings/settingsSelectors'
import { CantinaState } from 'src/common/redux/interfaces'
import { callViewActions } from 'src/common/redux/views'

const HOTKEYS = '0,1,2,3,4,5,6,7,8,9,p,m,v,r,c,s,h,k,a,o,d,left,right'
const SEEK_SPEED = '10000' // 10 seconds

export const CallShortcuts = ({ callId }: { callId: string }) => {
  const dispatch = useDispatch()
  const dispatchRoomCommand = useDispatchRoomCommand()
  const roomId = useSelector((state: CantinaState) =>
    getCallRoomId(state, callId)
  )
  const participantId = useSelector((state: CantinaState) =>
    getMyParticipantId(state)
  )
  const canShareScreen = useSelector((state: CantinaState) =>
    canShare(state, participantId)
  )
  const screenShareActive = useSelector(isScreenShareActive)
  const hideRaiseHand = useSelector(hasHideRaiseHandFeatureFlag)
  const { isInZone } = useMyZone()

  useHotkeys(
    HOTKEYS,
    (event: KeyboardEvent): any => {
      switch (event.key) {
        case 'p':
          event.preventDefault()
          return dispatch(callViewActions.toggleParticipantList())
        case 'm': // Audio mute
          return dispatch(callDTMF(callId, '0'))
        case 'v': // Video mute
          return dispatch(callDTMF(callId, '*0'))
        case 'r': // Room Navigator
          event.preventDefault()
          return dispatch(callViewActions.toggleRoomNavigator())
        case 'c': // ClipEEze
          event.preventDefault()
          return dispatch(callViewActions.toggleClipeeze())
        case 's': // Sidebar
          event.preventDefault()
          return dispatch(uiActions.toggle(Displayable.SidebarStartModal))
        case 'h': // Raise hand
          return hideRaiseHand ? null : dispatch(callDTMF(callId, '#5'))
        case 'k': // Share screen
          if (!canShareScreen || isInZone) {
            return null
          }
          if (screenShareActive) {
            return dispatch(callScreenShareStop(callId))
          } else {
            return dispatch(callScreenShareStart(callId))
          }
        case 'a': // Advanced settings
          return dispatch(callViewActions.toggleCallSettings())
        case 'o': // Chat
          return dispatch(callViewActions.toggleChat())
        case 'd': // DND
          return dispatch(callDTMF(callId, '#2'))
        case 'ArrowLeft':
          return dispatchRoomCommand({
            roomId,
            action: RoomCommandAction.SeekPlayback,
            speed: SEEK_SPEED,
            direction: '-',
          })
        case 'ArrowRight':
          return dispatchRoomCommand({
            roomId,
            action: RoomCommandAction.SeekPlayback,
            speed: SEEK_SPEED,
            direction: '+',
          })
        default:
          return dispatch(callDTMF(callId, event.key))
      }
    },
    {},
    [roomId, canShareScreen, isInZone, screenShareActive]
  )

  // TODO: asterisk/hashtag valid for US keyboard only
  useHotkeys('shift+8,shift+3', (event: KeyboardEvent): any => {
    switch (event.key) {
      case '#':
      case '*':
        return dispatch(callDTMF(callId, event.key))
    }
  })

  return null
}
