import { PayloadAction } from '@reduxjs/toolkit'
import { SagaIterator } from 'redux-saga'
import { take, put, call, fork, select, delay } from 'redux-saga/effects'
import { encode as Base64Encode } from 'js-base64'
import * as Relay from '../../../services/relay'
import {
  matchURLFlags,
  checkValidPlaybackURL,
  checkValidRtmpURL,
  stripSpaces,
} from '../../../services/helpers'
import {
  ChatMessageType,
  MixPanelEvent,
  RoomCommandAction,
  Scope,
} from '../../../constants'
import { RoomCommandPayload } from '../../interfaces'
import { roomsActions, roomCommand } from './roomsSlice'
import {
  getFilePlaybackRole,
  getPlayingSettings,
  getRoomUuid,
} from './roomsSelectors'
import { getRoomUuid as getRoomListUuid } from '../roomList/roomListSelectors'
import {
  getClipeezePlayOptions,
  getDefaultPlaybackVolume,
  getParticipantIdsToTransfer,
} from '../../views/callView/callViewSelectors'
import {
  getParticipantRoomId,
  getParticipantIdsByRoomId,
  getRoomParticipantIdsByRoomZoneId,
} from '../../features/participants/participantSelectors'
import {
  getActiveCallId,
  getActiveCallRoomId,
  getMyParticipantId,
} from '../calls/callSelectors'
import { callViewActions } from '../../views'
import { getName, getEmail } from '../auth/authSelectors'
import { hasScope } from '../scopes/scopesSelectors'
import { callRequest } from '../calls/callSlice'
import { trackRoomCommandWorker } from '../mixpanel/mixpanelSaga'
import { callLoaderBeginAction, mixPanelTrackAction } from '../../actions'
import { getBroadcasterBaseUrl } from '../settings/settingsSelectors'

const PDF_PATTERN = /\.pdf$|\.pdf\?.*$/i

/**
 * This worker handles all the "RoomCommand" to control a conference.
 * There's one case where we need to control them from the Lobby:
 * Lobby > Room Card > Cog Icon > Room Controls
 * In that case we have the roomsSlice empty but the roomListSlice with
 * data from Cantina-Manager events.
 * For this reason we need to use two different selectors:
 * - getRoomUuid: when we are in actively the room
 * - getRoomListUuid: when we control the room from Lobby.
 *
 * This is required only during the migration where CM doesn't support
 * all the features we need on the UI and we're still using a FS box.
 */
export function* roomCommandWorker({
  payload,
}: PayloadAction<RoomCommandPayload>): SagaIterator {
  let roomUuid = yield select(getRoomUuid, payload.roomId)
  if (!roomUuid) {
    roomUuid = yield select(getRoomListUuid, payload.roomId)
    if (!roomUuid) {
      return console.warn('Unknown room', payload)
    }
  }
  let relayConference = yield call(Relay.getRelayConference, roomUuid)
  if (!relayConference) {
    /**
     * Without a relayConference check for the active call and
     * fallback to the relayCall object that has the same
     * methods of relayConference.
     */
    const currentRoomId = yield select(getActiveCallRoomId)
    if (currentRoomId === payload.roomId) {
      const callId = yield select(getActiveCallId)
      relayConference = yield call(Relay.getRelayCall, callId)
    }
    if (!relayConference) {
      if (!payload._retry) {
        console.warn('Reload cache')
        yield call(Relay.loadVertoRooms)
        yield delay(500)
        const newAction = roomCommand({
          ...payload,
          _retry: true,
        })
        yield fork(roomCommandWorker, newAction)
        return console.warn('Retry room command for', roomUuid)
      }

      return console.warn('Unknown relayConference', roomUuid)
    }
  }
  // TODO: check for scope using a map scopeByCommand
  switch (payload.action) {
    case RoomCommandAction.AudioMuteAll:
      return relayConference.muteAudioAll()
    case RoomCommandAction.AudioMuteParticipant: {
      const myParticipantId = yield select(getMyParticipantId)
      if (payload.participantId === myParticipantId) {
        const callId = yield select(getActiveCallId)
        return Relay.dtmfCall(callId, '0')
      }
      return relayConference.muteAudio(payload.participantId)
    }
    case RoomCommandAction.AudioUnMuteAll:
      return relayConference.unmuteAudioAll()
    case RoomCommandAction.AudioUnMuteParticipant: {
      const myParticipantId = yield select(getMyParticipantId)
      if (payload.participantId === myParticipantId) {
        const callId = yield select(getActiveCallId)
        return Relay.dtmfCall(callId, '0')
      }
      return relayConference.unmuteAudio(payload.participantId)
    }
    case RoomCommandAction.VideoMuteAll:
      return relayConference.muteVideoAll()
    case RoomCommandAction.VideoMuteParticipant: {
      const myParticipantId = yield select(getMyParticipantId)
      if (payload.participantId === myParticipantId) {
        const callId = yield select(getActiveCallId)
        return Relay.dtmfCall(callId, '*0')
      }
      return relayConference.muteVideo(payload.participantId)
    }
    case RoomCommandAction.VideoUnMuteAll:
      return relayConference.unmuteVideoAll()
    case RoomCommandAction.VideoUnMuteParticipant: {
      const myParticipantId = yield select(getMyParticipantId)
      if (payload.participantId === myParticipantId) {
        const callId = yield select(getActiveCallId)
        return Relay.dtmfCall(callId, '*0')
      }
      return relayConference.unmuteVideo(payload.participantId)
    }
    case RoomCommandAction.DeafParticipant: {
      const myParticipantId = yield select(getMyParticipantId)
      if (payload.participantId === myParticipantId) {
        const callId = yield select(getActiveCallId)
        return Relay.dtmfCall(callId, '#2')
      }
      return relayConference.deaf(payload.participantId)
    }
    case RoomCommandAction.UndeafParticipant: {
      const myParticipantId = yield select(getMyParticipantId)
      if (payload.participantId === myParticipantId) {
        const callId = yield select(getActiveCallId)
        return Relay.dtmfCall(callId, '#2')
      }
      return relayConference.undeaf(payload.participantId)
    }
    case RoomCommandAction.ShowBanners:
      // return relayConference.confFullscreen('all', 'false')
      return relayConference.setVidLogos('on')
    case RoomCommandAction.HideBanners:
      // return relayConference.confFullscreen('all', 'true')
      return relayConference.setVidLogos('off')
    case RoomCommandAction.ShuffleVideo: {
      const { seconds } = payload
      if (seconds !== 'now') {
        const frequency = parseInt(seconds)
        if (!seconds || !Number.isInteger(frequency) || frequency < 0) {
          return
        }
      }
      return relayConference.shuffleVideo(String(payload.seconds))
    }
    case RoomCommandAction.SetBannerText: {
      const text = Base64Encode(payload.text || '')
      return text && relayConference.setBanner(payload.participantId, text)
    }
    case RoomCommandAction.RestoreBannerText: {
      return relayConference.setBanner(
        payload.participantId,
        Base64Encode('_default_')
      )
    }
    case RoomCommandAction.MemberShowBanner:
      return relayConference.confFullscreen(payload.participantId, 'false')
    case RoomCommandAction.MemberHideBanner:
      return relayConference.confFullscreen(payload.participantId, 'true')
    case RoomCommandAction.KickParticipant:
      return relayConference.kick(payload.participantId)
    case RoomCommandAction.KickAll:
      return relayConference.kick('all')
    case RoomCommandAction.Lock:
      return relayConference.lock()
    case RoomCommandAction.UnLock:
      return relayConference.unlock()
    case RoomCommandAction.EnableClipeeze:
      return relayConference.setConfVariable('public_clipeeze', 'true')
    case RoomCommandAction.DisableClipeeze:
      return relayConference.setConfVariable('public_clipeeze', 'false')
    case RoomCommandAction.EnableSilentMode:
      return relayConference.setSilentMode('on')
    case RoomCommandAction.DisableSilentMode:
      return relayConference.setSilentMode('off')
    case RoomCommandAction.EnableBlindMode:
      return relayConference.setBlindMode('on')
    case RoomCommandAction.DisableBlindMode:
      return relayConference.setBlindMode('off')
    case RoomCommandAction.EnableMeetingMode:
      return relayConference.setMeetingMode('on')
    case RoomCommandAction.DisableMeetingMode:
      return relayConference.setMeetingMode('off')
    case RoomCommandAction.EnableHideVMutedMode:
      return relayConference.toggleVidMuteHide('on')
    case RoomCommandAction.DisableHideVMutedMode:
      return relayConference.toggleVidMuteHide('off')
    case RoomCommandAction.SetPin:
      return relayConference.setPin(payload.pin)
    case RoomCommandAction.UnsetPin:
      return relayConference.removePin()
    case RoomCommandAction.SetModeratorPin:
      return relayConference.setModeratorPin(payload.pin)
    case RoomCommandAction.UnsetModeratorPin:
      return relayConference.removeModeratorPin()
    case RoomCommandAction.SetCanvasLayout:
      return relayConference.setVideoLayout(payload.layoutId)
    case RoomCommandAction.SetVideoQuality:
      return relayConference.confQuality(payload.videoQuality)
    case RoomCommandAction.SetMotionQuality:
      // room motion quality
      return relayConference.confMotionQuality(String(payload.value))
    case RoomCommandAction.SetMotionQualityInbound:
      // all members inbound motion quality
      return relayConference.confMotionQualityInbound(String(payload.value))
    case RoomCommandAction.SetMotionQualityMember:
      // member motion quality
      return relayConference.confMotionQualityMember(
        payload.participantId,
        String(payload.value)
      )
    case RoomCommandAction.SetPlaybackRole: {
      const playSettings = yield select(getPlayingSettings, payload.roomId)

      if (!playSettings) {
        return console.debug('No playback to set role', payload)
      }
      return relayConference.fileReservationId(
        payload.reservationId,
        playSettings.async
      )
    }
    case RoomCommandAction.SetFilePlaybackRole: {
      return relayConference.modCommand(
        'vid-playback-role',
        null,
        String(payload.reservationId)
      )
    }
    case RoomCommandAction.StartPlayback: {
      if (!payload.url) {
        return console.debug('No URL to play', payload)
      }
      const { flags, value } = matchURLFlags(payload.url)
      const isValid = checkValidPlaybackURL(value)
      if (!isValid) {
        yield put(
          roomsActions.createOrUpdate({
            id: payload.roomId,
            playingError: true,
          })
        )
        return
      }
      const defaultVolume = yield select(
        getDefaultPlaybackVolume,
        payload.roomId
      )
      let finalFlags = [`vol=${defaultVolume}`]
      if (flags) {
        finalFlags = flags.substring(1, flags.length - 1).split(',')
        finalFlags.unshift(`vol=${defaultVolume}`)
      }

      if (payload.role && payload.role !== 'clear') {
        finalFlags.unshift(`reservation_id=${payload.role}`)
      }

      const media = `{${finalFlags.join(',')}}${value}`
      yield put(
        roomsActions.createOrUpdate({ id: payload.roomId, playingError: false })
      )
      yield put(callViewActions.setRecentlyPlayedVideos(payload.url))
      return relayConference.playMedia(media)
    }
    case RoomCommandAction.SeekPlayback: {
      const playSettings = yield select(getPlayingSettings, payload.roomId)
      if (!playSettings) {
        return console.debug('No playback to seek', payload)
      }
      const time = PDF_PATTERN.test(playSettings.fileName) ? 1 : payload.speed
      return relayConference.fileSeek(
        `${payload.direction}${time}`,
        playSettings.async
      )
    }
    case RoomCommandAction.PausePlayback: {
      const playSettings = yield select(getPlayingSettings, payload.roomId)
      if (!playSettings) {
        return console.debug('No playback to pause', payload)
      }
      // it handles pause and resume
      return relayConference.pauseMedia(playSettings.async)
    }
    case RoomCommandAction.SetPlaybackVolume: {
      const playSettings = yield select(getPlayingSettings, payload.roomId)
      if (!playSettings) {
        return console.debug('No playback to set volume', payload)
      }
      return relayConference.fileVolume(payload.volume, playSettings.async)
    }
    case RoomCommandAction.StopPlayback:
      return relayConference.stopMedia()
    case RoomCommandAction.SendTTS:
      return relayConference.sayAll(payload.message)
    case RoomCommandAction.SendMemberTTS:
      return relayConference.sayMember(payload.participantId, payload.message)
    case RoomCommandAction.SetVolumeAudience:
      return relayConference.setVolumeAudience(String(payload.volume))
    case RoomCommandAction.StartRemoteBroadcast: {
      if (!payload.url) {
        return console.debug('No URL for recording', payload)
      }
      const { flags, value } = matchURLFlags(payload.url)
      if (!checkValidRtmpURL(value)) {
        yield put(
          roomsActions.createOrUpdate({
            id: payload.roomId,
            recordingError: 'Invalid Streaming URL',
          })
        )
        return
      }
      if (payload.withBackup === true) {
        relayConference.startRecord('recording.mp4')
      }
      return relayConference.startRecord(flags + value)
    }
    case RoomCommandAction.StartLocalBroadcast: {
      const broadcasterBaseUrl = yield select(getBroadcasterBaseUrl)
      const localUrl = `${broadcasterBaseUrl}${roomUuid}`
      if (payload.withBackup === true) {
        relayConference.startRecord('recording.mp4')
      }
      return relayConference.startRecord(localUrl)
    }
    case RoomCommandAction.StartFileRecording:
      return relayConference.startRecord('recording.mp4')
    case RoomCommandAction.StopRecording:
      return relayConference.stopRecord()
    case RoomCommandAction.EnableNoiseBlocker:
      return relayConference.setDenoise(payload.participantId, 'on')
    case RoomCommandAction.DisableNoiseBlocker:
      return relayConference.setDenoise(payload.participantId, 'off')
    case RoomCommandAction.EnableLowBitrateMode:
      return relayConference.setLowBitrate(payload.participantId, 'on')
    case RoomCommandAction.DisableLowBitrateMode:
      return relayConference.setLowBitrate(payload.participantId, 'off')
    case RoomCommandAction.RaiseHand: {
      const myParticipantId = yield select(getMyParticipantId)
      if (payload.participantId === myParticipantId) {
        const callId = yield select(getActiveCallId)
        return Relay.dtmfCall(callId, '#5')
      }
      return relayConference.setHandRaised(payload.participantId, 'on')
    }
    case RoomCommandAction.LowerHand: {
      const myParticipantId = yield select(getMyParticipantId)
      if (payload.participantId === myParticipantId) {
        const callId = yield select(getActiveCallId)
        return Relay.dtmfCall(callId, '#5')
      }
      return relayConference.setHandRaised(payload.participantId, 'off')
    }
    case RoomCommandAction.AllowHandraiseOnscreen:
      return relayConference.setHandraiseOnscreen('on')
    case RoomCommandAction.DisallowHandraiseOnscreen:
      return relayConference.setHandraiseOnscreen('off')
    case RoomCommandAction.LowerAllHands:
      return relayConference.setHandRaised('all', 'off')
    case RoomCommandAction.AddToCall: {
      if (!payload.extension) {
        return console.debug('Missing extension', payload)
      }
      const myEmail = yield select(getEmail)
      const myName = yield select(getName)
      return relayConference.addToCall(payload.extension, myEmail, myName)
    }
    case RoomCommandAction.SetPerformerDelay:
      return relayConference.setPerformerDelay(String(payload.delay))
    case RoomCommandAction.GrantModerator:
      return relayConference.grantModerator(payload.participantId, 'true')
    case RoomCommandAction.RevokeModerator:
      return relayConference.grantModerator(payload.participantId, 'false')
    case RoomCommandAction.GrantScreenShare:
      return relayConference.grantScreenShare(payload.participantId, 'true')
    case RoomCommandAction.RevokeScreenShare:
      return relayConference.grantScreenShare(payload.participantId, 'false')
    case RoomCommandAction.RoomNavigatorTransfer: {
      const canTransfer = yield select(hasScope, Scope.ConferenceTransfer)
      if (!canTransfer) {
        yield call(guestTransferWorker, payload.extension)
        break
      }

      const participantIds: string[] = yield select(getParticipantIdsToTransfer)
      if (!participantIds.length) {
        return console.debug('No participants to transfer', payload)
      }
      const roomIdsMap: { [roomId: string]: string[] } = {}
      for (let i = 0; i < participantIds.length; i++) {
        const participantRoomId = yield select(
          getParticipantRoomId,
          participantIds[i]
        )
        if (!roomIdsMap[participantRoomId]) {
          roomIdsMap[participantRoomId] = []
        }
        roomIdsMap[participantRoomId].push(participantIds[i])
      }
      const roomIds = Object.keys(roomIdsMap)
      for (let i = 0; i < roomIds.length; i++) {
        const roomId = roomIds[i]
        const allIds = yield select(getParticipantIdsByRoomId, roomId)
        const selectedIds = roomIdsMap[roomId] || []
        // Send the keyword "all" if we have to transfer the entire room (all the participantIds)
        // Otherwise send the ids separated by a space ("p0 p1 p2" or just "p0")
        const participantId =
          allIds.length === selectedIds.length ? 'all' : selectedIds.join(' ')
        const action = roomCommand({
          roomId,
          participantId,
          action: RoomCommandAction.Transfer,
          extension: payload.extension,
        })
        yield fork(roomCommandWorker, action)
      }
      yield put(callViewActions.clearParticipantIdsToTransfer())
      yield put(callViewActions.clearRoomNavigatorFilter())
      break
    }
    case RoomCommandAction.Transfer: {
      const canTransfer = yield select(hasScope, Scope.ConferenceTransfer)
      if (!canTransfer) {
        yield call(guestTransferWorker, payload.extension)
        break
      }

      return relayConference.transferMember(
        payload.participantId,
        payload.extension
      )
    }
    case RoomCommandAction.MemberEnergy:
      return relayConference.setEnergy(
        payload.participantId,
        String(payload.value * 100)
      )
    case RoomCommandAction.MemberVolume:
      return relayConference.modCommand(
        'volume_out',
        payload.participantId,
        String(payload.value)
      )
    case RoomCommandAction.MemberGain:
      return relayConference.modCommand(
        'volume_in',
        payload.participantId,
        String(payload.value)
      )
    case RoomCommandAction.SetVideoFloor:
      return relayConference.videoFloor(payload.participantId)
    case RoomCommandAction.SetLayoutRole:
      return relayConference.setReservationId(
        payload.participantId,
        payload.reservationId
      )
    case RoomCommandAction.EnableSpeakerHighlight:
      return relayConference.setConfVariable('speaker_highlight', 'true')
    case RoomCommandAction.DisableSpeakerHighlight:
      return relayConference.setConfVariable('speaker_highlight', 'false')
    case RoomCommandAction.EnableIntercom:
      return relayConference.setConfVariable('disable_intercom', 'false')
    case RoomCommandAction.DisableIntercom:
      return relayConference.setConfVariable('disable_intercom', 'true')
    case RoomCommandAction.SetVidFloorResId:
      return relayConference.setVidFloorResId(String(payload.reservationId))
    case RoomCommandAction.EnableEchoCancellation:
      return relayConference.modCommand(
        'audio-flags-member',
        payload.participantId,
        'ec'
      )
    case RoomCommandAction.DisableEchoCancellation:
      return relayConference.modCommand(
        'audio-flags-member',
        payload.participantId,
        '-ec'
      )
    case RoomCommandAction.EnableNoiseSuppression:
      return relayConference.modCommand(
        'audio-flags-member',
        payload.participantId,
        'nsup'
      )
    case RoomCommandAction.DisableNoiseSuppression:
      return relayConference.modCommand(
        'audio-flags-member',
        payload.participantId,
        '-nsup'
      )
    case RoomCommandAction.EnableAutoGainControl:
      return relayConference.modCommand(
        'audio-flags-member',
        payload.participantId,
        'agc'
      )
    case RoomCommandAction.DisableAutoGainControl:
      return relayConference.modCommand(
        'audio-flags-member',
        payload.participantId,
        '-agc'
      )
    case RoomCommandAction.ZoneCreate: {
      /**
       * We can also select the participantIds from redux in case the UI
       * requires to save the list of selected users in the callViewState.
       */

      const { roomId, participantIds = [] } = payload
      // Check sidebar name and strip spaces
      const zoneName = stripSpaces(payload.zoneName)
      if (!zoneName) {
        return console.warn('Invalid sidebar name to create', payload)
      }
      const myParticipantId = yield select(getMyParticipantId)
      const invitedParticipants = participantIds.filter(
        (id: string) => id !== myParticipantId
      )

      if (invitedParticipants?.length) {
        // Send invites via chatChannel
        const type = JSON.stringify({
          type: ChatMessageType.RoomZoneInvitationRequest,
          sendingParticipantId: myParticipantId,
          receivingParticipantIds: invitedParticipants,
          zoneName,
        })
        relayConference.sendChatMessage('', type)
      }

      // Add the zone originator to the zone w/o sending an invitation
      if (participantIds.includes(myParticipantId)) {
        const action = roomCommand({
          action: RoomCommandAction.ZoneJoin,
          participantId: myParticipantId,
          roomId,
          zoneName,
        })
        yield fork(roomCommandWorker, action)
      }

      break
    }
    case RoomCommandAction.ZoneInvite: {
      const myParticipantId = yield select(getMyParticipantId)
      const { participantId, zoneName } = payload

      const type = JSON.stringify({
        type: ChatMessageType.RoomZoneInvitationRequest,
        sendingParticipantId: myParticipantId,
        receivingParticipantIds: participantId,
        zoneName,
      })
      relayConference.sendChatMessage('', type)

      break
    }
    case RoomCommandAction.ZoneKick: {
      const { participantId, zoneName } = payload

      const type = JSON.stringify({
        type: ChatMessageType.RoomZoneKick,
        receivingParticipantId: participantId,
        zoneName,
      })
      relayConference.sendChatMessage('', type)

      return relayConference.infoCommand('zone-member', participantId, [
        `${zoneName} part`,
      ])

      break
    }
    case RoomCommandAction.ZoneDestroy: {
      const zoneName = stripSpaces(payload.zoneName)
      const { roomId, zoneId } = payload
      const participantIds = yield select(
        getRoomParticipantIdsByRoomZoneId,
        roomId,
        zoneId
      )

      if (!zoneId) {
        return console.warn('Invalid sidebar id to destroy', payload)
      }

      // Send a toast via chatChannel
      const type = JSON.stringify({
        type: ChatMessageType.RoomZoneDestroyed,
        participantIds,
        zoneName,
      })
      relayConference.sendChatMessage('', type)

      return relayConference.infoCommand('zonectl', null, [`${zoneName} end`])
    }
    case RoomCommandAction.ZoneJoin: {
      const zoneName = stripSpaces(payload.zoneName)

      if (!zoneName) {
        return console.warn('Invalid sidebar name to join', payload)
      }

      return relayConference.infoCommand('zone-member', payload.participantId, [
        `${zoneName} join`,
      ])
    }
    case RoomCommandAction.ZonePart: {
      const zoneName = stripSpaces(payload.zoneName)

      if (!zoneName) {
        return console.warn('Invalid sidebar name to part', payload)
      }

      return relayConference.infoCommand('zone-member', payload.participantId, [
        `${zoneName} part`,
      ])
    }
    case RoomCommandAction.ZoneInvitationDeclined: {
      const myParticipantId = yield select(getMyParticipantId)
      const sendingParticipantName = yield select(getName)
      const type = JSON.stringify({
        type: ChatMessageType.RoomZoneInvitationDeclined,
        receivingParticipantId: payload.zoneCreatorId,
        sendingParticipantId: myParticipantId,
        sendingParticipantName,
        zoneName: payload.zoneName,
      })

      return relayConference.sendChatMessage('', type)
    }
    case RoomCommandAction.ZoneRequestToJoin: {
      /**
       * Send chat msg to every user in the sidebar
       */
      const {
        requestingParticipantAvatar,
        requestingParticipantId,
        requestingParticipantName,
        roomId,
        roomZoneId,
      } = payload

      const participantIds = yield select(
        getRoomParticipantIdsByRoomZoneId,
        roomId,
        roomZoneId
      )

      if (participantIds?.length) {
        const type = JSON.stringify({
          type: ChatMessageType.RoomZoneRequestToJoin,
          receivingParticipantIds: participantIds,
          requestingParticipantAvatar,
          requestingParticipantId,
          requestingParticipantName,
          roomZoneId,
        })
        relayConference.sendChatMessage('', type)
      }
      break
    }
    case RoomCommandAction.ZoneAllowToJoin: {
      /**
       * 1. Broadcast msg to every user in the zone to subtract from the zoneRequestToJoinList.
       * 2. Fork worker with ZoneJoin to allow the user join the zone.
       */
      const {
        requestingParticipantId,
        requestingParticipantName,
        roomId,
        roomZoneId,
        zoneName,
      } = payload
      const participantIds = yield select(
        getRoomParticipantIdsByRoomZoneId,
        roomId,
        roomZoneId
      )

      if (participantIds?.length) {
        // Notify others in the zone
        const type = JSON.stringify({
          type: ChatMessageType.RoomZoneDismissRequestToJoin,
          requestAllowed: true,
          requestingParticipantId,
          requestingParticipantName,
          receivingParticipantIds: participantIds,
        })
        relayConference.sendChatMessage('', type)

        // Add the requestingParticipant to the zone
        const action = roomCommand({
          action: RoomCommandAction.ZoneJoin,
          participantId: requestingParticipantId,
          roomId,
          zoneName,
        })
        yield fork(roomCommandWorker, action)
      }
      break
    }
    case RoomCommandAction.ZoneDenyToJoin: {
      /**
       * 1. Broadcast msg to every users in the zone to subtract from the zoneRequestToJoinList.
       * 2. Send chat msg to the user to send rejection toast
       */
      const {
        requestingParticipantId,
        requestingParticipantName,
        roomId,
        roomZoneId,
      } = payload
      const participantIds = yield select(
        getRoomParticipantIdsByRoomZoneId,
        roomId,
        roomZoneId
      )

      if (participantIds?.length) {
        const sendingParticipantName = yield select(getName)

        // Notify others in the zone
        const dismissMsg = JSON.stringify({
          type: ChatMessageType.RoomZoneDismissRequestToJoin,
          requestAllowed: false,
          requestingParticipantId,
          requestingParticipantName,
          receivingParticipantIds: participantIds,
        })
        relayConference.sendChatMessage('', dismissMsg)

        // Notify the requester that they have been denied
        const deniedMsg = JSON.stringify({
          type: ChatMessageType.RoomZoneDenyToJoin,
          sendingParticipantName,
          deniedParticipantId: requestingParticipantId,
        })
        relayConference.sendChatMessage('', deniedMsg)
      }
      break
    }
    case RoomCommandAction.ZoneSetExternalVolume: {
      return relayConference.infoCommand('zonectl', null, [
        `${payload.zoneId} extvol ${payload.volume}`,
      ])
    }
    case RoomCommandAction.PlayClipeeze: {
      const { clipId, roomId } = payload
      const role = yield select(getFilePlaybackRole, roomId)
      const { clipeezeLoop } = yield select(getClipeezePlayOptions)

      // Multiple flags can be delimited by a colon
      const flags = clipeezeLoop ? 'loop' : ''

      yield put(mixPanelTrackAction({ event: MixPanelEvent.Clipeeze }))

      return relayConference.infoCommand('clipeeze.lua', null, [
        clipId,
        role ?? '',
        flags,
      ])
    }
    case RoomCommandAction.LiveArrayBootstrap:
      console.debug('Invoke LiveArrayBootstrap')
      return relayConference.liveArrayBootstrap()
    default:
      console.warn('Unknown room command action', payload)
  }
}

/**
 * Mock a transfer for a guest without transfer permission
 * Hangup the current call and make a new one.
 */
export function* guestTransferWorker(extension: string): SagaIterator {
  yield put(callLoaderBeginAction())
  console.warn('Invoke disconnectFromVertoFS [from worker]')
  yield call(Relay.disconnectFromVertoFS)
  const params = { extension, hasVideo: true, force: true }
  yield put(callRequest(params))
  yield put(callViewActions.clearParticipantIdsToTransfer())
  yield put(callViewActions.clearRoomNavigatorFilter())
}

export function* watchRoomCommands(): SagaIterator {
  while (true) {
    const action = yield take(roomCommand.type)
    // Send EVERY roomCommand action to mixPanel
    yield fork(trackRoomCommandWorker, action)
    yield fork(roomCommandWorker, action)
  }
}
