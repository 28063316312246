import React, { ComponentType } from 'react'

/**
 * This HOC is just a decorator for the in-call buttons.
 * It should be used in conjunction with a "withScope" HOC.
 * Very simple: it adds markup to the WrappedComponent if it is a valid component
 * - withScope returns null if the user does not have the proper scope
 */
type WithInCallButtonWrapperProps = {
  label: string
  offLabel?: string
  enabled?: boolean
  className?: string
}

export const withInCallButtonWrapper = <T extends object>(
  WrappedComponent: ComponentType<T>
) => {
  return (props: T & WithInCallButtonWrapperProps) => {
    if (!WrappedComponent) {
      return null
    }

    const { className = '' } = props
    return (
      <div className={`flex items-center flex-col px-2 mb-2 ${className}`}>
        <WrappedComponent {...props} />
        <span className='text-xs text-center leading-4 lg:leading-2 font-medium'>
          {props.offLabel
            ? props.enabled
              ? props.label
              : props.offLabel
            : props.label}
        </span>
      </div>
    )
  }
}
