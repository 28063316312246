import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { CantinaState } from 'src/common/redux/interfaces'
import { RoomCommandAction } from 'src/common/constants'
import { useDispatchRoomParticipantCommand } from 'src/common/hooks'
import { WaveformIcon } from 'src/common/icons'
import { Label } from 'src/common/i18n'
import { hasDenoise } from 'src/common/redux/features/participants/participantSelectors'
import { TestId } from 'src/constants'
import { ButtonFeatureToggle } from 'src/components/Inputs/ButtonFeatureToggle'

type BackgroundNoiseToggleProps = {
  participantId: string
}

export const BackgroundNoiseToggle: FC<BackgroundNoiseToggleProps> = ({
  participantId,
}) => {
  const dispatchRoomParticipantCommand =
    useDispatchRoomParticipantCommand(participantId)
  const denoise = useSelector((state: CantinaState) =>
    hasDenoise(state, participantId)
  )

  return (
    <ButtonFeatureToggle
      checked={denoise}
      Icon={WaveformIcon}
      label={Label.REMOVE_BACKGROUND_NOISE}
      onToggleOn={() => {
        dispatchRoomParticipantCommand({
          action: RoomCommandAction.EnableNoiseBlocker,
          participantId,
        })
      }}
      onToggleOff={() => {
        dispatchRoomParticipantCommand({
          action: RoomCommandAction.DisableNoiseBlocker,
          participantId,
        })
      }}
      testId={TestId.AudioToggleDenoise}
    />
  )
}
