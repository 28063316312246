import React from 'react'

const Speaking = ({ className = 'w-8 h-8' }) => (
  <div className={`audio-icon ${className}`}>
    <svg width='100%' height='100%' viewBox='0 0 32 20'>
      <g transform='translate(0 1)'>
        <rect className='audio-icon__bar' width='5' rx='2.5' />
        <rect className='audio-icon__bar' width='5' rx='2.5' />
        <rect className='audio-icon__bar' width='5' rx='2.5' />
        <rect className='audio-icon__bar' width='5' rx='2.5' />
      </g>
    </svg>
  </div>
)

export default Speaking
