import { useState } from 'react'
import { useInternalAPI } from './useInternalAPI'
import { RailsEndpoint, RequestState } from '../rest'

type UseDeleteUserData = {
  error: string | null
  requestState: RequestState
}

const DEFAULT_DATA: UseDeleteUserData = {
  error: null,
  requestState: RequestState.Loading,
}

const deleteOptions = {
  endpoint: RailsEndpoint.Users,
  method: 'DELETE',
}

export const useDeleteUser = (userId: string) => {
  const { invokeAPI: deleteHandler } = useInternalAPI(deleteOptions)
  const [data, setData] = useState<UseDeleteUserData>(DEFAULT_DATA)

  // delete is a private keyword in strict mode
  const performDelete = async () => {
    try {
      setData(DEFAULT_DATA)
      await deleteHandler({
        urlTransform: (url) => `${url}/${userId}`,
      })
      setData({
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      setData({
        requestState: RequestState.Error,
        error: 'Error deleting user',
      })
    }
  }

  return { ...data, deleteUser: performDelete }
}
