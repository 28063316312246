import React from 'react'
import { SpinnerIcon } from 'src/common/icons'
import { CenteredBox } from 'src/layout'
import { SimpleHeaderWithoutLink } from 'src/components/Header/SimpleHeader'

export const LoadingBox = ({ text = 'Loading...' }) => (
  <>
    <SimpleHeaderWithoutLink />
    <CenteredBox>
      <div className='text-center mb-6'>
        <div className='text-center text-contrast-h text-4xl font-neue'>
          {text}
        </div>
        <div className='text-contrast-m p-6 text-center w-2/3 m-auto'>
          <SpinnerIcon />
        </div>
      </div>
    </CenteredBox>
  </>
)
