import React from 'react'
import { Header } from './Header'

const SimpleHeader = () => {
  return <Header />
}

export const SimpleHeaderWithoutLink = () => {
  return <Header middle={null} />
}

export default SimpleHeader
