import { useState } from 'react'
import { RailsEndpoint, RequestState } from '../rest'
import { useInternalAPI } from './useInternalAPI'

type UseDeleteRoomData = {
  requestState: RequestState
  error: string | null
}
const deleteOptions = {
  endpoint: RailsEndpoint.Rooms,
  method: 'DELETE',
}

export const useDeleteRoom = (roomId: string) => {
  const { invokeAPI: deleteHandler } = useInternalAPI(deleteOptions)

  const [data, setData] = useState<UseDeleteRoomData>({
    requestState: RequestState.Loading,
    error: null,
  })

  // delete is a private keyword in strict mode
  const performDelete = async () => {
    try {
      setData({
        requestState: RequestState.Loading,
        error: null,
      })
      await deleteHandler({
        urlTransform: (url) => {
          return `${url}/${roomId}`
        },
      })
      setData({
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      setData({
        requestState: RequestState.Error,
        error: 'Error deleting room',
      })
    }
  }

  return { data, deleteRoom: performDelete }
}
