import React, { FC } from 'react'
import { CURRENT_HOSTNAME } from 'src/common/constants'
import { PrimaryButton } from './Button/Button'

type RoomCustomButtonsProps = {
  buttons: ({ text: string; url: string } | null)[]
  roomLocked?: boolean
}

export const RoomCustomButtons: FC<RoomCustomButtonsProps> = ({
  buttons = [],
  roomLocked = false,
}) => (
  <>
    {buttons.map((button, index) => {
      if (!button) {
        return null
      }

      const { text, url } = button
      let disabled = false
      let target
      let rel

      // Check for a room link, which should open in the current tab
      if (url.includes(`${CURRENT_HOSTNAME}/rooms/`)) {
        disabled = roomLocked
      } else {
        target = '_blank'
        rel = 'noreferrer'
      }

      return (
        <a
          className='overflow-hidden text-sm'
          href={url}
          key={`custom-btn-${index}`}
          rel={rel}
          target={target}
        >
          <PrimaryButton
            className='sw-btn-xs w-full max-w-[150px] truncate'
            disabled={disabled}
          >
            {text}
          </PrimaryButton>
        </a>
      )
    })}
  </>
)
