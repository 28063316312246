import React, { FC, useEffect } from 'react'
import { compose } from 'redux'
import { Redirect } from 'react-router'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'

import { Loader, RoutePath } from 'src/common/constants'
import { withActiveCall, withLoading } from 'src/common/hoc'
import { CantinaState } from 'src/common/redux/interfaces'
import { useFade, useMyZone, useWatchPip } from 'src/common/hooks'

import {
  isReceivingVideo,
  isSendingVideo,
  getCallPlaceholderImage,
  hasDeviceError as hasDeviceErrorSelector,
} from 'src/common/redux/features/calls/callSelectors'
import { isCallSettingsShown } from 'src/common/redux/views/callView/callViewSelectors'

import CallHeader from 'src/components/Header/CallHeader'
import CallLoading from 'src/components/CallLoading'
import { BottomBar } from './BottomBar'
import { MCU, MCU_ELEMENT_CLASS } from 'src/components/Mcu/MCU'
import { MCUOverlayImages } from 'src/components/Mcu/MCUOverlayImages'
import { Layers } from 'src/components/Mcu'
// import DialpadModal from 'src/modals/DialpadModal'
import { SecondSourceModal } from 'src/modals/SecondSourceModal'
import { DevicesModalInCall } from 'src/modals/DevicesModalInCall'
import { isSidebarStartModalVisible } from 'src/common/redux/features/ui/uiSelectors'
import StartSideBarModal from 'src/modals/SidebarModals/StartSidebarModal'
import { SidebarInvitationModal } from 'src/modals/SidebarModals/SidebarInvitationModal'
import RequestToJoinSidebarModal from 'src/modals/SidebarModals/RequestToJoinSidebarModal'
import KnockModal from 'src/modals/KnockModal'
import { MutedModal } from 'src/modals/MutedModal'
import { exitFullScreen } from 'src/services/FullScreen'
import VideoOptionsLayer from 'src/components/Mcu/VideoOptionsLayer'

import { RightDrawer } from './RightDrawer'
import { BottomDrawer } from './BottomDrawer'
import { DraggableLocalVideo } from './LocalVideo'
import { LowBitrateToast } from './LowBitrateToast'
import { TalkingWhileMutedToast } from './TalkingWhileMutedToast'
import { CallShortcuts } from './CallShortcuts'
import { AutoOpenRoomNavGuard } from './AutoOpenRoomNavGuard'
import { withBackButtonGuard } from './withBackButtonGuard'
import { SidebarControls } from './SidebarControls'
import { FloatersController } from './FloatersController'

type VideoWrapperProps = {
  callId: string
}

const PlaceholderImage: FC<VideoWrapperProps> = ({ callId }) => {
  const imageSrc = useSelector((state: CantinaState) =>
    getCallPlaceholderImage(state, callId)
  )
  return <img src={imageSrc} className='absolute w-full h-full' alt='' />
}

const VideoWrapper: FC<VideoWrapperProps> = ({ callId }) => {
  const receivingVideo = useSelector((state: CantinaState) =>
    isReceivingVideo(state, callId)
  )
  const sendingVideo = useSelector((state: CantinaState) =>
    isSendingVideo(state, callId)
  )
  const isBottomDrawerShown = useSelector(isCallSettingsShown)
  const { isInZone } = useMyZone()
  const [optionsClass, showOptions, wrapper] = useFade({})

  // Watch for MCU PiP window close
  useWatchPip(MCU_ELEMENT_CLASS)

  let heightClassName = ''

  switch (true) {
    case isBottomDrawerShown && isInZone:
      heightClassName = 'both-reduced-h'
      break
    case isBottomDrawerShown:
      heightClassName = 'bottom-reduced-h'
      break
    case isInZone:
      heightClassName = 'top-reduced-h'
      break
  }

  return (
    <div className='call-mcu-area relative flex items-center overflow-hidden'>
      <div
        className={`aspect-ratio-16-9-wrapper ${heightClassName}`}
        ref={wrapper}
      >
        <div className='aspect-ratio-16-9-content'>
          {receivingVideo && <MCU callId={callId} />}
          <MCUOverlayImages callId={callId} />
          {receivingVideo ? (
            <Layers callId={callId} />
          ) : (
            <PlaceholderImage callId={callId} />
          )}
        </div>
        {!isMobile && showOptions && (
          <div className={`sw-transition-all ${optionsClass}`}>
            <VideoOptionsLayer />
          </div>
        )}
      </div>
      {sendingVideo && <DraggableLocalVideo callId={callId} />}
    </div>
  )
}

const SidebarStartModal = () => {
  const show: boolean = useSelector(isSidebarStartModalVisible)
  return show ? <StartSideBarModal /> : null
}

const CallContent: FC<{ callId: string }> = ({ callId }) => {
  return (
    <div className='relative flex flex-1 w-full overflow-hidden'>
      <div className='flex flex-col flex-1 max-w-full'>
        <SidebarControls callId={callId} />
        <VideoWrapper callId={callId} />
        <BottomDrawer />
        <BottomBar />
      </div>
      <RightDrawer />
      <LowBitrateToast />
      <TalkingWhileMutedToast />
      <CallShortcuts callId={callId} />
      <SecondSourceModal callId={callId} />
      <DevicesModalInCall callId={callId} />
      <KnockModal />
      <MutedModal />
      <SidebarStartModal />
      <SidebarInvitationModal />
      <RequestToJoinSidebarModal />
      <AutoOpenRoomNavGuard />
      <FloatersController />
    </div>
  )
}

export const PostCallPage = () => {
  const hasDeviceError = useSelector(hasDeviceErrorSelector)

  // Tried to join a call, but a device was rejected
  if (hasDeviceError) {
    return <Redirect to={RoutePath.Prejoin} />
  }

  return <Redirect to={RoutePath.Home} />
}

export type CallPageProps = {
  callId: string
}

const CallScreen: FC<CallPageProps> = ({ callId }) => {
  useEffect(() => {
    return () => {
      exitFullScreen()
    }
  }, [])

  return (
    <>
      <CallHeader />
      <CallContent callId={callId} />
      {/*
        Remove the dialpad b/c https://github.com/signalwire/cloud-product/issues/2067
        <DialpadModal />
      */}
    </>
  )
}

// prettier-ignore
export const CallPageEnhanced = compose<FC<CallPageProps>>(
  withBackButtonGuard,
  withLoading(Loader.Call, CallLoading),
)(CallScreen)

export const CallPage = withActiveCall(CallPageEnhanced, PostCallPage)
