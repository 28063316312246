import React from 'react'
import { tr, Label } from 'src/common/i18n'
import { FlipLocalVideoToggle } from 'src/components/CallButtons/FlipLocalVideoToggle'
import { TestId } from 'src/constants'
import { InCallParticipantLowBitrateToggle } from 'src/components/CallSettings/LowBitrateToggle'
import { InCallParticipantHideMyBannerToggle } from 'src/components/CallSettings/HideMyBannerToggle'
import { CameraList } from './CameraList'

export const VideoSettings = () => {
  return (
    <div
      className='sm:w-screen sm:max-w-md'
      data-test={TestId.BottomBarVideoSettings}
    >
      <CameraList />
      <div className='block-highlight text-sm p-2 font-semibold'>
        {tr(Label.VIDEO_SETTINGS)}
      </div>
      <FlipLocalVideoToggle />
      <InCallParticipantLowBitrateToggle />
      <InCallParticipantHideMyBannerToggle />
    </div>
  )
}
