import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useDispatchRoomParticipantCommand } from 'src/common/hooks'
import { RoomCommandAction } from 'src/common/constants'
import { UserTagIcon } from 'src/common/icons'
import { CantinaState } from 'src/common/redux/interfaces'
import { hasBanner as hasBannerSelector } from 'src/common/redux/features/participants/participantSelectors'
import { Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import { ButtonFeatureToggle } from 'src/components/Inputs/ButtonFeatureToggle'

interface BannerToggleProps {
  participantId: string
}

export const BannerToggle: FC<BannerToggleProps> = ({ participantId }) => {
  const hasBanner = useSelector((state: CantinaState) =>
    hasBannerSelector(state, participantId)
  )
  const dispatchRoomParticipantCommand =
    useDispatchRoomParticipantCommand(participantId)

  const hideBanner = () => {
    dispatchRoomParticipantCommand({
      action: RoomCommandAction.MemberHideBanner,
      participantId,
    })
  }

  const showBanner = () => {
    dispatchRoomParticipantCommand({
      action: RoomCommandAction.MemberShowBanner,
      participantId,
    })
  }

  return (
    <>
      <ButtonFeatureToggle
        checked={hasBanner}
        Icon={UserTagIcon}
        label={Label.DISPLAY_BANNER}
        onToggleOn={showBanner}
        onToggleOff={hideBanner}
        testId={TestId.ParticipantToggleBanner}
      />
    </>
  )
}
