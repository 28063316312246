import React, { FC } from 'react'
import { isMobile } from 'react-device-detect'
import { TestId } from 'src/constants'
import { InCallSidebarButton } from 'src/components/CallButtons/SidebarButton'
import { InCallParticipantHandButton } from 'src/components/ParticipantCommandButtons/ParticipantHandButton'
import { InCallParticipantDnDButton } from 'src/components/ParticipantCommandButtons/ParticipantDnDButton'
import { InCallAdvancedControlsButton } from 'src/components/CallButtons/AdvancedControlsButton'
import { PictureInPictureButton } from 'src/components/CallSettings/PictureInPictureButton'
import { InCallChatButtonSimple } from 'src/components/CallButtons/ChatButton'

type BottomBarMoreProps = {
  closeHandler: () => void
}

export const BottomBarMore: FC<BottomBarMoreProps> = ({ closeHandler }) => {
  const buttonClassName =
    'p-2 w-full hover:bg-contrast-l active:bg-contrast-l rounded-md whitespace-nowrap flex gap-3 items-center'

  return (
    <div
      className='flex flex-col justify-between items-start gap-2 bg-pop-out'
      data-test={TestId.BottomBarMoreContainer}
    >
      <InCallAdvancedControlsButton
        className={buttonClassName}
        clickHandler={closeHandler}
        showLabel
      />
      <InCallParticipantHandButton
        className={buttonClassName}
        clickHandler={closeHandler}
        showLabel
      />
      <InCallParticipantDnDButton
        className={buttonClassName}
        clickHandler={closeHandler}
        showLabel
      />
      <InCallSidebarButton
        className={`${buttonClassName} text-contrast-h`}
        clickHandler={closeHandler}
        showLabel
      />
      {isMobile && (
        <PictureInPictureButton
          className={buttonClassName}
          clickHandler={closeHandler}
          labelClassName='more-menu-item-label'
          showLabel
        />
      )}
      <InCallChatButtonSimple
        className={buttonClassName}
        clickHandler={closeHandler}
        showLabel
      />
    </div>
  )
}
