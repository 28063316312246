import React, { FC, ChangeEvent } from 'react'
import { tr, Label } from 'src/common/i18n'
import { UseFetchRoomConsumerProps } from 'src/common/hooks'
import { RoomVisibility } from 'src/common/constants'
import { RoomKeys } from 'src/common/rest'
import { SelectGroup } from 'src/components/SelectGroup/SelectGroup'
import { useRoomAutoSave } from '../../../effects/useRoomAutoSave'

const optionsByName = {
  visibility: [
    { id: RoomVisibility.Pinned, label: 'Pinned to the top' },
    { id: RoomVisibility.Normal, label: 'Normal' },
    { id: RoomVisibility.Occupied, label: 'Hidden Unless Occupied' },
    { id: RoomVisibility.Hidden, label: 'Hidden' },
  ],
  order_priority: Array.from({ length: 25 }, (row, index) => ({
    id: index + 1,
    label: `Priority ${index + 1}`,
  })),
}

export const RoomSelectGroup: FC<
  UseFetchRoomConsumerProps & {
    name: RoomKeys
    label: Label
    defaultValue: string | number
    placeholder?: string
  }
> = ({ update, name, label, defaultValue, getErrorByName, placeholder }) => {
  const { currentValue, freeformHandler, iconState } = useRoomAutoSave({
    defaultValue,
    updateRoomHandler: update,
  })

  const changeHandler = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    freeformHandler(target.name, target.value || null)
  }

  return (
    <SelectGroup
      name={name}
      error={getErrorByName(name)}
      label={tr(label)}
      labelIconState={iconState}
      onChange={changeHandler}
      selectedId={currentValue as string}
      // @ts-expect-error
      options={optionsByName[name]}
      placeholder={placeholder}
    />
  )
}
