import React, { FC, useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Draggable from 'react-draggable'
import { getLocalStream } from 'src/common/services/relay'
import { CantinaState } from 'src/common/redux/interfaces'
import {
  isDeaf,
  isVisibleOnMCU,
  isVMuted,
  hasNoLocalVideo,
} from 'src/common/redux/features/participants/participantSelectors'
import {
  getMyParticipantId,
  // getCallRoomId,
} from 'src/common/redux/features/calls/callSelectors'
import { hasFlipLocalVideo } from 'src/common/redux/features/ui/uiSelectors'
import { isPipEnabled } from 'src/common/redux/views/callView/callViewSelectors'
import { EyeSlashIcon } from 'src/common/icons'
import { getAvatar } from 'src/common/redux/features/auth/authSelectors'
import { tr, Label } from 'src/common/i18n'
import { useFade } from 'src/common/hooks'
import { withTextTooltip } from 'src/hoc/withTextTooltip'
import { FlipLocalVideoButton } from 'src/components/CallButtons/FlipLocalVideoButton'
import { ScopedParticipantSettingsButton } from 'src/components/CallButtons/ParticipantSettingsButton'
import {
  SpeakingIcon,
  LowBitrateModeIcon,
} from 'src/components/Mcu/ParticipantLayers'
import { Avatar } from 'src/components/Images/Avatar'

type LocalVideoProps = {
  callId: string
  participantId: string
  className?: string
}

export const LocalVideo: FC<LocalVideoProps> = ({
  callId,
  participantId,
  className = 'absolute inset-0',
}) => {
  const vmuted = useSelector((state: CantinaState) =>
    isVMuted(state, participantId)
  )
  const flip = useSelector(hasFlipLocalVideo)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const localStream: MediaStream = getLocalStream(callId)

  useEffect(() => {
    let video: HTMLVideoElement | null = null
    if (localStream) {
      video = document.createElement('video')
      video.muted = true
      video.autoplay = true
      video.playsInline = true
      video.srcObject = localStream
      video.classList.add('local-video')
      wrapperRef.current?.appendChild(video)
      video.play().catch(() => console.error('LocalVideo cannot play?'))
    }
    return () => {
      if (video) {
        video.srcObject = null
        video.remove()
      }
    }
  }, [localStream])

  const classNames = `${className} ${vmuted ? 'hidden' : ''} ${
    flip ? 'flip-y' : ''
  }`
  return <div ref={wrapperRef} className={classNames} />
}

export const NoLocalVideo: FC<Omit<LocalVideoProps, 'participantId'>> = ({
  // callId,
  className = 'absolute inset-0',
}) => {
  // const roomId = useSelector((state: CantinaState) =>
  //   getCallRoomId(state, callId)
  // )
  const muteImages: any[] = []
  // FIXME: Use hybrid data from Rails
  // const muteImages = useSelector(
  //   (state: CantinaState) => getMuteRoomImages(state, roomId),
  //   shallowEqual
  // )
  const style: any = {}
  if (muteImages?.length) {
    // Always use the first one to not change the image on every layout change.
    // const image = muteImages[Math.floor(Math.random() * muteImages.length)]
    const image = muteImages[0]
    style.backgroundImage = image ? `url(${image.src})` : ''
  }

  return (
    <div
      className={`bg-black bg-cover aspect-ratio-16-9-wrapper ${className}`}
      style={style}
    >
      <div className='aspect-ratio-16-9-content'></div>
    </div>
  )
}

const DRAG_DEFAULT_POSITION = { x: -20, y: -20 }

export const DraggableLocalVideo: FC<{ callId: string }> = ({ callId }) => {
  const [collapsed, setCollapsed] = useState(false)
  const participantId = useSelector(getMyParticipantId)
  const onMCU = useSelector((state: CantinaState) =>
    isVisibleOnMCU(state, participantId)
  )
  const noLocalVideo = useSelector((state: CantinaState) =>
    hasNoLocalVideo(state, participantId)
  )
  const isPip = useSelector(isPipEnabled)
  if (onMCU || noLocalVideo || isPip) {
    return null
  }

  let isDragging = false
  const onStartDragging = () => {
    isDragging = true
  }
  const onStopDragging = () => {
    isDragging = false
  }
  let _timer: ReturnType<typeof setTimeout>
  const toggleCollapse = () => {
    clearTimeout(_timer)
    _timer = setTimeout(() => {
      if (!isDragging) {
        setCollapsed(!collapsed)
      }
    }, 300)
  }

  const className = collapsed
    ? 'w-auto'
    : 'text-contrast-h w-1/3 md:w-1/4 lg:w-1/6 h-auto'
  return (
    <Draggable
      bounds='parent'
      defaultPosition={DRAG_DEFAULT_POSITION}
      handle='.local-video-wrapper'
      onStart={onStartDragging}
      onStop={onStopDragging}
    >
      <div className={`absolute right-0 bottom-0 z-40 ${className}`}>
        {!collapsed && (
          <ControllableVideo
            callId={callId}
            participantId={participantId}
            toggleCollapse={toggleCollapse}
          />
        )}
        {collapsed && <ControllableAvatar toggleCollapse={toggleCollapse} />}
      </div>
    </Draggable>
  )
}

const HideButton = ({ clickHandler }: any) => (
  <button onClick={clickHandler} aria-label={tr(Label.HIDE_VIDEO)}>
    <EyeSlashIcon size='lg' fixedWidth />
  </button>
)
const HideLocalVideoButton = withTextTooltip()(HideButton)

const ControllableVideo = ({ callId, participantId, toggleCollapse }: any) => {
  const [optionsClass, showOptions, wrapper] = useFade({})
  const deaf = useSelector((state: CantinaState) =>
    isDeaf(state, participantId)
  )

  return (
    <div className='local-video-wrapper relative text-white' ref={wrapper}>
      {deaf && (
        <div className='absolute w-full h-full text-xs text-center flex justify-center items-center mcu-blurred-layer'>
          <span className='p-1 border border-white rounded'>
            DND
            <br />
            ACTIVE
          </span>
        </div>
      )}
      <LocalVideo
        callId={callId}
        participantId={participantId}
        className='w-full cursor-move'
      />
      <div className='absolute top-0 left-0 z-10'>
        <SpeakingIcon participantId={participantId} />
      </div>
      {showOptions && (
        <div
          className={`absolute top-0 left-0 user-menu-items w-full text-right flex justify-end items-center ${optionsClass}`}
        >
          <div className='inline-flex flex-wrap h-8 gap-y-2 justify-end items-center overflow-hidden'>
            {/* Empty space the same width as a button/indicator, needed to wrap the next icon offscreen */}
            <div className='w-12'>&nbsp;</div>
            <LowBitrateModeIcon participantId={participantId} />
            <HideLocalVideoButton clickHandler={toggleCollapse} />
            <FlipLocalVideoButton />
          </div>
          <div>
            <ScopedParticipantSettingsButton
              participantId={participantId}
              showTooltip
            />
          </div>
        </div>
      )}
    </div>
  )
}

type ControllableAvatarProps = {
  toggleCollapse: () => void
}

const ControllableAvatar: FC<ControllableAvatarProps> = ({
  toggleCollapse,
}) => {
  const avatar = useSelector((state: CantinaState) => getAvatar(state))

  return withTextTooltip()(({ toggleCollapse }: any) => (
    <button
      aria-label={tr(Label.SHOW_VIDEO)}
      className='block'
      onMouseDown={toggleCollapse}
      onTouchStart={toggleCollapse}
    >
      <Avatar
        className='relative block w-24 h-24 rounded-full pointer-events-none'
        src={avatar}
        alt={tr(Label.AVATAR)}
      />
    </button>
  ))({ toggleCollapse })
}
