import React, {
  FC,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
  useCallback,
} from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import {
  AUDIO_DEFAULT_VOLUME,
  AUDIO_MAX_VOLUME,
  AUDIO_MIN_VOLUME,
  RoomCommandAction,
  SeekDirection,
} from 'src/common/constants'
import {
  ForwardIcon,
  PauseIcon,
  PlayIcon,
  RewindIcon,
  VolumeDownIcon,
  VolumeUpIcon,
} from 'src/common/icons'
import { tr, Label } from 'src/common/i18n'
import {
  useDispatchRoomCommand,
  useRoomSelector,
  useMyZone,
} from 'src/common/hooks'
import { withConferencePlaybackControlsScope } from 'src/common/hoc'
import {
  getPlayingError,
  getPlayingSettings,
  getFilePlaybackRole,
} from 'src/common/redux/features/rooms/roomsSelectors'
import {
  getDefaultPlaybackVolume,
  getRecentlyPlayedVideos,
} from 'src/common/redux/views/callView/callViewSelectors'
import { callViewActions } from 'src/common/redux/views'
import { CantinaState } from 'src/common/redux/interfaces'
import { roomsActions, roomCommand } from 'src/common/redux/features'

import { useInputChange } from 'src/effects/useInputChange'
import RangeSlider from 'src/components/Inputs/RangeSlider'
import { Checkbox } from 'src/components/Inputs/Checkbox'
import { withTextTooltip } from 'src/hoc/withTextTooltip'
import { TestId } from 'src/constants'

import SubmittableInput from '../Inputs/SubmittableInput'
import { StopPlaybackButton } from './StopPlaybackButton'
import { LayoutRolesDropdown } from './LayoutRolesDropdown'
import { PlaybackLayoutRoleDropdown } from './PlaybackLayoutRoleDropdown'

type PlaybackControlsProps = {
  isPopout?: boolean
  roomId: string
}

const AUTO_SKIP_DELAY_MS = 250

const seekSpeeds = {
  '1000': '1x',
  '2000': '2x',
  '4000': '4x',
  '8000': '8x',
  '16000': '16x',
  '32000': '32x',
  '64000': '64x',
}

const PlaybackControls: FC<PlaybackControlsProps> = ({
  isPopout = false,
  roomId,
}) => {
  const settings = useSelector(
    (state: CantinaState) => getPlayingSettings(state, roomId),
    shallowEqual
  )
  const [seekDisplay, setSeekDisplay] = useState('1x')

  // The video is not playing
  if (!settings) {
    return <PlaybackInput isPopout={isPopout} roomId={roomId} />
  }

  // The video is playing
  return (
    <>
      <div
        className={`flex flex-col ${
          isPopout ? '' : 'sm:flex-row sm:items-end'
        }`}
      >
        <div className='flex items-center h-10'>
          {settings.seekable && (
            <TooltipWrappedSeekButton
              ariaLabel={`${tr(Label.SEEK_BACK)} ${seekDisplay}`}
              Icon={RewindIcon}
              roomId={roomId}
              seekDirection={SeekDirection.Rewind}
              setSeekDisplay={setSeekDisplay}
              testId={TestId.MediaButtonRewind}
            />
          )}
          <StopPlaybackButton
            roomId={roomId}
            className='sw-btn-playback-control'
          />
          <TooltipWrappedPlayPauseButton
            roomId={roomId}
            paused={Boolean(settings.paused)}
          />
          {settings.seekable && (
            <TooltipWrappedSeekButton
              ariaLabel={`${tr(Label.SEEK_FORWARD)} ${seekDisplay}`}
              Icon={ForwardIcon}
              roomId={roomId}
              seekDirection={SeekDirection.Forward}
              setSeekDisplay={setSeekDisplay}
              testId={TestId.MediaButtonFastForward}
            />
          )}
        </div>
        <PlaybackLayoutRoleDropdown roomId={roomId} className='pb-2 sm:pb-0' />
      </div>
      <PlaybackVolume roomId={roomId} currentVolume={settings.volume} />
    </>
  )
}

const SeekControl = ({
  ariaLabel,
  Icon,
  roomId,
  seekDirection,
  setSeekDisplay,
  testId,
}: any) => {
  const dispatchRoomCommand = useDispatchRoomCommand()
  const intervalRef = useRef<ReturnType<typeof setInterval>>()
  const speedRef = useRef(1000)
  const counterRef = useRef(1)

  const seekHandler = () => {
    counterRef.current += 1
    if (counterRef.current % 6 === 0 && speedRef.current < 64000) {
      speedRef.current *= 2
      // @ts-expect-error
      setSeekDisplay(seekSpeeds[speedRef.current])
      counterRef.current = 1
    }

    dispatchRoomCommand({
      roomId,
      action: RoomCommandAction.SeekPlayback,
      direction: seekDirection,
      speed: speedRef.current,
    })
  }

  const onPress = () => {
    if (intervalRef.current) {
      return
    }
    intervalRef.current = setInterval(seekHandler, AUTO_SKIP_DELAY_MS)
  }

  const onRelease = useCallback(() => {
    intervalRef.current && clearInterval(intervalRef.current)
    intervalRef.current = undefined
    speedRef.current = 1000
    counterRef.current = 1
    // @ts-expect-error
    setSeekDisplay(seekSpeeds[speedRef.current])
  }, [setSeekDisplay])

  useEffect(() => {
    return onRelease
  }, [onRelease])

  return (
    <button
      aria-label={ariaLabel}
      className='sw-btn-playback-control'
      data-test={testId}
      onClick={seekHandler}
      onMouseDown={onPress}
      onMouseUp={onRelease}
      onTouchEnd={onRelease}
      onTouchStart={onPress}
    >
      <Icon size='lg' fixedWidth />
    </button>
  )
}
const TooltipWrappedSeekButton = withTextTooltip({ controlledShow: true })(
  SeekControl
)

const PlayPauseButton = ({
  roomId,
  paused,
}: {
  roomId: string
  paused: boolean
}) => {
  const dispatchRoomCommand = useDispatchRoomCommand()
  const clickHandler = () => {
    // PausePlayback handles resume too
    dispatchRoomCommand({ roomId, action: RoomCommandAction.PausePlayback })
  }

  const IconComponent = paused ? PlayIcon : PauseIcon
  return (
    <button
      className='sw-btn-playback-control'
      onClick={clickHandler}
      aria-label={paused ? tr(Label.PLAY) : tr(Label.PAUSE)}
      data-test={TestId.MediaButtonPlayPause}
    >
      <IconComponent size='lg' fixedWidth />
    </button>
  )
}
const TooltipWrappedPlayPauseButton = withTextTooltip()(PlayPauseButton)

type PlaybackInputProps = {
  isPopout?: boolean
  roomId: string
}

const PlaybackInput: FC<PlaybackInputProps> = ({ roomId, isPopout }) => {
  const dispatchRoomCommand = useDispatchRoomCommand()
  const filePlaybackRole = useRoomSelector(getFilePlaybackRole, roomId)
  const defaultVolume = useRoomSelector(getDefaultPlaybackVolume, roomId)

  return (
    <>
      <div className={`flex flex-col gap-x-4 ${isPopout ? '' : 'sm:flex-row'}`}>
        <PlaybackFields
          roomId={roomId}
          selectedPlaybackRole={filePlaybackRole as string}
        />
        <LayoutRolesDropdown
          className=''
          roomId={roomId}
          name='layoutReservation'
          label={tr(Label.VIDEO_PLAYBACK_ROLE)}
          onChange={({ target }: ChangeEvent<HTMLSelectElement>) => {
            dispatchRoomCommand({
              roomId,
              action: RoomCommandAction.SetFilePlaybackRole,
              reservationId: target.value,
            })
          }}
          selectedId={filePlaybackRole ?? ''}
        />
      </div>
      <div
        className={`flex items-center gap-x-10 mt-4 ${
          isPopout ? '' : 'sm:mt-0'
        }`}
      >
        <PlaybackVolumeCheckbox roomId={roomId} />
        <PlaybackVolume
          roomId={roomId}
          currentVolume={defaultVolume}
          showLabel={false}
        />
      </div>
    </>
  )
}

type PlaybackFieldsProps = {
  roomId: string
  selectedPlaybackRole: string
}

const PlaybackFields: FC<PlaybackFieldsProps> = ({
  roomId,
  selectedPlaybackRole,
}) => {
  const dispatch = useDispatch()
  const dispatchRoomCommand = useDispatchRoomCommand()
  const error = useRoomSelector(getPlayingError, roomId)
  const recentlyPlayedVideos = useSelector(
    (state: CantinaState) => getRecentlyPlayedVideos(state),
    shallowEqual
  )
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useInputChange({ playbackUrl: '' })
  const { isInZone } = useMyZone()
  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const playbackUrl = values?.playbackUrl?.trim()

    if (playbackUrl) {
      setLoading(true)

      dispatchRoomCommand({
        roomId,
        action: RoomCommandAction.StartPlayback,
        url: playbackUrl,
        role: selectedPlaybackRole,
      })
    }
  }

  const buttonTitle = isInZone
    ? tr(Label.FEATURE_IS_UNAVAILABLE_IN_SIDEBAR, {
        feature: tr(Label.MEDIA),
      })
    : ''

  useEffect(() => {
    if (error) {
      setLoading(false)
    }
    // Clear the error when either the user or component remount clears the input
    if (!values.playbackUrl.length) {
      dispatch(
        roomsActions.createOrUpdate({
          id: roomId,
          playingError: false,
        })
      )
    }
  }, [dispatch, error, roomId, values])

  return (
    <>
      <SubmittableInput
        autoComplete='off' // Let the datalist provide this
        buttonTitle={buttonTitle}
        className='grow'
        currentValue={values.playbackUrl}
        disabled={isInZone}
        error={error ? tr(Label.INVALID_VIDEO_URL) : ''}
        id='playbackUrl'
        inputChanged={setValues}
        label={tr(Label.FILE_URL_TO_PLAY)}
        list='video-playback-list'
        name='playbackUrl'
        placeholder='https://example.com/vid.mp4'
        required
        submitButtonLabel={tr(Label.PLAY)}
        submitHandler={submitHandler}
        submitting={loading}
        testId={TestId.MediaInputUrl}
        title=''
        type='text'
      />
      <datalist id='video-playback-list'>
        {recentlyPlayedVideos.map((url: string) => (
          <option key={url} value={url} />
        ))}
      </datalist>
    </>
  )
}

type PlaybackVolumeProps = {
  roomId: string
  currentVolume?: number
  showLabel?: boolean
}

const PlaybackVolume: FC<PlaybackVolumeProps> = ({
  roomId,
  currentVolume = 0,
  showLabel = true,
}) => {
  const dispatch = useDispatch()
  const onChange = useCallback(
    (volume: number) => {
      dispatch(callViewActions.setDefaultPlaybackVolume(volume))
      dispatch(
        roomCommand({
          roomId,
          action: RoomCommandAction.SetPlaybackVolume,
          volume,
        })
      )
    },
    [dispatch, roomId]
  )

  return (
    <div className='w-full px-2'>
      <RangeSlider
        currentValue={currentVolume}
        label={showLabel ? tr(Label.VOLUME) : ''}
        LowerIcon={VolumeDownIcon}
        maxValue={AUDIO_MAX_VOLUME}
        minValue={AUDIO_MIN_VOLUME}
        onValueChange={onChange}
        step={1}
        testId={TestId.MediaRangeVolume}
        UpperIcon={VolumeUpIcon}
      />
    </div>
  )
}

const PlaybackVolumeCheckbox = ({ roomId }: { roomId: string }) => {
  const defaultVolume = useRoomSelector(getDefaultPlaybackVolume, roomId)
  const dispatch = useDispatch()
  const changeHandler = useCallback(
    (checked: boolean) => {
      dispatch(
        callViewActions.setDefaultPlaybackVolume(
          checked ? AUDIO_DEFAULT_VOLUME : AUDIO_MIN_VOLUME
        )
      )
    },
    [dispatch]
  )

  return (
    <Checkbox
      name='defaultVolume'
      label={tr(Label.PLAY_WITH_AUDIO)}
      onChange={(event) => changeHandler(event.target.checked)}
      checked={defaultVolume > AUDIO_MIN_VOLUME}
      testId={TestId.MediaRangeDefaultVolume}
      wrapperClassName='py-2'
    />
  )
}

export const ScopedPlaybackControls: FC<PlaybackControlsProps> =
  withConferencePlaybackControlsScope(PlaybackControls)
