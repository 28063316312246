import { createSelector } from 'reselect'
import { CantinaState } from '../../interfaces'
import { roomInvitationURL } from '../../../services/helpers'

export const getRoom = ({ inviteView }: CantinaState) => {
  return inviteView.room
}

export const isVisible = ({ inviteView }: CantinaState) => {
  return inviteView.isVisible
}

export const getInviteURL = createSelector(getRoom, (room) => {
  return room ? roomInvitationURL(room) : ''
})
