import React, { FC } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useRoomSelector } from 'src/common/hooks'
import { CantinaState } from 'src/common/redux/interfaces'
import { getLastLayout } from 'src/common/redux/features/rooms/roomsSelectors'
import { getLayoutReservationsDropdownOptions } from 'src/common/redux/features/layouts/layoutsSelectors'
import { TestId } from 'src/constants'
import {
  SelectGroup,
  SelectGroupProps,
} from 'src/components/SelectGroup/SelectGroup'

interface LayoutRolesDropdownProps extends SelectGroupProps {
  roomId: string
}

export const LayoutRolesDropdown: FC<LayoutRolesDropdownProps> = ({
  roomId,
  selectedId,
  ...props
}) => {
  const layout = useRoomSelector(getLastLayout, roomId) as string
  const reservationOptions = useSelector(
    (state: CantinaState) =>
      getLayoutReservationsDropdownOptions(state, layout),
    shallowEqual
  )
  if (!reservationOptions?.length) {
    return null
  }

  /**
   * Handle orphan case where the current reservationId is not available anymore
   * due to a layout change but you can still "clear" the select.
   * Add the orphan option as the second choice - after "None"
   */
  const options = [...reservationOptions]
  const hasOrphan = selectedId && !options.some(({ id }) => id === selectedId)
  if (hasOrphan) {
    options.splice(1, 0, { id: selectedId, label: selectedId })
  }

  return (
    <SelectGroup
      {...props}
      canHaveError={false}
      selectedId={selectedId || ''}
      options={options}
      testId={TestId.VideoLayoutSelectRole}
    />
  )
}
