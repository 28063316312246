import React, { FC } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { ParticipantGroup } from './ParticipantGroup'
import { CantinaState } from 'src/common/redux/interfaces'
import { getParticipantsGroupedByModerator } from 'src/common/redux/views/callView/callViewSelectors'
import { tr, Label } from 'src/common/i18n'
import { KeyIcon } from 'src/common/icons'
import { TestId } from 'src/constants'

interface ParticipantGroupModeratorsProps {
  className?: string
  roomId: string
}

export const ParticipantGroupModerators: FC<ParticipantGroupModeratorsProps> =
  ({ className, roomId }) => {
    const moderatorsIds = useSelector(
      (state: CantinaState) => getParticipantsGroupedByModerator(state, roomId),
      shallowEqual
    )

    return (
      <ParticipantGroup
        className={className}
        heading={tr(Label.MODERATOR)}
        Icon={KeyIcon}
        memberIds={moderatorsIds}
        groupParticipantTestId='moderator'
        testId={TestId.ParticipantsGroupModerator}
      />
    )
  }
