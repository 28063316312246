// TODO: (in the future) we can move this to a `.native.ts` to proxy different values to RN
export enum RoutePath {
  Login = '/new-login',
  LoginLocal = '/new-login/local',
  LoginGuest = '/new-login/guest',
  UserLogin = '/login/user',
  GuestLogin = '/login/guest',
  UserSetup = '/user-setup',
  UserPasswordReset = '/reset-password',
  Room = '/rooms/:room/',
  Join = '/join/:room',
  Home = '/home',
  PasswordForgot = '/password_reset',
  Call = '/in-call',
  DirectCall = '/direct-call',
  Settings = '/settings',
  SettingsAbout = '/settings/about',
  SettingsGlobal = '/settings/global',
  SettingsGlobalRoomsSecurity = '/settings/global/rooms-security',
  SettingsGlobalOptions = '/settings/global/options',
  SettingsGlobalAdvancedOptions = '/settings/global/advanced-options',
  SettingsGlobalAppearance = '/settings/global/appearance',
  SettingsGlobalHomeScreen = '/settings/global/home-screen',
  SettingsGlobalPermissions = '/settings/global/permissions',
  SettingsGroupList = '/settings/groups',
  SettingsGroup = '/settings/group/:groupId',
  SettingsRoomList = '/settings/rooms',
  SettingsRoom = '/settings/room/:roomId',
  SettingsRoomBasicInfo = '/settings/room/:roomId/info',
  SettingsRoomVisibility = '/settings/room/:roomId/visibility',
  SettingsRoomSecurity = '/settings/room/:roomId/security',
  SettingsRoomSignalWire = '/settings/room/:roomId/signalwire-integration',
  SettingsRoomOptions = '/settings/room/:roomId/options',
  SettingsRoomThemes = '/settings/room/:roomId/themes',
  SettingsRoomAppearance = '/settings/room/:roomId/appearance',
  SettingsRoomPermissions = '/settings/room/:roomId/permissions',
  SettingsUserList = '/settings/users',
  SettingsUser = '/settings/user/:username',
  SettingsAddUser = '/settings/new-user',
  SettingsRecordingList = '/settings/recordings',
  SettingsRecordingDetail = '/settings/recordings/:roomId',
  SettingsClipeeze = '/settings/clipeeze',
  Broadcast = '/broadcast/:broadcastId/:slugName',
  Prejoin = '/prejoin',
  AutoLogin = '/auto-login',
  AutoLoginNew = '/auto-login-new',
  Impersonate = '/impersonate',
  DesignSystem = '/design-system',
}
