import React, { FC, ComponentType, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { SocketStatus } from '../constants'
import { getSocketStatus } from '../redux/features/ui/uiSelectors'
import { hasValidAuth } from '../redux/features/auth/authSelectors'
import { dismissToast, toastWarn } from '../services/relay'

const ERROR_TIMEOUT = 5 * 1000 // 5 seconds
const STATUSES = [SocketStatus.Error, SocketStatus.Closed] as string[]
const TOAST_MSG =
  'The connection with the server has been closed but we are trying to reconnect.'
const TOAST_HEADER = 'Reconnecting...'

interface withWebSocketEventListenersProps {}

export const withWebSocketEventListeners =
  (_SocketClosedComponent: FC, _SocketErrorComponent: FC) =>
  <T extends object>(WrappedComponent: ComponentType<T>) => {
    return (props: T & withWebSocketEventListenersProps) => {
      const socketStatus = useSelector(getSocketStatus)
      const hasAuth = useSelector(hasValidAuth)
      const timeoutRef = useRef<any>()
      const toastId = useRef<any>()

      useEffect(() => {
        // Delay a bit the toast
        clearTimeout(timeoutRef.current)
        if (hasAuth && STATUSES.includes(socketStatus)) {
          timeoutRef.current = setTimeout(() => {
            toastId.current = toastWarn(TOAST_MSG, {
              header: TOAST_HEADER,
              link: location.href,
              linkText: 'Refresh The Page',
              linkTarget: '_self',
              autoClose: false,
            })
          }, ERROR_TIMEOUT)
        }

        return () => {
          clearTimeout(timeoutRef.current)
          if (toastId.current) {
            dismissToast(toastId.current)
            toastId.current = null
          }
        }
      }, [socketStatus, hasAuth])

      return <WrappedComponent {...props} />

      // if (!hasAuth) {
      //   return <WrappedComponent {...props} />
      // }

      // switch (status) {
      //   case SocketStatus.Closed:
      //     return <SocketClosedComponent />
      //   case SocketStatus.Error:
      //     return <SocketErrorComponent />
      //   default:
      //     return <WrappedComponent {...props} />
      // }
    }
  }
