import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import { participantKnock } from 'src/common/redux/features/participants/participantSlice'
import { withConferenceKnockScope } from 'src/common/hoc'
import { KnockIcon } from 'src/common/icons'

const DISABLED_FOR = 10 * 1000

const KnockButton: FC<ParticipantKnockButtonProps> = ({ participantId }) => {
  const [isKnocking, setIsKnocking] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const dispatch = useDispatch()

  const clickHandler = () => {
    if (isKnocking || isDisabled) {
      return
    }

    dispatch(participantKnock(participantId))
    setIsKnocking(true)
    setTimeout(() => {
      setIsKnocking(false)
    }, 750)

    setIsDisabled(true)
    setTimeout(() => {
      setIsDisabled(false)
    }, DISABLED_FOR)
  }

  return (
    <div className='absolute flex h-full w-full items-center justify-center text-white'>
      <button
        className={`participant-knock flex items-center justify-center w-1/5 min-w-[22px] max-w-[90px] aspect-square ${
          isKnocking ? 'knocking' : ''
        } focus:outline-none border hover:border-2 opacity-75 hover:opacity-100 transition duration-200 ease-in-out`}
        onClick={clickHandler}
        disabled={isDisabled}
      >
        <KnockIcon className='w-3/5 h-3/5 stroke-current fill-current' />
      </button>
    </div>
  )
}

type ParticipantKnockButtonProps = {
  participantId: string
}

export const ParticipantKnockButton: FC<ParticipantKnockButtonProps> = ({
  participantId,
}) => {
  return <KnockButton participantId={participantId} />
}

export const ScopedParticipantKnockButton: FC<ParticipantKnockButtonProps> =
  withConferenceKnockScope(ParticipantKnockButton)
