import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useRoomSelector } from 'src/common/hooks'
import { withConferenceLayerControlsScope } from 'src/common/hoc'
import { FloaterType } from 'src/common/constants'
import { CantinaState } from 'src/common/redux/interfaces'
import {
  getParticipantRoomId,
  isScreenShareLeg,
} from 'src/common/redux/features/participants/participantSelectors'
import { getVidFloorRole } from 'src/common/redux/features/rooms/roomsSelectors'
import { DecibelIcon, CameraOnIcon, VolumeUpIcon } from 'src/common/icons'
import { getFloater } from 'src/common/redux/features/floaters/floatersSelectors'
import { Size, TestId } from 'src/constants'
import { Floater } from 'src/layout/Floater'

import { ParticipantData } from './ParticipantData'
import { TextToSpeech } from './FeaturePanels/TextToSpeech'
import { BannerToggle } from './FeaturePanels/BannerToggle'
import { ParticipantLayoutRoleDropdown } from './FeaturePanels/ParticipantLayoutRoleDropdown'
import { ScopedGrantSpecialVisibilityToggle } from './FeaturePanels/GrantSpecialVisibilityToggle'
import { GrantModeratorToggle } from './FeaturePanels/GrantModeratorToggle'
import { GrantScreenShareToggle } from './FeaturePanels/GrantScreenShareToggle'
import { AudioConstraintsToggles } from './FeaturePanels/AudioConstraintsToggles'
import { LowBitrateModeToggle } from './FeaturePanels/LowBitrateModeToggle'
import { BackgroundNoiseToggle } from './FeaturePanels/BackgroundNoiseToggle'
import { DisableAutoRoleToggle } from './FeaturePanels/DisableAutoRoleToggle'
import { EditBanner } from './FeaturePanels/EditBanner'
import {
  ParticipantVolumeControl,
  MicVolumeControl,
  NoiseGateControl,
  VideoQualityControl,
} from './FeaturePanels/SoundControls/index'
import {
  ParticipantAudioMuteButton,
  ParticipantVideoMuteButton,
  ParticipantDnDButton,
  ParticipantHandButton,
  ParticipantKickButton,
} from '../ParticipantCommandButtons'

type ParticipantOptionsProps = {
  participantId: string
  index: number
}

const ParticipantOptions: FC<ParticipantOptionsProps> = ({
  participantId,
  index,
}) => {
  const roomId = useSelector((state: CantinaState) =>
    getParticipantRoomId(state, participantId)
  ) as string
  const vidFloorRole = useRoomSelector(getVidFloorRole, roomId)
  const floater = useSelector((state: CantinaState) =>
    getFloater(state, participantId)
  )
  const isScreenShare = useSelector((state: CantinaState) =>
    isScreenShareLeg(state, participantId)
  )
  const reservationId = floater?.reservationId

  if (!roomId) {
    return null
  }

  const disableAutoRole = vidFloorRole && vidFloorRole === reservationId

  return (
    <Floater
      className='participant-options-floater flex flex-col max-h-[80vh] sm:max-h-full overflow-hidden'
      id={participantId}
      key={participantId}
      testId={TestId.ParticipantOptions}
      type={FloaterType.ParticipantOptions}
      index={index}
    >
      <div className='flex flex-col flex-1 text-sm p-0 overflow-hidden'>
        <div className='shrink-0 p-4 pt-2 border-b border-contrast-l'>
          <div className='flex flex-col sm:flex-row justify-between gap-2 sm:gap-4 items-center'>
            <ParticipantData
              /* min-w needed to truncate contents */
              className='w-full justify-center sm:min-w-1/2 sm:justify-start py-1'
              participantId={participantId}
              size={Size.Large}
            />
            <div className='flex shrink-0 gap-2'>
              <ParticipantVideoMuteButton
                className='text-base'
                participantId={participantId}
                size='lg'
                testId={TestId.ParticipantButtonVideo}
              />
              <ParticipantAudioMuteButton
                className='text-base'
                participantId={participantId}
                size='lg'
                testId={TestId.ParticipantButtonAudio}
              />
              {!isScreenShare && (
                <ParticipantHandButton
                  className='text-base'
                  participantId={participantId}
                  size='lg'
                  testId={TestId.ParticipantButtonHand}
                />
              )}
              {!isScreenShare && (
                <ParticipantDnDButton
                  className='text-base'
                  participantId={participantId}
                  size='lg'
                  testId={TestId.ParticipantButtonDnd}
                />
              )}
              <ParticipantKickButton
                className='text-base'
                participantId={participantId}
                size='lg'
                testId={TestId.ParticipantButtonKick}
              />
            </div>
          </div>
        </div>
        <div
          className={`flex-1 flex flex-col overflow-y-auto ${
            isScreenShare ? 'pt-4 px-4' : 'p-4'
          }`}
        >
          <div className='flex flex-col sm:flex-row gap-2 sm:gap-4 pb-2'>
            <MicVolumeControl
              className='flex-1'
              LowerIcon={VolumeUpIcon}
              participantId={participantId}
            />
            <VideoQualityControl
              className='flex-1'
              LowerIcon={CameraOnIcon}
              participantId={participantId}
            />
          </div>

          {!isScreenShare && (
            <>
              <div className='flex flex-col sm:flex-row gap-2 sm:gap-4 pb-2'>
                <ParticipantVolumeControl
                  className='flex-1'
                  LowerIcon={VolumeUpIcon}
                  participantId={participantId}
                />
                <NoiseGateControl
                  className='flex-1'
                  LowerIcon={DecibelIcon}
                  participantId={participantId}
                />
              </div>
              <div className='toggle-button-grid'>
                <GrantModeratorToggle participantId={participantId} />
                <ScopedGrantSpecialVisibilityToggle
                  participantId={participantId}
                />
                <LowBitrateModeToggle participantId={participantId} />
                <BannerToggle participantId={participantId} />
                <GrantScreenShareToggle participantId={participantId} />
                <BackgroundNoiseToggle participantId={participantId} />
                {disableAutoRole && (
                  <DisableAutoRoleToggle
                    roomId={roomId}
                    reservationId={reservationId}
                  />
                )}
                <AudioConstraintsToggles participantId={participantId} />
              </div>
            </>
          )}
          <div className='flex flex-col sm:flex-row sm:gap-4'>
            <ParticipantLayoutRoleDropdown
              roomId={roomId}
              participantId={participantId}
              className='py-2 sm:w-1/2'
            />
            <EditBanner
              participantId={participantId}
              className='py-2 sm:w-1/2'
            />
          </div>
          {!isScreenShare && (
            <TextToSpeech participantId={participantId} className='pb-0' />
          )}
        </div>
      </div>
    </Floater>
  )
}

export const ScopedParticipantOptions =
  withConferenceLayerControlsScope(ParticipantOptions)
