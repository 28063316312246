import React, { FC, ComponentType, useEffect, useRef } from 'react'
import { compose } from 'redux'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { RoomCommandAction, RoutePath, Loader } from 'src/common/constants'
import {
  usePrevious,
  useDispatchRoomParticipantCommand,
} from 'src/common/hooks'
import { withLoading } from 'src/common/hoc'
import { uiActions, callRequest, callActions } from 'src/common/redux/features'
import { hasValidAuth } from 'src/common/redux/features/auth/authSelectors'
import {
  getRoomRouteData,
  hasErrors,
} from 'src/common/redux/features/calls/callSelectors'
import { hasSkipPrejoinPage } from 'src/common/redux/features/ui/uiSelectors'
import { CallRequestPayload } from 'src/common/redux/interfaces'
import { useHistoryPushToRoom } from 'src/effects/useHistoryPushToRoom'
import { useDecodedParams } from 'src/effects/useDecodedParams'
import { CallPageEnhanced, PostCallPage } from 'src/pages/Call/Call'
import { toastError } from 'src/services/ToastService'
import CallLoading from 'src/components/CallLoading'

const withPrejoinPageGuard = <T extends {}>(
  WrappedComponent: ComponentType<T>
) => {
  return (props: T) => {
    const { room } = useDecodedParams<{ room: string }>()
    const defaultState: CallRequestPayload = { extension: room, force: false }
    const { state = defaultState } = useLocation<CallRequestPayload>()
    const skipPrejoinPage = useSelector(hasSkipPrejoinPage)
    console.debug('Skip Prejoin', skipPrejoinPage, state.force)

    if (skipPrejoinPage || state.force) {
      return <WrappedComponent {...props} />
    }
    return (
      <Redirect
        to={{
          pathname: RoutePath.Prejoin,
          state,
        }}
      />
    )
  }
}

const withCallErrorsGuard = <T extends {}>(
  WrappedComponent: ComponentType<T>
) => {
  return (props: T) => {
    const dispatch = useDispatch()
    const hasCallErrors = useSelector(hasErrors)
    console.debug('Call error guard', hasCallErrors)

    useEffect(() => {
      return () => {
        console.debug('Call error guard cleanup')
        dispatch(callActions.cleanupErrors())
      }
    }, [dispatch])

    if (hasCallErrors) {
      toastError('Error joining the room. Please retry', { autoClose: 10_000 })

      return <Redirect to={RoutePath.Home} />
    }
    return <WrappedComponent {...props} />
  }
}

const RoomPage = compose<FC<{}>>(
  withPrejoinPageGuard,
  withLoading(Loader.Call, CallLoading),
  withCallErrorsGuard
)(() => {
  const dispatch = useDispatch()
  const { room } = useDecodedParams<{ room: string }>()
  const { pushHandler } = useHistoryPushToRoom()

  const {
    callId,
    roomId,
    participantId,
    roomName = '',
  } = useSelector(getRoomRouteData, shallowEqual)
  const roomRef = useRef('')
  const dispatchRoomParticipantCommand =
    useDispatchRoomParticipantCommand(participantId)
  const prevCallId = usePrevious(callId, '')
  const prevRoomIdRef = useRef('')

  console.debug(
    'RoomPage:',
    JSON.stringify({
      room,
      callId,
      prevCallId,
      roomId,
      roomName,
      participantId,
      prevRoomId: prevRoomIdRef.current,
      roomRef: roomRef.current,
    })
  )

  useEffect(() => {
    console.debug(
      'Watch Transfer',
      JSON.stringify({
        room,
        roomId,
        roomName,
        prevRoomId: prevRoomIdRef.current,
        roomRef: roomRef.current,
      })
    )
    if (roomId && prevRoomIdRef.current && roomId !== prevRoomIdRef.current) {
      console.debug('Transferred to a new room')
      roomRef.current = roomName
      pushHandler({ roomName, force: true })
    }
    if (roomId) {
      prevRoomIdRef.current = roomId
    }
  }, [pushHandler, room, roomId, roomName])

  useEffect(() => {
    console.debug(
      'RoomPage Watcher',
      JSON.stringify({
        room,
        callId,
        participantId,
        roomRef: roomRef.current,
      })
    )
    if (roomRef.current !== room) {
      // Needs a ref to the prev/current room for the transfer.
      if (Boolean(roomRef.current) && callId && participantId) {
        console.debug('Transfer to', room)
        dispatchRoomParticipantCommand({
          action: RoomCommandAction.Transfer,
          participantId,
          extension: room,
        })
      } else if (!callId) {
        console.debug('Joining room', room)
        dispatch(
          callRequest({
            extension: room,
            hasVideo: true, // FIXME:
          })
        )
      }

      roomRef.current = room
    }
  }, [dispatch, dispatchRoomParticipantCommand, room, callId, participantId])

  if (!callId && prevCallId) {
    return <PostCallPage />
  }
  if (callId) {
    return <CallPageEnhanced callId={callId} />
  }

  return <CallLoading />
})

// prettier-ignore
export const RoomRoute = ({ children, ...rest }: RouteProps) => { /* eslint-disable-line */
  const dispatch = useDispatch()
  const hasAuth = useSelector(hasValidAuth)

  return (
    <Route
      {...rest}
      render={({ match }) => {
        if (hasAuth) {
          return <RoomPage />
        }

        const { room } = match.params
        if (room) {
          dispatch(uiActions.setAutoDialExtension(room))
        }
        return <Redirect to={RoutePath.GuestLogin} />
      }}
    />
  )
}
