import React, {
  FC,
  ComponentType,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react'
import Slider, { SliderProps, SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import {
  LabelIconState,
  AUDIO_MAX_VOLUME,
  AUDIO_MIN_VOLUME,
} from 'src/common/constants'
import { useThrottle } from 'src/common/hooks'
import { IconState } from 'src/components/IconState'
import { TestId } from 'src/constants'

interface RangeSliderProps extends SliderProps {
  className?: string
  currentValue?: number
  hint?: string
  horizontalLabel?: boolean
  label?: string
  labelClassName?: string
  labelIconState?: LabelIconState
  LowerIcon?: ComponentType<{ className?: string }>
  maxValue?: number
  minValue?: number
  onValueChange: (newValue: number) => void
  step?: number
  testId?: TestId // TODO: make required
  trackStyle?: object
  UpperIcon?: ComponentType<{ className?: string }>
}
// const { createSliderWithTooltip } = Slider
const { Handle } = Slider

const handle = (props: any) => {
  const { value, dragging, index, ...restProps } = props
  return (
    <SliderTooltip
      prefixCls='rc-slider-tooltip'
      overlay={value}
      visible={dragging}
      placement='top'
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  )
}

const RangeSlider: FC<RangeSliderProps> = ({
  className = '',
  currentValue = 0,
  hint = '',
  horizontalLabel = false,
  label,
  labelClassName = '',
  labelIconState,
  LowerIcon = null,
  maxValue = AUDIO_MAX_VOLUME,
  minValue = AUDIO_MIN_VOLUME,
  onValueChange,
  step = 0.1,
  testId,
  trackStyle = { backgroundColor: 'var(--sw-selected)' },
  UpperIcon = null,
  ...rest
}) => {
  const [isDragging, setDragging] = useState(false)
  const [controlledValue, setControlledValue] = useState(currentValue)
  const throttledValue = useThrottle(controlledValue, 200)
  const firstRun = useRef(true)
  const { disabled } = rest

  useEffect(() => {
    if (!firstRun.current) {
      onValueChange(throttledValue)
    }
    firstRun.current = false
  }, [onValueChange, throttledValue])

  useEffect(() => {
    // Debounce the input prop change to control the case where
    // the user is not dragging anymore but the events from FS
    // are still coming because otherwise there is no 'live' feedback.
    // for example a volume slider that live-change the sound volume.
    let timer: ReturnType<typeof setTimeout>
    if (!isDragging) {
      timer = setTimeout(() => {
        setControlledValue(currentValue)
      }, 200)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [isDragging, currentValue])

  const updateValue = useCallback(
    (val: number) => {
      if (val > maxValue) {
        val = maxValue
      }

      if (val < minValue) {
        val = minValue
      }

      if (Math.round(controlledValue) === Math.round(val)) {
        return
      }

      setControlledValue(Math.round(val))
    },
    [controlledValue, maxValue, minValue]
  )

  const wrapperClassNames = horizontalLabel ? '' : 'py-2 h-8'
  const opacityClass = disabled ? 'opacity-50' : ''

  return (
    <div className={`${opacityClass} ${className}`}>
      {!horizontalLabel && label && (
        <label className={labelClassName}>
          {label}
          {labelIconState && <IconState state={labelIconState} />}
        </label>
      )}
      <div
        className={`flex items-center ${wrapperClassNames}`}
        data-test={testId}
      >
        {horizontalLabel && label && (
          <label className={`leading-none ${labelClassName}`}>{label}</label>
        )}
        {LowerIcon && (
          <div data-test={TestId.LowerIcon}>
            <LowerIcon className='mr-3 text-lg' />
          </div>
        )}
        <Slider
          className={isDragging ? 'dragging' : ''}
          handle={handle}
          max={maxValue}
          min={minValue}
          onAfterChange={() => setDragging(false)}
          onBeforeChange={() => setDragging(true)}
          onChange={updateValue}
          step={step}
          trackStyle={trackStyle}
          value={controlledValue}
          {...rest}
        />
        {UpperIcon && (
          <div data-test={TestId.HigherIcon}>
            <UpperIcon className='ml-3 text-lg' />
          </div>
        )}
      </div>
      {hint && <p className='text-xs italic'>{hint}</p>}
    </div>
  )
}

// @ts-ignore
// export default createSliderWithTooltip(RangeSlider)
export default RangeSlider
