import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { QuestionIcon } from 'src/common/icons'
import { FontAwesomeSizeProp } from 'src/common/icons/FontAwesomeIcon'
import { uiActions } from 'src/common/redux/features'
import { Displayable } from 'src/common/constants'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import {
  withTextTooltip,
  TOOLTIP_OFFSET_Y_TOP_BAR,
} from 'src/hoc/withTextTooltip'

interface ButtonProps {
  className?: string
  label?: string
  iconSize?: FontAwesomeSizeProp
}

const Button: FC<ButtonProps> = ({
  className = '',
  label,
  iconSize = 'lg',
}) => {
  const dispatch = useDispatch()
  const clickHandler = () => {
    dispatch(uiActions.hide(Displayable.AppMenu))
    if (
      // @ts-ignore
      typeof window.$crisp !== 'undefined' &&
      // @ts-ignore
      window.$crisp.is &&
      // @ts-ignore
      window.$crisp.push
    ) {
      // @ts-ignore
      const cmd = window.$crisp.is('chat:opened') ? 'chat:close' : 'chat:open'
      // @ts-ignore
      window.$crisp.push(['do', cmd])
      return
    }
    return console.debug('Crisp not configured')
  }

  return (
    <button
      className={`p-2 ${className}`}
      onClick={clickHandler}
      aria-label={tr(Label.HELP)}
      data-test={TestId.TopBarButtonHelp}
    >
      <QuestionIcon size={iconSize} />
      {label && <div className='text-base px-2'>{label}</div>}
    </button>
  )
}

export const CrispButton = withTextTooltip({
  offsetY: TOOLTIP_OFFSET_Y_TOP_BAR,
})(Button)
