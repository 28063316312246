import React, { FC, MouseEvent } from 'react'
import { compose } from 'redux'
import { RoomCommandAction } from 'src/common/constants'
import { StopIcon } from 'src/common/icons'
import { useDispatchRoomCommand } from 'src/common/hooks'
import { withConferencePlaybackControlsScope } from 'src/common/hoc'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import { Button } from '../Button/Button'
import { withTextTooltip } from 'src/hoc/withTextTooltip'

type StopButtonProps = {
  roomId: string
  className?: string
}

const StopButton = ({
  roomId,
  className = 'sw-btn-icon-toggle',
}: {
  roomId: string
  className: string
}) => {
  const dispatchRoomCommand = useDispatchRoomCommand()

  const clickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    dispatchRoomCommand({ roomId, action: RoomCommandAction.StopPlayback })
  }

  return (
    <Button
      className={className}
      onClick={clickHandler}
      ariaLabel={tr(Label.STOP)}
      testId={TestId.MediaButtonStop}
    >
      <StopIcon size='lg' fixedWidth />
    </Button>
  )
}

export const StopPlaybackButton = compose<FC<StopButtonProps>>(
  withConferencePlaybackControlsScope,
  withTextTooltip()
)(StopButton)
