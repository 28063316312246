interface IAppMixPanel {
  init: () => void
  identify: (userId: string, params: any) => void
  track: (event: string, params?: any) => void
}

export let appMixPanel: IAppMixPanel

export const setAppMixPanel = (mixpanel: IAppMixPanel) => {
  appMixPanel = mixpanel
}
