import React, { memo, FC, ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, useRouteMatch, Redirect, Link } from 'react-router-dom'
import { tr, Label } from 'src/common/i18n'
import { RoutePath } from 'src/common/constants'
import {
  LockIcon,
  HomeIcon,
  KeyIcon,
  WrenchIcon,
  ToolsIcon,
  PaletteIcon,
} from 'src/common/icons'
import { getGlobalLastUpdatedAt } from 'src/common/redux/features/roomTemplates/roomTemplatesSelectors'
import { dateFormatDistance } from 'src/services/Helpers'
import { RoomsSecurity } from './RoomsSecurity'
import { Options } from './Options'
import { AdvancedOptions } from './AdvancedOptions'
import { RoomTemplateAppearance } from '../Appearance'
import { HomeScreen } from './HomeScreen'
import { Permissions } from './Permissions'

type TabProps = {
  path: RoutePath
  label: string
  Component: ComponentType<any>
  IconComponent: ComponentType<any>
  iconClass?: string // useful for custom svgs
}

const TABS: TabProps[] = [
  {
    path: RoutePath.SettingsGlobalRoomsSecurity,
    label: Label.ROOMS_SECURITY,
    Component: RoomsSecurity,
    IconComponent: LockIcon,
  },
  {
    path: RoutePath.SettingsGlobalOptions,
    label: Label.OPTIONS,
    Component: Options,
    IconComponent: WrenchIcon,
  },
  {
    path: RoutePath.SettingsGlobalAdvancedOptions,
    label: Label.ADVANCED_OPTIONS,
    Component: AdvancedOptions,
    IconComponent: ToolsIcon,
  },
  {
    path: RoutePath.SettingsGlobalAppearance,
    label: Label.APPEARANCE,
    Component: RoomTemplateAppearance,
    IconComponent: PaletteIcon,
  },
  {
    path: RoutePath.SettingsGlobalHomeScreen,
    label: Label.HOME_SCREEN,
    Component: HomeScreen,
    IconComponent: HomeIcon,
  },
  {
    path: RoutePath.SettingsGlobalPermissions,
    label: Label.PERMISSIONS,
    Component: Permissions,
    IconComponent: KeyIcon,
  },
]

export const Global: FC = () => {
  return (
    <>
      <div className='administration-section-title'>
        <div className='font-neue font-bold text-contrast-h text-2xl'>
          {tr(Label.GLOBAL)}
        </div>
      </div>
      <div className='block-primary flex flex-row administration-section-content'>
        <div className='w-1/3'>
          <div className='administration-section-tabs-wrapper divide-y divide-contrast-l'>
            {TABS.map((props) => {
              return <Tab key={props.path} {...props} />
            })}
          </div>
        </div>
        <div className='relative w-3/4 pl-8'>
          <GlobalLastEdited />
          <Switch>
            {TABS.map(({ path, Component }) => {
              return (
                <Route key={path} exact path={path}>
                  <Component />
                </Route>
              )
            })}
            <Redirect to={RoutePath.SettingsGlobalRoomsSecurity} />
          </Switch>
        </div>
      </div>
    </>
  )
}

const Tab: FC<TabProps> = memo(({ path, label, IconComponent }) => {
  const match = useRouteMatch({ path, strict: true, sensitive: true })
  const className = match ? 'text-sw-selected border-sw-selected' : ''

  return (
    <Link to={path} className='block-primary-tint block w-full'>
      <label className={`administration-section-tab-label ${className}`}>
        <IconComponent
          fixedWidth
          className={`mr-2 ${match ? '' : 'opacity-50'}`}
        />
        {tr(label)}
      </label>
    </Link>
  )
})

const GlobalLastEdited = () => {
  const lastUpdatedAt = useSelector(getGlobalLastUpdatedAt)
  return (
    <span className='absolute top-0 right-0 text-sm text-contrast-m'>
      Last edited {dateFormatDistance(lastUpdatedAt)}
    </span>
  )
}
