import React, { FC } from 'react'
import { tr, Label } from 'src/common/i18n'
import { UseFetchRoomConsumerProps } from 'src/common/hooks'
import { RoomKeys } from 'src/common/rest'
import { TextAreaGroup } from 'src/components/TextAreaGroup/TextAreaGroup'
import { useRoomAutoSave } from '../../../effects/useRoomAutoSave'

export const RoomTextAreaGroup: FC<
  UseFetchRoomConsumerProps & {
    name: RoomKeys
    label: Label
    defaultValue: string | null
  }
> = ({ update, name, label, defaultValue, getErrorByName }) => {
  const { currentValue, iconState, blurHandler, changeHandler } =
    useRoomAutoSave({
      defaultValue,
      updateRoomHandler: update,
    })

  return (
    <TextAreaGroup
      name={name}
      label={tr(label)}
      labelIconState={iconState}
      onChange={changeHandler}
      onBlur={blurHandler}
      value={currentValue as string}
      error={getErrorByName(name)}
    />
  )
}
