import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { getActiveCallExtension } from 'src/common/redux/features/calls/callSelectors'
import { Header } from './Header'
import { CallDuration } from 'src/components/CallDuration/CallDuration'

const Left: FC = () => {
  const extension = useSelector(getActiveCallExtension)

  return (
    <div className='px-2'>
      <h2 className='text-base leading-5'>{extension}</h2>
    </div>
  )
}

const Right: FC = () => {
  return (
    <div className='flex flex-1 items-center mr-2'>
      <div className='hidden md:flex items-center justify-end flex-1'>
        <CallDuration />
      </div>
    </div>
  )
}

const DirectCallHeader = () => {
  return <Header left={Left} right={Right} />
}

export default DirectCallHeader
