import React, { FC } from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { CantinaState } from 'src/common/redux/interfaces'
import { isSendingVideo } from 'src/common/redux/features/calls/callSelectors'
import {
  isDeaf,
  isMyself,
  isVMuted,
} from 'src/common/redux/features/participants/participantSelectors'
import { uiActions } from 'src/common/redux/features'
import {
  withConferenceVideoMuteSelfScope,
  withCurrentParticipant,
  withActiveCall,
} from 'src/common/hoc'
import { Displayable, Scope, RoomCommandAction } from 'src/common/constants'
import { CameraOffIcon, CameraOnIcon } from 'src/common/icons'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import { withTooltipCaret } from 'src/components/Tooltip'
import { VideoSettings } from 'src/components/Devices/VideoSettings'
import { TOOLTIP_OFFSET_Y_BOTTOM_BAR } from 'src/hoc/withTextTooltip'
import {
  ParticipantCommandButton,
  ParticipantButtonProps,
  InCallParticipantButtonProps,
} from './index'

const ParticipantVideoButtonCameraDisabled: FC<ParticipantButtonProps> = ({
  participantId,
  testId,
  ...rest
}) => {
  const dispatch = useDispatch()

  // Open DevicesModal to select a device
  const clickHandler = () => dispatch(uiActions.show(Displayable.DevicesModal))

  // Show button as 'active' (same as video muted)
  return (
    <ParticipantCommandButton
      activated={true}
      clickHandler={clickHandler}
      Icon={CameraOffIcon}
      label={tr(Label.ENABLE_CAMERA)}
      participantId={participantId}
      scope={Scope.ConferenceVideoMuteSelf}
      testId={testId}
      {...rest}
    />
  )
}

const ParticipantVideoMuteButton: FC<ParticipantButtonProps> = ({
  offsetY,
  participantId,
  scope,
  titleSecondary,
  ...rest
}) => {
  let buttonActive = false
  let buttonScope = scope ?? Scope.ConferenceVideoMute
  let ButtonIcon = CameraOnIcon
  let buttonLabel = tr(Label.VIDEO_MUTE)
  const buttonAction = {
    participantId,
    action: RoomCommandAction.VideoMuteParticipant,
  }

  const muted = useSelector((state: CantinaState) =>
    isVMuted(state, participantId)
  )
  const dndEnabled = useSelector((state: CantinaState) =>
    isDeaf(state, participantId)
  )
  const myself = useSelector((state: CantinaState) =>
    isMyself(state, participantId)
  )
  const isOtherUserInDND = dndEnabled && !myself

  if (muted) {
    buttonActive = true
    buttonScope = scope ?? Scope.ConferenceVideoUnmute
    ButtonIcon = CameraOffIcon
    buttonLabel = tr(Label.VIDEO_UNMUTE)
    buttonAction.action = RoomCommandAction.VideoUnMuteParticipant
  }

  if (isOtherUserInDND) {
    buttonLabel = tr(Label.DND_UNAVAILABLE)
  }

  return (
    <ParticipantCommandButton
      action={buttonAction}
      activated={buttonActive}
      disabled={isOtherUserInDND}
      Icon={ButtonIcon}
      label={buttonLabel}
      participantId={participantId}
      offsetY={offsetY}
      scope={buttonScope}
      titleSecondary={titleSecondary}
      {...rest}
    />
  )
}

interface ParticipantVideoButtonProps extends ParticipantButtonProps {
  callId?: string
}

const ParticipantVideoButton: FC<ParticipantVideoButtonProps> = ({
  callId,
  ...rest
}) => {
  const sendingVideo = useSelector(
    (state: CantinaState) => callId && isSendingVideo(state, callId)
  )

  return !callId || sendingVideo ? (
    <ParticipantVideoMuteButton
      testId={TestId.BottomBarButtonVideo}
      {...rest}
    />
  ) : (
    <ParticipantVideoButtonCameraDisabled
      testId={TestId.BottomBarButtonVideo}
      {...rest}
    />
  )
}

// Component which includes settings for tooltip
const BottomBarParticipantVideoButton = (
  props: ParticipantVideoButtonProps
) => (
  <ParticipantVideoButton
    scope={Scope.ConferenceVideoMuteSelf}
    offsetY={TOOLTIP_OFFSET_Y_BOTTOM_BAR}
    titleSecondary={props.disabled ? '' : 'V'}
    {...props}
  />
)

const BottomBarParticipantVideoButtonEnabled = compose<
  FC<ParticipantVideoButtonProps>
>(
  withTooltipCaret(
    VideoSettings,
    TestId.BottomBarButtonVideoTooltip,
    Label.CAMERA_OPTIONS
  )
)(BottomBarParticipantVideoButton)

// The InCall button is used in the bottom bar, and includes the caret for the audio settings
// plus tooltip overrides
const InCallParticipantVideoButton = compose<FC<InCallParticipantButtonProps>>(
  withConferenceVideoMuteSelfScope,
  withCurrentParticipant,
  withActiveCall
)((props: ParticipantVideoButtonProps) => {
  if (props.disabled) {
    return <BottomBarParticipantVideoButton {...props} />
  }
  return <BottomBarParticipantVideoButtonEnabled {...props} />
})

export { ParticipantVideoMuteButton, InCallParticipantVideoButton }
