import React, { FC } from 'react'
import { tr, Label } from 'src/common/i18n'
import { useRoomTemplateField } from 'src/common/hooks'
import { RoomTemplateKeys } from 'src/common/rest'
import { InputGroup } from 'src/components/InputGroup/InputGroup'

type RoomTemplateInputProps = {
  name: RoomTemplateKeys
  label: Label
  type?: string
  hint?: Label
}

export const RoomTemplateInputGroup: FC<RoomTemplateInputProps> = ({
  name,
  label,
  type,
  hint,
}) => {
  const { reduxValue, update, iconState } = useRoomTemplateField(name)

  return (
    <InputGroup
      name={name}
      label={tr(label)}
      labelIconState={iconState}
      onBlur={(event) => update(event.target.value)}
      defaultValue={reduxValue as string}
      type={type}
      hint={hint && tr(hint)}
    />
  )
}
