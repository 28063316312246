import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { callViewActions } from '../redux/views'

export function useWatchPip(mcuElementClass: string) {
  const dispatch = useDispatch()

  useEffect(() => {
    // Get the video element after the DOM has rendered
    const video = <HTMLVideoElement>(
      document.querySelector(`video.${mcuElementClass}`)
    )

    const pipCloseHandler = () =>
      dispatch(callViewActions.setPictureInPicture(false))
    const playVideo = () => {
      video?.play()
    }

    if (video) {
      // Capture the close "X" in the browser's PiP window
      video.addEventListener('leavepictureinpicture', pipCloseHandler)

      // The close "X" pauses the video so we need to restart it
      video.addEventListener('pause', playVideo)

      return () => {
        video.removeEventListener('leavepictureinpicture', pipCloseHandler)
        video.removeEventListener('pause', playVideo)
      }
    }
  }, [dispatch])
}
