import React, { FC } from 'react'
import { Label } from 'src/common/i18n'
import { LabelIconState } from 'src/common/constants'
import { useRoomTemplateField } from 'src/common/hooks'
import { RoomTemplateKeys } from 'src/common/rest'
import { TestId } from 'src/constants'
import { PINInputGroup } from '../InputGroup/PINInputGroup'

// Use this component to set the Global PIN

type PINInputGlobalProps = {
  className?: string
  label: Label
  labelIconState?: LabelIconState
  name: RoomTemplateKeys
  testId: TestId
}

export const PINInputGlobal: FC<PINInputGlobalProps> = ({
  className,
  label,
  name,
  testId,
}) => {
  const {
    reduxValue: defaultValue,
    update,
    iconState,
  } = useRoomTemplateField(name)

  return (
    <PINInputGroup
      className={className}
      clearButtonFunction={() => update('')}
      defaultValue={defaultValue}
      label={label}
      labelIconState={iconState}
      name={name}
      testId={testId}
      updateFunction={(val) => update(val)}
    />
  )
}
