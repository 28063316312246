import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { roomJoinedAction, roomLeftAction } from '../rooms/roomsSlice'
import { callActions } from '../../features'
import { Floater, FloatersState } from '../../interfaces'
import { FloaterType } from '../../../constants'

export const initialFloatersState: FloatersState = Object.freeze({
  byId: {},
  idsByType: {
    [FloaterType.PlaybackControls]: [],
    [FloaterType.ParticipantOptions]: [],
  },
  idsByOrder: [],
})

const MAX_FLOATERS = {
  [FloaterType.PlaybackControls]: 1,
  [FloaterType.ParticipantOptions]: 4,
}

const floatersSlice = createSlice({
  name: 'floaters',
  initialState: initialFloatersState,
  reducers: {
    addFloater: (state, { payload }: PayloadAction<Floater>) => {
      const { id, type } = payload
      const byType = state.idsByType[type]
      const countByType = byType?.length

      if (!state.byId[id]) {
        // If we are over the limit, remove the oldest of that type before
        // adding a new floater
        if (countByType === MAX_FLOATERS[type]) {
          const oldestId = byType[countByType - 1]
          delete state.byId[oldestId]
        }
        state.byId[id] = payload
      }

      // Track order of opened floaters
      if (!byType.includes(id)) {
        if (countByType === MAX_FLOATERS[type]) {
          byType.pop()
        }
        byType.unshift(id)
      }

      if (!state.idsByOrder.includes(id)) {
        if (countByType === MAX_FLOATERS[type]) {
          state.idsByOrder.pop()
        }
        state.idsByOrder.unshift(id)
      }
    },
    removeFloater: (
      state,
      { payload }: PayloadAction<{ id: string; type: FloaterType }>
    ) => {
      const { id, type } = payload

      delete state.byId[id]
      state.idsByType[type] = state.idsByType[type].filter(
        (x: string) => x !== id
      )
      state.idsByOrder = state.idsByOrder.filter((x: string) => x !== id)
    },
    reOrder: (state, { payload }: PayloadAction<{ id: string }>) => {
      const { id } = payload
      const newOrder = state.idsByOrder.filter((x: string) => x !== id)

      newOrder.unshift(id)
      state.idsByOrder = newOrder
    },
  },
  extraReducers: (builder) => {
    const shouldClear = isAnyOf(
      roomJoinedAction,
      roomLeftAction,
      callActions.destroy
    )
    builder.addMatcher(shouldClear, (state) => {
      state.byId = {}
      state.idsByType = {
        [FloaterType.PlaybackControls]: [],
        [FloaterType.ParticipantOptions]: [],
      }
      state.idsByOrder = []
    })
  },
})

export const {
  actions: floatersActions,
  reducer: floatersReducer,
} = floatersSlice
