import { useState, useEffect, useRef } from 'react'

type UseAudioAnalyserParams = {
  stream: MediaStream
}

export const useAudioAnalyser = ({ stream }: UseAudioAnalyserParams) => {
  const [dataArray, setDataArray] = useState(new Uint8Array())
  const requestRef = useRef<number>()

  useEffect(() => {
    const audioContext = new (window.AudioContext ||
      (window as any).webkitAudioContext)()
    const analyser = audioContext.createAnalyser()
    analyser.fftSize = 64
    analyser.minDecibels = -90
    analyser.maxDecibels = -10
    analyser.smoothingTimeConstant = 0.85

    try {
      audioContext.createMediaStreamSource(stream).connect(analyser)
    } catch (error) {
      console.debug('No audio track found')
    }

    const startMetering = () => {
      const dataArray = new Uint8Array(analyser.frequencyBinCount)
      analyser.getByteFrequencyData(dataArray)
      setDataArray(dataArray)
      requestRef.current = requestAnimationFrame(startMetering)
    }
    requestRef.current = requestAnimationFrame(startMetering)

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
      audioContext.close()
    }
  }, [stream])

  /**
   * dataArray contains the values of the volume gathered within a single requestAnimationFrame
   * With reduce, divide by 200 and Math.floor we translate the array values into a 0-10 scale to
   * draw the green bars for the voice/volume energy.
   */
  const energy = Math.floor(
    dataArray.reduce((final, value) => final + value, 0) / 200
  )
  return [energy]
}
