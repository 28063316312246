import React, { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  getAvatar,
  getPublicProfile,
} from 'src/common/redux/features/auth/authSelectors'
import { CantinaState } from 'src/common/redux/interfaces'
import { Displayable } from 'src/common/constants'
import { uiActions } from 'src/common/redux/features'
import { Size } from 'src/constants'
import { UserProfile } from './UserProfile'

export const MiniProfile = () => {
  const { name, email } = useSelector(getPublicProfile, shallowEqual)
  const avatar = useSelector((state: CantinaState) => getAvatar(state))
  const dispatch = useDispatch()
  const profileHandler = useCallback(() => {
    dispatch(uiActions.show(Displayable.ProfileModal))
  }, [dispatch])

  return (
    <button onClick={profileHandler} className='contents'>
      <UserProfile
        avatar={avatar}
        name={name}
        subtitle={email}
        size={Size.ExtraLarge}
        wrapperClassName='text-sm cursor-pointer mx-2'
      />
    </button>
  )
}
