import { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { CantinaState } from '../redux/interfaces'
import * as roomListSelectors from '../redux/features/roomList/roomListSelectors'
import * as roomsSelectors from '../redux/features/rooms/roomsSelectors'
import { getActiveCallRoomId } from '../redux/features/calls/callSelectors'

/**
 * These are the valid selectors between `rooms` and `roomList`
 * FS events vs CantinaMgr events
 */
type CrossSelector = keyof typeof roomsSelectors &
  keyof typeof roomListSelectors

type CrossSelectorReturnType<P extends CrossSelector> = ReturnType<
  typeof roomsSelectors[P]
> &
  ReturnType<typeof roomListSelectors[P]>

export const useSmartRoomSelector = <T extends CrossSelector>(
  selectorName: T,
  roomId: string
): CrossSelectorReturnType<T> => {
  // console.debug('useSmartRoomSelector run with', selectorName, roomId)
  const currentRoomId = useSelector(getActiveCallRoomId)
  const selector = useMemo(() => {
    return currentRoomId && currentRoomId === roomId
      ? roomsSelectors[selectorName]
      : roomListSelectors[selectorName]
  }, [selectorName, roomId, currentRoomId])

  return useSelector((state: CantinaState) => {
    return selector(state, roomId) as CrossSelectorReturnType<T>
  }, shallowEqual)
}
