import React from 'react'
import { TestId } from 'src/constants'

const url = 'https://signalwire.com/policies?doc=privacy'

export const PrivacyPolicyLink = () => {
  return (
    <div className='text-xs md:text-sm text-contrast-m mt-4'>
      By continuing, you are confirming that you have read and agree to
      SignalWire&apos;s&nbsp;
      <a
        href={url}
        className='underline text-sw-red'
        target='_blank'
        rel='noreferrer'
        data-test={TestId.PrivacyPolicyLink}
      >
        privacy policy
      </a>
      .
    </div>
  )
}
