import {
  createSlice,
  createAction,
  PayloadAction,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit'
import { CallStateError } from '../../../constants'
import {
  Call,
  CallRequestPayload,
  CallState,
  CanvasInfo,
} from '../../interfaces'
import { roomLeftAction } from '../rooms/roomsSlice'

type UpdateAudioFlagsPayload = {
  callId: string
  isSendingAudio: boolean
  isReceivingAudio: boolean
}

type UpdateVideoFlagsPayload = {
  callId: string
  isSendingVideo: boolean
  isReceivingVideo: boolean
}

type AddInboundTrackIdPayload = {
  callId: string
  kind: MediaStreamTrack['kind']
  trackId: string
}

type SecondSourcePayload = {
  callId: string
  secondSourceCallId: string
}

export const callHangup: ActionCreatorWithPayload<string> =
  createAction('calls/hangup')
export const callRequest = createAction<CallRequestPayload>('calls/request')

export const initialCallState: CallState = Object.freeze({
  active: null,
  byId: {},
  canvasInfoById: {},
  deviceErrors: {},
  ids: [],
  lastTriedCall: <CallRequestPayload>{},
  rtcPeerConfig: <RTCConfiguration>{},
  errors: {},
})

const callSlice = createSlice({
  name: 'calls',
  initialState: initialCallState,
  reducers: {
    create: {
      reducer(state, { payload: call }: PayloadAction<Call>) {
        if (state.ids.includes(call.id) === false) {
          state.ids.unshift(call.id)
        }
        state.byId[call.id] = call
        state.active = state.active || call.id
        state.errors = {}
      },
      prepare(call: Call) {
        return {
          payload: { startedAt: Date.now(), ...call },
        }
      },
    },
    update: (state, { payload: call }: PayloadAction<Call>) => {
      if (call.id in state.byId) {
        state.byId[call.id] = {
          ...state.byId[call.id],
          ...call,
        }
      }
    },
    updateLayout: (state, { payload }: PayloadAction<CanvasInfo>) => {
      state.canvasInfoById[payload.callId] = payload
    },
    destroy: {
      reducer(state, { payload: call }: PayloadAction<Call>) {
        if (call.id in state.byId) {
          state.byId[call.id] = {
            ...state.byId[call.id],
            ...call,
          }
        }
        delete state.canvasInfoById[call.id]
        state.ids.forEach((callId) => {
          if (state.byId[callId] && state.byId[callId].secondSourceCallIds) {
            state.byId[callId].secondSourceCallIds = state.byId[
              callId
            ].secondSourceCallIds!.filter((id) => id !== call.id)
          }
        })
        if (state.active === call.id) {
          state.active = null
        }
        state.errors = {}
      },
      prepare(call: Call) {
        return {
          payload: { endedAt: Date.now(), ...call },
        }
      },
    },
    updateAudioFlags: (
      state,
      { payload }: PayloadAction<UpdateAudioFlagsPayload>
    ) => {
      if (payload.callId in state.byId) {
        const { isSendingAudio, isReceivingAudio } = payload
        state.byId[payload.callId] = {
          ...state.byId[payload.callId],
          isSendingAudio,
          isReceivingAudio,
        }
      }
    },
    updateVideoFlags: (
      state,
      { payload }: PayloadAction<UpdateVideoFlagsPayload>
    ) => {
      if (payload.callId in state.byId) {
        const { isSendingVideo, isReceivingVideo } = payload
        state.byId[payload.callId] = {
          ...state.byId[payload.callId],
          isSendingVideo,
          isReceivingVideo,
        }
      }
    },
    addInboundTrackId: (
      state,
      { payload }: PayloadAction<AddInboundTrackIdPayload>
    ) => {
      if (payload.callId in state.byId) {
        const { kind, trackId } = payload
        if (kind === 'audio') {
          state.byId[payload.callId].inboundAudioTrackIds =
            state.byId[payload.callId].inboundAudioTrackIds || []
          state.byId[payload.callId].inboundAudioTrackIds!.push(trackId)
        } else if (kind === 'video') {
          state.byId[payload.callId].inboundVideoTrackIds =
            state.byId[payload.callId].inboundVideoTrackIds || []
          state.byId[payload.callId].inboundVideoTrackIds!.push(trackId)
        }
      }
    },
    addSecondSourceCallId: (
      state,
      { payload }: PayloadAction<SecondSourcePayload>
    ) => {
      const { callId, secondSourceCallId } = payload
      if (callId in state.byId) {
        const { secondSourceCallIds = [] } = state.byId[callId]
        secondSourceCallIds.push(secondSourceCallId)
        state.byId[callId] = {
          ...state.byId[callId],
          secondSourceCallIds,
        }
      }
    },
    removeSecondSourceCallId: (state, { payload }: PayloadAction<string>) => {
      const callId = state.active

      if (callId) {
        const { secondSourceCallIds = [] } = state.byId[callId]
        const updatedIds = secondSourceCallIds.filter((id) => id !== payload)
        state.byId[callId] = {
          ...state.byId[callId],
          secondSourceCallIds: updatedIds,
        }
      }
    },
    setRTCPeerConfig: (state, { payload }: PayloadAction<RTCConfiguration>) => {
      state.rtcPeerConfig = payload
    },
    setDeviceErrors: (state, { payload }) => {
      state.deviceErrors = payload
    },
    setError: (
      state,
      { payload }: PayloadAction<{ errorType: CallStateError; error: unknown }>
    ) => {
      state.errors[payload.errorType] = payload.error
    },
    cleanupErrors: (state) => {
      state.errors = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(roomLeftAction, (state, { payload }) => {
        const { callId } = payload
        if (!state.byId?.[callId]) {
          return state
        }
        delete state.byId[callId].roomId
      })
      .addCase(callHangup, (state, { payload }) => {
        if (state.active) {
          state.byId[state.active].secondSourceCallIds = state.byId[
            state.active
          ].secondSourceCallIds?.filter?.((x) => x !== payload)
        }
      })
      .addCase(callRequest, (state, { payload }) => {
        state.lastTriedCall = payload
        state.deviceErrors = {}
        state.errors = {}
      })
  },
})

export const callAnswer: ActionCreatorWithPayload<string> =
  createAction('calls/answer')
export const callToggleHold: ActionCreatorWithPayload<string> =
  createAction('calls/toggleHold')
export const callScreenShareStart: ActionCreatorWithPayload<string> =
  createAction('calls/screenShareStart')
export const callScreenShareStop: ActionCreatorWithPayload<string> =
  createAction('calls/screenShareStop')
export const callDTMF = createAction(
  'calls/dtmf',
  (callId: string, dtmf: string) => {
    return { payload: { callId, dtmf } }
  }
)
export const conferenceJoin: ActionCreatorWithPayload<string> =
  createAction('calls/confJoin')
export const conferenceLeave: ActionCreatorWithPayload<string> =
  createAction('calls/confLeave')
export const updateDevice = createAction(
  'calls/updateDevice',
  ({
    callId,
    kind,
    deviceId,
  }: {
    callId: string
    kind: string
    deviceId: string
  }) => {
    return { payload: { callId, kind, deviceId } }
  }
)
export const updateMultipleDevices: ActionCreatorWithPayload<{
  callId: string
  cameraId: string
  microphoneId: string
  speakerId: string
}> = createAction('calls/updateMultipleDevices')
export const stopLocalVideoTrack: ActionCreatorWithPayload<string> =
  createAction('calls/stopLocalVideoTrack')
export const restoreLocalVideoTrack: ActionCreatorWithPayload<string> =
  createAction('calls/restoreLocalVideoTrack')
export const stopLocalAudioTrack: ActionCreatorWithPayload<string> =
  createAction('calls/stopLocalAudioTrack')
export const restoreLocalAudioTrack: ActionCreatorWithPayload<string> =
  createAction('calls/restoreLocalAudioTrack')
export const addSecondSource: ActionCreatorWithPayload<any> = createAction(
  'calls/addSecondSource'
)

// prettier-ignore
export const {
  actions: callActions,
  reducer: callReducer
} = callSlice
