import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { GroupRole } from '../constants'
import { groupsActions } from '../redux/features'
import { RequestState, Group, RailsEndpoint, FetchError } from '../rest'
import { useInternalAPI } from './useInternalAPI'

type CreateParams = {
  name: string
  parent_group_id?: string
}
type UseCreateGroupData = {
  group: Group | null
  requestState: RequestState
  errors: {
    [key: string]: string[]
  }
}
const createOptions = {
  endpoint: RailsEndpoint.Groups,
  method: 'POST',
}
const addUserToGroupOptions = {
  endpoint: RailsEndpoint.GroupMemberships,
  method: 'POST',
}

export const useCreateGroup = () => {
  const dispatch = useDispatch()
  const { invokeAPI: createHandler } = useInternalAPI(createOptions)
  const { invokeAPI: addUserToGroup } = useInternalAPI(addUserToGroupOptions)
  const [data, setData] = useState<UseCreateGroupData>({
    group: null,
    requestState: RequestState.Success,
    errors: {},
  })

  const create = async (params: CreateParams, userIds: string[]) => {
    try {
      if (data.requestState !== RequestState.Loading) {
        setData({
          ...data,
          requestState: RequestState.Loading,
          errors: {},
        })
      }
      const group = await createHandler({
        body: JSON.stringify(params),
      })
      if (userIds?.length) {
        const promises = userIds.map((userId) => {
          return addUserToGroup({
            urlTransform: (url) => {
              return url.replace(':groupId', group.id)
            },
            body: JSON.stringify({
              user_id: userId,
              group_role: GroupRole.Member,
            }),
          })
        })
        await Promise.all(promises)
      }
      dispatch(groupsActions.fetchGroupsSuccess([group]))
      setData({
        group,
        requestState: RequestState.Success,
        errors: {},
      })
    } catch (error) {
      const handlerError = <FetchError>error
      setData({
        ...data,
        requestState: RequestState.Error,
        errors: handlerError?.body?.errors || {},
      })
    }
  }

  const getError = (key: string) => data.errors[key]?.join()
  const isInvalid = () => Object.keys(data.errors).length > 0

  return { ...data, create, getError, isInvalid }
}
