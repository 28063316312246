import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MediaDevice, Displayable } from 'src/common/constants'
import { isSecondSourceModalVisible } from 'src/common/redux/features/ui/uiSelectors'
import { addSecondSource, uiActions } from 'src/common/redux/features'
import { tr, Label } from 'src/common/i18n'
import { useCameraPreview, useMicrophonePreview } from 'src/common/hooks'
import { TestId } from 'src/constants'
import { DevicesPreview } from 'src/components/Devices/DevicesPreview'
import { Modal } from './Modal'
import { useInputChange } from 'src/effects/useInputChange'

type SecondSourceModalProps = {
  callId: string
}

export const SecondSourceModal: FC<SecondSourceModalProps> = ({ callId }) => {
  const dispatch = useDispatch()
  const showModal = useSelector(isSecondSourceModalVisible)
  // Get default values from redux so that previously selected
  // devices are automatically set as second source if no changes
  // are make in the device pickers.
  const [selectedCameraId] = useCameraPreview()
  const [selectedMicId] = useMicrophonePreview()
  const [values, setValues] = useInputChange({
    camera: selectedCameraId ?? MediaDevice.None,
    microphone: selectedMicId ?? MediaDevice.None,
  })

  const confirmHandler = () => {
    const params = {
      callId,
      cameraId: values.camera,
      microphoneId: values.microphone,
    }
    dispatch(addSecondSource(params))
    cancelHandler()
  }

  const cancelHandler = () => {
    dispatch(uiActions.hide(Displayable.SecondSourceModal))
  }

  return (
    <Modal
      closeHandler={cancelHandler}
      cancelHandler={cancelHandler}
      confirmHandler={confirmHandler}
      confirmLabel={tr(Label.USE_THESE_DEVICES)}
      containerClassName='wide'
      show={showModal}
      testId={TestId.DevicesSecondSourceModal}
      title={tr(Label.SELECT_DEVICES)}
    >
      <DevicesPreview hideSpeakers updateFn={setValues} />
    </Modal>
  )
}
