import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'
import { MediaDevice } from '../../../constants'
import { DeviceState } from '../../interfaces'

type TDevice = {
  deviceId: string
  label?: string
}

// Tracking a separate preview state for devices
// allows us to only update devices in redux on confirm.

export const initialDeviceState: DeviceState = Object.freeze({
  cameraId: MediaDevice.Default,
  cameraLabel: 'OS Default',
  microphoneId: MediaDevice.Default,
  microphoneLabel: 'OS Default',
  previewCameraId: '',
  previewCameraLabel: '',
  previewMicrophoneId: '',
  previewMicrophoneLabel: '',
  previewSpeakerId: '',
  previewSpeakerLabel: '',
  speakerId: MediaDevice.Default,
  speakerLabel: 'OS Default',
})

const deviceSlice = createSlice({
  name: 'devices',
  initialState: initialDeviceState,
  reducers: {
    cameraChanged: (state, { payload }: PayloadAction<TDevice>) => {
      state.cameraId = payload.deviceId
      state.cameraLabel = payload.label ?? ''
    },
    microphoneChanged: (state, { payload }: PayloadAction<TDevice>) => {
      state.microphoneId = payload.deviceId
      state.microphoneLabel = payload.label ?? ''
    },
    speakerChanged: (state, { payload }: PayloadAction<TDevice>) => {
      state.speakerId = payload.deviceId
      state.speakerLabel = payload.label ?? ''
    },
    previewCameraChanged: (state, { payload }: PayloadAction<TDevice>) => {
      state.previewCameraId = payload.deviceId
      state.previewCameraLabel = payload.label ?? ''
    },
    previewMicrophoneChanged: (state, { payload }: PayloadAction<TDevice>) => {
      state.previewMicrophoneId = payload.deviceId
      state.previewMicrophoneLabel = payload.label ?? ''
    },
    previewSpeakerChanged: (state, { payload }: PayloadAction<TDevice>) => {
      state.previewSpeakerId = payload.deviceId
      state.previewSpeakerLabel = payload.label ?? ''
    },
    clearPreviewDevices: (state) => {
      state.previewCameraId = ''
      state.previewCameraLabel = ''
      state.previewMicrophoneId = ''
      state.previewMicrophoneLabel = ''
      state.previewSpeakerId = ''
      state.previewSpeakerLabel = ''
    },
    promoteDevices: (state) => {
      if (state.previewCameraId) {
        state.cameraId = state.previewCameraId
      }
      if (state.previewCameraLabel) {
        state.cameraLabel = state.previewCameraLabel
      }
      if (state.previewMicrophoneId) {
        state.microphoneId = state.previewMicrophoneId
      }
      if (state.previewMicrophoneLabel) {
        state.microphoneLabel = state.previewMicrophoneLabel
      }
      if (state.previewSpeakerId) {
        state.speakerId = state.previewSpeakerId
      }
      if (state.previewSpeakerLabel) {
        state.speakerLabel = state.previewSpeakerLabel
      }
    },
    disableVideo: (state) => {
      state.cameraId = MediaDevice.None
      state.cameraLabel = ''
    },
    enableVideo: (state) => {
      state.cameraId = MediaDevice.Default
      state.cameraLabel = 'OS Default'
    },
    disableMicrophone: (state) => {
      state.microphoneId = MediaDevice.None
      state.microphoneLabel = ''
    },
    enableAudio: (state) => {
      state.microphoneId = MediaDevice.Default
      state.microphoneLabel = 'OS Default'

      state.speakerId = MediaDevice.Default
      state.speakerLabel = 'OS Default'
    },
  },
})

export const validateDeviceAction = createAction(
  'devices/validate',
  (deviceId: string, label: string, kind: MediaDeviceKind) => {
    return {
      payload: { deviceId, label, kind },
    }
  }
)

// prettier-ignore
export const {
  actions: deviceActions,
  reducer: deviceReducer
} = deviceSlice
