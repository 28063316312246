import React from 'react'

const InviteOption = ({ label, description, children }: any) => {
  return (
    <section className='flex flex-col pt-5'>
      <h3 className='font-medium text-sm'>{label}</h3>
      <p className='text-xs text-contrast-m'>{description}</p>
      <div className='pt-3'>{children}</div>
    </section>
  )
}

export default InviteOption
