import React, { FC } from 'react'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import { PINInputRoom } from 'src/components/Inputs/PINInputRoom'
import { RoomComponentProps } from './RoomDetail'
import { RoomCheckbox } from './RoomCheckbox'

export const RoomSecurity: FC<RoomComponentProps> = ({
  room,
  update,
  getErrorByName,
}) => {
  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.ROOM_SECURITY)}
      </div>
      <PINInputRoom
        defaultValue={room?.pin}
        label={Label.PIN}
        name='pin'
        testId={TestId.PINInputParticipant}
        update={update}
      />
      <PINInputRoom
        defaultValue={room?.moderator_pin}
        label={Label.MODERATOR_PIN}
        name='moderator_pin'
        testId={TestId.PINInputModerator}
        update={update}
      />
      <RoomCheckbox
        update={update}
        getErrorByName={getErrorByName}
        name='require_moderator'
        label={Label.REQUIRE_MODERATOR}
        defaultChecked={room?.require_moderator}
      />
    </>
  )
}
