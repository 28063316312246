import React, { FC, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useCantinaBackendAPI, useCantinaId } from 'src/common/hooks'
import { SpinnerIcon, EnvelopeIcon } from 'src/common/icons'
import { CURRENT_HOSTNAME, RoutePath } from 'src/common/constants'
import { Endpoint } from 'src/common/rest'
import { tr, Label } from 'src/common/i18n'
import { useInputChange } from 'src/effects/useInputChange'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { CenteredBox } from 'src/layout'
import { TestId } from 'src/constants'
import { PrimaryButton, SecondaryButton } from 'src/components/Button/Button'
import SimpleHeader from 'src/components/Header/SimpleHeader'

const pwdResetOptions = {
  endpoint: Endpoint.PasswordResets,
  method: 'POST',
}

export const PasswordForgot: FC = () => {
  const { invokeAPI: resetPasswordHandler } =
    useCantinaBackendAPI(pwdResetOptions)
  const history = useHistory()
  const location = useLocation()
  // @ts-expect-error
  const { savedEmail } = location?.state || {}
  const [error, setError] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useInputChange({ email: savedEmail })
  const cantinaId = useCantinaId()

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { email } = values
    if (!email) {
      setError(tr(Label.PLEASE_ENTER_A_VALID_EMAIL))
      return setLoading(false)
    }
    try {
      setLoading(true)
      setError('')
      const { status } = await resetPasswordHandler({
        body: JSON.stringify({
          cantinaId,
          email,
          domain: CURRENT_HOSTNAME,
          callbackUrl: `https://${CURRENT_HOSTNAME}${RoutePath.UserPasswordReset}`,
        }),
      })
      if (status === 200 || status === 204) {
        setEmailSent(true)
      } else {
        setError(tr(Label.UNKNOWN_EMAIL))
      }
    } catch (error) {
      setError(tr(Label.UNKNOWN_EMAIL))
    } finally {
      setLoading(false)
    }
    return false
  }

  if (emailSent) {
    return <EmailSent email={values.email} />
  }

  return (
    <>
      <SimpleHeader />
      <CenteredBox>
        <form
          onSubmit={submitHandler}
          autoComplete='off'
          data-test={TestId.ResetPasswordForm}
        >
          <div className='text-center text-3xl font-neue'>
            {tr(Label.RESET_PASSWORD)}
          </div>
          <div className='text-sm text-contrast-h pb-4 text-center'>
            {tr(Label.PASSWORD_RESET_INSTRUX)}
          </div>
          <InputGroup
            autoFocus
            defaultValue={values.email}
            error={error}
            label='Email'
            name='email'
            onChange={setValues}
            placeholder='your@email.com'
            required
            testId={TestId.ResetPasswordInputEmail}
            type='email'
          />

          <div className='flex items-center justify-evenly'>
            <SecondaryButton
              className='w-full mr-2'
              onClick={() => history.push(RoutePath.UserLogin)}
              testId={TestId.ResetPasswordButtonCancel}
            >
              {tr(Label.CANCEL)}
            </SecondaryButton>
            <PrimaryButton
              type='submit'
              className='w-full ml-2'
              testId={TestId.ResetPasswordButtonReset}
            >
              {loading ? <SpinnerIcon /> : tr(Label.SEND_RESET_LINK)}
            </PrimaryButton>
          </div>
        </form>
      </CenteredBox>
    </>
  )
}

const EmailSent: FC<{ email: string }> = ({ email }) => {
  return (
    <>
      <SimpleHeader />
      <CenteredBox>
        <div className='text-center my-3' data-test={TestId.MessageEmailSent}>
          <div className='flex items-center justify-center'>
            <EnvelopeIcon size='3x' className='text-sw-success' />
          </div>
          <div className='text-3xl font-neue'>{tr(Label.CHECK_YOUR_EMAIL)}</div>
          <div className='text-base text-contrast-m'>{email}</div>
          <div className='text-sm text-contrast-h p-3'>
            {tr(Label.PASSWORD_RESET_LINK_SENT)}
          </div>
        </div>
      </CenteredBox>
    </>
  )
}
