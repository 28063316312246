import React, { FC } from 'react'
import 'webrtc-adapter'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import { Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import { createBrowserHistory } from 'history'
import {
  withLoading,
  withWebSocketEventListeners,
  withCantinaSettings,
} from 'src/common/hoc'
import { Loader } from 'src/common/constants'
import { getAppName } from 'src/common/redux/features/settings/settingsSelectors'

import { instanceName } from './services/Helpers'
import { Layout } from 'src/layout'
import { LoadingBox } from 'src/layout/LoadingBox'
import CantinaRoutes from 'src/routes'
import { withWebRTCSupport } from 'src/hoc/withWebRTCSupport'
import { InviteModal } from './modals/InviteModal'
import { PINModal } from './modals/PINModal'
import { ProfileModal } from './modals/ProfileModal'
import { AboutModal } from './modals/AboutModal'
// import { InboundCallModal } from './modals/InboundCallModal'
import { ConnectionClosed, ConnectionError } from './components/WebSocketErrors'
import { AutoDialExtensionGuard } from './components/AutoDialExtensionGuard'
import { CantinaSettingsError } from './components/CantinaSettingsError'
import { Polyfill } from './components/Polyfill/Polyfill'

/* App CSS */
import './theme/app.css'
import 'react-toastify/dist/ReactToastify.css'

const history = createBrowserHistory()

const AppFunction: FC = () => {
  return (
    <Router history={history}>
      <Layout>
        <CantinaHelmet />
        <CantinaRoutes />
        <InviteModal />
        <PINModal />
        <ProfileModal />
        <AboutModal />
        {/* <InboundCallModal /> Not currently supported */}
        <ToastContainer
          autoClose={4000}
          pauseOnFocusLoss={false}
          hideProgressBar={true}
        />
        <AutoDialExtensionGuard />
        <Polyfill />
      </Layout>
    </Router>
  )
}

const CantinaHelmet = () => {
  let appName = useSelector(getAppName)
  if (appName.toLowerCase().startsWith('signalwire')) {
    appName = `${appName} for ${instanceName()}`
  }
  return (
    <Helmet>
      <title>{appName}</title>
    </Helmet>
  )
}

export const Loading = () => (
  <div className='app-root sw-theme-default'>
    <div className='app-main-content'>
      <LoadingBox />
    </div>
  </div>
)

export const App = compose<FC>(
  withLoading(Loader.Bootstrap, Loading),
  withWebRTCSupport,
  withWebSocketEventListeners(ConnectionClosed, ConnectionError),
  withCantinaSettings(CantinaSettingsError)
)(AppFunction)
