import React, { useState, FormEvent, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { getAuthToken } from 'src/common/redux/features/auth/authSelectors'
import { getInternalAPIEndpoint } from 'src/common/redux/features/settings/settingsSelectors'
import { RailsEndpoint } from 'src/common/rest'
import { CantinaState } from 'src/common/redux/interfaces'
import { tr, Label } from 'src/common/i18n'
import {
  ClipeezeUploadDetails,
  fetchClipeezeUploadDetails,
  createClipeeze,
  uploadClipeeze,
} from 'src/common/rest/clipeeze'
import { truncate } from 'src/common/services/helpers'
import { SpinnerIcon } from 'src/common/icons'
import { TestId } from 'src/constants'
import { toastSuccess, toastError } from 'src/services/ToastService'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { Modal } from 'src/modals/Modal'

const DESCRIPTION_TRUNCATE_LEN = 20

const validFile = (fileName: string) => {
  // @ts-expect-error
  return fileName.split('.').pop().toUpperCase() === 'MP4'
}

export const ClipeezeUploadModal = ({ closeHandler, successHandler }: any) => {
  const [isUploading, setIsUploading] = useState(false)
  const [title, setTitle] = useState('')
  const [file, setFile] = useState({
    data: null,
    error: '',
  })
  const authToken = useSelector(getAuthToken)
  const baseUrl = useSelector((state: CantinaState) =>
    getInternalAPIEndpoint(state, RailsEndpoint.Clipeeze)
  )

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    // @ts-expect-error
    if (event?.target?.files[0] && !validFile(event.target.files[0].name)) {
      setFile({ data: null, error: tr(Label.CLIPEEZE_FILE_TYPE_ERROR) })
    } else {
      // @ts-expect-error
      setFile({ data: event.target.files[0], error: '' })
    }
  }

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    let clipId = ''
    // @ts-expect-error
    const { name: fileName, size: fileSize, type: fileType } = file.data
    const fileDescription = title.trim()

    event.preventDefault()

    if (file.data && fileDescription && validFile(fileName)) {
      setIsUploading(true)

      // 1) Get the S3 upload details from the ClipEEze API
      // 2) Upload the file to S3 with the details
      // 3) Fire off the ClipEEze API creation process

      await fetchClipeezeUploadDetails({
        baseUrl,
        token: authToken as string,
        contentLength: fileSize,
        contentType: fileType,
        filename: fileName,
      })
        .then((result: ClipeezeUploadDetails) => {
          if (!(result?.id && result?.url)) {
            throw new Error('Upload Details request failed to return details')
          }

          clipId = result.id

          // Add the S3 upload info to the form data we're POSTing
          const formData: FormData = new FormData()
          for (const [key, value] of Object.entries(result.fields)) {
            formData.append(key, value)
          }

          // The file needs to be appended last
          // @ts-expect-error
          formData.append('file', file.data)
          return uploadClipeeze({
            url: result.url,
            formData,
          })
        })
        .then((response) => {
          // The S3 request does not use cantinaFetch(), so we have to check the response
          if (
            !response.ok ||
            !(response.status === 200 || response.status === 204)
          ) {
            throw new Error(
              `HTTP Request to S3 failed with status ${response.status}. ${response.statusText}`
            )
          }
        })
        .then(() => {
          return createClipeeze({
            baseUrl,
            token: authToken as string,
            clipId,
            contentLength: fileSize,
            contentType: fileType,
            description: fileDescription,
            filename: fileName,
          })
        })
        .then(() => {
          toastSuccess(
            tr(Label.CLIPEEZE_ADDED, {
              title: truncate(fileDescription, DESCRIPTION_TRUNCATE_LEN, true),
            })
          )
          successHandler()
        })
        .catch((error) => {
          console.error(error)
          toastError(tr(Label.CLIPEEZE_UPLOAD_ERROR), {
            autoClose: false,
          })
        })
      setIsUploading(false)
    }
    closeHandler()
  }

  return (
    <Modal
      cancelHandler={closeHandler}
      cancelTestId={TestId.ClipeezeButtonCancel}
      closeHandler={closeHandler}
      confirmHandler={submitHandler}
      confirmLabel={tr(Label.UPLOAD)}
      confirmTestId={TestId.ClipeezeButtonUpload}
      disabled={!(title?.trim() && file.data)}
      isForm={!isUploading}
      show
      showCancelButton={!isUploading}
      showConfirmButton={!isUploading}
      testId={TestId.ClipeezeUploadModal}
      title={tr(Label.UPLOAD_A_NEW_CLIPEEZE)}
    >
      {isUploading ? (
        <div className='text-center py-8'>
          <SpinnerIcon size='2x' className='block mx-auto' />
          <div className='pt-4'>{tr(Label.UPLOAD_IN_PROGRESS_MSG)}</div>
        </div>
      ) : (
        <>
          <InputGroup
            label='Title'
            name='text'
            onChange={(event) => setTitle(event.target.value)}
            required
            value={title}
            autoFocus
          ></InputGroup>
          <InputGroup
            label='MP4 Video File'
            name='file'
            onChange={onFileChange}
            type='file'
            error={file.error}
            required
          />
        </>
      )}
    </Modal>
  )
}
