import React, { FC, useEffect, useState, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import {
  useDeleteUser,
  useFetchUsers,
  useDebounce,
  useSendInvitationEmail,
} from 'src/common/hooks'
import { UserPlusIcon, TimesCircleIcon, EditIcon } from 'src/common/icons'
import { UserRole, AuthProvider } from 'src/common/constants'
import { tr, Label } from 'src/common/i18n'
import {
  withUiSettingsUsersUpdateScope,
  withUiSettingsUsersDeleteScope,
} from 'src/common/hoc'
import { User } from 'src/common/redux/interfaces'
import { hasRoleAdministrator } from 'src/common/redux/features/auth/authSelectors'
import { SEARCH_DEBOUNCE_DELAY } from 'src/constants'
import {
  ButtonWithTooltip,
  ButtonWithConfirmation,
} from 'src/components/Button/Button'
import { RequestStateSwitcher } from './RequestStateSwitcher'
import { SearchInput } from 'src/components/Inputs/SearchInput'
import { SelectGroup } from 'src/components/SelectGroup/SelectGroup'
import { UserData } from './UserData'
import { RolesDropdown } from './RolesDropdown'
import { toastSuccess, toastError } from 'src/services/ToastService'
import { AddUserButton } from './AddUserButton'
import { UpdateUserModal } from 'src/modals/SystemSettings/UpdateUserModal'

const SHOW_ALL = 'all'
const showOptions = [
  { id: SHOW_ALL, label: 'All' },
  { id: UserRole.Member, label: 'Member' },
  { id: UserRole.Administrator, label: 'Administrator' },
]

export const UserList: FC = () => {
  const { requestState, refresh, error, getUsers, updateUserRole } =
    useFetchUsers({ refreshOnMount: false })
  const isAdministrator = useSelector(hasRoleAdministrator)
  const [filter, setFilter] = useState('')
  const [filterByGroup, setFilterByGroup] = useState(SHOW_ALL)
  const delayedFilter = useDebounce(
    filter,
    SEARCH_DEBOUNCE_DELAY
  )?.toLowerCase()
  const users = getUsers({
    filterByText: delayedFilter,
    filterByUserRole: filterByGroup,
  })
  const memberCount = users.filter(
    (user) => user.role === UserRole.Member
  ).length
  const administratorCount = users.filter(
    (user) => user.role === UserRole.Administrator
  ).length

  return (
    <>
      <div className='administration-section-title'>
        <div className='font-neue font-bold text-contrast-h text-2xl'>
          {tr(Label.USERS)}
        </div>
        <AddUserButton refresh={refresh} />
      </div>
      <div className='block-primary administration-section-content'>
        <RequestStateSwitcher
          requestState={requestState}
          refresh={refresh}
          error={error}
        >
          <div className='flex items-center mb-4'>
            <SearchInput
              className='text-sm mr-6'
              clearInputFunction={() => setFilter('')}
              currentValue={filter}
              inputChanged={(event: ChangeEvent<HTMLInputElement>) => {
                setFilter(event.target.value)
              }}
              name='groupMembersFilter'
              placeholder='Search'
            />
            <SelectGroup
              canHaveError={false}
              className='flex-none mr-4'
              horizontalLabel
              label='Show'
              name='auto-shuffle'
              onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                setFilterByGroup(event.target.value)
              }
              options={showOptions}
              selectedId={filterByGroup}
            />
            <p className='flex-1 text-sm text-right'>{`${memberCount}\u00A0${tr(
              Label.MEMBER,
              { count: memberCount }
            )}\u00A0- ${administratorCount}\u00A0${tr(Label.ADMINISTRATOR, {
              count: administratorCount,
            })}`}</p>
          </div>
          {!users?.length && (
            <p className='text-base py-2'>{tr(Label.USERS_NO_RESULTS)}</p>
          )}
          {users.map((user) => {
            return (
              <UserItem key={user.id} user={user}>
                {isAdministrator ? (
                  <UserActions
                    user={user}
                    updateUserRole={updateUserRole}
                    refresh={refresh}
                  />
                ) : (
                  <p className='w-32 text-center capitalize'>{user.role}</p>
                )}
              </UserItem>
            )
          })}
        </RequestStateSwitcher>
      </div>
    </>
  )
}

type UserItemProps = {
  user: User
}

const UserItem: FC<UserItemProps> = ({ user, children }) => {
  return (
    <div
      tabIndex={0}
      className='administration-section-item-title group hover:shadow-md'
    >
      <UserData
        userId={user.id}
        badges={
          <div className='badge inline-block ml-3'>{user.authProvider}</div>
        }
      />
      <div className='flex items-center'>{children}</div>
    </div>
  )
}

type UserTooltipButtonProps = {
  userId: string
  refresh?: () => Promise<void>
  clickHandler?: () => void
}

const DeleteUserButton: FC<UserTooltipButtonProps> =
  withUiSettingsUsersDeleteScope(({ userId, refresh }) => {
    const { deleteUser, error } = useDeleteUser(userId)
    const removeHandler = async () => {
      await deleteUser()
      if (!error) {
        toastSuccess(tr(Label.SUCCESSFUL_DELETE, { item: tr(Label.USER) }))
      }
      await refresh?.()
    }

    useEffect(() => {
      if (error) {
        toastError(error)
      }
    }, [error])

    return (
      <ButtonWithConfirmation
        ariaLabel={tr(Label.REMOVE)}
        className='text-sw-red'
        onClick={removeHandler}
        singleUse
      >
        <TimesCircleIcon />
      </ButtonWithConfirmation>
    )
  })

// This button uses the UiSettingsUsersUpdate scope
const SendInvitationEmailButton: FC<{
  userId: string
}> = withUiSettingsUsersUpdateScope(({ userId }) => {
  const { sendInvitation } = useSendInvitationEmail(userId)
  const clickHandler = async () => {
    await sendInvitation()
  }

  return (
    <ButtonWithTooltip
      onClick={clickHandler}
      ariaLabel={tr(Label.SEND_INVITATION_EMAIL)}
    >
      <UserPlusIcon />
    </ButtonWithTooltip>
  )
})

const EditUserButton: FC<UserTooltipButtonProps> =
  withUiSettingsUsersUpdateScope(({ clickHandler }) => {
    return (
      <ButtonWithTooltip onClick={clickHandler} ariaLabel={tr(Label.EDIT)}>
        <EditIcon />
      </ButtonWithTooltip>
    )
  })

type UserActionsProps = {
  user: User
  updateUserRole: (params: { userId: string; role: UserRole }) => Promise<void>
  refresh: () => Promise<void>
}
const UserActions: FC<UserActionsProps> = ({
  user,
  updateUserRole,
  refresh,
}) => {
  // Control the Edit Modal visibility
  const [showEditUserModal, setshowEditUserModal] = useState(false)
  const toggleModal = async () => {
    setshowEditUserModal(!showEditUserModal)
    if (showEditUserModal) {
      await refresh()
    }
  }

  const showSendInvitationEmail =
    user.authProvider === AuthProvider.Local && !user.lastLoggedInAt

  return (
    <>
      <div className='item-actions justify-center gap-3 mr-4'>
        {showSendInvitationEmail && (
          <SendInvitationEmailButton userId={user.id} />
        )}
        <EditUserButton userId={user.id} clickHandler={toggleModal} />
        <DeleteUserButton userId={user.id} refresh={refresh} />
      </div>
      <RolesDropdown
        backendValue={user.role}
        changeHandler={(event: ChangeEvent<HTMLInputElement>) => {
          updateUserRole({
            userId: user.id,
            role: event.target.value as UserRole,
          })
        }}
        roleType='user_role'
      />
      {showEditUserModal && (
        <UpdateUserModal
          closeHandler={toggleModal}
          show={showEditUserModal}
          user={user}
        />
      )}
    </>
  )
}
