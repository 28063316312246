import { useState } from 'react'
import { useSelector } from 'react-redux'
import { getDefaultRoomTemplateId } from '../redux/features/settings/settingsSelectors'
import {
  RailsEndpoint,
  RequestState,
  RestMethodParams,
  Room,
  FetchError,
} from '../rest'
import { useInternalAPI } from './useInternalAPI'

interface CreateRoomMethodParams extends RestMethodParams {
  name: string
  room_description: string
  cantina_room_template_id: string
}

type CreateParams = Omit<
  CreateRoomMethodParams,
  'baseUrl' | 'token' | 'cantina_room_template_id'
>
type UseCreateRoomData = {
  room: Room | null
  requestState: RequestState
  errors: {
    [key: string]: string[]
  }
}

const createOptions = {
  endpoint: RailsEndpoint.Rooms,
  method: 'POST',
}

export const useCreateRoom = () => {
  const { invokeAPI: createHandler } = useInternalAPI(createOptions)
  const defaultRoomTemplateId = useSelector(getDefaultRoomTemplateId)
  const [data, setData] = useState<UseCreateRoomData>({
    room: null,
    requestState: RequestState.Success,
    errors: {},
  })

  const create = async (params: CreateParams) => {
    try {
      if (data.requestState !== RequestState.Loading) {
        setData({
          ...data,
          requestState: RequestState.Loading,
          errors: {},
        })
      }
      const room = await createHandler({
        body: JSON.stringify({
          cantina_room_template_id: defaultRoomTemplateId as string,
          ...params,
        }),
      })
      setData({
        room,
        requestState: RequestState.Success,
        errors: {},
      })
    } catch (error) {
      const handlerError = <FetchError>error
      setData({
        ...data,
        requestState: RequestState.Error,
        errors: handlerError?.body?.errors || {},
      })
    }
  }

  const getError = (key: string) => data.errors[key]?.join()
  const isInvalid = () => Object.keys(data.errors).length > 0

  return { ...data, create, getError, isInvalid }
}
