export enum Endpoint {
  Bootstrap = '/api/bootstrap',
  UsersLookup = '/api/users/lookup',
  SpecialVisibility = '/api/special-visibility',
  Login = '/api/login',
  GuestLogin = '/api/login/guest',
  UserLogin = '/api/login/user',
  Refresh = '/api/refresh',
  Logout = '/api/logout',
  OutboundCall = '/api/cantina/:cantinaId/room/:roomId/outbound-call',
  PasswordResets = '/api/password-resets',
  PasswordResetsConfirm = '/api/password-resets/confirm',
  UpdatePassword = '/api/update-password',
  VerifyToken = '/api/verify-token',
  Version = '/api/version',
  LegacyVerto = '/api/index.cgi?/verto',
  LegacyGateway = '/api/index.cgi?/signalwire',
  SmsInvitation = '/api/cantina/:cantinaId/room/:roomId/sms-invitation',
  RoomDomain = '/api/cantina/:cantinaId/room/:roomId/domain',
  RoomJoin = '/api/cantina/:cantinaId/room',
  DefaultLayouts = '/api/cantina/:cantinaId/default-layouts',
}

export enum RailsEndpoint {
  Rooms = '/api/cantina/:cantinaId/internal/rooms',
  RoomsAvailable = '/api/cantina/:cantinaId/internal/rooms/available',
  RoomTemplates = '/api/cantina/:cantinaId/internal/room_templates',
  RoomTemplatesAvailable = '/api/cantina/:cantinaId/internal/room_templates/available',
  Users = '/api/cantina/:cantinaId/internal/users',
  Groups = '/api/cantina/:cantinaId/internal/groups',
  GroupMemberships = '/api/cantina/:cantinaId/internal/groups/:groupId/memberships',
  Settings = '/api/cantina/:cantinaId/internal/settings',
  AccessGrants = '/api/cantina/:cantinaId/internal/:resourceType/:resourceId/access_grants',
  Profile = '/api/cantina/:cantinaId/internal/profile',
  Recordings = '/api/cantina/:cantinaId/internal/recordings',
  RecordingsRooms = '/api/cantina/:cantinaId/internal/recordings/rooms',
  Clipeeze = '/api/cantina/:cantinaId/internal/clipeeze',
}

export enum RequestState {
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}
