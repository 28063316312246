import { createSelector } from 'reselect'
import { gravatarUrl } from '../../../services/helpers'
import { CantinaState, Participant } from '../../interfaces'
import { getMyParticipantId } from '../calls/callSelectors'
import { getRoomBannerDisplayOption } from '../rooms/roomsSelectors'
import { getRoomBannerDisplayOption as getRoomListBannerDisplayOption } from '../roomList/roomListSelectors'

export const getParticipants = ({ participants }: CantinaState) => {
  return participants.byId || {}
}

/**
 * HOF to not duplicate the logic and
 * have type checking on Room keys
 */
const makeParticipantKeySelector = <T extends keyof Participant>(key: T) => {
  return createSelector(
    getParticipant,
    (participant) => participant?.[key] ?? null
  )
}

/**
 * HOF as above but force the value to be a boolean
 */
const makeBooleanParticipantKeySelector = (key: keyof Participant) => {
  return createSelector(getParticipant, (participant) =>
    Boolean(participant?.[key])
  )
}

// export const getAllParticipantCount = createSelector(
//   getParticipants,
//   (participants) => Object.keys(participants).length || 0
// )

const participantId = (_: CantinaState, participantId: string) => participantId

export const getParticipant = createSelector(
  getParticipants,
  participantId,
  (participants, participantId) => participants[participantId] || null
)

export const participantExists = createSelector(getParticipant, (participant) =>
  Boolean(participant)
)
export const getName = makeParticipantKeySelector('name')
export const getParticipantRoomId = makeParticipantKeySelector('roomId')
export const getAvatar = createSelector(getParticipant, (participant) => {
  return (
    participant && gravatarUrl(participant.email || '', participant.name || '')
  )
})
export const getEmail = makeParticipantKeySelector('email')
export const getCompany = makeParticipantKeySelector('company')
export const hasEchoCancellation =
  makeBooleanParticipantKeySelector('echoCancellation')
export const hasNoiseSuppression =
  makeBooleanParticipantKeySelector('noiseSuppression')
export const hasAutoGainControl =
  makeBooleanParticipantKeySelector('autoGainControl')
export const getAudioConstraints = createSelector(
  hasEchoCancellation,
  hasNoiseSuppression,
  hasAutoGainControl,
  (echoCancellation, noiseSuppression, autoGainControl) => {
    return {
      echoCancellation,
      noiseSuppression,
      autoGainControl,
    }
  }
)
/**
 * These values will be the "id" and "type"
 * but for now we have to keep them separately
 * - Keeping "cantina" as prefix to identify
 */
export const getCantinaUserId = makeParticipantKeySelector('cantinaUserId')
export const getCantinaUserType = makeParticipantKeySelector('cantinaUserType')
export const hasSpecialVisibility =
  makeParticipantKeySelector('specialVisibility')

const getParticipantSubtitle = createSelector(
  (state: CantinaState) => state,
  getEmail,
  getCompany,
  getParticipantRoomId,
  (state, email, company, roomId) => {
    /**
     * Depending on the global pref, the participant can have name+email or name+company.
     * Search in "rooms" slice first and fallback to "roomList"
     */
    const bannerDisplayOption =
      getRoomBannerDisplayOption(state, roomId as string) ||
      getRoomListBannerDisplayOption(state, roomId as string)
    switch (bannerDisplayOption) {
      case 'none':
        return ''
      case 'company':
        return company
      default:
        return email
    }
  }
)

export const getNameAndSubtitle = createSelector(
  getName,
  getParticipantSubtitle,
  (name, subtitle) => [name, subtitle]
)

export const isTalking = createSelector(getParticipant, (participant) => {
  return Boolean(
    participant &&
      participant.talking &&
      !participant.muted &&
      !participant.studioAudio &&
      participant.energy
  )
})
export const isMuted = makeBooleanParticipantKeySelector('muted')
export const isVMuted = makeBooleanParticipantKeySelector('vMuted')
export const isDeaf = makeBooleanParticipantKeySelector('deaf') // DND Mode
export const isMyself = makeBooleanParticipantKeySelector('myself')
export const hasHandRaised = makeBooleanParticipantKeySelector('handRaised')
export const getParticipantRoomZoneId = makeParticipantKeySelector('roomZoneId')
export const isModerator = makeBooleanParticipantKeySelector('moderator')
export const isTemporaryModerator =
  makeBooleanParticipantKeySelector('temporaryModerator')
export const getParticipantCallId = createSelector(
  getParticipant,
  (participant) => {
    if (participant && participant.callId) {
      return participant.callId
    }
    return null
  }
)
export const getParticipantMotionQuality =
  makeParticipantKeySelector('motionQuality')
export const hasLowBitrateMode =
  makeBooleanParticipantKeySelector('lowBitrateMode')
export const hasBanner = makeBooleanParticipantKeySelector('hasBanner')
export const hasDenoise = makeBooleanParticipantKeySelector('hasDenoise')
export const hasCustomBanner =
  makeBooleanParticipantKeySelector('hasCustomBanner')
export const getVolume = makeParticipantKeySelector('volume')
export const getGain = makeParticipantKeySelector('gain')
export const getEnergy = makeParticipantKeySelector('energy')
export const getConnectionQuality =
  makeParticipantKeySelector('connectionQuality')
export const canShare = makeBooleanParticipantKeySelector('canShare')
export const isScreenShareLeg =
  makeBooleanParticipantKeySelector('isScreenShareLeg')
export const isSecondSourceLeg =
  makeBooleanParticipantKeySelector('isSecondSourceLeg')
export const getReservationId = makeParticipantKeySelector('reservationId')
export const hasAutoOverlay = makeParticipantKeySelector('autoOverlay')
export const isVisibleOnMCU = createSelector(getParticipant, (participant) =>
  Boolean(participant && participant.mcuLayerId !== -1)
)
/**
 * When noLocalVideo flag is set, replace the local video feed with a black/blank box
 * and (if present) show the first 'mute' overlay image
 */
export const hasNoLocalVideo = makeBooleanParticipantKeySelector('noLocalVideo')
export const getParticipantIdsByRoomId = (
  { participants }: CantinaState,
  roomId: string
) => {
  return participants.idsByRoomId[roomId] || []
}

export const getParticipantCountByRoomId = createSelector(
  getParticipantIdsByRoomId,
  (ids) => {
    return (ids || []).length
  }
)

export const getRoomPerformerId = createSelector(
  getParticipants,
  getParticipantIdsByRoomId,
  (participants, ids) => {
    return ids.find((id) => participants[id] && participants[id].performer)
  }
)

/**
 * Forcing the type for this selector because redux-saga
 * is not able to infer the arguments when used (ie: roomsSaga.ts)
 */
export const getRoomParticipantIdsByRoomZoneId: (
  state: CantinaState,
  roomId: string,
  roomZoneId: string
) => string[] = createSelector(
  getParticipants,
  getParticipantIdsByRoomId,
  (_: CantinaState, _roomId: string, roomZoneId: string) => roomZoneId,
  (participants, ids, roomZoneId) => {
    return ids.filter((id) => participants?.[id]?.roomZoneId === roomZoneId)
  }
)

const speakerHighlightMode = (
  _: CantinaState,
  _pId: string,
  speakerHighlightMode: boolean
) => speakerHighlightMode

export const makeParticipantLayerSelector = () => {
  return createSelector(
    getParticipants,
    participantId,
    speakerHighlightMode,
    getNameAndSubtitle,
    getMyParticipantId,
    (
      participants,
      participantId,
      speakerHighlightMode,
      [name, subtitle],
      myParticipantId
    ) => {
      const participant = participants?.[participantId]
      if (!participant) {
        return null
      }

      const classNames = []
      let isInTheSameZone = false
      let isBlurred = false

      if (participant.deaf) {
        classNames.push('mcu-blurred-layer')
        isBlurred = true
      }

      // Check same zone
      if (myParticipantId && participants?.[myParticipantId]) {
        const myZoneId = participants[myParticipantId].roomZoneId
        isInTheSameZone = Boolean(
          myZoneId && myZoneId === participant.roomZoneId
        )
        // Anyone not in the same zone should be blurred
        if ((myZoneId || participant.roomZoneId) && !isInTheSameZone) {
          classNames.push('mcu-blurred-layer')
          isBlurred = true
        }
        // Only add the sidebar border for people not in the same zone
        if (participant.roomZoneId && !isInTheSameZone) {
          classNames.push('sidebar')
        }
      }

      if (participant.handRaised && !isBlurred) {
        classNames.push('hand-raised')
      }

      if (
        speakerHighlightMode &&
        participant.talking &&
        !participant.reservationId &&
        !isBlurred
      ) {
        classNames.push('talking')
      }

      return {
        classNames: classNames.join(' '),
        isBlurred,
        isDeaf: participant.deaf,
        isInTheSameZone,
        isScreenReservation: participant.reservationId === 'screen',
        name,
        subtitle,
        zoneId: participant.roomZoneId,
      }
    }
  )
}
