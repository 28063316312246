import { useState } from 'react'
import { useInternalAPI } from './useInternalAPI'
import { GrantOwnerType, GrantResourceType, RoomRole } from '../constants'
import { RailsEndpoint, RequestState } from '../rest'

type CreateParams = {
  grant_owner_type: GrantOwnerType
  grant_owner_id: string
  grant_type: RoomRole
}[]
type UseCreateGroupData = {
  requestState: RequestState | null
  error: string | null
}

const createOptions = {
  endpoint: RailsEndpoint.AccessGrants,
  method: 'POST',
}

export const useCreateAccessGrant = (
  resourceType: GrantResourceType,
  resourceId: string
) => {
  const { invokeAPI: createHandler } = useInternalAPI(createOptions)

  const [data, setData] = useState<UseCreateGroupData>({
    requestState: null,
    error: null,
  })

  const create = async (accessGrantList: CreateParams) => {
    try {
      if (data.requestState !== RequestState.Loading) {
        setData({
          requestState: RequestState.Loading,
          error: null,
        })
      }
      if (accessGrantList?.length) {
        const promises = accessGrantList.map((row) => {
          return createHandler({
            urlTransform: (url) => {
              return url
                .replace(':resourceType', resourceType)
                .replace(':resourceId', resourceId)
            },
            body: JSON.stringify(row),
          })
        })
        await Promise.all(promises)
      }

      setData({
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      console.error('Create RoomAccessGrants error', error)
      setData({
        requestState: RequestState.Error,
        error: 'Error saving permissions',
      })
    }
  }

  return { ...data, create }
}
