import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useScopeSelector } from 'src/common/hooks'
import { RoutePath, Scope } from 'src/common/constants'

export const ScopedRoute = ({
  children,
  scope,
  redirectTo = RoutePath.Home,
  ...rest
}: RouteProps & { scope: Scope; redirectTo: RoutePath }) => {
  const hasScope = useScopeSelector(scope)
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (hasScope) {
          return children
        }
        return (
          <Redirect to={{ pathname: redirectTo, state: { from: location } }} />
        )
      }}
    />
  )
}

export default ScopedRoute
