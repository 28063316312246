import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'
import {
  AuthState,
  GuestLoginPayload,
  UserLoginPayload,
} from '../../interfaces'
import { User } from '../../../rest/interfaces'
import { isIUser } from '../../guards'

type UpdateProfileData = Partial<AuthState>
export const initialAuthState: AuthState = Object.freeze({
  userId: null,
  type: null,
  role: null,
  token: null,
  scopeId: null,
  username: '',
  name: '',
  email: '',
  phone: '',
  company: '',
  errorMessage: null,
  temporary: false,
})

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    loginRequest: (
      state,
      { payload }: PayloadAction<UserLoginPayload | GuestLoginPayload>
    ) => {
      if (isIUser(payload)) {
        state.username = payload.username
      } else {
        state.name = payload.name
        state.email = payload.email!
        state.company = payload.company
      }
      state.errorMessage = null
    },
    loginSuccess: (state, { payload }: PayloadAction<Partial<AuthState>>) => {
      return {
        ...state,
        ...payload,
        errorMessage: null,
      }
    },
    loginFailure: (state, { payload }: PayloadAction<{ message: string }>) => {
      return {
        ...initialAuthState,
        errorMessage: payload.message,
      }
    },
    clearErrorMessage: (state) => {
      state.errorMessage = null
    },
    updateProfileSuccess: (
      state,
      { payload }: PayloadAction<UpdateProfileData>
    ) => {
      return {
        ...state,
        ...payload,
      }
    },
    updateScopeId: (state, { payload: scopeId }: PayloadAction<string>) => {
      state.scopeId = scopeId
    },
    logout: () => initialAuthState,
    temporary: (state) => {
      state.temporary = true
    },
  },
})

export const refreshJWTRequest = createAction('auth/refreshJWT')
export const setScopesRequest = createAction('auth/setScopes')
export const updateProfileRequest = createAction<UpdateProfileData>(
  'auth/updateProfileRequest'
)

// prettier-ignore
export const {
  actions: authActions,
  reducer: authReducer
} = authSlice
