import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RoutePath } from 'src/common/constants'
import { Endpoint } from 'src/common/rest'
import { useCantinaBackendAPI, useCantinaId } from 'src/common/hooks'
import { authActions, uiActions } from 'src/common/redux/features'
import { getSearchParams } from 'src/services/Helpers'

const verifyTokenOptions = {
  endpoint: Endpoint.VerifyToken,
  method: 'POST',
}

export const useAutoLogin = () => {
  const { invokeAPI: verifyHandler } = useCantinaBackendAPI(verifyTokenOptions)
  const dispatch = useDispatch()
  const history = useHistory()
  const cantinaId = useCantinaId()

  useEffect(() => {
    const { token, userId, room } = getSearchParams()
    if (!cantinaId || !token || !userId) {
      return history.push(RoutePath.Login)
    }
    verifyHandler({
      body: JSON.stringify({ cantinaId, userId, token }),
    })
      .then((response) => {
        if (room) {
          dispatch(uiActions.setAutoDialExtension(room))
        }
        dispatch(
          authActions.loginSuccess({
            ...response,
            token,
          })
        )
      })
      .catch((error) => {
        console.warn('AutoLogin error', error)
        history.push(RoutePath.Login)
      })
  }, [verifyHandler, dispatch, history, cantinaId])

  return null
}
