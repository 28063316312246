import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MegaphoneIcon } from 'src/common/icons'
import { FontAwesomeSizeProp } from 'src/common/icons/FontAwesomeIcon'
import { uiActions } from 'src/common/redux/features'
import { Displayable } from 'src/common/constants'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import {
  withTextTooltip,
  TOOLTIP_OFFSET_Y_TOP_BAR,
} from 'src/hoc/withTextTooltip'

interface ButtonProps {
  className?: string
  iconSize?: FontAwesomeSizeProp
}

const Button: FC<ButtonProps> = ({ iconSize = 'lg' }) => {
  const dispatch = useDispatch()
  const widgetId = process.env?.REACT_APP_NOTICEABLE_PROJECT_ID
  const [count, setCount] = useState(0)

  // @ts-ignore
  const widgetIsAvailable = widgetId && window.noticeable

  useEffect(() => {
    if (widgetIsAvailable) {
      // @ts-ignore
      window.noticeable.render('widget', widgetId)

      // @ts-ignore
      window.noticeable.on(
        'widget:publication:unread_count:changed',
        widgetId,
        (e: any) => {
          setCount(e.detail.value)
        }
      )
    }
  }, [widgetId, widgetIsAvailable])

  if (!widgetIsAvailable) {
    return null
  }

  const clickHandler = () => {
    // @ts-ignore
    if (window.noticeable.is('widget:closed', widgetId)) {
      dispatch(uiActions.hide(Displayable.AppMenu))
    }
    // @ts-ignore
    window.noticeable.do('widget:toggle', widgetId)
  }

  const counter = count > 0 && (
    <span className='absolute top-0 right-0 bg-sw-white text-black rounded-full px-1 -mr-1 text-xxs'>
      {count}
    </span>
  )

  return (
    <>
      <button
        onClick={clickHandler}
        className='relative p-2'
        aria-label={tr(Label.NOTICES)}
        data-test={TestId.TopBarButtonNotices}
      >
        {counter}
        <MegaphoneIcon size={iconSize} />
      </button>
    </>
  )
}

const WrappedButton = withTextTooltip({ offsetY: TOOLTIP_OFFSET_Y_TOP_BAR })(
  Button
)

const NoticeableButton: FC = () => (
  <>
    <WrappedButton />
    <div id='noticeable-widget'></div>
  </>
)

export default NoticeableButton
