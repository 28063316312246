import React, { ComponentType, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { createLoadingSelector } from '../redux/features/loading/loadingSelectors'
import { Loader, SocketStatus } from '../constants'
import { getSocketStatus } from '../redux/features/ui/uiSelectors'

const LOADING_TIMEOUT = 10 * 1000 // 10 seconds

interface WithLoadingProps {
  text?: string
}

export const withLoading =
  (loader: Loader, LoadingComponent: ComponentType<WithLoadingProps>) =>
  <T extends object>(WrappedComponent: ComponentType<T>) => {
    const selector = createLoadingSelector(loader)

    return (props: T & WithLoadingProps) => {
      const isLoading = useSelector(selector)
      const socketStatus = useSelector(getSocketStatus)
      const [loading, setLoading] = useState(isLoading)
      const timeoutRef = useRef<any>()

      useEffect(() => {
        // If the call was restarted because of an error, don't show the loading
        // content during the next n seconds
        if (loader === Loader.Call) {
          if (
            socketStatus === SocketStatus.Error ||
            socketStatus === SocketStatus.Closed
          ) {
            timeoutRef.current = setTimeout(() => {
              setLoading(isLoading)
            }, LOADING_TIMEOUT)
          } else if (!timeoutRef.current) {
            setLoading(isLoading)
          }
        } else {
          setLoading(isLoading)
        }

        return () => {
          clearTimeout(timeoutRef.current)
          if (loader === Loader.Call) {
            setLoading(false)
          }
        }
      }, [isLoading, socketStatus])

      if (loading) {
        return <LoadingComponent text={props.text} />
      }
      return <WrappedComponent {...props} />
    }
  }
