import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDispatchRoomParticipantCommand } from 'src/common/hooks'
import { CantinaState } from 'src/common/redux/interfaces'
import {
  getEnergy,
  isDeaf,
} from 'src/common/redux/features/participants/participantSelectors'
import {
  RoomCommandAction,
  AUDIO_MAX_ENERGY,
  AUDIO_MIN_ENERGY,
} from 'src/common/constants'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import RangeSlider from 'src/components/Inputs/RangeSlider'

interface NoiseGateControlProps {
  className?: string
  horizontalLabel?: boolean
  LowerIcon?: any
  participantId: string
  UpperIcon?: any
}

export const NoiseGateControl: FC<NoiseGateControlProps> = ({
  participantId,
  ...rest
}) => {
  const dispatchRoomParticipantCommand =
    useDispatchRoomParticipantCommand(participantId)
  const energyVal =
    useSelector((state: CantinaState) => getEnergy(state, participantId) ?? 0) /
    100
  const dndEnabled = useSelector((state: CantinaState) =>
    isDeaf(state, participantId)
  )
  const onChange = useCallback(
    (value: number) => {
      dispatchRoomParticipantCommand({
        action: RoomCommandAction.MemberEnergy,
        participantId,
        value,
      })
    },
    [dispatchRoomParticipantCommand, participantId]
  )

  return (
    <RangeSlider
      currentValue={energyVal}
      disabled={dndEnabled}
      label={tr(Label.NOISE_GATE)}
      maxValue={AUDIO_MAX_ENERGY}
      minValue={AUDIO_MIN_ENERGY}
      onValueChange={onChange}
      testId={TestId.SoundRangeEnergy}
      {...rest}
    />
  )
}
