import React, { ComponentType } from 'react'
import screenfull from 'screenfull'

export const withFullScreenSupport = <T extends object>(
  WrappedComponent: ComponentType<T>
) => {
  return (props: T) => {
    if (screenfull.isEnabled) {
      return <WrappedComponent {...props} />
    }
    return null
  }
}
