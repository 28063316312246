import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { makeFeatureFlagSelector } from '../redux/features/settings/settingsSelectors'
import { FeatureFlag } from '../constants'
import { CantinaState } from '../redux/interfaces'

/*
Feature flag is set:
  showWithFlag = true
    no customSelector or customSelector returns true -> component
    customSelector returns false -> fallback

  showWithFlag = false
    no customSelector or customSelector returns true -> fallback
    customSelector returns false -> component

Feature flag is not set (customSelector has no effect):
  showWithFlag = true
    display fallback

  showWithFlag = false
    display component
*/

type CustomSelector = (state: CantinaState) => boolean

export const withFeatureFlag = <T extends object>(
  featureFlag: FeatureFlag,
  showWithFlag = true,
  WrappedComponent: ComponentType<T>,
  FallbackComponent: ComponentType<T> | null = null,
  customSelector?: CustomSelector
) => {
  const selector = makeFeatureFlagSelector([featureFlag], customSelector)

  return (props: T) => {
    const hasFlagAndCustomCheck = useSelector(selector)
    const check = showWithFlag ? hasFlagAndCustomCheck : !hasFlagAndCustomCheck
    if (check) {
      return <WrappedComponent {...(props as T)} />
    } else if (FallbackComponent) {
      return <FallbackComponent {...(props as T)} />
    }
    return null
  }
}

type makeFeatureFlagHOCParams = {
  customSelector?: CustomSelector
  FallbackComponent?: ComponentType<any> | null
  show?: boolean
}
const makeFeatureFlagHOC = (featureFlag: FeatureFlag) => {
  return ({
    customSelector,
    FallbackComponent = null,
    show = true,
  }: makeFeatureFlagHOCParams = {}) => {
    return <T extends object>(WrappedComponent: ComponentType<T>) => {
      return withFeatureFlag(
        featureFlag,
        show,
        WrappedComponent,
        FallbackComponent,
        customSelector
      )
    }
  }
}

export const withGrantViewAllRoomsFeatureFlag = makeFeatureFlagHOC(
  FeatureFlag.GrantViewAllRooms
)
export const withSidebarConversationsFeatureFlag = makeFeatureFlagHOC(
  FeatureFlag.SidebarConversations
)
export const withHideInviteButtonFeatureFlag = makeFeatureFlagHOC(
  FeatureFlag.HideInviteButton
)
export const withHideParticipantListFeatureFlag = makeFeatureFlagHOC(
  FeatureFlag.HideParticipantList
)
export const withHideRaiseHandFeatureFlag = makeFeatureFlagHOC(
  FeatureFlag.HideRaiseHand
)
export const withMCUEmptyLayersFeatureFlag = makeFeatureFlagHOC(
  FeatureFlag.MCUEmptyLayers
)
export const withAllowStereoAudioFeatureFlag = makeFeatureFlagHOC(
  FeatureFlag.AllowStereoAudio
)
export const withCustomHeaderLogoFeatureFlag = makeFeatureFlagHOC(
  FeatureFlag.CustomHeaderLogo
)
export const withCustomAppLabelFeatureFlag = makeFeatureFlagHOC(
  FeatureFlag.CustomAppLabel
)
export const withCustomNameLabelFeatureFlag = makeFeatureFlagHOC(
  FeatureFlag.CustomNameLabel
)
export const withCustomCompanyLabelFeatureFlag = makeFeatureFlagHOC(
  FeatureFlag.CustomCompanyLabel
)
