import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'
import {
  RoomListState,
  RoomListRoom,
  RoomListCommandPayload,
  Participant,
} from '../../interfaces'
import { CantinaManagerRoom } from '../../../rest'
import { flushAction } from '../../actions'
import { parseJoinButtons } from '../../../services/helpers'

export const initialRoomListState: RoomListState = Object.freeze({
  ids: [],
  byId: {},
  memberIdsByRoomId: {},
})

// Transform the data returned by Cantina Manager before storing
// it in the Redux state
const transformRoom = (room: CantinaManagerRoom): RoomListRoom => {
  return {
    description: room.roomDescription || '',
    name: room.name,
    emptyImages: room.customEmpty ?? [],
    id: room.id,
    joinButtons: parseJoinButtons(room.joinButton ?? ''),
    locked: room.locked,
    myRoles: room.myRoles ?? [],
    numMembers: room.memberCount,
    orderPriority: room.orderPriority,
    preview: room.lastSnapshot,
    previewImages: room.customPreview ?? [],
    recording: Boolean(room.recording),
    roomType: room.roomType,
    running: Boolean(room.memberCount),
    uuid: room.id,
    visibility: room.visibility,
    bannerDisplayOption: room.bannerDisplayOption,
  }
}

const roomListSlice = createSlice({
  name: 'roomList',
  initialState: initialRoomListState,
  reducers: {
    // Add bulk content from Cantina Manager
    bulkInsert: (
      state,
      { payload }: PayloadAction<{ rooms: CantinaManagerRoom[] }>
    ) => {
      state.ids = []
      state.byId = {}

      payload.rooms.forEach((room) => {
        const transformedRoom = transformRoom(room)
        if (transformedRoom.id) {
          state.byId[transformedRoom.id] = transformedRoom
          state.ids.push(transformedRoom.id)
        }
      })
    },
    // Update with content from Cantina Manager
    createOrUpdate: (
      state,
      { payload: room }: PayloadAction<CantinaManagerRoom>
    ) => {
      const transformedRoom = transformRoom(room)
      if (transformedRoom.id) {
        if (transformedRoom.id in state.byId) {
          state.byId[transformedRoom.id] = {
            ...state.byId[transformedRoom.id],
            ...transformedRoom,
          }
        } else {
          state.byId[transformedRoom.id] = transformedRoom
          state.ids = state.ids
            .filter((id) => id !== room.id)
            .concat(transformedRoom.id)
        }
      }
    },
    destroyById: (state, { payload: roomId }: PayloadAction<string>) => {
      delete state.byId[roomId]
      state.ids = state.ids.filter((id) => id !== roomId)
      delete state.memberIdsByRoomId[roomId]
    },
    bootstrapMembers: (
      state,
      { payload }: PayloadAction<{ roomId: string; members: Participant[] }>
    ) => {
      const { roomId, members } = payload
      state.memberIdsByRoomId[roomId] = members.map((row) => `${row.id}`)
    },
  },
  extraReducers: {
    [flushAction.type]: () => initialRoomListState,
  },
})

export const roomListCommand =
  createAction<RoomListCommandPayload>('roomList/command')

// prettier-ignore
export const {
  actions: roomListActions,
  reducer: roomListReducer
} = roomListSlice
