import React, { FC, ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { CantinaState } from 'src/common/redux/interfaces'
import {
  hasHandRaised,
  isMuted,
  isVMuted,
} from 'src/common/redux/features/participants/participantSelectors'
import { withTextTooltip } from 'src/hoc/withTextTooltip'
import {
  CameraOffIcon,
  CameraOnIcon,
  MicrophoneOffIcon,
  MicrophoneOnIcon,
  RaiseHandIcon,
} from 'src/common/icons'
import {
  FontAwesomeIconProps,
  FontAwesomeSizeProp,
} from 'src/common/icons/FontAwesomeIcon'
import { tr, Label } from 'src/common/i18n'

interface StatusIconProps {
  Icon?: ComponentType<Partial<FontAwesomeIconProps>>
  IconActive: ComponentType<Partial<FontAwesomeIconProps>>
  label: Label
  labelActive: Label
  participantId: string
  selector: typeof hasHandRaised | typeof isMuted | typeof isVMuted
  size?: FontAwesomeSizeProp
}

// Display inactive/active status icon
// To only show an icon when active, don't pass an Icon prop
const StatusIcon: FC<StatusIconProps> = ({
  Icon = null,
  IconActive,
  label,
  labelActive,
  participantId,
  selector,
  size = '1x',
}) => {
  const active = useSelector((state: CantinaState) =>
    selector(state, participantId)
  )

  return active ? (
    <span
      className={`sw-btn-icon-toggle ${Icon ? 'activated' : ''}`}
      aria-label={tr(labelActive)}
    >
      <IconActive size={size} fixedWidth />
    </span>
  ) : (
    <>
      {Icon && (
        <span className='sw-btn-icon-toggle' aria-label={tr(label)}>
          <Icon size={size} fixedWidth />
        </span>
      )}
    </>
  )
}

const WrappedStatusIcon: FC<StatusIconProps> = withTextTooltip()(StatusIcon)

type ParticipantListRowStatusProps = {
  participantId: string
}

export const ParticipantListRowStatus: FC<ParticipantListRowStatusProps> = ({
  participantId,
}) => {
  // If you change the toggle-button-grid gap, it needs to also be adjusted in the Safari polyfill
  return (
    <div className='participant-row-actions'>
      <WrappedStatusIcon
        IconActive={RaiseHandIcon}
        label={Label.HAND_RAISED}
        labelActive={Label.HAND_LOWERED}
        participantId={participantId}
        selector={hasHandRaised}
      />
      <WrappedStatusIcon
        Icon={CameraOnIcon}
        IconActive={CameraOffIcon}
        label={Label.VIDEO_ON}
        labelActive={Label.VIDEO_OFF}
        participantId={participantId}
        selector={isVMuted}
      />
      <WrappedStatusIcon
        Icon={MicrophoneOnIcon}
        IconActive={MicrophoneOffIcon}
        label={Label.MICROPHONE_ON}
        labelActive={Label.MICROPHONE_MUTED}
        participantId={participantId}
        selector={isMuted}
      />
    </div>
  )
}
