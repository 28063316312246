import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User as ApiUser } from '../../../rest'
import { UserState } from '../../interfaces'

export const initialUserState: UserState = Object.freeze({
  byId: {},
})

const usersSlice = createSlice({
  name: 'users',
  initialState: initialUserState,
  reducers: {
    fetchUsersSuccess: (
      state,
      { payload: userList }: PayloadAction<ApiUser[]>
    ) => {
      userList.forEach((row) => {
        state.byId[row.id] = {
          id: row.id,
          name: row.name,
          email: row.email,
          companyName: row.company_name,
          phoneNumber: row.phone_number,
          role: row.user_role,
          authProvider: row.auth_provider,
          lastLoggedInAt: row.last_logged_in_at,
        }
      })
    },
    clear: () => initialUserState,
  },
})

// prettier-ignore
export const {
  actions: usersActions,
  reducer: usersReducer
} = usersSlice
