import React, { FC, ComponentType, useState, useCallback } from 'react'
import { CaretUpIcon } from 'src/common/icons'
import { BasicTooltip } from './Tooltip'
import { TestId } from 'src/constants'

const modifiers = [
  {
    name: 'offset',
    options: {
      offset: [-60, 10],
    },
  },
]

/**
 * This HOC is just a decorator for the in-call buttons.
 * it receives the TooltipContent FC and - if the button is available -
 * it adds a BasicTooltip to open the TooltipContent.
 */
type withTooltipWrapperProps = {
  label: string
  'aria-label': string
}
export const withTooltipWrapper =
  (TooltipContent: FC<{ closeHandler: () => void }>, tooltipTestId?: TestId) =>
  <T extends object>(WrappedButton: ComponentType<T>) => {
    return (props: T & withTooltipWrapperProps) => {
      if (!WrappedButton) {
        return null
      }

      const [shown, setShown] = useState(false)
      const closeTooltip = useCallback(() => {
        setShown(false)
      }, [])

      return (
        <div className='flex items-start lg:items-center justify-center'>
          <WrappedButton {...props} />
          <BasicTooltip
            placement='top-start'
            trigger='click'
            modifiers={modifiers}
            tooltip={<TooltipContent closeHandler={closeTooltip} />}
            onVisibilityChange={setShown}
            tooltipShown={shown}
            tooltipClassName='in-call-tooltip'
            hideArrow={true}
          >
            <div
              className='mr-1 text-sw-white'
              aria-label={props['aria-label']}
              data-test={tooltipTestId}
            >
              <CaretUpIcon />
            </div>
          </BasicTooltip>
        </div>
      )
    }
  }
