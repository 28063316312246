import { createStore, applyMiddleware, AnyAction } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'
import { CantinaState } from './interfaces'

const sagaMiddleware = createSagaMiddleware()

interface ConfigureStoreOptions {
  persistConfig?: {
    persistReducer: any
    config: any
  }
  runSagaMiddleware?: boolean
}

export const configureStore = (
  preloadedState: Partial<CantinaState> = {},
  options: ConfigureStoreOptions = {}
) => {
  const { persistConfig, runSagaMiddleware = true } = options
  const middlewares = [sagaMiddleware]
  let reducers = rootReducer

  if (persistConfig) {
    reducers = persistConfig.persistReducer(persistConfig.config, reducers)
  }

  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_REDUX_LOGGER_ENABLED === 'true'
  ) {
    // eslint-disable-next-line
    const { createLogger } = require('redux-logger')
    const ignoredActions = [
      'auth/refreshJWT',
      'calls/updateLayout',
      'participants/update',
      'rooms/createOrUpdate',
      'roomZones/createFromConferenceInfo',
    ]
    const logger = createLogger({
      predicate: (getState: any, action: AnyAction) =>
        !ignoredActions.includes(action.type),
    })

    middlewares.push(logger)
  }

  const store = createStore(
    reducers,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares))
  )

  if (runSagaMiddleware) {
    sagaMiddleware.run(rootSaga)
  }

  return store
}
