import React, { FC, useState, useEffect, useCallback, ChangeEvent } from 'react'
import { tr, Label } from 'src/common/i18n'
import { UseFetchRoomConsumerProps } from 'src/common/hooks'
import { RoomComponentProps } from './RoomDetail'
import { RoomInputGroup } from './RoomInputGroup'
import { DefaultLayoutsDropdown } from 'src/components/LayoutDropdown'
import RoomCustomButtons from './RoomCustomButtons'

export const RoomTheme: FC<RoomComponentProps> = ({
  room,
  update,
  getErrorByName,
}) => {
  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.ROOM_CUSTOMIZE_THEME)}
      </div>
      <RoomInputGroup
        defaultValue={room?.autoplay_url}
        getErrorByName={getErrorByName}
        label={Label.AUTOPLAY_URL}
        name='autoplay_url'
        update={update}
      />
      <RoomDefaultLayout
        update={update}
        getErrorByName={getErrorByName}
        defaultLayout={room?.default_layout}
      />
      <RoomInputGroup
        defaultValue={room?.default_flags}
        getErrorByName={getErrorByName}
        label={Label.DEFAULT_FLAGS}
        name='default_flags'
        update={update}
      />
      <hr className='mb-4' />
      <RoomCustomButtons
        getErrorByName={getErrorByName}
        joinButton={room?.join_button}
        update={update}
      />
    </>
  )
}

export const RoomDefaultLayout: FC<
  UseFetchRoomConsumerProps & {
    defaultLayout: string
  }
> = ({ update, defaultLayout, getErrorByName }) => {
  const name = 'default_layout'
  const [value, setValue] = useState(defaultLayout ?? '')

  useEffect(() => {
    setValue(defaultLayout ?? '')
  }, [defaultLayout])

  const onChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setValue(event.target.value)
      update({
        [name]: event.target.value,
      })
    },
    [update, name]
  )

  return (
    <DefaultLayoutsDropdown
      name={name}
      label='Default Layout'
      onChange={onChange}
      placeholder='Auto'
      selectedId={value}
      error={getErrorByName(name)}
    />
  )
}
