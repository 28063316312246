import React from 'react'
import { ExpandIcon, CompressIcon, VideoFullScreenIcon } from 'src/common/icons'
import { tr, Label } from 'src/common/i18n'
import { FullScreenButton } from 'src/components/CallSettings/FullScreenButton'
import { PictureInPictureButton } from 'src/components/CallSettings/PictureInPictureButton'
import { TestId } from 'src/constants'

const VideoOptionsLayer = () => {
  return (
    <div className='video-options'>
      <div
        className='py-2 pr-4 flex justify-center gap-6'
        data-test={TestId.MCUVideoSettings}
      >
        <PictureInPictureButton />
        <FullScreenButton
          testId={TestId.VideoIconFullscreen}
          InactiveIcon={ExpandIcon}
          ActiveIcon={CompressIcon}
        />
        <FullScreenButton
          selector='#mcu-wrapper'
          testId={TestId.VideoIconFullscreenVideo}
          label={tr(Label.VIDEO_FULL_SCREEN)}
          InactiveIcon={VideoFullScreenIcon}
          ActiveIcon={VideoFullScreenIcon}
        />
      </div>
    </div>
  )
}

export default VideoOptionsLayer
