import React, {
  FC,
  useCallback,
  useState,
  useRef,
  useEffect,
  forwardRef,
} from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
// @ts-expect-error
import Linkify from 'linkifyjs/react'
import { PaperPlaneIcon, ArrowDownIcon } from 'src/common/icons'
import { extractImageURL } from 'src/common/services/helpers'
import { sendMessage, chatActions } from 'src/common/redux/features'
import { CantinaState } from 'src/common/redux/interfaces'
import { getMyParticipantId } from 'src/common/redux/features/calls/callSelectors'
import {
  getActiveChat,
  getActiveChatId,
  getLastReadId,
} from 'src/common/redux/features/chats/chatSelectors'
import { getName } from 'src/common/redux/features/participants/participantSelectors'
import {
  getMessageIdsByChatId,
  getMessage,
} from 'src/common/redux/features/messages/messageSelectors'
import { TestId } from 'src/constants'
import { PrimaryButton } from '../Button/Button'

interface ChatProps {}

const LINKIFY_OPTIONS = { className: 'underline' }
const BOTTOM_SCROLL_MARGIN = 10

export const Chat: FC<ChatProps> = () => {
  const chat = useSelector(getActiveChat)
  if (!chat) {
    return <div className='w-full h-full p-2'></div>
  }

  return <MessageList chatId={chat.id} />
}

const Message = ({
  messageId,
  testId,
}: {
  messageId: string
  testId: string
}) => {
  const message = useSelector((state: CantinaState) =>
    getMessage(state, messageId)
  )
  const date = new Date(message.timestamp)

  return (
    <div className='p-2 flex flex-col' data-test={testId}>
      <span className='text-sw-info bold flex'>
        {message.participantName}:&nbsp;
        <span className='text-xxs text-gray-500 text-right self-center'>
          {date.toLocaleString()}
        </span>
      </span>
      <MessageText text={message.content} />
    </div>
  )
}

const MessageText = ({ text = '' }) => {
  const imageUrl = extractImageURL(text)

  return (
    <div className='overflow-hidden break-words'>
      {imageUrl ? (
        <PicturifiedMessageText url={imageUrl} text={text} />
      ) : (
        <Linkify options={LINKIFY_OPTIONS}>{text}</Linkify>
      )}
    </div>
  )
}

const PicturifiedMessageText = ({
  url,
  text,
}: {
  url: string
  text: string
}) => {
  const urlBeginAt = text.indexOf(url)
  const urlEndAt = urlBeginAt + url.length

  const beforeURL = text.slice(0, urlBeginAt)
  const afterURL = text.slice(urlEndAt)

  return (
    <>
      {beforeURL}
      <a href={url} target='_blank' rel='noreferrer' className='underline'>
        {url}
      </a>
      {afterURL}
      <a href={url} target='_blank' rel='noreferrer'>
        <img className='my-2 mx-auto' src={url} alt={text} title={text} />
      </a>
    </>
  )
}

export const MessageInput = () => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const myId = useSelector(getMyParticipantId)
  const myName = useSelector((state: CantinaState) => getName(state, myId))
  const chatId = useSelector(getActiveChatId)
  const onSubmit = (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    try {
      if (message.trim()) {
        dispatch(
          sendMessage({
            id: '',
            chatId: chatId as string,
            participantId: myId,
            participantName: myName as string,
            status: 'sending',
            content: message,
            direction: 'outbound',
            type: 'text',
            timestamp: Date.now(),
          })
        )

        setMessage('')
      }
    } catch (error) {
      console.error('could not send message', error)
    }
    // return
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value)
  }

  return (
    <form
      onSubmit={onSubmit}
      className='flex p-2 w-full self-end fit-content'
      data-test={TestId.ChatForm}
    >
      <input
        autoComplete='off'
        className='w-full p-1 rounded mr-1'
        name='msg'
        onChange={handleChange}
        placeholder='message...'
        type='text'
        value={message}
        data-test={TestId.ChatInputMessage}
      />
      <PrimaryButton onClick={onSubmit} testId={TestId.ChatButtonSubmit}>
        <PaperPlaneIcon />
      </PrimaryButton>
    </form>
  )
}

const NewMessagesDivider = forwardRef((_props, ref) => (
  /* @ts-ignore */
  <div ref={ref} className='p-2'>
    <span className='text-sw-red border-sw-red text-xs border-t w-full flex justify-end'>
      New messages
    </span>
  </div>
))

// Are we scrolled to the bottom of a scrollable element?
const isAtBottom = (element: HTMLElement) => {
  if (element === null) {
    return true
  }
  return (
    element.scrollTop > 0 &&
    element.scrollTop >
      element.scrollHeight - element.clientHeight - BOTTOM_SCROLL_MARGIN
  )
}

const MessageList = ({ chatId }: { chatId: string }) => {
  const dispatch = useDispatch()
  const messageIds = useSelector(
    (state: CantinaState) => getMessageIdsByChatId(state, chatId),
    shallowEqual
  )
  const lastReadId = useSelector((state: CantinaState) => getLastReadId(state))

  const [atBottom, setAtBottom] = useState(false)

  // Hold value to be used by our cleanup function
  const lastMessageIdRef = useRef('')
  lastMessageIdRef.current = messageIds.slice(-1).pop() ?? ''

  // Is this the first render?
  const firstOpenRef = useRef(true)

  // Decide on the first render if we should show the divider
  const showDivider = useRef(lastMessageIdRef.current !== lastReadId)

  const endOfMessagesRef = useRef<HTMLDivElement>(null)
  const newMessageRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  // Determine if we should show the down button or not
  const toggleDownButton = useCallback(() => {
    const element = containerRef.current
    // We're at the bottom if the last message is in view
    setAtBottom(
      element === null ||
        element.scrollHeight <= element.clientHeight ||
        isAtBottom(element)
    )
  }, [])

  // Scroll to the bottom of the list
  const scrollToBottom = useCallback(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView(false)
      toggleDownButton()
    }
  }, [toggleDownButton])

  // On closing the sidebar, set the last viewed message
  useEffect(() => {
    // Cleanup function only
    return () => {
      if (chatId) {
        dispatch(
          chatActions.setLastViewedId({
            chatId,
            messageId: lastMessageIdRef.current,
          })
        )
      }
    }
  }, [chatId, dispatch])

  // If we're showing the bottom of the list and a new message is added,
  // scroll to show the new message
  useEffect(() => {
    if (!firstOpenRef.current && atBottom) {
      scrollToBottom()
    }
  }, [scrollToBottom, atBottom, messageIds])

  // On opening the sidebar, scroll to the first new message or the bottom
  useEffect(() => {
    if (newMessageRef.current) {
      // Scroll to the new message bar
      newMessageRef.current.scrollIntoView(true)
      toggleDownButton()
    } else {
      // Scroll to the bottom of the list
      scrollToBottom()
    }
    firstOpenRef.current = false
  }, [scrollToBottom, toggleDownButton])

  // When new messages are added, check to see if we need a down button
  useEffect(() => {
    toggleDownButton()
  }, [messageIds, toggleDownButton])

  return (
    <>
      <div
        ref={containerRef}
        className='w-full h-full overflow-y-auto p-2'
        onScroll={toggleDownButton}
      >
        {!lastReadId && <div ref={newMessageRef} />}
        {messageIds.map((messageId, index) => (
          <>
            <div key={messageId}>
              <Message
                messageId={messageId}
                testId={`${TestId.ChatMessage}_${index}`}
              />
            </div>
            {showDivider.current &&
              lastReadId === messageId &&
              lastMessageIdRef.current !== messageId && (
                <NewMessagesDivider
                  key={`divider-${lastReadId}`}
                  ref={newMessageRef}
                />
              )}
          </>
        ))}
        <div ref={endOfMessagesRef} />
      </div>
      {!atBottom && (
        <div className='absolute top-0 right-0 m-2'>
          <PrimaryButton onClick={scrollToBottom}>
            <ArrowDownIcon />
          </PrimaryButton>
        </div>
      )}
    </>
  )
}
