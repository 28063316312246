import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LobbyViewState } from '../../interfaces'

export const initialLobbyViewState: LobbyViewState = Object.freeze({
  currentRoomId: '',
  roomFilter: '',
})

const lobbyViewSlice = createSlice({
  name: 'lobbyView',
  initialState: initialLobbyViewState,
  reducers: {
    setCurrentRoomId: (state, { payload }) => {
      state.currentRoomId = payload
    },
    setRoomFilter: (state, { payload }: PayloadAction<{ filter: string }>) => {
      state.roomFilter = payload.filter
    },
  },
})

// prettier-ignore
export const {
  actions: lobbyViewActions,
  reducer: lobbyViewReducer,
} = lobbyViewSlice
