import React, { FC } from 'react'
import { RoomCommandAction } from 'src/common/constants'
import { useDispatchRoomCommand } from 'src/common/hooks'
import { UserSlashIcon } from 'src/common/icons'
import { Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import { ButtonFeatureToggle } from 'src/components/Inputs/ButtonFeatureToggle'

type DisableAutoRoleToggleProps = {
  roomId: string
  reservationId: string
}

const DisableAutoRoleToggle: FC<DisableAutoRoleToggleProps> = ({
  roomId,
  reservationId,
}) => {
  const dispatchRoomCommand = useDispatchRoomCommand()

  return (
    <ButtonFeatureToggle
      checked
      Icon={UserSlashIcon}
      label={Label.DISABLE_AUTO_ROLE}
      onToggleOn={() => {
        dispatchRoomCommand({
          roomId,
          action: RoomCommandAction.SetVidFloorResId,
          reservationId,
        })
      }}
      onToggleOff={() => {
        dispatchRoomCommand({
          roomId,
          action: RoomCommandAction.SetVidFloorResId,
          reservationId: 'clear',
        })
      }}
      testId={TestId.ParticipantToggleDisableAutoRole}
    />
  )
}

export { DisableAutoRoleToggle }
