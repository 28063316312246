import React from 'react'
import { useSelector } from 'react-redux'
import { getNewMessageCount } from 'src/common/redux/features/messages/messageSelectors'

export const NewMessageCountBadge = () => {
  const newMessageCount = useSelector(getNewMessageCount)
  if (newMessageCount > 0) {
    return <div className='badge-chat-new-messages'>{newMessageCount}</div>
  }
  return null
}
