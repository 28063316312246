import React, {
  FC,
  ButtonHTMLAttributes,
  useState,
  useEffect,
  useRef,
  MouseEvent,
} from 'react'
import { Placement } from '@popperjs/core'
import { tr, Label } from 'src/common/i18n'
import { withTextTooltip } from 'src/hoc/withTextTooltip'
import { TestId } from 'src/constants'

const CONFIRMATION_TOOLTIP_DURATION = 2500 // milliseconds
const CONFIRMATION_TOOLTIP_DEBOUNCE = 2000 // milliseconds

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  transparent?: boolean
  testId?: TestId | string
  ariaLabel?: string
}

const makeButtonHOC = (buttonProps: ButtonProps) => {
  return (props: ButtonProps) => {
    const { transparent, ...rest } = props

    const classNames = [
      props?.className ?? '',
      buttonProps.className ?? 'sw-btn',
      transparent ? 'sw-btn-transparent' : '',
    ].join(' ')

    return <Button {...rest} {...buttonProps} className={classNames} />
  }
}

const Button: FC<ButtonProps> = ({
  ariaLabel,
  children,
  onClick,
  className = '',
  type = 'button',
  disabled = false,
  title,
  testId,
  ...rest
}) => {
  return (
    <button
      aria-label={ariaLabel}
      className={className}
      data-test={testId}
      disabled={disabled}
      onClick={onClick}
      title={title}
      type={type}
      {...rest}
    >
      {children}
    </button>
  )
}

const PrimaryButton = makeButtonHOC({ className: 'sw-btn-primary' })
const SecondaryButton = makeButtonHOC({ className: 'sw-btn-secondary' })
const TertiaryButton = makeButtonHOC({ className: 'sw-btn-tertiary' })
const TransparentButton = makeButtonHOC({
  className: 'sw-btn-transparent',
})
const TransparentPrimaryButton = makeButtonHOC({
  className: 'sw-btn-primary sw-btn-transparent',
})

interface ButtonWithTooltipProps extends ButtonProps {
  offsetY?: number
  placement?: Placement
  title?: string
  titleSecondary?: string
}

// Set the ariaLabel attribute to the text you want to display in the tooltip
const ButtonWithTooltip: FC<ButtonWithTooltipProps> = ({
  offsetY,
  placement,
  title,
  titleSecondary,
  ...rest
}) => {
  return withTextTooltip({
    offsetY,
    placement,
    title,
    titleSecondary,
  })(Button)({ ...rest })
}

interface ButtonWithConfirmationProps extends ButtonProps {
  placement?: Placement
  singleUse?: boolean
}

const ButtonWithConfirmation: FC<ButtonWithConfirmationProps> = ({
  ariaLabel,
  children,
  className = '',
  disabled: initialDisabled = false,
  onClick,
  placement,
  singleUse = false,
  testId,
}) => {
  const initialButtonState = {
    label: ariaLabel,
    clicked: false,
    disabled: initialDisabled,
    testId,
  }
  const [buttonState, setButtonState] = useState(initialButtonState)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current)
    }
  }, [timer])

  const clickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (buttonState.clicked) {
      // Second click
      timer.current && clearTimeout(timer.current)
      setButtonState({ ...initialButtonState, disabled: true })

      // Perform the action
      if (onClick) {
        onClick(event)
      }

      if (!singleUse) {
        // Reset the button after a delay
        timer.current = setTimeout(() => {
          setButtonState(initialButtonState)
        }, CONFIRMATION_TOOLTIP_DEBOUNCE)
      }
    } else {
      // First click
      setButtonState({
        label: tr(Label.CLICK_AGAIN_TO_CONFIRM),
        clicked: true,
        disabled: false,
        testId: `${testId}_confirmation`,
      })
      timer.current = setTimeout(() => {
        setButtonState(initialButtonState)
      }, CONFIRMATION_TOOLTIP_DURATION)
    }
  }

  return withTextTooltip({
    controlled: buttonState.clicked,
    controlledShow: buttonState.clicked,
    placement,
    title: buttonState.label,
  })(Button)({
    ariaLabel: buttonState.label,
    children,
    className,
    disabled: buttonState.disabled,
    onClick: clickHandler,
    testId: buttonState.testId,
  })
}

export {
  Button,
  ButtonWithTooltip,
  ButtonWithConfirmation,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  TransparentButton,
  TransparentPrimaryButton,
}
