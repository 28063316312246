import {
  ActionCreatorWithPayload,
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { Message, MessageState } from '../../interfaces'
import { roomJoinedAction } from '../../features/rooms/roomsSlice'
import { callActions } from '../'

export const initialMessageState: MessageState = Object.freeze({
  byId: {},
  idsByChatId: {},
})

const messageSlice = createSlice({
  name: 'messages',
  initialState: initialMessageState,
  reducers: {
    add: (state, { payload }: PayloadAction<Message>) => {
      state.byId[payload.id] = payload
      if (!state.idsByChatId[payload.chatId]) {
        state.idsByChatId[payload.chatId] = []
      }
      if (state.idsByChatId[payload.chatId].includes(payload.id) === false) {
        state.idsByChatId[payload.chatId].push(payload.id)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(roomJoinedAction, (state, { payload }) => {
        const { roomId } = payload
        if (!roomId) {
          return state
        }
        // Remove other room messages across transfers
        Object.keys(state.idsByChatId)?.forEach((chatId) => {
          if (chatId !== roomId) {
            state.idsByChatId[chatId]?.forEach((messageId) => {
              delete state.byId[messageId]
            })
            delete state.idsByChatId[chatId]
          }
        })
      })
      .addCase(callActions.destroy, () => initialMessageState)
  },
})

export const sendMessage: ActionCreatorWithPayload<Message> = createAction(
  'messages/create'
)

// prettier-ignore
export const {
  actions: messageActions,
  reducer: messageReducer
} = messageSlice
