import React, { FC } from 'react'
import { ArrowUpIcon } from 'src/common/icons'
import { Scope } from 'src/common/constants'
import { useScopeSelector } from 'src/common/hooks'
import { tr, Label } from 'src/common/i18n'
import { BasicTooltip } from '../Tooltip'
import { TestId } from 'src/constants'
import { Button } from './Button'

// Modifiers for the BasicTooltip
const modifiers = [
  {
    name: 'offset',
    options: {
      offset: [-60, 10],
    },
  },
]

/**
 * This is a factory component for buttons with additional options.
 * It receives the TooltipContent FC and if the Scope check passes, it
 * adds a caret wrapped with a BasicTooltip which opens the TooltipContent.
 */
interface ButtonWithCaretProps {
  buttonTestId: TestId
  ButtonType: typeof Button
  caretScope: Scope
  caretTestId: TestId
  clickHandler: () => void
  label: Label
  tooltipContent: JSX.Element
}

export const ButtonWithCaret: FC<ButtonWithCaretProps> = ({
  buttonTestId,
  ButtonType,
  caretScope,
  caretTestId,
  clickHandler,
  label,
  tooltipContent,
}) => {
  const hasScope = useScopeSelector(caretScope)

  if (!ButtonType) {
    return null
  }

  // Only show the additional options if user has the right scope
  if (hasScope) {
    return (
      <div className='flex flex-row'>
        <ButtonType
          className='rounded-r-none mr-0 whitespace-nowrap'
          onClick={clickHandler}
          testId={buttonTestId}
        >
          {tr(label)}
        </ButtonType>
        <BasicTooltip
          modifiers={modifiers}
          placement='top'
          tooltip={tooltipContent}
          trigger='click'
          tooltipClassName='in-call-tooltip menu-tooltip'
        >
          <ButtonType
            aria-label={`${tr(Label.MORE)} ${tr(Label.OPTIONS)}`}
            className='rounded-l-none px-4 border-l border-gray-600'
            data-test={caretTestId}
          >
            <ArrowUpIcon size='xs' />
          </ButtonType>
        </BasicTooltip>
      </div>
    )
  }

  // Otherwise, just return the plain button
  return (
    <ButtonType
      // @ts-ignore
      onClick={clickHandler}
      testId={buttonTestId}
    >
      {tr(label)}
    </ButtonType>
  )
}
