import { eventChannel } from 'redux-saga'
import { v4 as uuidv4 } from 'uuid'
import { IAppMethods } from './interfaces'
import {
  CallState,
  CallError,
  ChatMessageType,
  Displayable,
  EXTENSION_SEPARATOR,
  FAKE_DEVICES,
  HYBRID_SCOPE_ID,
  MediaDevice,
  SoundFile,
} from '../constants'
import { getTimezoneData, forceIdToString } from './helpers'
import {
  SagaEmit,
  Call,
  Participant,
  Message,
  Room,
  CanvasLayout,
} from '../redux/interfaces'
import {
  refreshJWTRequest,
  callActions,
  messageActions,
  uiActions,
  participantActions,
  roomsActions,
  layoutsActions,
  deviceActions,
  roomZoneActions,
  roomJoinedAction,
  roomLeftAction,
} from '../redux/features/'
import { callViewActions, promptViewActions } from '../redux/views'
import { tr, Label } from '../i18n'
import * as cantinaManager from './cantinaManager'
import { cantinaTimer } from './cantinaTimer'

const VERTO_AUTH_ERROR = -32001
const RELAY_AUTH_ERROR = -32002
let cantinaMgrClient: ReturnType<typeof cantinaManager.createClient>
let relayClient: any = null
let sagaEmitter: SagaEmit
const SCOPE_ID = HYBRID_SCOPE_ID

let AppMethods: Partial<IAppMethods> = {}
export const setAppMethods = (methods: IAppMethods) => {
  AppMethods = methods
}

const makeAppMethodsFn = <K extends keyof IAppMethods>(
  method: K
): IAppMethods[K] => {
  return (...params: any[]) => {
    // @ts-ignore
    return AppMethods[method](...params)
  }
}

// Device getter methods
export const getDevices = makeAppMethodsFn('getDevices')
export const getCameras = makeAppMethodsFn('getVideoDevices')
export const getMicrophones = makeAppMethodsFn('getAudioInDevices')
export const getSpeakers = makeAppMethodsFn('getAudioOutDevices')

// Device validation methods
export const validateCamera = makeAppMethodsFn('validateVideoDevice')
export const validateMicrophone = makeAppMethodsFn('validateAudioInDevice')
export const validateSpeaker = makeAppMethodsFn('validateAudioOutDevice')

// Check permission methods
export const checkVideoPermissions = makeAppMethodsFn('checkVideoPermissions')
export const checkAudioPermissions = makeAppMethodsFn('checkAudioPermissions')
export const requestPermissions = makeAppMethodsFn('requestPermissions')

// Audio methods
export const playAudio = makeAppMethodsFn('playAudio')
export const stopAudio = makeAppMethodsFn('stopAudio')

// Toast methods
export const toastCustom = makeAppMethodsFn('toastCustom')
export const toastError = makeAppMethodsFn('toastError')
export const toastInfo = makeAppMethodsFn('toastInfo')
export const toastSuccess = makeAppMethodsFn('toastSuccess')
export const toastWarn = makeAppMethodsFn('toastWarn')
export const dismissToast = makeAppMethodsFn('dismissToast')

export const getUserClientDetails = makeAppMethodsFn('getUserClientDetails')
export const afterLogoutCallback = makeAppMethodsFn('afterLogoutCallback')
export const getInstanceName = makeAppMethodsFn('getInstanceName')
export const getVertoConferenceClass = makeAppMethodsFn(
  'getVertoConferenceClass'
)

export const setClientDefaultMediaConstraints = async ({
  cameraId,
  microphoneId,
  speakerId,
}: {
  cameraId: MediaDevice
  microphoneId: MediaDevice
  speakerId: MediaDevice
}) => {
  if (cameraId === MediaDevice.None) {
    relayClient.disableWebcam()
  } else {
    const video: MediaTrackConstraints = {
      aspectRatio: 16 / 9,
    }
    if (cameraId && cameraId !== MediaDevice.Default) {
      video.deviceId = {
        exact: cameraId,
      }
    }
    await relayClient.setVideoSettings(video)
  }

  if (microphoneId === MediaDevice.None) {
    relayClient.disableMicrophone()
  } else {
    const audio: MediaTrackConstraints = {
      echoCancellation: true,
      // @ts-ignore
      noiseSuppression: true,
      autoGainControl: true,
    }
    if (microphoneId && microphoneId !== MediaDevice.Default) {
      audio.deviceId = {
        exact: microphoneId,
      }
    }
    await relayClient.setAudioSettings(audio)
  }

  if (!FAKE_DEVICES.includes(speakerId)) {
    console.debug('Set speaker:', speakerId)
    relayClient.speaker = speakerId
  }
}

const dispatchAction = (action: any) => {
  if (!sagaEmitter) {
    return console.warn('sagaEmitter not ready yet!')
  }
  sagaEmitter(action)
}

const getExtension = (relayCall: any) => {
  // FIXME: remove the "conf:" hack
  try {
    // split on EXTENSION_SEPARATOR to remove flags from the extension (roomName;mute;vmute)
    const extension = relayCall.extension
      .replace('conf:', '')
      .split(EXTENSION_SEPARATOR)[0]
    return relayCall.conferenceName || extension
  } catch (error) {
    console.error('getExtension', error)
    return ''
  }
}

const callUpdateHandler = (relayCall: any, allowRecovering = false) => {
  // console.log('Relay callUpdate', relayCall.id, relayCall.state)
  /**
   * Workaround: ignore callUpdate from
   * screenShare and secondSource legs
   */
  if (relayCall?.options?.screenShare || relayCall?.options?.secondSource) {
    return
  }

  if (relayCall?.options?.screenShare || relayCall?.options?.secondSource) {
    if (
      relayCall?.options?.secondSource &&
      relayCall.state === CallState.Destroy
    ) {
      dispatchAction(callActions.removeSecondSourceCallId(relayCall.id))
    }
    return
  }

  // If there is a hangupError due to bad devices, save the erroring devices to
  // Redux so we can decide where to redirect the user
  if (relayCall?.hangupError?.message === CallError.DeviceError) {
    const constraints = relayCall?.hangupError?.details?.triedConstraints
    // If we get back something other than a string for the device ID, then
    // the device has an error
    // For example, our test audio error returns the object {exact: 'not-exists'}
    // FIXME:
    // Note: this is not super accurate because "relayCall.hangupError.details" is
    // an "OverconstrainedError" https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
    // object where we have a `constraint` property whose string value is the name of a constraint which was
    // impossible to meet, and a `message` property containing a human-readable string explaining the problem.
    // But, these properties cannot be used since `constraint` could be "deviceId" and we don't know the type of the device
    // (audio or video). The `message` property is always empty instead.
    // For now lets keep this FIXME and we can always improve it.
    dispatchAction(
      callActions.setDeviceErrors({
        audio: Boolean(typeof constraints?.audio?.deviceId !== 'string'),
        video: Boolean(typeof constraints?.video?.deviceId !== 'string'),
      })
    )
  }

  const { remoteCallerName = 'Unknown', remoteCallerNumber = '000' } =
    relayCall.options
  const call: Call = {
    id: relayCall.id,
    state: relayCall.state,
    extension: getExtension(relayCall),
    cameraId: relayCall.cameraId,
    microphoneId: relayCall.microphoneId,
    speakerId: relayCall.options.speakerId || MediaDevice.Default,
    isDirect: relayCall.isDirect || false,
    remoteCallerName: `${remoteCallerName} (${remoteCallerNumber})`,
  }
  relayCall.options.experimental = true
  relayCall.options.watchAudioPackets = true
  relayCall.options.watchAudioPacketsTimeout = 2 * 1000

  switch (relayCall.state) {
    case CallState.New:
      return dispatchAction(callActions.create(call))
    case CallState.Recovering: {
      // Dispatch the action only with allowRecovering
      // See "checkRecoveringCall" method
      if (allowRecovering) {
        dispatchAction(callActions.update(call))
      }
      break
    }
    case CallState.Ringing:
      playAudio(SoundFile.Ringer)
      return dispatchAction(callActions.update(call))
    case CallState.Active:
      stopAudio(SoundFile.Ringer)
      return dispatchAction(callActions.update(call))
    case CallState.Hangup:
      stopAudio(SoundFile.Ringer)
      return dispatchAction(callActions.update(call))
    case CallState.Purge:
      console.debug('Relay skipping', CallState.Purge)
      break
    case CallState.Destroy:
      stopAudio(SoundFile.Ringer)
      dispatchAction(callActions.destroy(call))
      return
    default:
      return dispatchAction(callActions.update(call))
  }
}

const buildParticipant = (data: any): Participant => {
  const {
    call,
    audio = {},
    video = {},
    variables = {},
    connectionState = {},
  } = data

  const roomId = variables.conferenceName || null // it's the UUID with a weird variable name
  const roomName = variables.conferenceDisplayName || null

  return {
    id: forceIdToString(data.participantId),
    callId: call ? call.id : undefined,
    roomId,
    roomZoneId: audio?.zoneID ? String(audio.zoneID) : null,
    cantinaUserId: variables?.cantina_user_id,
    cantinaUserType: variables?.cantina_user_type,
    name: data.participantName,
    number: data.participantNumber,
    email: data.participantNumber,
    company: data.participantCompany || '',
    muted: Boolean(audio?.muted),
    talking: Boolean(audio?.talking),
    deaf: Boolean(audio?.deaf),
    vMuted: Boolean(video?.muted),
    moderator: variables.moderator || false,
    temporaryModerator:
      variables.conference_temporary_moderator === 'true' || false,
    handRaised: Boolean(video?.handRaised),
    lowBitrateMode: connectionState.lowBitrateMode || false,
    hasBanner: !video.fullScreen || false,
    hasDenoise: audio.blockingNoise || false,
    performer: variables.performer || false,
    localVideoCanvas: variables.localVideoCanvas || false,
    noLocalVideo: variables.noLocalVideo || false,
    roomUuid: variables.conferenceUUID || null,
    roomMd5: variables.conferenceMD5 || null,
    roomName,
    hasCustomBanner:
      variables.cantina_name && variables.cantina_name !== '_default_',
    volume: audio.volumeOutLevel || 0,
    gain: audio.volumeInLevel || 0,
    energy: audio.energyLevel || 0,
    connectionQuality: connectionState.quality || 0, // Network indication
    canShare: variables.canShare || false, // Can share or not (not used i think)
    isScreenShareLeg: Boolean(video?.screenShare), // This user is a screenShare call
    isSecondSourceLeg: Boolean(audio?.secondSource), // This user is a secondSource call
    reservationId: video.reservationID || null, // Current reservationId on the canvas (presenter/singer etc)
    autoOverlay: Boolean(video?.autoOverlay), // Render your local video over the MCU
    mcuLayerId: video.videoLayerID,
    motionQuality: video?.motionQuality || 0,
    hasFloor: Boolean(video?.floor),
    echoCancellation: Boolean(audio.echoCancellation),
    noiseSuppression: Boolean(audio.noiseSuppression),
    autoGainControl: Boolean(audio.autoGainControl),
    studioAudio: Boolean(audio.studioAudio),
  }
}

const conferenceUpdateHandler = (notification: any) => {
  // console.log('Relay conferenceUpdate', notification.action)
  switch (notification.action) {
    case 'join': {
      const {
        call: relayCall,
        participantId,
        role,
        conferenceMd5 = null,
        conferenceUuid = null,
      } = notification
      const roomId = conferenceUuid
      dispatchAction(
        callActions.update({
          id: relayCall.id,
          roomId,
          roomMd5: conferenceMd5,
          roomUuid: conferenceUuid,
          participantId,
          extension: getExtension(relayCall),
        })
      )
      dispatchAction(
        roomJoinedAction({
          roomId,
          callId: relayCall.id,
          scopeId: SCOPE_ID,
          role,
        })
      )
      relayCall.stopScreenShare()
      break
    }
    case 'leave': {
      const { call: relayCall, conferenceUuid = null } = notification
      const roomId = conferenceUuid
      return dispatchAction(
        roomLeftAction({
          roomId,
          callId: relayCall.id,
          scopeId: SCOPE_ID,
        })
      )
    }
    case 'bootstrap': {
      // console.debug('Room bootstrap', notification)
      const { call: relayCall, participants = [] } = notification
      if (!relayCall) {
        return console.debug('Invalid liveArray bootstrap', notification)
      }
      const all: Participant[] = participants.map((part: any) => {
        const participant = buildParticipant(part)
        participant.myself = relayCall.id === part.callId
        participant.callId = relayCall.id
        return participant
      })
      const roomId = relayCall.conferenceUuid
      dispatchAction(
        participantActions.roomBootstrap({ roomId, participants: all })
      )
      break
    }
    case 'add':
      return dispatchAction(
        participantActions.create(buildParticipant(notification))
      )
    case 'modify':
      return dispatchAction(
        participantActions.update(buildParticipant(notification))
      )
    case 'delete':
      return dispatchAction(
        participantActions.destroy(buildParticipant(notification))
      )
    case 'layerInfo': {
      const { call, canvasInfo, participant } = notification
      if (participant) {
        dispatchAction(
          participantActions.update({
            id: participant.id,
            mcuLayerId: participant.layerIndex,
          })
        )
        if (call && canvasInfo) {
          canvasInfoHandler(call.id, participant.id, canvasInfo)
        }
      }
      break
    }
    case 'layoutInfo': {
      const { call, participant, canvasInfo } = notification
      if (call && participant && canvasInfo) {
        canvasInfoHandler(call.id, participant.id, canvasInfo)
      }
      break
    }
    case 'logoInfo': {
      const { call: relayCall, logo } = notification
      dispatchAction(
        callActions.update({
          id: relayCall.id,
          participantLogo: logo,
        })
      )
      break
    }
    case 'chatMessage':
      return chatMessageHandler(notification)
    case 'conferenceInfo': {
      const { conferenceState, messages = [] } = notification
      const { running, zones = [], ...conference } = conferenceState
      /**
       * In hybrid mode we're using `laName` since it's equal to the roomId
       * - weird, i know
       */
      const roomId = conference.laName
      // In here i want the server to say explicity running: false.
      // I had some cases where a property was missing from the events
      // so - in that weird case - a check like "!running" would be wrong
      if (running === false) {
        return dispatchAction(roomsActions.destroyById(roomId))
      }
      const room: Room = {
        ...conference,
        id: roomId,
        name: conference.confName,
        preview: conference.lastSnapshot,
        messages,
        running,
      }
      dispatchAction(
        roomZoneActions.createFromConferenceInfo({
          roomId: room.id,
          zones,
        })
      )
      return dispatchAction(roomsActions.createOrUpdate(room))
    }
    case 'clear': {
      /**
       * In hybrid mode we're using `confName` since it's equal to the roomId
       * - weird, i know
       */
      const roomId = notification.confName
      dispatchAction(roomsActions.destroyById(roomId))
      return
    }
    case 'modCommandResponse': {
      const { command, response, conferenceName = '' } = notification
      if (!command) {
        return
      }
      if (response.indexOf('-ERR (play)') !== -1) {
        const roomId = conferenceName
        if (roomId) {
          dispatchAction(
            roomsActions.createOrUpdate({
              id: roomId,
              playingError: true,
            })
          )
          dispatchAction(callViewActions.shiftRecentlyPlayedVideos())
        }
      }
      break
    }
    case 'layoutList': {
      const { call: relayCall, layouts = [] } = notification
      if (relayCall && layouts.length) {
        dispatchAction(
          layoutsActions.addCallLayouts({ callId: relayCall.id, layouts })
        )
      }
      break
    }
    case 'memberTalkState': {
      const { memberID, talking = null } = notification
      if (memberID && talking !== null) {
        dispatchAction(
          participantActions.update({
            id: forceIdToString(memberID),
            talking,
          })
        )
      }
      break
    }
    default:
      console.warn('Unknown Relay conference update', notification)
  }
}

const canvasInfoHandler = (
  callId: string,
  participantId: string,
  canvasInfo: any
) => {
  const info = {
    callId,
    layouts: (canvasInfo.canvasLayouts as CanvasLayout[]).map((layout) => {
      return {
        ...layout,
        myLayout: participantId === layout.participantId,
      }
    }),
  }
  return dispatchAction(callActions.updateLayout(info))
}

const chatMessageHandler = (notification: any) => {
  const { call, messageType } = notification
  let payload: any = {}
  try {
    payload = JSON.parse(messageType)
  } catch (e) {
    return console.warn('Unknown chat message', notification)
  }
  switch (payload.type) {
    case ChatMessageType.Chat: {
      const roomId = call.conferenceUuid
      const message: Message = {
        id: uuidv4(),
        chatId: roomId,
        participantId: payload.authorId,
        participantName: payload.authorName,
        status: 'track-status', // TODO:
        content: notification.messageText,
        direction: notification.direction,
        type: payload.type,
        timestamp: Date.now(),
      }
      dispatchAction(messageActions.add(message))
      break
    }
    case ChatMessageType.Knock: {
      const { sendingParticipantId, receivingParticipantId } = payload
      if (
        call.participantId === sendingParticipantId ||
        call.participantId === receivingParticipantId
      ) {
        playAudio(SoundFile.Knock)
        if (call.participantId === receivingParticipantId) {
          dispatchAction(uiActions.show(Displayable.Knocking))
        }
      }
      break
    }
    case ChatMessageType.RoomZoneInvitationRequest: {
      const {
        sendingParticipantId,
        receivingParticipantIds = [],
        zoneName,
      } = payload
      if (receivingParticipantIds.includes(call.participantId)) {
        playAudio(SoundFile.Knock)
        dispatchAction(
          roomZoneActions.setZoneInvitationList({
            sendingParticipantId,
            zoneName,
          })
        )
      }
      break
    }
    case ChatMessageType.RoomZoneInvitationDeclined: {
      const {
        sendingParticipantId,
        sendingParticipantName,
        receivingParticipantId,
        zoneName,
      } = payload

      // Remove any outstanding records of invitations from the Edit modal
      dispatchAction(
        roomZoneActions.deleteInvitationFromZoneEditInvitationList(
          sendingParticipantId
        )
      )

      if (call.participantId === receivingParticipantId) {
        toastInfo(
          tr(Label.SIDEBAR_INVITATION_DECLINED, {
            sendingParticipantName,
            zoneName,
          })
        )
      }
      break
    }
    case ChatMessageType.RoomZoneKick: {
      const { receivingParticipantId, zoneName } = payload

      if (receivingParticipantId === call.participantId) {
        // Clear all participants' requests to join and invitations to this zone
        dispatchAction(roomZoneActions.clear())
        // And send them a toast
        toastInfo(tr(Label.SIDEBAR_KICKED, { zoneName }))
      }
      break
    }
    case ChatMessageType.RoomZoneDestroyed: {
      const { participantIds, zoneName } = payload

      if (participantIds.includes(`${call.participantId}`)) {
        // Clear all participants' requests to join and invitations to this zone
        dispatchAction(roomZoneActions.clear())
        // And send them a toast
        toastInfo(tr(Label.SIDEBAR_HAS_ENDED, { zoneName }))
      }

      // Update all users' list of outstanding invitations
      // This is separate from the clear action because it includes everyone
      // in the room, not just those already inside the zone
      // Need to use zoneName here because the zone doesn't have an id yet
      dispatchAction(
        roomZoneActions.deleteInvitationFromZoneInvitationList(zoneName)
      )

      break
    }
    case ChatMessageType.RoomZoneRequestToJoin: {
      const {
        receivingParticipantIds,
        requestingParticipantAvatar,
        requestingParticipantId,
        requestingParticipantName,
      } = payload

      if (receivingParticipantIds.includes(call.participantId)) {
        dispatchAction(
          roomZoneActions.setZoneRequestToJoinList({
            requestingParticipantAvatar,
            requestingParticipantId,
            requestingParticipantName,
          })
        )
      }
      break
    }
    case ChatMessageType.RoomZoneDismissRequestToJoin: {
      const {
        receivingParticipantIds,
        requestAllowed,
        requestingParticipantName,
        requestingParticipantId,
      } = payload

      if (receivingParticipantIds.includes(call.participantId)) {
        dispatchAction(
          roomZoneActions.deleteRequestFromRequestToJoinList(
            requestingParticipantId
          )
        )
        if (requestAllowed) {
          toastInfo(
            tr(Label.SOMEONE_JOINED_THE_SIDEBAR, {
              name: requestingParticipantName,
            })
          )
        } else {
          toastInfo(
            tr(Label.SOMEONE_DENIED_FROM_SIDEBAR, {
              name: requestingParticipantName,
            })
          )
        }
      }
      break
    }
    case ChatMessageType.RoomZoneDenyToJoin: {
      const { sendingParticipantName, deniedParticipantId } = payload
      if (call.participantId === deniedParticipantId) {
        toastWarn(
          tr(Label.REQUEST_TO_JOIN_SIDEBAR_DENIED, {
            sender: sendingParticipantName,
          })
        )
      }
      break
    }
    default:
      console.warn('Unknown Chat message type', notification)
  }
}

const trackAddHandler = (notification: any) => {
  const { call, event } = notification
  try {
    console.debug('trackAddHandler', event.track.kind, event.track.id)
    dispatchAction(
      callActions.addInboundTrackId({
        callId: call.id,
        kind: event.track.kind,
        trackId: event.track.id,
      })
    )

    switch (event.track.kind) {
      case 'audio': {
        const params = {
          callId: call.id,
          isSendingAudio: call.peer && call.peer.hasAudioSender,
          isReceivingAudio: call.peer && call.peer.hasAudioReceiver,
        }
        return dispatchAction(callActions.updateAudioFlags(params))
      }
      case 'video': {
        const params = {
          callId: call.id,
          isSendingVideo: call.peer && call.peer.hasVideoSender,
          isReceivingVideo: call.peer && call.peer.hasVideoReceiver,
        }
        return dispatchAction(callActions.updateVideoFlags(params))
      }
      default:
        break
    }
  } catch (error) {
    console.error('trackAddHandler', error)
  }
}

/**
 * Custom mediaParams handler
 * The workaround to the standard applyConstraints is to stop the current audio track
 * and create a new one with new constraints. This because some devices (including iOS)
 * ignore applyConstraints and do not support change live constraints for audio tracks.
 */
const mediaParamsHandler = async (notification: any) => {
  const { call: relayCall, mediaParams = {} } = notification
  if (!relayCall) {
    return console.debug('Invalid mediaParams event', notification)
  }
  const { audio, video } = mediaParams
  if (video) {
    relayCall.peer.applyMediaConstraints('video', video)
  }
  if (audio && relayCall.microphoneId) {
    try {
      // Inject current microphoneId in the audio constraints from the server
      audio.deviceId = {
        exact: relayCall.microphoneId,
      }
      const newConstraints = { audio }
      console.debug(
        'Apply audio constraints [custom]',
        relayCall.id,
        newConstraints
      )
      await relayCall.updateDevices(newConstraints)
    } catch (error) {
      console.error('Error applying [custom] audio constraints', error)
      const safeConstraints = {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true,
        deviceId: {
          exact: relayCall.microphoneId,
        },
      }
      console.debug(
        'Apply audio constraints [safe]',
        relayCall.id,
        safeConstraints
      )
      await relayCall.updateDevices(safeConstraints).catch((error: any) => {
        console.error('Error applying [safe] audio constraints', error)
      })
    }
  }
}

const notificationHandler = (notification: any) => {
  switch (notification.type) {
    case 'callUpdate':
      return callUpdateHandler(notification.call)
    case 'conferenceUpdate':
      return conferenceUpdateHandler(notification)
    case 'refreshToken':
      return dispatchAction(refreshJWTRequest())
    case 'userMediaError':
      console.log('Relay userMediaError', notification)
      return toastWarn(
        'It appears we do not have permissions to access your devices. Please, check your browser settings and allow access to them.'
      )
    case 'participantData': {
      const {
        call: relayCall,
        displayNumber,
        displayName,
        displayDirection,
      } = notification
      const call: Call = {
        id: relayCall.id,
        state: relayCall.state,
        extension: displayDirection === 'inbound' ? displayName : displayNumber,
        isDirect: relayCall.isDirect || false,
      }
      return dispatchAction(callActions.update(call))
    }
    case 'trackAdd':
      return trackAddHandler(notification)
    case 'prompt': {
      console.debug('Prompt', notification)
      const { text, regex, promptType } = notification
      return dispatchAction(
        promptViewActions.show({ regex, promptType, title: text })
      )
    }
    case 'deviceUpdated': {
      const { call: relayCall } = notification
      const audioParams = {
        callId: relayCall.id,
        isSendingAudio: relayCall.peer && relayCall.peer.hasAudioSender,
        isReceivingAudio: relayCall.peer && relayCall.peer.hasAudioReceiver,
      }
      dispatchAction(callActions.updateAudioFlags(audioParams))
      const videoParams = {
        callId: relayCall.id,
        isSendingVideo: relayCall.peer && relayCall.peer.hasVideoSender,
        isReceivingVideo: relayCall.peer && relayCall.peer.hasVideoReceiver,
      }
      dispatchAction(callActions.updateVideoFlags(videoParams))

      // Update devices
      const call: Call = {
        id: relayCall.id,
        state: relayCall.state,
        cameraId: relayCall.cameraId,
        microphoneId: relayCall.microphoneId,
        speakerId: relayCall.options.speakerId || MediaDevice.Default,
      }
      dispatchAction(callActions.update(call))

      const { secondSource, screenShare } = relayCall.options
      const isMain = !secondSource && !screenShare

      if (isMain) {
        if (relayCall.cameraId && relayCall.cameraLabel) {
          dispatchAction(
            deviceActions.cameraChanged({
              deviceId: relayCall.cameraId,
              label: relayCall.cameraLabel,
            })
          )
        } else {
          dispatchAction(
            deviceActions.cameraChanged({
              deviceId: MediaDevice.None,
              label: '',
            })
          )
        }
        if (relayCall.microphoneId && relayCall.microphoneLabel) {
          dispatchAction(
            deviceActions.microphoneChanged({
              deviceId: relayCall.microphoneId,
              label: relayCall.microphoneLabel,
            })
          )
        } else {
          dispatchAction(
            deviceActions.cameraChanged({
              deviceId: MediaDevice.None,
              label: '',
            })
          )
        }
      }
      break
    }
    case 'vertoClientReady':
      break
    case 'mediaParams':
      return mediaParamsHandler(notification)
    default:
      console.debug('Unknown Relay notification', notification)
  }
}

export const isClientInitialized = () => relayClient !== null

export const createSagaChannel = (appConfig: any) => {
  return eventChannel((emit) => {
    sagaEmitter = emit
    cantinaMgrClient = cantinaManager.createClient({
      host: appConfig.cantinaManagerHost,
      token: appConfig.vertoPasswd,
      dispatchAction,
    })
    relayClient = AppMethods.clientBuilder!(appConfig)

    return async () => {
      await cantinaMgrClient.disconnect()
      await relayClient.disconnect()
    }
  })
}

/**
 * TODO: Rename this method
 * relayConnect is going to connect to Hagrid (sw network)
 * and so to CantinaManager.
 * We should rename it to something more generic but for now
 * it's fine.
 */
export const relayConnect = () => {
  // prettier-ignore
  return Promise.all([
    cantinaMgrClient.connect(),
    // relayClient.connect(),
  ])
}

export const cantinaManagerSubscribe = () => {
  return cantinaMgrClient.subscribe()
}

export const cantinaManagerGetRoomMembers = (roomId: string) => {
  return cantinaMgrClient.getRoomMembers({ roomId })
}

export const relayRefreshToken = async (token: string) => {
  /**
   * - Reauthenticate with Hagrid/CantinaMgr
   * - Update the JWT for the relayClient
   * - Do vertoLogin only if we `relayClient` is already connected to FS
   */
  const promises: Promise<any>[] = []
  promises.push(cantinaMgrClient.reauthenticate(token))

  relayClient.options.passwd = token
  if (relayClient.connected) {
    promises.push(relayClient.vertoLogin())
  }

  await Promise.all(promises).catch((error) => {
    console.error('Refresh Auth Error', error)
  })
}

export const makeCall = async (options: any) => {
  // Reject is handled in callSaga
  const { hasReattachedSessions } = await _connectToVertoFS({
    vertoHost: options.vertoHost,
  })
  if (hasReattachedSessions) {
    return console.debug('Reattaching previous session')
  }

  options.userVariables = {
    ...(options.userVariables || {}),
    ...getUserClientDetails(),
    ...getTimezoneData(),
  }
  try {
    const timer = cantinaTimer('cantina:roomJoin')
    timer.start()
    const relayCall = await relayClient.newCall(options)
    console.debug('MakeCall', relayCall)
    timer.stop()
    return relayCall
  } catch (error: any) {
    console.error('MakeCall', error)

    switch (error.message) {
      case CallError.IncompatibleDestination:
        /**
         * FS Rejected the call because the provided
         * SDP is not valid and/or other bad RTC things happened.
         * We can't do too much client-side so we can report an
         * error to the end-user related to either the network
         * or the box instance setup
         */
        console.error('INCOMPATIBLE_DESTINATION')
        break
      case CallError.IceGatheringFailed:
        /**
         * The user is behind an aggressive firewall
         * or the network is in a bad state so we can ask them
         * to check the network, switch WiFi, turn off
         * the firewall etc...
         */
        console.error('ICE_GATHERING_FAILED')
        break
      case CallError.DeviceError:
        /**
         * Either a device or a specific constraints failure.
         * The `error` object contains a custom "details" key with the original
         * browser error with the addition of `triedConstraints` that are the
         * media constraints we asked on the gUM request.
         */
        toastInfo(tr(Label.CONNECTION_ISSUE_DEVICES))
        console.error(
          'DEVICE_ERROR',
          error.details,
          error.details.triedConstraints
        )
        break
      default:
        break
    }
  }
}

export const disableMicrophone = () => relayClient.disableMicrophone()
export const disableWebcam = () => relayClient.disableWebcam()

export const getRelayCall = (callId: string) => relayClient.calls[callId]
export const getRelayConference = (uuid: string) =>
  relayClient.conferences[uuid]

/**
 * From the (old) SDK we have 3 types of "call".
 * Main one for the user, screenShare and secondSource.
 * This function returns true if the callId belongs to a
 * real user.
 */
export const isMainCall = (callId: string) => {
  const call = getRelayCall(callId)
  if (call) {
    const { screenShare, secondSource } = call.options
    return !screenShare && !secondSource
  }
  return false
}

type VertoSubscribeToConferenceParams = {
  vertoHost: string
  roomId: string
}
export const vertoSubscribeToConference = async ({
  vertoHost,
  roomId,
}: VertoSubscribeToConferenceParams) => {
  await _connectToVertoFS({ vertoHost })
  const conferenceList = await relayClient.vertoConferenceList()
  const conference = conferenceList.find((conf: any) => {
    return roomId === conf.laName
  })
  relayClient.setVertoConference(conference)

  await relayClient.vertoSubscribeToConference({
    infoChannel: conference.infoChannel,
    modChannel: conference.modChannel,
  })

  await getRelayConference(conference.uuid).getConferenceState()
}

export const vertoUnsubscribeFromConference = async (_roomId: string) => {
  /** Instead of unsubscribe and then disconnect, just disconnect from FS. */
  relayClient.conferences = {}
  console.warn('Invoke disconnectFromVertoFS [from unsub]')
  await disconnectFromVertoFS()
  // return relayClient.vertoUnsubscribeFromConference(...args)
}

export const getRelayCallDeviceSetupLabel = (callId: string) => {
  const fallback = `Device ${callId.split('-')[0]}`
  try {
    const { cameraLabel, microphoneLabel } = relayClient.calls[callId]
    const label = `${cameraLabel || ''}\n${microphoneLabel || ''}`.trim()
    return label || fallback
  } catch (error) {
    console.warn('getRelayCallDeviceSetupLabel', error)
    return fallback
  }
}

const makeRelayCallMethodFn = (method: string) => {
  return (callId: string, ...params: any[]) => {
    try {
      const call = getRelayCall(callId)
      return call[method](...params)
    } catch (error) {
      console.error(`Error on call: ${callId} method: ${method}`, error)
    }
  }
}

/** Call Methods */
export const hangupCall = makeRelayCallMethodFn('hangup')
export const answerCall = makeRelayCallMethodFn('answer')
export const toggleHoldCall = makeRelayCallMethodFn('toggleHold')
export const stopLocalVideoTrack = makeRelayCallMethodFn('stopOutboundVideo')
export const restoreLocalVideoTrack = makeRelayCallMethodFn(
  'restoreOutboundVideo'
)
export const stopLocalAudioTrack = makeRelayCallMethodFn('stopOutboundAudio')
export const restoreLocalAudioTrack = makeRelayCallMethodFn(
  'restoreOutboundAudio'
)
export const dtmfCall: (callId: string, dtmf: string) => void =
  makeRelayCallMethodFn('dtmf')
export const startScreenShare = (callId: string, options: any) => {
  try {
    const call = getRelayCall(callId)
    options.skipNotifications = false
    options.autoApplyMediaParams = false
    options.onNotification = (notification: any) => {
      const { type, call: relayCall } = notification
      if (type === 'mediaParams') {
        return mediaParamsHandler(notification)
      }
      if (type !== 'callUpdate' || !relayCall) {
        return
      }
      switch (relayCall.state) {
        case CallState.New:
          return dispatchAction(callViewActions.setScreenShareActive(true))
        case CallState.Hangup:
        case CallState.Destroy:
          return dispatchAction(callViewActions.setScreenShareActive(false))
        default:
          // eslint-disable-next-line
          return
      }
    }
    return call.startScreenShare(options)
  } catch (error) {
    console.error('startScreenShare error', error)
  }
}
export const stopScreenShare = makeRelayCallMethodFn('stopScreenShare')
export const transfer: (
  callId: string,
  extension: string,
  participantId: string
) => void = makeRelayCallMethodFn('transfer')
export const updateDevices = (
  callId: string,
  constraints: MediaStreamConstraints
) => {
  try {
    const call = getRelayCall(callId)

    /**
     * If needs to update audio constraints but the call have no
     * microphone, it means they are enabling the microphone
     * so we force EC/NS/AGC to true by default.
     */
    if (typeof constraints.audio === 'object' && !Boolean(call.microphoneId)) {
      constraints.audio = {
        ...constraints.audio,
        echoCancellation: true,
        // @ts-ignore
        noiseSuppression: true,
        autoGainControl: true,
      }
    }
    return call.updateDevices(constraints)
  } catch (error) {
    console.error('updateDevices error', error)
  }
}
export const setAudioOutDevice: (callId: string, speakerId: string) => void =
  makeRelayCallMethodFn('setAudioOutDevice')
export const listVideoLayouts: (callId: string) => void =
  makeRelayCallMethodFn('listVideoLayouts')
export const doReinviteWithRelayOnly: (callId: string) => void =
  makeRelayCallMethodFn('doReinviteWithRelayOnly')
export const askVideoKeyFrame: (callId: string) => void =
  makeRelayCallMethodFn('askVideoKeyFrame')
export const addSecondSource = async (callId: string, options: any) => {
  try {
    const call = getRelayCall(callId)
    options.skipNotifications = false
    options.autoApplyMediaParams = false
    console.warn('Adding second source', callId, options)
    toastInfo(tr(Label.ADDING_SECOND_SOURCE))
    const secondSourceCall = await call.addSecondSource(options)
    dispatchAction(
      callActions.addSecondSourceCallId({
        callId,
        secondSourceCallId: secondSourceCall.id,
      })
    )
  } catch (error) {
    console.error('addSecondSource error', error)
  }
}

/** Chat Methods */
export const sendChatMessage: (
  callId: string,
  message: string,
  type: string
) => void = makeRelayCallMethodFn('sendChatMessage')
export const knock = (
  callId: string,
  sendingParticipantId: string,
  receivingParticipantId: string
) => {
  const type = JSON.stringify({
    type: 'knock',
    sendingParticipantId,
    receivingParticipantId,
  })
  return sendChatMessage(callId, '', type)
}

export const getRemoteStream = (callId: string) => {
  try {
    return getRelayCall(callId).remoteStream
  } catch (e) {
    return null
  }
}

export const getLocalStream = (callId: string) => {
  try {
    return getRelayCall(callId).localStream
  } catch (e) {
    return null
  }
}

export const getHTMLVideoElement = (
  callId: string
): HTMLVideoElement | null => {
  try {
    return getRelayCall(callId).htmlVideoElement
  } catch (error) {
    console.debug('Error getHTMLVideoElement', error)
    return null
  }
}

export const loadDefaultLayoutList = async () => {
  const layoutList: any[] = await relayClient.vertoLayoutList({
    fullList: true,
  })
  // console.debug('Boot layouts', layoutList)
  const defaultIds: string[] = []
  const byId: any = {}
  layoutList.forEach((layout) => {
    defaultIds.push(layout.id)
    byId[layout.id] = layout
  })
  dispatchAction(
    layoutsActions.bulkInsert({ defaultIds, byId, idsByCallId: {} })
  )
}

/**
 * Right after the client subscription we have to check
 * for recovering calls from the previous session.
 * Recover it only if we have one call only (the recovering one)
 * otherwise it means the user is already on another call
 * (ie: invite link) so hangup it.
 */
export const checkRecoveringCall = () => {
  const callIds = Object.keys(relayClient.calls)
  callIds.forEach((callId) => {
    const relayCall = relayClient.calls[callId]
    if (relayCall?.state === CallState.Recovering) {
      if (callIds.length === 1) {
        callUpdateHandler(relayCall, true)
      } else {
        relayCall.hangup()
      }
    }
  })
}

/**
 * Connect to a single FS box to join a room
 *
 * FIXME: Handle verto.attach calls
 */
type ConnectToVertoFSResponse = {
  hasReattachedSessions: boolean
}
const _connectToVertoFS = ({ vertoHost }: { vertoHost: string }) => {
  console.log('Connecting to FS')

  const timer = cantinaTimer('cantina:connectToFs')
  timer.start()

  const promise = new Promise<ConnectToVertoFSResponse>(
    // eslint-disable-next-line
    async (resolve, reject) => {
      const rejectHandler = () => {
        console.warn('Reject to connect to FS')
        clearTimeout(connectTimeout)
        relayClient.disconnect()

        // eslint-disable-next-line
        reject()
      }
      const socketCloseHandler = () => {
        console.warn('FS Client Socket Closed')
        relayClient.off('signalwire.socket.close', socketCloseHandler)
      }
      const socketErrorHandler = () => () => {
        console.warn('FS Client Socket Error')
        relayClient.off('signalwire.socket.error', socketErrorHandler)
      }

      const sameHost = vertoHost === relayClient.options.host
      console.log('Connect to FS', {
        sameHost,
        connected: relayClient.connected,
      })
      if (relayClient.connected && sameHost) {
        console.log('Resolve - already connected')
        return resolve({
          hasReattachedSessions: false,
        })
      } else {
        console.log('Not connected to this host yet')
        relayClient.off('signalwire.socket.close', socketCloseHandler)
        relayClient.off('signalwire.socket.error', socketErrorHandler)

        await disconnectFromVertoFS()
      }

      // Set the client host to the new vertoHost
      relayClient.options.host = vertoHost

      const connectTimeout = setTimeout(() => {
        console.error('FS Connect Timeout')
        rejectHandler()
      }, 10_000)

      relayClient.off('signalwire.ready')
      relayClient.on('signalwire.ready', () => {
        console.debug('FS Client Ready!')
        clearTimeout(connectTimeout)

        relayClient._autoReconnect = true
        window.name = relayClient.sessionid
        loadDefaultLayoutList()
      })

      relayClient.off('signalwire.error')
      relayClient.on('signalwire.error', (error: any) => {
        console.warn('FS Client Error', error)
        // Handle request timeouts and vertoLogin errors
        const codes = [relayClient.timeoutErrorCode, VERTO_AUTH_ERROR]
        if (codes.includes(error.code)) {
          rejectHandler()
        }
      })

      relayClient.on('signalwire.socket.close', socketCloseHandler)
      relayClient.on('signalwire.socket.error', socketErrorHandler)

      relayClient.off('signalwire.notification')
      relayClient.on('signalwire.notification', notificationHandler)

      const vertoReadyHandler = (notification: any) => {
        if (notification.type === 'vertoClientReady') {
          const hasReattachedSessions = Boolean(
            notification?.reattached_sessions?.length
          )
          resolve({ hasReattachedSessions })

          if (hasReattachedSessions) {
            checkRecoveringCall()
          }
        }
      }
      relayClient.on('signalwire.notification', vertoReadyHandler)

      relayClient.connect()
    }
  )

  return promise.then((result) => {
    timer.stop()
    return result
  })
}

export const disconnectFromVertoFS = () => {
  return new Promise<void>((resolve) => {
    if (relayClient.connected || relayClient?.connection?.connecting) {
      console.debug('Disconnect from FS now')
      const handler = () => {
        console.debug('Disconnected from FS')
        relayClient.off('signalwire.socket.close', handler)
        resolve()
      }
      relayClient.on('signalwire.socket.close', handler)
      relayClient._autoReconnect = false
      relayClient.disconnect()
    } else {
      resolve()
    }
  })
}

export const loadVertoRooms = async () => {
  try {
    const params = {
      showLayouts: false,
      showMembers: false,
    }
    const rooms = await relayClient.vertoConferenceList(params)
    relayClient.conferences = {}
    const VertoConference = getVertoConferenceClass()
    rooms.forEach((row: any) => {
      relayClient.conferences[row.laName] = new VertoConference(
        relayClient,
        row
      )
    })
  } catch (error) {
    console.debug('loadVertoRooms error', error)
  }
}

export const loadVertoParticipants = async () => {
  try {
    const params = {
      showLayouts: false,
      showMembers: true,
    }
    const rooms = await relayClient.vertoConferenceList(params)
    // SDK Hack to empty the `conferences` object
    relayClient.conferences = {}
    const VertoConference = getVertoConferenceClass()
    rooms.forEach((row: any) => {
      // SDK Hack to fill the `conferences` object
      relayClient.conferences[row.laName] = new VertoConference(
        relayClient,
        row
      )

      const participants: Participant[] = row.members.map((member: any) => {
        return {
          id: member.participantId,
          callId: member.callId,
          roomId: row.laName,
          // TODO: Used only in-call
          // roomZoneId: null,
          cantinaUserId: member.variables?.cantina_user_id,
          cantinaUserType: member.variables?.cantina_user_type,
          name: member.participantName,
          number: member.participantNumber,
          email: member.participantNumber,
          // TODO: Handle company?
          // company: data.participantCompany || '',
          muted: !Boolean(member?.flags?.can_speak),
          talking: Boolean(member?.flags?.talking),
          deaf: !Boolean(member?.flags?.can_hear),
          // vMuted: Boolean(member?.video_muted),
          moderator: Boolean(member?.flags?.is_moderator),
          // temporaryModerator: false,
          // handRaised: Boolean(video?.handRaised),
          // lowBitrateMode: connectionState.lowBitrateMode || false,
          // hasBanner: !video.fullScreen || false,
          // hasDenoise: audio.blockingNoise || false,
          // performer: variables.performer || false,
          // localVideoCanvas: variables.localVideoCanvas || false,
          // noLocalVideo: variables.noLocalVideo || false,
          // roomUuid: variables.conferenceUUID || null,
          // roomMd5: variables.conferenceMD5 || null,
          // roomName,
          // hasCustomBanner: variables.cantina_name && variables.cantina_name !== '_default_',
          // volume: audio.volumeOutLevel || 0,
          // gain: audio.volumeInLevel || 0,
          // energy: audio.energyLevel || 0,
          // connectionQuality: connectionState.quality || 0, // Network indication
          // canShare: variables.canShare || false, // Can share or not (not used i think)
          isScreenShareLeg: member.type === 'screen', // This user is a screenShare call
          isSecondSourceLeg: member.type === 'device', // This user is a secondSource call
          // reservationId: video.reservationID || null, // Current reservationId on the canvas (presenter/singer etc)
          // autoOverlay: Boolean(video?.autoOverlay), // Render your local video over the MCU
          // mcuLayerId: video.videoLayerID,
          // motionQuality: video?.motionQuality || 0,
          // hasFloor: Boolean(video?.floor),
          // echoCancellation: Boolean(audio.echoCancellation),
          // noiseSuppression: Boolean(audio.noiseSuppression),
          // autoGainControl: Boolean(audio.autoGainControl),
          // studioAudio: Boolean(audio.studioAudio),
        }
      })

      dispatchAction(
        participantActions.roomBootstrap({
          roomId: row.laName,
          participants,
        })
      )
    })
  } catch (error) {
    console.debug('loadVertoRooms error', error)
  }
}
