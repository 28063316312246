import React, { ReactNode, useEffect, useState, ComponentType } from 'react'
import { usePopper } from 'react-popper'
import { Placement, PositioningStrategy } from '@popperjs/core'
import { uniqueId } from 'src/common/services/helpers'
import { tr, Label } from 'src/common/i18n'

export const TOOLTIP_OFFSET_Y_TOP_BAR = 18
export const TOOLTIP_OFFSET_Y_BOTTOM_BAR = 12
export const TOOLTIP_OFFSET_Y_ROOM_CARD = 12

// Passing 'content' will override the title, titleSecondary, and subtitle markup
// Setting 'controlled' to true will allow the the component to be hidden or shown
//  with the 'controlledShow' prop, which ignores the button hover events
// 'offsetX' and 'offsetY' values will override any offsets passed in 'modifiers'
//
// Placements: auto, top, right, bottom, left (can add a suffix of '-start' or '-end')
export const withTextTooltip =
  (tooltipOptions?: {
    className?: string
    content?: ReactNode
    controlled?: boolean
    controlledShow?: boolean
    modifiers?: Array<any>
    offsetX?: number
    offsetY?: number
    placement?: Placement
    strategy?: PositioningStrategy
    subtitle?: string
    title?: string
    titleSecondary?: string
  }) =>
  <T extends object>(WrappedComponent: ComponentType<T>) => {
    return (props: T) => {
      const [referenceElement, setReferenceElement] =
        useState<HTMLElement | null>(null)
      const [popperElement, setPopperElement] = useState<HTMLElement | null>(
        null
      )
      const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null)
      const [showTooltip, setShowTooltip] = useState(false)
      const [tooltipId] = useState(() => uniqueId('tooltip'))

      // Handle all of the DOM events to hide or show the tooltip
      // TODO: could add focus & blur, but need to target nearest descendant that's
      //   able to get focus (a, button, input, etc.)
      useEffect(() => {
        const show = () => {
          setShowTooltip(true)
        }
        const hide = () => {
          setShowTooltip(false)
        }
        const events = {
          mouseenter: show,
          mouseleave: hide,
          touchstart: show,
          touchend: hide,
          click: hide,
        }

        if (referenceElement) {
          Object.entries(events).forEach((event) => {
            const [name, func] = event
            referenceElement.addEventListener(name, func, { passive: true })
          })

          return () => {
            Object.entries(events).forEach((event) => {
              const [name, func] = event
              // @ts-expect-error
              referenceElement.removeEventListener(name, func, {
                passive: true,
              })
            })
          }
        }
      }, [referenceElement])

      let tooltipText
      if (tooltipOptions?.title || referenceElement) {
        // Use wrapped component's aria-label by default and override with "title" option
        tooltipText =
          tooltipOptions?.title ??
          referenceElement
            ?.querySelector('[aria-label]')
            ?.getAttribute('aria-label')
      }

      // Add default modifiers and allow overriding
      const defaultModifiers = [
        { name: 'arrow', options: { element: arrowElement, padding: 2 } },
        { name: 'offset', options: { offset: [0, 8] } },
        {
          name: 'preventOverflow',
          options: { padding: { right: 3, left: 3 } },
        },
      ]

      // Override with passed modifiers
      const popperModifiers = Array.isArray(tooltipOptions?.modifiers)
        ? // @ts-expect-error
          defaultModifiers.concat(tooltipOptions.modifiers)
        : defaultModifiers

      // Override offset with easier to use props, since this is a common modifier
      if (tooltipOptions?.offsetX || tooltipOptions?.offsetY) {
        popperModifiers.push({
          name: 'offset',
          options: {
            offset: [
              tooltipOptions?.offsetX ?? 0,
              tooltipOptions?.offsetY ?? 0,
            ],
          },
        })
      }

      const { styles, attributes } = usePopper(
        referenceElement,
        popperElement,
        {
          placement: tooltipOptions?.placement ?? 'top',
          strategy: tooltipOptions?.strategy ?? undefined,
          modifiers: popperModifiers,
        }
      )

      const displayTooltip = tooltipOptions?.controlled
        ? tooltipOptions?.controlledShow
        : tooltipText && showTooltip

      return (
        <>
          <span
            aria-describedby={displayTooltip ? tooltipId : undefined}
            className='tooltip-wrapper'
            ref={setReferenceElement}
          >
            <WrappedComponent {...props} />
          </span>

          {displayTooltip && (
            <div
              id={tooltipId}
              ref={setPopperElement}
              role='tooltip'
              className={`tooltip-text ${tooltipOptions?.className ?? ''}`}
              data-show
              style={styles.popper}
              {...attributes.popper}
            >
              {tooltipOptions?.content ?? (
                <>
                  <div className='tooltip-title'>
                    {tooltipText}
                    {tooltipOptions?.titleSecondary && (
                      <em aria-label={tr(Label.KEYBOARD_SHORTCUT)}>
                        {tooltipOptions?.titleSecondary}
                      </em>
                    )}
                  </div>
                  {tooltipOptions?.subtitle && (
                    <div className='tooltip-subtitle'>
                      {tooltipOptions?.subtitle}
                    </div>
                  )}
                </>
              )}
              <div
                ref={setArrowElement}
                className='tooltip-text-arrow'
                style={styles.arrow}
                {...attributes.arrow}
              />
            </div>
          )}
        </>
      )
    }
  }
