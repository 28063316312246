import React, { FC } from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import {
  callScreenShareStart,
  callScreenShareStop,
} from 'src/common/redux/features'
import { DesktopIcon } from 'src/common/icons'
import {
  withActiveCall,
  withConferenceShareScreenScope,
  withScreenShareSupport,
  withCurrentParticipant,
} from 'src/common/hoc'
import { tr, Label } from 'src/common/i18n'
import { CantinaState } from 'src/common/redux/interfaces'
import { isScreenShareActive } from 'src/common/redux/views/callView/callViewSelectors'
import { canShare as canShareSelector } from 'src/common/redux/features/participants/participantSelectors'
import { useMyZone } from 'src/common/hooks'
import { SecondSourceSettings } from 'src/components/Devices/SecondSourceSettings'
import { TestId } from 'src/constants'
import { TOOLTIP_OFFSET_Y_BOTTOM_BAR } from 'src/hoc/withTextTooltip'
import { withTooltipCaret } from '../Tooltip'
import { ButtonWithTooltip } from '../Button/Button'

// callId will override the one from withActiveCall
type ScreenShareButtonProps = {
  callId: string
  className?: string
  disabled?: boolean
  participantId: string
  label?: string
  offLabel?: string
  enabled: boolean
}

const ScreenShareButton: FC<ScreenShareButtonProps> = ({
  callId,
  className = '',
  disabled,
  participantId,
}) => {
  const dispatch = useDispatch()
  const { isInZone } = useMyZone()
  const canShare = useSelector((state: CantinaState) =>
    canShareSelector(state, participantId)
  )
  const active = useSelector(isScreenShareActive)
  const buttonClasses = `${active ? 'text-sw-success' : ''} ${
    canShare ? '' : 'opacity-40'
  }`

  const clickHandler = () => {
    if (active) {
      dispatch(callScreenShareStop(callId))
    } else {
      dispatch(callScreenShareStart(callId))
    }
  }

  const buttonDisabled = disabled || !canShare || isInZone

  let label = ''
  switch (true) {
    case disabled:
      label = tr(Label.SHARE_YOUR_SCREEN)
      break
    case !canShare:
      label = tr(Label.SCREEN_SHARING_PERMISSION)
      break
    case isInZone:
      label = tr(Label.FEATURE_IS_UNAVAILABLE_IN_SIDEBAR, {
        feature: tr(Label.SCREEN_SHARING),
      })
      break
    case active:
      label = tr(Label.STOP_SHARING)
      break
    default:
      label = tr(Label.SHARE_YOUR_SCREEN)
  }

  return (
    <ButtonWithTooltip
      ariaLabel={label}
      className={`sw-btn-icon-toggle screen-share ${buttonClasses} ${className}`}
      disabled={buttonDisabled}
      offsetY={TOOLTIP_OFFSET_Y_BOTTOM_BAR}
      onClick={clickHandler}
      testId={TestId.BottomBarButtonShare}
      titleSecondary={buttonDisabled ? '' : 'K'}
    >
      <DesktopIcon size='lg' />
    </ButtonWithTooltip>
  )
}

type LabelWrappedScreenShareButtonProps = {
  callId?: string
  className?: string
  disabled?: boolean
  label?: string
  participantId?: string
}

const ScreenShareButtonEnabled: FC<ScreenShareButtonProps> = compose(
  withTooltipCaret(
    SecondSourceSettings,
    TestId.BottomBarButtonShareTooltip,
    Label.DEVICE_OPTIONS
  )
)(ScreenShareButton)

const InCallScreenShareButton = compose<FC<LabelWrappedScreenShareButtonProps>>(
  withConferenceShareScreenScope,
  withScreenShareSupport,
  withCurrentParticipant,
  withActiveCall
)((props: ScreenShareButtonProps) => {
  const canShare = useSelector((state: CantinaState) =>
    canShareSelector(state, props.participantId)
  )
  if (canShare && !props.disabled) {
    return <ScreenShareButtonEnabled {...props} />
  }
  return <ScreenShareButton {...props} />
})

export { ScreenShareButtonEnabled, InCallScreenShareButton }
