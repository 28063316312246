import { all, spawn, call } from 'redux-saga/effects'
import { watchAuths } from './features/auth/authSaga'
import { watchCalls } from './features/calls/callSaga'
import { watchChats } from './features/chats/chatSaga'
import { watchUI } from './features/ui/uiSaga'
import {
  watchBootstrapRequest,
  watchUpdateSettingsRequests,
} from './features/settings/settingsSaga'
import { watchRoomCommands } from './features/rooms/roomsSaga'
import { watchParticipants } from './features/participants/participantSaga'
import { watchClipeeze } from './features/clipeeze/clipeezeSaga'
import { watchMixPanel } from './features/mixpanel/mixpanelSaga'
import { watchUpdateRoomTemplateRequests } from './features/roomTemplates/roomTemplatesSaga'
import { watchRoomList } from './features/roomList/roomListSaga'
import { watchLayouts } from './features/layouts/layoutsSaga'

const ROOT_SAGAS = [
  watchBootstrapRequest,
  watchAuths,
  watchCalls,
  watchChats,
  watchUI,
  watchParticipants,
  watchRoomCommands,
  watchClipeeze,
  watchRoomList,
  watchLayouts,
]
if (process.env.REACT_APP_HYBRID_MODE === '1') {
  ROOT_SAGAS.push(watchUpdateRoomTemplateRequests, watchUpdateSettingsRequests)
}
/**
 * Watch MixPanel events only with REACT_APP_MIXPANEL_TOKEN
 */
if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  ROOT_SAGAS.push(watchMixPanel)
}

export default function* root(sagas = ROOT_SAGAS) {
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (error) {
            console.warn('RootSaga catch:', error)
          }
        }
      })
    )
  )
}
