import React, { FC } from 'react'
import { EllipsisIcon } from 'src/common/icons'
import { useFloater } from 'src/common/hooks'
import { withConferenceLayerControlsScope } from 'src/common/hoc'
import { tr, Label } from 'src/common/i18n'
import { FloaterType, MEDIA_CONTROLS_FLOATER_ID } from 'src/common/constants'
import { withTextTooltip } from 'src/hoc/withTextTooltip'

type PlaybackSettingsButtonProps = {
  showTooltip?: boolean
}

const Button: FC<PlaybackSettingsButtonProps> = () => {
  const { toggleFloaterFunction, ref } = useFloater({
    id: MEDIA_CONTROLS_FLOATER_ID,
    type: FloaterType.PlaybackControls,
  })

  return (
    <button
      aria-label={tr(Label.PLAYBACK_SETTINGS)}
      className='sw-btn-icon-toggle'
      onClick={toggleFloaterFunction}
      ref={ref}
    >
      <EllipsisIcon size='lg' fixedWidth />
    </button>
  )
}

export const PlaybackSettingsButton: FC<PlaybackSettingsButtonProps> = ({
  showTooltip,
  ...rest
}) => {
  if (showTooltip) {
    return withTextTooltip()(Button)({})
  }
  return <Button {...rest} />
}

export const ScopedPlaybackSettingsButton: FC<PlaybackSettingsButtonProps> =
  withConferenceLayerControlsScope(PlaybackSettingsButton)
