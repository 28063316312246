import React, { FC } from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { PauseIcon } from 'src/common/icons'
import { withActiveCall } from 'src/common/hoc'
import { callToggleHold } from 'src/common/redux/features'
import { withInCallButtonWrapper } from './withInCallButtonWrapper'
import { isCallOnHold } from 'src/common/redux/features/calls/callSelectors'
import { CantinaState } from 'src/common/redux/interfaces'

type HoldButtonProps = {
  callId: string
}
const HoldButton: FC<HoldButtonProps> = ({ callId }) => {
  const dispatch = useDispatch()
  const isHold = useSelector((state: CantinaState) =>
    isCallOnHold(state, callId)
  )
  const clickHandler = () => dispatch(callToggleHold(callId))
  // use disabled as the "active" class
  const className = isHold ? 'disabled' : ''

  return (
    <button
      className={`sw-btn-icon-toggle ${className}`}
      onClick={clickHandler}
    >
      <PauseIcon size='lg' fixedWidth />
    </button>
  )
}

export const InCallHoldButton = compose<FC<Partial<HoldButtonProps>>>(
  withInCallButtonWrapper,
  withActiveCall
)(HoldButton)
