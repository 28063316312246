import React, { FC, memo, useEffect, useCallback, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
// @ts-expect-error
import AutoSizer from 'react-virtualized-auto-sizer'
// @ts-expect-error
import InfiniteLoader from 'react-window-infinite-loader'
// @ts-expect-error
import { FixedSizeList as ReactWindowList } from 'react-window'
import { RoomCommandAction } from 'src/common/constants'
import { TestId, SEARCH_DEBOUNCE_DELAY } from 'src/constants'
import { useDispatchRoomCommand, useDebounce } from 'src/common/hooks'
import { CantinaState } from 'src/common/redux/interfaces'
import { createLoadingSelector } from 'src/common/redux/features/loading/loadingSelectors'
import {
  loadClipeezeRequest,
  loadClipeezeFilteredRequest,
  clipeezeActions,
} from 'src/common/redux/features'
import {
  getClipeeze,
  getFilteredClipeezes,
  getClipeezes,
  getClipeezeFilter,
  hasMoreClipeeze,
  hasMoreFilteredClipeeze,
} from 'src/common/redux/features/clipeeze/clipeezeSelectors'
import { getActiveCallRoomId } from 'src/common/redux/features/calls/callSelectors'
import { tr, Label } from 'src/common/i18n'
import { SpinnerIcon } from 'src/common/icons'
import { SecondaryButton } from '../Button/Button'
import { ClipeezeClip } from './ClipeezeClip'

const ROW_HEIGHT = 80
const ITEMS_LEFT_TO_SHOW_BEFORE_LOADING = 10

const Loading = () => (
  <div className='flex items-center px-4 py-2 gap-3'>
    <div className='text-sm my-1'>{tr(Label.LOADING)}</div>
    <SpinnerIcon />
  </div>
)

interface ClipeezeRowProps {
  clipId: string
  testId: TestId | string
  playClip: (clipId: string) => void
}

const ClipeezeRow = ({ clipId, testId, playClip }: ClipeezeRowProps) => {
  const clipeeze = useSelector(
    (state: CantinaState) => getClipeeze(state, clipId),
    shallowEqual
  )

  if (!clipeeze) {
    return null
  }

  return (
    <div
      className='flex h-16 px-2 mb-4 cursor-pointer'
      onClick={() => playClip(clipId)}
      style={{ height: ROW_HEIGHT }}
    >
      <ClipeezeClip
        className='shrink-0 w-24 h-full mr-2'
        clipeeze={clipeeze}
        testId={testId}
      />
      <div className='justify-self-start h-full leading-tight overflow-hidden break-words'>
        <div className='leading-5 line-clamp-3'>{clipeeze.description}</div>
      </div>
    </div>
  )
}

interface ClipeezeListUIProps {
  clipeezeIds: string[]
  hasNextPage: boolean
  isLoadingNextPage: boolean
  loadNextPage: () => void
  playClip: (clipId: string) => void
}

// Use react-window to show only visible rows instead of the whole list
const ClipeezeListUI: FC<ClipeezeListUIProps> = memo(
  ({ clipeezeIds, hasNextPage, isLoadingNextPage, loadNextPage, playClip }) => {
    // Add "loading" item if there are more items forthcoming
    const itemCount = hasNextPage ? clipeezeIds.length + 1 : clipeezeIds.length
    const loadMoreItems = isLoadingNextPage ? () => {} : loadNextPage
    const isItemLoaded = (index: number) =>
      !hasNextPage || index < clipeezeIds.length

    // The nesting of these components follows patterns recommended for react-window
    // https://github.com/bvaughn/react-window
    return (
      <AutoSizer disableWidth>
        {({ height, width }: any) => {
          return (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }: any) => (
                <ReactWindowList
                  ref={ref}
                  itemCount={itemCount}
                  onItemsRendered={onItemsRendered}
                  className='clipeeze-list'
                  height={height}
                  width={width}
                  itemSize={ROW_HEIGHT}
                  overscanCount={ITEMS_LEFT_TO_SHOW_BEFORE_LOADING}
                >
                  {({ index, style }: any) => {
                    return (
                      <div style={style}>
                        {isItemLoaded(index) ? (
                          <ClipeezeRow
                            clipId={clipeezeIds[index]}
                            testId={`${TestId.ClipeezeClip}_${index}`}
                            playClip={playClip}
                          />
                        ) : (
                          <Loading />
                        )}
                      </div>
                    )
                  }}
                </ReactWindowList>
              )}
            </InfiniteLoader>
          )
        }}
      </AutoSizer>
    )
  }
)

interface ClipeezeListContentsProps {
  clipeezeIds: string[]
  hasNextPage?: boolean
  isLoading?: boolean
  loadNextPage: () => void
}

const ClipeezeListContents: FC<ClipeezeListContentsProps> = ({
  clipeezeIds,
  isLoading = false,
  hasNextPage = false,
  loadNextPage,
}) => {
  const dispatchRoomCommand = useDispatchRoomCommand()
  const roomId = useSelector(getActiveCallRoomId)

  const playClipeeze = useCallback(
    (clipId: string) => {
      dispatchRoomCommand({
        action: RoomCommandAction.PlayClipeeze,
        roomId,
        clipId,
      })
    },
    [dispatchRoomCommand, roomId]
  )

  return (
    <ClipeezeListUI
      clipeezeIds={clipeezeIds}
      playClip={playClipeeze}
      isLoadingNextPage={isLoading}
      loadNextPage={loadNextPage}
      hasNextPage={hasNextPage}
    />
  )
}

interface NoClipeezeProps {
  filtered?: boolean
}

const NoClipeeze: FC<NoClipeezeProps> = ({ filtered = false }) => {
  const dispatch = useDispatch()
  const clearFilter = () => {
    dispatch(clipeezeActions.clearClipeezeFilter())
  }

  return (
    <div className='flex items-center justify-between w-full px-4 py-2'>
      <div className='text-sm my-1'>{tr(Label.NO_CLIPEEZE_FOUND)}</div>
      {filtered && (
        <SecondaryButton
          className='p-2 text-xs'
          onClick={clearFilter}
          testId={TestId.ClipeezeButtonClearFilter}
        >
          {tr(Label.CLEAR_FILTER)}
        </SecondaryButton>
      )}
    </div>
  )
}

interface ClipeezeListFilteredProps {
  filter?: string
}

const ClipeezeListFiltered: FC<ClipeezeListFilteredProps> = ({
  filter = '',
}) => {
  const dispatch = useDispatch()
  const clipeeze: string[] = useSelector(getFilteredClipeezes, shallowEqual)
  const hasNextPage = useSelector(hasMoreFilteredClipeeze)
  const isLoading: boolean = useSelector(
    createLoadingSelector('clipeeze/loadClipeezeFiltered')
  )
  const [currentFilter, setCurrentFilter] = useState('')
  const debouncedFilter = useDebounce(filter, SEARCH_DEBOUNCE_DELAY)

  useEffect(() => {
    // If the filter has changed, clear the list to cause the request action
    // to load page 0 for this filter
    if (debouncedFilter !== currentFilter) {
      dispatch(clipeezeActions.clearClipeezeFiltered())
      dispatch(loadClipeezeFilteredRequest())
      setCurrentFilter(debouncedFilter)
    }
  }, [dispatch, currentFilter, debouncedFilter])

  if (!clipeeze?.length) {
    if (isLoading) {
      return <Loading />
    }
    return <NoClipeeze filtered />
  }
  return (
    <ClipeezeListContents
      clipeezeIds={clipeeze}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
      loadNextPage={async () => await dispatch(loadClipeezeFilteredRequest())}
    />
  )
}

const ClipeezeListUnfiltered: FC = () => {
  const dispatch = useDispatch()
  const clipeeze: string[] = useSelector(getClipeezes, shallowEqual)
  const hasNextPage = useSelector(hasMoreClipeeze)
  const isLoading: boolean = useSelector(
    createLoadingSelector('clipeeze/loadClipeeze')
  )

  useEffect(() => {
    // If there are no clips in the store, load the first page
    if (!clipeeze?.length) {
      dispatch(loadClipeezeRequest())
    }
  }, [dispatch, clipeeze])

  if (!clipeeze?.length) {
    if (isLoading) {
      return <Loading />
    }
    return <NoClipeeze />
  }

  return (
    <ClipeezeListContents
      clipeezeIds={clipeeze}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
      loadNextPage={async () => await dispatch(loadClipeezeRequest())}
    />
  )
}

export const ClipeezeList: FC = () => {
  const clipeezeFilter: string = useSelector(getClipeezeFilter, shallowEqual)
  const isFiltered = !!clipeezeFilter?.length

  return isFiltered ? (
    <ClipeezeListFiltered filter={clipeezeFilter} />
  ) : (
    <ClipeezeListUnfiltered />
  )
}
