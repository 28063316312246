import React, { FC } from 'react'
import { compose } from 'redux'
import { useSelector, shallowEqual } from 'react-redux'
import { tr, Label } from 'src/common/i18n'
import { withMCUEmptyLayersFeatureFlag } from 'src/common/hoc'
import { CantinaState } from 'src/common/redux/interfaces'
import { getCallCanvasEmptyLayers } from 'src/common/redux/features/calls/callSelectors'

type EmptyLayersProps = {
  callId: string
}

export const EmptyLayersFunction: FC<EmptyLayersProps> = ({ callId }) => {
  const emptyLayouts = useSelector(
    (state: CantinaState) => getCallCanvasEmptyLayers(state, callId),
    shallowEqual
  )

  return (
    <>
      {emptyLayouts.map((layout) => (
        <EmptyLayer key={layout.resID} layout={layout} />
      ))}
    </>
  )
}

// prettier-ignore
export const EmptyLayers: FC<EmptyLayersProps> = compose(
  withMCUEmptyLayersFeatureFlag()
)(EmptyLayersFunction)

const EmptyLayer = ({ layout }: { layout: any }) => {
  return (
    <div
      className='absolute overflow-hidden bg-gray-900 bg-opacity-75'
      style={layout.styles}
    >
      <div className='flex items-center justify-center flex-col text-center w-full h-full border border-gray-600'>
        {layout?.resIDLabel?.length && (
          <>
            <p className='sw-clamp-lg text-white px-2 sm:px-0'>
              {tr(Label.RESERVED_FOR)} {layout.resIDLabel}
            </p>
            <p className='sw-clamp-base text-gray-400 mt-2 hidden md:block'>
              {tr(Label.ASSIGN_ROLE_INSTRUX)}
            </p>
          </>
        )}
      </div>
    </div>
  )
}
