import React, { FC, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { ArrowDownIcon, ArrowUpIcon } from 'src/common/icons'
import { Checkbox } from '../Inputs/Checkbox'
import { ParticipantKeysMap } from 'src/common/redux/interfaces'
import { callViewActions } from 'src/common/redux/views'
import { getParticipantListGroups } from 'src/common/redux/views/callView/callViewSelectors'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'
import { GroupRolesAssignedCheckbox } from './ParticipantGroupRolesAssignedCheckbox'
import { HandsRaisedCheckbox } from './ParticipantHandsRaisedCheckbox'
import { ParticipantGroupTalking } from './ParticipantGroupTalking'
import { ParticipantGroupModerators } from './ParticipantGroupModerators'
import { ParticipantGroupHandsRaised } from './ParticipantGroupHandsRaised'
import { ParticipantGroupRolesAssigned } from './ParticipantGroupRolesAssigned'

interface ParticipantGroupControlsProps {
  roomId: string
}

export const ParticipantGroupControls: FC<ParticipantGroupControlsProps> = ({
  roomId,
}) => {
  const dispatch = useDispatch()
  const groups: ParticipantKeysMap = useSelector(
    getParticipantListGroups,
    shallowEqual
  )
  const [groupsAreVisible, setGroupsAreVisible] = useState(false)
  const visible = groupsAreVisible ? 'block' : 'hidden'

  return (
    <>
      <div className='sticky top-0 px-2 z-30 block-primary'>
        <div
          className='cursor-pointer mb-2'
          onClick={() => setGroupsAreVisible(!groupsAreVisible)}
          data-test={TestId.ParticipantsButtonShowGroupBy}
        >
          <span className='mr-2'>{tr(Label.GROUP_BY)}</span>
          {groupsAreVisible ? (
            <ArrowUpIcon size='sm' fixedWidth />
          ) : (
            <ArrowDownIcon className='fa-fw-16' size='sm' fixedWidth />
          )}
        </div>
        <div
          className={`${visible} grid grid-cols-2 block-secondary border rounded-md p-2`}
        >
          <div>
            <Checkbox
              checked={groups?.talking ?? false}
              className='w-full'
              label={tr(Label.TALKING)}
              name={tr(Label.TALKING)}
              onChange={() =>
                dispatch(callViewActions.updateGroupsList('talking'))
              }
              testId={TestId.ParticipantsGroupToggleTalking}
              wrapperClassName='mb-2 overflow-hidden'
            />
            <Checkbox
              checked={groups?.moderator ?? false}
              className='w-full'
              label={tr(Label.MODERATOR)}
              name={tr(Label.MODERATOR)}
              onChange={() =>
                dispatch(callViewActions.updateGroupsList('moderator'))
              }
              testId={TestId.ParticipantsGroupToggleModerator}
              wrapperClassName='overflow-hidden'
            />
          </div>
          <div>
            <HandsRaisedCheckbox checked={groups?.handRaised ?? false} />
            <GroupRolesAssignedCheckbox
              checked={groups?.reservationId ?? false}
            />
          </div>
        </div>
      </div>
      <div className='px-2 pt-2'>
        {groups?.talking ? (
          <ParticipantGroupTalking
            className='mb-2 h-48 resize-y'
            roomId={roomId}
          />
        ) : null}
        {groups?.moderator ? (
          <ParticipantGroupModerators
            className='mb-2 h-48 resize-y'
            roomId={roomId}
          />
        ) : null}
        {groups?.handRaised ? (
          <ParticipantGroupHandsRaised
            className='mb-2 h-48 resize-y'
            roomId={roomId}
          />
        ) : null}
        {groups?.reservationId ? (
          <ParticipantGroupRolesAssigned
            className='mb-2 h-48 resize-y'
            roomId={roomId}
          />
        ) : null}
      </div>
    </>
  )
}
