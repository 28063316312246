import { useState } from 'react'
import { RailsEndpoint, RequestState } from '../rest'
import { useInternalAPI } from './useInternalAPI'

type UseDeleteGroupData = {
  requestState: RequestState
  error: string | null
}

const deleteOptions = {
  endpoint: RailsEndpoint.Groups,
  method: 'DELETE',
}

export const useDeleteGroup = (groupId: string) => {
  const { invokeAPI: deleteHandler } = useInternalAPI(deleteOptions)
  const [data, setData] = useState<UseDeleteGroupData>({
    requestState: RequestState.Loading,
    error: null,
  })

  // delete is a private keyword in strict mode
  const performDelete = async () => {
    try {
      setData({
        requestState: RequestState.Loading,
        error: null,
      })
      await deleteHandler({
        urlTransform: (url) => `${url}/${groupId}`,
      })
      setData({
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      setData({
        requestState: RequestState.Error,
        error: 'There was an error deleting the group. Please retry.',
      })
    }
  }

  return { ...data, deleteGroup: performDelete }
}
