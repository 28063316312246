import React, { FC, useState, useEffect, useRef } from 'react'
import { tr, Label } from 'src/common/i18n'
import { PlusSquareIcon, TrashIcon } from 'src/common/icons'
import { UseFetchRoomConsumerProps } from 'src/common/hooks'
import { checkValidHttpURL } from 'src/common/services/helpers'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { useRoomAutoSave } from 'src/effects/useRoomAutoSave'
import { IconState } from 'src/components/IconState'

const RoomCustomButtons: FC<
  UseFetchRoomConsumerProps & { joinButton: string | null }
> = ({ joinButton, update, getErrorByName }) => {
  const { currentValue, freeformHandler, iconState } = useRoomAutoSave({
    defaultValue: joinButton,
    updateRoomHandler: update,
  })

  const customButtons = (currentValue as string)?.split('\n')
  // Start with one blank field if there are no custom buttons
  const [buttonsArray, setButtonsArray] = useState(customButtons ?? [''])
  const showingEmptyInput = buttonsArray.includes('')

  const changeHandler = ({
    index,
    newButtonName,
    newButtonLink,
  }: {
    index: number
    newButtonName: string
    newButtonLink: string
  }) => {
    if (newButtonName?.length && newButtonLink?.length) {
      const newButton = `${newButtonName}:${newButtonLink}`
      const buttonsArrayCopy = [...buttonsArray]
      buttonsArrayCopy.splice(index, 1, newButton)
      setButtonsArray(buttonsArrayCopy)
      freeformHandler('join_button', buttonsArrayCopy.join('\n'))
    }
  }

  const deleteHandler = (index: number) => {
    const buttonsArrayCopy = [...buttonsArray]
    buttonsArrayCopy.splice(index, 1)
    setButtonsArray(buttonsArrayCopy)
    freeformHandler('join_button', buttonsArrayCopy.join('\n'))
  }

  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.CUSTOM_BUTTONS)}
        <IconState state={iconState} />
      </div>
      {buttonsArray.map((button, i) => (
        <RoomCustomButtonsInputs
          buttonData={button}
          changeHandler={changeHandler}
          deleteHandler={deleteHandler}
          getErrorByName={getErrorByName}
          index={i}
          key={button}
        />
      ))}
      <span
        // "Disable" the link if we've already got a blank field showing
        className={`text-sw-red text-xs ${
          showingEmptyInput ? 'sw-disabled' : ''
        }`}
        onClick={() =>
          showingEmptyInput ? null : setButtonsArray([...buttonsArray, ''])
        }
        role='button'
      >
        <PlusSquareIcon fixedWidth className='rct-icon rct-icon-expand-all' />
        {tr(Label.ADD_BUTTON)}
      </span>
    </>
  )
}

const RoomCustomButtonsInputs = ({
  buttonData,
  changeHandler,
  deleteHandler,
  getErrorByName,
  index,
}: any) => {
  // buttonData example: 'Google:https://google.com'
  // This allows us to get everything after the first colon as the link
  const [buttonName, ...rest] = buttonData.split(':')
  const buttonLink = rest.join(':')

  // Fields state
  const [newButtonName, setNewButtonName] = useState(buttonName ?? '')
  const [newButtonLink, setNewButtonLink] = useState(buttonLink ?? '')

  // Error state
  const [nameError, setNameError] = useState('')
  const [linkError, setLinkError] = useState('')

  // Track runs
  const isFirstRunLink = useRef(true)
  const isFirstRunName = useRef(true)

  // OnBlur setting either field
  useEffect(() => {
    const hasName = newButtonName?.length > 0
    const hasLink = newButtonLink?.length > 0

    if (!isFirstRunLink.current) {
      hasLink ? setLinkError('') : setLinkError('Field cannot be blank.')
    }

    if (!isFirstRunName.current) {
      hasName ? setNameError('') : setNameError('Field cannot be blank.')
    }

    // Set an error if we have input but it's not a URL
    if (hasLink && !checkValidHttpURL(newButtonLink)) {
      setLinkError('Invalid URL')
      return
    }

    const isNewData = buttonData !== `${newButtonName}:${newButtonLink}`

    if (hasName && hasLink && isNewData) {
      changeHandler({ index, newButtonLink, newButtonName })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newButtonName, newButtonLink])

  return (
    <>
      <div className='grid grid-cols-12 gap-2'>
        <div className='col-span-3'>
          <InputGroup
            error={nameError ?? getErrorByName('join_button')}
            label={tr(Label.CUSTOM_BUTTON_NAME)}
            name={`room_custom_button_name-${index}`}
            onBlur={(e) => {
              isFirstRunName.current = false
              setNewButtonName(e.target.value)
            }}
            placeholder='Example'
            defaultValue={newButtonName}
          />
        </div>
        <div className='col-span-8'>
          <InputGroup
            error={linkError ?? getErrorByName('join_button')}
            label={tr(Label.CUSTOM_BUTTON_LINK)}
            name={`room_custom_button_link-${index}`}
            onBlur={(e) => {
              isFirstRunLink.current = false
              setNewButtonLink(e.target.value)
            }}
            placeholder='https://'
            type='url'
            defaultValue={newButtonLink}
          />
        </div>
        <div
          className='col-span-1 text-center py-8 cursor-pointer'
          onClick={() => deleteHandler(index)}
          role='button'
          title='Delete'
        >
          {buttonName && buttonLink && (
            <TrashIcon className='text-sw-red' fixedWidth />
          )}
        </div>
      </div>
    </>
  )
}

export default RoomCustomButtons
