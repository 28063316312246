import { createSelector } from 'reselect'
import { CantinaState, Room, RoomListRoom } from '../../interfaces'
import { RoomRole } from '../../../constants'
import { staticRoomImageUrl } from '../../../services/helpers'
import { hasHideParticipantEmailFeatureFlag } from '../settings/settingsSelectors'

const _roomId = (_: CantinaState, roomId: string) => roomId

/**
 * HOF to not duplicate the logic and
 * have type checking on Room keys
 */
const makeRoomKeySelector = <T extends keyof RoomListRoom>(key: T) => {
  return createSelector(getRoom, (room) => room?.[key])
}

/**
 * HOF as above but force the value to be a boolean
 */
const makeBooleanRoomKeySelector = (key: keyof RoomListRoom) => {
  return createSelector(getRoom, (room) => Boolean(room?.[key]))
}

export const getRoomIds = ({ roomList }: CantinaState) => {
  return roomList.ids
}

export const getRooms = ({ roomList }: CantinaState) => {
  return roomList.byId
}

export const roomExists = ({ roomList }: CantinaState, roomId: string) => {
  return roomId in roomList.byId
}

export const getRoom = createSelector(
  getRooms,
  _roomId,
  (roomList, roomId) => roomList[roomId] || null
)

export const getRoomName = makeRoomKeySelector('name')
export const getRoomPreview = createSelector(getRoom, (room) => {
  if (!room) {
    return null
  }
  if (room.running && room.preview) {
    return room.preview
  }
  return staticRoomImageUrl(<string>room.name)
})

export const getRoomDescription = makeRoomKeySelector('description')
export const getRoomUuid = makeRoomKeySelector('uuid')
export const getParticipantCount = makeRoomKeySelector('numMembers')

/**
 * Boolean Properties
 */
// export const hasMeetingMode = makeBooleanRoomKeySelector('meetingMode')

// TODO: getRoomBannerDisplayOption, isRunning, isRecording, isLocked are duplicated in roomsSelectors.ts. Consolidate?
export const getRoomBannerDisplayOption = createSelector(getRoom, (room) => {
  return room?.bannerDisplayOption
})
export const isRunning = makeBooleanRoomKeySelector('running')
export const isRecording = createSelector(
  isRunning,
  makeBooleanRoomKeySelector('recording'),
  (running, recording) => {
    return running && recording
  }
)
export const isLocked = createSelector(
  isRunning,
  makeBooleanRoomKeySelector('locked'),
  (running, locked) => {
    return running && locked
  }
)
const makeCustomImageSelector = (key: 'emptyImages' | 'previewImages') => {
  return createSelector(getRoom, (room) => {
    const images = room?.[key] ?? []
    if (!Array.isArray(images)) {
      return []
    }
    return images.map((url, index) => ({ id: `${key}-${index}`, src: url }))
  })
}

export const getRoomEmptyImages = makeCustomImageSelector('emptyImages')
export const getRoomPreviewImages = makeCustomImageSelector('previewImages')

export const getRoomCustomButtons = createSelector(getRoom, (room) => {
  return room?.joinButtons ?? []
})

export const getRoomMemberIds = (
  { roomList }: CantinaState,
  roomId: string
) => {
  return roomList?.memberIdsByRoomId?.[roomId] ?? []
}

const _canManageRoomHandler = (room: Room | null) => {
  if (room?.myRoles?.length) {
    return (
      room.myRoles.includes(RoomRole.Manager) ||
      room.myRoles.includes(RoomRole.Moderator) ||
      room.myRoles.includes(RoomRole.Configurator)
    )
  }
  return false
}

export const canManageRoom = createSelector(getRoom, _canManageRoomHandler)

/**
 * Create a selector that returns data
 * required by several UI components.
 */
export const makeRoomCardSelector = () => {
  return createSelector(getRoom, (room) => {
    return {
      roomName: room?.name ?? '',
      roomDescription: room?.description ?? '',
      running: Boolean(room?.running),
      canManage: Boolean(_canManageRoomHandler(room)),
    }
  })
}

/**
 * When the HideParticipantEmail flag is set, only moderators/managers can read the
 * participant subtitle.
 */
export const canSeeRoomParticipantSubtitle = createSelector(
  hasHideParticipantEmailFeatureFlag,
  canManageRoom,
  (hasFlag, canManage) => {
    return !hasFlag || (hasFlag && canManage)
  }
)
