import { SagaIterator } from 'redux-saga'
import {
  take,
  call,
  put,
  select,
  actionChannel,
  retry,
} from 'redux-saga/effects'
import { cantinaTimer } from '../../../services/cantinaTimer'
import {
  bootstrap,
  updateSettings,
  fetchSettings,
  BootstrapResponse,
  RailsEndpoint,
  Endpoint,
} from '../../../rest'
import { purgeAction, globalLoaderBeginAction } from '../../actions'
import { settingsActions, updateSettingsRequest } from '../'
import { getAuthToken, hasRoleAdministrator } from '../auth/authSelectors'
import {
  getCantinaBackendAPIEndpoint,
  getInternalAPIEndpoint,
} from '../settings/settingsSelectors'

const BOOTSTRAP_MAX_TRIES = 2
const BOOTSTRAP_DELAY_MS = 500

export function* watchBootstrapRequest(): SagaIterator {
  const {
    payload: { hostname },
  } = yield take(settingsActions.bootstrapRequest.type)
  const timer = cantinaTimer('cantina:bootstrap')

  try {
    timer.start()
    yield put(globalLoaderBeginAction())

    const baseUrl = yield select(
      getCantinaBackendAPIEndpoint,
      Endpoint.Bootstrap
    )

    const response: BootstrapResponse = yield retry(
      BOOTSTRAP_MAX_TRIES,
      BOOTSTRAP_DELAY_MS,
      bootstrap,
      { baseUrl, hostname }
    )
    const { domains = [] } = response
    if (!domains.includes(hostname)) {
      yield put(purgeAction())
      return
    }
    yield put(settingsActions.bootstrapSuccess(response))
  } catch (error) {
    yield put(settingsActions.bootstrapFailure())
    yield put(purgeAction())
  } finally {
    timer.stop()
  }
}

export function* fetchSettingsWorker(): SagaIterator {
  const isAdministrator = yield select(hasRoleAdministrator)
  if (!isAdministrator) {
    return
  }
  try {
    const baseUrl = yield select(getInternalAPIEndpoint, RailsEndpoint.Settings)
    const token = yield select(getAuthToken)
    const settings = yield call(fetchSettings, { baseUrl, token })
    yield put(settingsActions.updateSettingsSuccess(settings))
  } catch (error) {
    console.error('fetchSettings error', error)
  }
}

/**
 * This Saga uses actionChannel to buffer multiple actions
 * https://redux-saga.js.org/docs/advanced/Channels.html
 */
export function* watchUpdateSettingsRequests(): SagaIterator {
  const channel = yield actionChannel(updateSettingsRequest.type)
  while (true) {
    const { payload } = yield take(channel)
    const baseUrl = yield select(getInternalAPIEndpoint, RailsEndpoint.Settings)
    const updateData = {
      baseUrl,
      token: yield select(getAuthToken),
      params: payload,
    }
    const settings = yield call(updateSettings, updateData)
    yield put(settingsActions.updateSettingsSuccess(settings))
  }
}
