import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useInputChange } from 'src/effects/useInputChange'
import { RoomCommandAction } from 'src/common/constants'
import { roomCommand } from 'src/common/redux/features'
import { tr, Label } from 'src/common/i18n'
import { getActiveCallRoomId } from 'src/common/redux/features/calls/callSelectors'
import { TestId } from 'src/constants'
import SubmittableInput from 'src/components/Inputs/SubmittableInput'

interface TextToSpeechProps {
  className?: string
  participantId: string
}

export const TextToSpeech: FC<TextToSpeechProps> = ({
  className = '',
  participantId,
}) => {
  const [sending, setSending] = useState(false)
  const [values, setValues, bulkUpdate] = useInputChange({ tts: '' })

  const dispatch = useDispatch()
  const activeRoomId = useSelector(getActiveCallRoomId)

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { tts: ttsText } = values
    if (ttsText.trim() === '') {
      return
    }

    setSending(true)
    const action = roomCommand({
      roomId: activeRoomId,
      action: RoomCommandAction.SendMemberTTS,
      participantId,
      message: ttsText.trim(),
    })
    dispatch(action)
    setTimeout(() => {
      setSending(false)
      bulkUpdate({ tts: '' })
    }, 1000)
  }

  return (
    <SubmittableInput
      canHaveError={false}
      className={`py-2 ${className}`}
      currentValue={values.tts}
      disabled={sending}
      inputChanged={setValues}
      label={tr(Label.TEXT_TO_SPEECH_LABEL)}
      name='tts'
      placeholder='e.g. Hello'
      required={true}
      submitButtonLabel={tr(Label.SEND)}
      submitHandler={submitHandler}
      submitting={sending}
      testId={TestId.ParticipantInputTextToSpeech}
      type='text'
    />
  )
}
