// Uses ClipEEze API: https://github.com/signalwire/relay-apis/blob/master/cantina_backend/internal_clipeeze.md
import { cantinaFetch } from '.'
import { RestMethodParams } from './interfaces'
import { ClipeezeStatus } from '../constants'

const CLIPEEZE_PAGE_SIZE = 30

type SortColumn = 'date' | 'description'
type SortOrder = 'asc' | 'desc'

// Map parameter values to data columns
const sortColumnMap = new Map([
  ['date', 'created_at'],
  ['description', 'description'],
])

// Format of the upload details returned by the "new_upload" request
interface ClipeezeUploadDetails {
  id: string
  url: string
  fields: { [key: string]: string }
}

type FetchClipeezeListParams = {
  // Filter parameters
  description?: string
  pageSize?: number
  status?: ClipeezeStatus
  sortColumn?: SortColumn
  sortOrder?: SortOrder
}

const fetchClipeezeList = async ({
  baseUrl,
  description,
  pageSize,
  sortColumn,
  sortOrder,
  status,
  token,
}: RestMethodParams & FetchClipeezeListParams): Promise<any> => {
  let apiUrl = baseUrl

  // Only add params if there are none set on URL
  const parsedUrl = new URL(baseUrl)
  if (!parsedUrl.search?.length) {
    const params = new URLSearchParams()

    if (description) {
      params.set('description', description)
    }
    if (pageSize) {
      params.set('page_size', pageSize.toString())
    }
    if (status) {
      params.set('status', status)
    }

    // Default sort is description ascending
    if (sortColumn && sortColumnMap.has(sortColumn)) {
      params.set('sort_column', sortColumnMap.get(sortColumn) || '')
      if (sortOrder) {
        params.set('sort_direction', sortOrder)
      }
    }

    const paramString = `?${params.toString()}`
    apiUrl = `${parsedUrl.origin}${parsedUrl.pathname}${
      paramString.length > 1 ? paramString : ''
    }`
  }

  const clipeezeData = await cantinaFetch(apiUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
  return clipeezeData
}

// Get the info needed to upload to S3 and the ID to use when calling
// the Create ClipEEze endpoint
type FetchClipeezeUploadDetailsParams = {
  contentLength: number
  contentType: string
  filename: string
}

const fetchClipeezeUploadDetails = async ({
  baseUrl,
  token,
  contentLength,
  contentType,
  filename,
}: RestMethodParams & FetchClipeezeUploadDetailsParams): Promise<any> => {
  const data = {
    content_length: contentLength,
    content_type: contentType,
    filename,
  }

  return cantinaFetch(`${baseUrl}/new_upload`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
}

// Create a ClipEEze from a file uploaded to S3
type CreateClipeezeParams = {
  clipId: string
  contentLength: number
  contentType: string
  description: string
  filename: string
}

const createClipeeze = async ({
  baseUrl,
  token,
  clipId,
  contentType,
  contentLength,
  description,
  filename,
}: RestMethodParams & CreateClipeezeParams): Promise<any> => {
  const data = {
    content_type: contentType,
    content_length: contentLength,
    description,
    file_id: clipId,
    filename,
  }

  return cantinaFetch(baseUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
}

// Upload the ClipEEze file to S3
type UploadClipeezeParams = {
  url: string
  formData: FormData
}

const uploadClipeeze = async ({
  url,
  formData,
}: UploadClipeezeParams): Promise<any> => {
  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  })
  return response
}

export {
  CLIPEEZE_PAGE_SIZE,
  fetchClipeezeList,
  fetchClipeezeUploadDetails,
  createClipeeze,
  uploadClipeeze,
}
export type { ClipeezeUploadDetails, SortColumn, SortOrder }
