import React, { FC, ChangeEvent } from 'react'
import { tr, Label } from 'src/common/i18n'
import { RoomRole } from 'src/common/constants'
import { TimesCircleIcon } from 'src/common/icons'
import { AccessGrant } from 'src/common/rest/interfaces'
import { ButtonWithConfirmation } from 'src/components/Button/Button'
import { RolesDropdown } from './RolesDropdown'

type AccessGrantItemProps = {
  accessGrant: any
  updateGrantType: (params: {
    accessGrantId: string
    grantType: RoomRole
  }) => Promise<any>
  deleteAccessGrant: (accessGrant: AccessGrant) => Promise<any>
  readOnly: boolean
  roleType: 'room_role' | 'room_template_role'
}

export const AccessGrantItem: FC<AccessGrantItemProps> = ({
  accessGrant,
  children,
  updateGrantType,
  deleteAccessGrant,
  readOnly,
  roleType,
}) => {
  const { grant_type: accessGrantType, id: accessGrantId } = accessGrant
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    updateGrantType({
      accessGrantId,
      grantType: event.target.value as RoomRole,
    })
  }
  const removeHandler = () => {
    deleteAccessGrant(accessGrant)
  }
  const className = readOnly ? 'cursor-not-allowed' : ''

  return (
    <div className={`flex items-center justify-between py-2 ${className}`}>
      <div className='flex-1 truncate'>{children}</div>
      {readOnly ? (
        <p className='text-contrast-m capitalize whitespace-nowrap'>
          {accessGrantType}
        </p>
      ) : (
        <div className='flex items-center gap-4'>
          <ButtonWithConfirmation
            ariaLabel={tr(Label.REMOVE)}
            className='p-0 text-sw-red'
            onClick={removeHandler}
            singleUse
          >
            <TimesCircleIcon />
          </ButtonWithConfirmation>
          <RolesDropdown
            backendValue={accessGrantType}
            changeHandler={changeHandler}
            roleType={roleType}
          />
        </div>
      )}
    </div>
  )
}
