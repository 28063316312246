import { useState, useEffect } from 'react'
import { RailsEndpoint, RequestState, Room } from '../rest'
import { useInternalAPI } from './useInternalAPI'

type UseFetchRecordingsData = {
  rooms: Room[]
  requestState: RequestState
  error: string | null
}

const fetchOptions = {
  endpoint: RailsEndpoint.RecordingsRooms,
  method: 'GET',
}

// Fetch rooms that have recordings available
export const useFetchRecordings = () => {
  const { invokeAPI: fetchHandler } = useInternalAPI(fetchOptions)
  const [data, setData] = useState<UseFetchRecordingsData>({
    rooms: [],
    requestState: RequestState.Loading,
    error: null,
  })

  const refresh = async () => {
    try {
      if (data.requestState !== RequestState.Loading) {
        setData({
          ...data,
          requestState: RequestState.Loading,
        })
      }
      const { rooms } = await fetchHandler()
      setData({
        rooms,
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      setData({
        rooms: [],
        requestState: RequestState.Error,
        error: 'Error loading recordings',
      })
    }
  }

  useEffect(() => {
    refresh()
  }, [])

  return { ...data, refresh }
}
