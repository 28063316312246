import { PayloadAction } from '@reduxjs/toolkit'
import { SagaIterator } from 'redux-saga'
import { take, fork, call, select, put } from 'redux-saga/effects'
import {
  clipeezeActions,
  loadClipeezeRequest,
  loadClipeezeSuccess,
  loadClipeezeError,
  loadClipeezeFilteredRequest,
  loadClipeezeFilteredSuccess,
  loadClipeezeFilteredError,
} from './clipeezeSlice'
import {
  getClipeezeFilter,
  getClipeezes,
  getClipeezeNextUrl,
  getFilteredClipeezes,
  getFilteredClipeezeNextUrl,
} from './clipeezeSelectors'
import { getAuthToken } from '../auth/authSelectors'
import {
  CLIPEEZE_PAGE_SIZE,
  fetchClipeezeList,
  RailsEndpoint,
} from '../../../rest'
import { Clipeeze } from '../../interfaces'
import { ClipeezeStatus } from '../../../constants'
import {
  getInternalAPIEndpoint,
  getInternalAPIHost,
} from '../settings/settingsSelectors'

// prettier-ignore
export const ACTIONS: string[] = [
  loadClipeezeRequest.type,
  loadClipeezeFilteredRequest.type,
]

export function* clipeezeWorker({
  type,
  payload,
}: PayloadAction<any>): SagaIterator {
  try {
    switch (type) {
      // Actions ending with 'Request' add an entry to the loading slice
      case loadClipeezeRequest.type:
      case loadClipeezeFilteredRequest.type: {
        const initialUrl = yield select(
          getInternalAPIEndpoint,
          RailsEndpoint.Clipeeze
        )
        const authToken = yield select(getAuthToken)

        // Decide which actions/list to use, filtered or full list
        const isFiltered = type === loadClipeezeFilteredRequest.type
        const clipeezeList = yield select(
          isFiltered ? getFilteredClipeezes : getClipeezes
        )
        const successAction = isFiltered
          ? loadClipeezeFilteredSuccess
          : loadClipeezeSuccess
        const errorAction = isFiltered
          ? loadClipeezeFilteredError
          : loadClipeezeError

        let url = initialUrl
        if (clipeezeList.length) {
          // If we already have at least one page of data, use the 'next' URL
          // If the 'next' URL is empty, we have reached the end of the list
          const apiHost = yield select(getInternalAPIHost)
          const relativeUrl = yield select(
            isFiltered ? getFilteredClipeezeNextUrl : getClipeezeNextUrl
          )

          url = ''
          if (relativeUrl) {
            url = `${apiHost}${relativeUrl}`
          }
        }

        if (url) {
          const filter = isFiltered ? yield select(getClipeezeFilter) : ''
          const response = yield call(fetchClipeezeList, {
            baseUrl: url,
            description: filter,
            pageSize: CLIPEEZE_PAGE_SIZE,
            status: ClipeezeStatus.Active,
            token: authToken,
          })

          if (Array.isArray(response?.clipeeze)) {
            if (response?.clipeeze.length) {
              // Add the ClipEEze to the store
              const insertAction = isFiltered
                ? clipeezeActions.addListFiltered
                : clipeezeActions.addList

              const byId: { [index: string]: Clipeeze } = {}
              response?.clipeeze.forEach((clip: Clipeeze) => {
                byId[clip.id] = clip
              })
              yield put(
                insertAction({
                  byId,
                  ids: Object.keys(byId),
                  links: response.links,
                })
              )
            }
            // Update loading slice
            yield put(successAction())
          } else {
            // Update loading slice
            yield put(errorAction())
            console.error('loadClipeezeRequest error', response)
          }
        }
        break
      }
      default:
        console.warn('Unhandled clipeezeWorker action', type, payload)
    }
  } catch (error) {
    console.error('clipeezeWorker error', error)
  }
}

export function* watchClipeeze(actions: string[] = ACTIONS): SagaIterator {
  if (!actions.length) {
    return
  }
  while (true) {
    const action = yield take(actions)
    yield fork(clipeezeWorker, action)
  }
}
