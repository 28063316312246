import React, { FC, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { callViewActions } from 'src/common/redux/views'
import {
  getRoomNavigatorFilter,
  hasExactRoomNameMatch,
} from 'src/common/redux/views/callView/callViewSelectors'
import { tr, Label } from 'src/common/i18n'
import { stripFlags } from 'src/common/services/helpers'
import { TestId } from 'src/constants'
import { useHistoryPushToRoom } from 'src/effects/useHistoryPushToRoom'
import { SecondaryButton } from '../Button/Button'
import { MakeRoomButton } from 'src/components/Rooms/MakeRoomButton'
import {
  TransferList,
  TransferListButton,
  TransferActions,
} from './TransferList'

const NoFilteredRooms: FC<{ filter: string }> = ({ filter }) => {
  const dispatch = useDispatch()
  const { pushHandler } = useHistoryPushToRoom()
  const [transferListOpened, setTransferListOpened] = useState(false)
  const strippedFilter = stripFlags(filter)

  const clearFilter = () => {
    dispatch(callViewActions.clearRoomNavigatorFilter())
  }

  const makeRoomHandler = () => {
    pushHandler({ roomName: filter, force: true })
    clearFilter()
  }

  if (transferListOpened) {
    return (
      <div className='room-nav-box'>
        <TransferList />
        <TransferActions
          extension={filter}
          onCancel={() => setTransferListOpened(false)}
        />
      </div>
    )
  }

  if (strippedFilter?.length) {
    return (
      <div className='room-nav-box flex flex-col items-center w-full px-4 py-2 mb-3 text-center'>
        <div className='text-2xl'>&quot;{strippedFilter}&quot;</div>
        <div className='text-base mt-1 text-contrast-m'>
          {tr(Label.WAS_NOT_FOUND)}
        </div>
        <div className='flex justify-evenly w-full mt-3 text-sm gap-1'>
          <SecondaryButton
            className='text-xs py-1'
            onClick={clearFilter}
            testId={TestId.RoomNavigatorButtonCancel}
          >
            {tr(Label.CANCEL)}
          </SecondaryButton>
          <TransferListButton
            onOpen={() => setTransferListOpened(true)}
            onClose={() => setTransferListOpened(false)}
            testId={TestId.RoomNavigatorButtonTransfer}
          />
          <MakeRoomButton
            clickHandler={makeRoomHandler}
            className='text-xs py-1 px-3'
            testId={TestId.RoomNavigatorButtonJoin}
          />
        </div>
      </div>
    )
  }

  return null
}

export const RoomFilterResults: FC = () => {
  const filter: string = useSelector(getRoomNavigatorFilter)
  const hasExactMatch = useSelector(hasExactRoomNameMatch)

  if (filter && !hasExactMatch) {
    return <NoFilteredRooms filter={filter} />
  }

  if (!filter || hasExactMatch) {
    return null
  }

  return (
    <div className='flex flex-col items-center w-full p-4 text-center'>
      <div className='text-base text-contrast-m'>
        {tr(Label.NO_ROOMS_IN_USE)}
      </div>
    </div>
  )
}
