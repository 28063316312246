"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStreamsFromStats = exports.getTransportFromStats = void 0;
var getTransportFromStats = function (id, reports) {
    if (!reports) {
        return null;
    }
    var reportKeys = Object.keys(reports);
    var keyPairs = reportKeys.filter(function (reportKey) { return reports[reportKey].type === "candidate-pair" && reports[reportKey].nominated; });
    if (keyPairs.length > 0) {
        var _a = reports[keyPairs[0]], localCandidateId_1 = _a.localCandidateId, remoteCandidateId_1 = _a.remoteCandidateId;
        var localCandidateKey = reportKeys.find(function (reportKey) { return reports[reportKey].type === "local-candidate" && reports[reportKey].id === localCandidateId_1; });
        var remoteCandidateKey = reportKeys.find(function (reportKey) { return reports[reportKey].type === "remote-candidate" && reports[reportKey].id === remoteCandidateId_1; });
        if (localCandidateKey && remoteCandidateKey) {
            var localCandidate = reports[localCandidateKey];
            var remoteCandidate = reports[remoteCandidateKey];
            var peer = {};
            peer[id] = {
                connection: localCandidate.candidateType === "relay" ? "relay" : "direct",
                transport: localCandidate.candidateType === "relay" ? localCandidate.relayProtocol : "udp",
                address: "".concat(remoteCandidate.address, ":").concat(remoteCandidate.port),
            };
            return peer;
        }
    }
    return null;
};
exports.getTransportFromStats = getTransportFromStats;
var getStreamsFromStats = function (id, previous, current) {
    var streams = {};
    var currentReportsKeys = Object.keys(current);
    var streamsKeys = currentReportsKeys.filter(function (keys) { return current[keys].type === "inbound-rtp" || current[keys].type === "outbound-rtp"; });
    streamsKeys.forEach(function (streamKey) {
        var currentReport = current[streamKey];
        var previousReport = previous && streamKey in previous ? previous[streamKey] : null;
        var direction = currentReport.type === "inbound-rtp" ? "inbound" : "outbound";
        var kind = currentReport.kind;
        var currentRemoteReport = currentReport.remoteId in current ? current[currentReport.remoteId] : null;
        var previousRemoteReport = previousReport && previousReport.remoteId in previous ? previous[previousReport.remoteId] : null;
        var deltaSeconds = previousReport ? (currentReport.timestamp - previousReport.timestamp) / 1000 : null;
        var stream = {
            direction: direction,
            kind: kind,
            peerId: id,
        };
        // Specific video (in inbound, outbound
        if (kind === "video") {
            stream.frameWidth = currentReport.frameWidth;
            stream.frameHeight = currentReport.frameHeight;
            stream.framerate = currentReport.framesPerSecond;
        }
        if (currentReport.type === "inbound-rtp") {
            stream.jitter = currentReport.jitter;
            // Compute intermediate values if previous report exists
            stream.bytesReceived = previousReport ? currentReport.bytesReceived - previousReport.bytesReceived : null;
            stream.packetsReceived = previousReport ? currentReport.packetsReceived - previousReport.packetsReceived : null;
            stream.packetsLost = previousReport ? currentReport.packetsLost - previousReport.packetsLost : null;
            stream.packetsLoss =
                stream.packetsLost + stream.packetsReceived > 0
                    ? (stream.packetsLost * 100) / (stream.packetsLost + stream.packetsReceived)
                    : null;
            stream.bitrate = deltaSeconds ? (stream.bytesReceived * 8) / deltaSeconds : null;
            // Compute intermediate stats from remoteId (remoteOutbound);
            stream.roundTripTime =
                currentRemoteReport && "roundTripTime" in currentRemoteReport ? currentRemoteReport.roundTripTime : null;
        }
        else {
            // Compute intermediate values if previous report exists
            stream.bytesSent = previousReport ? currentReport.bytesSent - previousReport.bytesSent : null;
            stream.packetsSent = previousReport ? currentReport.packetsSent - previousReport.packetsSent : null;
            stream.bitrate = deltaSeconds ? (stream.bytesSent * 8) / deltaSeconds : null;
            // Compute intermediate stats from remoteId (remoteInbound)
            stream.roundTripTime =
                currentRemoteReport && "roundTripTime" in currentRemoteReport ? currentRemoteReport.roundTripTime : null;
            stream.jitter = currentRemoteReport && "jitter" in currentRemoteReport ? currentRemoteReport.jitter : null;
            stream.packetsLost =
                currentRemoteReport && previousRemoteReport
                    ? currentRemoteReport.packetsLost - previousRemoteReport.packetsLost
                    : null;
            stream.packetsLoss =
                currentRemoteReport && "fractionLost" in currentRemoteReport ? currentRemoteReport.fractionLost : null;
        }
        streams[current[streamKey].id] = stream;
    });
    return streams;
};
exports.getStreamsFromStats = getStreamsFromStats;
