import React, { FC, useState, useCallback, ComponentType } from 'react'
import { isElementTruncated } from '../services/Helpers'
import { withTextTooltip, TOOLTIP_OFFSET_Y_ROOM_CARD } from './withTextTooltip'

// If the component contents have been truncated,
//  wrap them with a tooltip containing the title
interface WrappedComponentProps {
  title: string
  offsetY?: number
  [x: string]: any
}
export const withTruncationTooltip = (
  WrappedComponent: ComponentType<WrappedComponentProps>
) => {
  return (props: WrappedComponentProps) => {
    const [truncated, setTruncated] = useState(false)

    const truncatedRef = useCallback((node: HTMLElement) => {
      if (node !== null) {
        setTruncated(isElementTruncated(node))
      }
    }, [])

    const WrappedWithTooltip: FC<WrappedComponentProps> = (props) => {
      return withTextTooltip({
        title: props.title,
        offsetY: props.offsetY || TOOLTIP_OFFSET_Y_ROOM_CARD,
      })(WrappedComponent)(props)
    }

    return truncated ? (
      <WrappedWithTooltip {...props} />
    ) : (
      <WrappedComponent truncatedRef={truncatedRef} {...props} />
    )
  }
}

const Title = ({
  className,
  title,
  truncatedRef,
}: {
  className?: string
  title: string
  truncatedRef?: any
}) => {
  return (
    <div ref={truncatedRef} className={className}>
      {title}
    </div>
  )
}

export const TitleWithTruncationTooltip: FC<WrappedComponentProps> = withTruncationTooltip(
  Title
)
