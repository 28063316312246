import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChatState } from '../../interfaces'
import { roomJoinedAction } from '../../features/rooms/roomsSlice'
import { callActions } from '../'

export const initialChatState: ChatState = Object.freeze({
  active: null,
  byId: {},
})

const chatSlice = createSlice({
  name: 'chats',
  initialState: initialChatState,
  reducers: {
    setLastViewedId: (
      state,
      { payload }: PayloadAction<{ chatId: string; messageId: string }>
    ) => {
      if (payload.chatId in state.byId) {
        state.byId[payload.chatId].lastReadId = payload.messageId
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(roomJoinedAction, (state, { payload }) => {
        const { roomId } = payload
        if (!roomId) {
          return state
        }
        if (!(roomId in state.byId)) {
          state.byId[roomId] = {
            id: roomId,
            roomId,
          }
        }
        state.active = roomId

        // Remove previous chats across transfers
        const chatIds = Object.keys(state.byId)
        chatIds.forEach((id) => {
          if (id !== roomId) {
            delete state.byId[id]
          }
        })
      })
      .addCase(callActions.destroy, () => initialChatState)
  },
})

// prettier-ignore
export const {
  actions: chatActions,
  reducer: chatReducer
} = chatSlice
