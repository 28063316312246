import React, { FC, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Draggable from 'react-draggable'
import { CloseIcon } from 'src/common/icons'
import { tr, Label } from 'src/common/i18n'
import { FloaterType } from 'src/common/constants'
import { floatersActions } from 'src/common/redux/features'
import {
  getFloater,
  getOpenFloatersCount,
} from 'src/common/redux/features/floaters/floatersSelectors'
import { CantinaState } from 'src/common/redux/interfaces'
import { TestId } from 'src/constants'

type FloaterProps = {
  className?: string
  children: ReactNode
  id: string
  index: number
  testId: TestId
  type: FloaterType
}

const FloaterData = {
  [FloaterType.PlaybackControls]: {
    label: Label.MEDIA,
    maxWidth: 500,
    maxHeight: 267,
  },
  [FloaterType.ParticipantOptions]: {
    label: Label.PARTICIPANT_OPTIONS,
    maxWidth: 600,
    maxHeight: 525,
  },
}

export const Floater: FC<FloaterProps> = ({
  className = '',
  children,
  id,
  index,
  testId,
  type,
}) => {
  const dispatch = useDispatch()
  const floaterObject = useSelector((state: CantinaState) =>
    getFloater(state, id)
  )
  const numOpenFloaters: number = useSelector(getOpenFloatersCount)
  const mcu = document.querySelector('.call-mcu-area')
  if (!mcu) {
    return null
  }
  const rect = mcu?.getBoundingClientRect()
  const right = rect?.right - window.scrollX
  const left = rect?.left - window.scrollX
  const bottom = rect?.bottom - window.scrollY
  const top = rect?.top - window.scrollY
  const { label, maxWidth, maxHeight } = FloaterData[type]
  let x = floaterObject?.coords?.left
  let y = floaterObject?.coords?.top

  if (!floaterObject) {
    return null
  }

  // We are always positioning the floater down and to the right of the click,
  // so if there isn't enough room for it, reposition it
  const FLOATER_MARGIN = 10
  const VERTICAL_STACK_MARGIN = 25

  switch (true) {
    // Not wide enough to show the participant options, so place
    // near the top and css will make it full width. Only one floater
    // is likely to ever be open in this case because the floater
    // will cover most of the screen.
    case window.innerWidth < maxWidth:
      x = 0
      y = numOpenFloaters * VERTICAL_STACK_MARGIN
      break
    // Too close to left, bottom, and top
    case left + maxWidth > x && bottom - y < maxHeight:
      if (y < maxHeight) {
        x = right - maxWidth - numOpenFloaters * FLOATER_MARGIN
        y = top + numOpenFloaters * VERTICAL_STACK_MARGIN
        break
      }
      // Too close to left and bottom
      x += FLOATER_MARGIN
      y = y - maxHeight - FLOATER_MARGIN
      break
    // Too close to right, bottom and top
    case right - x < maxWidth && bottom - y < maxHeight:
      if (y - top < maxHeight) {
        x = right - maxWidth - numOpenFloaters * FLOATER_MARGIN
        y = top + numOpenFloaters * VERTICAL_STACK_MARGIN
        break
      }
      // Too close to right and bottom
      x = right - maxWidth - FLOATER_MARGIN
      y = y - maxHeight - VERTICAL_STACK_MARGIN
      break
    // Too close to the right
    case right - x < maxWidth:
      x = right - maxWidth - FLOATER_MARGIN
      break
    // Too close to the bottom
    case bottom - y < maxHeight:
      y = bottom - maxHeight - FLOATER_MARGIN
      break
    // Too close to the top
    case y < maxHeight:
      y = top + FLOATER_MARGIN
      break
  }

  // Override a landscape tablet (short browser)
  if (rect.height < maxHeight) {
    y = numOpenFloaters * VERTICAL_STACK_MARGIN
  }

  return (
    <Draggable
      bounds='#root'
      defaultPosition={{ x, y }}
      handle='.handle'
      onMouseDown={(e: MouseEvent) => {
        const id = `${(e.target as Element).closest('section')?.dataset?.id}`
        dispatch(floatersActions.reOrder({ id }))
      }}
    >
      <section
        className={`block-secondary rounded-md absolute shadow-2xl floater ${className}`}
        data-test={`${testId}_${id}`}
        data-id={id}
        style={{ zIndex: 50 - index }}
      >
        <div className='flex shrink-0 justify-between border-b border-contrast-l px-4 py-3 mb-2'>
          <div className='cursor-move handle flex-1 font-bold leading-7'>
            {tr(label)}
          </div>
          <button
            aria-label={tr(Label.CLOSE)}
            className='sw-btn-close'
            data-test={`${TestId.FloaterClose}_${id}`}
            onClick={() =>
              dispatch(floatersActions.removeFloater({ id, type }))
            }
          >
            <CloseIcon size='lg' />
          </button>
        </div>
        {children}
      </section>
    </Draggable>
  )
}
