import React, { FC, useState, useRef } from 'react'
import { useCombobox, useMultipleSelection } from 'downshift'
import { SearchIcon } from 'src/common/icons'
import { tr, Label } from 'src/common/i18n'
import { TestId } from 'src/constants'

export type AutoCompleteItemType = {
  id: string
  searchableLabel: string
  type: string
  // This key/value to build the props of the children components
  [key: string]: any
}

type AutoCompleteProps = {
  getItemComponent: (type: string) => FC<any>
  inputLabel?: Label
  inputPlaceholder?: Label
  items: AutoCompleteItemType[]
  selectedItems: AutoCompleteItemType[]
  selectedItemsChangeHandler: (items: AutoCompleteItemType[]) => void
  testId: TestId
}

export const AutoComplete: FC<AutoCompleteProps> = ({
  getItemComponent,
  inputLabel = Label.SELECT_ITEMS,
  inputPlaceholder = Label.SEARCH,
  items,
  selectedItems = [],
  selectedItemsChangeHandler,
  testId,
}) => {
  const initialItemsLength = useRef(items.length)
  const [inputValue, setInputValue] = useState('')

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
  } = useMultipleSelection({
    selectedItems,
    onStateChange: (changes) => {
      switch (changes.type) {
        case useMultipleSelection.stateChangeTypes.FunctionAddSelectedItem:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          if (changes.selectedItems) {
            selectedItemsChangeHandler(changes.selectedItems)
          }
          break
      }
    },
  })

  const filteredItems = items.filter((item) => {
    return (
      !selectedItems.some(({ id }) => id === item.id) &&
      item.searchableLabel.includes(inputValue.toLowerCase())
    )
  })
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    openMenu,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    inputValue,
    // defaultIsOpen: true,
    // isOpen: true,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    items: filteredItems,
    itemToString: (item) => (item ? item.id : ''),
    /** stop use `stateReducer` to close the menu after selection */
    // stateReducer: (state, actionAndChanges) => {
    //   const { changes, type } = actionAndChanges
    //   switch (type) {
    //     case useCombobox.stateChangeTypes.InputKeyDownEnter:
    //     case useCombobox.stateChangeTypes.ItemClick:
    //       return {
    //         ...changes,
    //         // isOpen: true, // keep the menu open after selection.
    //       }
    //   }
    //   return changes
    // },
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue ?? '')
          break
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue('')
            addSelectedItem(selectedItem)
          }
          break
      }
    },
  })

  const inputFocusHandler = () => {
    if (!isOpen) {
      openMenu()
    }
  }

  return (
    <div className='w-full relative' data-test={testId}>
      <div className='flex items-center justify-between text-contrast-m text-sm'>
        <p>{tr(inputLabel)}</p>
        <p data-test={TestId.AutoCompleteCounter}>
          {selectedItems?.length || 0} {tr(Label.OF)}{' '}
          {initialItemsLength.current || 0}
        </p>
      </div>
      <div className='block-secondary p-3 relative h-64 mb-4 overflow-hidden'>
        <div className='flex items-center justify-start'>
          <div className='relative w-full inline-block' {...getComboboxProps()}>
            <div className='absolute inset-y-0 left-0 pl-1 flex items-center pointer-events-none'>
              <SearchIcon className='self-center mx-2 cursor-pointer' />
            </div>
            <input
              {...getInputProps(
                getDropdownProps({
                  preventKeyAction: isOpen,
                  onFocus: inputFocusHandler,
                })
              )}
              className='sw-input w-full pl-8'
              placeholder={tr(inputPlaceholder)}
            />
          </div>
        </div>
        <ul
          {...getMenuProps()}
          className='block-primary absolute overflow-y-auto m-0 p-0 z-10 border-t-0 list-none divide-y divide-gray-200'
          style={{ maxHeight: '150px', right: '0.75rem', left: '0.75rem' }}
        >
          {isOpen && !filteredItems.length && (
            <li className='p-2 text-center'>{tr(Label.NO_RESULTS)}</li>
          )}
          {isOpen &&
            filteredItems.map((item, index) => {
              const Component = getItemComponent(item.type)
              const className =
                highlightedIndex === index ? 'block-highlight' : ''
              return (
                <li
                  key={`${item.id}${index}`}
                  className={`p-2 flex items-center ${className}`}
                  data-test={`${testId}_item_${index}`}
                  {...getItemProps({ item, index })}
                >
                  <Component {...item} />
                </li>
              )
            })}
        </ul>

        <div className='grid grid-cols-2 content-start gap-3 overflow-auto h-full py-2 my-2'>
          {selectedItems.map((selectedItem, index) => {
            const Component = getItemComponent(selectedItem.type)
            return (
              <div
                key={selectedItem.id}
                className='block-primary-tint w-full flex items-center justify-start rounded-lg p-2 text-sm font-medium h-10'
                data-test={`${testId}_item_selected_${index}`}
                {...getSelectedItemProps({ selectedItem, index })}
              >
                <div className='truncate'>
                  <Component {...selectedItem} />
                </div>
                <button
                  type='button'
                  onClick={(e) => {
                    e.stopPropagation()
                    removeSelectedItem(selectedItem)
                  }}
                  className='shrink-0 h-4 w-4 rounded-full inline-flex items-center justify-center ml-auto hover:bg-sw-red hover:text-sw-white'
                >
                  <svg
                    className='h-2 w-2'
                    stroke='currentColor'
                    fill='none'
                    viewBox='0 0 8 8'
                  >
                    <path
                      strokeLinecap='round'
                      strokeWidth='1.5'
                      d='M1 1l6 6m0-6L1 7'
                    />
                  </svg>
                </button>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
