import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useDispatchRoomParticipantCommand } from 'src/common/hooks'
import { CantinaState } from 'src/common/redux/interfaces'
import { RoomCommandAction } from 'src/common/constants'
import { Label } from 'src/common/i18n'
import {
  hasEchoCancellation,
  hasNoiseSuppression,
  hasAutoGainControl,
} from 'src/common/redux/features/participants/participantSelectors'
import { withConferenceAudioFlagsScope } from 'src/common/hoc'
import {
  SignalStreamIcon,
  VolumeUpIcon,
  WaveSquareIcon,
} from 'src/common/icons'
import { TestId } from 'src/constants'
import { ButtonFeatureToggle } from 'src/components/Inputs/ButtonFeatureToggle'

type AudioConstraintsTogglesProps = {
  participantId: string
}

export const AudioConstraintsToggles: FC<AudioConstraintsTogglesProps> =
  withConferenceAudioFlagsScope(({ participantId }) => {
    const dispatchRoomParticipantCommand =
      useDispatchRoomParticipantCommand(participantId)

    const echoCancellation = useSelector((state: CantinaState) =>
      hasEchoCancellation(state, participantId)
    )
    const noiseSuppression = useSelector((state: CantinaState) =>
      hasNoiseSuppression(state, participantId)
    )
    const autoGainControl = useSelector((state: CantinaState) =>
      hasAutoGainControl(state, participantId)
    )

    return (
      <>
        <ButtonFeatureToggle
          checked={echoCancellation}
          Icon={SignalStreamIcon}
          label={Label.ECHO_CANCELLATION}
          onToggleOn={() => {
            dispatchRoomParticipantCommand({
              action: RoomCommandAction.EnableEchoCancellation,
              participantId,
            })
          }}
          onToggleOff={() => {
            dispatchRoomParticipantCommand({
              action: RoomCommandAction.DisableEchoCancellation,
              participantId,
            })
          }}
          testId={TestId.AudioToggleEchoCancellation}
        />
        <ButtonFeatureToggle
          checked={noiseSuppression}
          Icon={WaveSquareIcon}
          label={Label.NOISE_SUPPRESSION}
          onToggleOn={() => {
            dispatchRoomParticipantCommand({
              action: RoomCommandAction.EnableNoiseSuppression,
              participantId,
            })
          }}
          onToggleOff={() => {
            dispatchRoomParticipantCommand({
              action: RoomCommandAction.DisableNoiseSuppression,
              participantId,
            })
          }}
          testId={TestId.AudioToggleNoiseSuppression}
        />
        <ButtonFeatureToggle
          checked={autoGainControl}
          Icon={VolumeUpIcon}
          label={Label.AUTO_GAIN_CONTROL}
          onToggleOn={() => {
            dispatchRoomParticipantCommand({
              action: RoomCommandAction.EnableAutoGainControl,
              participantId,
            })
          }}
          onToggleOff={() => {
            dispatchRoomParticipantCommand({
              action: RoomCommandAction.DisableAutoGainControl,
              participantId,
            })
          }}
          testId={TestId.AudioToggleAutoGain}
        />
      </>
    )
  })
