import React, { FC } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import {
  getFilteredSortedRoomIds,
  getPinnedRoomIds,
  getRoomFilter,
  getUnpinnedRoomIds,
  hasVisibleRooms,
} from 'src/common/redux/views/lobbyView/lobbyViewSelectors'
import { TestId } from 'src/constants'
import { RoomCard } from './RoomCard'
import { NoRooms } from './NoRooms'

export const RoomList: FC = () => {
  const showRooms = useSelector(hasVisibleRooms)

  return showRooms ? <LobbyGridList /> : <NoRooms />
}

const LobbyGridList: FC = () => {
  const isFiltered = Boolean(useSelector(getRoomFilter))

  return (
    <div
      data-test={TestId.LobbyGridViewContainer}
      className='w-full max-w-screen-xl mx-auto mt-0 sm:mt-8 mb-4'
    >
      {isFiltered ? (
        <div
          data-test={TestId.LobbyGridViewContainer}
          className='grid gap-4 md:grid-cols-2 lg:grid-cols-3 mt-4'
        >
          <RenderRooms
            selector={getFilteredSortedRoomIds}
            testIdPrefix={TestId.HomeRoomGridFiltered}
          />
        </div>
      ) : (
        <>
          <div className='grid md:grid-cols-2 lg:grid-cols-lobby lg:justify-center gap-4 lobby-pinned-rooms'>
            <RenderRooms
              selector={getPinnedRoomIds}
              testIdPrefix={TestId.HomeRoomGridPinned}
            />
          </div>
          <div
            data-test={TestId.LobbyGridViewContainer}
            className='grid gap-4 md:grid-cols-2 lg:grid-cols-3 mt-4'
          >
            <RenderRooms
              selector={getUnpinnedRoomIds}
              testIdPrefix={TestId.HomeRoomGridUnpinned}
            />
          </div>
        </>
      )}
    </div>
  )
}

const RenderRooms = ({ selector, testIdPrefix }: any) => {
  const roomIdList: string[] = useSelector(selector, shallowEqual)
  return (
    <>
      {roomIdList.map((roomId, index) => {
        return (
          <RoomCard
            key={roomId}
            joinButtonTestId={`${testIdPrefix}_live_button_room_${index}`}
            roomId={roomId}
          />
        )
      })}
    </>
  )
}
