import { useState } from 'react'
import { RailsEndpoint, RequestState, User, FetchError } from '../rest'
import { useInternalAPI } from './useInternalAPI'

// type CreateParams = Omit<CreateGroupMethodParams, 'cantinaId' | 'token'>
type useCreateUserData = {
  user: User | null
  requestState: RequestState
  errors: {
    [key: string]: string[]
  }
}

const fetchOptions = {
  endpoint: RailsEndpoint.Users,
  method: 'POST',
}

export const useCreateUser = () => {
  const { invokeAPI: createHandler } = useInternalAPI(fetchOptions)
  const [data, setData] = useState<useCreateUserData>({
    user: null,
    requestState: RequestState.Loading,
    errors: {},
  })

  const create = async (params: any) => {
    try {
      if (data.requestState !== RequestState.Loading) {
        setData({
          ...data,
          requestState: RequestState.Loading,
          errors: {},
        })
      }
      const user = await createHandler({
        body: JSON.stringify(params),
      })
      setData({
        user,
        requestState: RequestState.Success,
        errors: {},
      })
    } catch (error) {
      const handlerError = <FetchError>error
      setData({
        ...data,
        requestState: RequestState.Error,
        errors: handlerError?.body?.errors || {},
      })
    }
  }

  const getError = (key: string) => data.errors[key]?.join()
  const isInvalid = () => Object.keys(data.errors).length > 0

  return { ...data, create, getError, isInvalid }
}
