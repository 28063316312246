export * from './scopes'
export * from './roomCommandActions'
export * from './roomListCommandActions'
export * from './routes'

export enum Color {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Info = 'info',
  Light = 'light',
  Medium = 'medium',
  Dark = 'dark',
}

export enum SocketStatus {
  Connecting = 'connecting',
  Ready = 'ready',
  Error = 'error',
  Closed = 'closed',
}

export enum CallRole {
  Participant = 'participant',
  Moderator = 'moderator',
}

export enum CallError {
  IncompatibleDestination = 'INCOMPATIBLE_DESTINATION',
  IceGatheringFailed = 'ICE_GATHERING_FAILED',
  DeviceError = 'DEVICE_ERROR',
}

export const VALID_DTMF = '0123456789*#'.split('')

export enum Displayable {
  AboutModal = 'AboutModal',
  AppMenu = 'AppMenu',
  DevicesModal = 'DevicesModal',
  DialpadModal = 'DialpadModal',
  Knocking = 'Knocking',
  MediaModal = 'MediaModal',
  ProfileModal = 'ProfileModal',
  SecondSourceModal = 'SecondSourceModal',
  SettingsModal = 'SettingsModal',
  SidebarEditModal = 'SidebarEditModal',
  SidebarInvitationModal = 'SidebarInvitationModal',
  SidebarRequestToJoinModal = 'SidebarRequestToJoinModal',
  SidebarStartModal = 'SidebarStartModal',
  ThemesModal = 'ThemesModal',
}

export enum Theme {
  Light = 'light',
  Dark = 'dark',
  Default = 'default',
}

export enum SoundFile {
  Ringer = 'ringer',
  Knock = 'knock',
}

export enum Loader {
  Global = 'cantina/load',
  Call = 'cantina/call',
  Bootstrap = 'settings/bootstrap',
}

export enum MediaDevice {
  None = 'swNone',
  Default = 'swDefault',
}

export enum Devices {
  Camera = 'camera',
  Microphone = 'microphone',
  Speaker = 'speaker',
}

export const FAKE_DEVICES = [MediaDevice.Default, MediaDevice.None]

export enum ToastPosition {
  TOP,
  TOP_RIGHT,
  TOP_LEFT,
  BOTTOM,
  BOTTOM_RIGHT,
  BOTTOM_LEFT,
}

export type ToastProps = {
  header?: string
  message?: string
  link?: string
  linkText?: string
  linkTarget?: string
  Icon?: object
  color?: Color
  autoClose?: number | false
  position?: ToastPosition
  className?: string
}

export enum RoomType {
  Live = 'live',
  Static = 'static',
  Current = 'current',
  // permanent / adhoc are coming from Rails
  Permanent = 'permanent',
  Adhoc = 'adhoc',
}

export enum SeekDirection {
  Forward = '+',
  Rewind = '-',
}

export const CANTINA_HELP_URL = 'https://cantina.crisp.help/en/'
export const CURRENT_HOSTNAME = process.env.REACT_APP_FORCE_HOSTNAME
  ? process.env.REACT_APP_FORCE_HOSTNAME
  : window.location.hostname

export enum MixPanelEvent {
  ScreenShare = 'screenShare',
  CallFinished = 'userCallFinished',
  Clipeeze = 'clipeezeShare',
  NetworkDegradation = 'networkDegradation',
  NetworkRestored = 'networkRestored',
  SigninNotificationAgree = 'signinNotificationAgree',
  ParticipantListAdvancedToggle = 'participantListAdvancedToggle',
  ClickHomeLogo = 'clickHomeLogo',
  EnablePushToTalk = 'enablePushToTalk',
  DisablePushToTalk = 'disablePushToTalk',
}

export enum MixPanelUserType {
  SuperUser = 'superuser',
  User = 'user',
  Guest = 'guest',
}

export enum CallState {
  New = 'new',
  Requesting = 'requesting',
  Trying = 'trying',
  Recovering = 'recovering',
  Ringing = 'ringing',
  Answering = 'answering',
  Early = 'early',
  Active = 'active',
  Held = 'held',
  Hangup = 'hangup',
  Destroy = 'destroy',
  Purge = 'purge',
}

export enum ProductLine {
  Work = 'work',
  School = 'school',
  Events = 'events',
}

export enum PasswordInputLength {
  Max = 64,
  Min = 8,
}

export const EXTENSION_SEPARATOR = ';'
// AutoDial valid for 10 minutes
export const AUTO_DIAL_VALID_FOR_MS = 10 * 60 * 1000
export const AUDIO_DEFAULT_VOLUME = 0
export const AUDIO_MAX_VOLUME = 50
export const AUDIO_MIN_VOLUME = -50
export const AUDIO_MAX_ENERGY = 12
export const AUDIO_MIN_ENERGY = 0
export const TITLE_MAX_CHARS = 50
export const VIDEO_MAX_MOTION_QUALITY = 16
export const VIDEO_MIN_MOTION_QUALITY = 1

export enum ChatMessageType {
  Chat = 'chat',
  Knock = 'knock',
  RoomZoneDenyToJoin = 'roomZoneDenyToJoin',
  RoomZoneDestroyed = 'roomZoneDestroyed',
  RoomZoneDismissRequestToJoin = 'roomZoneDismissRequestToJoin',
  RoomZoneInvitationDeclined = 'roomZoneInvitationDeclined',
  RoomZoneInvitationRequest = 'roomZoneInvitationRequest',
  RoomZoneKick = 'roomZoneKick',
  RoomZoneRequestToJoin = 'roomZoneRequestToJoin',
}

export enum AuthProvider {
  Local = 'local',
  Guest = 'guest',
  Saml = 'saml',
}

export enum UserType {
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
}

export enum UserRole {
  Administrator = 'administrator',
  Member = 'member',
  Guest = 'guest',
}

export enum RoomRole {
  Inviteable = 'inviteable',
  Visitor = 'visitor',
  Attendee = 'attendee',
  Moderator = 'moderator',
  Configurator = 'configurator',
  Manager = 'manager',
}

export enum RoomVisibility {
  Pinned = 'pinned',
  Normal = 'normal',
  Hidden = 'hidden',
  Occupied = 'occupied',
}

export enum GroupRole {
  Maintainer = 'maintainer',
  Member = 'member',
}

export enum LabelIconState {
  None = 'none',
  Loading = 'loading',
  Success = 'success',
}

export enum UserActionType {
  Add = 'add',
  Edit = 'edit',
}

export enum GrantResourceType {
  Room = 'rooms',
  RoomTemplate = 'room_templates',
}

// To "own" a grant is to have received it from an admin
export enum GrantOwnerType {
  Group = 'cantina_group',
  User = 'cantina_user',
  Guest = 'cantina_guest',
}

export const MEDIA_CONTROLS_FLOATER_ID = 'media_controls'
export enum FloaterType {
  PlaybackControls = 'playback_controls',
  ParticipantOptions = 'participant_options',
}

export enum FeatureFlag {
  None = '-none-',
  GrantViewAllRooms = 'grant_view_all_rooms',
  SidebarConversations = 'sidebar_conversations',
  SidebarConversationsGuestCreate = 'sidebar_conversations_guest_create',
  WatchRTC = 'watch_rtc_sdk',
  HideInviteButton = 'hide_invite_button',
  HideParticipantList = 'hide_participant_list',
  HideParticipantEmail = 'hide_participant_email',
  HideRaiseHand = 'hide_raise_hand',
  HideAdminMenu = 'hide_admin_menu',
  SubdomainRefresh = 'subdomain_refresh',
  MixPanel = 'mix_panel',
  MCUEmptyLayers = 'mcu_empty_layers',
  AllowStereoAudio = 'allow_stereo_audio',
  CustomHeaderLogo = 'custom_header_logo',
  CustomAppLabel = 'custom_app_label',
  CustomNameLabel = 'custom_name_label',
  CustomCompanyLabel = 'custom_company_label',
}

export enum ClipeezeStatus {
  Active = 'active',
  Processing = 'processing',
  Failed = 'failed',
}

export enum CallStateError {
  RoomReserve = 'roomReserve',
  FSConnect = 'fsConnect',
}
